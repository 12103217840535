import React, { useEffect, useState } from "react"
import "./styles.scss"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { compose } from "redux"
import { withNamespaces } from "react-i18next"
import { API_URL, TOAST_CONFIG } from "../../config/constants"
import { Loading, Panel, PaymentLogForm } from "../../components"

const PaymentLog = ({
  t,
  showMessage,
  clients: {
    paymentRecords: { paymentLog },
    loading,
  },
  history,
}) => {
  return (
    <>
      {loading && <Loading />}
      <Panel headingText={t("gatewayInformation")}>
        <PaymentLogForm log={paymentLog} t={t} />
        <div className="accept-return-btns">
          <button
            className="button custom-btn is-primary"
            onClick={() => history.push({ pathname: "/payment-record" })}
          >
            {t("return")}
          </button>
        </div>
      </Panel>
    </>
  )
}

PaymentLog.propTypes = {
  auth: PropTypes.shape(Object).isRequired,
  i18n: PropTypes.shape(Object).isRequired,
  t: PropTypes.func.isRequired,
  // showMessage: PropTypes.func.isRequired,
}

const mapStateToProps = ({ clients }) => ({
  clients,
})

export default compose(
  connect(mapStateToProps, {
    // ...clientsActions,
  }),
  withNamespaces()
)(PaymentLog)
