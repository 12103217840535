const START_FETCH = 'item/START_FETCH';
const END_FETCH = 'item/END_FETCH';
const API_CALL = 'item/API_CALL';
const MESSAGE = 'item/MESSAGE';
const FETCH_LEADS = 'item/FETCH_LEADS';
const UPDATE_LEADS = 'item/UPDATE_LEADS';
const EDIT_LEAD = 'item/EDIT_LEAD';
const DELETE_LEAD = 'item/DELETE_LEAD';


export default {
  START_FETCH,
  END_FETCH,
  API_CALL,
  MESSAGE,
  FETCH_LEADS,
  UPDATE_LEADS,
  EDIT_LEAD,
  DELETE_LEAD,
};
