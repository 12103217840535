import types from './types';

const webpay = (state = {}, action ) => {
  switch (action.type) {
    case types.START_FETCH: {
      return {
        ...state,
        loading: true,
      };
    }
    case types.END_FETCH: {
      const { error, payload } = action;
      return {
        ...state,
        loading: false,
        error: error ? payload : null,
      };
    }
    case types.CREATE_TRANSACTION: {
      const { payload } = action;
      return {
        ...state,
        list: payload,
      };
    }
    case types.TOKEN: {
      const { payload } = action;
      return {
        ...state,
        accessToken: payload,
      };
    }
    case types.LIST_CARDS: {
      const { payload } = action;
      return {
        ...state,
        listCards: payload,
      };
    }
    case types.CYCLE_CARD: {
      const { payload } = action;
      return {
        ...state,
        cycleCard: payload,
      };
    }
    default:
      return state;
  }
}

export default webpay;



