import React from 'react';
import PropTypes from 'prop-types';
import Panel from '../Panel';
import Form from '../Form';
import SelectSingle from '../SelectSingle';
import TextInput from '../TextInput';

const FlowApprovalForm = ({
  t,
  title,
  onSubmit,
  deleteItem,
  handleSelectChange,
  roles,
  roleList,
  users,
  userList,
  values,
  handleInputChange,
  handleInputValueChange,
  flowApprovalList,
  name,
  condition_amount,
  operador,
  is_default,
  process,
  minAmount,
  maxAmount,
}) => (
    <Panel headingText={title}>
      <Form
        submitText={t('save')}
        onSubmit={(event) => {
          event.preventDefault();
          onSubmit();
        }}
        buttonClasses="mr-8px"
      >
        {/*   <div className="field is-horizontal">
        <div className="field-body columns">
          <div className="column">
            <br />
            <ul style={{ marginBottom: '3px' }}>
              {flowApprovalList.map((val, index) => (
                <li key={val.user_id !== null ? val.person.name : val.group.name}>
                  {`${index + 1}   `}
                  <i className="fas fa-chevron-circle-down mr-10px" />
                  <p style={{ marginLeft: '35px', marginTop: '-22px' }}>
                    {val.user_id !== null ? val.person.name : val.group.name}
                  </p>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div> */}
        <div className="field is-horizontal">
        { process.processType.name === 'by_amount' && 
          <div className="field-body columns">
            <div className="column is-two-third">
              <TextInput
                name="name"
                label={t('name')}
                value={name}
                onChange={handleInputChange}
                placeholder={t('name')}
                type="text"
              />
            </div>
          </div>
        }
        { process.processType.name === 'by_hierarchy' && 
          <div className="field-body columns">
            <div className="column is-two-third">
              <TextInput
                name="name"
                label={t('name')}
                value={name}
                onChange={handleInputChange}
                placeholder={t('name')}
                type="text"
              />
            </div>
            <div className="column is-two-third">
              <SelectSingle
                name="operador"
                label={t('operator')}
                selectClasses="is-fullwidth"
                placeholder={t('Select')}
                onChange={handleSelectChange}
                type="text"
                options={
                  [{
                    label: t('lessThan'),
                    value: 0,
                  }, {
                    label: t('greaterThan'),
                    value: 1,
                  }, {
                    label: t('lessThanOrEqualTo'),
                    value: 2,
                  }, {
                    label: t('greaterThanOrEqualTo'),
                    value: 3,
                  }]}
                value={operador}
              />
            </div> 
            <div className="column is-two-third">
              <TextInput
                name="condition_amount"
                label={t('condition_amount')}
                value={condition_amount}
                onChange={handleInputChange}
                placeholder="0"
                type="number"
                controlClasses="is-expanded has-icons-left"
                inputClasses="has-text-right"
                min="0"
                step="0.01"
              />
            </div>
          </div>}
        </div>
        <br />
        <div className="field is-horizontal">
          <div className="field-body columns">
            <div className="column is-one-third select-lead-require">
              <div className="width-select-lead-require">
                <SelectSingle
                  name="roles"
                  label={t('roles')}
                  selectClasses="is-fullwidth"
                  placeholder={t('Select')}
                  onChange={handleSelectChange}
                  options={[
                    {
                      label: t('Select'),
                      value: '',
                    },
                    ...roleList.map((item, index) => ({
                      label: `${item.name} `,
                      value: index,
                    })),
                  ]}
                  value={roleList + 1}
                />
              </div>
            </div>
            <div className="column is-two-third">
              <SelectSingle
                name="users"
                label={t('users')}
                selectClasses="is-fullwidth"
                placeholder={t('Select')}
                onChange={handleSelectChange}
                options={[
                  {
                    label: t('Select'),
                    value: '',
                  },
                  ...userList.map((item, index) => ({
                    label: `${item.idPerson.name} - ${t(item.role_name)} `,
                    value: index,
                  })),
                ]}
                value={userList + 1}
              />
            </div>
          </div>
        </div>
        <div className="field is-horizontal">
          {/*<div className="field-body columns">
            <div className="column">
              <label htmlFor="noRetain" className="checkbox" style={{ width: '100%' }} />
              <input
                label={t('flowApprovalCheck')}
                name="is_default"
                value={is_default}
                onChange={handleInputChange}
                type="checkbox"
                checked={is_default}
              />
              {` ${t('flowApprovalCheck')}`}
            </div>
          </div>*/}
        </div>
        <div className="field is-horizontal">
          <div className="field-body columns">
            <div className="column">
              <br />
              {values
                && (
                  <ul style={{ marginBottom: '3px' }}>
                    {values.map((val, index) => (
                      <li key={val.name} style={{display: 'flex'}}>
                        <div className="column is-one-third">
                          <button
                            className="button is-danger is-inverted"
                            type="button"
                            onClick={deleteItem.bind(this, index)}
                          >
                            <i className="fas fa-minus-circle mr-10px" />
                          </button>
                          <p
                            style={{
                              marginLeft: '35px',
                              marginTop: '-25px',
                            }}
                          >
                            &nbsp;
                            {val.name}
                          </p>
                        </div>
                        {process.processType.name === 'by_amount' && (
                          <div 
                            className="column is-two-third" 
                            style={{display:'flex', justifyContent: 'space-between', marginTop: '-25px'}}
                          >
                            <TextInput
                              id={index}
                              name={"minAmount-"+index}
                              label={t('since')}
                              value={minAmount[index]}
                              onChange={handleInputValueChange}
                              placeholder="0"
                              type="number"
                              controlClasses="is-expanded has-icons-left"
                              inputClasses="has-text-right"
                              min="0"
                              //step="1000"
                            />
                            <TextInput
                              id={index}
                              name={"maxAmount-"+index}
                              label={t('until')}
                              value={maxAmount[index]}
                              onChange={handleInputValueChange}
                              placeholder="0"
                              type="number"
                              controlClasses="is-expanded has-icons-left"
                              inputClasses="has-text-right"
                              min="0"
                              //step="1000"
                            />
                        </div>
                        )}
                      </li>
                    ))}
                  </ul>
                )}
            </div>
          </div>
        </div>
      </Form>
    </Panel>
  );

FlowApprovalForm.propTypes = {
  t: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  values: PropTypes.arrayOf(PropTypes.object).isRequired,
  roleList: PropTypes.arrayOf(PropTypes.object).isRequired,
  userList: PropTypes.arrayOf(PropTypes.object).isRequired,
  deleteItem: PropTypes.func.isRequired,
};

export default FlowApprovalForm;
