import React, { Component } from 'react';
import { compose } from 'redux';
import './styles.scss';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import PropTypes from 'prop-types';
import { Loading, } from '../../components';
import { TOAST_CONFIG } from '../../config/constants';
import { authActions } from '../../state/ducks/auth';
import { roleActions } from '../../state/ducks/role';
import { userProfileActions } from '../../state/ducks/userProfile';
import { flowApprovalActions } from '../../state/ducks/flowApproval';
import FlowApprovalForm from '../../components/FlowApprovalForm';

class FlowApproval extends Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  getInitialState = () => ({
    name: '',
    user: '',
    condition_amount: 0,
    operador: '',
    is_default: false,
    roleList: [],
    userList: [],
    value: [],
    minAmount: [],
    maxAmount: [],
  });

  componentDidMount() {
    const {
      fetchRoles,
      fetchUserProfile,
      getProcessWorkFlow,
      auth: { user: { b2b: { id } } },
      router: { location: { pathname } },
    } = this.props;

    fetchRoles({
      params: {
        owner_id: id,
      },
    });

    fetchUserProfile({
      params: {
        owner_id: id,
      },
    });

    getProcessWorkFlow({
      params: { user_id: id },
    });

    if (pathname === '/edit-approval-flow') this.setState(this.arrayWorkFlow);
  }

  resetState = () => {
    this.setState(this.getInitialState());
  };

  getTitle = () => {
    const {
      t,
      router: { location: { pathname } },
    } = this.props;

    switch (pathname) {
      case '/create-approval-flow':
        return t('createFlowApproval');
      case '/edit-approval-flow':
        return t('edit');
      default:
        return t('');
    }
  };

  arrayWorkFlow = () => {
    const {
      flowApproval: { activeFlow },
      auth: { user: { process_type } },
    } = this.props;

    const processtype = process_type.name;
  
    if (activeFlow != '') {
      const pos = activeFlow.length - 1;
      const array = [];

      for (let i = 0; i < pos; i++) {
        let variable = {
          type: activeFlow[i].user_id !== null ? 'user' : 'role',
          id: activeFlow[i].user_id !== null ? activeFlow[i].user_id : activeFlow[i].group.id,
          name: activeFlow[i].user_id !== null ? activeFlow[i].person.name : activeFlow[i].group.name,
        };
        array.push(variable);
      }

      const name = activeFlow[pos].name;
      const condition_amount = activeFlow[pos].condition_value;
      const operador = this.changeOperatorInv(activeFlow[pos].condition_operator);
      const is_default = (activeFlow[pos].is_default === '1') ? true : false;
      const value = array;

      let minAmount = [];
      let maxAmount = [];

      if(processtype === "by_amount"){
        for(let i = 0; i < (activeFlow.length-1); i++){
          minAmount[i] = activeFlow[i].conditions[0].condition_value;
          maxAmount[i] = activeFlow[i].conditions[1].condition_value;
        }
      }else{
        minAmount = null;
        maxAmount = null;
      }

      return {
        name,
        condition_amount,
        operador,
        is_default,
        minAmount,
        maxAmount,
        value,
      };
    }
  };

  validateDuplicate = (id) => {
    const {
      value,
    } = this.state;

    for (let i = 0; i < value.length; i++) {
      if (id === value[i].id) {
        return false;
      }
    }
    return true;
  };

  handleInputChange = (event) => {
    const {
      target: {
        checked, value: targetValue, type, name,
      },
    } = event;
    const value = type === 'checkbox' ? checked : targetValue;
    this.setState({
      [name]: value,
    });
  };

  handleInputValueChange = (event) => {
    const {
      target: {
        checked, value: targetValue, type, name
      },
    } = event;
    const value = type === 'checkbox' ? checked : targetValue;
    
    const values = name.split('-');
    const inputName = values[0];
    const index = values[1];

    if(inputName === 'minAmount'){
      let minAmount = [...this.state.minAmount];
      let min = {...minAmount[index]};
      min = value;  
      minAmount[index] = min;
      this.setState({minAmount});
    }
    if(inputName === 'maxAmount'){
      let maxAmount = [...this.state.maxAmount];
      let max = {...maxAmount[index]};
      max = value;  
      maxAmount[index] = max;
      this.setState({maxAmount});
    }
  };

  handleSelectChange = (newVal, label) => {
    const {
      userProfile: { list: userList },
      role: { list: roleList },
      t
    } = this.props;

    if (newVal.value !== '') {
      this.setState({
        [label.name]: newVal.value,
      }, () => (this.state.user));

      if (label.name === 'users') {
        let variable = {
          type: 'user',
          id: userList[newVal.value].id,
          name: userList[newVal.value].idPerson.name + ' - ' + t(userList[newVal.value].role_name),
        };
        if (this.validateDuplicate(variable.id)) {
          this.state.value.push(variable);
        }
      }
      if (label.name === 'roles') {
        let variable = {
          type: 'role',
          id: roleList[newVal.value].id,
          name: roleList[newVal.value].name,
        };
        if (this.validateDuplicate(variable.id)) {
          this.state.value.push(variable);
        }
      }
    }
  };

  deleteItem = (index) => {
    const {
      value,
    } = this.state;
    this.setState(value.splice(index, 1));
  };

  changeOperator = (operator) => {
    switch (operator) {
      case 0:
        return '<';
      case 1:
        return '>';
      case 2:
        return '<=';
      case 3:
        return '>=';
      default:
        return '';
    }
  };

  changeOperatorInv = (operator) => {
    switch (operator) {
      case '<':
        return 0;
      case '>':
        return 1;
      case '<=':
        return 2;
      case '>=':
        return 3;
      default:
        return '';
    }
  };

  saveFlowApproval = () => {
    const {
      t,
      showMessage,
      createFlowApproval,
      flowApproval: { activeFlow },
      auth: { user: { user_id } },
      auth: { user: { process_type } },
      router: { location: { pathname } },
      history,
    } = this.props;

    const {
      value,
      name,
      condition_amount,
      operador,
      is_default,
      minAmount,
      maxAmount,
    } = this.state;

    const processtype = process_type.name;

    const condition_operator = this.changeOperator(operador);
    const id = user_id;
    const data = value;
    //const processtype = process_type.name;
    let new_condition = [];
    
    if(processtype === "by_amount"){
      for(let i = 0; i<maxAmount.length; i++){
        new_condition[i] = [
          {condition_operator:">=", condition_value: minAmount[i]},
          {condition_operator:"<=", condition_value: maxAmount[i]},
        ];
        value[i].conditions = new_condition[i];
      }
    }

    if (pathname === '/create-approval-flow') {
      if (value.length > 0) {
        createFlowApproval({
          id,
          data,
          is_default,
          name,
          condition_amount,
          condition_operator,
          callback: (response) => {
            console.info(response);
            showMessage({
              message: t('createFlowApprovalSuccess'),
              config: TOAST_CONFIG.SUCCESS,
            });
            history.push('/approval-flow-list');
            //this.resetState();
          },
        });
      }
    }

    if (pathname === '/edit-approval-flow') {
      let pos = activeFlow.length - 1;
      let process_id = activeFlow[pos].id;
      console.dir(process_id);
      if (value.length > 0) {
        createFlowApproval({
          id,
          data,
          is_default,
          name,
          process_id,
          condition_amount,
          condition_operator,
          callback: (response) => {
            console.info(response);
            showMessage({
              message: t('editFlowApprovalSuccess'),
              config: TOAST_CONFIG.SUCCESS,
            });
            //this.resetState();
            history.push('/approval-flow-list');
          },
        });
      }
    }
  };

  render() {
    const {
      t,
      role: { loading, list: roleList },
      userProfile: { list: userList },
      router: { location: { pathname } },
      flowApproval: { process },
    } = this.props;
    const {
      value,
      name,
      condition_amount,
      operador,
      is_default,
      minAmount,
      maxAmount,
    } = this.state;
    return (
      <div>
        {loading && <Loading/>}
        <div className="columns">
          <div className="column is-full">
            <FlowApprovalForm
              t={t}
              title={this.getTitle()}
              handleItemChange={this.handleItemChange}
              handleSelectChange={this.handleSelectChange}
              handleInputChange={this.handleInputChange}
              handleInputValueChange={this.handleInputValueChange}
              roleList={roleList}
              userList={userList}
              //flowApprovalList={flowApprovalList}
              values={value}
              name={name}
              condition_amount={condition_amount}
              operador={operador}
              is_default={is_default}
              onSubmit={() => this.saveFlowApproval()}
              deleteItem={this.deleteItem}
              process={process[0]}
              minAmount={minAmount}
              maxAmount={maxAmount}
            />
          </div>
        </div>
      </div>
    );
  }
}

FlowApproval.propTypes = {
  showMessage: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  fetchRoles: PropTypes.func.isRequired,
  fetchUserProfile: PropTypes.func.isRequired,
  createFlowApproval: PropTypes.func.isRequired,
  getFlowApproval: PropTypes.func.isRequired,
  getWorkFlowId: PropTypes.func.isRequired,
  router: PropTypes.shape(Object).isRequired,
};

const mapStateToProps = ({ role, auth, userProfile, flowApproval, router, }) => (
  {
    role,
    auth,
    userProfile,
    flowApproval,
    router,
  }
);

export default compose(
  connect(mapStateToProps, {
    ...roleActions,
    ...authActions,
    ...userProfileActions,
    ...flowApprovalActions,
  }),
  withNamespaces(),
)(FlowApproval);
