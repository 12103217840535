import { createAction } from 'redux-actions';
import types from './types';

const startFetch = createAction(types.START_FETCH);
const endFetch = createAction(types.END_FETCH);
const fetchCardData = createAction(types.FETCH_CARD_DATA);
const updateCardData = createAction(types.UPDATE_CARD_DATA);
const rechargeCard = createAction(types.RECHARGE_CARD);
const saveCycleCard = createAction(types.SAVE_CYCLE_CAR);
const getCycleCard = createAction(types.GET_CYCLE_CARD);
const getCyclePeriods = createAction(types.GET_CYCLE_PERIODS);
const registerPeriods = createAction(types.REGISTER_PERIODS);
const getBillingCycles = createAction(types.GET_BILLING_CYCLES);
const getCycles = createAction(types.GET_CYCLES);
const getCard = createAction(types.GET_CARD);
const deleteCard = createAction(types.DELETE_CARD);
const registerExecutive = createAction(types.REGISTER_EXECUTIVE);
const getExecutive = createAction(types.GET_EXECUTIVE);
const assistCard = createAction(types.ASSIST_CARD);
const getAssistCard = createAction(types.GET_ASSIST_CARD);
const editBillingCycle = createAction(types.EDIT_BILLING_CYCLES);
const loadExecutivesFile = createAction(types.LOAD_EXECUTIVES_FILE);
const saveExecutivesFile = createAction(types.SAVE_EXECUTIVES_FILE);
const getPeriodBilling = createAction(types.GET_PERIOD_BILLING);
const updateBillingPaymentPerson = createAction(types.UPDATE_BILLING_PAYMENT_PERSON);

const showMessage = createAction(types.MESSAGE,
  ({ message }) => ({
    message,
  }),
  ({ config }) => ({
    config,
  }));

export default {
  startFetch,
  endFetch,
  showMessage,
  fetchCardData,
  updateCardData,
  rechargeCard,
  saveCycleCard,
  getCycleCard,
  getCyclePeriods,
  registerPeriods,
  getBillingCycles,
  getCycles,
  getCard,
  registerExecutive,
  getExecutive,
  deleteCard,
  assistCard,
  getAssistCard,
  editBillingCycle,
  loadExecutivesFile,
  saveExecutivesFile,
  getPeriodBilling,
  updateBillingPaymentPerson,
};
