import React, { useEffect, useState } from "react"
import "./styles.scss"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { compose } from "redux"
import { clientsActions } from "../../state/ducks/clients"
import { withNamespaces } from "react-i18next"
import { API_URL, TOAST_CONFIG } from "../../config/constants"
import { Loading, Modal, Panel, TableSimple, Toggle } from "../../components"
import { formatAmount, formatDate } from "../../util"

const PaymentRecord = ({
  t,
  showMessage,
  fetchClientsPaymentRecords,
  fetchBanksPaymentRecords,
  fetchPaymentDetail,
  fetchPaymentLog,
  deletePayment,
  auth: {
    user: {
      b2b: { user_type, type_user },
    },
  },
  clients: { paymentRecords, loading },
  history,
}) => {
  useEffect(() => {
    type_user === "buyer"
      ? fetchClientsPaymentRecords({})
      : fetchBanksPaymentRecords({})
  }, [])

  const [requiredAction, setRequiredAction] = useState("none")
  const getGridSettings = (onToggle) => {
    let columns = null
    let data = [] //null
    let title = "" //null

    columns = [
      {
        expander: true,
        show: false,
      },
      {
        id: "provider", // Required because our accessor is not a string
        Header: t("provider"),
        accessor: (d) => d.seller.name,
      },
      {
        id: "invoiceNumber", // Required because our accessor is not a string
        Header: user_type === "NCL" ? t("instructionNumber") : t("invoiceNumber"),
        accessor: (d) => (d.invoice_number !== "" ? d.invoice_number : "-"),
      },
      {
        id: "paymentReference", // Required because our accessor is not a string
        Header: t("paymentReference"),
        accessor: (d) =>
          d.payment_reference !== "" ? d.payment_reference : "-",
      },
      {
        id: "currency",
        Header: t("currency"),
        show: !(user_type === "NCL"),
        accessor: (d) => d.currency_id,
      },
      {
        id: "amount",
        Header: t("amount"),
        accessor: (d) => formatAmount(d.adjusted_amount),
      },
      {
        id: "buyer",
        Header: t("buyer"),
        accessor: (d) => d.buyer.name,
        show: type_user === "issuing_bank",
      },
      {
        id: "description",
        Header: t("description"),
        accessor: (d) => (d.description !== "" ? d.description : "-"),
      },
      {
        id: "executionDate", // Required because our accessor is not a string
        Header: t("executionDate"),
        accessor: (d) => formatDate(d.date),
      },
      {
        id: "status", // Required because our accessor is not a string
        Header: t("status"),
        accessor: (d) => d.sender_status_id,
      },
      {
        id: "action", // Required because our accessor is not a string
        Header: t("action"),
        accessor: (d) => "Acción",
        width: 220,
        filterable: false,
        Cell: (cellInfo) => {
          const {
            resource,
            resource_xml,
            sender_status_id,
            emails,
            log,
          } = cellInfo.original
          const hasPDF = resource,
            hasXML = resource_xml,
            showPaymentDetail = sender_status_id === "Pagado",
            showPaymentLog =
              sender_status_id === "Guardado" &&
              user_type === "TDC" &&
              type_user === "buyer" &&
              log.length > 0,
            canEdit = sender_status_id === "Guardado" && type_user === "buyer",
            canDelete = canEdit,
            hasEmails = emails.length > 0

          return (
            <table>
              <tbody>
                <tr className="TableSimple__action-btns">
                  {/* DOWNLOAD PDF */}
                  {hasPDF && (
                    <td>
                      <a
                        href={`${API_URL}b2b/resource-file?nameFile=${resource}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="button"
                        download
                      >
                        <i
                          className="fas fa-file-pdf"
                          title={t("downloadPDF")}
                        />
                      </a>
                    </td>
                  )}
                  {/* DOWNLOAD XML */}
                  {hasXML && (
                    <td>
                      <a
                        href={resource_xml}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="button"
                        download
                      >
                        <i
                          className="fas fa-file-code"
                          title={t("downloadXML")}
                        />
                      </a>
                    </td>
                  )}
                  {/* EDIT PAYMENT */}
                  {canEdit && (
                    <td>
                      <button
                        className="button"
                        onClick={() => {
                          fetchPaymentDetail({
                            params: { payment: cellInfo.original.id },
                            callback: (res) => {
                              if (res.success) {
                                history.push({
                                  pathname: "/edit-payment",
                                })
                              }
                            },
                          })
                        }}
                      >
                        <i className="fas fa-edit" title={t("edit")} />
                      </button>
                    </td>
                  )}
                  {/* DELETE PAYMENT */}
                  {canDelete && (
                    <td>
                      <button
                        className="button"
                        onClick={() => {
                          setRequiredAction("deletePayment")
                          onToggle({
                            id: cellInfo.original.id,
                            amount: formatAmount(
                              cellInfo.original.adjusted_amount,
                              `${cellInfo.original.currency_id} $`,
                            ),
                            provider: cellInfo.original.seller.name,
                          })
                        }}
                      >
                        <i className="fas fa-times" title={t("delete")} />
                      </button>
                    </td>
                  )}
                  {/* PAYMENT DETAIL */}
                  {showPaymentDetail && (
                    <td>
                      <button
                        className="button"
                        onClick={() => {
                          fetchPaymentDetail({
                            params: { payment: cellInfo.original.id },
                            callback: (res) => {
                              if (res.success) {
                                history.push({
                                  pathname: "/payment-detail",
                                })
                              }
                            },
                          })
                        }}
                      >
                        <i
                          className="fas fa-file-alt"
                          title={t("instructionDetail")}
                        />
                      </button>
                    </td>
                  )}
                  {/* RELATED EMAILS */}
                  {hasEmails && (
                    <td>
                      <button
                        className="button"
                        onClick={() => {
                          setRequiredAction("relatedEmails")
                          onToggle({
                            mails: cellInfo.original.emails,
                          })
                        }}
                      >
                        <i
                          className="fas fa-envelope"
                          title={t("relatedEmails")}
                        />
                      </button>
                    </td>
                  )}
                  {/* GATEWAY INFO (PAYMENT LOG) */}
                  {showPaymentLog && (
                    <td>
                      <button
                        className="button"
                        onClick={() => {
                          fetchPaymentLog({
                            params: { payment: cellInfo.original.id },
                            callback: (res) => {
                              if (res.success) {
                                history.push({
                                  pathname: "/payment-log",
                                })
                              }
                            },
                          })
                        }}
                      >
                        <i
                          className="fas fa-eye"
                          title={t("gatewayInformation")}
                        />
                      </button>
                    </td>
                  )}
                </tr>
              </tbody>
            </table>
          )
        },
      },
    ]

    data = typeof(paymentRecords.list) !== "undefined" ? paymentRecords.list : [];

    return {
      columns,
      data,
      title,
    }
  }

  return (
    <>
      {(loading || !paymentRecords.list.length === 0) && <Loading />}
      <Toggle>
        {({ toggled, onToggle, toggleProps }) => {
          const { title, columns, data } = getGridSettings(onToggle)

          return (
            <>
              {/* DELETE PAYMENT */}
              {toggled && requiredAction === "deletePayment" && (
                <Modal
                  title={t("confirmDeletePayment", {
                    amount: toggleProps.amount,
                    provider: toggleProps.provider,
                  })}
                  onToggle={onToggle}
                >
                  <div className="modal-action-btns">
                    <button
                      className="button custom-btn is-danger"
                      onClick={() => onToggle()}
                    >
                      {t("return")}
                    </button>
                    <button
                      className="button custom-btn is-primary"
                      onClick={() => {
                        deletePayment({
                          id: toggleProps.id,
                          callback: (response) => {
                            if (response.success) {
                              type_user === "buyer"
                                ? fetchClientsPaymentRecords({})
                                : fetchBanksPaymentRecords({})
                              showMessage({
                                message: t("deletePaymentSuccess"),
                                config: TOAST_CONFIG.SUCCESS,
                              })
                              onToggle()
                            }
                          },
                        })
                      }}
                    >
                      {t("accept")}
                    </button>
                  </div>
                </Modal>
              )}
              {/* RELATED EMAILS */}
              {toggled && requiredAction === "relatedEmails" && (
                <Modal title={t("relatedEmails")} onToggle={onToggle}>
                  <>
                    <ul className="PaymentRecord__mails-list">
                      {toggleProps.mails.map((mail) => {
                        return (
                          <li key={mail}>
                            <i className="fas fa-envelope" />
                            {mail}
                          </li>
                        )
                      })}
                    </ul>
                    <div className="modal-action-btns">
                      <button
                        className="button custom-btn is-danger"
                        onClick={() => onToggle()}
                      >
                        {t("return")}
                      </button>
                    </div>
                  </>
                </Modal>
              )}
              <Panel headingText={t("paymentRecord")}>
                <TableSimple data={data} columns={columns} />
              </Panel>
            </>
          )
        }}
      </Toggle>
    </>
  )
}

PaymentRecord.propTypes = {
  auth: PropTypes.shape(Object).isRequired,
  i18n: PropTypes.shape(Object).isRequired,
  t: PropTypes.func.isRequired,
  showMessage: PropTypes.func.isRequired,
}

const mapStateToProps = ({ auth, clients }) => ({
  auth,
  clients,
})

export default compose(
  connect(mapStateToProps, {
    ...clientsActions,
  }),
  withNamespaces()
)(PaymentRecord)
