import React from 'react';
import PropTypes from 'prop-types';
import Panel from '../Panel';
import Form from '../Form';
import TextInput from '../TextInput';
import TextArea from '../../components/TextArea';
import SelectSingle from '../SelectSingle';
import { CreatableMultiSelect } from '..';
import { propTypes } from 'qrcode.react';

const ProductForm = ({
    t,
    onSubmit,
    handleInputChange,
    addBenefit,
    name,
    description,
    amount,
    details,
    edit,
  }) => (
    <Panel headingText={t('registerProduct')}>
        <Form
        submitText={edit ? t('edit') : t('save')}
        onSubmit={(event) => {
            event.preventDefault();
            onSubmit(edit);
        }}
        buttonClasses="mr-8px"
        >
            <div className="field is-horizontal">
                <div className="column">
                    <TextInput
                        name="name"
                        label={t('name')}
                        value={name}
                        onChange={handleInputChange}
                        placeholder={t('name')}
                        type="text"
                        controlClasses="is-expanded"
                    />
                </div>
            </div>
            <div className="field is-horizontal">
                <div className="column">
                    <TextArea
                        name="description"
                        label={t('description')}
                        value={description}
                        onChange={handleInputChange}
                        placeholder={t('description')}
                        controlClasses="is-expanded"
                        maxLength="120"
                    />
                </div>
            </div>
            <div className="field is-horizontal">
                <div className="column">
                    <TextInput
                        name="amount"
                        label={t('amount')}
                        value={amount}
                        placeholder="0.00"
                        onChange={handleInputChange}
                        type="number"
                        controlClasses="is-expanded"
                    />
                </div>
            </div>
            <div className="field is-horizontal has-text-weight-bold">
                {t('benefits')}
            </div>
            <ul>
            {details.map((val, index) => (
                <li>
                    <div className="field is-horizontal">
                        <div className="field-body columns">
                            <div className="column">
                                <TextInput
                                    name={'detail-' + index}
                                    value={val.description}
                                    onChange={handleInputChange}
                                    placeholder={t('benefits')}
                                    type="text"
                                    controlClasses="is-expanded"
                                />
                            </div>
                            <div style={{ marginTop: '14px', }}>
                                <button
                                    className="button is-info is-outlined is-pulled-right"
                                    type="button"
                                    onClick={addBenefit}
                                    disabled={index === details.length - 1 ? false : true}
                                >
                                    <i className="fas fa-plus-circle"/>
                                </button>
                            </div>
                        </div>
                    </div>
                </li>
            ))}
            </ul>
        </Form>
    </Panel>
  );

  ProductForm.propTypes = {
    t: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    handleInputChange: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
  }
  
  export default ProductForm;