import React, { Component, Fragment } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import PropTypes from 'prop-types';
import { Currency, Loading, Modal, Panel, TableSimple, Toggle, } from '../../components';
import { statsActions } from '../../state/ducks/stats';
import { transactionsActions } from '../../state/ducks/transactions';
import { clientsActions } from '../../state/ducks/clients';
import { providersActions } from '../../state/ducks/providers';
import { loanActions } from '../../state/ducks/loan';
import { formatDate, formatAmount, numberFilter, onInputOnlyNumbers } from '../../util';
import { Link } from 'react-router-dom';
import { CSVLink } from 'react-csv';


const ItemCard = ({ name, value }) => (
  <li>
    <b>
      {`${name}:`}
    </b>
    {value}
  </li>
);

ItemCard.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

class Loans extends Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
    this.selectTable = React.createRef();
		this.csvRef = React.createRef();
  }

  getInitialState = () => {
    return ({
      invoice_number: null,
      loan_number: null,
      totalAmount: '',
      minimumPayment: '',
      status: '',
      cortDate: '',
      arrayLoan: [{ loan_number: '100-100', invoice_number: '00001', cort_date: '10/03/2022', total_amount: '1200.20', minimum_payment: '350.00', status: 'Activo' }],
      downloadCsv: [],
      csvCols: [],
    });
  };

  componentDidMount() {
    const {
      getLoan,
      auth: {
        user: {
          user_id,
        },
      },
    } = this.props;

    getLoan({
      params: {
        clientId: user_id,
      }
    });
  }

  getTitle = () => {
    const {
      t,
      router: { location: { pathname } },
    } = this.props;

    switch (pathname) {
      case '/approved-loan':
        return t('myLoans');
    }
  };

  getGridSettings = (userType, onToggle) => {
    let columns = null;
    let data = null;
    let title = null;
    const {
      getReference,
      loan: { list, },
      auth: { user: { user_id, } },
      getLoan,
      showMessage,
      t,
    } = this.props;
    /*const {
      arrayLoan,
    } = this.state;
    const {
      approveDisabled
    } = this.state;*/
    switch (userType) {
      case 'buyer':
        columns = [
          {
            expander: true,
            show: false,
          },
          {
            id: 'loan_number', // Required because our accessor is not a string
            headerClassName: 'wordwrap',
            Header: t('loanNumber'),
            accessor: d => `${d.loan.reference}`, // d => `${d.invoice_number}`,
            Cell: cellInfo => (
              <div style={{ textAlign: 'center' }}>
                <span>{cellInfo.original.loan.reference}</span>
              </div>
            ),
          },
          {
            id: 'seller', // Required because our accessor is not a string
            headerClassName: 'wordwrap',
            Header: t('provider'),
            accessor: d => `${d.seller}`, // d => `${d.invoice_number}`,
          },
          {
            id: 'invoice_number', // Required because our accessor is not a string
            Header: t('invoiceNumber'),
            width: 120,
            //accessor: d => formatDate(d.due_date),
            accessor: d => `${d.invoice_number}`,
          },
          {
            id: 'status', // Required because our accessor is not a string
            Header: t('status'),
            headerClassName: 'wordwrap',
            accessor: d => `${d.status.description}`,
            Cell: cellInfo => (
              <div style={{ textAlign: 'center' }}>
                <span>{cellInfo.original.status.description}</span>
              </div>
            ),
          },
          {
            id: 'currency', // Required because our accessor is not a string
            headerClassName: 'wordwrap',
            className: 'text-center',
            Header: t('currency'),
            accessor: d => 'MXN',
          },
          {
            id: 'total_amount', // Required because our accessor is not a string
            headerClassName: 'wordwrap',
            Header: t('amountArranged'),
            accessor: d => d.loan.total_amount,
            Cell: cellInfo => (
              <Currency value={cellInfo.original.loan.total_amount} />
            ),
            sortMethod: (a, b) => Number(a)-Number(b),
            filterMethod: (filter, row) => numberFilter(filter, row),
            Filter: ({ filter, onChange }) =>
              <input
                onInput={onInputOnlyNumbers}
                onChange={event => onChange(event.target.value)}
                style={{ width: '100%' }}
                value={filter ? filter.value : ''}
              />
          },
          {
            id: 'loan_date', // Required because our accessor is not a string
            Header: t('dispositionDate'),
            headerClassName: 'wordwrap',
            accessor: d => formatDate(d.loan.loan_date),
            Cell: cellInfo => (
              <div style={{ textAlign: 'center' }}>
                <span>{formatDate(cellInfo.original.loan.loan_date)}</span>
              </div>
            ),
          },
          {
            id: 'due_date', // Required because our accessor is not a string
            Header: t('dateV'),
            width: 120,
            headerClassName: 'wordwrap',
            accessor: d => formatDate(d.loan.due_date),
            Cell: cellInfo => (
              <div style={{ textAlign: 'center' }}>
                <span>{formatDate(cellInfo.original.loan.due_date)}</span>
              </div>
            ),
          },
          {
            id: 'balance',
            Header: t('balancePerDay'),
            headerClassName: 'wordwrap',
            accessor: d => d.loan.balance,
            Cell: cellInfo => (
              <Currency value={cellInfo.original.loan.balance} />
            ),
            sortMethod: (a, b) => Number(a)-Number(b),
            filterMethod: (filter, row) => numberFilter(filter, row),
            Filter: ({ filter, onChange }) =>
              <input
                onInput={onInputOnlyNumbers}
                onChange={event => onChange(event.target.value)}
                style={{ width: '100%' }}
                value={filter ? filter.value : ''}
              />
          },
          {
            id: 'payment', // Required because our accessor is not a string
            Header: t('payment'),
            filterable: false,
            headerClassName: 'wordwrap',
            Cell: cellInfo => (
              <td>
                <Link
                  className="button is-primary is-inverted"
                  to="/approved-loan"
                  onClick={() => onToggle({
                    clabe: cellInfo.original.loan.clabe !== null ? cellInfo.original.loan.clabe : '',
                    cie: cellInfo.original.loan.cie !== null ? cellInfo.original.loan.cie : '',
                    reference: cellInfo.original.loan.payment_reference !== null ?
                      cellInfo.original.loan.payment_reference : '',
                    info: true,
                  })}
                >
                  <i className="fas fa-plus mr-8px" aria-hidden="true"/>
                  {t('info')}
                </Link>
              </td>
            ),
          },
          /*{
            id: 'clabe', // Required because our accessor is not a string
            Header: t('accountClabe'),
            headerClassName: 'wordwrap',
            accessor: d => `${d.loan.clabe}`,
          },
          {
            id: 'cie', // Required because our accessor is not a string
            Header: t('accountCie'),
            headerClassName: 'wordwrap',
            accessor: d => d.loan.cie !== null ? `${d.loan.cie}` : '',
          },
          {
            id: 'reference', // Required because our accessor is not a string
            Header: t('otherSign'),
            headerClassName: 'wordwrap',
            accessor: d => d.loan.payment_reference !== null ? `${d.loan.payment_reference}` : '',
          },*/
          /* {
             id: 'action', // Required because our accessor is not a string
             Header: t('action'),
             Cell: cellInfo => (
               <table>
                 <tbody>
                   <tr>
                     <td>
                       <button
                         className="button is-primary is-inverted"
                         onClick={() => {
                           getReference({
                             params: {
                               loan: cellInfo.original.id
                             },callback: (response) => {
                               showMessage({
                                 message: t('generatedReference', { reference: response.reference }),
                                 config: TOAST_CONFIG.SUCCESS,
                               });
                               getLoan({
                                 params: {
                                   clientId: user_id,
                                 }
                               });
                             }
                           })
                           }
                         }
                       >
                       <span className="icon" title={t('generateReferencePayment')}>
                         <div>
                           {t('toPay')}
                         </div>
                       </span>
                       </button>
                     </td>
                   </tr>
                 </tbody>
               </table>
             ),
           },
           {
             id: 'action', // Required because our accessor is not a string
             Header: t('detail'),
             Cell: cellInfo => (
               <table>
                 <tbody>
                 <tr>
                   <td>
                     <a
                       //href={`${API_URL}b2b/resource-file?nameFile=${cellInfo.original.resource}`}
                       target="_blank"
                       rel="noopener noreferrer"
                       className="button"
                     >
                       <span className="icon" title={t('download')}>
                         <a className="ml-">
                           <i className="fas fa-download"/>
                         </a>
                       </span>
                     </a>
                   </td>
                   <td>
                     <button
                       className="button is-primary is-inverted"
                       onClick='/#'
                     >
                         <span className="icon" title={t('detail')}>
                           <i className="far fa-file-alt"/>
                         </span>
                     </button>
                   </td>
                 </tr>
                 </tbody>
               </table>
             ),
           },*/
        ];
        data = list !== null ? list : [];
        title = this.getTitle();
        break;
      default:
        columns = [];
        data = [];
        title = '';
    }
    return {
      columns,
      data,
      title,
    };
  };

  download = () => {
		const { sortedData, columns } = this.selectTable.current.getResolvedState();
    const usefulColumns = columns.filter(columm => columm.Header && columm.id !== 'payment')
		// for (let i = 0; i < sortedData.length; i++) {
		// 	sortedData[i].loan_date = (sortedData[i]._original.loan.loan_date);
		// 	sortedData[i].due_date = (sortedData[i]._original.loan.due_date);
		// }

		const downloadCsv = [],
		csvCols = usefulColumns.map(columm => columm.Header),
    columnsWithAmounts = ['total_amount', 'balance'];

    downloadCsv.push(...sortedData.map((sorted) => {
			const downloadCsvObject = {};
			usefulColumns.forEach((column) => { 
        downloadCsvObject[column.id] = columnsWithAmounts.includes(column.id) ? formatAmount(sorted[column.id], '$') : sorted[column.id]
      });
			return Object.values(downloadCsvObject);
		}));

		this.setState({ downloadCsv, csvCols }, () => {
			this.csvRef.current.link.click();
		});
	};

  render() {
    const {
      t,
      loan: { loading: loanLoading },
      transactions: { loading: transactionLoading },
      auth: { user: { b2b: { type_user, } }},
      clients: { loading: clientLoading },
    } = this.props;
    const {
      csvCols,
      downloadCsv,
    } = this.state;
    const loading = transactionLoading || clientLoading || loanLoading;
    return (
      <Toggle>
        {({ toggled, onToggle, toggleProps }) => {
          const { title, columns, data } = this.getGridSettings(type_user, onToggle);
          return (
            <div className="dashboard">
              {loading && <Loading/>}
              <div className="columns is-multiline">
                <Fragment>
                  {
                    toggled && toggleProps.info
                    && (
                      <Modal onToggle={onToggle} title={t("paymentInformation")}> {loading && <Loading/>}
                        <diV style={{align:'left'}}>
                          <br/>
                          <table className="table">
                            <tr>
                              <td>{t('accountClabe')+': '}</td>
                              <td>{toggleProps.clabe}</td>
                            </tr>
                            <tr>
                              <td>{t('accountCie')+': '}</td>
                              <td>{toggleProps.cie}</td>
                            </tr>
                            <tr>
                              <td>{t('otherSign')+': '}</td>
                              <td>{toggleProps.reference}</td>
                            </tr>
                          </table>
                          <div className="modal-action-btns">
                            <button
                              className="button custom-btn is-primary"
                              onClick={() => onToggle()}
                            >
                              {t("goBack")}
                            </button>
                          </div>
                        </diV>
                      </Modal>
                    )
                  }
                </Fragment>
                <Fragment>
                  {/*data
                    && (*/}
                      <div className="column is-full">
                        <div className='panel-BTNS'>
                          <button
                            className="button custom-btn is-link is-outlined"
                            onClick={this.download}
                          >
                            {`${t('download')} CSV`}
                          </button>
                          <CSVLink
                            headers={csvCols}
                            data={downloadCsv}
                            filename="dispositions.csv"
                            className="hidden"
                            ref={this.csvRef}
                            separator=';'
                            charSet="UTF-8"
                          />
                        </div>
                        <Panel headingText={title}>
                          <TableSimple data={data} columns={columns} reff={this.selectTable}/>
                          {/*((type_user === 'buyer') && (pathname === '/review-invoices' || pathname === '/approved-invoices' || pathname === '/paid-invoices')) &&
                        <div className="terms-box" style={{ display: (amexOwner ? 'none' : '') }}>
                          <p style={{ marginBottom: '.5em' }}>{t('termsAndConditions')}</p>
                          <p>{t('invoiceTerms')}</p>
                        </div>
                        */}
                        </Panel>
                      </div>
                  {/*)}*/}
                </Fragment>
              </div>
            </div>
          );
        }}
      </Toggle>

    );
  }
}

Loans.propTypes = {
  auth: PropTypes.shape(Object).isRequired,
  transactions: PropTypes.shape(Object).isRequired,
  clients: PropTypes.shape(Object).isRequired,
  t: PropTypes.func.isRequired,
  fetchStats: PropTypes.func.isRequired,
  listTransactionsSender: PropTypes.func.isRequired,
  listTransactionsReceiver: PropTypes.func.isRequired,
  getClients: PropTypes.func.isRequired,
  approveInvoice: PropTypes.func.isRequired,
  editTransaction: PropTypes.func.isRequired,
  deferInvoice: PropTypes.func.isRequired,
  advanceInvoice: PropTypes.func.isRequired,
  router: PropTypes.shape(Object).isRequired,
  deleteTransaction: PropTypes.func.isRequired,
  history: PropTypes.shape(Object).isRequired,
};

const mapStateToProps = ({
                           transactions,
                           auth,
                           router,
                           clients,
                           loan,
                         }) => ({
  transactions,
  auth,
  router,
  clients,
  loan,
});

export default compose(
  connect(mapStateToProps, {
    ...statsActions, ...transactionsActions, ...clientsActions, ...providersActions, ...loanActions,
  }),
  withNamespaces(),
)(Loans);
