import React, { Component, Fragment } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import PropTypes from 'prop-types';
import { supportActions } from '../../state/ducks/support';
import { Link } from 'react-router-dom';
import { Form, Loading, Modal, Panel, SelectSingle, TableSimple, TextArea, TextInput, Toggle } from '../../components';
import { TOAST_CONFIG } from '../../config/constants';

class Faq extends Component {
    constructor(props) {
        super(props);
        this.state = this.getInitialState();
    }

    componentDidMount() {
        const { 
            supportRoles,
        } = this.props;

        supportRoles({
            callback: (response) => {
                this.setState({roles: response.data});
            }
        });
    }

    getInitialState = () => ({
        id: '',
        faqs: [],
        question: '',
        link: '',
        answer: '',
        roles: [],
        role: '',
    });

    resetState = () => {
        this.setState(this.getInitialState());
    };

    getGridSettings = (onToggle) => {
        const { 
            t,
        } = this.props;
        const { 
            faqs,
        } = this.state;

        const columns = [
            {
                expander: true,
                show: false,
            },
            {
                id: 'question',
                Header: t('question'),
                accessor: d => `${d.question}`,
            },
            {
                id: 'answer',
                Header: t('answer'),
                accessor: d => `${d.answer}`,
            },
            {
                id: 'link_support',
                Header: t('supportLink'),
                accessor: 'link_support',//d => `${d.link_support}`,
                Cell: cellInfo => (
                    <a href={cellInfo.original.link_support} target="_blank">
                        {cellInfo.original.link_support}
                    </a>
                ),
            },
            {
                id: 'action', // Required because our accessor is not a string
                Header: t('action'),
                filterable: false,
                accessor: d => `${d.id} `,
                Cell: cellInfo => (
                <table>
                    <tbody>
                        <tr>
                            <div style={{display: 'flex', flexWrap: 'wrap', justifyContent: 'space-around'}}>
                                <button
                                    className="button is-primary is-inverted"
                                    onClick={() => this.openModal(onToggle, cellInfo.original, true)}
                                >
                                    <i className="far fa-edit mr-8px" aria-hidden="true"/>
                                    {t('edit')}
                                </button>
                                <button
                                    className="button is-primary is-inverted"
                                    onClick={() => this.openModal(onToggle, cellInfo.original, false)}
                                >
                                    <i className="far fa-eye mr-8px" aria-hidden="true"/>
                                    {t('seeQuestion')}
                                </button>
                            </div>
                        </tr>
                    </tbody>
                </table>
                ),
            },
        ];
        const data = faqs;
        const title = t('platformFaqList');
        return {
            columns,
            data,
            title,
        };
    };

    handleSelectRole = (newVal, label) => {
        
        const { getFaqs } = this.props;
        const { roles } = this.state;
    
        getFaqs({
            params: {
                role: roles[newVal.value].id,
            },
            callback: (response) => {
                this.setState({faqs: response.data});
            }
        });

        this.setState({
            role: roles[newVal.value].id,
        });
    };

    openModal = (onToggle, data, edit) => {
        this.setState({
            id: data.id,
            question: data.question,
            answer: data.answer,
            link: data.link_support,
        });
        if(edit === true){
            onToggle({
                edit: true,
            })
        }else{
            onToggle({
                detail: true,
            })
        }
    }

    closeModal = (onToggle) => {
        onToggle();
    };

    handleInputChange = (event) => {
        const {
            target: {
                checked, value: targetValue, type, name,
            },
        } = event;
        const value = type === 'checkbox' ? checked : targetValue;
        this.setState({
            [name]: value,
        });
    };

    editQuestion = () => {
        const {
            t,
            updateFaq,
            showMessage,
        } = this.props;
        const { 
            id,
            question,
            link,
            answer,
            role,
        } = this.state;

        const data = {
            id,
            question, 
            answer,
            link_support: link,
            role,
        }

        updateFaq({
            data,
            callback: (response) => {
                showMessage({
                    message: t('createQuestionSuccess'),
                    config: TOAST_CONFIG.SUCCESS,
                });
                this.resetState();
                window.location.reload();
            }
        });
    }

    render() {
        const { 
            t,
            auth: { loading: authLoading },
        } = this.props;
        const loading = authLoading;
        
        const { 
            question,
            link,
            answer,
            role,
            roles,
        } = this.state;

        return(
            <Toggle>
            {({ toggled, onToggle, toggleProps }) => {
                const { title, columns, data } = this.getGridSettings(onToggle);
                return(
                    <div className="dashboard">
                        <div className="columns is-multiline">
                            <Fragment>
                            {toggled && toggleProps.edit &&(
                                <Modal onToggle={onToggle}> {loading && <Loading/>}
                                    <Panel headingText={t('savePlatformFaq')}>
                                        <Form
                                            submitText={t('save')}
                                            onSubmit={(event) => {
                                                event.preventDefault();
                                                this.editQuestion();
                                            }}
                                            buttonClasses="mr-8px"
                                        >
                                            <div className="field is-horizontal">
                                                <div className="field-body columns">
                                                    <div className="column">
                                                        <TextInput
                                                            name="question"
                                                            label={t('question')}
                                                            value={question}
                                                            onChange={this.handleInputChange}
                                                            placeholder={t('question')}
                                                            type="text"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="field is-horizontal">
                                                <div className="field-body columns">
                                                    <div className="column">
                                                        <TextInput
                                                            name="link"
                                                            label={t('supportLink')}
                                                            value={link}
                                                            onChange={this.handleInputChange}
                                                            placeholder={t('supportLink')}
                                                            type="text"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="field is-horizontal">
                                                <div className="field-body columns">
                                                    <div className="column">
                                                        <TextArea
                                                            name="answer"
                                                            label={t('answer')}
                                                            value={answer}
                                                            onChange={this.handleInputChange}
                                                            placeholder={t('answer')}   
                                                            controlClasses="is-expanded"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </Form>
                                    </Panel>
                                </Modal>
                                ) 
                            }
                            {toggled && toggleProps.detail && (
                                <Modal onToggle={onToggle}> {loading && <Loading/>}
                                    <Panel headingText={t('question')}>
                                        <Form
                                            submitText={t('accept')}
                                            onSubmit={(event) => {
                                                event.preventDefault();
                                                this.closeModal(onToggle);
                                            }}
                                            buttonClasses="mr-8px"
                                        >
                                            <div className="field is-horizontal">
                                                <div className="field-body columns">
                                                    <div className="column">
                                                        <TextInput
                                                            name="question"
                                                            label={t('question')}
                                                            value={question}
                                                            onChange={this.handleInputChange}
                                                            placeholder={t('question')}
                                                            type="text"
                                                            disabled
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="field is-horizontal">
                                                <div className="field-body columns">
                                                    <div className="column">
                                                        <TextInput
                                                            name="link"
                                                            label={t('supportLink')}
                                                            value={link}
                                                            onChange={this.handleInputChange}
                                                            placeholder={t('supportLink')}
                                                            type="text"
                                                            disabled
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="field is-horizontal">
                                                <div className="field-body columns">
                                                    <div className="column">
                                                        <TextArea
                                                            name="answer"
                                                            label={t('answer')}
                                                            value={answer}
                                                            onChange={this.handleInputChange}
                                                            placeholder={t('answer')}   
                                                            controlClasses="is-expanded"
                                                            disabled
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </Form>
                                    </Panel>
                                </Modal>
                                )
                            }
                            {data && 
                                <div className="column is-full">
                                    <div className="panel-BTNS">
                                        <Link
                                            className="button custom-btn is-primary"
                                            to="/save-faq"
                                            >
                                            <i className="fas fa-plus mr-8px" aria-hidden="true"/>
                                            {t('saveQuestion')}
                                        </Link>
                                    </div>
                                    <Panel headingText={title}>
                                        <div className="field is-horizontal">
                                            <div className="field-body columns">
                                                <div className="column is-one-third">
                                                    <SelectSingle
                                                        name={'role'}
                                                        label={t('rol')}
                                                        selectClasses="is-fullwidth"
                                                        placeholder={t('Select')}
                                                        onChange={this.handleSelectRole}
                                                        options={[
                                                            ...roles.map((item, index) => ({
                                                            label: t(item.name),
                                                            value: index,
                                                            })),
                                                        ]}
                                                        value={role}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        {/* <div className="field is-horizontal">
                                            <div className="field-body columns">
                                                <div className="column is-one-third">
                                                    <Link
                                                        className="button is-primary"
                                                        to="/save-faq"
                                                        >
                                                        <i className="fas fa-plus mr-8px" aria-hidden="true"/>
                                                        {t('saveQuestion')}
                                                    </Link>
                                                </div>
                                            </div>
                                        </div> */}
                                        <TableSimple data={data} columns={columns}/>
                                    </Panel>
                                </div>
                            }
                            </Fragment>
                        </div>
                    </div>
                );
            }}
            </Toggle>
        );
    }


}  

Faq.propTypes = {
    t: PropTypes.func.isRequired,
    getFaq: PropTypes.func.isRequired,
};

const mapStateToProps = ({ support, auth, router }) => (
    {
        auth,
        support,
        router,
    }
);

export default compose(
    connect(mapStateToProps, {
        ...supportActions,
    }),
    withNamespaces(),
)(Faq);