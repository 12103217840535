import { createAction } from 'redux-actions';
import types from './types';

const startFetch = createAction(types.START_FETCH);
const endFetch = createAction(types.END_FETCH);
const endError = createAction(types.END_ERROR);
const saveClients = createAction(types.SAVE_CLIENTS);
const getClients = createAction(types.GET_CLIENTS);
const getClientsConditions = createAction(types.GET_CLIENTS_CONDITIONS);
const approveInvoice = createAction(types.APPROVE_INVOICE);
const deferInvoice = createAction(types.DEFER_INVOICE);
const setClientsConditions = createAction(types.SET_CLIENTS_CONDITIONS);
const getClient = createAction(types.GET_CLIENT);
const editUser = createAction(types.EDIT_USER);
const setClient = createAction(types.SET_CLIENT);
const clientsFileLoad = createAction(types.CLIENTS_FILE_LOAD);
const getDispositionReport = createAction(types.GET_DISPOSITION_REPORT);
const setDispositionReport = createAction(types.SET_DISPOSITION_REPORT);
const createPayment = createAction(types.CREATE_PAYMENT);
const fetchClientsPaymentRecords = createAction(types.FETCH_CLIENTS_PAYMENT_RECORDS);
const fetchBanksPaymentRecords = createAction(types.FETCH_BANKS_PAYMENT_RECORDS);
const savePaymentRecords = createAction(types.SAVE_PAYMENT_RECORDS);
const saveCurrentPayment = createAction(types.SAVE_CURRENT_PAYMENT);
const fetchPaymentDetail = createAction(types.FETCH_PAYMENT_DETAIL);
const editPayment = createAction(types.EDIT_PAYMENT);
const deletePayment = createAction(types.DELETE_PAYMENT);
const fetchPaymentLog = createAction(types.FETCH_PAYMENT_LOG);
const savePaymentLog = createAction(types.SAVE_PAYMENT_LOG);

const showMessage = createAction(types.MESSAGE,
  ({ message }) => ({
    message,
  }),
  ({ config }) => ({
    config,
  }));

export default {
  startFetch,
  endFetch,
  endError,
  saveClients,
  showMessage,
  getClients,
  getClientsConditions,
  approveInvoice,
  deferInvoice,
  setClientsConditions,
  getClient,
  editUser,
  setClient,
  clientsFileLoad,
  getDispositionReport,
  setDispositionReport,
  createPayment,
  fetchClientsPaymentRecords,
  fetchBanksPaymentRecords,
  savePaymentRecords,
  saveCurrentPayment,
  fetchPaymentDetail,
  editPayment,
  deletePayment,
  fetchPaymentLog,
  savePaymentLog
};
