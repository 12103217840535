/**
 * Created by mariale on 24/10/18.
 */
import React, { Component } from 'react';
import './styles.scss';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';
import { compose } from 'redux';
import { transactionsActions } from '../../state/ducks/transactions';
import { ExtractDetail, ExtractForm } from './components';
import { Panel } from '../../components';
import { TOAST_CONFIG } from '../../config/constants';

class Extract extends Component {
  constructor(props) {
    super(props);
    this.state = {
      product: 'AR$',
      transaction_type: 'withdrawal',
      transaction_amount: '',
      qrData: null,
    };
  }

  componentDidMount() {
    const { checkBalance } = this.props;
    checkBalance();
  }

  handleInputChange = (event) => {
    const {
      target: {
        checked, value: targetValue, type, name,
      },
    } = event;
    const value = type === 'checkbox' ? checked : targetValue;
    this.setState({
      [name]: value,
    });
  };

  handleFinish = () => {
    const { checkBalance } = this.props;
    this.setState(
      () => ({
        product: 'AR$',
        transaction_type: 'withdrawal',
        transaction_amount: 1000,
        qrData: null,
      }),
    );
    checkBalance();
  };

  handleSubmit = (event) => {
    event.preventDefault();
    const {
      extractQr, auth: {
        user: {
          document_type,
          document_number,
          name,
          lastname,
          email,
          phone_number,
          phone,
          country_code,
          id_person,
        },
      },
      transactions: { balance },
    } = this.props;
    const {
      transaction_type, transaction_amount, product,
    } = this.state;
    if (parseFloat(transaction_amount) <= parseFloat(balance)) {
      extractQr({
        data: {
          transaction_type,
          transaction_amount,
          transaction_metadata: +new Date() + id_person,
          source_code: country_code,
          source_phone: phone_number,
          product,
        },
        callback: response => this.setState({
          qrData: {
            product: response.product,
            name: `${name} ${lastname}`,
            email,
            phone_number,
            country_code,
            cellphone: phone,
            type: document_type,
            document: document_number,
            operation: 'withdrawal',
            amount: response.transaction_amount,
            time: +new Date(),
            id: id_person + new Date(),
          },
        }),
      });
    } else {
      const { showMessage, t } = this.props;
      showMessage({
        message: t('invalidAmount'),
        config: TOAST_CONFIG.INFO,
      });
    }
  };

  render() {
    const {
      transactions: {
        currency,
        balance,
      },
      auth: {
        account: {
          id,
          name,
          account_number,
        },
      },
    } = this.props;
    const { qrData } = this.state;
    return (
      <div className="Extract">
        <Panel>
          <table className="table">
            <tbody>
              <tr key={id}>
                <td>
                  {name}
                  <br />
                  <span className="text-verde">{account_number}</span>
                </td>
                <td>{`${currency} ${balance}`}</td>
              </tr>
            </tbody>
          </table>
        </Panel>
        {
          qrData !== null
            ? (
              <ExtractDetail qrData={qrData} onFinish={this.handleFinish} />
            )
            : (
              <ExtractForm
                balance={balance}
                onChange={this.handleInputChange}
                onSubmit={this.handleSubmit}
              />
            )
        }
      </div>
    );
  }
}

Extract.propTypes = {
  extractQr: PropTypes.func.isRequired,
  auth: PropTypes.shape(Object).isRequired,
  transactions: PropTypes.shape(Object).isRequired,
  t: PropTypes.func.isRequired,
  showMessage: PropTypes.func.isRequired,
  checkBalance: PropTypes.func.isRequired,
};

const mapStateToProps = ({ transactions, auth }) => ({
  transactions,
  auth,
});

export default compose(connect(mapStateToProps, { ...transactionsActions }), withNamespaces())(
  Extract,
);
