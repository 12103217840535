import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import PropTypes from 'prop-types';
import { Form, Loading, Panel, SelectSingle, TextInput, } from '../../components';
import { TOAST_CONFIG } from '../../config/constants';
import { transactionsActions } from '../../state/ducks/transactions';


class RetryPayment extends Component {
  constructor(props) {
    super(props);
    this.state = { ...this.getInitialState() };
  }

  getInitialState = () => ({
    retryPayment: [],
    optionTense: [
      "minute", "hour"
    ]
  });

  componentDidMount() {
    const {
      getRetryPayment
    } = this.props;
    const {
      retryPayment,
      optionTense,
    } = this.state;

    getRetryPayment({
      callback: (resp) => {
        resp.forEach(function (val, index) {
          retryPayment.push({
            id: index,
            time: val.value,
            timeOption: optionTense.findIndex(function (option) {
              return val.time === option
            }),
          });
        });
      }
    });
  }

  resetState = () => {
    this.setState(this.getInitialState());
  };

  handleInputChange = (event) => {
    const {
      target: {
        checked, value: targetValue, type, name,
      },
    } = event;
    const value = type === 'checkbox' ? checked : targetValue;

    const information = name.split('-');

    let retryPayment = [...this.state.retryPayment];

    switch (information[0]) {
      case 'time':
        retryPayment[information[1]] = {
          id: Number(information[1]),
          time: value,
          timeOption: retryPayment[information[1]].timeOption,
        };
        break;
      case 'timeOption':
        retryPayment[information[1]] = {
          id: Number(information[1]),
          time: retryPayment[information[1]].time,
          timeOption: retryPayment[information[1]].timeOption,
        };
        break;
    }
    this.setState({ retryPayment });
  };

  addExecutive = () => {
    const {
      retryPayment,
    } = this.state;
    const {
      showMessage,
      t,
    } = this.props;
    let count,
      i = 1,
      variable;
    count = retryPayment.length + i;

    if ( parseInt(count) <= 10 ) {
      if (retryPayment[retryPayment.length - 1].timeOption === -1 ||
        retryPayment[retryPayment.length - 1].time === '') {
        showMessage({
          message: <strong style={{ color: '#ffffff' }}>{t('emptyFields')}</strong>,
          config: TOAST_CONFIG.ERROR,
        });
      } else {
        variable = {
          id: count,
          time: '',
          timeOption: -1,
        };
        this.state.retryPayment.push(variable);
      }
      this.setState({
        countCycleCard: count
      });
    } else {
      showMessage({
        message: <strong style={{ color: '#ffffff' }}>{t('cannotAddMore')}</strong>,
        config: TOAST_CONFIG.ERROR,
      });
    }
  };

  deleteExecutive = (index) => {
    const {
      retryPayment,
    } = this.state;
    this.setState(retryPayment.splice(index, 1));
  };

  registerExecutive = () => {
    const {
      retryPayment,
      optionTense,
    } = this.state;

    const {
      t,
      history,
      showMessage,
      minuteSettlePayment,
    } = this.props;
    let error = 0;
    let retries = [];

    retryPayment.forEach(function (option, index) {
      if ( optionTense[option.timeOption] === 'hour' ) {
        if ( option.time < 1 ||option.time > 24 ) {
          showMessage({
            message: t('errorInTheRow',{ row: (index + 1), error: option.time < 1 ? t('errorHour1') : t('errorHour') }),
            config: TOAST_CONFIG.ERROR,
          });
          error = 1;
          return;
        }
      }
      if ( optionTense[option.timeOption] === 'minute' ){
        if ( option.time < 3 || option.time > 60 ) {
          showMessage({
            message: t('errorInTheRow',{ row: (index + 1), error: option.time < 3 ? t('errorMinute1') : t('errorMinute') }),
            config: TOAST_CONFIG.ERROR,
          });
          error = 1;
          return;
        }
      }
      if ( optionTense[option.timeOption] === -1 || option.time === '' ) {
        showMessage({
          message: <strong style={{ color: '#ffffff' }}>{t('emptyFields')}</strong>,
          config: TOAST_CONFIG.ERROR,
        });
        error = 1;
        return;
      }
      if ( error !== 1) {
        retries.push({
          retry: index + 1,
          minutes: optionTense[option.timeOption] === 'hour' ? (parseInt(option.time) * 60)
            : parseInt(option.time),
        });
      }
    });

    if ( error !== 1) {
      const data = {
        retries,
      };
      minuteSettlePayment({
        data,
        callback: () => {
          showMessage({
            message: t('retriesLoaded'),
            config: TOAST_CONFIG.SUCCESS,
          });
          history.push('/dashboard');
        }
      });
    }
  };

  handleSelectChange = (newVal, label) => {
    const {
      optionTense,
    } = this.state;
    const {
      t,
      showMessage,
    } = this.props;

    const cycles1 = label.name.split('-');
    const index = cycles1[1];

    let retryPayment = [...this.state.retryPayment];

    if ( optionTense[newVal.value] === 'hour' ) {
      if ( retryPayment[index].time < 1 || retryPayment[index].time > 24 ) {
        showMessage({
          message: retryPayment[index].time < 1 ? t('errorHour1') : t('errorHour'),
          config: TOAST_CONFIG.ERROR,
        });
        return;
      }
    }
    if ( optionTense[newVal.value] === 'minute' ){
      if ( retryPayment[index].time < 3 || retryPayment[index].time > 60 ) {
        showMessage({
          message: retryPayment[index].time < 3 ? t('errorMinute1') : t('errorMinute'),
          config: TOAST_CONFIG.ERROR,
        });
        return;
      }
    }

    retryPayment[index] = {
      id: index,
      time: retryPayment[index].time,
      timeOption: newVal.value,
    };

    this.setState({ retryPayment });
  };



  render() {
    const {
      optionTense, retryPayment
    } = this.state;
    const {
      t,
      history,
      transactions: { loading },
    } = this.props;

    return (

      <div>
        {loading && <Loading />}
        <div className="columns">
          <div className="column is-full">
            <Panel headingText={t('retries') } usedInModals>
              <Form
                submitText={t('save')}
                onSubmit={(event) => {
                  event.preventDefault();
                  this.registerExecutive();
                }}
                buttonClasses="mr-8px is-hidden"
              >
                <ul>
                  {retryPayment.map((val, index) => (
                    <li>
                      <div className="field is-horizontal">
                        <div className="field-body columns">
                          <div className="column">
                            <div className="field is-horizontal subtitle is-4 text-black">
                              {t('retry',{ cant: (index + 1) })}
                            </div>
                          </div>
                          <div className="column">
                            <TextInput
                              id={'time'}
                              name={'time-' + index}
                              label={t('tense')}
                              value={val.time}
                              onChange={this.handleInputChange}
                              placeholder={t('tense')}
                              type="number"
                              min={1}
                              step={1}
                              controlClasses="is-expanded"
                              isRequired={true}
                            />
                          </div>
                          <div className="column">
                            <SelectSingle
                              name={'timeOption-' + index}
                              label={t('SelectOption')}
                              placeholder={t('SelectOption')}
                              onChange={this.handleSelectChange}
                              options={[
                                ...optionTense.map((item, index) => ({
                                  label: t(item),
                                  value: index,
                                })),
                              ]}
                              selectClasses="is-fullwidth"
                              controlClasses="has-icons-left"
                              icon="far fa-user"
                              iconPosition="is-left"
                              value={val.timeOption}
                              isDisabled={val.time === ''}
                            />
                          </div>
                          <div style={{ marginTop: '36px', }}>
                            {index === retryPayment.length - 1 ?
                              <button
                                className="button is-info is-outlined is-pulled-right"
                                type="button"
                                onClick={this.addExecutive}
                                //disabled={index === banking_executive.length - 1 ? false : true}
                              >
                                <i className="fas fa-plus-circle"/>
                              </button>
                              :
                              <button
                                className="button is-danger is-inverted"
                                type="button"
                                onClick={this.deleteExecutive.bind(this, index)}
                              >
                                <i className="fas fa-minus-circle mr-10px"/>
                              </button>
                            }
                          </div>
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
                <br/>
                <div className="field is-grouped is-grouped-centered"
                     style={{ width: '50%', margin: '30px auto' }}>
                  <button
                    type="button"
                    className="button custom-btn is-danger"
                    style={{ color: '#C4C4C4', flex: '1', maxWidth: '200px', margin: '0 10px' }}
                    onClick={() => {
                      history.push('/dashboard');
                    }}
                  >
                    {t('cancel')}
                  </button>
                  &nbsp;&nbsp;
                  <button
                    type="button"
                    className="button custom-btn is-primary"
                    style={{ flex: '1', maxWidth: '200px', margin: '0 10px' }}
                    onClick={() => {
                      this.registerExecutive();
                    }}
                  >
                    {t('save')}
                  </button>
                </div>
              </Form>
            </Panel>
          </div>
        </div>
      </div>

    );
  }
}

RetryPayment.propTypes = {
  history: PropTypes.shape(Object).isRequired,
  showMessage: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};


const mapStateToProps = ({ transactions, }) => ({
  transactions,
});
export default compose(
  connect(mapStateToProps, {
    ...transactionsActions,
  }),
  withNamespaces(),
)(RetryPayment);