/**
 * Created by Nelson on 29/10/18.
 */

import React from 'react';
import { withNamespaces } from 'react-i18next';
import PropTypes from 'prop-types';
import { Card, Form, Panel, SelectSingle, TextInput, } from '../index';
import DateInput from '../DateInput';
import { getPath } from '../../util';
import './styles.scss';

const LoadInvoicesForm = (props) => {
  const {
    t,
    disabled,
    formData,
    handleInputChange,
    handleFileChange,
    handleDateChange,
    handleClientChange,
    invoice_amount,
    invoiceDate,
    description,
    invoiceNumber,
    clients,
    selectedClient,
    onSubmit,
    file,
    fileName,
    xmlFile,
    xmlFileName,
    handleOnInputChange,
    inputValue,
    onToggle,
    type,
    handleSelectChange,
    typeList,
    descriptionCommerceCode,
    commerceCodes,
    commerceCode,
    handleSelectCommerce,
    currency,
    handleXMLChange,
    messageDate,
    user_type,
  } = props;
  return (
    <div className="columns">
      <div className="column is-full">
        <Panel headingText={t('loadInvoice')}>
          <Form
            submitText={t('send')}
            onSubmit={(event) => {
              event.preventDefault();
              onSubmit(formData);
            }}
            buttonClasses="mr-8px"
            disabled={disabled}
            >
            {user_type === 'NCL' &&
              <div className='terms_box1 m-16px'>
                <h2 className='has-text-link'>
                  {t('nclInvoiceNotification')}
                </h2>
              </div>
            }
            <div className="field is-horizontal">
              <div className="field-body columns">
              <div className="column is-two-third">
                  <DateInput
                    name="invoice_date"
                    label={t('invoiceDate')}
                    value={invoiceDate}
                    onChange={handleDateChange}
                    type="text"
                    fieldClasses="is-expanded"
                    controlClasses="has-icons-left"
                    icon="fas fa-calendar-alt"
                    iconPosition="is-left"
                    dateFormat="dd/MM/yyyy"
                    disabled={true}
                  />
                  <button
                    className="button is-primary is-inverted"
                    type="button"
                    onClick={messageDate.bind(this, onToggle)}
                  >
                    <i className="fas fa-info" aria-hidden="true"/>
                  </button>
                  <strong>
                    <span className="texto-file" style={{ color: '#A6192E' }}>{t('dateTheInvoice')}</span>
                  </strong>
                </div>
              </div>                
            </div>
            <div className="field is-horizontal">
              <div className="field-body columns">
                <div className="column is-two-third">
                  <TextInput
                    name="invoice_number"
                    type="text"
                    label={t('invoicesNumber')}
                    value={invoiceNumber === null ? '' : invoiceNumber}
                    onChange={handleInputChange}
                    placeholder={t('invoicesNumber')}
                    maxlength="60"
                    controlClasses="is-expanded"
                  />
                </div>
              </div>                
            </div>
            <div className="field is-horizontal">
              <div className="field-body columns">
                <div className="column is-two-third">
                  <TextInput
                    name="invoice_amount"
                    label={t('amount')}
                    value={invoice_amount === null ? '' : invoice_amount}
                    onChange={handleInputChange}
                    placeholder="00,00"
                    title={t('amount')}
                    type="text"
                    controlClasses="is-expanded"
                    min="0.10"
                    max="999999999999999.99"
                    currency={currency !== '' ? currency : ''}
                  />
                </div>
              </div>
            </div>
            <div className="field is-horizontal">
              <div className="field-body columns">
                <div className="column is-one-third select-lead-require">
                  <div className="width-select-lead-require">
                    <SelectSingle
                      name="client"
                      label={t('customer')}
                      placeholder={t('SelectOption')}
                      onChange={handleClientChange}
                      options={ [
                        ...clients.map((client, index) => ({
                          label: `${getPath(['idPerson', 'name'], client)} - ${getPath(['idPerson', 'idDocumentType', 'document_type'], client)} ${getPath(['idPerson', 'document_number'], client)}`,
                          value: index,
                        })),
                      ]}
                      selectClasses="is-fullwidth"
                      controlClasses="has-icons-left"
                      icon="far fa-user"
                      iconPosition="is-left"
                      value={selectedClient}
                      isSearchable
                      onInputChange={handleOnInputChange}
                      inputValue={inputValue}
                    />
                  </div>
                </div>
                <div className="column is-two-third">
                  <TextInput
                    name="description"
                    type="text"
                    label={t('invoiceDescription')}
                    value={description}
                    onChange={handleInputChange}
                    placeholder={t('description')}
                    maxlength="120"
                    controlClasses="is-expanded"
                    inputClasses="has-text-left"
                  />
                </div>
              </div>
            </div>
            <div className="field is-horizontal" style={{ display: 'none' }}>
              <div className="field-body columns">
                <div className="column is-two-third">
                  <SelectSingle
                    name="type"
                    label={t('documentType')}
                    selectClasses="is-fullwidth"
                    placeholder={t('Select')}
                    onChange={handleSelectChange}
                    options={[
                      ...typeList.map((item, index) => ({
                        label: `${t(item.description)} `,
                        value: item.type,
                      })),
                    ]}
                    value={type}
                  />
                </div>
              </div>
            </div>
            {user_type !== 'NCL' &&
              <div className="field is-horizontal">
                <div className="field-body columns">
                  <div className="column is-one-third select-lead-require">
                    <div className="width-select-lead-require">
                      <SelectSingle
                        name="commerceCode"
                        label={t('affiliationNumber')}
                        selectClasses="is-fullwidth"
                        placeholder={t('Select')}
                        onChange={handleSelectCommerce}
                        options={
                          [
                            ...commerceCodes.map((item, index) => ({
                              label: item.commerce_code,
                              value: index,
                            })),
                          ]}
                        value={commerceCode}
                      />
                    </div>
                  </div>
                  <div className="column is-two-third">
                    <TextInput
                      name="descriptionCommerceCode"
                      label={t('commerceCodeDescription')}
                      value={descriptionCommerceCode}
                      onChange={handleInputChange}
                      placeholder={t('description')}
                      type="text"
                      controlClasses="is-expanded"
                      title="Three letter country code"
                      disabled={true}
                    />
                  </div>
                </div>
              </div>
            }
            <div className="field is-horizontal">
              <div className="field-body columns">
                <div className="column">
                  <TextInput
                    name="file"
                    label={t('selectPDF')}
                    value={file}
                    fileName={fileName}
                    onChange={handleFileChange}
                    type="file"
                    accept=".pdf"
                    />
                  <strong> 
                    <span className="texto-file" style={{ color: '#A6192E' }}>{t('allowedFormatsPDF')}</span>
                  </strong>
                </div>
                <div className="column">
                  <TextInput
                    name="xmlFile"
                    label={t('selectXML')}
                    value={xmlFile}
                    fileName={xmlFileName}
                    onChange={handleXMLChange}
                    type="file"
                    accept=".xml"
                  />
                  <strong> 
                    <span className="texto-file" style={{ color: '#A6192E' }}>{t('allowedFormatsXML')}</span>
                  </strong>
                </div>
              </div>
            </div>
            {selectedClient != null
              && (
                <div className="columns">
                  <div className="column is-8-tablet is-offset-2-tablet mt-24px">
                    <Card
                      title={t('customerInformation')}
                      subtitle={`${getPath(['idPerson', 'name'], clients[selectedClient])}`}
                      items={
                      user_type === 'NCL' ?
                      [
                        {
                          name: 'documentNumber',
                          value: `${getPath(['idPerson', 'idDocumentType', 'document_type'], clients[selectedClient])} ${getPath(['idPerson', 'document_number'], clients[selectedClient])}`,
                        }, {
                          name: 'address',
                          value: clients[selectedClient].address_full,
                        }, {
                          name: 'email',
                          value: `${getPath(['idPerson', 'idEmailActive', 'email'], clients[selectedClient])}`,
                        }, 
                        {
                          name: 'arrangedDays',
                          value: clients[selectedClient].paymentConditionAsBuyer[0].days
                        },
                        {
                          name: 'startCountingFrom',
                          value: clients[selectedClient].paymentConditionAsBuyer[0].due_from_approval === 0 ? t('receptionDate') : t('approvalDate')
                        },
                        {
                          name: 'NCLCommission',
                          value: clients[selectedClient].paymentConditionAsBuyer[0].ncl_commission ? clients[selectedClient].paymentConditionAsBuyer[0].ncl_commission : ''
                        }  
                      ]:[
                        {
                          name: 'documentNumber',
                          value: `${getPath(['idPerson', 'idDocumentType', 'document_type'], clients[selectedClient])} ${getPath(['idPerson', 'document_number'], clients[selectedClient])}`,
                        }, {
                          name: 'address',
                          value: clients[selectedClient].address_full,
                        }, {
                          name: 'email',
                          value: `${getPath(['idPerson', 'idEmailActive', 'email'], clients[selectedClient])}`,
                        }, 
                        {
                          name: 'arrangedDays',
                          value: ((commerceCodes.length) > 0 && (commerceCode !== -1)) ? commerceCodes[commerceCode].payment_condition.days+" días" : "" 
                        },
                        {
                          name: 'startCountingFrom',
                          value: ((commerceCodes.length > 0) && (commerceCode !== -1)) ? commerceCodes[commerceCode].payment_condition.due_from_approval === 0 ? t('receptionDate') : t('approvalDate')  : ""
                        },                        
                      ]}
                    />
                  </div>
                </div>
            )}
          </Form>
        </Panel>
      </div>
    </div>
  );
};

LoadInvoicesForm.propTypes = {
  t: PropTypes.func.isRequired,
  handleDateChange: PropTypes.func.isRequired,
  handleClientChange: PropTypes.func.isRequired,
  invoiceAmount: PropTypes.string,
  fileName: PropTypes.string,
  disabled: PropTypes.bool,
  formData: PropTypes.shape({}).isRequired,
  handleInputChange: PropTypes.func.isRequired,
  handleFileChange: PropTypes.func.isRequired,
  clients: PropTypes.arrayOf(PropTypes.object),
  detailAmount: PropTypes.shape({}),
  invoiceDate: PropTypes.instanceOf(Date).isRequired,
  description: PropTypes.string,
  invoiceNumber: PropTypes.string,
  selectedClient: PropTypes.number,
  file: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  inputValue: PropTypes.string,
  handleOnInputChange: PropTypes.func,
};

LoadInvoicesForm.defaultProps = {
  disabled: false,
  clients: [],
  detailAmount: {},
  invoiceAmount: '',
  description: '',
  invoiceNumber: '',
  selectedClient: '',
  file: '',
  fileName: '',
  handleOnInputChange: null,
  inputValue: '',
};


export default withNamespaces()(LoadInvoicesForm);
