import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import { Form, Loading, Modal, Panel, Toggle, } from '../../components';
import { cardActions } from '../../state/ducks/card';
import TextInput from '../../components/TextInput';
import { TOAST_CONFIG } from '../../config/constants';
import ReactTooltip from 'react-tooltip';

class BusinessExecutives extends Component {
  constructor(props) {
    super(props);
    const {
      location: { state },
    } = props;
    this.state = { ...this.getInitialState(), ...state };
  }

  getInitialState = () => ({
    banking_executive: [{
      id: 0,
      name: '',
      lastname: '',
      email: '',
      bank_id: '',
    }
    ],
    file_executives: [],
    file: '',
    binaryFile: '',
  });

  componentDidMount() {
    const {
      t,
      card: { fileExecutives },
      router: { location: { pathname } },
    } = this.props;

    if(pathname === "/load-executives"){
      this.setState({banking_executive: fileExecutives});
    }
  };

  handleInputChange = (event) => {
    const {
      target: {
        checked, value: targetValue, type, name,
      },
    } = event;
    const {
      auth: { user: { b2b: { id } } },
    } = this.props;
    const value = type === 'checkbox' ? checked : targetValue;

    const executive = name.split('-');
    let banking_executive = [...this.state.banking_executive];

    switch (executive[0]) {
      case 'name':
        banking_executive[executive[1]] = {
          id: Number(executive[1]),
          name: value,
          lastname: banking_executive[executive[1]].lastname,
          email: banking_executive[executive[1]].email,
          bank_id: id,
        };
        break;
      case 'lastname':
        banking_executive[executive[1]] = {
          id: Number(executive[1]),
          name: banking_executive[executive[1]].name,
          lastname: value,
          email: banking_executive[executive[1]].email,
          bank_id: id,
        };
        break;
      case 'email':
        banking_executive[executive[1]] = {
          id: Number(executive[1]),
          name: banking_executive[executive[1]].name,
          lastname: banking_executive[executive[1]].lastname,
          email: value,
          bank_id: id,
        };
        break;
    }
    this.setState({ banking_executive });
  };

  addExecutive = () => {
    const {
      banking_executive,
    } = this.state;
    const {
      auth: { user: { b2b: { id } } },
      showMessage,
      t,
    } = this.props;
    let count = 0,
      i = 1,
      variable;
    count = banking_executive.length + i;

    if (banking_executive[banking_executive.length - 1].name === '' ||
      banking_executive[banking_executive.length - 1].lastname === '' ||
      banking_executive[banking_executive.length - 1].email === '') {
      showMessage({
        message: <strong style={{ color: '#ffffff' }}>{t('emptyAll')}</strong>,
        config: TOAST_CONFIG.ERROR,
      });
    } else {
      variable = {
        id: count,
        name: '',
        lastname: '',
        email: '',
        bank_id: id,
      };
      this.state.banking_executive.push(variable);
    }

    this.setState({
      countCycleCard: count
    });
  };

  deleteExecutive = (index) => {
    const {
      banking_executive,
    } = this.state;
    this.setState(banking_executive.splice(index, 1));
  };

  registerExecutive = () => {
    const {
      banking_executive,
    } = this.state;
    const {
      t,
      history,
      showMessage,
      registerExecutive,
      auth: { user: { b2b: { id } } },
      router: { location: { pathname } },
    } = this.props;

    let variable = [];

    banking_executive.forEach(function (banking) {
      if (banking.name !== '' && banking.lastname !== '' && banking.email !== '') {
        variable.push(banking);
      }
    });

    if(pathname === "/load-executives"){
      banking_executive.forEach((val, index) => {
        banking_executive[index].bank_id = id;
      });
    }

    const data = {
      banking_executive,
    };
    registerExecutive({
      data,
      callback: (data) => {
        showMessage({
          message: <strong style={{ color: '#ffffff' }}>{t(data.message)}</strong>,
          config: TOAST_CONFIG.SUCCESS,
        });
        history.replace('/banking-executives');
      },
    });
  };

  handleFileChange = (event) => {
    const {
      target: {
        name,
      },
    } = event;
    const {
      t,
      showMessage,
    } = this.props;
    const files = Array.from(event.target.files);
    const arrays = files[0].name;
    let ext = arrays.split('.');

    if (name === 'file') {
      if (ext[1] === 'xls' || ext[1] === 'xlsx') {
        this.setState({
          binaryFile: files[0],
          file: event.target.value,
        });
      } else {
        showMessage({
          message: t('errorLoad', { type: 'xlsx' }),
          config: TOAST_CONFIG.WARNING,
        });
      }
    }
  };

  registerExecutiveFile = () => {
    const {
      t,
      history,
      showMessage,
      auth: {
        user: {
          b2b: {
            id,
          },
        },
      },
      loadExecutivesFile
    } = this.props;

    const {
      binaryFile,
    } = this.state;
  
    const data = {
      file_executives: binaryFile,
    };

    loadExecutivesFile({
      data,
      callback: (response) => {
        history.push('/load-executives');
        this.resetState();
      },
    });
  };

  render() {
    const {
      banking_executive, file, fileName,
    } = this.state;
    const {
      t,
      history,
      card: { loading, fileExecutives },
      router: { location: { pathname } },
    } = this.props;

    return (
      <div>
        {loading && <Loading/>}
        <Toggle>
          {({ toggled, onToggle, toggleProps }) => (
            <Fragment>
              {
                toggled
                && (
                  <Modal onToggle={onToggle} title={t('createExecutive')}>
                    <div style={{ marginTop: '25px' }}>
                      <Form
                        submitText={t('save')}
                        onSubmit={(event) => {
                          event.preventDefault();
                          this.registerExecutiveFile();
                        }}
                        buttonClasses="mr-8px"
                        className='no-background'
                      >
                        <div className="field is-horizontal">
                          <div className="field-body columns">
                            <div className="column is-full">
                              <TextInput
                                name="file"
                                label={t('selectFile')}
                                value={file}
                                fileName={fileName}
                                onChange={this.handleFileChange}
                                // placeholder="Suba un documento"
                                type="file"
                                // fieldClasses="file has-name"
                                //  labelClasses="file-label"
                                //  controlClasses="is-expanded has-icons-left file-label"
                                //  inputClasses="has-text-right "
                                //  iconPosition="is-left"
                                //  icon="fas fa-upload"
                              />
                              <span className="texto-file">{t('allowedFormatsXLS')}</span>
                            </div>
                          </div>
                        </div>
                      </Form>
                    </div>
                  </Modal>
                )
              }
              {pathname === '/create-banking-executive' &&
              <Panel headingText={t('createExecutive')}>
                <Form
                  submitText={t('save')}
                  onSubmit={(event) => {
                    event.preventDefault();
                    this.registerExecutive();
                  }}
                  buttonClasses="mr-8px is-hidden"
                >
                  <div className="field is-horizontal subtitle is-5 text-black">
                    {t('executiveData')}
                  </div>
                  <ul>
                    {banking_executive.map((val, index) => (
                      <li>
                        <div className="field is-horizontal">
                          <div className="field-body columns">
                            <div className="column">
                              <TextInput
                                id={'name'}
                                name={'name-' + index}
                                label={t('name')}
                                value={val.name}
                                onChange={this.handleInputChange}
                                placeholder={t('firstAndSecondName')}
                                type="text"
                                controlClasses="is-expanded"
                                isRequired={true}
                              />
                            </div>
                            <div className="column">
                              <TextInput
                                id={'lastname'}
                                name={'lastname-' + index}
                                label={t('adminLastname')}
                                value={val.lastname}
                                onChange={this.handleInputChange}
                                placeholder={t('lastName')}
                                type="text"
                                controlClasses="is-expanded"
                                isRequired={true}
                              />
                            </div>
                            <div className="column" data-tip={t('uniqueEmail')}>
                              <TextInput
                                id={'email'}
                                name={'email-' + index}
                                label={t('email')}
                                value={val.email}
                                onChange={this.handleInputChange}
                                placeholder={t('emailExample')}
                                type="text"
                                controlClasses="is-expanded"
                                isRequired={true}
                              />
                              <ReactTooltip />
                            </div>
                            <div style={{ marginTop: '36px', }}>
                              {index === banking_executive.length - 1 ?
                                <button
                                  className="button is-info is-outlined is-pulled-right"
                                  type="button"
                                  onClick={this.addExecutive}
                                  //disabled={index === banking_executive.length - 1 ? false : true}
                                >
                                  <i className="fas fa-plus-circle"/>
                                </button>
                                :
                                <button
                                  className="button is-danger is-inverted"
                                  type="button"
                                  onClick={this.deleteExecutive.bind(this, index)}
                                >
                                  <i className="fas fa-minus-circle mr-10px"/>
                                </button>
                              }
                            </div>
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                  <br/>
                  <div className="field is-horizontal">
                    <div className="field-body columns">
                      <div className="column">
                        <button
                          className="button custom-btn is-success"
                          type="button"
                          onClick={() => onToggle({})}
                        >
                          {t('loadFromFile')}
                        </button>
                      </div>
                    </div>
                  </div>
                  <div 
                    className="field is-grouped is-grouped-centered"
                    style={{ width: '50%', margin: '30px auto' }}>
                      <button
                        type="button"
                        className="button custom-btn is-danger"
                        style={{ color: '#C4C4C4', flex: '1', maxWidth: '200px', margin: '0 10px' }}
                        onClick={() => {
                          history.push('/dashboard');
                        }}
                      >
                        {t('cancel')}
                      </button>
                      <button
                        type="submit"
                        className="button custom-btn is-primary"
                        style={{ flex: '1', maxWidth: '200px', margin: '0 10px' }}
                      >
                        {t('save')}
                      </button>
                  </div>
                </Form>
              </Panel>
              }
              { pathname === "/load-executives" &&
                <Panel headingText={t('createExecutive')}>
                  <Form
                    submitText={t('save')}
                    onSubmit={(event) => {
                      event.preventDefault();
                      this.registerExecutive();
                    }}
                    buttonClasses="mr-8px"
                  >
                  <div className="field is-horizontal subtitle is-5 text-black">
                    {t('executiveData')}
                  </div>
                    <ul>
                    {banking_executive.map((val, index) => (
                      <li>
                        <div className="field is-horizontal">
                          <div className="field-body columns">
                            <div className="column">
                              <TextInput
                                id={'name'}
                                name={'name-' + index}
                                label={t('name')}
                                value={val.name}
                                onChange={this.handleInputChange}
                                placeholder={t('name')}
                                type="text"
                                controlClasses="is-expanded"
                                isRequired={true}
                              />
                            </div>
                            <div className="column">
                              <TextInput
                                id={'lastname'}
                                name={'lastname-' + index}
                                label={t('adminLastname')}
                                value={val.lastname}
                                onChange={this.handleInputChange}
                                placeholder={t('adminLastname')}
                                type="text"
                                controlClasses="is-expanded"
                                isRequired={true}
                              />
                            </div>
                            <div className="column">
                              <TextInput
                                id={'email'}
                                name={'email-' + index}
                                label={t('email')}
                                value={val.email}
                                onChange={this.handleInputChange}
                                placeholder={t('email')}
                                type="text"
                                controlClasses="is-expanded"
                                isRequired={true}
                              />
                            </div>
                          </div>
                        </div>
                      </li>
                    ))}
                    </ul>
                  </Form>
                </Panel>
                }
            </Fragment>
          )}
        </Toggle>
      </div>
    );
  }
}

BusinessExecutives.propTypes = {
  t: PropTypes.func.isRequired,
  registerExecutive: PropTypes.func.isRequired,
};

const mapStateToProps = ({ card, auth, router}) => (
  {
    auth,
    card,
    router,
  }
);

export default compose(
  connect(mapStateToProps, {
    ...cardActions,
  }),
  withNamespaces(),
)(BusinessExecutives);