const START_FETCH = 'userProfile/START_FETCH';
const END_FETCH = 'userProfile/END_FETCH';
const UPDATE_USER_PROFILE = 'userProfile/UPDATE_USER_PROFILE';
const API_CALL = 'userProfile/API_CALL';
const MESSAGE = 'userProfile/MESSAGE';
const FETCH_USER_PROFILE = 'userProfile/FETCH_USER_PROFILE';
const EDIT_USER_PROFILE = 'userProfile/EDIT_USER_PROFILE';

export default {
  START_FETCH,
  END_FETCH,
  UPDATE_USER_PROFILE,
  API_CALL,
  MESSAGE,
  FETCH_USER_PROFILE,
  EDIT_USER_PROFILE,
};