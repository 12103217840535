import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';
import { withNamespaces } from 'react-i18next';
import { Form, Panel, TextArea } from '../../../../components';
import TextInput from '../../../../components/TextInput';

const FormReject = (props) => {
  const {
    t,
    onSubmit,
    handleInputChange,
    data,
    isEdit,
    handleFileChange,
    file,
    binaryFile,
    type,
    type_user,
    password,
    invoiceNCL,
  } = props;
  const { rejectReason } = data;
  return (
    <div className="columns">
      <div className="column is-full">
    <Panel /*headingText={type_user === 'seller' ? t('rejectReason') : t('digitalCertificate')}*/ usedInModals>

        <Form
          className="no-background"
          submitText={type === 'delete' ? t('invoiceReject') : t('approveInvoice')}
          onSubmit={(event) => {
            event.preventDefault();
            onSubmit();
          }}
          disabled={!(rejectReason !== '')}
          buttonClasses={`${isEdit ? '' : ' is-hidden'}`}
          isBTNFull
        >
          <div className="field is-horizontal">
            <div className="field-body columns">


          <div style={{ display: (type_user === 'seller' ? 'none' : 'none') }}>
            <TextInput
              name="file"
              value={file}
              fileName={binaryFile !== '' ? binaryFile.name : binaryFile}
              onChange={handleFileChange}
              placeholder={t('digitalCertificate')}
              type="file"
              fieldClasses="file has-name"
              labelClasses="file-label"
              controlClasses="is-expanded has-icons-left file-label"
              inputClasses="has-text-right file-input"
              iconPosition="is-left"
              icon="fas fa-file-upload fa-2x"
              band={true}
            />
            <br/>
            <TextInput
              name="password"
              label={t('passwordDigitalCertificate')}
              placeholder={t('password')}
              type="password"
              onChange={handleInputChange}
              value={password}
            />
          </div>
          {/*
            <TextInput
              name="file"
              label={t('SelectDocumentType')}
              value={file}
              fileName={fileName}
              onChange={handleFileChange}
              // placeholder="Suba un documento"
              type="file"
              // fieldClasses="file has-name"
              //  labelClasses="file-label"
              //  controlClasses="is-expanded has-icons-left file-label"
              //  inputClasses="has-text-right "
              //  iconPosition="is-left"
              //  icon="fas fa-upload"
            />
          */}
          <div className="column" style={{ display: (type === 'delete' ? '' : 'none') }}>
            <TextArea
              name="rejectReason"
              value={rejectReason}
              onChange={handleInputChange}
              label={invoiceNCL ? '' : t('rejectReason')}
              placeholder={t('rejectReason')}
              controlClasses="is-expanded"
              maxLength="120"
              rows="3"
              cols="33"
              disabled={!isEdit}
            />
          </div>

            </div></div>
        </Form>

    </Panel>
      </div></div>
  );
};

FormReject.propTypes = {
  t: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  handleInputChange: PropTypes.func.isRequired,
  isEdit: PropTypes.bool.isRequired,
  data: PropTypes.shape({
    rejectReason: PropTypes.string.isRequired,
  }).isRequired,
};

export default withNamespaces()(FormReject);
