/**
 * Created by Feedback Software on 24/10/18.
 * @param children contain the body of this table, it's important use th or td inside tr
 * @param totalPages if is pass by params it means that must be added the pagination nav
 * @param title it is used by pass by params to the Panel for the title of this
 * @param head contain the head of the table, it's important pass th inside tr
 * @param currentPage manage de actual page in pagination
 * @param onChangePage manage the change of the page in the pagination, must change currentPage
 */

import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import { Modal, TableList, Toggle } from '../../components';
import { transactionsActions } from '../../state/ducks/transactions';
import { authActions } from '../../state/ducks/auth';
import { TransferDetail, TransferForm } from './components';
import { TOAST_CONFIG } from '../../config/constants';
import './styles.scss';

class Transfer extends Component {
  constructor(props) {
    super(props);

    const { t, transactions: { currency } } = props;
    this.state = {
      transaction_type: 'transfer',
      destination_phone_number: '',
      amount: '',
      product: currency,
      transfer_date: Date.now(),
      detail: '',
      options: [
        {
          text: t('extractAccount'),
          value: 1,
        },
      ],
    };
  }

  componentDidMount() {
    const {
      getContacts,
      authorizeTransaction,
      checkBalance,
    } = this.props;
    getContacts();
    authorizeTransaction();
    checkBalance();
  }

  handleInputChange = (event) => {
    const {
      target: {
        checked, value: targetValue, type, name,
      },
    } = event;
    const value = type === 'checkbox' ? checked : targetValue;
    this.setState({
      [name]: value,
    });
  };

  handleSubmit = (event, total) => {
    event.preventDefault();
    const { ...data } = this.state;
    const { transfer, transactions: { balance } } = this.props;

    if (parseFloat(total) <= parseFloat(balance)) {
      transfer({
        data,
        callback: (detailTransfer) => {
          this.setState(() => ({
            detailTransfer,
          }));
        },
      });
    } else {
      const { showMessage, t } = this.props;
      showMessage({
        message: t('invalidAmount'),
        config: TOAST_CONFIG.INFO,
      });
    }
  };

  onFinish = () => {
    const { checkBalance } = this.props;
    this.setState(() => ({
      detailTransfer: null,
      amount: '',
      destination_phone_number: '',
    }));
    checkBalance();
  };

  validateEmptyFields = () => {
    const { amount, destination_phone_number } = this.state;
    return (amount === ''
      || destination_phone_number === '');
  };

  render() {
    const { t, transactions: { balance, charge }, auth: { contacts } } = this.props;
    const { amount, detailTransfer } = this.state;
    const charge_amount = parseFloat(amount) * (charge / 100);
    const total = parseFloat(amount) + charge_amount;
    const disabledForm = this.validateEmptyFields();

    if (detailTransfer) {
      return (
        <TransferDetail
          detailTransfer={detailTransfer}
          onFinish={this.onFinish}
        />
      );
    }

    return (
      <Toggle>
        {({ toggled, onToggle, toggleProps }) => (
          <Fragment>
            {
              toggled
              && (
                <Modal onToggle={onToggle}>
                  <TableList
                    title={t('confirmTransfer')}
                    head={(
                      <tr>
                        <th>{t('transactionType')}</th>
                        <th>{t('recipient')}</th>
                        <th>{t('amount')}</th>
                        <th>{t('totalAmount')}</th>
                        <th>{t('concept')}</th>
                      </tr>
                    )}
                  >
                    <tr key={toggleProps.text}>
                      <td>{t(`${toggleProps.transaction_type}`)}</td>
                      <td>{toggleProps.destination_phone_number}</td>
                      <td>{`${toggleProps.product} ${toggleProps.amount}`}</td>
                      <td>{`${toggleProps.product} ${total}`}</td>
                      <td>{toggleProps.detail}</td>
                    </tr>
                    <tr>
                      <th colSpan="5">
                        <div className="field is-grouped is-grouped-right">
                          <p className="control">
                            <button
                              type="button"
                              className="button margin-right-5"
                              onClick={onToggle}
                            >
                              {t('goBack')}
                            </button>
                          </p>
                          <p className="control">
                            <button
                              type="button"
                              className="button is-primary"
                              onClick={(event) => {
                                this.handleSubmit(event, total);
                                onToggle();
                              }}
                            >
                              {t('confirm')}
                            </button>
                          </p>
                        </div>
                      </th>
                    </tr>
                  </TableList>
                </Modal>
              )
            }
            <TransferForm
              formData={{ ...this.state }}
              onToggle={onToggle}
              toggle={toggled}
              toggleProps={toggleProps}
              disabled={disabledForm}
              balance={balance}
              handleInputChange={this.handleInputChange}
              contacts={contacts}
              chargeAmount={charge_amount}
              total={total}
            />

          </Fragment>
        )}
      </Toggle>
    );
  }
}

Transfer.propTypes = {
  auth: PropTypes.shape(Object).isRequired,
  transactions: PropTypes.shape(Object).isRequired,
  transfer: PropTypes.func.isRequired,
  authorizeTransaction: PropTypes.func.isRequired,
  checkBalance: PropTypes.func.isRequired,
  getContacts: PropTypes.func.isRequired,
  showMessage: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

const mapStateToProps = ({ transactions }) => (
  {
    transactions,
  }
);

export default compose(
  connect(mapStateToProps, {
    ...transactionsActions,
    ...authActions,
  }),
  withNamespaces(),
)(Transfer);
