/**
 * Created by Jonas on 29/08/19.
*/

import React from 'react';
import PropTypes from 'prop-types';
import Panel from '../Panel';
import Form from '../Form';
import TextInput from '../TextInput';

const RouteForm = ({
  t,
  onSubmit,
  handleInputChange,
  name,
}) => (
  <Panel headingText={t('createRoute')}>
    <Form
      submitText={t('save')}
      onSubmit={(event) => {
        event.preventDefault();
        onSubmit();
      }}
      buttonClasses="mr-8px"
    >
      <div className="field is-horizontal">
        <div className="field-body columns">
          <div className="column is-tree-fifths">
            <TextInput
              name="name"
              label={t('routeName')}
              value={name}
              onChange={handleInputChange}
              placeholder={t('name')}
              type="text"
              controlClasses="is-expanded"
            />
          </div>
        </div>
      </div>
    </Form>
  </Panel>
);

RouteForm.propTypes = {
  t: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  handleInputChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
};

export default RouteForm;
