const pt = {
  translations: {
    accept: 'Ok',
    acceptTerms: 'Aceite os termos e condições do Acordo',
    acceptTermsGateway: 'Aceitamos os encargos ou taxas de serviço indicados na secção Clientes e aceitamos que sejam cobrados pela GETNET por dedução directa das liquidações mensais efectuadas ao ESTABELECIMENTO, conforme indicado no Contrato de Afiliação e no seu Anexo que regula o acesso e uso da plataforma "B2B Enterprise"',
    account: 'Conta',
    accountBalance: 'Saldo da conta',
    accountPlural: 'Contas',
    accumulatedAmount: 'Saldo acumulado diário',
    acquiring_bank: 'Banco Adquirente',
    acquiringBank: 'Adquirente',
    acquiringQuery: 'Adquirente de consulta',
    action: 'Operação',
    active: 'Ativo',
    activeCompanies: 'Empresas ativas',
    activate: 'Ativar',
    addComment: 'Inserir comentário ...',
    addFinanceProductSuccess: 'Produto de financiamento criado com sucesso',
    addFinancingProducts: 'Adicionar produtos de financiamento',
    additionalInformation: 'Informação Adicional',
    addNew: 'Adicionar novo',
    address: 'Endereço',
    admin: 'Administrar',
    administrators: 'Administradores',
    administratorsList: 'Lista de administradores',
    administratorName: 'Nome do administrador',
    administratorBank: 'Banco do Administrador',
    adminRoutes: 'Administrar endereços',
    assignPermissions: 'Atribuir permissões',
    adminUser: 'Usuario Administrador',
    affiliateCode: 'Código de Afiliado',
    affiliateClients: 'Clientes Afiliados',
    affiliateN: 'Número de afiliado',
    authUser: 'Usuário autorizado',
    advance: 'Antecipar',
    advanceCommercialConditions: 'Condições comerciais de anticipo',
    alias: 'Pseudônimo',
    allowedFormats: 'Formatos permitidos: JPG e PNG. Tamanho máximo de 3 MB.',
    amexAffiliationNumber: 'Número de afiliação amex',
    amexCard: 'Cartão de Tesoureiro Amex',
    amount: 'Valor',
    amountInt: 'O valor deve ser um número inteiro',
    amountInstallments: 'Valor das parcelas',
    amountMoney: 'Valor do dinheiro',
    amountOfTransactionsToProcess: 'Quantidade de transações a processar',
    amountAverageTransactionsMonthly: 'Valor médio mensal de transações',
    amountToApprove: 'Saldo a aprovar',
    amountToCollect: 'Extrato diário',
    amountToGet: 'Saldo a receber',
    amountToPay: 'Saldo a pagar',
    annualQuota: 'Cota anual',
    anticipatedAmount: 'Valor Antecipado',
    applicant: 'Candidato',
    applicationDate: 'Data de aplicação',
    approvalStatus: 'Status de aprovação',
    applicantName: 'Nome do candidato',
    applicantBank: 'Banco requerente',
    applicationDeleted: 'Pedido removido',
    applicationOneNotification: 'Você tem {{payment_request_pending}} solicitação',
    applicationNotification: 'Você tem  {{payment_request_pending}} solicitações',
    applicationSuccess: 'Aplicação aprovada com sucesso',
    applicationRejected: 'Aplicação rejeitada com sucesso',
    approvalConditionDate: 'Data de aprovação da condição comerciais',
    approvalConditionDateUserType: 'Data de aprovação de {{userType}}',
    approvalDate: 'Data de aprovação',
    approve: 'Aprovar',
    approved: 'Aprovadas',
    approvedStatus: 'Aprovado',
    approveInvoice: 'Aprovar fatura',
    approvedInvoices: 'Fac. Aprovadas',
    approvedInvoicesTitle: 'Faturas Aprovadas',
    arrangedDays: 'Días acordados',
    asterisk: '*** {{number_card}}',
    assistCard: 'Assist card',
    assign: 'Atribuir',
    assignRoute: 'Atribuir rotas',
    assignedRoutes: 'Número do cartão atribuído',
    assignedCardNumber: 'Assigned card number',
    assignRoleRoute: 'A função e o endereço foram configurados com sucesso',
    assignRouteSuccess: 'O endereço foi configurado com sucesso',
    authorization: 'Autorização',
    available: 'Acessível',
    availableLineApprovedInvoices: 'Linha disponível em faturas aprovadas',
    avance: 'Anticipo',
    balance: 'Saldo',
    bankProvider: 'AMEX',
    bankList: 'Lista de bancos',
    bankLogo: 'Logotipo do banco',
    bankName: 'Nome do banco',
    banks: 'Bancos',
    bankType: 'Tipo de banco',
    baseConditions: 'Condições gerais (Pagamento de Faturas)',
    benefits: 'Benefícioss',
    bills: 'Faturas enviadas',
    billPayment: 'Pagamento de faturas',
    billingVolume: 'Volume de faturamento',
    bin: 'Bin',
    business: 'O negócio',
    businessName: 'Razão social',
    businessExecutive: 'Executivo de negócios',
    businessExecutives: 'Executivo de negócios',
    by_amount: 'Por quantidade',
    by_hierarchy: 'Por hierarquia de posições',
    cardBind: 'Compartimento de cartão',
    cardChargeDate: 'Data de cobrança no cartão Tesoureiro Amex',
    cardholderPaymentPercentage: 'Porcentagem de pagamento do titular do cartão',
    cardPayment: 'TTA selecionado para pagamento',
    cardPaymentDate: 'Data do pagamento com cartão',
    changeOfCommercialConditions: 'Registro de alteração das condições comerciais',
    changeCode: 'Você deve modificar o código comercial que corresponde ao novo tipo de moeda selecionado',
    changeLog: 'Log de alterações',
    charge: 'Cargo',
    chargeDate: 'Data de cobrança',
    chargeForService: 'Cobrar pelo serviço',
    cif: 'CIF',
    cifPattern: 'Deve conter letras e números',
    cancel: 'Cancelar',
    clientName: 'Nome do cliente',
    clientPaymentCondition: 'Determine as condições de pagamento para este',
    code: 'Código',
    codeArea: 'Prefixo + n˚ telefone',
    collaboratorNumber: 'Número do colaborador',
    collaboratorNumberCharacter: 'Número do colaborador, deve ter 8 caracteres',
    company: 'Companhia',
    commerceCode: 'Código de Afiliado',
    updateInfo: 'Atualizar informação',
    commercialBillingUser: 'Usuário de cobrança comercial',
    commercialConditions: 'Condições Comercias',
    commercialCreditDays: 'Dias de crédito comercial',
    commercialCreditSince: 'Crédito comercial desde',
    condition: 'Condição',
    concept: 'Conceito',
    confirm: 'Aceitar',
    confirmInvoiceApprovement: '¿Tem certeza de que deseja aprovar a fatura No. {{ invoiceNu }} no valor de {{ currency}} {{ invoiceAmount }}?',
    confirmInvoiceDecline: '¿Tem certeza de que deseja recusar a fatura No. {{ invoiceNu }} no valor de {{ currency}} {{ invoiceAmount }}?',
    confirmPassword: 'Confirmar senha',
    confirmUnsubscribe: 'Tem certeza de que deseja cancelar a condição comercial com {{client}} e o {{product}} que envolve?',
    confirmUnsubscribeFrom: 'Confirme o cancelamento da inscrição de: {{product}}',
    confirmTaxCode: 'Confirme o Código de Imposto',
    confirmTransfer: 'Confirmar sua operação',
    continue: 'Continuar',
    consult: 'Consultar',
    contractedProducts: 'Produtos contratados',
    createBank: 'Criar novo banco',
    createBankSuccess: 'Banco {{name}} criado com sucesso',
    createClientSuccess: 'Cliente {{name}} criado com sucesso',
    createCondition: 'Criar condição de comércio para: {{name}}',
    createCustomer: 'Criar \nCliente',
    createExecutive: 'Executivo de negócios',
    createInvoiceSuccess: 'A factura N° {{invoice_number}} foi registrada com sucesso',
    createNewItem: 'Criar nova industria "{{inputValue}}"',
    createNewExecutive: 'Criar novo executivo "{{inputValueExecutive}}"',
    createNewManaged: 'Criar novo administrador',
    createProvider: 'Criar \nFornecedor',
    createProviderSuccess: 'Fornecedor {{name}} criado com sucesso',
    createProfile: 'Criar Perfil',
    createProfileSuccess: 'Perfil {{name}} criado com sucesso',
    createRol: 'Criar função',
    createRolSuccess: 'Função {{rolName}} criada com sucesso',
    createUser: 'Criar usuário',
    createUserSuccess: 'usuário {{name}} criada com sucesso',
    createFlowApproval: 'Criar fluxo de aprovação',
    createFlowApprovalSuccess: 'Approval flow, criada com sucesso',
    creditCardSubstitution: 'Substituição de cartão de crédito',
    creditPeriod: 'Período de crédito',
    cuttingCycle: 'Ciclo de corte',
    cuttingCycleAmex: 'Ciclo de corte de cartão tesoureiro AMEX',
    cuttingCycleCard: 'O ciclo de corte do cartão inserido é: {{message}}',
    customer: 'Cliente',
    customerInformation: 'Informação do cliente selecionado',
    customerName: 'Nome do cliente',
    customerPayment: 'Pagamento do cliente',
    customerPaymentPercentage: '% Pagamento do cliente',
    customers: 'Clientes',
    collectionDate: 'Data de coleta',
    cort: 'Corte',
    cortDate: 'Data de corte',
    configuration: 'Configuração',
    changePassword: 'Mudar senha',
    changePasswordSuccess: 'Senha alterada com sucesso',
    city: 'Cidade',
    cycleGroup: 'Grupo de Ciclo',
    cyclesList: 'Lista de ciclos de faturamento',
    ccChargeDate: 'CC charge date',
    insertYourEmailAddress: 'Insira o seu endereço de email',
    enterYourNewPassword: 'Digite sua nova senha',
    registrationStatus: 'Status de registro',
    successEmail: 'Um email foi enviado para o seu endereço de email.',
    successfullyUpdatedManual: 'Manual atualizado com sucesso',
    daily: 'Diário',
    dashboard: 'Sumário',
    date: 'Data',
    dateOfAdmission: 'Data de entrada em Sii',
    dateOfEntry: 'Data de entrada no B2B',
    dateFf: 'Date FF',
    FF: 'FF',
    FV: 'FV',
    dateInvoice: 'Data da fatura',
    dateV: 'Data de vencimento',
    dataConfirmedSuccessfully: 'Dados confirmados com sucesso',
    day: 'Dia',
    daysNumber: 'Número de días',
    daysSince: ' dias desde ',
    defer: 'Diferido',
    defineRejectReason: "Defina o motivo da rejeição",
    decline: 'Declínio',
    deferCommercialConditions: 'Condiciones comerciales de diferimiento',
    deferredAmount: 'Monto Diferido',
    delete: 'Eliminar',
    deleteInvoice: 'A fatura foi excluída',
    deletePaymentCondition: 'A condição de pagamento foi removida',
    deleteTta: 'TTA eliminado com sucesso',
    demographicInformation: 'Dados de identificação',
    deposit: 'Depositar',
    description: 'Descripción',
    destinationAccount: 'Cuenta destino',
    deactivate: 'Desativar',
    detail: 'Detalle',
    digitalCertificate: 'Certificado digital',
    differ: 'Adiado',
    discountRate: 'Taxa de desconto',
    discountRateMDR: 'Taxa de desconto (MDR)',
    discountRateCharges: 'Taxas de desconto cobradas',
    discountRateInvoiceSavedSuccessfully: 'Fatura de taxa de desconto salva com sucesso',
    discountRateOutOfLimits: 'Taxa de desconto fora dos limites',
    dispositionReport: 'Relatório de disposição',
    documentDate: 'Data do documento',
    documentNumber: 'Nro. de documento',
    documentType: 'Tipo de documento',
    document: 'Documento',
    dontPose: 'Não tem',
    doNotIncludeTheUsername: '* Não inclua o nome de usuário como parte da senha.',
    eCommerceTerminal: 'Terminal de comércio eletrônico',
    edit: 'Alterar',
    editBank: 'Editar manual: {{name}}',
    editCycle: 'Editar ciclo',
    editPaymentConditionSuccess: 'Condições de pago editada',
    editClientSuccess: 'Cliente {{name}} editada',
    editProviderSuccess: 'Fornecedor {{name}} editada',
    editProfileSuccess: 'Perfil {{name}} editada',
    editRolSuccess: 'Função {{rolName}} editada com sucesso',
    editUserSuccess: 'Usuário {{name}} editada',
    editionRequestSent: 'Pedido de edição enviado',
    email: 'Correio Electrônico',
    emptyFields: 'Devem ser completados todos os campos.',
    emptyAll: 'Certifique-se de preencher todas as informações',
    emptyAllRequest: 'Certifique-se de preencher todas as informações, dos campos obrigatórios',
    enter: 'Entrar',
    enterCuttingCycle: 'Entrar no ciclo de corte',
    enterDataInternalTaxService: 'Conecte sua empresa ao SII - Receita Federal',
    enterCodeSms: 'Verifique e insira o código\n de segurança enviado por SMS',
    enterTaxCode: 'Insira o Código de Imposto',
    errorDate: 'A data \'Desde\' que deve ser menor',
    errorData: 'Falha ao carregar dados.',
    errorDateCycles: 'A data FF que deve ser menor',
    errorLoad: 'Você deve carregar um arquivo de extensão {{type}}',
    executiveData: 'Dados executivos comerciais',
    expiredInvoices: 'Aprovadas / Pagadas',
    expirationDate: 'Data de expiração',
    extract: 'Sacar',
    extractAccount: 'Saque na rede B2B',
    extractDetail1: 'Codigo de aprobação gerado com sucesso',
    extractDetail2: 'Saldo a retirar',
    extractDetail3: 'Em',
    extractDetail4: 'Completar o saque em um local da nossa rede',
    extractDetail5: 'Com o seu número de telefone',
    extractDetail6: 'Proporcione o numero de telefone ao operador do local para completar o saque iniciado',
    extractDetail7: 'Codigo QR',
    extractDetail8: 'Apresentese con o seguinte codigo no local da nossa rede',
    financingProducts: 'Produtos de \nfinanciamento',
    finish: 'Finalizar',
    fiscaldocument: 'Documento Fiscal',
    flexibleConditions: 'Condições Flexíveis (Financiamento)',
    flowApproval: 'Fluxo de aprovação',
    flowsApproval: 'fluxos de aprovação',
    flowApprovalCheck: 'fluxo de aprovação padrão',
    forgotPassword: '¿Esqueceu a sua senha?',
    friday: 'Sexta-feira',
    fullName: 'Nome completo',
    goBack: 'Voltar',
    greaterThan: 'Major',
    lessThan: 'Menor',
    lessThanOrEqualTo: 'Menos igual',
    general_admin: 'Usuário administrador geral do banco',
    general_admin_issuing: 'Usuário administrador geral do banco emissor',
    general_admin_acquiring: 'Usuário administrador geral do banco adquirente',
    general_admin__issuing_amex: 'Usuário administrador geral do banco emissor AMEX',
    greaterThanOrEqualTo: 'Maior igual',
    group: 'Grupo ',
    handbook: 'Manual',
    fileSize: 'O tamanho máximo permitido é de 3MB',
    fileSizeExceeded: 'Tamanho do arquivo excedido',
    import: 'Quantidade',
    inactive: 'Inativo',
    inactiveBusinesses: 'Negócios inativos',
    inactiveClients: 'Clientes Inativos',
    informationOfTheSelectedBank: 'Informação do banco selecionado',
    issuedInvoices: 'Faturas emitidas',
    inCharge: 'Responsável',
    incorrectDate: 'Você não pode inserir uma data antes, {{dateNow}}',
    incorrectEmail: '{{email}} ñao parece um email válido. Verifique se você tem o formato correto',
    incorrectDays: 'Você deve inserir um valor maior ou igual a 0',
    installmentNumber: 'Número de parcelas',
    installments: 'N° de parcelas',
    intNumber: 'Deve ser um número inteiro',
    interestRate: 'Taxa de juros',
    interestOrdinary: 'Juros ordinários',
    interestLatePayment: 'Juros de mora',
    internalRevenueService: 'Receita Federal',
    invalidAmount: 'Quantidade inválida',
    invalidRoleOrRoute: 'A função e o endereço não podem estar vazios',
    invoiceCount: 'Número de Faturas',
    invoiceDate: 'Data da fatura',
    invoice: 'Factura',
    invoices: 'Faturas',
    invoicesNumber: 'Número de fatura',
    invoiceNumber: 'Fatura nº',
    invoiceTotalAmount: 'Valor em Faturas',
    invoiceDetail: 'Detalhe de fatura',
    invoiceIssueDate: 'Data de emissão da fatura',
    invoiceN: 'Fatura N° {{invoiceNumber}}',
    invoicesToApproved: 'Contas a aprovar',
    invoicesCollectiontDate: 'Data de cobrança das faturas',
    invoicesPaymentDate: 'Data de pagamento das faturas',
    invoicePending: 'Emitir',
    invoiceReject: 'Rejeitar fatura',
    invoiceProvidersReceiver: 'Fatura de recebimento',
    invoiceManualResolution: 'Faturas que exigem resolução manual',
    invoiceMarkPaid: 'A fatura nº {{invoiceNumber}} foi marcada como paga',
    invoiceStatusInTheSii: 'Status da fatura no SII',
    invoiceUploaded: 'Fatura N° {{invoiceNumber}} carregada para B2B Enterprise com sucesso',
    issuing_bank: 'Banco Emisor',
    issuing_query: 'Emisor inquerir',
    quantity: 'Quantidade',
    acquiring_query: 'Adquirente inquerir',
    items: 'Indústrias',
    keo: 'AMEX',
    kindOfProduct: 'Tipo de produto',
    legal: 'Legal',
    physical: 'Physical',
    lastDays: 'Últimos días',
    last4Digits: 'Número do cartão dos últimos 4 dígitos',
    lastMovements: 'Últimas operações',
    lengthToCharacters: '* Comprimento de 8 a 12 caracteres contendo todas as três das seguintes características: letras maiúsculas (A-Z), letras minúsculas (a-z), números (0-9) ou caracteres especiais.',
    listCuttingCycle: 'Lista de ciclos de corte',
    listManuals: 'Listar manuais',
    listInvoices: 'Em revisão',
    listPayments: 'Lista de pagos',
    load: 'Carregar',
    loadManuals: 'Carregar manuais',
    loadBillingCycle: 'Carregar ciclos de faturamento',
    loading: 'Carregando...',
    loadingContent: 'Carregando conteúdo ...',
    loadInvoice: 'Carregando \nfatura',
    loan: 'Disposição',
    loanDate: 'Data de disposição',
    loanPeriod: 'Período do empréstimo',
    logs: 'Logs',
    logout: 'Sair',
    logTransaction: 'Log de transações',
    logView: 'Visualização de log',
    operationLog: 'Log de eventos',
    mandatoryFieldsRequired: '(*) Você deve preencher todos os campos marcados com um asterisco.',
    mainUser: 'Usuario Principal',
    mainInvoiceNumber: 'Número da fatura principal',
    markAsPaid: 'Marcar como pago',
    manualsAndFaq: 'Manuais e FAQ\'s',
    manualLoaded: 'Manual carregado com sucesso',
    manualResolution: 'Resolução Manual',
    mdr: 'MDR',
    message: 'Mensagem',
    messageUserProcess: 'No momento, você não possui um tipo de fluxo de aprovação configurado. Entre em contato com seu representante para ativar esta funcionalidade.',
    merchantAffiliateCode: 'Número de afiliado',
    minimumCharacter: 'Número de afiliação comercial, deve ter 10 caracteres',
    monday: 'Segunda-feira',
    monthly: 'Mensal',
    month: 'Mês',
    name: 'Nome',
    nameFantasy: 'Nome fantasia',
    newBank: 'Novo banco',
    newPassword: 'Nova senha',
    newPaymentCondition: 'Novas Condições de Pago',
    newRecharge: 'Novas Recargas',
    newProfile: 'Novo usuário',
    newProfileBTN: 'Novo perfil',
    newRole: 'Nova função',
    newTransfer: 'Nova Transferencia',
    next: 'Continuar',
    nextCollection: 'Próxima Cobranza',
    nextLoanExpiration: 'Próximos vencimentos de linha',
    nextSellerPayments: 'Próximos pagamentos a fornecedores',
    nextDays: 'Próximos días',
    nextPage: 'Próxima',
    nextPayment: 'Próximo Pagamento',
    no: 'No',
    noCycles: 'Não há ciclos de corte carregados para: {{month}}',
    notVerified: 'Não verificado',
    noCard: 'Não possui cartão prepagado',
    noData: 'Sem informação',
    noInformation: 'Sem informação',
    noItemsAvailable: 'Não existem industrias disponiveis',
    noExecutivesAvailable: 'Nenhum executivo de negócios disponível',
    nonMatchingPasswords: 'As senhas inseridas não correspondem',
    noRowsFound: 'Sem informação',
    notifyCardPaymentAuthorization: 'Notificar autorização de pagamento com cartão',
    noTransactions: 'Este cartão não possue operações',
    notYetFound: 'A fatura ainda não está disponível no SII, se desejar, pode carregá-la e o B2B Enterprise se encarregará de tentar novamente a busca e enviá-la para nossa plataforma, caso contrário cancele a operação e tente carregá-la novamente mais tarde',
    numberInstallments: 'Número de parcelas',
    numberTta: 'Número TTA',
    of: 'de',
    operationVolume: 'Q. Operações',
    operator: 'Condição',
    originAccount: 'Conta de origem',
    qualify: 'Habilitar',
    params: 'Parâmetros',
    page: 'Páginas',
    paid: 'Pagadas',
    paidAmount: 'Valor Pagado',
    paidInvoices: 'Faturas Pagadas',
    paidOut: 'Pago',
    password: 'Senha',
    passwordDigitalCertificate: 'Senha do certificado digital',
    passwordHasExpired: 'Sua senha expirou, por favor digite sua nova senha',
    payedAmounts: 'Total diario faturado',
    payment: 'Pagamento',
    paymentCurrency: 'Moeda de pagamento',
    paymentDateInvoice: 'Data de cobrança:',
    paymentConditionProvider: 'Condições comerciais para fornecedores de',
    paymentConditionClients: 'Condições comerciais para clientes de',
    permanentlyRejectCommercialStatus: 'Rejeitar permanentemente o status comercial',
    paymentDate: 'Data de cobranza',
    datePayment: 'Data de pagamento',
    paymentDeadline: 'Prazo de pagamento',
    paymentCapital: 'Pagamento ao principal',
    paymentInterest: 'Pagamento de juros',
    paymentReceived: 'Pagamento recebido',
    paymentOrders: 'Ordens de Pagamento',
    paymentTax: 'Pagamento de IVA',
    paymentsTransfers: 'Pagamento e Transferencias',
    platformAdministrator: 'Administrador da plataforma',
    platformManuals: 'Manuais de plataforma',
    person: 'Pessoa',
    pending: 'Pendentes',
    pendingToApprove: 'Pendente de aprovação',
    pendingInvoices: 'Fac. Pendentes',
    period: 'Periodo',
    present: 'Atual',
    prepaidCard: 'Cartões Prepagados',
    previous: 'Anterior',
    previous_page: 'Anterior',
    printQR: 'Imprimir QR',
    product: 'Produto',
    productUnsubscribedSuccessfully: 'Produto cancelado com sucesso',
    productsContracted: 'Produtos contratados',
    productContracted: 'Solicitação de contrato de produto: {{name}}, enviada com sucesso',
    productName: 'Nome do Produto',
    profileName: 'Nome do perfil',
    provider: 'Fornecedor',
    profileList: 'Lista de usuários',
    profilesList: 'Lista de perfis',
    providerName: 'Nome do fornecedor',
    providers: 'Fornecedores',
    providerPaymentCondition: 'Determinar as condições de pagamento para este provedor',
    profile: 'Perfil',
    profiles: 'Perfiles',
    QuoteAndRequest: 'Cotação e Solicitação',
    rate: 'Percentual',
    dateNotAvailable: 'Data não disponível',
    dateRange: 'Período',
    respJson: 'Responder JSON',
    respXml: 'Responder XML',
    response: 'Responder',
    receivedInvoices: 'Faturas \nRecebidas',
    receivedRequest: 'Solicitação recebida',
    receptionDate: 'Data de emissão da fatura',
    recharge: 'Recarregar',
    recipient: 'Destinatário',
    recoverPassword: 'Recuperar senha',
    registerBank: 'Registrar banco',
    registeredCards: 'Cartões registrados de: {{name}}',
    registerRole: 'Registrar função',
    registerRoute: 'Registrar endereço',
    reject: 'Rejeitar',
    rejectedCycle: 'Ciclo de faturamento rejeitado com sucesso.',
    rejectedInvoices: 'Fac. Rejeitadas',
    rejectReason: 'Rejeitar razão',
    rejectWithEdition: 'Rejeitar com solicitação de edição',
    replaceTheSelectedCard: 'Você tem certeza de substituir o cartão selecionado.',
    relatedUsers: 'Usuarios Relacionados',
    rememberToAcceptTheTerms: 'Lembre-se de aceitar os termos e condições para contratar o produto',
    removeRouteSuccess: 'O Endereço foi removido com sucesso',
    request: 'Solicitações de',
    requestsSent: 'Pedidos enviados',
    requestsReceived: 'Pedidos recebidos',
    requestEdition: 'solicitar edição',
    requestSentProduct: 'Solicitação enviada',
    requestUnsubscribe: 'Solicitar cancelamento de inscrição',
    requestSent: 'Enviei',
    requestReceived: 'Recebido',
    requestsReceivedAmount: 'Recebido ({{amount}})',
    requiredFields: '(*) Campos obrigatórios',
    requiredInstallments: 'O numero de parcelas é invalido',
    requiredName: 'O nome é invalido',
    requiredPeriod: 'O periodo é invalido',
    requiredRate: 'A taxa é invalida',
    requiredTypeFinance: 'O tipo de financiamento é invalido',
    roleCantBeEmpty: 'A função não pode estar vazia',
    roleList: 'Lista de funções',
    roles: 'Funções',
    rol: 'Função',
    roleType: 'Tipo da função',
    rolName: 'Nome da função ',
    rolType: 'Tipo da função',
    routeList: 'Lista de endereços',
    routes: 'Endereços',
    rows: 'Filas',
    save: 'Salvar',
    safetyRules: 'Precisamos conectar sua empresa ao SII para podermos obter as notas fiscais emitidas por seus fornecedores ao seu motivo social, para isso agradecemos por inserir os seguintes dados:',
    saturday: 'Sábado',
    sunday: 'Domigo',
    seeDetail: 'Ver detalhe',
    seeUsers: 'Ver usuários',
    Select: 'Escolha ...',
    selectANewCommercialExecutive: 'Você deve selecionar um novo executivo de negócios',
    SelectDocumentType: 'Escolha um tipo de documento',
    SelectBank: 'Escolha um banco',
    selectCard: 'TTA escolha',
    selectMonth: 'Você deve selecionar um mês',
    selectCycle: 'Você deve selecionar todos os ciclos',
    SelectOption: 'Escolha uma opção',
    selectOrTypeItems: 'Escolha ou escreva uma industria...',
    selectOrTypeRoutes: 'Escolha ou escreva um endereço',
    selectOrTypeRol: 'Escolha ou escreva um função',
    selectOrTypePermissions: 'Escolha ou escreva um Permissõe',
    selectProfile: 'Selecione o Perfil',
    selectRecipient: 'Escolha destinatario',
    send: 'Enviar',
    sendDate: 'Data de envio',
    senderDiscountShare: 'Porcentagem de pagamento do cliente',
    sendInvoice: 'Data de envio da fatura',
    shouldNotSelect: 'Você não deve selecionar o mesmo Executivo Comercial, que deseja eliminar',
    sentInvoices: 'Faturas \nenviadas',
    settings: 'Configurações',
    seeHow: 'Ver como',
    shippingDate: 'Data de envio',
    showQR: 'Mostrar código QR',
    since: 'De',
    startDate: 'Data de início',
    endingDate: 'Data de término',
    source_log: 'Origem da solicitação',
    startCountingFrom: 'Dias acordados a partir de',
    summary: 'Resumo',
    targetNumber: 'Número de cartão',
    taxOrdinary: 'IVA normal',
    taxLatePayment: 'Moratória do IVA',
    termsAndConditions: 'Termos e Condições',
    transactionsToProcess: 'Transações para processar',
    toDisable: 'Desabilitar',
    total: 'Total',
    totalAmount: 'Valor total',
    totalAmountToPayOnTheInvoice: 'Valor total a pagar na fatura',
    toBeApproved: 'Para ser aprovado',
    totalBuyers: 'Total de empresas pagadoras',
    totalBuyersDeferred: 'Empresas com Faturas Diferidas',
    totalBuyersInvoiced: 'Empresas com Faturas Aprovadas',
    totalCharged: 'Total cobrado {{month}}',
    totalDebtAmount: 'Valor total do pagamento',
    totalOwedAmount: 'Valor total do cobro',
    totalPay: 'Total do pagamento',
    totalReject: 'Rejeição total',
    totalToPayCardholder: 'Total para pagar a taxa de serviço do titular do cartão',
    totalToPayInvoice: 'Total para pagar a fatura',
    totalVolumePendingPayment: 'Volume total pendente de pagamento',
    totalSellers: 'Fornecedores associados',
    totalSellersCharged: 'Fornecedores com faturamento Aprovado',
    totalSellersInvoiced: 'Proveedores com faturamente Pendente',
    totalToApprove: 'Total a aprovar',
    totalToReceiver: 'Total a receber',
    tradeInformation: 'Informação comercial',
    transactionType: 'Tipo de operação',
    transfer: 'Transferir',
    transferCharge: 'Taxa da operação',
    transferDetail2: 'Voucher',
    transferDetail3: 'Enviado para',
    transferDetail4: 'Valor enviado',
    transferDetail5: 'Valor total descontado',
    transferDetail: 'Transferência efetuada',
    transferState: 'Status',
    transferType: 'Tipo de operação',
    TtaValidation: 'Validação TTA',
    type: 'Tipo',
    tuesday: 'Terça-feira',
    totalInvoice: 'Fatura total',
    totalInvoices: 'Faturas totais',
    theCodeExpiresIn: 'O código expira em: {{time}}',
    theInvoiceWillBeCharged: 'A fatura será cobrada da empresa B2B em status, {{status}}',
    thursday: 'Quinta feira',
    theyMustNotHave: '* Não devem ter caracteres consecutivos idênticos, nem totalmente numéricos, nem totalmente alfabéticos.',
    typeFinance: 'Tipo de financiamento',
    typeOfApprovalFlow: 'Tipo de fluxo de aprovação',
    typeOfEmitFlow: 'Tipo de fluxo de emissão',
    typeAndDocumentNumber: 'Tipo e número do documento',
    underReview: 'Em revisão',
    underReviewInvoices: 'Faturas em revisão',
    user: 'Do utilizador',
    userManual: 'Manual de usuário',
    username: 'Nome do usuario',
    userQuery: 'Consulta do usuário',
    userBillingCycles: 'Ciclos de faturamento do usuário',
    Unsubscribe: 'Cancelar subscrição',
    until: 'Até',
    updatedBillingCycles: 'Ciclos de faturamento atualizados',
    virtualAccount: 'Conta Virtual',
    yearly: 'Anual',
    resource: 'Recurso',
    view: 'Ver',
    waitingForApproval: 'Aguardando aprovação do pedido de cancelamento do serviço',
    daysAdvance: 'Dias de adiantamento',
    daysDefer: 'Dias de diferimento',
    anualRate: 'Taxa anual',
    percentage: 'Taxa de juros anual',
    interest_rate: 'Juros',
    adjusted_amount: 'Montante',
    cantDeleteRol: 'A função não pode ser eliminada porque tem asignado um ou mais endereços',
    deleteRol: 'A função foi eliminada',
    deleteRoute: 'O Endereço foi eliminado',
    deleteExecutive: 'Executivo de negócios removido\n',
    cantDeleteRoute: 'O endereço não pode ser eliminado porque esta asignada a uma ou mais funções',
    createLead: 'Requerer \n alta',
    sellerCreateLead: 'Requerer alta do fornecedor',
    buyerCreateLead: 'Requerer alta do cliente',
    requireSuccess: '{{name}} requerido com sucesso',
    buyer: 'Cliente',
    seller: 'Fornecedor',
    buyerName: 'Nome do cliente',
    sellerName: 'Nome do fornecedor',
    leadList: 'Lista de \nrequisitos',
    createRoleSuccess: 'Função {{rolName}} criada com sucesso',
    updateBank: 'Atualizar banco',
    updateInformation: 'Atualizar informação',
    adminName: 'Nome',
    adminPosition: 'Posição',
    invoicesApproved: 'Faturas Aprovadas',
    approvedMount: 'Valor Aprovado',
    nInvoices: 'Nro. de Faturas',
    advanceMount: 'Valor adiantado',
    deferMount: 'Valor diferido',
    totalCommissionAmount: 'Comição total',
    commission: 'Comição',
    discountAmount: 'Valor do desconto',
    reports: 'Reportes',
    createPaymentCondition: 'Criar condição \nde pagamento',
    addCondition: ' Adicionar condição',
    receiverDiscountShare: 'Porcentagem de pagamento do fornecedor',
    recentInvoices: 'Faturas recentes',
    paymentClient: 'O pagamento do cliente é',
    createPaymentConditionSuccess: 'Condição de pagamento criada com sucesso',
    paymentCondition: 'Condição',
    client: 'Cliente',
    download: 'Descarregar',
    en: 'English',
    es: 'Español',
    pt: 'Português',
    language: 'Idioma',
    lead: 'Alta',
    completeComercialConditionOrEmptyAll: 'Completar os dados das condições comerciais o deixeos vazios si não deseja adicionar condições comerciais com os fornecedores',
    transactions: 'Transações',
    totalReceiver: 'Total a cobrar',
    providersReceiver: 'Fornecedores por cobrar',
    clientsPay: 'Clientes por pagar',
    clients: 'Clientes',
    selectedBank: 'Banco',
    uploadInvoice: 'Carregar fatura',
    loadInvoiceData: 'Carregar dados de fatura',
    naoReter: 'Não reter IR (propaganda, publicidade e comições)',
    disable: 'Desabilitar',
    status: 'Estado',
    statusOfInvoice: 'O status da fatura N ° {{invoiceNumber}} no SII é: {{status}}.',
    cannotBeUploaded: 'Portanto, a fatura não pode ser carregada para B2B Enterprise',
    clientPay: 'Pagamento do cliente',
    referrer: 'Referido',
    // Despues de enviar a traduccion a portugues
    invoiceApprovedSuccess: 'Fatura aprovada com sucesso',
    invoiceDeclinedSuccess: 'Fatura recusada com sucesso',
    invoiceRejectedSuccess: 'Fatura rejeitada com sucesso',
    actionNotAllowed: 'Ação não permitida',
    limit: 'Limite',
    limitAmount: 'Montante limite',
    new: 'Novo',
    paymentMethod: 'Método de pagamento',
    target: 'Cartão',
    line: 'Linha',
    defaultFlow: 'Fluxo padrão',
    condition_amount: 'Quantidade',
    invoiceNumberValidate: 'O formato deve ser xxx-xxx-xxxxxxxxx',
    amountValidate: 'A quantia deve ser positiva',
    cantCreateTransaction: 'O número da fatura já existe',
    penddingInvoices: 'Você tem {{pending_invoices}} faturas pendentes sem aprovar',
    registerAdministrator: 'Registrar administrador',
    registerCard: 'Cartão de registro',
    registerCardAmex: 'Insira o cartão American Express',
    listCards: 'Lista de Cartões',
    cards: 'Cartões',
    savedCards: 'Cartões de crédito salvos',
    secureCards: 'As informações do seu cartão de crédito serão armazenadas sob rígidas normas de segurança',
    rejectInvoices: 'Você tem {{reject_invoices}} faturas rejeitadas',
    rejectInvoiceAll: 'Faturas rejeitadas',
    rejectedRequests: 'Solicitações rejeitadas ({{amount}})',
    emitInvoices: 'Você tem {{emit_invoices}} faturas sem emitir',
    search: 'Procurar',
    successExecutive: 'Executivo de negócios criado com sucesso',
    successCard: 'O seu cartão já foi registrado.',
    successCycle: 'Ciclo de faturamento confirmado com sucesso.',
    successCycles: 'Ciclos de faturamento confirmados com sucesso',
    main_bank: 'Usuário principal do Banco',
    user_list: 'Lista de Usuários',
    editFlowApprovalSuccess: 'fluxo de aprovação editado com sucesso',
    emissionFlow: 'Fluxo de Emissão',
    transactionReport: 'Relatório de transação',
    paymentDateRange: 'Período de coleta da fatura',
    paymentDateRangeClient: 'Período de pagamento da fatura',
    identificationNumber: 'Número de identificação',
    time: 'Hora',
    event: 'Evento',
    info: 'Info',
    infoLog: 'Informações do evento',
    affiliationCode: 'Código de afiliado do comerciante',
    affiliateNumber: 'Número de afiliado',
    validCard: 'Verificado',
    invalidCard: 'A ser checado',
    RUT: 'RUT',
    conditions: 'Condições',
    phone: 'Telefone',
    contactPhone: 'Telefone de contato',
    yes: 'Sí',
    yourDataWillBeStoredEncrypted: '*Seus dados serão armazenados criptografados sob rígidos regulamentos de segurança.',
    validity: 'Validade',
    vendorMerchantAffiliateCodes: 'Códigos de afiliados de comerciante do fornecedor: {{provider}}',
    volumeProcessed: 'Volume processado nos últimos três meses',
    verificationCode: 'Código de verificação',
    verificationCodeSent: 'Digite o código de verificação enviado para o seu e-mail',
    waitingBillingCycle: 'Aguardando atribuição do ciclo de faturamento',
    billingCycle: 'Ciclo de faturamento',
    billingCycles: 'Ciclos de faturamento',
    cardList: 'Lista cartão',
    permissions: 'Permissões',
    region: 'Região',
    commune: 'Comuna',
    grant: 'Conceder',
    deny: 'Negar',
    viewTta: 'Ver TTA',
    webpay: 'Webpay',
    wednesday: 'Quarta feira',
    getnet: 'Getnet',
    gateWay: 'Gateway',
    main_bank_issuing: 'Usuário administrador do banco emissor',
    main_bank_acquiring: 'Usuário administrador do banco emissor adquirente',
    currency: 'Moeda',
    bankingExecutives: 'Executivo de negócios',
    currentCycle: 'Ciclo Válido',
    disabled: 'Desativado',
    enabled: 'Ativado',
    assignedUsers: 'Usuários Atribuídos',
    newBankingExecutive: 'Novo executivo de negócios',
    rate_operator: 'Operador de tarifas financieras',
    cycle_loader: 'Operador de Ciclos de Facturación',
    requestProducts: 'Solicite seu seguro ou assistência',
    products: 'Seguro / Assistência',
    cost: 'Custo',
    contract: 'Contrato',
    registerProduct: 'Registrar Produto',
    registerProfile: 'Registrar perfil',
    insurances: 'Seguros',
    contractRequest: 'Solicitação de contrato',
    unsuscribeRequest: 'Pedido de cancelamento de inscrição',
    productType: 'Tipo de produto',
    emailLabel: 'nome@negócio.cl',
    fantasyName: 'Nome fantasia',
    b2bMDR: 'Taxa B2B Enterprise (MDR)',
    providerB2BRate: 'Taxa de B2B paga pelo fornecedor',
    clientB2BRate: 'A taxa de B2B a pagar pelo cliente é',
    verifyHuman: 'Por favor, verifique se você é humano',
    selectInvoice: 'Selecciona a fatura',
    invoiceTerms: 'Ao aprovar as faturas dentro da plataforma B2B Enterprise, aceito a cobrança que será feita no meu cartão Tesoureiro American Express, pelo valor que se individualiza em cada fatura, e o valor da taxa de serviço, se aplicável de acordo com as condições comerciais acordado com seu provedor. Instrui irrevogavelmente o AMEX, para que tais cobranças sejam feitas no referido Cartão.',
    main_bank_issuing_amex: 'Usuário Administrador do Banco Emissor AMEX',
    clientPercentageCharge: 'Comprador – Porcentagem de taxa de serviço',
    providerPercentageCharge: 'Fornecedor – Porcentagem de taxa de serviço',
    adminBank: 'Banco Administrador',
    bankInformation: 'Informação do banco selecionado',
    selectBank: 'Você deve selecionar um banco',
    buyerCode: 'Código do cliente',
    sellerCode: 'Código do Fornecedor',
    january: 'Janeiro',
    february: 'Fevereiro',
    march: 'Março',
    april: 'Abril',
    may: 'Maio',
    june: 'Junho',
    july: 'Julho',
    august: 'Agosto',
    september: 'Setembro',
    october: 'Outubro',
    november: 'Novembro',
    december: 'Dezembro',
    updatedBillingCycle: 'Ciclo de faturamento atualizado',
    enterTheCardBillingCycle: 'Você precisa entrar no ciclo de faturamento do cartão para continuar operando com aquele cartão',
    successfullyAssignedCuttingCycle: 'Ciclo de corte atribuído com sucesso',
    validEmail: 'Você deve inserir e-mails que pertencem a @ baccredomatic.com',
    providerGroup: 'Grupo de fornecedores',
    customerGroup: 'Grupo de clientes',
    suggestTradeConditionCorrection: 'Sugerir correção de condição de negociação',
    rejectTradeRequest: 'Rejeitar pedido de negociação',
    esp: 'ESP',
    por: 'POR',
    eng: 'ENG',
    otherSign: 'Referência',
    province: 'Doença',
    canton: 'Capital',
    myLoans: 'Empréstimos',
    approveds: 'Aprovado',
    loanNumber: 'Número do empréstimo',
    totalBalance: 'Saldo total',
    minimumPayment: 'Pago minimo',
    toPay: 'Pagar',
    outdoorNumber: 'Número externo',
    interiorNumber: 'Número interno',
    suburb: 'Subúrbio',
    municipality: 'Delegação ou município',
    postalCode: 'Código postal',
    authorizedBy: 'Autorizado por',
    RFC: 'RFC {{type}}',
    haveNotifications: 'Tem notificações',
    receivingBank: 'Banco receptor',
    requestRejected: 'Enviado com rejeição',
    uniqueEmail: 'O e-mail não deve ser cadastrado anteriormente no site como executivo de negócios',
    invoicesSent: 'Faturas enviadas',
    rejectInvoice: 'Rejeitadas',
    affiliationNumber: 'Número de afiliação',
    rejectedBy: 'Rejeitado por',
    failedPayment: 'Pagamento falhou',
    incidence: 'Incidência',
    amountArranged: 'Valor combinado',
    pendingProvisions: 'Disposições pendentes',
    totalAmountDisposed: 'Valor total descartado',
    dispositionDate: 'Data de disposição',
    balancePerDay: 'Saldo por dia',
    generateReferencePayment: 'Gerar referência para pagamento',
    generatedReference: 'Número de referencia, {{reference}}',
    accountClabe: 'Conta clabe',
    accountCie: 'Conta cie',
    assignedName: 'Nome atribuído',
    emailExample: 'ejemplo@correo.com',
    keoEdition: 'Edición estado AMEX',
    declinedInvoices: 'Faturas recusadas',
    declined: 'Recusadas',
    apiRequests: 'Solicitações de API',
    origin: 'Fonte',
    responseStatus: 'Status de resposta',
    replyMessage: 'Responder à mensagem',
    paymentInformation: 'Informação de pagamento',
    invoiceAmount: 'Valor da fatura',
    timeLimit: 'Prazo',
    retries: 'Novas tentativas',
    retry: 'Tentar novamente {{cant}}',
    tense: 'Tiempo',
    errorHour: 'Você não pode inserir mais de 24 horas',
    errorHour1: 'Você não pode inserir menos de 1 hora',
    hour: 'Horas',
    hours: 'Horas',
    errorMinute: 'Você não pode inserir mais de 60 minutos',
    errorMinute1: 'Você não pode entrar com menos de 3 minutos',
    minute: 'Minuto',
    minutes: 'Minutos',
    cannotAddMore: 'Não é possível adicionar mais de 10 tentativas',
    errorInTheRow: 'Erro na linha {{row}}, {{error}}',
    retriesLoaded: 'Novas tentativas carregadas com sucesso',
    information: 'Informação',
    invoiceDateAutomatically: 'O campo Data da Fatura agora é automático, considere o dia em que a solicitação é feita',
    dateTheInvoice: 'A data agora corresponde ao dia em que a fatura é carregada',
    responsiveMsg: 'Para obter controle total do aplicativo da web ABL, recomendamos usar a versão para desktop.',
    transactionDate: 'Data da transação',
    TDCUserConfirm: 'Se continuar com a operação, o provedor será criado com o tipo de pagamento TDC.',
    NCLUserConfirm: 'Se continuar com a operação, o provedor será criado com o tipo de pagamento NCL, ou seja, só poderá receber pagamentos através de transferências SPEI',
    RFCValidation: 'RFC deve corresponder a este formato AAA0000000A0',
    wantToContinue: '¿Você deseja continuar?',
    createNCLCustomer: 'Criar cliente NCL',
    clientWithACG: 'O cliente será criado com a modalidade de pagamento NCL, ou seja, só poderá realizar pagamentos através de transferências SPEI',
    createdNCL: 'Se você continuar com a operação, o cliente será criado com a modalidade de pagamento NCL, ou seja, você só poderá efetuar pagamentos por meio de transferências SPEI',
    wishToContinue: 'Você deseja continuar?',
    countryCode: 'Código do país',
    areaCode: 'Código de área',
    paymentType: 'Tipo de pagamento',
    newCondition: 'Nova Condição',
    NCLCommission: 'comissão NCL',
    sentCondition: 'Pedido enviado entre o {{client}} e o {{supplier}} com {{number}} dias acordados de {{type}}e uma comissão de {{commission}}',
    NCLComission: 'comissão NCL',
    nclInvoiceNotification2: 'A fatura possui tipo de pagamento NCL, portanto, seu pagamento será realizado por transferência SPEI fora da plataforma Business Link',
    nclInvoiceNotification: 'A fatura será carregada com o tipo de pagamento NCL, portanto, seu pagamento será realizado por transferência SPEI fora da plataforma Business Link',
    nclInvoiceApprovementNotification: 'O pagamento da sua fatura será feito por transferência SPEI fora da plataforma Business Link',
    invoicesNcl: 'Pagamentos NCL',
    proofPayment: 'Comprovante de pagamento',
    process: 'Processo',
    downloadPayment: 'Baixar comprovante de pagamento',
    nclPaymentSuccessfullyRejected: 'Pagamento NCL recusado com sucesso',
    nclPaymentProcessedSuccessfully: 'Pagamento NCL processado com sucesso',
    AllowedFormats: 'Formatos permitidos: PDF e JPG.',
    errorLoadFile: 'Você deve enviar um arquivo com extensão {{type}} ou {{type1}}',
    paymentRegisteredSuccessfully: 'Pagamento registrado com sucesso',
    downloadAttached: 'Baixar em anexo',
    NCLSupplierCommission: 'Comissão do fornecedor NCL',
    scheduledCollectionDate: 'Data de coleta programada',
    scheduledDateTheProvider: 'Data de pagamento agendada',
    effectiveDatePayment: 'Data efetiva de pagamento',
    effectiveDateCollection: 'Data efetiva de coleta',
    switchProfile: 'Mudar Perfil',
    usd: 'USD',
    mxn: 'MXN',
    mexicanPesos: 'pesos mexicanos',
    americanDollar: 'Dólar estadounidense',
    relationshipInactive: 'A condição não pode ser aprovada porque o relacionamento está inativo',
    relationshipStatus: 'Status de relacionamento',
    a: 'Ativo',
    i: 'Parado',
    c: 'Cancelado',
    p: 'Pendente',
    r: 'Rejeitado',
    noLogRecord: 'lamentamos, não há informações de registro de pagamento',
    allInvoices: 'Todas as faturas',
    processInvoices: 'Faturas em processo de pagamento',
    paymentProcess: 'Payment process',
    mode: 'Modalidade',
    withInvoice: 'Com fatura',
    withoutInvoice: 'Sem fatura',
    savePaymentSuccess: 'Pagamento salvo com sucesso',
    sendPaymentSuccess: 'Pagamento enviado com sucesso',
    editPaymentSuccess: 'Pagamento editado com sucesso',
    deletePaymentSuccess: 'Pagamento deletado com sucesso',
    paymentRecord: 'Registro de Pagamento',
    editPayment: 'Editar Pagamento',
    relatedEmails: 'e-mails adicionais',
    instructionDetail: 'Detalhe da instrução',
    noInfoMsg: 'Sem dados disponíveis',
    traditional: 'Tradicional',
    confirmPayment: 'Tem certeza de que deseja enviar um pagamento de {{currency}} ${{amount}} para o provedor {{provider}} agora?',
    confirmDeletePayment: 'Tem certeza de que deseja excluir o pagamento de {{amount}} para o provedor {{provider}}?',
    notZeroAmount: 'O valor deve ser maior que zero',
    instructionNumber: 'Número de instrução',
    bpp: 'BPP',
    selectAProvider: 'Seleccione um provedor',
    newPayment: 'Novo pagamento',
    gatewayInformation: 'Informações de gateway',
    BPPDesc: 'Buyer Pushed Payment permite que o cliente efetue pagamentos em uma fatura que ainda não foi gerada pelo fornecedor.',
    commerceRelationshipID: 'ID de relacionamento comercial',
    validInvoiceNumber: 'O número da fatura não pode estar vazio ou ser um símbolo',
    '/recent-invoices': 'Faturas recentes',
    '/declined-invoices': 'Faturas recusadas',
    '/process-invoices': 'Faturas em processo de pagamento',
    '/create-payment': 'Novo pagamento',
    '/payment-record': 'Registro de Pagamento',
    '/payment-detail': 'Detalhe da instrução',
    '/payment-log': 'Informações de gateway',
    '/edit-payment': 'Alterar',
    '/update-retry-payment': 'Novas tentativas',
    '/approved-loan': 'Meus empréstimos',
    '/create-provider': 'Criar fornecedores',
    '/edit-provider': 'Editar provedores',
    '/create-customer': 'Criar clientes',
    '/edit-customer': 'Editar clientes',
    '/create-profile': 'Criar perfil',
    '/dashboard': 'Sumário',
    '/account-details': 'Detalhes da conta',
    '/extract': 'Extrair',
    '/prepaid-Card': 'Cartão pré-pago',
    '/transfer': 'Transferir',
    '/deposit': 'Depositar',
    '/load-invoice': 'Carregar faturas',
    '/customers': 'Listar, editar ou desativar clientes',
    '/invoices': 'Faturas',
    // '/invoices': 'Listar, editar, aprovar ou rejeitar faturas sem aprovar',
    '/expired-invoices': 'Listar faturas expiradas',
    '/related-users': 'Cliente ou fornecedores relacionados', //REVISAR
    '/clients': 'Clients',
    '/providers': 'Provedores',
    '/register-bank': 'Criar bancos',
    '/bank-list': 'Listar, editar ou desativar bancos',
    '/role-list': 'Listar, editar e excluir funções',
    '/invoice-detail': 'Detalle do fatura',
    '/register-financing-products': 'Criar financiamento do produto',
    '/financing-products': 'Produtos financeiros',
    '/register-role': 'Criar funções',
    '/route-list': 'Listar, editar e excluir rotas',
    '/register-route': 'Criar rotas',
    '/admin-routes': 'Atribuir ou remover rotas para funções',
    '/review-invoices': 'Contas em revisão',
    '/reject-invoices': 'Faturas rejeitadas',
    '/approved-invoices': 'Faturas aprovadas',
    '/paid-invoices': 'Faturas pagas',
    '/pending-invoices': 'Faturas pendentes',
    '/create-lead': 'Exigir clientes ou fornecedores',
    '/lead-list': 'Listar, aprovar ou rejeitar obrigatório',
    '/reports': 'Reportes',
    '/payment-conditions-log': 'Registros de condições de pagamentos',
    '/transactions': 'Faturas geradas pelo banco',
    '/profile-list': 'Gerenciar usuários',
    '/create-approval-flow': 'Criar fluxo de aprovação de fatura',
    '/edit-approval-flow': 'Editar fluxo de aprovação de fatura',
    '/approval-flow-list': 'Lista de fluxo de aprovação', 
    '/account-profile': 'Conta de perfil',
    '/payment-conditions': 'Condições de pagamento',
    '/create-bank-user': 'Criar usuário',
    '/edit-bank-user': 'Editar usuário',
    '/bank-user-list': 'Lista de usuário',
    '/register-card': 'Cartão de registro',
    '/replace-card': 'Substituição do cartão de crédito',
    '/list-cards': 'Lista de cartas',
    '/payment-request-sent': 'Solicitações',
    '/payment-request-received': 'Solicitações',
    '/save-credentials': 'Salvar credenciais',
    '/edit-credentials': 'Editar credenciais',
    '/load-billing-cycle': 'Carregar ciclo de faturamento',
    '/edit-billing-cycle': 'Editar ciclo de faturamento',
    '/billing-cycles': 'Ciclos de faturamento',
    '/create-banking-executive': 'Criar executivo',
    '/load-executives': 'Carregar executivos',
    '/products': 'Produtos',
    '/contracted-products': 'Produtos contratados',
    '/product-detail': 'Detalhes dos produtos',
    '/accept-discount-rate': 'Aceitar taxa de desconto',
    '/edit-bank-admin': 'Editar administrador',
    '/save-manual': 'Salvar manual',
    '/list-manual': 'Manual',
    '/support': 'Suporte',
    '/monthly-report': 'Relatório mensal',
    '/commerce-code': 'Código de comercio',
    '/user-commerce-code': 'Número de afiliado',
    '/confirm-provider': 'Confirmar provedor',
    '/confirm-customer': 'Confirmar cliente',
    '/create-payment-condition': 'Criar condição de pagamento',
    '/edit-payment-condition': 'Editar condição de pagamento',
    '/provider-group-load': 'Grupo provedores',
    '/customer-group-load': 'Grupo clientes',
    'list-provider': 'Lista de proveedores',
    'list-customer': 'Lista de clientes',
    '/create-admin-bank': 'Criar banco de administrador',
    '/edit-admin-bank': 'Editar banco de administrador',
    '/admin-bank-list': 'Bancos administradores',
    '/resp': 'Confirmação de armazenamento do cartão',
    '/log-view': 'Logs',
    '/operation-log': 'Logs',
    '/mixed-payments': 'Agendar pagamento misto',
    '/credit-request': 'Créditos e empréstimos',
    '/credits-list': 'Registro de crédito',
    '/check-deposit': 'Cheques para depositar',
    '/approved-checks': 'Cheques aprovados',
    '/not-approved-checks': 'Cheques a serem aprovados',
    '/rejected-checks': 'Cheques devolvidos',
    '/send-information': 'Envie ERP',
    '/banking-executives': 'Executivos de negócios',
    '/payment-request': 'Solicitações de',
    '/disposition-report': 'Relatório de disposição',
  },
};

export default pt;
