import React, { useState, useEffect } from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';
import { Toggle } from '../../../../components';
import getMenuOptions from '../../../../config/asideOptions';
import { Menu } from './components';
import { withPermissions } from '../../../../hoc';
import './styles.scss';

const returnUrlFromPathname = (pathname, menus) => {
  let url = '';
  menus.forEach((menu) => {
    const { submenu, url: menuUrl } = menu;
    if (submenu !== undefined) {
      const cantSubMenu = submenu.items.length;
      for (let i = 0; i < cantSubMenu; i += 1) {
        if (pathname === submenu.items[i].url) {
          url = menuUrl;
        }
      }
    } else {
      url = menuUrl;
    }
  });
  return url;
};

const Aside = (props) => {
  const {
    setSidebarStatus,
    location: { pathname },
    userHasRoles,
    hasAccessTo,
    auth: { user: { b2b: { buyer, seller } } }
  } = props;

  const aditionalBuyer = buyer;
  const aditionalSeller = seller;
  const menu = getMenuOptions(hasAccessTo, userHasRoles, aditionalBuyer, aditionalSeller);
  const [selected, setSelected] = useState(returnUrlFromPathname(pathname, menu));
  const [sideBar, setSidebar] = useState(localStorage.getItem('sideBarStatus') != null ? localStorage.getItem('sideBarStatus') : 'expanded');

  const handleSidebarStatus = () => {
    if (sideBar === 'expanded') {
      setSidebar('compressed');
      setSidebarStatus('compressed');
      localStorage.setItem('sideBarStatus', 'compressed');
      window.dispatchEvent(new Event('resize'));
    } else {
      setSidebar('expanded');
      setSidebarStatus('expanded');
      localStorage.setItem('sideBarStatus', 'expanded');
      window.dispatchEvent(new Event('resize'));
    }
  };

  return (
    <div
      className="Aside"
    >
      <Toggle>
        {
          ({ toggled, onToggle }) => (
            <div
              className={`be-left-sidebar animated fadeInUp ${sideBar}`}
              style={{ animationDelay: '0.5s' }}
            >
              <div onClick={handleSidebarStatus}
                className='sidebar-compress'
              >
                <i className='fa fa-chevron-left'></i>
              </div>
              <div className={`left-sidebar-wrapper ${toggled ? 'open' : 'closed'}`}>
                <span
                  role="presentation"
                  className="left-sidebar-toggle"
                  onClick={onToggle}
                >
                <i className={`fa ${toggled ? 'fa-times' : 'fa-bars'}`} aria-hidden="true" />
                </span>
                <div
                  className={`${toggled ? 'show-up-down' : 'hide-down-up'}`}
                >
                  <div className="left-sidebar-scroll">
                    <div className="left-sidebar-content">
                      <ul className="sidebar-elements">
                        {menu.map((value, i) => ( 
                          <Menu
                            key={i}
                            name={value.name}
                            icon={value.icon}
                            url={value.url}
                            isHidden={value.isHidden}
                            pathname={pathname}
                            selected={selected}
                            setSelected={setSelected}
                            submenu={value.submenu}
                          />
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )
        }
      </Toggle>
    </div>
  );
};

Aside.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  hasAccessTo: PropTypes.func.isRequired,
  userHasRoles: PropTypes.func.isRequired,
};

export default compose(
  withPermissions,
  withNamespaces(),
)(Aside);
