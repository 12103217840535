import { roleActions, roleTypes } from '../ducks/role';
import { TOAST_CONFIG } from '../../config/constants';

const roleData = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== roleTypes.FETCH_ROLE) {
    return;
  }

  const {
    params: {
      id = '', name = '', type = '', owner_id = '', pageSize = 0
    } = {},
    callback = () => { },
  } = action.payload;

  dispatch({
    type: roleTypes.API_CALL,
    payload: {
      config: {
        method: 'GET',
        url: `b2b-roles?id=${id}&name=${name}&type=${type}&owner_id=${owner_id}&pageSize=${pageSize}`,
      },
      authorization: true,
      onStart: roleActions.startFetch,
      onComplete: ({ data: responseData }) => {
        const { success, data } = responseData;
        if (success) {
          dispatch(roleActions.updateRoles({
            list: data,
          }));
          callback(data);
        }
      },
      onEnd: roleActions.endFetch,
    },
  });
};

const typesRoleData = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== roleTypes.FETCH_TYPES_ROLE) {
    return;
  }

  dispatch({
    type: roleTypes.API_CALL,
    payload: {
      config: {
        method: 'GET',
        url: 'b2b-roles/get-types-role',
      },
      authorization: true,
      onStart: roleActions.startFetch,
      onComplete: ({ data: responseData }) => {
        const { success, data } = responseData;
        if (success) {
          dispatch(roleActions.updateTypesRole({
            list: data,
          }));
        }
      },
      onEnd: roleActions.endFetch,
    },
  });
};

const saveRolData = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== roleTypes.ADD_ROLE) {
    return;
  }

  const {
    data,
    callback = () => {
    },
  } = action.payload;

  dispatch({
    type: roleTypes.API_CALL,
    payload: {
      config: {
        method: 'POST',
        url: 'b2b-roles',
        data,
      },
      authorization: true,
      onStart: roleActions.startFetch,
      onComplete: ({ data: responseData }) => {
        const { success, data: rol } = responseData;
        if (success) {
          dispatch(roleActions.saveRole({
            rol,
          }));
          callback(responseData);
        }
      },
      onEnd: roleActions.endFetch,
    },
  });
};

const removeRole = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== roleTypes.DELETE_ROLE) {
    return;
  }

  const {
    data: {
      role_id = '',
    } = {},
    callback = () => {
    },
    t = () => {},
  } = action.payload;

  dispatch({
    type: roleTypes.API_CALL,
    payload: {
      config: {
        method: 'DELETE',
        url: `b2b-roles/${role_id}`,
      },
      authorization: true,
      onStart: roleActions.startFetch,
      onComplete: () => {
        callback();
        dispatch(roleActions.removeRole({ role_id }));
        dispatch(roleActions.showMessage({
          message: t('deleteRol'),
          config: TOAST_CONFIG.SUCCESS,
        }));
      },
      onError: () => {
        dispatch(roleActions.showMessage({
          message: t('cantDeleteRol'),
          config: TOAST_CONFIG.INFO,
        }));
      },
      onEnd: roleActions.endFetch,
    },
  });
};

const editRole = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== roleTypes.EDIT_ROLE) {
    return;
  }

  const {
    data: {
      id,
      name,
      type,
      is_approver,
    } = {},
    callback = () => {
    },
    t = () => {
    },
  } = action.payload;

  dispatch({
    type: roleTypes.API_CALL,
    payload: {
      config: {
        method: 'PUT',
        url: `b2b-roles/${id}`,
        data: {
          name,
          type,
          is_approver
        },
      },
      authorization: true,
      onStart: roleActions.startFetch,
      onComplete: ({ data: { data, success } }) => {
        if (success) {
          callback(data, success);
        }
      },
      onEnd: roleActions.endFetch,
    },
  });
};

const ownerId = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== roleTypes.OWNER_ID) {
    return;
  }

  const {
    id_bank,
    callback = () => {
    },
  } = action.payload;

  dispatch({
    type: roleTypes.API_CALL,
    payload: {
      config: {
        method: 'GET',
        url: `b2b-roles/role-owner-id?id_bank=${id_bank}`,
      },
      authorization: true,
      onStart: roleActions.startFetch,
      onComplete: ({ data: responseData }) => {
        const { success, data } = responseData;
        if (success) {
          dispatch(roleActions.updateTypesRole({
            list: data,
          }));
        }
      },
      onEnd: roleActions.endFetch,
    },
  });
};

const rolesSupport = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== roleTypes.ROLES_SUPPORT) {
    return;
  }

  const {} = action.payload;

  dispatch({
    type: roleTypes.API_CALL,
    payload: {
      config: {
        method: 'GET',
        url: `b2b-roles/roles-support`,
      },
      authorization: true,
      onStart: roleActions.startFetch,
      onComplete: ({ data: responseData }) => {
        const { success, data } = responseData;
        if (success) {
          dispatch(roleActions.updateRoles({
            list: data,
          }));
        }
      },
      onEnd: roleActions.endFetch,
    },
  });
};

const saveManual = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== roleTypes.SAVE_MANUAL) {
    return;
  }

  const {
    data,
    callback = () => {
    },
  } = action.payload;

  dispatch({
    type: roleTypes.API_CALL,
    payload: {
      config: {
        method: 'POST',
        url: 'b2b-support/save-manual',
        data,
      },
      authorization: true,
      onStart: roleActions.startFetch,
      onComplete: ({ data: responseData }) => {
        const { success, data: rol } = responseData;
        if (success) {
          callback(responseData);
        }
      },
      onEnd: roleActions.endFetch,
    },
  });
};

const updateManual = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== roleTypes.UPDATE_MANUAL) {
    return;
  }

  const {
    data,
    callback = () => {
    },
  } = action.payload;

  dispatch({
    type: roleTypes.API_CALL,
    payload: {
      config: {
        method: 'POST',
        url: 'b2b-support/update-manual',
        data,
      },
      authorization: true,
      onStart: roleActions.startFetch,
      onComplete: ({ data: responseData }) => {
        const { success, data: rol } = responseData;
        if (success) {
          callback(responseData);
        }
      },
      onEnd: roleActions.endFetch,
    },
  });
};

const getManuals = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== roleTypes.GET_MANUALS) {
    return;
  }

  const {
    params: {
      bank = '',
      role = '',
    } = {},
    callback = () => {
    },
  } = action.payload;

  dispatch({
    type: roleTypes.API_CALL,
    payload: {
      config: {
        method: 'GET',
        url: `b2b-support/get-manuals?bank=${bank}&role=${role}`,
      },
      authorization: true,
      onStart: roleActions.startFetch,
      onComplete: ({ data: responseData }) => {
        const { success, data } = responseData;
        if (success) {
          dispatch(roleActions.updateTypesRole({
            list: data,
          }));
        }
      },
      onEnd: roleActions.endFetch,
    },
  });
};

export default [roleData, typesRoleData, saveRolData, removeRole, editRole, rolesSupport, saveManual, getManuals, updateManual];
