const START_FETCH = 'Card/START_FETCH';
const END_FETCH = 'Card/END_FETCH';
const API_CALL = 'Card/API_CALL';
const MESSAGE = 'Card/MESSAGE';
const FETCH_CARD_DATA = 'Card/FETCH_CARD_DATA';
const UPDATE_CARD_DATA = 'Card/UPDATE_CARD_DATA';
const RECHARGE_CARD = 'Card/RECHARGE_CARD';
const SAVE_CYCLE_CAR = 'Card/SAVE_CYCLE_CAR';
const GET_CYCLE_CARD = 'Card/GET_CYCLE_CARD';
const GET_CYCLE_PERIODS = 'Card/GET_CYCLE_PERIODS';
const REGISTER_PERIODS = 'Card/REGISTER_PERIODS';
const GET_BILLING_CYCLES = 'card/GET_BILLING_CYCLES';
const GET_CYCLES = 'card/GET_CYCLES';
const GET_CARD = 'card/GET_CARD';
const DELETE_CARD = 'card/DELETE_CARD';
const REGISTER_EXECUTIVE = 'card/REGISTER_EXECUTIVE';
const GET_EXECUTIVE = 'card/GET_EXECUTIVE';
const ASSIST_CARD = 'card/ASSIST_CARD';
const GET_ASSIST_CARD = 'card/GET_ASSIST_CARD';
const EDIT_BILLING_CYCLES = 'card/EDIT_BILLING_CYCLES';
const LOAD_EXECUTIVES_FILE = 'card/LOAD_EXECUTIVES_FILE';
const SAVE_EXECUTIVES_FILE = 'card/SAVE_EXECUTIVES-FILE';
const GET_PERIOD_BILLING = 'card/GET_PERIOD_BILLING';
const UPDATE_BILLING_PAYMENT_PERSON = 'card/UPDATE_BILLING_PAYMENT_PERSON';

export default {
  START_FETCH,
  END_FETCH,
  API_CALL,
  MESSAGE,
  FETCH_CARD_DATA,
  UPDATE_CARD_DATA,
  RECHARGE_CARD,
  SAVE_CYCLE_CAR,
  GET_CYCLE_CARD,
  GET_CYCLE_PERIODS,
  REGISTER_PERIODS,
  GET_BILLING_CYCLES,
  GET_CYCLES,
  GET_CARD,
  DELETE_CARD,
  REGISTER_EXECUTIVE,
  GET_EXECUTIVE,
  ASSIST_CARD,
  GET_ASSIST_CARD,
  EDIT_BILLING_CYCLES,
  LOAD_EXECUTIVES_FILE,
  SAVE_EXECUTIVES_FILE,
  GET_PERIOD_BILLING,
  UPDATE_BILLING_PAYMENT_PERSON,
};
