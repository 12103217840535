import types from './types';

const bank = (state = {}, action) => {
  switch (action.type) {
    case types.START_FETCH: {
      return {
        ...state,
        loading: true,
      };
    }
    case types.END_FETCH: {
      const { error, payload } = action;
      return {
        ...state,
        loading: false,
        error: error ? payload : null,
      };
    }
    case types.UPDATE_BANK: {
      const { list } = action.payload;

      return {
        ...state,
        list,
      };
    }
    default:
      return state;
  }
};

export default bank;
