import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';
import { withNamespaces } from 'react-i18next';
import { Form, TextInput } from '../../../../../components';

const FormLogin = ({
                     t,
                     handleSubmit,
                     handleInputChange,
                     data,
                     isPasswordExpire,
                   }) => {
  const { password, confirmPassword } = data;
  return (
    <div className="FormLogin">
      <div className="column is-full" 
           style={{ textAlign: 'center', 
           fontSize: '1.1em', 
           fontWeight: '600',
           color: '#751644' }}>
        <span>{isPasswordExpire === 1 ? t('passwordHasExpired') : t('enterYourNewPassword')}</span>
      </div>
      <Form
        className="column is-full no-background"
        submitText={t('send')}
        onSubmit={(event) => {
          event.preventDefault();
          handleSubmit();
        }}
        buttonClasses="button-chile center"
        band={'#FFFFFF'}
        isBTNFull
      >
        <div>
          <TextInput
            name="password"
            placeholder={t('password')}
            type="password"
            onChange={handleInputChange}
            value={password}
            login={true}
          />
          <TextInput
            name="confirmPassword"
            placeholder={t('confirmPassword')}
            type="password"
            onChange={handleInputChange}
            value={confirmPassword}
            login={true}
          />
          <ul className='pass-specs'>
            <li>
              <span>{t('lengthToCharacters')}</span>
            </li>
            <li>
              <span>{t('doNotIncludeTheUsername')}</span>
            </li>
            <li>
              <span>{t('theyMustNotHave')}</span>
            </li>
          </ul>
        </div>
      </Form>
    </div>
  );
};

FormLogin.propTypes = {
  t: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleInputChange: PropTypes.func.isRequired,
  data: PropTypes.shape({
    password: PropTypes.string.isRequired,
    confirmPassword: PropTypes.string.isRequired,
  }).isRequired,
};

export default withNamespaces()(FormLogin);
