/**
 * Created by Jonas on 09/09/19.
 */

import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';
import { 
  ToggleBTN, 
  SelectSingle, 
  TextInput, 
  Form, 
  Panel } from '../../components';
import { getPath, formatAmount } from '../../util';

const PaymentConditionForm = ({
  t,
  onSubmit,
  handleInputChange,
  handleOnInputChange,
  handlePercentageChange,
  handleSelectChange,
  isClient,
  client,
  provider,
  persons,
  baseDays,
  discountRate,
  receiverDiscountShare,
  clientPayment,
  editable,
  startCountingFrom,
  //limitAmount,
  hidden,
  band,
  inputValue,
  clientEdit,
  title,
  editPercentage,
  isProvider,
  isConditionClient,
  isConditionProvider,
  closeModal,
  codes,
  commerceCode,
  codeDescription,
  reject,
  rejectReason,
  providerEdit,
  isBank,
  related,
  typeUser,
  numberCommerceCode,
  pathname,
  usedInModals,
  user_type,
  nclCommission,
  commission,
  toggleBTNStatus,
  handleToggleChange,
}) => (
  <div className={ usedInModals ? "modal-scroll-container" : ""} >
    <Panel
      usedInModals={usedInModals}
      headingText={
        usedInModals ?
        '' :
        pathname ? '' : title ? title : band ? t('commercialConditions') : editable ? t('edit') : t('commercialConditions')}>
      <Form
        submitText={band ? t('accept') : t('save')}
        onSubmit={(event) => {
          event.preventDefault();
          onSubmit();
        }}
        buttonClasses={`mr-8px ${hidden === true ? 'is-hidden' : 'is-hidden'}`}
        className={usedInModals && 'no-background'}
      >
        <div className="field is-horizontal" style={{ display: reject ? 'none' : '' }}>
          <div className="field-body columns">
            <div className="column">
              <TextInput
                name="rejectReason"
                label={t('rejectReason')}
                placeholder={t('rejectReason')}
                type="text"
                onChange={handleInputChange}
                value={rejectReason}
                isRequired={true}
                disabled={pathname}
              />
            </div>
          </div>
        </div>
        <div className="column" style={{ marginBottom: "1rem" }}>
          <h2 className="title is-4">
            {t('billPayment')}
          </h2>
          <h3 className="subtitle is-3">
            {isClient ? t('providerPaymentCondition') : t('clientPaymentCondition')}
          </h3>
        </div>
        <div className="field is-horizontal">
          <div className="field-body columns">
            <div className="column" style={{ display: (editable ? 'none' : '') }}>
              <SelectSingle
                  name={isClient ? 'provider' : 'client'}
                  label={t(isClient ? 'provider' : 'customer')}
                  placeholder={t('SelectOption')}
                  onChange={handleSelectChange}
                  options={handleOnInputChange === null || inputValue.length > 1 ? [
                    ...persons.map((person, index) => ({
                      label: `${person.idPerson.name} - ${getPath(['idPerson', 'idDocumentType', 'document_type'], person)} ${person.idPerson.document_number}`,
                      value: index,
                    })),
                  ] : [{}]}
                  value={ isClient ? provider + 1 : client + 1 }
                  selectClasses="is-fullwidth"
                  controlClasses="has-icons-left"
                  icon="far fa-user"
                  iconPosition="is-left"
                  isDisabled={editable}
                  isSearchable
                  onInputChange={handleOnInputChange}
                  inputValue={inputValue}
                />
              </div>
              <div className="column" style={{ display: (editable ? '' : 'none') }}>
                <TextInput
                  name="clientEdit"
                  label={t(isClient ? 'provider' : 'customer')}
                  value={isBank ? providerEdit : clientEdit}
                  onChange={handleInputChange}
                  placeholder="client"
                  type="text"
                  controlClasses="is-expanded"
                  //min="0"
                  disabled={editable}
                />
              </div>
            <div className="column" style={{ display: ((editable && isBank) ? '' : 'none') }}>
              <TextInput
                name="providerEdit"
                label={t(isClient ? 'customer' : 'provider')}
                value={clientEdit}
                onChange={handleInputChange}
                placeholder="client"
                type="text"
                controlClasses="is-expanded"
                //min="0"
                disabled={editable}
              />
            </div>
            <div className="column" style={{ display: ((editable && isBank) ? 'none' : '') }}>
              <TextInput
                name="baseDays"
                label={t('arrangedDays')}
                value={baseDays}
                onChange={handleInputChange}
                placeholder="0"
                type="number"
                controlClasses="is-expanded has-icons-right"
                //min="0"
                step="1"
                disabled={band}
              />
            </div>
          </div>
        </div>
        <div className="field is-horizontal"
            style={{ display: ((editable && isBank) ? 'none' : 'none') }}>
          <div className="field-body columns">
            <div className="column">
              <TextInput
                name="baseDays"
                label={t('arrangedDays')}
                value={baseDays}
                onChange={handleInputChange}
                placeholder="0"
                type="number"
                controlClasses="is-expanded has-icons-right"
                //min="0"
                step="1"
                disabled={band}
              />
            </div>
          </div>
        </div>
        <div className="field is-horizontal">
          <div className="field-body columns">
            <div className="column" style={{ display: ((editable && isBank) ? '' : 'none') }}>
              <TextInput
                name="baseDays"
                label={t('arrangedDays')}
                value={baseDays}
                onChange={handleInputChange}
                placeholder="0"
                type="number"
                controlClasses="is-expanded has-icons-right"
                //min="0"
                step="1"
                disabled={band || pathname}
              />
            </div>
            <div className="column">
              <SelectSingle
                name="startCountingFrom"
                label={t('startCountingFrom')}
                placeholder={t('SelectOption')}
                onChange={handleSelectChange}
                options={
                  [{
                    label: t('receptionDate'),
                    value: 0,
                  }, {
                    label: t('approvalDate'),
                    value: 1,
                  }]}
                selectClasses="is-fullwidth"
                controlClasses="has-icons-left"
                icon="far fa-user"
                iconPosition="is-left"
                value={startCountingFrom}
                isClearable={false}
                isDisabled={band || pathname}
              />
            </div>
            <div className="column" style={{ display: ((typeUser === 'issuing_bank') ? '' : 'none') }}>
              <TextInput
                name="commission"
                label={t('commission')}
                value={commission}
                onChange={handleInputChange}
                placeholder="0"
                type="float"
                controlClasses="is-expanded has-icons-right"
                //min="0"
                //step="1"
                disabled={band || pathname}
                inputClasses="has-text-right"
              />
            </div>
            {/*
                (editPercentage) == null && 
                <div className="column" /*style={{ display: (hidden ? 'none' : '') }}>
                  <TextInput
                    name="discountRate"
                    label={t('chargeForService')}
                    value={discountRate}
                    onChange={handleInputChange}
                    placeholder="0"
                    type="number"
                    controlClasses="is-expanded has-icons-right"
                    min="0"
                    step="0.01"
                    max="100"
                    iconPosition="is-right"
                    icon="fas fa-percent"
                    disabled={band || isConditionClient || isProvider}
                  />
                </div>     
              */}
            {/*
                ((editPercentage > 0) && (editPercentage < 100)) &&
                <div className="column" /*style={{ display: (hidden ? 'none' : '') }}>
                  <TextInput
                    name="discountRate"
                    label={t('chargeForService')}
                    value={discountRate}
                    onChange={handleInputChange}
                    placeholder="0"
                    type="number"
                    controlClasses="is-expanded has-icons-right"
                    min="0"
                    step="0.01"
                    max="100"
                    iconPosition="is-right"
                    icon="fas fa-percent"
                    disabled={band || isConditionClient || isProvider}
                  />
                </div>
              */}
            {/*
                ((editPercentage == 0) && !isConditionClient) &&
                <div className="column" /*style={{ display: (hidden ? 'none' : '') }}>
                  <TextInput
                    name="discountRate"
                    label={t('chargeForService')}
                    value={discountRate}
                    onChange={handleInputChange}
                    placeholder="0"
                    type="number"
                    controlClasses="is-expanded has-icons-right"
                    min="0"
                    step="0.01"
                    max="100"
                    iconPosition="is-right"
                    icon="fas fa-percent"
                    disabled={band || isConditionClient || isProvider}
                  />
                </div>
              */}
            {/*
                ((editPercentage == 100) && !isConditionProvider) &&
                <div className="column" /*style={{ display: (hidden ? 'none' : '') }}>
                  <TextInput
                    name="discountRate"
                    label={t('chargeForService')}
                    value={discountRate}
                    onChange={handleInputChange}
                    placeholder="0"
                    type="number"
                    controlClasses="is-expanded has-icons-right"
                    min="0"
                    step="0.01"
                    max="100"
                    iconPosition="is-right"
                    icon="fas fa-percent"
                    disabled={band || isConditionClient || isProvider}
                  />
                </div>
              */}
          </div>
        </div>
          {/*
            (editPercentage == null) &&
            <div className="field is-horizontal" /*style={{ display: (hidden ? 'none' : '') }}>
              <div className="field-body columns">
                <div className="column">
                  <TextInput
                    name="receiverDiscountShare"
                    label={t('providerPercentageCharge')}
                    value={receiverDiscountShare}
                    onChange={handleInputChange/*handlePercentageChange}
                    placeholder="100"
                    type="number"
                    controlClasses="is-expanded has-icons-right"
                    min="0"
                    step="0.01"
                    max="100"
                    iconPosition="is-right"
                    icon="fas fa-percent"
                    disabled={band || isConditionClient || isProvider}
                  />
                </div>
                <div className="column" /*style={{ display: (hidden ? 'none' : '') }}>
                  <div className="field is-6 text-black" style={{ marginBottom: '12px' }}>
                    {`${t('clientPercentageCharge')}:`}
                  </div>
                  {`${Number(clientPayment)
                    .toFixed(2)} %`}
                </div>
              </div>
            </div>
          */}
          {/*
            ((editPercentage > 0) && (editPercentage < 100)) &&
            <div className="field is-horizontal" /*style={{ display: (hidden ? 'none' : '') }}>
              <div className="field-body columns">
                <div className="column">
                  <TextInput
                    name="receiverDiscountShare"
                    label={t('providerPercentageCharge')}
                    value={receiverDiscountShare}
                    onChange={handleInputChange/*handlePercentageChange}
                    placeholder="100"
                    type="number"
                    controlClasses="is-expanded has-icons-right"
                    min="0"
                    step="0.01"
                    max="100"
                    iconPosition="is-right"
                    icon="fas fa-percent"
                    disabled={band || isConditionClient || isProvider}
                  />
                </div>
                <div className="column" /*style={{ display: (hidden ? 'none' : '') }}>
                  <div className="field is-6 text-black" style={{ marginBottom: '12px' }}>
                    {`${t('clientPercentageCharge')}:`}
                  </div>
                  {`${Number(clientPayment)
                    .toFixed(2)} %`}
                </div>
              </div>
            </div>
          */}
          {/*
            ((editPercentage == 0) && !isConditionClient) &&
            <div className="field is-horizontal" /*style={{ display: (hidden ? 'none' : '') }}>
              <div className="field-body columns">
                <div className="column">
                  <TextInput
                    name="receiverDiscountShare"
                    label={t('providerPercentageCharge')}
                    value={receiverDiscountShare}
                    onChange={handleInputChange/*handlePercentageChange}
                    placeholder="100"
                    type="number"
                    controlClasses="is-expanded has-icons-right"
                    min="0"
                    step="0.01"
                    max="100"
                    iconPosition="is-right"
                    icon="fas fa-percent"
                    disabled={band || isConditionClient || isProvider}
                  />
                </div>
                <div className="column" /*style={{ display: (hidden ? 'none' : '') }}>
                  <div className="field is-6 text-black" style={{ marginBottom: '12px' }}>
                    {`${t('clientPercentageCharge')}:`}
                  </div>
                  {`${Number(clientPayment)
                    .toFixed(2)} %`}
                </div>
              </div>
            </div>
          */}
          {/*
            ((editPercentage == 100) && !isConditionProvider) && 
            <div className="field is-horizontal" /*style={{ display: (hidden ? 'none' : '') }}>
              <div className="field-body columns">
                <div className="column">
                  <TextInput
                    name="receiverDiscountShare"
                    label={t('providerPercentageCharge')}
                    value={receiverDiscountShare}
                    onChange={handleInputChange/*handlePercentageChange}
                    placeholder="100"
                    type="number"
                    controlClasses="is-expanded has-icons-right"
                    min="0"
                    step="0.01"
                    max="100"
                    iconPosition="is-right"
                    icon="fas fa-percent"
                    disabled={band || isConditionClient || isProvider}
                  />
                </div>
                <div className="column" /*style={{ display: (hidden ? 'none' : '') }}>
                  <div className="field is-6 text-black" style={{ marginBottom: '12px' }}>
                    {`${t('clientPercentageCharge')}:`}
                  </div>
                  {`${Number(clientPayment)
                    .toFixed(2)} %`}
                </div>
              </div>
            </div>
          */}
          {/*<div className="field is-horizontal"
              style={{ display: (band ? '' : isProvider ? 'none' : '') }}>
            <div className="field-body columns">
              <div className="column">
                <TextInput
                  name="limitAmount"
                  label={t('limitAmount')}
                  value={limitAmount}
                  onChange={handleInputChange}
                  placeholder="0.00"
                  type="text"
                  controlClasses="is-expanded has-icons-right"
                  //min="0"
                  //step="0.01"
                  //max="999999999999999.99"
                  iconPosition="is-right"
                  icon="fas fa-money-bill-wave"
                  disabled={band}
                />
              </div>
            </div>
          </div>*/}
        {related && user_type !== 'NCL' &&
          <>
            <div className="field is-horizontal" style={{ display: typeUser !== 'issuing_bank' ? '' : 'none' }}>
              <div className="field-body columns">
                <div className="column" style={{ display: (pathname ? 'none' : '') }}>
                  <SelectSingle
                    name={'commerceCode'}
                    label={t('commerceCode')}
                    placeholder={t('SelectOption')}
                    onChange={handleSelectChange}
                    options={[
                      ...codes.map((code, index) => ({
                        label: code.commerce_code,
                        value: index,
                      })),
                    ]}
                    value={commerceCode}
                    selectClasses="is-fullwidth"
                    controlClasses="has-icons-left"
                    icon="far fa-user"
                    iconPosition="is-left"
                    isDisabled={band}
                  />
                </div>
                <div className="column" style={{ display: (pathname ? '' : 'none') }}>
                  <TextInput
                    name={'commerceCode'}
                    label={t('commerceCode')}
                    value={numberCommerceCode}
                    onChange={handleInputChange}
                    placeholder={t('commerceCode')}
                    type="text"
                    controlClasses="is-expanded has-icons-right"
                    disabled={editable}
                  />
                </div>
                <div className="column" style={{ display: (pathname ? '' : 'none') }}>
                  <TextInput
                    name="discountRate"
                    label={typeUser==='acquiring_bank' ? t('discountRate') : t('chargeForService')}
                    value={formatAmount(discountRate)}
                    onChange={handleInputChange}
                    placeholder="0"
                    type="number"
                    controlClasses="is-expanded has-icons-right"
                    min="0"
                    step="0.01"
                    max="100"
                    iconPosition="is-right"
                    icon="fas fa-percent"
                    disabled={pathname}
                  />
                </div>
                {/* <div className="column">
                  <div style={{ display: 'flex' }}>
                    <p style={{ marginTop: '1.5em' }}>
                      {codeDescription}
                    </p>
                  </div>
                </div> */}
              </div>
            </div>
            <div className="field is-horizontal"  style={{ display: (typeUser !== 'issuing_bank' && typeUser !== 'buyer') ? '' : 'none' }}>
              <div className="field-body columns">
                <div className="column is-half" style={{ display: (pathname ? 'none' : '') }}>
                  <TextInput
                    name="discountRate"
                    label={typeUser==='acquiring_bank' ? t('discountRate') : t('chargeForService')}
                    value={formatAmount(discountRate)}
                    onChange={handleInputChange}
                    placeholder="0"
                    type="number"
                    controlClasses="is-expanded has-icons-right"
                    min="0"
                    step="0.01"
                    max="100"
                    iconPosition="is-right"
                    icon="fas fa-percent"
                    disabled={band || isConditionClient || isProvider}
                  />
                </div>     
              </div>     
            </div> 
            <div className="field is-horizontal" style={{ display: typeUser !== 'issuing_bank' ? '' : 'none' }}>
              <div className="field-body columns">
                <div className="column">
                  <TextInput
                    name="receiverDiscountShare"
                    label={t('providerPercentageCharge')}
                    value={formatAmount(receiverDiscountShare)}
                    onChange={handleInputChange/*handlePercentageChange*/}
                    placeholder="100"
                    type="number"
                    controlClasses="is-expanded has-icons-right"
                    min="0"
                    step="0.01"
                    max="100"
                    iconPosition="is-right"
                    icon="fas fa-percent"
                    disabled={band || isConditionClient || isProvider || pathname}
                  />
                </div>
                <div className="column">
                  <TextInput
                    name="senderDiscountShare"
                    label={t('clientPercentageCharge')}
                    value={formatAmount(clientPayment)}
                    onChange={handleInputChange/*handlePercentageChange*/}
                    placeholder="100"
                    type="number"
                    controlClasses="is-expanded has-icons-right"
                    min="0"
                    step="0.01"
                    max="100"
                    iconPosition="is-right"
                    icon="fas fa-percent"
                    disabled
                  />
                </div>
                {/* <div className="column">
                  <div className="field is-6 text-black" style={{ marginBottom: '12px', fontWeight: '600' }}>
                    {`${t('clientPercentageCharge')}`}
                  </div>
                  {`${Number(clientPayment)
                    .toFixed(2)} %`}
                </div> */}

              </div>
            </div>
          </>
        }
        {related && user_type === 'NCL' &&
          <div className="field is-horizontal">
            <div className="field-body columns">
              <div className="column">
                <TextInput
                  name="NCLCommission"
                  label={t('NCLCommission')}
                  value={formatAmount(nclCommission)}
                  onChange={handleInputChange}
                  placeholder="0"
                  type="number"
                  controlClasses="is-expanded has-icons-right"
                  min="0"
                  step="0.01"
                  max="100"
                  iconPosition="is-right"
                  icon="fas fa-percent"
                  disabled={true}
                />
              </div>
            </div>
          </div>
        }
        <div className="field is-horizontal">
          <div className="field-body columns">
            <div className="column">
              <label className='label' style={{ marginLeft: "15px", fontSize: '.9rem' }}>BPP</label>
              <div 
                style={{display: 'flex', alignItems: 'center ', gap: '15px'}} 
              >
                <ToggleBTN 
                  color={pathname !== '/related-users' ? 'is-link' : ''}
                  isOn={Number(toggleBTNStatus)}
                  disabled={pathname === '/related-users'}
                  handleClick={pathname !== '/related-users' && handleToggleChange}
                />
                <p style={{fontSize: '.8em'}}>
                  <i className="fas fa-info has-text-primary mr-8px"/>
                  {t('BPPDesc')}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div
          className="field is-grouped is-grouped-centered"
          style={{ display: hidden === false ? '' : 'none', width: '50%', margin: '0 auto' }}>
            <button
              type="button"
              className='button custom-btn is-block is-danger'
              style={{ color: '#C4C4C4', flex: '1', maxWidth: '200px', margin: '0 10px' }}
              onClick={closeModal.bind(this)}
            >
              {['seller', 'buyer'].includes(typeUser) || related ? t('goBack') : t('cancel')}
            </button>
            {
              !(['seller', 'buyer'].includes(typeUser)) && (
                <button
                  type="button"
                  className="button custom-btn is-block is-primary"
                  style={{ display: related ? 'none' : '', flex: '1', maxWidth: '200px', margin: '0 10px' }}
                  onClick={onSubmit.bind(this)}
                >
                  {t('save')}
                </button>
              )
            }
        </div>
      </Form>
    </Panel>
  </div>
  );

PaymentConditionForm.propTypes = {
  t: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  handleInputChange: PropTypes.func.isRequired,
  handleOnInputChange: PropTypes.func,
  handlePercentageChange: PropTypes.func,
  handleSelectChange: PropTypes.func.isRequired,
  isClient: PropTypes.bool,
  client: PropTypes.number,
  provider: PropTypes.number,
  persons: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  baseDays: PropTypes.number.isRequired,
  discountRate: PropTypes.number,
  receiverDiscountShare: PropTypes.number,
  //limitAmount: PropTypes.string.isRequired,
  clientPayment: PropTypes.number,
  editable: PropTypes.bool.isRequired,
  band: PropTypes.bool,
  hidden: PropTypes.bool,
  inputValue: PropTypes.string,
  closeModal: PropTypes.func.isRequired,
  typeUser: PropTypes.string.isRequired,
  usedInModals: PropTypes.bool,
  toggleBTNStatus: PropTypes.oneOfType([PropTypes.bool, PropTypes.number, PropTypes.string]),
};

PaymentConditionForm.defaultProps = {
  related: false,
  reject: true,
  isBank: false,
  isClient: false,
  client: null,
  provider: null,
  handleOnInputChange: null,
  inputValue: '',
  pathname: false,
  usedInModals: false,
};


export default PaymentConditionForm;
