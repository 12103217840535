import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './styles.scss';
import { compose } from 'redux';
import { withNamespaces } from 'react-i18next';
import { withPermissions } from '../../../../hoc';
import { Form, TextInput, SelectSingle } from '../../../../components';
import { TOAST_CONFIG } from '../../../../config/constants';

class FormFinancingProducts extends Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  getInitialState = () => ({
    id: undefined,
    name: '',
    rate: undefined,
    installments: undefined,
    period: undefined,
    type: '',
    bank_id: '',
    errorName: '',
    errorRate: '',
    errorInstallments: '',
    errorPeriod: '',
    errorType: '',
    msjName: 'null',
    msjRate: 'null',
    msjInstallments: 'null',
    msjPeriod: 'null',
    msjType: 'null',
    redirectBack: false,
  });

  componentDidMount() {
    const {
      data: { id },
      data,
    } = this.props;
    if (id !== undefined) {
      this.loadData(data);
    }
  }

  resetState = () => {
    this.setState(this.getInitialState());
  };

  loadData = async (data) => {
    await this.setState({
      id: data.id,
      name: data.name,
      rate: data.rate,
      installments: data.installments,
      period: data.period,
      type: data.type,
    });
  };

  createProduct = async () => {
    const { addFinancingProducts, t, showMessage } = this.props;
    const {
      name,
      rate,
      installments,
      period,
      type,
      errorName,
      errorRate,
      errorInstallments,
      errorPeriod,
      errorType,
    } = this.state;
    const data = new URLSearchParams();
    data.append('name', name);
    data.append('rate', rate);
    data.append('installments', installments);
    data.append('period', period);
    data.append('type', type);

    if (name.length === 0) {
      await this.setState({ errorName: 'is-danger', msjName: 'requiredName' });
    } else {
      await this.setState({ errorName: 'is-success', msjName: 'null' });
    }
    if (rate === undefined || (rate < 0 || rate > 100)) {
      await this.setState({ errorRate: 'is-danger', msjRate: 'requiredRate' });
    } else {
      await this.setState({ errorRate: 'is-success', msjRate: 'null' });
    }
    if (installments === undefined || installments < 0) {
      await this.setState({ errorInstallments: 'is-danger', msjInstallments: 'requiredInstallments' });
    } else {
      await this.setState({ errorInstallments: 'is-success', msjInstallments: 'null' });
    }
    if (period !== 1 && period !== 30 && period !== 365) {
      await this.setState({ errorPeriod: 'is-danger2', msjPeriod: 'requiredPeriod' });
    } else {
      await this.setState({ errorPeriod: 'is-success2', msjPeriod: 'null' });
    }
    if (type !== 'avance' && type !== 'defer') {
      await this.setState({ errorType: 'is-danger2', msjType: 'requiredTypeFinance' });
    } else {
      await this.setState({ errorType: 'is-success2', msjType: 'null' });
    }

    if (errorName !== 'is-success' || errorRate !== 'is-success'
      || errorInstallments !== 'is-success' || errorPeriod !== 'is-success2'
      || errorType !== 'is-success2') {
      return;
    }

    addFinancingProducts({
      data,
      callback: () => {
        showMessage({
          message: t('addFinanceProductSuccess'),
          config: TOAST_CONFIG.SUCCESS,
        });
        this.resetState();
        this.setState({ redirectBack: true });
      },
    });
  };

  editProduct = async () => {
    const { editFinancingProducts, t, showMessage } = this.props;
    const {
      id,
      name,
      rate,
      installments,
      period,
      type,
      errorName,
      errorRate,
      errorInstallments,
      errorPeriod,
      errorType,
    } = this.state;
    const data = new URLSearchParams();
    data.append('id', id);
    data.append('name', name);
    data.append('rate', rate);
    data.append('installments', installments);
    data.append('period', period);
    data.append('type', type);

    if (name.length === 0) {
      await this.setState({ errorName: 'is-danger', msjName: 'requiredName' });
    } else {
      await this.setState({ errorName: 'is-success', msjName: 'null' });
    }
    if (rate === undefined || (rate < 0 || rate > 100)) {
      await this.setState({ errorRate: 'is-danger', msjRate: 'requiredRate' });
    } else {
      await this.setState({ errorRate: 'is-success', msjRate: 'null' });
    }
    if (installments === undefined || installments < 0) {
      await this.setState({ errorInstallments: 'is-danger', msjInstallments: 'requiredInstallments' });
    } else {
      await this.setState({ errorInstallments: 'is-success', msjInstallments: 'null' });
    }
    if (period !== 1 && period !== 30 && period !== 365) {
      await this.setState({ errorPeriod: 'is-danger2', msjPeriod: 'requiredPeriod' });
    } else {
      await this.setState({ errorPeriod: 'is-success2', msjPeriod: 'null' });
    }
    if (type !== 'avance' && type !== 'defer') {
      await this.setState({ errorType: 'is-danger2', msjType: 'requiredTypeFinance' });
    } else {
      await this.setState({ errorType: 'is-success2', msjType: 'null' });
    }

    if (errorName !== 'is-success' || errorRate !== 'is-success'
      || errorInstallments !== 'is-success' || errorPeriod !== 'is-success2'
      || errorType !== 'is-success2') {
      return;
    }

    editFinancingProducts({
      id,
      data,
      callback: () => {
        showMessage({
          message: t('addFinanceProductSuccess'),
          config: TOAST_CONFIG.SUCCESS,
        });
        this.resetState();
        this.setState({ redirectBack: true });
      },
    });
  };

  handlePeriodChange = (newPeriod) => {
    this.setState({
      period: newPeriod.value,
    });
  };

  handleTypeChange = (newType) => {
    this.setState({
      type: newType.value,
    });
  };

  handleInputChange = (event) => {
    const {
      target: {
        checked, value: targetValue, type, name,
      },
    } = event;
    const value = type === 'checkbox' ? checked : targetValue;
    this.setState({
      [name]: value,
    });
  };

  render() {
    const {
      t,
      redirectBack,
      data,
    } = this.props;
    const {
      name,
      rate,
      installments,
      errorName,
      errorRate,
      errorInstallments,
      errorPeriod,
      errorType,
      msjName,
      msjRate,
      msjInstallments,
      msjPeriod,
      msjType,
      redirectBack: stateRedirectBack,
    } = this.state;

    if (stateRedirectBack === true) {
      redirectBack();
    }

    return (
      <div>
        <Form
          submitText={t('save')}
          onSubmit={(event) => {
            event.preventDefault();
            if (data.id !== undefined && data.id > 0) {
              this.editProduct();
            } else {
              this.createProduct();
            }
          }}
          buttonClasses="mr-8px"
        >
          <div className="field is-horizontal">
            <div className="field-body columns">
              <div className="column is-12">
                <TextInput
                  name="name"
                  label={t('productName')}
                  placeholder={t('name')}
                  type="text"
                  value={name}
                  onChange={this.handleInputChange}
                  controlClasses="is-expanded"
                  inputClasses={`has-text-left ${errorName}`}
                  helpText={t(msjName)}
                />
              </div>
            </div>
          </div>

          <div className="field is-horizontal">
            <div className="field-body columns">
              <div className="column is-6">
                <TextInput
                  name="rate"
                  label={t('interestRate')}
                  placeholder="0"
                  type="number"
                  value={rate}
                  onChange={this.handleInputChange}
                  controlClasses="is-expanded has-icons-right"
                  inputClasses={`has-text-right ${errorRate}`}
                  min="0"
                  max="100"
                  step="0.01"
                  iconPosition="is-right"
                  icon="fas fa-percent"
                  helpText={t(msjRate)}
                />
              </div>
              <div className="column is-6">
                <TextInput
                  name="installments"
                  label={t('numberInstallments')}
                  placeholder="0"
                  type="number"
                  value={installments}
                  onChange={this.handleInputChange}
                  controlClasses="is-expanded has-icons-right"
                  inputClasses={`has-text-right ${errorInstallments}`}
                  min="0"
                  iconPosition="is-right"
                  helpText={t(msjInstallments)}
                />
              </div>
            </div>
          </div>

          <div className="field is-horizontal">
            <div className="field-body columns">
              <div className="column is-6">
                <SelectSingle
                  name="period"
                  label={t('period')}
                  placeholder={t('SelectOption')}
                  onChange={this.handlePeriodChange}
                  options={
                    [{
                      label: t('yearly'),
                      value: 365,
                    }, {
                      label: t('monthly'),
                      value: 30,
                    }, {
                      label: t('daily'),
                      value: 1,
                    }]}
                  selectClasses="is-fullwidth"
                  controlClasses="has-icons-left"
                  icon="far fa-user"
                  iconPosition="is-left"
                  isClearable={false}
                  styles={errorPeriod}
                  helpText={t(msjPeriod)}
                />
              </div>
              <div className="column is-6">
                <SelectSingle
                  name="type"
                  label={t('typeFinance')}
                  placeholder={t('SelectOption')}
                  onChange={this.handleTypeChange}
                  options={
                    [{
                      label: t('avance'),
                      value: 'avance',
                    }, {
                      label: t('defer'),
                      value: 'defer',
                    }]}
                  selectClasses="is-fullwidth"
                  controlClasses="has-icons-left"
                  icon="far fa-user"
                  iconPosition="is-left"
                  isClearable={false}
                  styles={errorType}
                  helpText={t(msjType)}
                />
              </div>
            </div>
          </div>
        </Form>
      </div>
    );
  }
}

FormFinancingProducts.propTypes = {
  t: PropTypes.func.isRequired,
  addFinancingProducts: PropTypes.func.isRequired,
  showMessage: PropTypes.func.isRequired,
  editFinancingProducts: PropTypes.func.isRequired,
  redirectBack: PropTypes.func.isRequired,
  data: PropTypes.shape(Object).isRequired,
};

export default compose(
  withPermissions,
  withNamespaces(),
)(FormFinancingProducts);
