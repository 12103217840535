import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import { Form, Panel, TextInput, SelectSingle, TextArea } from '../../components';
import { supportActions } from '../../state/ducks/support';
import { roleActions } from '../../state/ducks/role';
import { TOAST_CONFIG } from '../../config/constants';

class CreateFaq extends Component {
    constructor(props) {
        super(props);
        const {
            location: { state },
        } = props;
        this.state = { ...this.getInitialState(), ...state };
    }
    
    getInitialState = () => ({
        question: '',
        link: '',
        answer: '',
        roles: [],
        role: '',
    });

    resetState = () => {
        this.setState(this.getInitialState());
    };

    componentDidMount() {
        
        const { 
            supportRoles,
        } = this.props;

        supportRoles({
            callback: (response) => {
                this.setState({roles: response.data});
            }
        });

    };

    handleInputChange = (event) => {
        const {
            target: {
                checked, value: targetValue, type, name,
            },
        } = event;
        const value = type === 'checkbox' ? checked : targetValue;
        this.setState({
            [name]: value,
        });
    };

    handleSelectRole = (newVal, label) => {
        const {
            roles,
        } = this.state;
    
        this.setState({
            role: roles[newVal.value].id,
        });
    };

    registerQuestion = () => {
        const {
            t,
            saveFaq,
            showMessage,
        } = this.props;
        const { 
            question,
            link,
            answer,
            role,
        } = this.state;

        const data = {
            question, 
            answer,
            link_support: link,
            role,
        }

        saveFaq({
            data,
            callback: (response) => {
                showMessage({
                    message: t('createQuestionSuccess'),
                    config: TOAST_CONFIG.SUCCESS,
                });
                this.resetState();
                window.location.reload();
            }
        });
    }

    render() {

        const { t } = this.props;
        const { 
            question,
            link,
            answer,
            role,
            roles,
        } = this.state;

        return(
            <Panel headingText={t('updatePlatformFaq')}>
                <Form
                    submitText={t('save')}
                    onSubmit={(event) => {
                        event.preventDefault();
                        this.registerQuestion();
                    }}
                    buttonClasses="mr-8px"
                >
                    <div className="field is-horizontal">
                        <div className="field-body columns">
                            <div className="column is-one-third">
                                <SelectSingle
                                    name={'role'}
                                    label={t('rol')}
                                    selectClasses="is-fullwidth"
                                    placeholder={t('Select')}
                                    onChange={this.handleSelectRole}
                                    options={[
                                        ...roles.map((item, index) => ({
                                        label: t(item.name),
                                        value: index,
                                        })),
                                    ]}
                                    value={role}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="field is-horizontal">
                        <div className="field-body columns">
                            <div className="column">
                                <TextInput
                                    name="question"
                                    label={t('question')}
                                    value={question}
                                    onChange={this.handleInputChange}
                                    placeholder={t('question')}
                                    type="text"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="field is-horizontal">
                        <div className="field-body columns">
                            <div className="column">
                                <TextInput
                                    name="link"
                                    label={t('supportLink')}
                                    value={link}
                                    onChange={this.handleInputChange}
                                    placeholder={t('supportLink')}
                                    type="text"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="field is-horizontal">
                        <div className="field-body columns">
                            <div className="column">
                                <TextArea
                                    name="answer"
                                    label={t('answer')}
                                    value={answer}
                                    onChange={this.handleInputChange}
                                    placeholder={t('answer')}   
                                    controlClasses="is-expanded"
                                />
                            </div>
                        </div>
                    </div>
                </Form>
            </Panel>
        );
    }

}

CreateFaq.propTypes = {
    t: PropTypes.func.isRequired,
    saveFaq: PropTypes.func.isRequired,
};

const mapStateToProps = ({ support, auth, router, role}) => (
    {
        auth,
        support,
        router,
        role,
    }
);

export default compose(
    connect(mapStateToProps, {
        ...supportActions,
        ...roleActions,
    }),
    withNamespaces(),
)(CreateFaq);