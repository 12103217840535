import { webpayActions, webpayTypes } from '../ducks/webpay';

const createTransaction = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== webpayTypes.GET_WEBPAY) {
    return;
  }

  const {
    callback = () => {
    },
  } = action.payload;

  dispatch({
    type: webpayTypes.API_CALL,
    payload: {
      config: {
        method: 'POST',
        url: 'trans-bank/webpay-mall-plus-create',
      },
      authorization: false,
      onStart: webpayActions.startFetch,
      onComplete: ({ data: { success, data } }) => {
        if (success) {
          dispatch(webpayActions.createTransaction(data));
          callback(data);
        }
      },
      onEnd: webpayActions.endFetch,
    },
  });
};

const accessToken = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== webpayTypes.ACCESS_TOKEN) {
    return;
  }

  const {
    callback = () => {
    },
  } = action.payload;

  dispatch({
    type: webpayTypes.API_CALL,
    payload: {
      config: {
        method: 'GET',
        url: 'pci-booking/pci-access-token',
      },
      authorization: true,
      onStart: webpayActions.startFetch,
      onComplete: ({ data: { success, data } }) => {
        if (success) {
          dispatch(webpayActions.token(data));
          callback(data);
        }
      },
      onEnd: webpayActions.endFetch,
    },
  });
};

const registerPayment = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== webpayTypes.REGISTER_PAYMENT) {
    return;
  }

  const {
    data,
    callback = () => {
    },
  } = action.payload;

  dispatch({
    type: webpayTypes.API_CALL,
    payload: {
      config: {
        method: 'POST',
        url: 'pci-booking/register-payment',
        data,
      },
      authorization: true,
      onStart: webpayActions.startFetch,
      onComplete: ({ data }) => {
        callback(data);
        dispatch(webpayActions.cycleCard(data));
      },
      onEnd: webpayActions.endFetch,
    },
  });
};

const listCards = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== webpayTypes.LIST_CARDS) {
    return;
  }

  const {
    callback = () => {
    },
  } = action.payload;

  dispatch({
    type: webpayTypes.API_CALL,
    payload: {
      config: {
        method: 'GET',
        url: `pci-booking/get-payment-person`,
      },
      authorization: true,
      onStart: webpayActions.startFetch,
      onComplete: ({ data: { success, data } }) => {
        if (success) {
          //dispatch(webpayActions.listCards(data));
          callback(data);
        }
      },
      onEnd: webpayActions.endFetch,
    },
  });
};

const confirmBillingCycle = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== webpayTypes.CONFIRM_BILLING_CYCLE) {
    return;
  }

  const {
    data,
    callback = () => {
    },
  } = action.payload;

  dispatch({
    type: webpayTypes.API_CALL,
    payload: {
      config: {
        method: 'POST',
        url: 'pci-booking/confirm-billing-cycle',
        data,
      },
      authorization: true,
      onStart: webpayActions.startFetch,
      onComplete: ({ data }) => {
        callback(data);
      },
      onEnd: webpayActions.endFetch,
    },
  });
};

export default [createTransaction, accessToken, registerPayment, listCards, confirmBillingCycle];