import { createAction } from 'redux-actions';
import types from './types';

const startFetch = createAction(types.START_FETCH);
const endFetch = createAction(types.END_FETCH);
const createFlowApproval = createAction(types.CREATE_FLOW_APPROVAL);
const getFlowApproval = createAction(types.GET_FLOW_APPROVAL);
const updateFlowApproval = createAction(types.UPDATE_FLOW_APPROVAL);
const fetchActiveFlow = createAction(types.FETCH_ACTIVE_FLOW);
const updateActiveFlow = createAction(types.UPDATE_ACTIVE_FLOW);
const getWorkFlows = createAction(types.GET_WORK_FLOWS);
const getWorkFlowId = createAction(types.GET_WORK_FLOW_ID);
const selectId = createAction(types.SELECTED_ID);
const desactivetWorkFlow = createAction(types.DESACTIVE_WORK_FLOW);
const defaultWorkFlow = createAction(types.DEFAULT_WORK_FLOW);
const getWorkFlowTypes = createAction(types.GET_WORK_FLOW_TYPES);
const updateProcess = createAction(types.UPDATE_PROCESS);
const getProcessWorkFlow = createAction(types.GET_PROCESS_WORK_FLOW);

const showMessage = createAction(types.MESSAGE,
  ({ message }) => ({
    message,
  }),
  ({ config }) => ({
    config,
  }));

export default {
  startFetch,
  endFetch,
  showMessage,
  createFlowApproval,
  getFlowApproval,
  updateFlowApproval,
  fetchActiveFlow,
  updateActiveFlow,
  getWorkFlows,
  getWorkFlowId,
  selectId,
  desactivetWorkFlow,
  defaultWorkFlow,
  getWorkFlowTypes,
  getProcessWorkFlow,
  updateProcess,
};
