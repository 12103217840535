import React, { useState, useEffect, useRef } from 'react';
import './styles.scss';
import PropTypes from 'prop-types';

const Panel = ({ headingText, children, alert, usedInModals }) => {

  return (
    <>
      {headingText && <h2 className="Panel__heading-text">{headingText}</h2>}
      <div className="Panel">
        <div
          className="columns is-centered"
          style={{ animationDelay: '1s' }}
        >
          <div className="column is-full wow animated fadeInUp">
            <div 
              className="panel panel-default panel-table shadas"
              style={usedInModals ? { marginBottom: '0px' } : null }>
              {/* {headingText !== ''
              && (
                <div
                  className={alert === true ? 'title is-4 panel-header text-white' : 'title is-4 panel-header bg-panel text-white'}
                  style={{ background: '#016fd0' }}>
                  {headingText}
                </div>
              )} */}
              <div className="panel-body">
                {children}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

Panel.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.array,
  ]).isRequired,
  headingText: PropTypes.string,
};

Panel.defaultProps = {
  headingText: '',
};

export default Panel;
