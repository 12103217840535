import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import { Card, Form, Loading, Panel, } from '../../components';
import { cardActions } from '../../state/ducks/card';
import { bankActions } from '../../state/ducks/bank';
import SelectSingle from '../../components/SelectSingle';
import DateInput from '../../components/DateInput';
import { TOAST_CONFIG } from '../../config/constants';
import listCards from '../listCards';

class BillingCycle extends Component {
  constructor(props) {
    super(props);
    const {
      location: { state },
    } = props;
    this.state = { ...this.getInitialState(), ...state };
  }

  getInitialState = () => ({
    day: '',
    day0: '',
    months: [],
    month: -1,
    billing_cycles: [],
    disable: true,
    cycle_date: new Date(),
    monthEdit: '',
    yearEdit: '',
    hidden: false,
    bank: '',
    bank_id: '',
    bank_name: '',
    bank_document: '',
    bank_address: '',
    bank_email: '',
  });

  componentDidMount() {
    const {
      t,
      showMessage,
      getCycleCard,
      getBillingCycles,
      router: { location: { pathname } },
      fetchBanks,
      auth: { user: { b2b: { id, type_user } } },
    } = this.props;

    if (pathname === '/load-billing-cycle') {   
      if(type_user === "cycle_loader"){
        getCycleCard({
          callback: (response) => {
            this.arrayMonth(response);
          }
        });
      }
      if(type_user === "admin"){
        fetchBanks({
          params: {
            admin: true,
          }
        });
      }
    }

    if (pathname === '/edit-billing-cycle') {
      getBillingCycles({
        params: {
          year: new Date().getFullYear(),
          month: new Date().getMonth() + 1,
        },
        callback: (response) => {
          if (response.length > 0) {
            this.examp(response, new Date().getFullYear(), new Date().getMonth() + 1, new Date());
          } else {
            this.examp(response, new Date().getFullYear(), new Date().getMonth() + 1, new Date());
            showMessage({
              message: <strong
                style={{ color: '#ffffff' }}>{t('noCycles', { month: this.getMonth(new Date().getMonth()) })}</strong>,
              config: TOAST_CONFIG.ERROR,
            });
          }
        }
      });
    }
  }

  examp = (response, year, month, newDate) => {
    const {
      t,
    } = this.props;
    let variable = [],
      i = 0,
      order = [5, 3, 8, 1, 2];

    let dias = [t('monday'), t('tuesday'), t('wednesday'), t('thursday'), t('friday'), t('saturday'), t('sunday')];

    if (response.length === 0) {
      for (let j = 0; j < 5; j++) {
        variable.push({
          id: i,
          group: order[j],
          billing_cycle_id: order[j],
          dayI: '',
          date_init: '',
          dayF: '',
          date_end: '',
          band: false,
        });
      }
    }

    if (response.length > 0) {
      for (let j = 0; j < 5; j++) {
        response.forEach(function (path) {
          if (order[i] === Number(path.billingCycle.cycle)) {
            variable.push({
              id: path.id,
              group: path.billingCycle.cycle,
              billing_cycle_id: path.billingCycle.id,
              dayI: (new Date(path.date_init * 1000).getDay()) < 0 ? dias[6] : dias[new Date(path.date_init * 1000).getDay() - 1],
              date_init: new Date(path.date_init * 1000),
              dayF: (new Date(path.date_end * 1000).getDay()) < 0 ? dias[6] : dias[new Date(path.date_end * 1000).getDay() - 1],
              date_end: new Date(path.date_end * 1000),
              band: false,
              bandInit: true,
              bandEnd: true,
            });
            i = i + 1;
          }
        });
      }
    }

    const date = new Date();
    const date1 = date.setMonth(newDate.getMonth(), 1);
    const date2 = date.setMonth(newDate.getMonth() + 2, 0); //date_end
    const date3 = date.setMonth(newDate.getMonth() + 2, 0);

    this.setState({
      monthEdit: month,
      yearEdit: year,
      billing_cycles: variable,
      //disable: false,
      cycle_date: newDate,
      minDate_init: new Date(date1),
      maxDate_init: date2,
      maxDate_end: date3,
      disable: response.length > 0 ? false : true,
    });
  };

  getMonth = (month) => {
    const months = [
      {
        id: 0,
        name: 'Enero'
      },
      {
        id: 1,
        name: 'Febrero'
      },
      {
        id: 2,
        name: 'Marzo'
      },
      {
        id: 3,
        name: 'Abril'
      },
      {
        id: 4,
        name: 'Mayo'
      },
      {
        id: 5,
        name: 'Junio'
      },
      {
        id: 6,
        name: 'Julio'
      },
      {
        id: 7,
        name: 'Agosto'
      },
      {
        id: 8,
        name: 'Septiembre'
      },
      {
        id: 9,
        name: 'Octubre'
      },
      {
        id: 10,
        name: 'Noviembre'
      },
      {
        id: 11,
        name: 'Diciembre'
      }];

    return months[month].name;
  };

  arrayMonth = (response) => {
    let variable = [];

    this.setState({
      months: [
        {
          id: 0,
          name: 'Enero'
        },
        {
          id: 1,
          name: 'Febrero'
        },
        {
          id: 2,
          name: 'Marzo'
        },
        {
          id: 3,
          name: 'Abril'
        },
        {
          id: 4,
          name: 'Mayo'
        },
        {
          id: 5,
          name: 'Junio'
        },
        {
          id: 6,
          name: 'Julio'
        },
        {
          id: 7,
          name: 'Agosto'
        },
        {
          id: 8,
          name: 'Septiembre'
        },
        {
          id: 9,
          name: 'Octubre'
        },
        {
          id: 10,
          name: 'Noviembre'
        },
        {
          id: 11,
          name: 'Diciembre'
        }],
      hidden: true,
      billing_cycles: response.length > 0 ? this.orderArray(response) : variable,
    });

  };

  handleSelectChange = (newVal, label) => {
    const {
      card: { cycle }
    } = this.props;
    let variable = [],
      i = 0,
      order = [5, 3, 8, 1, 2];

    const date = new Date();
    const date1 = date.setMonth(newVal.value, 1);
    const date2 = date.setMonth(newVal.value + 2, 0); //date_end
    const date3 = date.setMonth(newVal.value + 2, 0);

    for (let j = 0; j < 5; j++) {
      cycle.forEach(function (path) {
        if (order[i] === Number(path.cycle)) {
          variable.push({
            id: i,
            group: path.cycle,
            billing_cycle_id: path.id,
            dayI: '',
            date_init: date1,
            dayF: '',
            date_end: date1,
            bandInit: false,
            bandEnd: false,
          });
          i = i + 1;
        }
      });
    }

    this.setState({
      [label.name]: newVal.value,
      billing_cycles: variable,
      disable: false,
      minDate_init: new Date(date1),
      maxDate_init: date2,
      maxDate_end: date3,
    });
  };

  orderArray = (list) => {
    let variable = [],
      i = 0,
      order = [5, 3, 8, 1, 2];

    for (let j = 0; j < 5; j++) {
      list.forEach(function (path) {
        if (order[i] === Number(path.cycle)) {
          variable.push({
            id: i,
            group: path.cycle,
            billing_cycle_id: path.id,
            dayI: '',
            date_init: '',
            dayF: '',
            date_end: '',
            band: false,
          });
          i = i + 1;
        }
      });
    }

    return variable;
  };

  handleMonthChange = (newDate) => {
    const {
      t,
      showMessage,
      getBillingCycles,
    } = this.props;

    getBillingCycles({
      params: {
        year: newDate.getFullYear(),
        month: newDate.getMonth() + 1,
      },
      callback: (response) => {
        if (response.length > 0) {
          this.examp(response, newDate.getFullYear(), newDate.getMonth() + 1, newDate);
        } else {
          this.examp(response, newDate.getFullYear(), newDate.getMonth() + 1, new Date());
          showMessage({
            message: <strong
              style={{ color: '#ffffff' }}>{t('noCycles', { month: this.getMonth(newDate.getMonth()) })}</strong>,
            config: TOAST_CONFIG.ERROR,
          });
        }
      }
    });
  };

  handleDateChange = (dateName, newDate) => {
    const {
      t,
      showMessage,
      router: { location: { pathname } },
    } = this.props;
    let dias = [t('monday'), t('tuesday'), t('wednesday'), t('thursday'), t('friday'), t('saturday'), t('sunday')];

    const cycles = dateName.split('-');
    let billing_cycles = [...this.state.billing_cycles];

    if ((cycles[0] === 'invoice_dateI' && (newDate > billing_cycles[cycles[1]].date_end) && billing_cycles[cycles[1]].bandInit) ||
      (cycles[0] === 'invoice_dateF' && (billing_cycles[cycles[1]].date_init > newDate) && billing_cycles[cycles[1]].bandInit)) {
      showMessage({
        message: <strong style={{ color: '#ffffff' }}>{t('errorDateCycles')}</strong>,
        config: TOAST_CONFIG.ERROR,
      });
    } else {
      billing_cycles[cycles[1]] = {
        id: pathname === '/edit-billing-cycle' ? billing_cycles[cycles[1]].id : Number(cycles[1]),
        group: billing_cycles[cycles[1]].group,
        billing_cycle_id: billing_cycles[cycles[1]].billing_cycle_id,
        dayI: cycles[0] === 'invoice_dateI' ? ((newDate.getDay() - 1) < 0 ? dias[6] : dias[newDate.getDay() - 1]) : billing_cycles[cycles[1]].dayI,
        date_init: cycles[0] === 'invoice_dateI' ? newDate : billing_cycles[cycles[1]].date_init,
        dayF: cycles[0] === 'invoice_dateF' ? ((newDate.getDay() - 1) < 0 ? dias[6] : dias[newDate.getDay() - 1]) : billing_cycles[cycles[1]].dayF,
        date_end: cycles[0] === 'invoice_dateF' ? newDate : billing_cycles[cycles[1]].date_end,
        bandInit: cycles[0] === 'invoice_dateI' ? true : billing_cycles[cycles[1]].bandInit,
        bandEnd: cycles[0] === 'invoice_dateF' ? true : billing_cycles[cycles[1]].bandEnd,
      };

      this.setState({ billing_cycles });
    }
  };

  handleInputChange = (event) => {
    const {
      target: {
        checked, value: targetValue, type, name,
      },
    } = event;
    const value = type === 'checkbox' ? checked : targetValue;
    this.setState({
      [name]: value,
    });
  };

  registerPeriod = () => {
    const {
      month,
      billing_cycles,
      monthEdit,
      yearEdit,
      bank_id,
    } = this.state;
    const {
      t,
      history,
      showMessage,
      registerPeriods,
      editBillingCycle,
      router: { location: { pathname } },
      auth: { user: { b2b: { type_user } } },
    } = this.props;

    let band = true;

    if (pathname === '/load-billing-cycle') {
      if(bank_id !== '' || type_user !== "admin" ){
        if (month !== -1) {
          billing_cycles.forEach(function (path) {
            if (path.bandInit === false || path.bandEnd === false) {
              band = false;
            }
          });
          if (band === true) {
            const data = {
              billing_cycle_periods: [{
                month: (month + 1).toString(),
                periods: billing_cycles,
                bank: bank_id,
              }]
            };
            registerPeriods({
              data,
              callback: () => {
                showMessage({
                  message: <strong style={{ color: '#ffffff' }}>{t('successCycles')}</strong>,
                  config: TOAST_CONFIG.SUCCESS,
                });
                history.replace('/dashboard');
              },
            });
          } else {
            showMessage({
              message: <strong style={{ color: '#ffffff' }}>{t('selectCycle')}</strong>,
              config: TOAST_CONFIG.ERROR,
            });
          }
        } else {
          showMessage({
            message: <strong style={{ color: '#ffffff' }}>{t('selectMonth')}</strong>,
            config: TOAST_CONFIG.ERROR,
          });
        }
      }else{
        showMessage({
          message: <strong style={{ color: '#ffffff' }}>{t('selectBank')}</strong>,
          config: TOAST_CONFIG.ERROR,
        });
      }
    }
    if (pathname === '/edit-billing-cycle') {
      let variable = [];
      billing_cycles.forEach(function (path) {
        variable.push({
          id: path.id,
          billing_cycle_id: path.billing_cycle_id,
          date_init: (path.date_init.getTime() / 1000).toString(),
          date_end: (path.date_end.getTime() / 1000).toString(),
          month: monthEdit.toString(),
          year: yearEdit.toString(),
        });
      });
      const data = {
        month: monthEdit.toString(),
        year: yearEdit.toString(),
        periods: variable,
      };

      editBillingCycle({
        data,
        callback: () => {
          history.push('/dashboard');
          showMessage({
            message: t('updatedBillingCycles'),
            config: TOAST_CONFIG.SUCCESS,
          });
          this.resetState();
        },
      });
    }
  };

  closeModal = () => {
    const {
      history,
    } = this.props;

    this.getInitialState();
    history.push('/dashboard');
  };

  handleSelectBank = (newVal, label) => {
    const {
      bank: { list },
      getCycleCard,
    } = this.props;

    getCycleCard({
      params: {
        bank: list[newVal.value].id,
      },
      callback: (response) => {
        this.arrayMonth(response);
      }
    })

    this.setState({
      bank_id: list[newVal.value].id,
      bank_name: list[newVal.value].idPerson.name,
      bank_document: list[newVal.value].idPerson.document_number,
      bank_address: list[newVal.value].address,
      bank_email: list[newVal.value].username,
    });
  };

  render() {
    const {
      months,
      month,
      billing_cycles,
      disable,
      minDate_init,
      maxDate_init,
      maxDate_end,
      cycle_date,
      hidden,
      bank,
      bank_name,
      bank_document,
      bank_address,
      bank_email,
    } = this.state;
    const {
      t,
      card: { loading },
      router: { location: { pathname } },
      bank: { list },
      auth: { user: { b2b: { type_user } } },
    } = this.props;

    return (
      <div>
        {loading && <Loading/>}
        <Panel headingText={t('loadBillingCycle')}>
          <div className="field is-horizontal">
          {type_user === "admin" && 
            <div className="field-body columns">
              <div className="column is-one-third">
                <SelectSingle
                  name={'bank'}
                  label={t('adminBank')}
                  selectClasses="is-fullwidth"
                  placeholder={t('Select')}
                  onChange={this.handleSelectBank}
                  options={[
                    ...list.map((item, index) => ({
                      label: item.idPerson.name +" - "+item.username,
                      value: index,
                    })),
                  ]}
                  value={bank}
                />
              </div>
              <div className="column">
                {bank_email !== '' &&
                <Card 
                  title={t('bankInformation')}
                  subtitle={bank_name}
                  items={[
                    {
                      name: 'documentNumber',
                      value: bank_document,
                    }, {
                      name: 'address',
                      value: bank_address,
                    }, {
                      name: 'email',
                      value: bank_email,
                    },
                  ]}
                />}
              </div>
            </div>}
          </div> 

          <div className="field is-horizontal">
            <div className="field-body columns">
              <div className="column is-one-third">
                {pathname === '/load-billing-cycle' && (
                  <SelectSingle
                    name={'month'}
                    label={t('month')}
                    selectClasses="is-fullwidth"
                    placeholder={t('Select')}
                    onChange={this.handleSelectChange}
                    options={[
                      ...months.map((item, index) => ({
                        label: item.name,
                        value: index,
                      })),
                    ]}
                    value={month}
                  />
                )}
                {pathname === '/edit-billing-cycle' && (
                  <DateInput
                    name={'cycle_date'}
                    label={t('month')}
                    value={cycle_date}
                    onChange={this.handleMonthChange}
                    fieldClasses="is-expanded"
                    controlClasses="has-icons-left"
                    icon="far fa-calendar-alt"
                    iconPosition="is-left"
                    dateFormat="MM/yyyy"
                    showMonthYearPicker={true}
                    showFullMonthYearPicker={true}
                    locale={'es'}
                  />
                )}
                <br/>
              </div>
            </div>
          </div>
          <Form
            submitText={t('save')}
            onSubmit={(event) => {
              event.preventDefault();
              this.registerPeriod();
            }}
            buttonClasses={`mr-8px ${hidden === true ? '' : 'is-hidden'}`}
          >
            <div className="field is-horizontal">
              <div className="field-body columns">
                <div className="column is-one-third">
                </div>
                <div className="column is-one-third">
                  <strong>
                    {t('dateFf')}
                  </strong>
                </div>
                <div className="column is-one-third">
                  <strong>
                    {t('dateV')}
                  </strong>
                </div>
              </div>
            </div>
            <div>
              <ul>
                {billing_cycles.map((val, index) => (
                  <li>
                    <div className="field is-horizontal">
                      <div className="field-body columns">
                        <div className="column is-one-fifth">
                          <label>{t('group') + val.group}</label>
                        </div>
                        <div style={{
                          width: '70px',
                          marginTop: '16px'
                        }}>
                          <label>
                            {val.dayI}
                          </label>
                        </div>
                        {/*Fecha Init*/}
                        <div className="column is-one-third">
                          <DateInput
                            name={'invoice_dateI-' + index}
                            // label={index === 0 ? t('dateff') : ''}
                            value={val.date_init}
                            onChange={(value) => this.handleDateChange('invoice_dateI-' + index, value)}
                            type="text"
                            fieldClasses="is-expanded"
                            controlClasses="has-icons-left"
                            icon="far fa-calendar-alt"
                            iconPosition="is-left"
                            dateFormat="dd/MM/yyyy"
                            disabled={disable}
                            minDate_init={minDate_init}
                            maxDate={maxDate_init}
                            band={true}
                          />
                        </div>
                        {/*FechaFin*/}
                        <div style={{
                          width: '70px',
                          marginTop: '16px'
                        }}>
                          <label>
                            {val.dayF}
                          </label>
                        </div>
                        <div className="column is-one-third">
                          <DateInput
                            name={'invoice_dateF-' + index}
                            //label={index === 0 ? t('dateV') : ''}
                            value={val.date_end}
                            onChange={(value) => this.handleDateChange('invoice_dateF-' + index, value)}
                            type="text"
                            fieldClasses="is-expanded"
                            controlClasses="has-icons-left"
                            icon="far fa-calendar-alt"
                            iconPosition="is-left"
                            dateFormat="dd/MM/yyyy"
                            disabled={disable}
                            minDate_init={minDate_init}
                            maxDate={maxDate_end}
                            band={true}
                          />
                        </div>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
            <div className="field is-grouped is-grouped-centered"
                 style={{ display: hidden === false ? '' : 'none', width: '50%', margin: '30px auto' }}>
              <button
                type="button"
                className="button custom-btn is-danger"
                style={{ color: '#C4C4C4', flex: '1', maxWidth: '200px', margin: '0 10px' }}
                onClick={this.closeModal.bind(this)}
              >
                {t('cancel')}
              </button>
              <button
                type="button"
                className="button custom-btn is-primary"
                style={{ flex: '1', maxWidth: '200px', margin: '0 10px' }}
                onClick={this.registerPeriod.bind(this)}
              >
                {t('save')}
              </button>
            </div>
          </Form>
        </Panel>
      </div>
    );
  }
}

BillingCycle.propTypes = {
  t: PropTypes.func.isRequired,
  getCycleCard: PropTypes.func.isRequired,
};

const mapStateToProps = ({ card, router, bank }) => (
  {
    card,
    router,
    bank,
  }
);

export default compose(
  connect(mapStateToProps, {
    ...cardActions,
    ...bankActions,
  }),
  withNamespaces(),
)(BillingCycle);