import React from 'react';
import { withNamespaces } from 'react-i18next';
import PropTypes from 'prop-types';

const ItemCard = ({ name, value }) => (
  <li>
    <b>
      { `${name}:` }
      {' '}
    </b>
    { value }
  </li>
);

ItemCard.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

const Card = (props) => {
  const {
    t, title, subtitle, items,
  } = props;
  return (
    <div className="card">
      <header className="card-header">
        <p className="card-header-title">
          {t(title)}
        </p>
      </header>
      <div className="card-content">
        <p className="subtitle">{t(subtitle)}</p>

        {
          !!items && (
            <div className="content">
              <ul>
                {items.map((value) => {
                  const information = value.value !== 'null' ? value.value : t('noData');
                  return (
                    <ItemCard key={value.name} name={t(value.name)} value={information} />
                  );
                })}
              </ul>
            </div>
          )

        }

      </div>
    </div>
  );
};

Card.propTypes = {
  t: PropTypes.func.isRequired,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

Card.defaultProps = {
  title: '',
  subtitle: '',
};


export default withNamespaces()(Card);
