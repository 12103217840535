const START_FETCH = 'products/START_FETCH';
const END_FETCH = 'products/END_FETCH';
const END_ERROR = 'products/END_ERROR';
const API_CALL = 'products/API_CALL';
const MESSAGE = 'products/MESSAGE';
const SAVE_PRODUCT = 'products/SAVE_PRODUCT';
const GET_PRODUCT = 'products/GET_PRODUCT';
const UPDATE_PRODUCT = 'products/UPDATE_PRODUCT';
const DISABLE_PRODUCT = 'products/DISABLE_PRODUCT';
const GET_CONTRACTED_PRODUCT = 'products/GET_CONTRACTED_PRODUCT';
const GET_PRODUCTS = 'products/GET_PRODUCTS';
const GET_CONTRACTED = 'products/const GET_CONTRACTED';

export default {
  START_FETCH,
  END_FETCH,
  END_ERROR,
  API_CALL,
  MESSAGE,
  SAVE_PRODUCT,
  GET_PRODUCT,
  UPDATE_PRODUCT,
  DISABLE_PRODUCT,
  GET_CONTRACTED_PRODUCT,
  GET_PRODUCTS,
  GET_CONTRACTED,
};