import React from 'react';
import { withNamespaces } from 'react-i18next';
import PropTypes from 'prop-types';
import { Panel, TextInput, } from '../index';
import './styles.scss';
import Form from '../Form';
import { Fragment } from 'react';

const LogDetailForm = (props) => {
  const {
    t,
    log,
  } = props;

  return (
    <div className="columns">
      <div className="column is-full">
        <Panel headingText={t('gatewayInformation')}>
          <div className='Form'>
            {log.map((val, index) => (
              <Fragment  key={val.id}>
                <div className="field is-horizontal">
                  <div className="field-body columns">
                    <p style={{fontWeight: 700}}>{t('chargeAttempt')+' '+(log.length - index)}</p>
                  </div>
                </div>
                <div className="field is-horizontal">
                  <div className="field-body columns">
                    <div className="column is-one-third select-lead-require">
                      <div className="width-select-lead-require">
                        <TextInput
                          name="invoice_date"
                          label={t('executionDate')}
                          value={val.date}
                          type="text"
                          fieldClasses="is-expanded"
                          controlClasses="is-expanded"
                          icon=""
                          iconPosition=""
                          disabled={true}
                        />
                      </div>
                    </div>
                    <div className="column is-one-third select-lead-require">
                      <div className="width-select-lead-require">
                        <TextInput
                          name="invoice_date"
                          label={t('status')}
                          value={val.status}
                          type="text"
                          fieldClasses="is-expanded"
                          controlClasses="is-expanded"
                          icon=""
                          iconPosition=""
                          disabled={true}
                        />
                      </div>
                    </div>
                    <div className="column is-one-third select-lead-require">
                      <div className="width-select-lead-require">
                        <TextInput
                          name="invoice_date"
                          label={t('description')}
                          value={val.description}
                          type="text"
                          fieldClasses="is-expanded"
                          controlClasses="is-expanded"
                          icon=""
                          iconPosition=""
                          disabled={true}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </Fragment>
            ))}
          </div>
        </Panel>
      </div>
    </div>
  );
};

LogDetailForm.propTypes = {
  t: PropTypes.func.isRequired,
  invoiceAmount: PropTypes.string,
  isEdit: PropTypes.bool,
  handleInputChange: PropTypes.func.isRequired,
  wf_status: PropTypes.arrayOf(PropTypes.object),
  wf_status_emit: PropTypes.arrayOf(PropTypes.object),
  detailAmount: PropTypes.shape({}),
  invoiceDate: PropTypes.string,
  due_date: PropTypes.instanceOf(Date).isRequired,
  description: PropTypes.string,
  invoiceNumber: PropTypes.string,
  selectedClient: PropTypes.number,
  file: PropTypes.string,
  client: PropTypes.string,
  sender: PropTypes.bool,
  receiver: PropTypes.bool,
  issuing_bank: PropTypes.bool,
  cardPayment: PropTypes.array,
  message: PropTypes.string,
  cardMask: PropTypes.string,
  auth: PropTypes.string,
  cardCollectionDate: PropTypes.string,
};

LogDetailForm.defaultProps = {
  detailAmount: {},
  invoiceAmount: '',
  description: '',
  invoiceNumber: '',
  invoiceDate: '',
  band: false,
};

export default withNamespaces()(LogDetailForm);
