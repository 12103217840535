import { loanActions, loanTypes } from '../ducks/loan';

const getLoan = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== loanTypes.GET_LOAN) {
    return;
  }

  const {
    params: {
      id = '',
      reference = '',
      clientId = '',
    } = {},
    callback = () => {
    },
  } = action.payload;
  dispatch({
    type: loanTypes.API_CALL,
    payload: {
      config: {
        method: 'GET',
        url: `/b2b-loan/get-loans-client?clientId=${clientId}`,
        //url: `/b2b-loan/get-loan?id=${id}&reference=${reference}`,
      },
      authorization: true,
      onStart: loanActions.startFetch,
      onComplete: ({ data: { success, data } }) => {
        if (success) {
          dispatch(loanActions.saveLoan(data));
          callback(data);
        }
      },
      onEnd: loanActions.endFetch,
    },
  });
};

const getReference = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== loanTypes.GET_REFERENCE) {
    return;
  }

  const {
    params: {
      loan = '',
    } = {},
    callback = () => {
    },
  } = action.payload;
  dispatch({
    type: loanTypes.API_CALL,
    payload: {
      config: {
        method: 'GET',
        url: `/b2b-loan/get-reference?loan=${loan}`,
      },
      authorization: true,
      onStart: loanActions.startFetch,
      onComplete: ({ data: { success, data } }) => {
        if (success) {
          dispatch(loanActions.saveReference(data));
          callback(data);
        }
      },
      onEnd: loanActions.endFetch,
    },
  });
};

export default [ getLoan, getReference ];