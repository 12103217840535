import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Panel, TableSimple } from '../../components';
import { authActions } from '../../state/ducks/auth';
import { flowApprovalActions } from '../../state/ducks/flowApproval';

class FlowApproval extends Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  getInitialState = () => ({
    id: '',
    name: '',
    roleType: '',
    alert: false,
  });

  componentDidMount() {
    const {
      getWorkFlows,
      getProcessWorkFlow,
      auth: { user: { b2b: { id } } },
    } = this.props;
    getProcessWorkFlow({
      params: { user_id: id },
      callback: (response) => this.messageUser(response),
    });
    getWorkFlows({
      params: { id }
    });
  }

  resetState = () => {
    this.setState(this.getInitialState());
  };

  messageUser = (response) => {
    if (response.length > 0) {
      this.setState({ alert: true });
    }
  };

  getGridSettings = () => {
    const {
      t,
      flowApproval: { list },
      getWorkFlowId,
      auth: { user: { b2b: { id } } },
      desactivetWorkFlow,
      defaultWorkFlow,
      getWorkFlows,
      history
    } = this.props;
    const columns = [
      {
        expander: true,
        show: false,
      },
      {
        id: 'name', // Required because our accessor is not a string
        Header: t('name'),
        accessor: d => `${d.name} `,
      },
      /*{
        id: 'condition', // Required because our accessor is not a string
        Header: t('condition'),
        width: 300,
        accessor: d => d.condition_operator ? `${getPath(['condition_operator'], d)} ${getPath(['condition_value'], d)} ` : '-',
      },*/
      {
        id: 'activo', // Required because our accessor is not a string
        Header: t('status'),
        width: 120,
        Cell: cellInfo => (cellInfo.original.active === '1'
          ?
          <i className="far fa-check-circle mr-8px" aria-hidden="true" style={{ color: 'green' }}/>
          : <i className="fas fa-ban mr-8px" aria-hidden="true" style={{ color: 'red' }}/>),
      },
      {
        id: 'action', // Required because our accessor is not a string
        Header: t('action'),
        filterable: false,
        accessor: d => `${d.id} `,
        Cell: cellInfo => (
          <table>
            <tbody>
            <tr>
              <td>
                <button
                  className="button is-primary is-inverted"
                  onClick={() => {
                    getWorkFlowId({
                      params: {
                        iduser: id,
                        id: cellInfo.original.id
                      },
                      callback: () => {
                        history.push('/edit-approval-flow');
                      }
                    });
                    {/* selectId({ id:cellInfo.original.id }) */
                    }
                  }}
                >
                  <i className="far fa-edit mr-8px" aria-hidden="true"/>
                  {t('edit')}
                </button>
              </td>
              <td>
                <button className="button is-primary is-inverted"
                        onClick={() => {
                          desactivetWorkFlow({
                            params: {
                              id: cellInfo.original.id
                            },
                            callback: () => {
                              getWorkFlows({
                                params: {
                                  id: id,
                                },
                              });
                            }
                          });
                        }}
                        //style={{ display: cellInfo.original.active === '1' ? 'none' : '' }}
                >
                  {(cellInfo.original.active === '1'
                    ? <i className="fas fa-minus-circle mr-8px" aria-hidden="true"
                         style={{ color: 'red' }}/>
                    : <i className="fas fa-minus-circle mr-8px" aria-hidden="true"
                         style={{ color: 'green' }}/>)}
                  {(cellInfo.original.active === '1' ? t('deactivate') : t('activate'))}
                </button>
              </td>
              {/*<td>
                <button style={{ display: (cellInfo.original.is_default === '1' ? 'none' : '') }}
                        className="button is-info is-inverted"
                        onClick={() => {
                          defaultWorkFlow({
                            params: {
                              userid: id,
                              id: cellInfo.original.id
                            },
                            callback: () => {
                              getWorkFlows({
                                params: {
                                  id: id,
                                },
                              });
                            }
                          });
                        }}
                >
                  <i className="fas fa-minus-circle mr-8px" aria-hidden="true"/>
                  {t('defaultFlow')}
                </button>
              </td>*/}
            </tr>
            </tbody>
          </table>
        ),
      },
    ];
    const data = list;
    const title = t('flowsApproval');
    return {
      columns,
      data,
      title,
    };
  };

  handleInputChange = (event) => {
    const {
      target: {
        checked, value: targetValue, type, name,
      },
    } = event;
    const value = type === 'checkbox' ? checked : targetValue;
    this.setState({
      [name]: value,
    });
  };

  render() {
    const {
      alert
    } = this.state;
    const {
      t,
    } = this.props;

    const { title, columns, data } = this.getGridSettings();
    return (
      <div className="dashboard">
        {/* loading && <Loading/> */}
        <div className="columns is-multiline">
          <div className="column is-full">
            <Panel alert headingText={title}>
              {alert === false
              && (
                <div>
                  <div className={`notification is-warning`} style={{
                    background: '#fcb92d',
                    fontFamily: 'Roboto, Arial, sans-serif'
                  }}>
                    <strong>{t('messageUserProcess')}</strong>
                  </div>
                  <div className="column is-full">
                  </div>
                </div>
              )
              }
              {(alert === true && data)
              && (
                <div>
                  <div className="panel-top-content">
                    <Link
                      className="button is-primary"
                      to="/create-approval-flow"
                    >
                      <i className="fas fa-plus mr-8px" aria-hidden="true"/>
                      {t('createFlowApproval')}
                    </Link>
                  </div>
                  <TableSimple data={data} columns={columns}/>
                </div>
              )}
            </Panel>
          </div>
        </div>
      </div>
    );
  }
}

FlowApproval.propTypes = {
  showMessage: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  getWorkFlows: PropTypes.func.isRequired,
  getWorkFlowId: PropTypes.func.isRequired,
  selectId: PropTypes.func.isRequired,
  desactivetWorkFlow: PropTypes.func.isRequired,
  defaultWorkFlow: PropTypes.func.isRequired,
};

const mapStateToProps = ({ auth, flowApproval }) => (
  {
    auth,
    flowApproval,
  }
);

export default compose(
  connect(mapStateToProps, {
    ...authActions,
    ...flowApprovalActions,
  }),
  withNamespaces(),
)(FlowApproval);
