import { documentTypeActions, documentTypeTypes } from '../ducks/documentType';

const documentTypeData = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== documentTypeTypes.FETCH_DOCUMENT_TYPES) {
    return;
  }

  const {
    params: {
      status = '',
      document_type = '',
      legal_type = '',
    } = {},
    callback = () => {},
  } = action.payload;

  dispatch({
    type: documentTypeTypes.API_CALL,
    payload: {
      config: {
        method: 'GET',
        url: `document/document-type?status=${status}&document_type=${document_type}&legal_type=${legal_type}`,
      },
      authorization: true,
      onStart: documentTypeActions.startFetch,
      onComplete: ({ data: responseData }) => {
        const { success, data } = responseData;
        if (success) {
          callback(data);
          dispatch(documentTypeActions.updateDocumentTypes({
            list: data,
          }));
        }
      },
      onEnd: documentTypeActions.endFetch,
    },
  });
};

export default [documentTypeData];
