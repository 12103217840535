import React, { useEffect } from "react"
import { withNamespaces } from "react-i18next"
import PropTypes from "prop-types"
import "./styles.scss"
import { formatAmount } from "../../util"
import { Currency } from "../index"

const InformativeBox = ({
  title,
  bigValue,
  bigValue1,
  icon,
  size,
  typeCurrency,
  typeCurrency1,
}) => {
  return (
    <div className="information-box">
      <div className="content-title">
        <div className="title">{title}</div>
      </div>
      <div className="big-value">
        <div className="content">
          {typeCurrency === "" && <div>{bigValue}</div>}
          {typeCurrency !== "" && (
            <div>
              <div className="columns field-body">
                <span style={{ fontSize: "20px" }}> {typeCurrency} </span>
                <span style={{ float: "right" }}> {bigValue} </span>
              </div>
              {typeCurrency1 !== "" && (
                <div className="columns field-body">
                  <span style={{ fontSize: "20px" }}> {typeCurrency1} </span>
                  <span style={{ float: "right" }}> {bigValue1} </span>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
InformativeBox.propTypes = {
  title: PropTypes.string.isRequired,
  bigValue: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.object,
  ]).isRequired,
  bigValue1: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.object,
  ]).isRequired,
  icon: PropTypes.string.isRequired,
  size: PropTypes.string.isRequired,
}
InformativeBox.defaultProps = {
  typeCurrency: "",
  typeCurrency1: "",
}

export default withNamespaces()(InformativeBox)
