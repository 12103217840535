import types from "./types";
import { initialState } from "../../../App";

const auth = (state = {}, action) => {
  switch (action.type) {
    case types.CLEAR: {
      return {
        ...state,
        ...initialState.auth,
      };
    }
    case types.LOGGED: {
      const { payload } = action;
      return {
        ...state,
        logged: payload,
      };
    }
    case types.UPDATE_PROFILE: {
      const { payload } = action;
      return {
        ...state,
        profile: {
          ...state.profile,
          ...(payload !== null ? payload : {}),
        },
      };
    }
    case types.UPDATE_USER: {
      const { payload } = action;
      return {
        ...state,
        user: {
          ...state.user,
          ...(payload !== null ? payload : {}),
        },
      };
    }
    case types.SAVE_PROFILES: {
      const { payload } = action;
      return {
        ...state,
        profiles: payload,
      };
    }
    case types.UPDATE_ACCOUNT: {
      const { payload } = action;
      return {
        ...state,
        account: {
          ...state.account,
          ...(payload !== null ? payload : {}),
        },
      };
    }
    case types.UPDATE_CONTACTS: {
      const { payload } = action;
      return {
        ...state,
        contacts: payload !== null ? payload : [],
      };
    }
    case types.CHANGE_LANGUAGE: {
      const {
        payload: { language },
      } = action;
      return {
        ...state,
        language,
      };
    }
    case types.START_FETCH: {
      return {
        ...state,
        loading: true,
      };
    }
    case types.END_FETCH: {
      return {
        ...state,
        loading: false,
      };
    }
    case types.SAVE_ROLE: {
      const {
        payload: { b2b, profile },
      } = action;
      return {
        ...state,
        user: {
          ...state.user,
          profile: profile,
          b2b: b2b,
        },
      };
    }
    case types.SAVE_B2B: {
      const {
        payload: { b2b },
      } = action;
      return {
        ...state,
        user: {
          ...state.user,
          b2b: b2b,
        },
      };
    }
    case types.SAVE_CONFIRMATION: {
      const { payload } = action;
      return {
        ...state,
        user: payload,
      };
    }
    default:
      return state;
  }
};

export default auth;
