export const breadcrumbRoutes = (route, typeUser) => {

    const routes = {
        ['/payment-request']: ['request'],
        ['/payment-conditions-log']: ['request', 'changeLog'],
        ['/register-bank']: ['administrators', 'registerAdministrator'],
        ['/bank-list']: ['administrators', 'administratorsList'],
        ['/edit-bank-admin']: ['administrators', 'edit'],
        ['/register-role']: ['admin', 'registerRole'],
        ['/role-list']: ['admin', 'roleList'],
        ['/admin-routes']: ['admin', 'assignPermissions'],
        ['/log-view']: ['logs', 'logView'],
        ['/operation-log']: ['logs', 'operationLog'],
        ['/log-api-request']: ['logs', 'logApiRequest'],
        ['/load-billing-cycle']: ['billingCycles', 'loadBillingCycle'],
        ['/billing-cycles']: ['billingCycles', 'cyclesList'],
        ['/reports']: ['reports', 'transactions'],
        ['/disposition-report']: ['reports', 'dispositionReport'],
        ['/save-manual']: ['manualsAndFaq', 'loadManuals'],
        ['/list-manual']: ['manualsAndFaq', 'listManuals'],
        ['/save-faq']: ['manualsAndFaq', 'saveQuestion'],
        ['/list-faqs']: ['manualsAndFaq', 'listFaqs'],
        ['/create-admin-bank']: ['banks', 'registerBank'],
        ['/admin-bank-list']: ['banks', 'bankList'],
        ['/edit-admin-bank']: ['banks', 'bankList', 'edit'],
        ['/create-bank-user']: ['admin', 'createUser'],
        ['/bank-user-list']: ['admin', 'profileList'],
        ['/edit-bank-user']: ['admin', 'profileList', 'edit_user'],
        ['/create-banking-executive']: ['businessExecutives', 'createExecutive'],
        ['/banking-executives']: ['businessExecutives', 'businessExecutive'],
        ['/create-provider']: ['createProvider'],
        ['/edit-provider']: ['providers', 'edit'],
        ['/create-customer']: ['createCustomer'],
        ['/edit-customer']: ['customers', 'edit'],
        ['/payment-conditions']: ['customers', 'commercialConditions'],
        ['/invoices']: [typeUser === 'seller' ? 'invoicesSent' : 'receivedInvoices'],
        ['/invoice-detail']: [typeUser === 'seller' ? 'invoicesSent' : 'receivedInvoices', 'invoiceDetail'],
        ['/load-invoice']: ['loadInvoice'],
        ['/related-users']: [typeUser === 'seller' ? 'customers' : 'providers'],
        ['/create-profile']: ['admin', 'createProfile'],
        ['/profile-list']: ['admin', 'profileList'],
        ['/account-profile']: ['user', 'profile'],
        ['/user-commerce-code']: ['user', 'commerceCode'],
        ['/commerce-code']: ['user', 'commerceCode'],
        ['/support']: ['user', 'support'],
        ['/approved-loan']: ['myLoans'],
    }

    return routes[route]
} 