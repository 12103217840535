import { createAction } from 'redux-actions';
import types from './types';

const startFetch = createAction(types.START_FETCH);
const endFetch = createAction(types.END_FETCH);
const fetchFinancingProducts = createAction(types.FETCH_FINANCING_PRODUCTS);
const updateFinancingProducts = createAction(types.UPDATE_FINANCING_PRODUCTS);
const editFinancingProducts = createAction(types.EDIT_FINANCING_PRODUCTS);
const addFinancingProducts = createAction(types.ADD_FINANCING_PRODUCTS);
const deleteFinancingProduct = createAction(types.DELETE_FINANCING_PRODUCT);
const removeFinancingProduct = createAction(types.REMOVE_FINANCING_PRODUCT);

const showMessage = createAction(types.MESSAGE,
  ({ message }) => ({
    message,
  }),
  ({ config }) => ({
    config,
  }));

export default {
  startFetch,
  endFetch,
  showMessage,
  fetchFinancingProducts,
  updateFinancingProducts,
  editFinancingProducts,
  addFinancingProducts,
  deleteFinancingProduct,
  removeFinancingProduct,
};
