import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';
import ReactToPrint from 'react-to-print';
import QRCode from 'qrcode.react';
import { Modal, TableList, Toggle } from '../../../../components';

class ExtractDetail extends Component {
  render() {
    const {
      t,
      onFinish,
      qrData,
    } = this.props;

    return (
      <div className="animation-slide-in-right">
        <Toggle>
          { ({ toggled, onToggle }) => (
            <Fragment>
              {
                toggled
                && (
                  <Modal onToggle={ onToggle }>
                    <div>
                      <div ref={ (ref) => { this.infoToPrint = ref; } }>
                        <TableList>
                          <tr>
                            <th className="has-text-centered is-size-6" colSpan="2">
                              { t('extractDetail4') }
                            </th>
                          </tr>
                          <tr>
                            <th>{ t('extractDetail5') }</th>
                            <td>
                              { t('extractDetail6') }
                            </td>
                          </tr>
                          <tr>
                            <th>{ t('extractDetail7') }</th>
                            <td>
                              <div className="columns">
                                <div className="column">{ t('extractDetail8') }</div>
                              </div>
                              <div className="columns">
                                <div className="column">
                                  <QRCode value={ JSON.stringify(qrData) } />
                                </div>
                              </div>
                            </td>
                          </tr>
                        </TableList>
                      </div>
                      <ReactToPrint
                        trigger={ () => (
                          <div className="is-fullwidth has-text-right">
                            <button
                              type="button"
                              className="button is-primary"
                            >
                              { t('printQR') }
                            </button>
                          </div>
                        ) }
                        content={ () => this.infoToPrint }
                      />
                    </div>
                  </Modal>
                )
              }

              <TableList title={ t('detail') }>
                <tr>
                  <th className="has-text-centered is-size-5" colSpan="2">
                    { t('extractDetail1') }
                    <span className="icon text-verde">
                      <i className="fas fa-check-circle" />
                    </span>
                  </th>
                </tr>
                <tr>
                  <th>
                    { t('extractDetail2') }
                    <br />
                    { t('extractDetail3') }
                    { qrData.product }
                  </th>
                  <td>{ qrData.amount }</td>
                </tr>
                <tr>
                  <th className="has-text-centered is-size-6" colSpan="2">
                    { t('extractDetail4') }
                  </th>
                </tr>
                <tr>
                  <th>{ t('extractDetail5') }</th>
                  <td>
                    { t('extractDetail6') }
                  </td>
                </tr>
                <tr>
                  <th>{ t('extractDetail7') }</th>
                  <td>
                    <div className="columns">
                      <div className="column is-7">{ t('extractDetail8') }</div>
                      <div className="column">
                        <button
                          type="button"
                          className="button is-info"
                          onClick={ onToggle }
                        >
                          { t('showQR') }
                        </button>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th colSpan="2">
                    <div className="has-text-right">
                      <button
                        type="button"
                        className="button is-primary"
                        onClick={ onFinish }
                      >
                        { t('finish') }
                      </button>
                    </div>
                  </th>
                </tr>
              </TableList>
            </Fragment>
          ) }
        </Toggle>
      </div>
    );
  }
}

ExtractDetail.propTypes = {
  t: PropTypes.func.isRequired,
  onFinish: PropTypes.func.isRequired,
  qrData: PropTypes.shape(Object).isRequired,
};

export default withNamespaces()(ExtractDetail);
