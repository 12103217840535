import { createAction } from 'redux-actions';
import types from './types';

const startFetch = createAction(types.START_FETCH);
const endFetch = createAction(types.END_FETCH);
const fetchRoutes = createAction(types.FETCH_ROUTE);
const fetchRoutesByType = createAction(types.FETCH_ROUTE_BY_TYPE);
const updateRoutes = createAction(types.UPDATE_ROUTE);
const addRoute = createAction(types.ADD_ROUTE);
const saveRoute = createAction(types.SAVE_ROUTE);
const fetchRoutesRole = createAction(types.FETCH_ROUTE_ROLE);
const updateRoutesRole = createAction(types.UPDATE_ROUTE_ROLE);
const createRouteRole = createAction(types.CREATE_ROUTE_ROLE);
const deleteRouteRole = createAction(types.DELETE_ROUTE_ROLE);
const deleteRoute = createAction(types.DELETE_ROUTE);
const removeRoute = createAction(types.REMOVE_ROUTE);
const editRoute = createAction(types.EDIT_ROUTE);


const showMessage = createAction(types.MESSAGE,
  ({ message }) => ({
    message,
  }),
  ({ config }) => ({
    config,
  }));

export default {
  startFetch,
  endFetch,
  showMessage,
  fetchRoutes,
  fetchRoutesByType,
  updateRoutes,
  addRoute,
  saveRoute,
  fetchRoutesRole,
  updateRoutesRole,
  createRouteRole,
  deleteRouteRole,
  deleteRoute,
  removeRoute,
  editRoute,
};
