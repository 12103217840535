import { paymentConditionsActions, paymentConditionsTypes } from '../ducks/paymentConditions';
import { statsActions } from '../ducks/stats';

const url = '/b2b-payment-conditions';
const savePaymentCondition = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== paymentConditionsTypes.CREATE_PAYMENT_CONDITION) {
    return;
  }

  const {
    data,
    callback = () => {
    },
  } = action.payload;
  dispatch({
    type: paymentConditionsTypes.API_CALL,
    payload: {
      config: {
        method: 'POST',
        url,
        data,
      },
      authorization: true,
      onStart: paymentConditionsActions.startFetch,
      onComplete: (response) => {
        if (response.data.success) {
          callback(response.data.data);
        }
      },
      onEnd: paymentConditionsActions.endFetch,
    },
  });
};

const editPaymentCondition = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== paymentConditionsTypes.EDIT_PAYMENT_CONDITION) {
    return;
  }

  const {
    id,
    data,
    callback = () => {
    },
  } = action.payload;
  dispatch({
    type: paymentConditionsTypes.API_CALL,
    payload: {
      config: {
        method: 'PUT',
        url: `${url}/${id}`,
        data,
      },
      authorization: true,
      onStart: paymentConditionsActions.startFetch,
      onComplete: (response) => {
        if (response.data.success) {
          callback(response.data.data);
        }
      },
      onEnd: paymentConditionsActions.endFetch,
    },
  });
};

const deletePaymentCondition = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== paymentConditionsTypes.DELETE_PAYMENT_CONDITION) {
    return;
  }

  const {
    data: {
      id,
      is_request = false,
      sendEmail = false,
    },
    callback = () => {
    },
    t = () => {
    },
  } = action.payload;
  dispatch({
    type: paymentConditionsTypes.API_CALL,
    payload: {
      config: {
        method: 'POST',
        url: `b2b-payment-conditions/delete-condition`,
        // url: `b2b-payment-conditions/delete-condition?id=${id}&is_request=${is_request}`,
        data: {id, is_request, sendEmail}
      },
      authorization: true,
      onStart: paymentConditionsActions.startFetch,
      onComplete: (response) => {
        if (response.data.success) {
          callback(response.data.data);
        }
      },
      /* onComplete: () => {
        callback();
        dispatch(paymentConditionsActions.showMessage({
          message: t('deletePaymentCondition'),
          config: TOAST_CONFIG.SUCCESS,
        }));
      },
      onError: () => {
        dispatch(paymentConditionsActions.showMessage({
          message: t('cantDelete'),
          config: TOAST_CONFIG.INFO,
        }));
      },*/
      onEnd: paymentConditionsActions.endFetch,
    },
  });
};

const getPaymentCondition = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== paymentConditionsTypes.GET_PAYMENT_CONDITION) {
    return;
  }

  const {
    params: {
      person = '',
      isClient = '',
      idCondition = '',
    } = {},
    callback = () => {
    },
  } = action.payload;
  dispatch({
    type: paymentConditionsTypes.API_CALL,
    payload: {
      config: {
        method: 'GET',
        url: `b2b/conditions-providers?person=${person}&isClient=${isClient}&idCondition=${idCondition}`,
      },
      authorization: true,
      onStart: paymentConditionsActions.startFetch,
      onComplete: ({ data: { success, data } }) => {
        if (success) {
          callback(data);
          dispatch(paymentConditionsActions.savePaymentCondition(data));
        }
      },
      onEnd: paymentConditionsActions.endFetch,
    },
  });
};

const getPaymentConditionRange = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== paymentConditionsTypes.GET_PAYMENT_CONDITION_RANGE) {
    return;
  }

  const {
    params: {
      date_min = '',
      date_max = '',
    } = {},
    callback = () => {},
  } = action.payload;
  dispatch({
    type: paymentConditionsTypes.API_CALL,
    payload: {
      config: {
        method: 'GET',
        url: `${url}/payment-conditions?date_min=${date_min}&date_max=${date_max}`,
      },
      authorization: true,
      onStart: paymentConditionsActions.startFetch,
      onComplete: ({ data: { success, data } }) => {
        if (success) {
          callback(data);
          dispatch(statsActions.updateRelatedUsers(data));
        }
      },
      onEnd: paymentConditionsActions.endFetch,
    },
  });
};

const paymentRequest = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== paymentConditionsTypes.PAYMENT_REQUEST) {
    return;
  }

  const {
    data,
    callback = () => {
    },
  } = action.payload;
  dispatch({
    type: paymentConditionsTypes.API_CALL,
    payload: {
      config: {
        method: 'POST',
        url: `${url}/approve-payment-request`,
        data,
      },
      authorization: true,
      onStart: paymentConditionsActions.startFetch,
      onComplete: (response) => {
        if (response.data.success) {
          callback(response.data.data);
        }
      },
      onEnd: paymentConditionsActions.endFetch,
    },
  });
};

const requestReceived = ({ dispatch, getState }) => next => (action) => {
  next(action);

  if (action.type !== paymentConditionsTypes.REQUEST_BANK) {
    return;
  }

  const {
    auth: { user: { profile: { role } } },
    router: {
      location: { pathname }
    },
  } = getState() || {};

  const {
    params: {
      idBank = '',
      receiver = '',//pathname === '/payment-request-sent' ? false : true,
      is_bank = (role === 'buyer' || role === 'seller') ? false : true,
      rejected = '',
    } = {},
    callback = () => {
    },
  } = action.payload;
  dispatch({
    type: paymentConditionsTypes.API_CALL,
    payload: {
      config: {
        method: 'GET',
        url: `${url}/requests-bank?id=${idBank}&receiver=${receiver}&is_bank=${is_bank}&rejected=${rejected}`,
      },
      authorization: true,
      onStart: paymentConditionsActions.startFetch,
      onComplete: ({ data: { success, data } }) => {
        if (success) {
          callback(data);
          if (receiver === true) {
            dispatch(paymentConditionsActions.saveListReceiver(data));
          } else {
            dispatch(paymentConditionsActions.savePaymentCondition(data));
          }
        }
      },
      onEnd: paymentConditionsActions.endFetch,
    },
  });
};

const requestPaymentCondition = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== paymentConditionsTypes.REQUEST_PAYMENT_CONDITION) {
    return;
  }

  const {
    data,
    callback = () => {
    },
  } = action.payload;
  dispatch({
    type: paymentConditionsTypes.API_CALL,
    payload: {
      config: {
        method: 'POST',
        url: 'b2b-payment-conditions/create-payment-request',
        data,
      },
      authorization: true,
      onStart: paymentConditionsActions.startFetch,
      onComplete: (response) => {
        if (response.data.success) {
          callback(response.data.data);
        }
      },
      onEnd: paymentConditionsActions.endFetch,
    },
  });
};

const approveProductPayment = ({dispatch}) => next => (action) => {
  next(action);

  if (action.type !== paymentConditionsTypes.APPROVE_PRODUCT_PAYMENT) {
    return;
  }
  const {
    data,
    callback = () => {
    },
  } = action.payload;
  dispatch({
    type: paymentConditionsTypes.API_CALL,
    payload: {
      config: {
        method: 'POST',
        url: 'b2b-payment-conditions/approve-payment-assist-card',
        data,
      },
      authorization: true,
      onStart: paymentConditionsActions.startFetch,
      onComplete: (response) => {
        if (response.data.success) {
          callback(response.data.data);
        }
      },
      onEnd: paymentConditionsActions.endFetch,
    },
  });
};

const unsuscribeProductPayment = ({dispatch}) => next => (action) => {
  next(action);

  if (action.type !== paymentConditionsTypes.UNSUSCRIBE_PRODUCT_PAYMENT) {
    return;
  }
  const {
    data,
    callback = () => {
    },
  } = action.payload;
  dispatch({
    type: paymentConditionsTypes.API_CALL,
    payload: {
      config: {
        method: 'POST',
        url: 'b2b-payment-conditions/approve-request-unsubscribe-product',
        data,
      },
      authorization: true,
      onStart: paymentConditionsActions.startFetch,
      onComplete: (response) => {
        if (response.data.success) {
          callback(response.data.data);
        }
      },
      onEnd: paymentConditionsActions.endFetch,
    },
  });
};

const requestUnsubscribeProduct = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== paymentConditionsTypes.REQUEST_UNSUBSCRIBE_PRODUCT) {
    return;
  }

  const {
    data,
    callback = () => {
    },
  } = action.payload;
  dispatch({
    type: paymentConditionsTypes.API_CALL,
    payload: {
      config: {
        method: 'POST',
        url: 'b2b-payment-conditions/create-request-unsubscribe-product',
        data,
      },
      authorization: true,
      onStart: paymentConditionsActions.startFetch,
      onComplete: (response) => {
        if (response.data.success) {
          callback(response.data.data);
        }
      },
      onEnd: paymentConditionsActions.endFetch,
    },
  });
};

const acceptPaymentCondition = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== paymentConditionsTypes.ACCEPT_PAYMENT_CONDITION) {
    return;
  }

  const {
    data,
    callback = () => {
    },
  } = action.payload;
  dispatch({
    type: paymentConditionsTypes.API_CALL,
    payload: {
      config: {
        method: 'POST',
        url: 'b2b-payment-conditions/accept-discount-rate',
        data,
      },
      authorization: true,
      onStart: paymentConditionsActions.startFetch,
      onComplete: (response) => {
        if (response.data.success) {
          callback(response.data.data);
        }
      },
      onEnd: paymentConditionsActions.endFetch,
    },
  });
};

const getPaymentConditionLog = ({ dispatch, getState }) => next => (action) => {
  next(action);

  if (action.type !== paymentConditionsTypes.GET_PAYMENT_CONDITION_LOG) {
    return;
  }

  const {
    auth: { user: { profile: { role } } },
    router: {
      location: { pathname }
    },
  } = getState() || {};

  const {
    params: {
      idUser = '',
    } = {},
    callback = () => {
    },
  } = action.payload;
  dispatch({
    type: paymentConditionsTypes.API_CALL,
    payload: {
      config: {
        method: 'GET',
        url: `${url}/log?user=${idUser}`,
      },
      authorization: true,
      onStart: paymentConditionsActions.startFetch,
      onComplete: ({ data: { success, data } }) => {
        if (success) {
          callback(data);
          dispatch(paymentConditionsActions.paymentConditionLog(data));
        }
      },
      onEnd: paymentConditionsActions.endFetch,
    },
  });
};

const checkPaymentRequest = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== paymentConditionsTypes.CHECK_PAYMENT_REQUEST) {
    return;
  }

  const {
    data,
    callback = () => {
    },
  } = action.payload;
  dispatch({
    type: paymentConditionsTypes.API_CALL,
    payload: {
      config: {
        method: 'POST',
        url: `${url}/check-payment-request`,
        data,
      },
      authorization: true,
      onStart: paymentConditionsActions.startFetch,
      onComplete: ({ data: { success, data } }) => {
        if (success) {
          callback(data);
          dispatch(paymentConditionsActions.paymentConditionLog(data));
        }
      },
      onEnd: paymentConditionsActions.endFetch,
    },
  });
};

const getInfoPaymentCondition = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== paymentConditionsTypes.GET_INFO_PAYMENT_CONDITION) {
    return;
  }

  const {
    params: {
      condition = '',
    },
    callback = () => {
    },
  } = action.payload;
  dispatch({
    type: paymentConditionsTypes.API_CALL,
    payload: {
      config: {
        method: 'GET',
        url: `${url}/payment-condition?condition=${condition}`,
      },
      authorization: true,
      onStart: paymentConditionsActions.startFetch,
      onComplete: ({ data: { success, data } }) => {
        if (success) {
          callback(data);
          dispatch(paymentConditionsActions.paymentConditionLog(data));
        }
      },
      onEnd: paymentConditionsActions.endFetch,
    },
  });
};

const getAdminPaymentConditions = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== paymentConditionsTypes.GET_ADMIN_PAYMENT_CONDITIONS) {
    return;
  }

  const {
    callback = () => {
    },
  } = action.payload;
  dispatch({
    type: paymentConditionsTypes.API_CALL,
    payload: {
      config: {
        method: 'GET',
        url: `${url}/get-payment-conditions`,
      },
      authorization: true,
      onStart: paymentConditionsActions.startFetch,
      onComplete: (res) => {
        callback(res)
      },
      onError: (res) => {
        callback(res)
      },
      onEnd: paymentConditionsActions.endFetch,
    },
  });
};

const activateBpp = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== paymentConditionsTypes.ACTIVATE_BPP) {
    return;
  }

  const {
    data,
    callback = () => {
    },
  } = action.payload;
  dispatch({
    type: paymentConditionsTypes.API_CALL,
    payload: {
      config: {
        method: 'POST',
        url: `${url}/activate-bpp`,
        data,
      },
      authorization: true,
      onStart: paymentConditionsActions.startFetch,
      onComplete: (res) => {
        callback(res);
      },
      onError: (res) => {
        callback(res);
      },
      onEnd: paymentConditionsActions.endFetch,
    },
  });
};

export default [
  savePaymentCondition, 
  editPaymentCondition, 
  deletePaymentCondition, 
  getPaymentCondition, 
  getPaymentConditionRange, 
  paymentRequest, 
  requestReceived, 
  requestPaymentCondition, 
  approveProductPayment, 
  unsuscribeProductPayment, 
  requestUnsubscribeProduct, 
  acceptPaymentCondition, 
  getPaymentConditionLog, 
  checkPaymentRequest, 
  getInfoPaymentCondition,
  getAdminPaymentConditions,
  activateBpp,
];
