/**
 * Created by Jonas on 02/09/19.
 */

import React from 'react';
import PropTypes from 'prop-types';
import Panel from '../Panel';
import Form from '../Form';
import TextInput from '../TextInput';
import SelectSingle from '../SelectSingle';
import { CreatableMultiSelect } from '..';
import './styles.scss';

const PersonForm = ({
                      t,
                      onSubmit,
                      handleInputChange,
                      handleSelectChange,
                      handleItemChange,
                      name,
                      documentTypesList,
                      documentNumber,
                      items,
                      itemList,
                      email,
                      password,
                      client,
                      adminName,
                      adminLastname,
                      adminPosition,
                      title,
                      edit,
                      role,
                      phoneNumber,
                      phoneNumberAdmin,
                      handleOnInputChange,
                      districts,
                      district,
                      inputValue,
                      paymentCurrency,
                      executives,
                      executivesList,
                      handleExecutivesChange,
                      handleOnInputChangeExecutive,
                      inputValueExecutive,
                      newBankingExec,
                      addBankingExec,
                      deleteBankingExec,
                      handleInputChangeNewExc,
                      documentTypeUser,
                      street,
                      tradeInformation,
                      addTradeInformation,
                      deleteTradeInformation,
                      handleInputChangeTradeInformation,
                      handleSelectCurrency,
                      cif,
                      cancelSave,
                      categories,
                      category,
                      confirm,
                      pathName,
                      handleOnInput,
                      industriesList,
                      inputValueIndustry,
                      handleOnInputChangeIndustry,
                      outdoor_number,
                      interior_number,
                      suburb,
                      municipality,
                      postal_code,
                      authorized_by,
                      industry,
                      confirmBuyer,
                      tradeInformationOption,
                      onToggle,
                      messageDate,
                      areaCode,
                      countryCode,
                      userType,
                    }) => (
  <Panel headingText={title}>
    <Form
      submitText={((role === 'seller') || (role === 'buyer')) ? t('confirm') : t('save')}
      onSubmit={(event) => {
        event.preventDefault();
        onSubmit();
      }}
      buttonClasses={`mr-8px ${confirm ? '' : 'is-hidden'}`}
    >
      <div className="terms_box1" style={{ display: (!client || userType !== 'NCL' ? 'none' : '')}}>
        <p style={{ marginBottom: '.2em' }}>{t('clientWithACG')}</p>
      </div>
      <div className="field is-horizontal subtitle is-5 text-black">
        {t('demographicInformation')}
      </div>
      <div className="field is-horizontal">
        <div className="field-body columns">
          <div className="column">
            <TextInput
              name="name"
              label={ !role ? t('clientName') : t('businessName') }
              value={name}
              onChange={handleInputChange}
              placeholder={!role ? t('clientName') : t('businessName')}
              type="text"
              controlClasses="is-expanded"
              disabled={confirmBuyer}
              isRequired={true}
            />
          </div>
        </div>
      </div>
      <div className="field is-horizontal">
        <div className="field-body columns">
          <div className="column">
            <SelectSingle
              name="documentTypeUser"
              label={t('documentType')}
              selectClasses="is-fullwidth"
              placeholder={t('Select')}
              onChange={handleSelectChange}
              options={[
                ...documentTypesList.map((item, index) => ({
                  label: item.document_type,
                  value: index,
                })),
              ]}
              value={documentTypeUser}
              isRequired={true}
              isDisabled={confirmBuyer}
            />
          </div>
          <div className="column">
            <TextInput
              name="documentNumber"
              label={t('documentNumber')}
              value={documentNumber}
              onChange={handleInputChange}
              onInput={handleOnInput}
              placeholder={t('documentNumber')}
              type="text"
              controlClasses="is-expanded"
              inputClasses="has-text-right"
              disabled={confirmBuyer}
              maxlength={13}
              isRequired={true}
              dataRegex="alphaNumeric"
            />
          </div>
          <div className="column" style={{ display: pathName ? 'none' : 'none' }}>
            <TextInput
              name="cif"
              value={cif}
              onChange={handleInputChange}
              type="text"
              controlClasses="is-expanded"
              inputClasses="has-text-right"
              maxlength={12}
              pattern="(?=.*?[0-9])(?=.*?[A-Za-z]).+"
              title={t('cifPattern')}
            />
          </div>
        </div>
      </div>
      <div className="field is-horizontal">
        <div className="field-body columns">
          <div className="column">
            <TextInput
              name="street"
              label={t('street')}
              value={street}
              onChange={handleInputChange}
              placeholder={t('street')}
              type="text"
              controlClasses="is-expanded"
              disabled={confirmBuyer}
              isRequired={true}
            />
          </div>
          <div className="column">
            <TextInput
              name="outdoor_number"
              label={t('outdoorNumber')}
              value={outdoor_number}
              onChange={handleInputChange}
              placeholder={t('outdoorNumber')}
              type="text"
              controlClasses="is-expanded"
              disabled={confirmBuyer}
              isRequired={true}
              maxlength={100}
            />
          </div>
          <div className="column">
            <TextInput
              name="interior_number"
              label={t('interiorNumber')}
              value={interior_number}
              onChange={handleInputChange}
              placeholder={t('interiorNumber')}
              type="text"
              controlClasses="is-expanded"
              disabled={confirmBuyer}
              maxlength={100}
            />
          </div>
        </div>
      </div>
      <div className="field is-horizontal">
        <div className="field-body columns">
          <div className="column">
            <TextInput
              name="suburb"
              label={t('suburb')}
              value={suburb}
              onChange={handleInputChange}
              placeholder={t('suburb')}
              type="text"
              controlClasses="is-expanded"
              disabled={confirmBuyer}
              isRequired={true}
            />
          </div>
          <div className="column">
            <TextInput
              name="municipality"
              label={t('municipality')}
              value={municipality}
              onChange={handleInputChange}
              placeholder={t('municipality')}
              type="text"
              controlClasses="is-expanded"
              disabled={confirmBuyer}
              isRequired={true}
            />
          </div>
          <div className="column">
            <SelectSingle
              name="district"
              label={t('province')}
              selectClasses="is-fullwidth"
              placeholder={t('Select')}
              onChange={handleSelectChange}
              options={[
                ...districts.map((item, index) => ({
                  label: item.name,
                  value: index,
                })),
              ]}
              value={district}
              isRequired={true}
              isDisabled={confirmBuyer}
            />
          </div>
          <div className="column">
            <TextInput
              name="postal_code"
              label={t('postalCode')}
              value={postal_code}
              onChange={handleInputChange}
              placeholder={t('postalCode')}
              type="text"
              controlClasses="is-expanded"
              isRequired={true}
              disabled={confirmBuyer}
            />
          </div>
        </div>
      </div>
      <div className="field is-horizontal">
        <div className="field-body columns">
          <div className="column">
            <SelectSingle
              name="category"
              label={t('categories')}
              selectClasses="is-fullwidth"
              placeholder={t('Select')}
              onChange={handleSelectChange}
              options={[
                ...categories.map((item, index) => ({
                  label: item.description,
                  value: index,
                })),
              ]}
              value={category}
              isRequired={((role === 'issuing_bank') || (role === 'buyer')) ? false : true}
              isDisabled={confirmBuyer ? true : false}
            />
          </div>
          <div className="column">
            <CreatableMultiSelect
              name="items"
              label={t('items')}
              value={items}
              onChange={handleItemChange}
              placeholder={t('selectOrTypeItems')}
              noOptionsMessage={() => t('noItemsAvailable')}
              formatCreateLabel={inputValue => t('createNewItem', { inputValue })}
              getOptionLabel={option => option.classification === undefined ? '' : option.classification + ' - ' + option.code + ' - ' + option.description}
              getOptionValue={option => option.id}
              controlClasses="is-expanded"
              options={itemList}
              onInputChange={handleOnInputChange}
              inputValue={inputValue}
              isRequired={((role === 'issuing_bank') || (role === 'buyer')) ? false : true}
              isDisabled={category === '' || confirmBuyer ? true : false}
            />
          </div>
          <div className="column">
            <SelectSingle
              name="industry"
              label={t('items')+" SAT "}
              selectClasses="is-fullwidth"
              placeholder={t('Select')}
              onChange={handleSelectChange}
              options={[
                ...industriesList.map((industry, index) => ({
                  label: industry.description == '' ? "Seleccione..." : industry.code +" - "+ industry.description,
                  value: index,
                })),
              ]}
              value={industry}
              isRequired={((role === 'issuing_bank') || (role === 'buyer')) ? true : false}
              isDisabled={confirmBuyer ? true : false}
              isSearchable
              onInputChange={handleOnInputChangeIndustry}
              inputValue={inputValueIndustry}
            />
          </div>
        </div>
      </div>
      {((role === 'acquiring_bank') || (role === 'seller')) &&
      <div style={{ display: pathName ? 'none' : '' }}>
        <div className="field is-horizontal subtitle is-5 text-black">
          {t('tradeInformation')}
        </div>
          <div className="field is-horizontal">
            <div className="field-body columns">
              <div className="column is-one-quarter">
                <input
                  className='mr-8px'
                  type="radio" 
                  name="TDC" 
                  onChange={handleInputChangeTradeInformation} 
                  disabled={edit} 
                  checked={tradeInformationOption === "TDC"}/>
                <label htmlFor='TDC'>TDC *</label>
              </div>
              <div className="column is-one-quarter">
                <input
                  className='mr-8px'
                  type="radio" 
                  name="NCL" 
                  onChange={handleInputChangeTradeInformation} 
                  disabled={edit} 
                  checked={tradeInformationOption === "NCL"}/>
                <label htmlFor='NCL'>NCL *</label>
              </div>
            </div>
          </div>
          { tradeInformationOption === 'TDC' &&
            <ul>
              {tradeInformation.map((val, index) => (
                <li key={index}>
                  <div className="field is-horizontal">
                    <div className="field-body columns">
                      <div className="column">
                        <TextInput
                          id={index}
                          name={'commerce_code-' + index}
                          label={t('affiliationNumber')}
                          value={val.commerce_code}
                          onChange={handleInputChangeTradeInformation}
                          placeholder={t('affiliationNumber')}
                          type="text"
                          controlClasses="is-expanded"
                          maxlength={10}
                          isRequired={(!client && !pathName)}
                        />
                      </div>
                      <div className="column">
                        <SelectSingle
                          id={index}
                          name={'currency-' + index}
                          label={t('paymentCurrency')}
                          selectClasses="is-fullwidth"
                          placeholder={t('Select')}
                          onChange={handleSelectCurrency}
                          options={[
                            ...paymentCurrency.map((item, index) => ({
                              label: item.description+" ("+item.id+")",
                              value: index,
                            })),
                          ]}
                          value={val.currency}
                          isRequired={(!client && !pathName)}
                        />
                      </div>
                      <div className="column">
                        <TextInput
                          id={index}
                          name={'description-' + index}
                          label={t('commerceName')}
                          value={val.description}
                          onChange={handleInputChangeTradeInformation}
                          placeholder={t('commerceName')}
                          type="text"
                          controlClasses="is-expanded"
                          //maxlength={15}
                          title={t('commerceCodeDesc')}
                          isRequired={(!client && !pathName)}
                        />
                      </div>
                      <div style={{ marginTop: '36px', }}>
                        <button
                          className="button is-info is-outlined is-pulled-right"
                          type="button"
                          onClick={addTradeInformation}
                          disabled={index === tradeInformation.length - 1 ? false : true}//{index === index - 1 ? true : false}

                        >
                          <i className="fas fa-plus-circle"/>
                        </button>
                      </div>
                      <div style={{
                        marginTop: '36px',
                        marginLeft: '.5em'
                      }}>
                        <button
                          className="button is-danger is-outlined"
                          type="button"
                          onClick={deleteTradeInformation.bind(this, index)}
                          disabled={index === 0 ? true : false}
                        >
                          <i className="fas fa-minus-circle mr-10px"/>
                        </button>
                      </div>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          }
      </div>
      }
      {role === 'issuing_bank' && (
      <div style={{ display: pathName ? 'none' : '' }}>
        <div className="field is-horizontal subtitle is-5 text-black">
          {t('businessExecutive')}
        </div>
        <div className="field is-horizontal">
          <div className="field-body columns">
            <div className="column">
              <CreatableMultiSelect
                name="executives"
                label={t('businessExecutive')}
                value={executives}
                onChange={handleExecutivesChange}
                placeholder={t('Select')}
                noOptionsMessage={() => t('noExecutivesAvailable')}
                formatCreateLabel={inputValueExecutive => t('createNewExecutive', { inputValueExecutive })}
                getOptionLabel={option => option.name === undefined ? '' : option.name + ' ' + option.lastname + ' - ' + option.email}
                //getOptionLabel={option => option.id === undefined ? '' : option.name + ' ' + option.lastname + ' - ' + option.email}
                getOptionValue={option => option.id}
                getNewOptionData={(inputValueExecutive, optionLabel) => ({
                  id: optionLabel,
                  description: optionLabel,
                })
                }
                controlClasses="is-expanded"
                options={executivesList}
                //options={handleOnInputChangeExecutive === null || inputValueExecutive.length > 1 ? executivesList : [{}]}
                onInputChange={handleOnInputChangeExecutive}
                inputValue={inputValueExecutive}
                //isRequired={true}
              />
            </div>
          </div>
        </div>
        {newBankingExec.length > 0 && (
          <div>
            <div className="field is-horizontal subtitle is-4 text-black">
              {t('addNew') + ' ' + t('businessExecutive')}
            </div>
            <ul>
              {newBankingExec.map((val, index) => (
                <li>
                  <div className="field is-horizontal">
                    <div className="field-body columns">
                      <div className="column">
                        <TextInput
                          id={index}
                          name={'name-' + index}
                          label={t('name')}
                          value={val.name}
                          onChange={handleInputChangeNewExc}
                          placeholder={t('name')}
                          inputClasses="has-text-left"
                          type="text"
                          controlClasses="is-expanded"
                          //isRequired={true}
                        />
                      </div>
                      <div className="column">
                        <TextInput
                          id={index}
                          name={'lastname-' + index}
                          label={t('adminLastname')}
                          value={val.lastname}
                          onChange={handleInputChangeNewExc}
                          placeholder={t('adminLastname')}
                          inputClasses="has-text-left"
                          type="text"
                          controlClasses="is-expanded"
                          //isRequired={true}
                        />
                      </div>
                      <div className="column">
                        <TextInput
                          id={index}
                          name={'email-' + index}
                          label={t('email')}
                          value={val.email}
                          onChange={handleInputChangeNewExc}
                          placeholder={t('email')}
                          inputClasses="has-text-left"
                          type="text"
                          controlClasses="is-expanded"
                          isRequired={true}
                        />
                      </div>
                      <div style={{ marginTop: '36px', }}>
                        {index === newBankingExec.length - 1 ?
                          <button
                            className="button is-info is-outlined is-pulled-right"
                            type="button"
                            onClick={addBankingExec}
                            //disabled={index === newBankingExec.length - 1 ? false : true}
                          >
                            <i className="fas fa-plus-circle"/>
                          </button>
                          :
                          <button
                            className="button is-danger is-inverted"
                            type="button"
                            onClick={deleteBankingExec.bind(this, index)}
                            //disabled={route}
                          >
                            <i className="fas fa-minus-circle mr-10px"/>
                          </button>
                        }
                      </div>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>)}
      <div className="field is-horizontal subtitle is-5 text-black">
        {t('authUser')}
      </div>
      <div className="field is-horizontal">
        <div className="field-body columns">
          <div className="column">
            <TextInput
              name="adminName"
              label={t('adminName')}
              value={adminName}
              onChange={handleInputChange}
              placeholder={t('adminName')}
              type="text"
              controlClasses="is-expanded"
              maxlength={250}
              isRequired={true}
              disabled={confirmBuyer}
            />
          </div>
          <div className="column">
            <TextInput
              name="adminLastname"
              label={t('adminLastname')}
              value={adminLastname}
              onChange={handleInputChange}
              placeholder={t('adminLastname')}
              type="text"
              controlClasses="is-expanded"
              isRequired={true}
              disabled={confirmBuyer}
            />
          </div>
          <div className="column"  style={{ display: !client ? 'none' : '' }}>
            <TextInput
              name="authorized_by"
              label={t('authorizedBy')}
              value={authorized_by}
              onChange={handleInputChange}
              placeholder={t('authorizedBy')}
              type="text"
              controlClasses="is-expanded"
              isRequired={client}
              disabled={confirmBuyer}
            />
          </div>
          <div className="column" style={{ display: 'none' }}>
            <TextInput 
              name="password"
              label={t('password')}
              value={password}
              onChange={handleInputChange}
              placeholder={t('password')}
              type="password"
              controlClasses="is-expanded"
              disabled={edit || confirmBuyer}
            />
          </div>
        </div>
      </div>
      {role === 'issuing_bank' && (
        <div className="field is-horizontal">
          <div className="field-body columns">
            <div className="column">
              <TextInput
                name="countryCode"
                label={t('countryCode')}
                value={countryCode}
                onChange={handleInputChange}
                placeholder={t('countryCode')}
                type="text"
                controlClasses="is-expanded"
                inputClasses="has-text-right"
                maxlength={12}
                disabled={confirmBuyer}
                isRequired={true}
              />
            </div>
            <div className="column">
              <TextInput
                name="areaCode"
                label={t('areaCode')}
                value={areaCode}
                onChange={handleInputChange}
                placeholder={t('areaCode')}
                type="text"
                controlClasses="is-expanded"
                inputClasses="has-text-right"
                maxlength={12}
                disabled={confirmBuyer}
                isRequired={true}
              />
            </div>
            <div className="column">
              <TextInput
                name="phoneNumber"
                label={t('contactPhone')}
                value={phoneNumber}
                onChange={handleInputChange}
                placeholder={t('contactPhone')}
                type="text"
                controlClasses="is-expanded"
                inputClasses="has-text-right"
                maxlength={12}
                disabled={confirmBuyer}
                isRequired={true}
              />
            </div>
          </div>
        </div>
        )
      }
      <div className="field is-horizontal">
        <div className="field-body columns">
          <div className="column">
            <TextInput
              name="adminPosition"
              label={t('adminPosition')}
              value={adminPosition}
              onChange={handleInputChange}
              placeholder={t('adminPosition')}
              type="text"
              controlClasses="is-expanded"
              maxlength={250}
              isRequired={true}
              disabled={confirmBuyer}
            />
          </div>
          <div className="column" style={{ display: 'none' }}>
            <TextInput
              name="phoneNumberAdmin"
              label={t('contactPhone')}
              value={phoneNumberAdmin}
              onChange={handleInputChange}
              placeholder={t('phone')}
              type="text"
              controlClasses="is-expanded"
              inputClasses="has-text-right"
              maxlength={12}
              disabled={confirmBuyer}
            />
          </div>
          <div className="column">
            <TextInput
              name="email"
              label={t('email')}
              value={email}
              onChange={handleInputChange}
              placeholder={t('email')}
              type="text"
              controlClasses="is-expanded"
              isRequired={true}
              disabled={confirmBuyer}
            />
          </div>
        </div>
      </div>
      <div className="field is-horizontal">
        <label>{t('requiredFields')}</label>
      </div>
      <div 
        className="field is-grouped is-grouped-centered" 
        style={{display: confirm ? 'none' : '', width: '50%', margin: '30px auto'}}>
          <button
            type="button"
            className="button custom-btn is-block is-danger mr-8px"
            style={{ color: '#C4C4C4', flex: '1', maxWidth: '200px' }}
            onClick={cancelSave}
          >
            {t('cancel')}
          </button>
          <button
            type="button"
            className="button custom-btn is-block is-primary mr-8px"
            style={{ flex: '1', maxWidth: '200px' }}
            onClick={(role === 'issuing_bank') ?
              messageDate.bind(this, onToggle) : onSubmit.bind(this)}
          >
            {((role === 'seller') || (role === 'buyer')) ? t('confirm') : t('save')}
          </button>
      </div>
    </Form>
  </Panel>
);

PersonForm.propTypes = {
  t: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  handleInputChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  handleSelectChange: PropTypes.func.isRequired,
  documentTypesList: PropTypes.arrayOf(PropTypes.object).isRequired,
  documentNumber: PropTypes.string.isRequired,
  address: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  handleItemChange: PropTypes.func.isRequired,
  itemList: PropTypes.arrayOf(PropTypes.object).isRequired,
  email: PropTypes.string.isRequired,
  documentType: PropTypes.number.isRequired,
  password: PropTypes.string.isRequired,
  persons: PropTypes.arrayOf(Object).isRequired,
  baseDays: PropTypes.number.isRequired,
  percentage: PropTypes.number.isRequired,
  discountRate: PropTypes.number,
  client: PropTypes.bool,
  adminName: PropTypes.string,
  adminLastname: PropTypes.string.isRequired,
  adminDocumentNumber: PropTypes.string.isRequired,
  adminDocumentType: PropTypes.number.isRequired,
  adminPosition: PropTypes.string,
  receiverDiscountShare: PropTypes.number.isRequired,
  handlePercentageChange: PropTypes.func.isRequired,
  clientPayment: PropTypes.number.isRequired,
  typeFlows: PropTypes.arrayOf(PropTypes.object).isRequired,
  typeFlow: PropTypes.number.isRequired,
  handleOnInput: PropTypes.func,
};

PersonForm.defaultProps = {
  discountRate: 0,
  client: false,
  adminName: '',
  adminLastname: '',
  adminPosition: '',
  pathName: false,
  confirm: false,
};

export default PersonForm;
