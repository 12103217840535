import React from "react"
import "./styles.scss"
import PropTypes from "prop-types"

const PaymentLogForm = ({ log, t }) => {
  return (
    <>
      {log && log.length > 0 ? (
        <ul className="PaymentLogForm__main Form">
          {log.map((log, index) => {
            return (
              <li className="PaymentLogForm__log" key={log.date}>
                <h3>
                  {t("chargeAttempt")} {index + 1}
                </h3>
                <div className="PaymentLogForm__data">
                  <div className="PaymentLogForm__field">
                    <p>{t("executionDate")}</p>
                    <p>{log.date}</p>
                  </div>
                  <div className="PaymentLogForm__field">
                    <p>{t("status")}</p>
                    <p>{log.status}</p>
                  </div>
                  <div className="PaymentLogForm__field">
                    <p>{t("description")}</p>
                    <p>{log.description}</p>
                  </div>
                </div>
              </li>
            )
          })}
        </ul>
      ) : (
        <p className="no-info-msg">{t("noInfoMsg")}</p>
      )}
    </>
  )
}

PaymentLogForm.propTypes = {
  log: PropTypes.shape(Object).isRequired,
  t: PropTypes.func.isRequired,
}

export default PaymentLogForm
