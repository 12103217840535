import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

const Loading = (props) => {
  const {
    // Default value for close function
    close,
  } = props;

  return (
    <div className="loading">
      {/* eslint-disable-next-line max-len */}
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions */}
      <div
        // eslint-disable-next-line jsx-a11y/aria-role
        role="Presentation"
        className="holder"
        onClick={close}
      >
        <div className="preloader">
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
        </div>
      </div>
    </div>
  );
};

Loading.propTypes = {
  close: PropTypes.func,
};

Loading.defaultProps = {
  close: () => {},
};

export default Loading;
