const START_FETCH = 'financingProducts/START_FETCH';
const END_FETCH = 'financingProducts/END_FETCH';
const API_CALL = 'financingProducts/API_CALL';
const MESSAGE = 'financingProducts/MESSAGE';
const FETCH_FINANCING_PRODUCTS = 'financingProducts/FETCH_FINANCING_PRODUCTS';
const UPDATE_FINANCING_PRODUCTS = 'financingProducts/UPDATE_FINANCING_PRODUCTS';
const ADD_FINANCING_PRODUCTS = 'financingProducts/ADD_FINANCING_PRODUCTS';
const EDIT_FINANCING_PRODUCTS = 'financingProducts/EDIT_FINANCING_PRODUCTS';
const DELETE_FINANCING_PRODUCT = 'financingProducts/DELETE_FINANCING_PRODUCT';
const REMOVE_FINANCING_PRODUCT = 'financingProducts/REMOVE_FINANCING_PRODUCT';


export default {
  START_FETCH,
  END_FETCH,
  API_CALL,
  MESSAGE,
  FETCH_FINANCING_PRODUCTS,
  UPDATE_FINANCING_PRODUCTS,
  ADD_FINANCING_PRODUCTS,
  EDIT_FINANCING_PRODUCTS,
  DELETE_FINANCING_PRODUCT,
  REMOVE_FINANCING_PRODUCT,
};
