const es = {
  translations: {
    accept: 'Aceptar',
    acceptTerms: 'Debe aceptar los términos y condiciones',
    acceptTermsAndConditions: 'He leído y acepto los términos y condiciones del Servicio',
    acceptTermsGateway: 'Aceptamos los cargos o tarifas por servicio que en su caso estén indicados en la sección Clientes y aceptamos que éstas sean cargadas por GETNET mediante deducción directa a las liquidaciones mensuales practicadas al ESTABLECIMIENTO, como se indica en el Contrato de Afiliación y en su Anexo que regula el acceso y uso de la plataforma “B2B Enterprise”',
    account: 'Cuenta',
    accountBalance: 'Saldo de la cuenta',
    accountPlural: 'Cuentas',
    accumulatedAmount: 'Monto acumulado diario',
    acquiring_bank: 'Banco Adquiriente',
    acquiringBank: 'Adquiriente',
    action: 'Acción',
    active: 'Activo',
    activeCompanies: 'Empresas activas',
    activate: 'Activar',
    administrators: 'Administradores',
    administratorsList: 'Lista de administradores',
    addComment: 'Agregar comentario ...',
    addFinanceProductSuccess: 'Producto de financiamiento creado de manera exitosa',
    addFinancingProducts: 'Agregar productos de financiamiento',
    additionalInformation: 'Información Adicional',
    addNew: 'Agregar nuevo',
    address: 'Dirección',
    admin: 'Administrar',
    administratorName: 'Nombre del administrador',
    administratorBank: 'Banco Administrador',
    adminRoutes: 'Administrar rutas',
    assignPermissions: 'Gestionar permisos',
    adminUser: 'Usuario Administrador',
    affiliateCode: 'Código de afiliación',
    affiliateNumber: 'Número de afiliado',
    affiliateN: 'Nro. de afiliado',
    affiliateClients: 'Clientes afiliados',
    authUser: 'Usuario autorizado',
    advance: 'Anticipar',
    advanceCommercialConditions: 'Condiciones comerciales de anticipo',
    alias: 'Alias',
    alreadyRegisteredCard: 'El cliente ya tiene una tarjeta registrada',
    allowedFormats: 'Formatos permitidos: JPG y PNG. Tamaño máximo 3MB.',
    allowedFormatsPDF: 'Formatos permitidos: PDF. Tamaño máximo 3MB.',
    allowedFormatsXML: 'Formatos permitidos: XML. Tamaño máximo 3MB.',
    amexAffiliationNumber: 'Nro. de afiliación AMEX',
    amexCard: 'Tarjeta Tesorero Amex',
    amount: 'Monto',
    amountInt: 'El monto debe ser un número entero',
    amountInstallments: 'Monto de las cuotas',
    amountMoney: 'Monto de dinero',
    amountOfTransactionsToProcess: 'Monto de transacciones por procesar',
    amountAverageTransactionsMonthly: 'Monto promedio mensual de transacciones',
    amountToApprove: 'Monto por aprobar',
    amountToCollect: 'Total diario por cobrar',
    amountToGet: 'Facturas por cobrar',
    amountToPay: 'Monto por pagar',
    anticipatedAmount: 'Monto anticipado',
    annualQuota: 'Cuota anual',
    anyName: 'Cualquier de los nombres registrados',
    applicant: 'Solicitante',
    applicationDate: 'Fecha de solicitud',
    applicantName: 'Nombre del solicitante',
    applicantBank: 'Banco solicitante',
    applicationDeleted: 'Solicitud eliminada',
    applicationOneNotification: 'Tiene {{payment_request_pending}} solicitud',
    applicationNotification: 'Tienes {{payment_request_pending}} solicitudes',
    applicationSuccess: 'Solicitud aprobada exitosamente',
    applicationRejected: 'Solicitud rechazada exitosamente',
    approvalConditionDate: 'Fecha de aprobación de condición comercial',
    approvalConditionDateUserType: 'Fecha de alta {{userType}}',
    approvalDate: 'Fecha de aprobación',
    approvalStatus: 'Estado de aprobación',
    approve: 'Aprobar',
    approved: 'Aprobadas',
    approvedStatus: 'Aprobado',
    approveInvoice: 'Aprobar factura',
    approvedInvoices: 'Fac. aprobadas',
    approvedInvoicesTitle: 'Facturas aprobadas',
    arrangedDays: 'Días pactados',
    asterisk: '*** {{number_card}}',
    assistCard: 'Assist card',
    assign: 'Asignar',
    assignRoute: 'Asignar rutas',
    assignedRoutes: 'Rutas asignadas',
    assignedCardNumber: 'Nro. tarjeta asignada',
    assignRoleRoute: 'El rol y la ruta se asignaron exitosamente',
    assignRouteSuccess: 'La ruta fue asignada exitosamente',
    authorization: 'Autorización',
    available: 'Disponible',
    availableLineApprovedInvoices: 'Disponible de línea sobre facturas aprobadas',
    avance: 'Anticipo',
    balance: 'Saldo',
    bankList: 'Lista de bancos',
    bankLogo: 'Logo del Banco',
    bankName: 'Nombre del banco',
    bankProvider: 'AMEX',
    banks: 'Bancos',
    bankType: 'Tipo de banco',
    baseConditions: 'Condiciones Base (Pago de Facturas)',
    benefits: 'Beneficios',
    bills: 'Facturas \nenviadas',
    billPayment: 'Pago de facturas',
    billingVolume: 'Volumen de facturación',
    billingPerMonth: 'Facturación {{month}}',
    bin: 'Bin',
    business: 'Empresas',
    businessName: 'Razón social',
    businessExecutive: 'Ejecutivo comercial',
    businessExecutives: 'Ejecutivos comerciales',
    cardLoadedSuccessfully: 'Tarjeta cargada exitosamente',
    cardPaymentDate: 'Fecha de pago de tarjeta de crédito',
    onlyCardPaymentDate: 'Fecha de pago de tarjeta',
    byAmount: 'por un monto de',
    by_amount: 'Por monto',
    by_hierarchy: 'Por jerarquía de puestos',
    cancel: 'Cancelar',
    cardChargeDate: 'Fecha de cargo en la tarjeta',
    cardholderPaymentPercentage: 'Porcentaje de pago del tarjetahabiente',
    cardPayment: 'TTA seleccionada para pago',
    cedulaJuridicaDesc: '10 dígitos, sin cero al inicio ni guiones',
    changeOfCommercialConditions: 'Registro de cambio de condiciones comerciales',
    charge: 'Cargo',
    chargeDate: 'Fecha de cargo',
    chargeForService: 'Cargo por servicio',
    cif: 'CIF',
    cifPattern: 'Debe contener letras y números',
    clientName: 'Nombre del cliente',
    clientPaymentCondition: 'Determina las condiciones de pago para este cliente',
    code: 'Código',
    codeArea: 'cód. área + n˚ teléfono',
    collaboratorNumber: 'Número de colaborador',
    collaboratorNumberCharacter: 'Número de colaborador, debe tener 8 caracteres',
    company: 'Compañia',
    commerceCode: 'Nro. de afiliado',
    updateInfo: 'Actualizar información',
    commercialBillingUser: 'Usuario de facturación comercial',
    commerceCodeDesc: 'Ingrese descripción para código de comercio',
    commercialCreditDays: 'Días de crédito comercial',
    commercialCreditSince: 'Crédito comercial a partir de',
    commercialConditions: 'Condiciones comerciales',
    condition: 'Condición',
    concept: 'Concepto',
    configuration: 'Configuración',
    confirm: 'Confirmar',
    confirmInvoiceApprovement: '¿Está seguro que desea aprobar la factura No. {{ invoiceNu }} por un monto de {{ currency}} {{ invoiceAmount }}?',
    confirmInvoiceDecline: '¿Está seguro que desea declinar la factura No. {{ invoiceNu }} por un monto de {{ currency}} {{ invoiceAmount }}?',
    confirmPassword: 'Confirmar contraseña',
    confirmUnsubscribe: '¿Está seguro que desea dar de baja la condición comercial con {{client}} y el {{product}} que involucra?',
    confirmUnsubscribeFrom: 'Confirmar baja de: {{product}}',
    confirmSupplierBeforeEdit: 'El proveedor debe confirmar sus datos antes de poder editarlo',
    confirmTaxCode: 'Confirmar Clave Tributaria',
    confirmTransfer: 'Confirme su transacción',
    continue: 'Continuar',
    consult: 'Consultar',
    productsContracted: 'Productos contratados',
    cardBind: 'Bin de tarjetas',
    cort: 'Corte',
    cortDate: 'Fecha de corte',
    createBank: 'Crear nuevo banco',
    createBankSuccess: 'Banco {{name}} creado de manera exitosa',
    createClientSuccess: 'Cliente {{name}} creado de manera exitosa',
    createCondition: 'Crear condición comercial para: {{name}}',
    createCustomer: 'Crear cliente',
    createExecutive: 'Crear ejecutivo comercial',
    createInvoiceSuccess: 'La factura Nro. {{invoice_number}} se ha registrado exitosamente',
    createNewItem: 'Crear nueva industria "{{inputValue}}"',
    createNewExecutive: 'Crear nuevo ejecutivo "{{inputValueExecutive}}"',
    createNewManaged: 'Crear nuevo administrador',
    createProvider: 'Crear proveedor',
    createProviderSuccess: 'Proveedor {{name}} creado de manera exitosa',
    createProfile: 'Crear perfil',
    createProfileSuccess: 'Perfil {{name}} creado de manera exitosa',
    createRol: 'Crear rol',
    createRolPlaceholder: 'Comercial, finanzas, etc.',
    createRolSuccess: 'Rol {{rolName}} creado de manera exitosa',
    createUser: 'Crear usuario',
    createUserSuccess: 'Usuario {{name}} creado de manera exitosa',
    createFlowApproval: 'Crear flujo de aprobación de factura',
    createFlowApprovalSuccess: 'Flujo de Aprobación de factura, creado de manera exitosa',
    creditCardSubstitution: 'Sustitución de tarjeta de crédito',
    creditPeriod: 'Período del crédito',
    cuttingCycle: 'Ciclo de corte',
    cuttingCycleAmex: 'Ciclo de corte tarjeta tesorero AMEX',
    cuttingCycleCard: 'Ciclo de corte de la tarjeta ingresada es: {{message}}',
    customer: 'Cliente',
    customerInformation: 'Información del cliente seleccionado',
    customerName: 'Nombre del cliente',
    customerPayment: 'Pago del cliente',
    customerPaymentPercentage: '% Pago cliente',
    customers: 'Clientes',
    collectionDate: 'Fecha cobro',
    changeCode: 'Debe modificar el código de comercio que corresponda al nuevo tipo de moneda seleccionada',
    changeLog: 'Registro de cambio',
    changePassword: 'Cambiar contraseña',
    changePasswordSuccess: 'Contraseña cambiada de manera exitosa',
    city: 'Ciudad',
    cycleGroup: 'Grupo de ciclo',
    cyclesList: 'Lista de Ciclos de Facturación',
    ccChargeDate: 'CC charge date',
    daily: 'Diario',
    dashboard: 'Resumen',
    dataSavedSuccessfully: 'Datos guardados con exito',
    date: 'Fecha',
    dateOfAdmission: 'Fecha de ingreso en SII',
    dateOfEntry: 'Fecha de ingreso a B2B',
    dateInvoice: 'Fecha factura',
    dateFf: 'Fecha FF',
    FF: 'FF',
    FV: 'FV',
    dateV: 'Fecha de vencimiento',
    dataConfirmedSuccessfully: 'Datos confirmados exitosamente',
    dateNotAvailable: 'Fecha no disponible',
    dateRange: 'Rango de fechas',
    day: 'Día',
    daysNumber: 'Número de días',
    daysSince: ' días desde la ',
    defer: 'Diferido',
    defineRejectReason: "Defina motivo del rechazo",
    decline: 'Declinar',
    deferCommercialConditions: 'Condiciones comerciales de diferimiento',
    deferredAmount: 'Monto Diferido',
    delete: 'Eliminar',
    deleteInvoice: 'La factura fue eliminada',
    deletePaymentCondition: 'La condición de pago fue eliminada',
    deleteTta: 'Tarjeta AMEX eliminada con éxito',
    demographicInformation: 'Datos de identificación',
    deposit: 'Depositar',
    description: 'Descripción',
    invoiceDescription: 'Descripción de la factura',
    commerceCodeDescription: 'Descripción de código de comercio',
    destinationAccount: 'Cuenta destino',
    deactivate: 'Desactivar',
    detail: 'Detalle',
    digitalCertificate: 'Certificado digital',
    differ: 'Diferir',
    discountRate: 'Tasa de descuento',
    discountRateMDR: 'Tasa dcto. (MDR)',
    discountRateCharges: 'Cargos por tasa de descuento',
    discountRateInvoiceSavedSuccessfully: 'Factura por tasa de descuento guardada con éxito',
    discountRateOutOfLimits: 'Tasa de descuento fuera de los limites',
    dispositionReport: 'Reporte de disposiciones',
    doNotIncludeTheUsername: '* No incluir como parte de la contraseña el nombre del usuario.',
    documentNumber: 'RFC',
    documentType: 'Tipo de documento',
    document: 'Documento',
    documentDate: 'Fecha del documento',
    dontPose: 'No Posee',
    eCommerceTerminal: 'Terminal E-commerce',
    edit: 'Editar',
    editBank: 'Editar manual: {{name}}',
    editCycle: 'Editar ciclo',
    editionRequestSent: 'Solicitud de edicion enviada',
    editRolSuccess: 'Rol {{rolName}} editado de manera exitosa',
    email: 'Correo electrónico',
    incorrectEmail: '{{email}} no parece un correo válido. Revisa que tenga el formato correcto: ejemplo@mail.com',
    emptyFields: 'Debes completar todos los campos.',
    emptyAll: 'Asegúrate de llenar toda la información',
    emptyAllRequest: 'Asegúrate de llenar toda la información, de los Campos obligatorios',
    enter: 'Ingresar',
    enterCuttingCycle: 'Ingresar ciclo de corte',
    enterDataInternalTaxService: 'Conecte su empresa al SII - Servicio de Impuestos Internos',
    enterCodeSms: 'Verifica e ingresa el código\n que te enviamos vía SMS',
    enterTaxCode: 'Ingresar Clave Tributaria',
    errorDate: 'La fecha \'Desde\' tiene que ser menor',
    errorDateCycles: 'La fecha FF tiene que ser menor',
    errorData: 'Error al cargar los datos.',
    errorLoad: 'Debe cargar un archivo de extensión {{type}}',
    executiveData: 'Datos del ejecutivo comercial',
    expirationDate: 'Fecha de expiración',
    expiredInvoices: 'Aprobadas / Pagadas',
    extract: 'Extraer',
    extractAccount: 'Extracción en red B2B',
    extractDetail1: 'Codigo de aprobación generado exitosamente',
    extractDetail2: 'Monto a retirar',
    extractDetail3: 'En',
    extractDetail4: 'Completa la extracción en un punto de nuestra Red',
    extractDetail5: 'Con tu número de telefono',
    extractDetail6: 'Proporciona tu numero de telefono al operador del punto para completar la extraccion iniciada',
    extractDetail7: 'Codigo QR',
    extractDetail8: 'Preséntate con el siguiente codigo en el punto de la red',
    financingProducts: 'Productos de \nfinanciamiento',
    finish: 'Finalizar',
    firstAndSecondName: 'Primer y segundo nombre',
    fiscaldocument: 'Documento fiscal',
    flexibleConditions: 'Condiciones Flexibles (Financiamiento)',
    flowApproval: 'Flujo de aprobación',
    flowsApproval: 'Flujos de aprobación',
    flowApprovalCheck: 'Flujo de aprobación por defecto',
    forgotPassword: '¿Olvidó su contraseña?',
    friday: 'Viernes',
    fullName: 'Nombre y apellido',
    goBack: 'Regresar',
    greaterThan: 'Mayor',
    lessThan: 'Menor',
    lessThanOrEqualTo: 'Menor Igual',
    general_admin: 'Usuario administrador general del banco',
    general_admin_issuing: 'Usuario administrador general del banco Emisor',
    general_admin_acquiring: 'Usuario administrador general del banco Adquiriente',
    general_admin_issuing_amex: 'Usuario administrador general del banco Emisor AMEX',
    group: 'Grupo ',
    greaterThanOrEqualTo: 'Mayor Igual',
    fileSize: 'El tamaño máximo permitido, es 3MB',
    fileSizeExceeded: 'Tu archivo supera el peso máximo de 3MB',
    handbook: 'Manual',
    inCharge: 'Responsable',
    import: 'Importe',
    inactive: 'Inactivo',
    inactiveBusinesses: 'Comercios inactivos',
    inactiveClients: 'Clientes inactivos',
    informationOfTheSelectedBank: 'Información del banco seleccionado',
    installmentNumber: 'Número de cuotas',
    installments: 'Nro. de cuotas',
    intNumber: 'Debe ser un número entero',
    interestRate: 'Tasa de interés',
    interestOrdinary: 'Interés ordinario',
    interestLatePayment: 'Interés Moratorio',
    internalRevenueService: 'Servicio de Impuestos Internos',
    invalidAmount: 'Monto invalido',
    invalidRoleOrRoute: 'El rol y la ruta no pueden estar vacios',
    invoiceCount: 'Número de Facturas',
    invoiceDate: 'Fecha de factura',
    invoiceDetail: 'Detalle de Factura',
    invoiceIssueDate: 'Fecha de emisión factura',
    invoiceN: 'Factura Nro. {{invoiceNumber}}',
    invoiceManualResolution: 'Facturas que requieren resolución manual',
    invoiceMarkPaid: 'Factura Nro. {{invoiceNumber}} fue marcada como pagada',
    incorrectDate: 'No puedes ingresar una fecha anterior a, {{dateNow}}',
    incorrectDays: 'Debes ingresar un valor mayor o igual a 0',
    invoice: 'Factura',
    invoices: 'Facturas',
    invoicesNumber: 'Número de factura',
    invoiceStatus: 'Estado de la factura en el SII es: {{status}}',
    invoiceNumber: 'Nro. factura',
    invoicesCollectiontDate: 'Fecha de cobro facturas',
    invoicesPaymentDate: 'Fecha de pago facturas',
    invoicePending: 'Por emitir',
    invoiceReject: 'Rechazar factura',
    invoiceTotalAmount: 'Monto en Facturas',
    invoiceUploaded: 'Factura Nro. {{invoiceNumber}} cargada en B2B Enterprise exitosamente',
    issuedInvoices: 'Facturas emitidas',
    issuing_bank: 'Banco Emisor',
    items: 'Industrias',
    keo: 'AMEX',
    kindOfProduct: 'Tipo de producto',
    legal: 'Jurídica',
    listCuttingCycle: 'Lista de ciclos de corte',
    listManuals: 'Listar Manuales',
    lastDays: 'Últimos días',
    lastName: "Primer apellido",
    last4Digits: 'Últimos 4 dígitos Nro. Tarjeta',
    lastMovements: 'Últimos Movimientos',
    lengthToCharacters: '* Longitud 8 a 12 caracteres que contengan todas las siguientes características: letras mayúsculas (A-Z), letras minúsculas (a-z), números (0-9) o caracteres especiales.',
    listInvoices: 'En revisión',
    listPayments: 'Listado de pagos',
    load: 'Cargar',
    loadManuals: 'Cargar Manuales',
    loadBillingCycle: 'Cargar Ciclos de Facturación',
    loading: 'Cargando...',
    loadingContent: 'Cargando contenido ...',
    loadInvoice: 'Cargar factura',
    loan: 'Disposición',
    loanDate: 'Fecha de disposición',
    loanPeriod: 'Período del préstamo',
    logs: 'Logs',
    logout: 'Salir',
    logView: 'Vista log',
    logTransaction: 'Log transacciones',
    operationLog: 'Log de Eventos',
    mandatoryFieldsRequired: '(*) Debe rellenar todos los campos marcados con un asterisco',
    mainUser: 'Usuario Principal',
    mainInvoiceNumber: 'Número de factura principal',
    markAsPaid: 'Marcar como pagado',
    manualsAndFaq: 'Manuales y FAQ\'s',
    manualLoaded: 'Manual cargado exitosamente',
    manualResolution: 'Resolución Manual',
    mdr: 'MDR',
    monday: 'Lunes',
    minimumCharacter: 'Número de Afilación de Comercio, debe tener 10 caracteres',
    message: 'Mensaje',
    messageUserProcess: 'En este momento no posee un tipo de flujo de aprobación configurado. Por favor comuníquese con su representante para activar esta funcionalidad.',
    merchantAffiliateCode: 'Número de afiliado',
    month: 'Mes',
    monthly: 'Mensual',
    name: 'Nombre',
    newBank: 'Nuevo banco',
    nameFantasy: 'Nombre fantasía',
    newPassword: 'Nueva contraseña',
    newPaymentCondition: 'Nuevas condiciones de pago',
    newRecharge: 'Nueva recarga',
    newRole: 'Nuevo rol',
    newProfile: 'Nuevo usuario',
    newProfileBTN: 'Nuevo perfil',
    newTransfer: 'Nueva transferencia',
    next: 'Siguiente',
    nextCollection: 'Próximo cobro',
    nextLoanExpiration: 'Próximos vencimientos de línea',
    nextSellerPayments: 'Próximos pagos a proveedores',
    nextDays: 'Próximos días',
    nextPage: 'Siguiente',
    nextPayment: 'Próximo pago',
    no: 'No',
    noCycles: 'No hay ciclos de corte cargados para: {{month}}',
    notVerified: 'No verificada',
    noCard: 'No posee tarjeta prepagada',
    noData: 'Sin información',
    noInformation: 'Sin Información',
    noItemsAvailable: 'No hay industrias disponibles',
    noExecutivesAvailable: 'No hay ejecutivo comercial',
    nonMatchingPasswords: 'Las contraseñas ingresadas no coinciden',
    noRowsFound: 'Sin información',
    notifyCardPaymentAuthorization: 'Notificar autorización de pago con tarjeta',
    noTransactions: 'Esta tarjeta no posee movimientos',
    notYetFound: 'La factura aún no se encuentra disponible en el SII, si lo desea puede cargarla y B2B Enterprise se encargará de reintentar la búsqueda de la misma y cargarla en nuestra plataforma, de lo contrario cancele la operación e intente cargarla nuevamente más tarde',
    numberInstallments: 'Número de cuotas',
    numberTta: 'Número de TTA',
    of: 'de',
    operationVolume: 'Vol. operaciones',
    operator: 'Condición',
    originAccount: 'Cuenta origen',
    params: 'Parametros',
    page: 'Páginas',
    paid: 'Pagadas',
    paidAmount: 'Monto pagado',
    paidInvoices: 'Facturas pagadas',
    paidOut: 'Pagado',
    password: 'Contraseña',
    passwordDigitalCertificate: 'Contraseña del certificado digital',
    passwordHasExpired: 'Su contraseña ha expirado, por favor ingrese su nueva contraseña',
    payedAmounts: 'Total diario facturado',
    payment: 'Pago',
    paymentCurrency: 'Moneda de pago',
    paymentDate: 'Fecha de cobro',
    paymentDateInvoice: 'Fecha de cobro de facturas:',
    datePayment: 'Fecha de pago',
    paymentDeadline: 'Plazo de pago',
    paymentCapital: 'Pago a capital',
    paymentInterest: 'Pago a intereses',
    paymentReceived: 'Pago recibido',
    paymentOrders: 'Ordenes de Pago',
    paymentTax: 'Pago a IVA',
    paymentsTransfers: 'Pagos y Transferencias',
    platformAdministrator: 'Administrador plataforma',
    platformManuals: 'Manuales de la plataforma',
    person: 'Persona',
    pendingInvoices: 'Fac. pendientes',
    pending: 'Pendiente',
    pendingToApprove: 'Pendiente por aprobar',
    period: 'Periodo',
    physical: 'Física',
    prepaidCard: 'Tarjeta prepagada',
    present: 'Actual',
    previous: 'Anterior',
    previous_page: 'Anterior',
    printQR: 'Imprimir QR',
    product: 'Producto',
    productUnsubscribedSuccessfully: 'Producto dado de baja exitosamente',
    productContracted: 'Solicitud de contrato del producto: {{name}}, enviada exitosamente',
    productName: 'Nombre del Producto',
    provider: 'Proveedor',
    profile: 'Perfil',
    profiles: 'Perfiles',
    profileName: 'Nombre del perfil',
    profileList: 'Lista de usuarios',
    profilesList: 'Lista de perfiles',
    providerName: 'Nombre del proveedor',
    providers: 'Proveedores',
    providerPaymentCondition: 'Determina las condiciones de pago para este proveedor',
    qualify: 'Habilitar',
    QuoteAndRequest: 'Cotizar y Solicitar',
    rate: 'Porcentaje',
    receivedInvoices: 'Facturas \nRecibidas',
    receivedRequest: 'Solicitud recibida',
    receptionDate: 'Fecha de emisión de factura',
    fromReceptionDate: 'Fecha de recepción',
    recharge: 'Recargar',
    recipient: 'Destinatario',
    recoverPassword: 'Recuperar contraseña',
    rejectReason: 'Motivo de rechazo',
    replaceTheSelectedCard: 'Está seguro de sustituir la tarjeta seleccionada.',
    requestUnsubscribe: 'Solicitar baja',
    requestSentProduct: 'Solicitud enviada',
    requiredFields: '(*) Campos obligatorios',
    insertYourEmailAddress: 'Introduce tu dirección de correo electrónico.',
    enterYourNewPassword: 'Introduzca su nueva contraseña',
    registrationStatus: 'Estado de registro',
    registerBank: 'Registrar banco',
    registeredCards: 'Tarjetas registradas del cliente: {{name}}',
    registerRole: 'Registrar rol',
    registerRoute: 'Registrar ruta',
    reject: 'Rechazar',
    rejectInvoice: 'Rechazadas',
    rejectInvoiceAll: 'Facturas rechazadas',
    rejectedInvoices: 'Fac. rechazadas',
    rejectedRequests: 'Solicitudes rechazadas ({{amount}})',
    rejectWithEdition: 'Rechazar con solicitud de edición',
    relatedUsers: 'Usuarios Relacionados',
    rememberToAcceptTheTerms: 'Recuerde aceptar los términos y condiciones para contratar el producto',
    removeRouteSuccess: 'La ruta fue removida exitosamente',
    request: 'Solicitudes',
    requestEdition: 'Solicitar edición',
    requestSent: 'Enviadas',
    requestReceived: 'Recibidas',
    requiredInstallments: 'El numero de cuotas es invalido',
    requiredName: 'El nombre es invalido',
    requiredPeriod: 'El periodo es invalido',
    requiredRate: 'La tasa es invalida',
    requiredTypeFinance: 'El tipo de financiamiento es invalido',
    respJson: 'Respuesta JSON',
    respXml: 'Respuesta XML',
    response: 'Respuesta',
    return: 'Regresar',
    roleCantBeEmpty: 'El rol no puede estar vacio',
    roleList: 'Lista de roles',
    roles: 'Roles',
    rol: 'Rol',
    roleType: 'Tipo del rol',
    rolName: 'Nombre del rol ',
    rolType: 'Tipo del rol',
    routeList: 'Lista de rutas',
    routes: 'Rutas',
    rows: 'Filas',
    safetyRules: 'Necesitamos conectar su empresa al SII para poder obtener las facturas emitidas por sus proveedores a su rázon social, para ello le agradecemos ingresar los siguientes datos:',
    save: 'Guardar',
    saturday: 'Sábado',
    sunday: 'Domingo',
    seeDetail: 'Ver detalle',
    seeConditions: 'Ver condiciones',
    seeCustomers: 'Ver clientes',
    seeUsers: 'Ver usuarios',
    Select: 'Selecciona ...',
    selectANewCommercialExecutive: 'Debe seleccionar un nuevo ejecutivo comercial',
    selectCard: 'TTA Seleccionada',
    SelectDocumentType: 'Elige el tipo de documento para continuar',
    SelectBank: 'Selecciona un banco',
    selectCycle: 'Debe seleccionar todos los ciclos',
    selectMonth: 'Debe seleccionar un mes',
    SelectOption: 'Selecciona una opción',
    selectOrTypeItems: 'Selecciona o escriba las industrias...',
    selectOrTypeRoutes: 'Selecciona o escriba la ruta',
    selectOrTypePermissions: 'Selecciona o escriba el permiso',
    selectRecipient: 'Seleccionar destinatario',
    selectOrTypeRol: 'Selecciona o escriba los roles',
    selectProfile: 'Seleccionar Perfil',
    send: 'Enviar',
    sendDate: 'Fecha envío',
    sendInvoice: 'Fecha de envío de factura',
    sentInvoices: 'Facturas \nenviadas',
    shouldNotSelect: 'No debe seleccionar el mismo Ejecutivo Comercial, que quiere eliminar',
    requestsSent: 'Solicitudes enviadas',
    requestsReceived: 'Solicitudes recibidas',
    requestsReceivedAmount: 'Solicitudes recibidas ({{amount}})',
    settings: 'Ajustes',
    seeHow: 'Ver como',
    shippingDate: 'Fecha de envío',
    showQR: 'Mostrar código QR',
    since: 'Desde',
    startDate: 'Fecha de inicio',
    endingDate: 'Fecha de fin',
    source_log: 'Origen de petición',
    startCountingFrom: 'Días pactados desde',
    summary: 'Resumen',
    successExecutive: 'Ejecutivo comercial creado exitosamente',
    successCard: 'Su tarjeta ya fue registrada.',
    successCycles: 'Ciclos de facturación creado exitosamente.',
    successCycle: 'Ciclo de facturación confirmado exitosamente.',
    successEmail: 'Se ha enviado un correo a tu dirección de correo electrónico.',
    successfullyUpdatedManual: 'Manual actualizado con exito',
    transactionsToProcess: 'Transacciones por procesar',
    targetNumber: 'Numero de tarjeta',
    taxOrdinary: 'IVA ordinario',
    taxLatePayment: 'IVA moratorio',
    TtaValidation: 'Validación de TTA',
    termsAndConditions: 'Términos y condiciones',
    toDisable: 'Deshabilitar',
    total: 'Total',
    totalAmount: 'Importe total',
    totalAmountToPayOnTheInvoice: 'Monto total a pagar en la factura',
    toBeApproved: 'Por Aprobar',
    totalBuyers: 'Total Empresas Pagadoras',
    totalBuyersDeferred: 'Empresas con Facturas Diferidas',
    totalBuyersInvoiced: 'Empresas con Facturas Aprobadas',
    totalCharged: 'Total cobrado {{month}}',
    totalDebtAmount: 'Monto total a pagar',
    totalOwedAmount: 'Monto total a cobrar',
    totalPay: 'Total a pagar',
    totalReject: 'Rechazo total',
    totalToPayCardholder: 'Total a Pagar Tarjetahabiente Cargo por Servicio',
    totalToPayInvoice: 'Total a pagar factura',
    totalVolumePendingPayment: 'Total de volumen pendiente por pagar',
    totalSellers: 'Proveedores Afiliados',
    totalSellersCharged: 'Proveedores con Facturación Aprobada',
    totalSellersInvoiced: 'Proveedores con Facturación Pendiente',
    totalToApprove: 'Total por aprobar',
    totalToReceiver: 'Total por cobrar',
    transactionType: 'Tipo de transacción',
    transfer: 'Transferir',
    transferCharge: 'Cargo de la operación',
    transferDetail2: 'Comprobante',
    transferDetail3: 'Enviado a',
    transferDetail4: 'Monto enviado',
    transferDetail5: 'Monto total descontado',
    transferDetail: 'Transferencia Realizada',
    transferState: 'Estado',
    transferType: 'Tipo de transacción',
    tuesday: 'Martes',
    theCodeExpiresIn: 'El código vence a las: {{time}}',
    theInvoiceWillBeCharged: 'La factura se cargará en B2B Enterprise en estado, {{status}}',
    thursday: 'Jueves',
    theyMustNotHave: '* No deberán tener caracteres idénticos consecutivos, ni totalmente numéricos, ni totalmente alfabéticos.',
    type: 'Tipo',
    typeFinance: 'Tipo de financiamiento',
    typeOfApprovalFlow: 'Tipo de flujo de aprobación',
    typeOfEmitFlow: 'Tipo de flujo de emisión',
    typeAndDocumentNumber: 'Tipo y Nro. de documento',
    underReview: 'En Revisión',
    underReviewInvoices: 'Facturas en revisión',
    user: 'Usuario',
    userManual: 'Manual de usuario',
    username: 'Nombre de usuario',
    usernamePlaceholder: 'Ej: usuario123',
    users: 'usuarios',
    userQuery: 'Usuario consulta',
    userBillingCycles: 'Usuario ciclos de facturación',
    Unsubscribe: 'Dar de baja',
    until: 'Hasta',
    updatedBillingCycle: 'Ciclo de facturación actualizado',
    updatedBillingCycles: 'Ciclos de facturación actualizado',
    updateInformation: 'Actualizar información',
    virtualAccount: 'Cuenta Virtual',
    yearly: 'Anual',
    resource: 'Recurso',
    view: 'Ver',
    daysAdvance: 'Dias a adelantar',
    daysDefer: 'Dias a diferir',
    anualRate: 'Tasa anual',
    percentage: 'Porcentaje de interes anual',
    interest_rate: 'Monto de interés',
    adjusted_amount: 'Monto ajustado',
    cantDeleteRol: 'El rol no se puede eliminar porque tiene asignado una o mas rutas',
    deleteRol: 'El rol fue elminado',
    deleteRoute: 'La ruta fue eliminada',
    deleteExecutive: 'Ejecutivo comercial eliminado',
    cantDeleteRoute: 'La ruta no se puede eliminar porque esta asignada a uno o mas roles',
    createLead: 'Requerir \n alta',
    sellerCreateLead: 'Requerir alta de proveedor',
    buyerCreateLead: 'Requerir alta de cliente',
    requireSuccess: '{{name}} requerido con exito',
    buyerName: 'Nombre del cliente',
    sellerName: 'Nombre del proveedor',
    leadList: 'Listado de \nrequeridos',
    createRoleSuccess: 'Role {{rolName}} creado exitosamente',
    // Despues de enviar a traduccion a portugues
    updateBank: 'Actualizar banco',
    adminName: 'Nombre',
    adminLastname: 'Apellido',
    adminPosition: 'Cargo',
    adminPositionPlaceholder: 'Gerente, ejecutivo, etc.',
    invoicesApproved: 'Facturas aprobadas',
    invoicesToApproved: 'Facturas por aprobar',
    approvedMount: 'Monto aprobado',
    nInvoices: 'Nro. de facturas',
    advanceMount: 'Monto adelantado',
    deferMount: 'Monto diferido',
    totalCommissionAmount: 'Comisión total',
    discountAmount: 'Monto de descuento',
    reports: 'Reportes',
    createPaymentCondition: 'Crear condición \nde pago',
    addCondition: ' Agregar condición',
    receiverDiscountShare: 'Porcentaje de pago del proveedor',
    senderDiscountShare: 'Porcentaje de pago del cliente',
    paymentClient: 'El pago del cliente es de',
    createPaymentConditionSuccess: 'Condición de pago creada exitosamente',
    paymentCondition: 'Condición',
    paymentConditionProvider: 'Condiciones comerciales para proveedores de',
    paymentConditionClients: 'Condiciones comerciales para clientes de',
    permanentlyRejectCommercialStatus: 'Rechazar condición comercial permanentemente',
    editPaymentConditionSuccess: 'Condición de pago Editada exitosamente.',
    editClientSuccess: 'Cliente {{name}} editado exitosamente',
    editProviderSuccess: 'Proveedor {{name}} editado exitosamente',
    editProfileSuccess: 'Perfil {{name}} editado exitosamente',
    editUserSuccess: 'Usuario {{name}} editado exitosamente',
    client: 'Cliente',
    download: 'Descargar',
    downloadPDF: 'Descargar PDF',
    downloadXML: 'Descargar XML',
    en: 'English',
    es: 'Español',
    pt: 'Português',
    language: 'Lenguaje',
    lead: 'Alta',
    completeComercialConditionOrEmptyAll: 'Complete los datos de las condiciones comerciales o dejelos vacios si no desea agregar condicion comercial con algun proveedor',
    tradeInformation: 'Información de comercio',
    transactions: 'Transacciones',
    totalReceiver: 'Total a cobrar',
    totalInvoice: 'Total factura',
    totalInvoices: 'Total facturas',
    quantity: 'Cantidad',
    providersReceiver: 'Factura de proveedores por cobrar',
    clientsPay: 'Clientes por pagar',
    clients: 'Clientes',
    selectedBank: 'Banco',
    uploadInvoice: 'Cargar factura',
    loadInvoiceData: 'Cargar datos de factura',
    naoReter: 'No retener IR (propaganda, publicidad, comisiones y corretaje)',
    disable: 'Deshabilitar',
    status: 'Estado',
    statusOfInvoice: 'El estado de la factura Nro. {{invoiceNumber}} en el SII es: {{status}}.',
    cannotBeUploaded: 'Por lo tanto la factura no se puede cargar en B2B Enterprise',
    invoiceApprovedSuccess: 'Factura aprobada exitosamente',
    invoiceDeclinedSuccess: 'Factura declinada exitosamente',
    invoiceRejectedSuccess: 'Factura rechazada exitosamente',
    invoiceProvidersReceiver: 'Factura de proveedores por cobrar',
    invoiceStatusInTheSii: 'Estado de la factura en el SII',
    actionNotAllowed: 'Acción no permitida',
    clientPay: 'Pagos de los clientes',
    referrer: 'Referido',
    buyer: 'Cliente',
    seller: 'Proveedor',
    limitAmount: 'Monto límite',
    limitAmountValidate: 'Debes ingresar un valor mayor o igual a 0',
    limit: 'Límite',
    new: 'Nueva',
    paymentMethod: 'Método de pago',
    target: 'Tarjeta',
    line: 'Linea',
    defaultFlow: 'Flujo por defecto',
    condition_amount: 'Monto',
    invoiceNumberValidate: 'El formato válido para el ingreso del número de factura es xxx-xxx-xxxxxxxxx',
    amountValidate: 'El valor debe ser mayor o igual a 0.1',
    cantCreateTransaction: 'Ya existe el número de factura',
    penddingInvoice: 'Tiene {{pending_invoices}} factura pendiente sin aprobar',
    penddingInvoices: 'Tienes {{pending_invoices}} facturas pendientes sin aprobar',
    registerAdministrator: 'Registrar administrador',
    registerCard: 'Registrar Tarjeta',
    registerCardAmex: 'Ingrese Tarjeta American Express',
    listCards: 'Lista de tarjetas',
    cards: 'Tarjetas',
    savedCards: 'Tarjetas de crédito guardadas',
    secureCards: 'La información de tus tarjetas de crédito serán almacenadas bajo estrictas normas de seguridad',
    rejectInvoices: 'Tienes {{reject_invoices}} factura(s) rechazadas',
    rejectedCycle: 'Ciclo de facturación rechazado exitosamente.',
    emitInvoices: 'Tiene(s) {{emit_invoices}} factura(s) sin emitir',
    search: 'Buscar',
    operationRank: 'Rango de operaciones (desde)',
    maxRank: '(hasta)',
    support: 'Asistencia',
    main_bank: 'Usuario Administrador del Banco',
    user_list: 'Lista de Usuarios',
    edit_user: 'Editar Usuario',
    acquiring_query: 'Banco Adquirente de Consulta',
    acquiringQuery: 'Adquirente de Consulta',
    issuing_query: 'Banco Emisor de Consulta',
    enable: 'Habilitar',
    transactionReport: 'Reporte de transacciones',
    paymentDateRange: 'Rango de fecha de cobro de facturas',
    paymentDateRangeClient: 'Rango de fecha de pago de facturas',
    identificationNumber: 'No. de Identificación',
    time: 'Hora',
    event: 'Evento',
    info: 'Info',
    infoLog: 'Información del evento',
    editFlowApprovalSuccess: 'Flujo de aprobación editado con exito',
    emissionFlow: 'Flujo de emisión',
    affiliationCode: 'Código de afiliación de comercio',
    validCard: 'Verificada',
    viewTta: 'Ver TTA',
    waitingForApproval: 'Esperando aprobación de solicitud de baja del servicio',
    invalidCard: 'Por verificar',
    RUT: 'RUT',
    conditions: 'Condiciones',
    phone: 'Teléfono',
    contactPhone: 'Teléfono de contacto',
    yes: 'Sí',
    yourDataWillBeStoredEncrypted: '*Sus datos serán almacenados encriptados bajo estrictas normas de seguridad.',
    validity: 'Vigencia',
    verificationCode: 'Código de verificación',
    verificationCodeSent: 'Ingrese el código  de verificación enviado a su correo',
    waitingBillingCycle: 'Esperando asignación de ciclo de facturación',
    wednesday: 'Miércoles',
    billingCycle: 'Ciclo de facturación',
    billingCycles: 'Ciclos de facturación',
    cardList: 'Lista de tarjetas',
    permissions: 'Permisos',
    region: 'Región',
    commune: 'Comuna',
    grant: 'Conceder',
    deny: 'Denegar',
    webpay: 'Webpay',
    getnet: 'Getnet',
    gateway: 'Gateway',
    main_bank_issuing: 'Usuario Administrador del Banco Emisor',
    main_bank_acquiring: 'Usuario Administrador del Banco Adquiriente',
    currency: 'Moneda',
    bankingExecutives: 'Ejecutivo comercial',
    currentCycle: 'Ciclo Vigente',
    disabled: 'Deshabilitado',
    enabled: 'Habilitado',
    assignedUsers: 'Usuarios Asignados',
    assignedClients: 'Clientes asignados',
    assignedProviders: 'Proveedores Asignados',
    newBankingExecutive: 'Nuevo ejecutivo comercial',
    rate_operator: 'Operador de tarifas financieras',
    cycle_loader: 'Operador de Ciclos de Facturación',
    requestProducts: 'Solicite su seguro o asistencia',
    contractedProducts: 'Seguros contratados',
    products: 'Seguros / Asistencias',
    cost: 'Costo',
    contract: 'Contratar',
    registerProduct: 'Registrar Producto',
    registerProfile: 'Registrar perfil',
    insurances: 'Seguros',
    contractRequest: 'Solicitud de contrato',
    unsuscribeRequest: 'Solicitud de baja',
    productType: 'Tipo de producto',
    emailLabel: 'nombre@empresa.cl',
    fantasyName: 'Nombre de fantasía',
    b2bMDR: 'Tasa B2B Enterprise (MDR)',
    providerB2BRate: 'Tasa B2B que paga el proveedor',
    clientB2BRate: 'Tasa B2B a cargo del cliente es',
    verifyHuman: 'Por favor verifica que eres humano',
    selectInvoice: 'Selecciona la factura',
    selectPDF: 'Selecciona archivo PDF',
    selectXML: 'Selecciona archivo XML',
    paymentAmount: 'Monto a pagar',
    valorNeto: 'Valor neto',
    vendorMerchantAffiliateCodes: 'Número de afiliado del proveedor: {{provider}}',
    volumeProcessed: 'Volumen procesado en los últimos tres meses',
    allowedFormatsXLS: 'Formatos permitidos: XLSX o XLS. Tamaño máximo 3MB.',
    loadFromFile: 'Cargar desde archivo',
    selectFile: 'Seleccionar archivo',
    invoiceTerms: 'Al aprobar facturas dentro de la plataforma B2B Enterprise, acepto el cargo que se realizará en mi Tarjeta Tesorero American Express, por el monto que se individualiza en cada factura, y del monto del cargo por servicio, si correspondiese de acuerdo a las condiciones comerciales pactadas con su proveedor. Instruye irrevocablemente a AMEX, para que tales cargos se efectúen en la referida Tarjeta.',
    main_bank_issuing_amex: 'Usuario Administrador del Banco Emisor AMEX',
    discountRateAccept: 'Condiciones de cargos por servicio a AMEX',
    discountRateTerms: 'Al aceptar el cargo a mi Tarjeta de Crédito AMEX Tesorero American Express, el cliente se compromete a pagar la factura más los cargos por Servicio a en todas las facturas en las que el cliente le pague al proveedor indicado.',
    clientPercentageCharge: 'Cliente – Porcentaje de cargo por servicio',
    providerPercentageCharge: 'Proveedor – Porcentaje de cargo por servicio',
    adminBank: 'Banco Administrador',
    bankInformation: 'Información del Banco seleccionado',
    selectBank: 'Debe seleccionar un Banco',
    saveFaq: 'Manuales y FAQ',
    question: 'Pregunta',
    answer: 'Respuesta',
    supportLink: 'Link de soporte',
    createQuestionSuccess: 'Pregunta creada de manera exitosa',
    saveQuestion: 'Cargar FAQ\'s',
    savePlatformFaq: 'Cargar FAQ\'s de la Plataforma',
    updatePlatformFaq: 'Editar FAQ\'s de la Plataforma',
    listFaqs: 'Listar FAQ\'s',
    platformFaqList: 'Listar FAQ\'s de la Plataforma',
    seeQuestion: 'Ver pregunta',
    b2bInformation: 'Información sobre B2B Enterprise',
    monthlyReport: 'Reporte mensual',
    clientRUT: 'RUT Cliente',
    providerRUT: 'RUT Proveedor',
    buyerCode: 'Código del cliente',
    sellerCode: 'Código del proveedor',
    enable2FA: 'Activar verificación en dos pasos',
    disable2FA: 'Desactivar verificación en dos pasos',
    send2FASuccess: 'Código enviado correctamente',
    FMA: 'Verificación en dos pasos',
    email2FA: 'Se ha enviado un código de verificación a su correo electrónico, por favor ingréselo para continuar la operación',
    district: 'Distrito',
    canton: 'Capital',
    province: 'Estado',
    january: 'Enero',
    february: 'Febrero',
    march: 'Marzo',
    april: 'Abril',
    may: 'Mayo',
    june: 'Junio',
    july: 'Julio',
    august: 'Agosto',
    september: 'Septiembre',
    october: 'Octubre',
    november: 'Noviembre',
    december: 'Diciembre',
    enterTheCardBillingCycle: 'Es necesario que ingrese el ciclo de facturación de la tarjeta para continuar operando con está tarjeta',
    successfullyAssignedCuttingCycle: 'Ciclo de corte asignado exitosamente',
    categories: 'Categorías',
    validEmail: 'Debe ingresar correos que pertenezcan a @baccredomatic.com',
    providerGroup: 'Grupo de proveedores',
    customerGroup: 'Grupo de clientes',
    loadProvidersGruop: 'Cargar grupo de proveedores',
    loadProviders: 'Cargar empresas',
    loadCustomersGruop: 'Cargar grupo de clientes',
    loadCustomers: 'Cargar clientes',
    personType: 'Tipo de persona',
    mccCode: 'Código MCC',
    continueLoadingProviders: '¿Continuar con la carga de empresas?',
    continueLoadingCustomers: '¿Continuar con la carga de clientes?',
    cantProviders: 'empresas seleccionadas para cargar',
    cantCustomers: 'clientes seleccionados para cargar',
    failedProviders: 'empresas con errores',
    failedCustomers: 'clientes con errores',
    failedProvidersAlert: '(Empresas con errores no serán tomadas en cuenta para registrar)',
    failedCustomersAlert: '(Clientes con errores no serán tomados en cuenta para registrar)',
    errorProviders: 'Proveedores con errores',
    errorCustomers: 'Clientes con errores',
    errors: 'Errores',
    providersSaved: 'Proveedores cargados exitosamente',
    loadFile: 'Cargar archivo',
    customersSaved: 'Clientes cargados exitosamente',
    suggestTradeConditionCorrection: 'Sugerir correción de condición comercial',
    rejectTradeRequest: 'Rechazar vínculo comercial',
    esp: 'ESP',
    por: 'POR',
    eng: 'ENG',
    notifyPaymentAuthorization: 'Notificar autorización de pago',
    cancelInvoiceRequest: 'Solicitar Cancelación de Factura',
    cancelInvoice: 'Cancelar factura',
    suggestInvoiceAdjustment: 'Sugerir ajuste de factura',
    editInvoiceRequest: 'Solicitud de edición de factura',
    restartDays: 'Reiniciar plazo de los días pactados',
    mantainDays: 'Mantener plazo de los días pactados (Continúa con los días pactados transcurridos)',
    update: 'Actualizada',
    issuing: 'Emisor',
    otherSign: 'Referencia',
    myLoans: 'Disposiciones',
    approveds: 'Aprobados',
    loanNumber: 'Número de disposición',
    totalBalance: 'Saldo total',
    minimumPayment: 'Pago minimo',
    toPay: 'Pagar',
    affiliationNumber: 'Número de afiliación',
    commerceName: 'Nombre del comercio',
    outdoorNumber: 'Número exterior',
    interiorNumber: 'Número interior',
    suburb: 'Colonia',
    municipality: 'Delegación o municipio',
    postalCode: 'Código postal',
    authorizedBy: 'Autorizado por',
    CIF: 'CIF',
    RFC: 'RFC {{type}}',
    haveNotifications: 'Tiene notificaciones',
    requestRejected: 'Enviadas con rechazo',
    receivingBank: 'Banco receptor',
    uniqueEmail: 'El correo electrónico no debe haber sido registrado antes en el sitio como ejecutivo comercial',
    invoicesSent: 'Facturas enviadas',
    rejectedBy: 'Rechazado por',
    failedPayment: 'Pago fallido',
    incidence: 'Incidencia',
    amountArranged: 'Monto dispuesto',
    pendingProvisions: 'Disposiciones pendientes',
    totalAmountDisposed: 'Monto total dispuesto',
    dispositionDate: 'Fecha de disposición',
    balancePerDay: 'Saldo al día',
    generateReferencePayment: 'Generar referencia para pago',
    generatedReference: 'Número de referencia, {{reference}}',
    accountClabe: 'Cuenta clabe',
    accountCie: 'Cuenta cie',
    assignedName: 'Nombre asignado',
    emailExample: 'ejemplo@correo.com',
    keoEdition: 'Edición estado AMEX',
    declinedInvoices: 'Facturas declinadas',
    declined: 'Declinadas',
    apiRequests: 'Peticiones a la API',
    logApiRequest: 'Peticiones a la API',
    origin: 'Origen',
    responseStatus: 'Status de respuesta',
    recentInvoices: 'Facturas recientes',
    replyMessage: 'Mensaje de respuesta',
    paymentInformation: 'Información de pago',
    invoiceAmount: 'Monto de la factura',
    processInvoices: 'Facturas en proceso de pago',
    paymentProcess: 'Proceso de pago',
    executionHistory: 'Historial de ejecución',
    unapprovedInvoices: 'Facturas pendientes sin aprobar ({{pending_invoices}})',
    processInvoicesCant: 'Facturas en proceso de pago ({{in_process_invoices}})',
    chargeInvoices: 'Tiene {{in_process_invoices}} factura(s) en proceso de cobro',
    gatewayInformation: 'Información del gateway',
    chargeAttempt: 'Intento de cobro',
    executionDate: 'Fecha de ejecución',
    timeLimit: 'Plazo',
    retries: 'Reintentos',
    retry: 'Reintento {{cant}}',
    tense: 'Tiempo',
    errorHour: 'No se puede ingresar más de 24 Horas',
    errorHour1: 'No se puede ingresar menos de 1 Hora',
    hour: 'Horas',
    hours: 'Horas',
    errorMinute: 'No se puede ingresar más de 60 Minutos',
    errorMinute1: 'No se puede ingresar menos de 3 Minutos',
    minute: 'Minutos',
    minutes: 'Minutos',
    cannotAddMore: 'No se puede agregar más de 10 reintentos',
    errorInTheRow: 'Error en la fila {{row}}, {{error}}',
    retriesLoaded: 'Reintentos cargados con exito',
    information: 'Información',
    invoiceDateAutomatically: 'El campo Fecha de Factura ahora es automático, considera el día en que se hace la solicitud',
    dateTheInvoice: 'La fecha ahora corresponde al día en que se carga la factura',
    responsiveMsg: 'Para aprovechar al máximo la plataforma ABL te recomendamos usar la versión de escritorio.',
    transactionDate: 'Fecha de transacción',
    TDCUserConfirm: 'Si continúa con la operación, el proveedor se creará con el tipo de pago TDC.',
    NCLUserConfirm: 'Si continúa con la operación, el proveedor se creará con el tipo de pago NCL, es decir, solo podrá recibir pagos mediante transferencias SPEI.',
    RFCValidation: 'RFC debe cumplir con el siguiente formato AAA0000000A0',
    wantToContinue: '¿Desea continuar?',
    createNCLCustomer: 'Crear cliente NCL',
    clientWithACG: 'El cliente se creará con el tipo de pago NCL, es decir, solo podrá realizar pagos mediante transferencias SPEI',
    createdNCL: 'Si continúa con la operación, el cliente se creará con el tipo de pago NCL, es decir, solo podrá realizar pagos mediante transferencias SPEI',
    wishToContinue: '¿Desea continuar?',
    countryCode: 'Código de país',
    areaCode: 'Código de área',
    paymentType: 'Tipo de pago',
    newCondition: 'Nueva condición',
    NCLCommission: 'Comisión NCL',
    commission: 'Comisión',
    sentCondition: 'Envió de solicitud entre el {{client}} y el {{supplier}} con {{number}} día/s pactados desde {{type}} y una comisión de {{commission}}',
    NCLComission: 'Comisión NCL',
    nclInvoiceNotification2: 'La factura posee el tipo de pago NCL, es decir, su pago es ejecutado mediante transferencia SPEI fuera de la plataforma de Business Link',
    nclInvoiceNotification: 'La factura será cargada con el tipo de pago NCL, es decir, su pago será ejecutado mediante transferencia SPEI fuera de la plataforma de Business Link',
    nclInvoiceApprovementNotification: 'El pago de la factura será realizado mediante transferencia SPEI fuera de la plataforma de Business Link',
    invoicesNcl: 'Pagos NCL',
    proofPayment: 'Comprobante de pago',
    process: 'Procesar',
    downloadPayment: 'Descargar comprobante de pago',
    nclPaymentSuccessfullyRejected: 'Pago NCL rechazado con éxito',
    nclPaymentProcessedSuccessfully: 'Pago NCL procesado con exito',
    AllowedFormats: 'Formatos permitidos: PDF y JPG. Tamaño máximo 3MB.',
    errorLoadFile: 'Debe cargar un archivo de extensión {{type}} o {{type1}}',
    paymentRegisteredSuccessfully: 'Pago registrado con éxito',
    downloadAttached: 'Descargar adjunto',
    NCLSupplierCommission: 'Comisión NCL proveedor',
    scheduledCollectionDate: 'Fecha programada de cobro',
    scheduledDateTheProvider: 'Fecha programada de pago',
    effectiveDatePayment: 'Fecha efectiva de pago',
    effectiveDateCollection: 'Fecha efectiva de cobro',
    switchProfile: 'Cambiar Perfil',
    usd: 'USD',
    mxn: 'MXN',
    mexicanPesos: 'Pesos mexicanos',
    americanDollar: 'Dólar estadounidense',
    relationshipInactive: 'No es posible aprobar la condición debido a que la relación se encuentra inactiva',
    relationshipStatus: 'Estado de relación',
    a: 'Activo',
    i: 'Inactivo',
    c: 'Cancelado',
    p: 'Pendiente',
    r: 'Rechazado',
    noLogRecord: 'Lo sentimos, no existe ningún registro en el log de la transacción',
    allInvoices: 'Todas las facturas',
    mode: 'Modalidad',
    withInvoice: 'Con Factura',
    withoutInvoice: 'Sin Factura',
    paymentInstructions: 'Instrucciones de pagos',
    newPayment: 'Nuevo Pago',
    instructionNumber: 'Número de instrucción',
    paymentReference: 'Referencia de pago',
    aditionalEmail: 'Correo electrónico Adicional',
    invalidMails: 'Revisa que los correos adicionales tengan el formato correcto: ejemplo@mail.com',
    confirmPayment: '¿Está seguro que desea enviar un pago de {{currency}} ${{amount}} al proveedor {{provider}} ahora?',
    confirmDeletePayment: '¿Está seguro que desea eliminar el pago de {{amount}} al proveedor {{provider}}?',
    savePaymentSuccess: 'Pago guardado correctamente',
    sendPaymentSuccess: 'Pago enviado correctamente',
    editPaymentSuccess: 'Pago editado correctamente',
    deletePaymentSuccess: 'Pago eliminado correctamente',
    paymentRecord: 'Registro de Pagos',
    editPayment: 'Editar Pago',
    relatedEmails: 'Correos adicionales',
    instructionDetail: 'Detalle de la instrucción',
    noInfoMsg: 'Sin información disponible',
    traditional: 'Tradicional',
    notZeroAmount: 'El monto ingresado debe ser mayor a cero',
    bpp: 'BPP',
    selectAProvider: 'Seleccione un proveedor',
    BPPDesc: 'Buyer Pushed Payment permite al cliente realizar pagos a una factura sin que un proveedor la haya cargado previamente',
    commerceRelationshipID: 'Identificador de la relación comercial',
    validInvoiceNumber: 'El número de factura no puede estar vacío o ser un símbolo',
    '/recent-invoices': 'Facturas recientes',
    '/declined-invoices': 'Facturas declinadas',
    '/process-invoices': 'Facturas en proceso de pago',
    '/create-payment': 'Nuevo pago',
    '/payment-record': 'Registro de Pagos',
    '/payment-detail': 'Detalle de la instrucción',
    '/payment-log': 'Información del gateway',
    '/edit-payment': 'Editar',
    '/update-retry-payment': 'Reintentos',
    '/approved-loan': 'Disposiciones',
    '/create-provider': 'Crear proveedores',
    '/edit-provider': 'Editar proveedores',
    '/create-customer': 'Crear clientes',
    '/edit-customer': 'Editar clientes',
    '/create-profile': 'Crear usuario',
    '/dashboard': 'Resumen',
    '/account-details': 'Detalles de cuenta',
    '/extract': 'Extraer',
    '/prepaid-Card': 'Tarjeta prepaga',
    '/transfer': 'Transferir',
    '/deposit': 'Depositar',
    '/load-invoice': 'Cargar facturas',
    '/customers': 'Listar, editar o deshabilitar clientes',
    // '/invoices': 'Listar facturas',
    '/invoices': 'Facturas',
    '/expired-invoices': 'Listar facturas expiradas',
    '/related-users': 'Cliente o proveedores relacionados', //REVISAR
    '/clients': 'Clientes',
    '/providers': 'Proveedores',
    '/register-bank': 'Crear bancos',
    '/bank-list': 'Listar, editar o deshabilitar bancos',
    '/role-list': 'Gestionar roles',
    '/invoice-detail': 'Detalle de factura',
    '/register-financing-products': 'Crear financiamiento de productos',
    '/financing-products': 'Financiar productos',
    '/register-role': 'Crear roles',
    '/route-list': 'Listar, editar y eliminar rutas',
    '/register-route': 'Crear rutas',
    '/admin-routes': 'Gestionar permisos',
    '/review-invoices': 'Facturas en revisión',
    '/reject-invoices': 'Facturas rechazadas',
    '/approved-invoices': 'Facturas aprobadas',
    '/paid-invoices': 'Facturas pagadas',
    '/pending-invoices': 'Facturas pendientes',
    '/create-lead': 'Requerir clietes o proveedores',
    '/lead-list': 'Listar, aprobar o rechazar requeridos',
    '/reports': 'Reportes',
    '/payment-conditions-log': 'Registro de condiciones de pago',
    '/transactions': 'Facturas generadas por banco',
    '/profile-list': 'Gestionar usuarios',
    '/create-approval-flow': 'Crear flujo de aprobacion de factura',
    '/edit-approval-flow': 'Editar flujo de aprobacion de factura',
    '/approval-flow-list': 'Lista de flujo de aprobación', 
    '/account-profile': 'Perfil de la cuenta',
    '/payment-conditions': 'Condiciones de pago',
    '/create-bank-user': 'Crear usuario',
    '/edit-bank-user': 'Editar usuario',
    '/bank-user-list': 'Lista de usuarios',
    '/register-card': 'Registrar tarjeta',
    '/replace-card': 'Sustitución de tarjeta de crédito',
    '/list-cards': 'Lista de tarjetas',
    '/payment-request-sent': 'Solicitudes',
    '/payment-request-received': 'Solicitudes',
    '/save-credentials': 'Guardar credenciales',
    '/edit-credentials': 'Editar credenciales',
    '/load-billing-cycle': 'Cargar ciclo de facturación',
    '/edit-billing-cycle': 'Editar ciclo de facturación',
    '/billing-cycles': 'Ciclos de facturación',
    '/create-banking-executive': 'Crear ejecutivo',
    '/load-executives': 'Cargar ejecutivos',
    '/products': 'Productos',
    '/contracted-products': 'Productos contratados',
    '/product-detail': 'Detalle de productos',
    '/accept-discount-rate': 'Aceptar tasa de descuento',
    '/edit-bank-admin': 'Editar administrador',
    '/save-manual': 'Guardar manual',
    '/list-manual': 'Manual',
    '/support': 'Asistencia',
    '/monthly-report': 'Reporte mensual',
    '/commerce-code': 'Código de comercio',
    '/confirm-provider': 'Confirmar proveedor',
    '/confirm-customer': 'Confirmar cliente',
    '/user-commerce-code': 'Número de afiliado',
    '/create-payment-condition': 'Crear condición de pago',
    '/edit-payment-condition': 'Editar condición de pago',
    '/provider-group-load': 'Grupo de proveedores',
    '/customer-group-load': 'Grupo de clientes',
    '/list-provider': 'Lista de proveedores',
    '/list-customer': 'Lista de clientes',
    '/create-admin-bank': 'Crear banco administrador',
    '/edit-admin-bank': 'Editar banco administrador',
    '/admin-bank-list': 'Bancos administradores',
    '/resp': 'Confirmación de almacenamiento de tarjeta',
    '/log-view': 'Logs',
    '/operation-log': 'Logs',
    '/mixed-payments': 'Programar pago mixto',
    '/credit-request': 'Créditos y préstamos',
    '/credits-list': 'Registro de créditos',
    '/check-deposit': 'Cheque a depositar',
    '/approved-checks': 'Cheques aprobados',
    '/not-approved-checks': 'Cheques por aprobar',
    '/rejected-checks': 'Cheques rechazados',
    '/send-information': 'Enviar ERP',
    '/banking-executives': 'Ejecutivos comerciales',
    '/payment-request-rejected': 'Solicitudes',
    '/payment-request': 'Solicitudes',
    '/disposition-report': 'Reporte de disposiciones',
  },
};

export default es;