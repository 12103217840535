const START_FETCH = 'role/START_FETCH';
const END_FETCH = 'role/END_FETCH';
const API_CALL = 'role/API_CALL';
const MESSAGE = 'role/MESSAGE';
const FETCH_BANK = 'role/FETCH_BANK';
const UPDATE_BANK = 'role/UPDATE_BANK';


export default {
  START_FETCH,
  END_FETCH,
  API_CALL,
  MESSAGE,
  FETCH_BANK,
  UPDATE_BANK,
};
