import React from 'react';
import './styles.scss';
import amex from '../../../../resources/images/assets/amex.png';

const Footer = ({ usedInLogin }) => {
  const d = new Date();
  const n = d.getFullYear();
  return (
    <footer>
      <div className='footer-main-container'>
        <div className={usedInLogin && 'footer-small'}>
          <figure className="image">
            <img src={amex} alt="logo"/>
          </figure>
          <p>
            <strong>Copyright ©{` ${n} `}</strong> Business Link.
            <span>
              Powered by American Express®. All rights reserved.
            </span>
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;