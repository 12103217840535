import { createAction } from 'redux-actions';
import types from './types';

const startFetch = createAction(types.START_FETCH);
const endFetch = createAction(types.END_FETCH);
const fetchDocumentTypes = createAction(types.FETCH_DOCUMENT_TYPES);
const updateDocumentTypes = createAction(types.UPDATE_DOCUMENT_TYPES);

const showMessage = createAction(types.MESSAGE,
  ({ message }) => ({
    message,
  }),
  ({ config }) => ({
    config,
  }));

export default {
  startFetch,
  endFetch,
  showMessage,
  fetchDocumentTypes,
  updateDocumentTypes,
};
