import React, { Component } from 'react';
import { compose } from 'redux';
import './styles.scss';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import PropTypes from 'prop-types';
import { Loading, Panel, SelectSingle, TableSimple, } from '../../components';
import { TOAST_CONFIG } from '../../config/constants';
import { roleActions } from '../../state/ducks/role';
import { routeActions } from '../../state/ducks/route';


class AssignRoute extends Component {
  constructor(props) {
    super(props);
    this.state = { ...this.getInitialState() };
  }

  getInitialState = () => {
    const { rolesRoutes: propsRolesRoutes } = this.props;
    return {
      //rolesRoutes: propsRolesRoutes,
      role: '',
      route: '',
      assignLoading: false,
      activate: -1,
      selectedRole: '',
      rolesRoutes: [],
    };
  };

  componentDidMount() {
    const { fetchRoutesByType, fetchRoles, auth: { user: { user_id, b2b: { type_user } } } } = this.props;

    fetchRoles({
      params: {
        owner_id: user_id,
      },
    });
    fetchRoutesByType({
      params: {
        type: type_user
      },
    });
  }

  orderArray = (list) => {
    const {
      route: {
        list: routeList,
      },
    } = this.props;

    const { rolesRoutes } = this.state;
    let variable = [];
    let j = 0;

    if (rolesRoutes.length > 0) {
      routeList.forEach(function (route) {
        if ((rolesRoutes[j].path === route.path)) {
          variable.push({
            role_id: rolesRoutes[j].role_id,
            id: rolesRoutes[j].id,
            name: rolesRoutes[j].name,
            path: rolesRoutes[j].path,
            active: 1,
          });
          j = j < rolesRoutes.length - 1 ? j + 1 : j;
        } else {
          variable.push({
            role_id: '',
            id: route.id,
            name: '',
            path: route.path,
            active: 0,
          });
        }
      });
    } else {
      routeList.forEach(function (route) {
        variable.push({
          role_id: '',
          id: route.id,
          name: '',
          path: route.path,
          active: 0,
        });
      });
    }
    return variable;
  };

  sort = (list) => {
    return list.sort(((a, b) => b.active - a.active))
  };

  getGridSettings = () => {

    const {
      t,
      route: {
        list: routeList,
      },
      createRouteRole,
      deleteRouteRole,
      showMessage,
      fetchRoutesRole,
    } = this.props;

    const { rolesRoutes, activate, selectedRole } = this.state;

    const route = 
      this.orderArray(routeList)
        /* Here we filter these two routes to not allow the user change them and assign them by default */ 
        .filter(currentRoute => currentRoute.path !== "/dashboard" && currentRoute.path !== "/select-profile");

    const fullroutes = { ...route };

    this.sort(route);
    //let permissions = Object.values(fullroutes);

    const columns = [
      {
        id: 'path', // Required because our accessor is not a string
        Header: t('permissions'),
        accessor: d => `${t(d.path)} `,
      },
      /*{
        id: 'activo', // Required because our accessor is not a string
        Header: t('status'),
        width: 120,
        Cell: cellInfo => (cellInfo.original.name
          ?
          <div>
            <i className="far fa-check-circle mr-8px" aria-hidden="true" style={{ color: 'green' }}/>

          </div>  
          : <i className="fas fa-ban mr-8px" aria-hidden="true" style={{ color: 'red' }}/>),
      },*/
      {
      id: 'action', // Required because our accessor is not a string
      Header: t('action'),
      accessor: d => `${d.id} `,
      filterable: false,
      show: activate === 1 ? true : false,
      Cell: cellInfo => (
        <table>
          <tbody>
          <tr>
            <td>
              <button className="button is-primary is-inverted"
                      onClick={() => {
                        cellInfo.original.active
                          ?
                          (cellInfo.original.path === '/resp' || cellInfo.original.path === '/register-card') ?
                            deleteRouteRole({
                              params: {
                                role_id: cellInfo.original.role_id,
                                route_id: route.map((roleMap, index) => ({
                                  ...roleMap,
                                  index
                                }),)
                                  .filter(roleFil => roleFil.path === '/register-card')[0].id,
                              },
                              callback: () => {
                                deleteRouteRole({
                                  params: {
                                    role_id: cellInfo.original.role_id,
                                    route_id: route.map((roleMap, index) => ({
                                      ...roleMap,
                                      index
                                    }),)
                                      .filter(roleFil => roleFil.path === '/resp')[0].id,
                                  },
                                  callback: () => {
                                    fetchRoutesRole({
                                      params: {
                                        role_id: selectedRole,
                                        isFull: true,
                                      },
                                      callback: responseData => this.setState({
                                        rolesRoutes: responseData.data,
                                      }),
                                    });
                                    showMessage({
                                      message: t('removeRouteSuccess'),
                                      config: TOAST_CONFIG.SUCCESS,
                                    });
                                  },
                                });
                              }
                            })
                            :
                            deleteRouteRole({
                              params: {
                                role_id: cellInfo.original.role_id,
                                route_id: cellInfo.original.id,
                              },
                              callback: () => {
                                fetchRoutesRole({
                                  params: {
                                    role_id: selectedRole,
                                    isFull: true,
                                  },
                                  callback: responseData => this.setState({
                                    rolesRoutes: responseData.data,
                                  }),
                                });
                                showMessage({
                                  message: t('removeRouteSuccess'),
                                  config: TOAST_CONFIG.SUCCESS,
                                });
                              },
                            })
                          :
                          (cellInfo.original.path === '/resp' || cellInfo.original.path === '/register-card') ?
                            createRouteRole({
                              data: {
                                role_id: selectedRole,
                                route_id: route.map((roleMap, index) => ({
                                  ...roleMap,
                                  index
                                }),)
                                  .filter(roleFil => roleFil.path === '/register-card')[0].id,
                              },
                              callback: () => {
                                createRouteRole({
                                  data: {
                                    role_id: selectedRole,
                                    route_id: route.map((roleMap, index) => ({
                                      ...roleMap,
                                      index
                                    }),)
                                      .filter(roleFil => roleFil.path === '/resp')[0].id,
                                  },
                                  callback: () => {
                                    fetchRoutesRole({
                                      params: {
                                        role_id: selectedRole,
                                        isFull: true,
                                      },
                                      callback: responseData => this.setState({
                                        rolesRoutes: responseData.data,
                                      }),
                                    });
                                    showMessage({
                                      message: t('assignRouteSuccess'),
                                      config: TOAST_CONFIG.SUCCESS,
                                    });
                                  }
                                });
                              }
                            })
                            :
                            createRouteRole({
                              data: {
                                role_id: selectedRole,
                                route_id: cellInfo.original.id,
                              },
                              callback: () => {
                                fetchRoutesRole({
                                  params: {
                                    role_id: selectedRole,
                                    isFull: true,
                                  },
                                  callback: responseData => this.setState({
                                    rolesRoutes: responseData.data,
                                  }),
                                });
                                showMessage({
                                  message: t('assignRouteSuccess'),
                                  config: TOAST_CONFIG.SUCCESS,
                                });
                              },
                            });
                      }}
              >
                {(cellInfo.original.active === 1
                  ? <i className="fas fa-minus-circle mr-8px" aria-hidden="true"
                       style={{ color: 'red' }}/>
                  : <i className="fas fa-minus-circle mr-8px" aria-hidden="true"
                       style={{ color: 'green' }}/>)}
                {(cellInfo.original.active === 1 ? t('deny') : t('grant'))}
              </button>
            </td>
          </tr>
          </tbody>
        </table>
        ),
      },
    ];

    const data = route;
    const title = t('flowsApproval');
    
    return {
      columns,
      data,
      title,
    };
  };

  resetState = () => {
    this.setState(this.getInitialState());
  };

  handleInputChange = (event) => {
    const {
      target: {
        checked, value: targetValue, type, name,
      },
    } = event;
    const value = type === 'checkbox' ? checked : targetValue;
    this.setState({
      [name]: value,
    });
  };

  handleSelectChange = (newVal, label) => {
    const { fetchRoutesRole } = this.props;
    this.setState({
      [label.name]: newVal.value,
    });
   if(newVal.value !== "") {
     fetchRoutesRole({
        params: {
          role_id: newVal.value,
          isFull: true,
        },
        callback: responseData => this.setState({ 
          rolesRoutes: responseData.data, 
          activate: 1, 
          selectedRole: newVal.value
        }),
     });
   }
   else{
     this.setState({ rolesRoutes: [] });
   }
  };

  handleItemChange = (newValue, action) => {
    const { role } = this.state;
    const {
      createRouteRole,
      deleteRouteRole,
      showMessage,
      t,
    } = this.props;
    if (role !== '') {
      if (action.action === 'select-option') {
        createRouteRole({
          data: {
            role_id: role,
            route_id: action.option.id,
          },
          callback: () => {
            this.setState({ rolesRoutes: newValue });
            showMessage({
              message: t('assignRouteSuccess'),
              config: TOAST_CONFIG.SUCCESS,
            });
          },
        });
      } else if (action.action === 'remove-value') {
        deleteRouteRole({
          params: {
            role_id: role,
            route_id: action.removedValue.id,
          },
          callback: () => {
            this.setState({ rolesRoutes: newValue });
            showMessage({
              message: t('removeRouteSuccess'),
              config: TOAST_CONFIG.SUCCESS,
            });
          },
        });
      }
    } else {
      showMessage({
        message: t('roleCantBeEmpty'),
        config: TOAST_CONFIG.SUCCESS,
      });
    }
  };

  createAssignRoute = () => {
    const { saveAssignRoute, t, showMessage } = this.props;
    const { role, route } = this.state;

    if (role !== null && role !== '' && route !== null && route !== '') {
      const data = {
        role_id: role,
        route_id: route,
      };
      this.setState({ assignLoading: true });
      saveAssignRoute({
        data,
        callback: () => {
          showMessage({
            message: t('assignRoleRoute'),
            config: TOAST_CONFIG.SUCCESS,
          });
          this.resetState();
        },
      });
    } else {
      showMessage({
        message: <strong style={{ color: '#ffffff' }}>{t('invalidRoleOrRoute')}</strong>,
        config: TOAST_CONFIG.ERROR,
      });
    }
  };

  render() {
    const {
      t,
      route: {
        list: routeList,
        loading: routeLoading,
      },
      role: {
        list: roleList,
        loading: roleLoading,
      },
    } = this.props;
    
    const { title, columns, data } = this.getGridSettings();

    const { assignLoading, rolesRoutes, selectedRole } = this.state;
    const loading = roleLoading || routeLoading || assignLoading;

    return (

      <div>
        {loading && <Loading />}
        <div className="columns">
          <div className="column is-full">
            <Panel headingText={t('assignPermissions')}>
              <div className="field is-horizontal">
                <div className="field-body columns">
                  <div className="column is-tree-fifths">
                    <SelectSingle
                      name="role"
                      label={t('profiles')}
                      selectClasses="is-fullwidth"
                      placeholder={t('Select')}
                      onChange={this.handleSelectChange}
                      options={roleList ? [
                        {
                          label: t('Select'),
                          value: selectedRole,
                        },
                        ...roleList.map(item => ({
                          label: item.name,
                          value: item.id,
                        })),
                      ] : []}
                    />
                  </div>
                </div>
              </div>
              {/*<div className="field is-horizontal">
                <div className="field-body columns">
                  <div className="column is-tree-fifths">
                    <CreatableMultiSelect
                      name="route"
                      label={t('permissions')}
                      value={rolesRoutes}
                      onChange={this.handleItemChange}
                      placeholder={t('selectOrTypePermissions')}
                      noOptionsMessage={() => t('noItemsAvailable')}
                      formatCreateLabel={inputValue => t('createNewItem', { inputValue })}
                      getOptionLabel={option => t(option.path)}
                      getOptionValue={option => option.id}
                      getNewOptionData={(inputValue, optionLabel) => ({
                        id: optionLabel,
                        description: optionLabel,
                      })
                      }
                      controlClasses="is-expanded"
                      options={routeList}
                    />
                  </div>
                </div>
              </div> */}
              <div className="field is-horizontal">
              <div className="field-body columns">
                  <div className="column">
                    <TableSimple data={data} columns={columns}/>
                  </div>
                </div>
              </div>
            </Panel>
          </div>
        </div>
      </div>

    );
  }
}

AssignRoute.propTypes = {
  role: PropTypes.shape(Object).isRequired,
  rolesRoutes: PropTypes.shape(Object).isRequired,
  route: PropTypes.shape(Object).isRequired,
  fetchRoutesRole: PropTypes.func.isRequired,
  createRouteRole: PropTypes.func.isRequired,
  deleteRouteRole: PropTypes.func.isRequired,
  fetchRoutes: PropTypes.func.isRequired,
  fetchRoles: PropTypes.func.isRequired,
  saveAssignRoute: PropTypes.func.isRequired,
  showMessage: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};


const mapStateToProps = ({
  auth,
  role,
  route,
}) => ({
  auth,
  role,
  route,
});
export default compose(
  connect(mapStateToProps, {
    ...roleActions,
    ...routeActions,
  }),
  withNamespaces(),
)(AssignRoute);
