import React, { Component, Fragment, memo } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import { CSVLink } from 'react-csv';
import PropTypes from 'prop-types';

import './styles.scss';
import Modal from '../../components/Modal';
import { Currency, Loading, Panel, TableSimple, Toggle } from '../../components';
import { clientsActions } from '../../state/ducks/clients';
import {
	formatAmount,
	formatDate,
	numberFilter,
	onInputOnlyNumbers,
	fileDateTime,
	formatCurrencyKeo,
} from '../../util';
import DateInput from '../../components/DateInput';
import { TOAST_CONFIG } from '../../config/constants';
import XLSX from 'xlsx';
import deepClone from 'lodash.clonedeep';

class DispositionReport extends Component {
	constructor(props) {
		super(props);
		this.state = this.getInitialState();
		this.selectTable = React.createRef();
		this.csvRef = React.createRef();
	}

	getInitialState = () => ({
		downloadFileData: [],
		csvCols: [],
		fromDate: '', //new Date().setDate(new Date().getDate() - 1),
		toDate: '', //new Date().setHours(23, 59, 59, 999),
	});

	componentDidMount() {this.loadDispositionReport(true);}

	getGridSettings = () => {
		const {
			t,
			auth: { user: { b2b: { type_user } } },
			clients: { dispositionReport },
		} = this.props;

		let columns = null;
		let data = [];
		let title = null;

		switch (type_user) {
			case 'buyer':
				columns = [
					{
						expander: true,
						show: false,
					},
					{
						id: 'loan_number',
						Header: t('loanNumber'),
						accessor: 'reference',
					},
					{
						id: 'seller',
						Header: t('seller'),
						accessor: 'seller',
					},
					{
						id: 'invoice',
						Header: t('invoiceNumber'),
						accessor: 'invoice',
						Cell: cellInfo => (
							<div style={{ textAlign: 'center' }}>
								<span>{cellInfo.original.invoice}</span>
							</div>
						),
					},
					{
						id: 'status',
						Header: t('transferState'),
						accessor: 'status',
						Cell: cellInfo => (
							<div style={{ textAlign: 'center' }}>
								<span>{cellInfo.original.status}</span>
							</div>
						),
					},
					{
						id: 'currency', // Required because our accessor is not a string
						headerClassName: 'wordwrap',
						className: 'text-center',
						Header: t('currency'),
						accessor: d => 'MXN',
					},
					{
						id: 'total_amount',
						Header: t('amountArranged'),
						accessor: d => d.total_amount,
						Cell: cellInfo => (
							<Currency value={cellInfo.original.total_amount} />
						),
						sortMethod: (a, b) => Number(a)-Number(b),
						filterMethod: (filter, row) => numberFilter(filter, row),
						Filter: ({ filter, onChange }) =>
							<input
								onInput={onInputOnlyNumbers}
								onChange={event => onChange(event.target.value)}
								style={{ width: '100%' }}
								value={filter ? filter.value : ''}
							/>
					},
					{
						id: 'date',
						Header: t('loanDate'),
						accessor: d => formatDate(d.date),
						Cell: cellInfo => (
							<div style={{ textAlign: 'center' }}>
								<span>{formatDate(cellInfo.original.date)}</span>
							</div>
						),
					},
					{
						id: 'due_date',
						Header: t('dateV'),
						accessor: d => formatDate(d.due_date),
						Cell: cellInfo => (
							<div style={{ textAlign: 'center' }}>
								<span>{formatDate(cellInfo.original.due_date)}</span>
							</div>
						),
					},
					{
						id: 'term_days', // Required because our accessor is not a string
						headerClassName: 'wordwrap',
						className: 'text-center',
						Header: t('timeLimit'),
						accessor: d => d.term_days !== null ? d.term_days : '',
						sortMethod: (a, b) => Number(a)-Number(b),
						filterMethod: (filter, row) => numberFilter(filter, row),
						Filter: ({ filter, onChange }) =>
							<input
								onInput={onInputOnlyNumbers}
								onChange={event => onChange(event.target.value)}
								style={{ width: '100%' }}
								value={filter ? filter.value : ''}
							/>
					},
					{
						id: 'balance',
						Header: t('canton'),
						accessor: d => d.balance,
						Cell: cellInfo => (
							<Currency value={cellInfo.original.balance} />
						),
						sortMethod: (a, b) => Number(a)-Number(b),
						filterMethod: (filter, row) => numberFilter(filter, row),
						Filter: ({ filter, onChange }) =>
							<input
								onInput={onInputOnlyNumbers}
								onChange={event => onChange(event.target.value)}
								style={{ width: '100%' }}
								value={filter ? filter.value : ''}
							/>
					},
					{
						id: 'fixed_interest',
						Header: t('interestOrdinary'),
						accessor: d => d.fixed_interest,
						Cell: (cellInfo) => (
							<Currency value={cellInfo.original.fixed_interest} />
						),
						sortMethod: (a, b) => Number(a)-Number(b),
						filterMethod: (filter, row) => numberFilter(filter, row),
						Filter: ({ filter, onChange }) =>
							<input
								onInput={onInputOnlyNumbers}
								onChange={event => onChange(event.target.value)}
								style={{ width: '100%' }}
								value={filter ? filter.value : ''}
							/>
					},
					{
						id: 'fixed_iva',
						Header: t('taxOrdinary'),
						accessor: d => d.fixed_iva,
						Cell: (cellInfo) => (
							<Currency value={cellInfo.original.fixed_iva} />
						),
						sortMethod: (a, b) => Number(a)-Number(b),
						filterMethod: (filter, row) => numberFilter(filter, row),
						Filter: ({ filter, onChange }) =>
							<input
								onInput={onInputOnlyNumbers}
								onChange={event => onChange(event.target.value)}
								style={{ width: '100%' }}
								value={filter ? filter.value : ''}
							/>
					},
					{
						id: 'late_interest',
						Header: t('interestLatePayment'),
						accessor: d => d.late_interest,
						Cell: (cellInfo) => (
							<Currency value={cellInfo.original.late_interest} />
						),
						sortMethod: (a, b) => Number(a)-Number(b),
						filterMethod: (filter, row) => numberFilter(filter, row),
						Filter: ({ filter, onChange }) =>
							<input
								onInput={onInputOnlyNumbers}
								onChange={event => onChange(event.target.value)}
								style={{ width: '100%' }}
								value={filter ? filter.value : ''}
							/>
					},
					{
						id: 'late_iva',
						Header: t('taxLatePayment'),
						accessor: d => d.late_iva,
						Cell: (cellInfo) => (
							<Currency value={cellInfo.original.late_iva} />
						),
						sortMethod: (a, b) => Number(a)-Number(b),
						filterMethod: (filter, row) => numberFilter(filter, row),
						Filter: ({ filter, onChange }) =>
							<input
								onInput={onInputOnlyNumbers}
								onChange={event => onChange(event.target.value)}
								style={{ width: '100%' }}
								value={filter ? filter.value : ''}
							/>
					},
					{
						id: 'sum_payment_received',
						Header: t('paymentReceived'),
						accessor: d => d.sum_payment_received,
						Cell: (cellInfo) => (
							<Currency value={cellInfo.original.sum_payment_received} />
						),
						sortMethod: (a, b) => Number(a)-Number(b),
						filterMethod: (filter, row) => numberFilter(filter, row),
						Filter: ({ filter, onChange }) =>
							<input
								onInput={onInputOnlyNumbers}
								onChange={event => onChange(event.target.value)}
								style={{ width: '100%' }}
								value={filter ? filter.value : ''}
							/>
					},
					{
						id: 'sum_capital_payment',
						Header: t('paymentCapital'),
						accessor: d => d.sum_capital_payment,
						Cell: (cellInfo) => (
							<Currency value={cellInfo.original.sum_capital_payment} />
						),
						sortMethod: (a, b) => Number(a)-Number(b),
						filterMethod: (filter, row) => numberFilter(filter, row),
						Filter: ({ filter, onChange }) =>
							<input
								onInput={onInputOnlyNumbers}
								onChange={event => onChange(event.target.value)}
								style={{ width: '100%' }}
								value={filter ? filter.value : ''}
							/>
					},
					{
						id: 'sum_interest',
						Header: t('paymentInterest'),
						accessor: d => d.sum_interest,
						Cell: (cellInfo) => (
							<Currency value={cellInfo.original.sum_interest} />
						),
						sortMethod: (a, b) => Number(a)-Number(b),
						filterMethod: (filter, row) => numberFilter(filter, row),
						Filter: ({ filter, onChange }) =>
							<input
								onInput={onInputOnlyNumbers}
								onChange={event => onChange(event.target.value)}
								style={{ width: '100%' }}
								value={filter ? filter.value : ''}
							/>
					},
					{
						id: 'sum_tax',
						Header: t('paymentTax'),
						accessor: d => d.sum_tax,
						Cell: (cellInfo) => (
							<Currency value={cellInfo.original.sum_tax} />
						),
						sortMethod: (a, b) => Number(a)-Number(b),
						filterMethod: (filter, row) => numberFilter(filter, row),
						Filter: ({ filter, onChange }) =>
							<input
								onChange={event => onChange(event.target.value)}
								style={{ width: '100%' }}
								value={filter ? filter.value : ''}
							/>
					},
					{
						id: 'current_amount',
						Header: t('balancePerDay'),
						accessor: d => d.current_amount,
						Cell: (cellInfo) => (
							<Currency value={cellInfo.original.current_amount} />
						),
						sortMethod: (a, b) => Number(a)-Number(b),
						filterMethod: (filter, row) => numberFilter(filter, row),
						Filter: ({ filter, onChange }) =>
							<input
								onInput={onInputOnlyNumbers}
								onChange={event => onChange(event.target.value)}
								style={{ width: '100%' }}
								value={filter ? filter.value : ''}
							/>
					},
				];
				data = dispositionReport;
				title = t('dispositionReport');
				break;

			default:
				columns = [];
				data = [];
				title = '';
		}

		return {
			columns,
			data,
			title,
		};
	};

	download = (e, fileFormat, fileName) => {

		e.preventDefault();

		// Quitamos la infomación que no queremos exportar
		function cleanData(data) {
			return data.filter(item => !item.hasOwnProperty("expander") && item.show !== false && item.id !== "action");
		}
		
		// Traemos el estado actual de la tabla
		const { sortedData, columns } = this.selectTable.current.getResolvedState();

		// Creamos una deep copy para evitra mutar el objeto original
		let cleanedSortedData = deepClone(sortedData);
		let cleanedColumns = deepClone(columns);

		// Limpiamos los datos
		cleanedSortedData = cleanData(cleanedSortedData);
		cleanedColumns = cleanData(cleanedColumns);

		// Cambiamos el formato de la fecha, la moneda y completamos los datos necesarios
		for (let i = 0; i < cleanedSortedData.length; i++) {
			cleanedSortedData[i].totalPay = "$" + formatCurrencyKeo(cleanedSortedData[i]._original.adjusted_amount);
			cleanedSortedData[i].adjusted_amount = "$" + formatCurrencyKeo(cleanedSortedData[i]._original.adjusted_amount);
			cleanedSortedData[i].due_date = (cleanedSortedData[i]._original.due_date);
			cleanedSortedData[i].date = (cleanedSortedData[i]._original.date);
		}

		// Este array almacena los datos que vamos a exportar    
		let downloadFileData = [];

		// Pusheamos solo los headers de las columnas
		downloadFileData.push(cleanedColumns.map(column => column.Header));

		downloadFileData.push(...cleanedSortedData.map((sorted) => {
			const individualItems = {};
			cleanedColumns.forEach((column) => {
			  individualItems[column.id] = sorted[column.id];
			});
			return Object.values(individualItems);
		}));

		if ( fileFormat === "csv" ) {
			this.setState({ downloadFileData }, () => {
			  this.csvRef.current.link.click();
			});
		  }
	  
		  if ( fileFormat === "xlsx" ) {
			const ws = XLSX.utils.json_to_sheet(downloadFileData, {skipHeader : true});
			const wb = XLSX.utils.book_new();
			XLSX.utils.book_append_sheet(wb, ws,  fileDateTime());
			XLSX.writeFile(wb, `${fileName} - ${fileDateTime()}.xlsx`);
		  }

	};

	handleDateChange = (name, date) => {
		
		if(date){
			if (name === 'fromDate' && date) {
				date.setHours(0, 0, 0, 0);
			}

			if (name === 'toDate' && date) {
				date.setHours(23, 59, 59, 999);
			}
		}
		this.setState({
			[name]: date ? date : '',
		});
	};

	loadDispositionReport = (last_dispositions) => {
		const { getDispositionReport } = this.props;
		const { fromDate, toDate } = this.state;
		getDispositionReport({
			params: {
				from: last_dispositions ? null : Math.round(fromDate / 1000),
				to: last_dispositions ? null : Math.round(toDate / 1000),
				last_dispositions,
			},
		})
	}

	render() {
		const {
			clients: { loading },
			t,
			showMessage,
			auth: { user: { b2b: { buyer } } }
		} = this.props;
		const { downloadFileData, fromDate, toDate, csvCols } = this.state;
		return (
			<Toggle>
				{({ toggled, onToggle }) => {
					const { title, columns, data } = this.getGridSettings(onToggle);
					return (
						<div className="dashboard">
							{loading && <Loading />}
							<div className="columns is-multiline">
								<Fragment>
									{ toggled && (<Modal onToggle={onToggle} />)}
									<div className="column is-full">
										<div className='download-buttons-disp'>
											<button
											className="button custom-btn is-link is-outlined"
											onClick={(e) => {
												this.download(e, "csv")
											}}
											>
											<i className="fa fa-file-csv mr-8px" aria-hidden="true"/>
											<span>{`${t('download')} CSV`}</span>
											</button>
											<button
											className="button custom-btn is-link is-outlined"
											onClick={(e) => {
												this.download(e, "xlsx", t('transactionReport'))
											}}
											>
											<i className="fa fa-file-excel mr-8px" aria-hidden="true"/>
											<span>{`${t('download')} XLSX`}</span>
											</button>
											<CSVLink
											data={downloadFileData}
											filename={`${t('transactionReport')} - ${fileDateTime()}.csv`}
											className="hidden"
											ref={this.csvRef}
											separator={";"}
											charSet="UTF-8"
											/>
										</div>
										<Panel headingText={t(title)}>
											<div className="field is-horizontal">
												<div className="field-body columns">
													<div className="columns is-centered" style={{ width: '100%', marginTop: "10px" }}>
														<div className="column">
															<p style={{ fontSize: '1.25rem' }}>
																{ buyer === 1 && t('dateRange') }
															</p>
														</div>
														<div className="column" style={{ zIndex: 99999 }}>
															<DateInput
																name="invoice_date"
																label={t('since')}
																value={fromDate}
																onChange={(value) => this.handleDateChange('fromDate', value)}
																type="text"
																fieldClasses="is-expanded"
																controlClasses="has-icons-left"
																icon="far fa-calendar-alt"
																iconPosition="is-left"
															/>
														</div>
														<div className="column" style={{ zIndex: 99999 }}>
															<DateInput
																name="invoice_date"
																label={t('until')}
																value={toDate}
																onChange={(value) => this.handleDateChange('toDate', value)}
																type="text"
																fieldClasses="is-expanded"
																controlClasses="has-icons-left"
																icon="far fa-calendar-alt"
																iconPosition="is-left"
															/>
														</div>
														<div style={{ marginTop: '35px' }}>
															<button
																type="button"
																className="button is-primary"
																onClick={() => {
																	!fromDate || !toDate ?
																		showMessage({
																			message: <strong style={{ color: '#ffffff' }}>{t('emptyFields')}</strong>,
																			config: TOAST_CONFIG.ERROR,
																		})
																		: toDate < fromDate ?
																			showMessage({
																				message: <strong style={{ color: '#ffffff' }}>{t('errorDate')}</strong>,
																				config: TOAST_CONFIG.ERROR,
																			})
																			:
																			this.loadDispositionReport(false);
																}}
															>
																{t('search')}
															</button>
														</div>
													</div>
												</div>
												<br/>
											</div>
											{ data &&
												<TableSimple
													data={data}
													columns={columns}
													reff={this.selectTable}
												/>
											}
										</Panel>
									</div>
								</Fragment>
							</div>
						</div>
					);
				}}
			</Toggle>
		);
	}
}

DispositionReport.propTypes = {
	t: PropTypes.func.isRequired,
	showMessage: PropTypes.func.isRequired,
	auth: PropTypes.shape({}).isRequired,
	clients: PropTypes.shape({}).isRequired,
	getDispositionReport: PropTypes.func.isRequired,
};

const mapStateToProps = ({ auth, clients }) => (
	{
		auth,
		clients,
	}
);
export default compose(
	connect(mapStateToProps, { ...clientsActions }),
	withNamespaces(),
)(memo(DispositionReport));