import { leadActions, leadTypes } from '../ducks/lead';

const leadData = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== leadTypes.FETCH_LEADS) {
    return;
  }

  const {
    params: {
      id = '', name = '', document_type = '', document_number = '', address = '', referrer_id = '', role_id = '', pageSize = 0,
    } = {},
  } = action.payload;

  dispatch({
    type: leadTypes.API_CALL,
    payload: {
      config: {
        method: 'GET',
        url: `b2b-leads?id=${id}&name=${name}&document_type=${document_type}&document_number=${document_number}&address=${address}&referrer_id=${referrer_id}&role_id=${role_id}&pageSize=${pageSize}`,
      },
      authorization: true,
      onStart: leadActions.startFetch,
      onComplete: ({ data: responseData }) => {
        const { success, data } = responseData;
        if (success) {
          dispatch(leadActions.updateLeads({
            list: data,
          }));
        }
      },
      onEnd: leadActions.endFetch,
    },
  });
};

const editLead = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== leadTypes.EDIT_LEAD) {
    return;
  }

  const {
    data = {},
    params: {
      id,
    } = {},
    callback = () => {
    },
    t = () => {
    },
  } = action.payload;

  dispatch({
    type: leadTypes.API_CALL,
    payload: {
      config: {
        method: 'PUT',
        url: `b2b-leads/${id}`,
        data,
      },
      authorization: true,
      onStart: leadActions.startFetch,
      onComplete: ({ data: responseData }) => {
        const { success, data: rspData } = responseData;
        if (success) {
          callback(rspData);
        }
      },
      onEnd: leadActions.endFetch,
    },
  });
};

const deleteLead = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== leadTypes.DELETE_LEAD) {
    return;
  }

  const {
    params: {
      id,
    } = {},
    callback = () => {
    },
  } = action.payload;

  dispatch({
    type: leadTypes.API_CALL,
    payload: {
      config: {
        method: 'DELETE',
        url: `b2b-leads/${id}`,
      },
      authorization: true,
      onStart: leadActions.startFetch,
      onComplete: () => {
        callback();
      },
      onEnd: leadActions.endFetch,
    },
  });
};

export default [leadData, editLead, deleteLead];
