import { productActions, productTypes } from '../ducks/product';

const saveProduct = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== productTypes.SAVE_PRODUCT) {
    return;
  }

  const {
    data,
    callback = () => {
    },
  } = action.payload;

  dispatch({
    type: productTypes.API_CALL,
    payload: {
      config: {
        method: 'POST',
        url: 'b2b/register-product',
        data,
      },
      authorization: true,
      onStart: productActions.startFetch,
      onComplete: ({ data: { success, data } }) => {
        if (success) {
          callback(data);
        }
      },
      onEnd: productActions.endFetch,
    },
  });
};

const getProduct = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== productTypes.GET_PRODUCT) {
    return;
  }

  const {
    params: {
      id = '',
    } = {},
    callback = () => {
    },
  } = action.payload;

  dispatch({
    type: productTypes.API_CALL,
    payload: {
      config: {
        method: 'GET',
        url: `b2b/get-products?id=${id}`,
      },
      authorization: true,
      onStart: productActions.startFetch,
      onComplete: ({ data: { success, data } }) => {
        if (success) {
          dispatch(productActions.getContracted(data));
          callback(data);
        }
      },
      onEnd: productActions.endFetch,
    },
  });
};

const updateProduct = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== productTypes.UPDATE_PRODUCT) {
    return;
  }

  const {
    data,
    callback = () => {
    },
  } = action.payload;

  dispatch({
    type: productTypes.API_CALL,
    payload: {
      config: {
        method: 'POST',
        url: `b2b/update-product`,
        data
      },
      authorization: true,
      onStart: productActions.startFetch,
      onComplete: ({ data: { success, data } }) => {
        if (success) {
          callback(data);
        }
      },
      onEnd: productActions.endFetch,
    },
  });
};

const disableProduct = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== productTypes.DISABLE_PRODUCT) {
    return;
  }

  const {
    data,
    callback = () => {
    },
  } = action.payload;

  dispatch({
    type: productTypes.API_CALL,
    payload: {
      config: {
        method: 'POST',
        url: `b2b/disable-product`,
        data,
      },
      authorization: true,
      onStart: productActions.startFetch,
      onComplete: ({ data: { success, data } }) => {
        if (success) {
          callback(data);
        }
      },
      onEnd: productActions.endFetch,
    },
  });
};

const getContractedProduct = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== productTypes.GET_CONTRACTED_PRODUCT) {
    return;
  }

  const {
    callback = () => {
    },
  } = action.payload;

  dispatch({
    type: productTypes.API_CALL,
    payload: {
      config: {
        method: 'GET',
        url: 'b2b/get-contrated-products',
      },
      authorization: true,
      onStart: productActions.startFetch,
      onComplete: ({ data: { success, data } }) => {
        if (success) {
          callback(data);
          dispatch(productActions.getProducts(data));
        }
      },
      onEnd: productActions.endFetch,
    },
  });
};

export default [saveProduct, getProduct, updateProduct, disableProduct, getContractedProduct];

