const START_FETCH = 'flowApproval/START_FETCH';
const END_FETCH = 'flowApproval/END_FETCH';
const UPDATE_FLOW_APPROVAL = 'flowApproval/UPDATE_FLOW_APPROVAL';
const API_CALL = 'flowApproval/API_CALL';
const MESSAGE = 'flowApproval/MESSAGE';
const CREATE_FLOW_APPROVAL = 'flowApproval/CREATE_FLOW_APPROVAL';
const FETCH_ACTIVE_FLOW = 'flowApproval/FETCH_ACTIVE_FLOW';
const UPDATE_ACTIVE_FLOW = 'flowApproval/UPDATE_ACTIVE_FLOW';
const GET_FLOW_APPROVAL = 'flowApproval/GET_FLOW_APPROVAL';
const GET_WORK_FLOWS = 'flowApproval/GET_WORK_FLOWS';
const GET_WORK_FLOW_ID = 'flowApproval/GET_WORK_FLOW_ID';
const SELECTED_ID = 'flowApproval/SELECTED_ID';
const DESACTIVE_WORK_FLOW = 'flowApproval/DESACTIVE_WORK_FLOW';
const DEFAULT_WORK_FLOW = 'flowApproval/DEFAULT_WORK_FLOW';
const GET_WORK_FLOW_TYPES = 'flowApproval/GET_WORK_FLOW_TYPES';
const UPDATE_PROCESS = 'flowApproval/UPDATE_PROCESS';
const GET_PROCESS_WORK_FLOW = 'flowApproval/GET_PROCESS_WORK_FLOW';

export default {
  START_FETCH,
  END_FETCH,
  UPDATE_FLOW_APPROVAL,
  API_CALL,
  MESSAGE,
  CREATE_FLOW_APPROVAL,
  GET_FLOW_APPROVAL,
  FETCH_ACTIVE_FLOW,
  UPDATE_ACTIVE_FLOW,
  GET_WORK_FLOWS,
  GET_WORK_FLOW_ID,
  SELECTED_ID,
  DESACTIVE_WORK_FLOW,
  DEFAULT_WORK_FLOW,
  GET_WORK_FLOW_TYPES,
  UPDATE_PROCESS,
  GET_PROCESS_WORK_FLOW,
};
