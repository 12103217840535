import { createAction } from 'redux-actions';
import types from './types';

const startFetch = createAction(types.START_FETCH);
const endFetch = createAction(types.END_FETCH);
const updateUserProfile = createAction(types.UPDATE_USER_PROFILE);
const fetchUserProfile = createAction(types.FETCH_USER_PROFILE);
const editUserProfile = createAction(types.EDIT_USER_PROFILE);

const showMessage = createAction(types.MESSAGE,
  ({ message }) => ({
    message,
  }),
  ({ config }) => ({
    config,
  }));

export default {
  startFetch,
  endFetch,
  showMessage,
  updateUserProfile,
  fetchUserProfile,
  editUserProfile,
};