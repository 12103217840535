/**
 * Created by Jonas on 02/09/19.
 */

import React from 'react';
import Panel from '../Panel';
import Form from '../Form';
import TextInput from '../TextInput';
import SelectSingle from '../SelectSingle';

const UserForm = ({
                    t,
                    onSubmit,
                    handleInputChange,
                    handleSelectChange,
                    name,
                    lastName,
                    documentTypesList,
                    documentNumber,
                    documentType,
                    email,
                    title,
                    edit,
                    hidden,
                    position,
                    roles,
                    roleType,
                    roleList,
                    handleItemChange,
                    isAcquiring,
                    role,
                    typeUser,
                    required,
                    phoneNumber,
                    collaborator_number,
                    disabled,
                    handleInputChangeTypeDocument,
                    typePerson,
                    cancelSave,
                  }) => (
  <Panel headingText={title}>
    <Form
      submitText={t('save')}
      onSubmit={(event) => {
        event.preventDefault();
        onSubmit();
      }}
      buttonClasses="mr-8px is-hidden"
    >
      <div className="field is-horizontal">
        <div className="field-body columns">
          <div className="column">
            <TextInput
              name="name"
              label={t('name')}
              value={name}
              onChange={handleInputChange}
              // placeholder={t('name')}
              placeholder={t('firstAndSecondName')}
              type="text"
              controlClasses="is-expanded"
              //isRequired={required}
              //disabled={edit}
              isRequired={!edit}
            />
          </div>
          <div className="column">
            <TextInput
              name="lastName"
              label={t('adminLastname')}
              value={lastName}
              onChange={handleInputChange}
              // placeholder={t('adminLastname')}
              placeholder={t('lastName')}
              type="text"
              controlClasses="is-expanded"
              //isRequired={required}
              //disabled={edit}
              isRequired={!edit}
            />
          </div>
          {/* <div className="column">
            <TextInput
              name="phoneNumber"
              label={t('phone')}
              value={phoneNumber}
              onChange={handleInputChange}
              placeholder={t('phone')}
              type="text"
              controlClasses="is-expanded"
              inputClasses="has-text-right"
              maxlength={12}
              //disabled={edit}
              isRequired={!edit}
            />
          </div> */}
          <div className="column" style={{ display: 'none' }}>
            <SelectSingle
              name="documentType"
              label={t('documentType')}
              selectClasses="is-fullwidth"
              placeholder={t('Select')}
              onChange={handleSelectChange}
              options={[
                ...documentTypesList.map((item, index) => ({
                  label: item.document_type,
                  value: index,
                })),
              ]}
              value={documentType}
              //isDisabled={hidden ? hidden : edit}
            />
          </div>
          <div className="column" style={{ display: 'none' }}>
            <TextInput
              name="documentNumber"
              label={t('documentNumber')}
              value={documentNumber}
              onChange={handleInputChange}
              placeholder={t('documentNumber')}
              type="text"
              controlClasses="is-expanded"
              inputClasses="has-text-right"
              maxlength={13}
              //isRequired={required}
              //disabled={edit}
              //isRequired={typeUser === 'main_bank_issuing' ? edit : !edit}
            />
          </div>
        </div>
      </div>
      {/* {(
        <div className="field is-horizontal">
          <div className="field-body columns">
            <div className="column is-two-third">
              <label><strong>{` ${t('person')}`}</strong></label>
              <br/>
              <input
                name="typePerson"
                value={'person'}
                onChange={handleInputChangeTypeDocument}
                type="radio"
                checked={typePerson === 'person'}
              />
              {` ${t('physical')}`}
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <input
                name="typePerson"
                value={'legal'}
                onChange={handleInputChangeTypeDocument}
                type="radio"
                checked={typePerson === 'legal'}
              />
              {` ${t('legal')}`}
            </div>
            <div className="column">
              <SelectSingle
                name="documentType"
                label={t('documentType')}
                selectClasses="is-fullwidth"
                placeholder={t('Select')}
                onChange={handleSelectChange}
                options={[
                  ...documentTypesList.map((item, index) => ({
                    label: item.document_type,
                    value: index,
                  })),
                ]}
                value={documentType}
                //isDisabled={hidden ? hidden : edit}
              />
            </div>
            <div className="column">
              <TextInput
                name="documentNumber"
                label={t('documentNumber')}
                value={documentNumber}
                onChange={handleInputChange}
                placeholder={t('documentNumber')}
                type="text"
                controlClasses="is-expanded"
                inputClasses="has-text-right"
                maxlength={13}
                //disabled={edit}
                //isRequired={true}
              />
            </div>
          </div>
        </div>
      )} */}
      <div className="field is-horizontal">
        <div className="field-body columns">
          <div className="column">
            <TextInput
              name="adminPosition"
              label={t('adminPosition')}
              value={position}
              onChange={handleInputChange}
              placeholder={t('adminPositionPlaceholder')}
              type="text"
              controlClasses="is-expanded"
              //isRequired={required}
              //disabled={edit}
              isRequired={!edit}
            />
          </div>
          <div className="column">
            {/*<CreatableMultiSelect
              isMulti={false}
              name="roleType"
              label={t('rol')}
              controlClasses="is-expanded"
              value={roles}
              onChange={handleItemChange}
              placeholder={t('selectOrTypeRol')}
              noOptionsMessage={() => t('noItemsAvailable')}
              //formatCreateLabel={inputValue => t('createNewItem', { inputValue })}
              getOptionLabel={option => option.label_type}
              getOptionValue={option => option.id}
              getNewOptionData={(inputValue, optionLabel) => ({
                id: optionLabel,
                name: optionLabel,
              })
              }
              options={roleList}
            />*/}
            <SelectSingle
              name="role"
              label={t('rol')}
              selectClasses="is-fullwidth"
              placeholder={t('Select')}
              onChange={handleSelectChange}
              options={[
                ...roleList.map((item, index) => ({
                  label: typeUser === 'main_bank_acquiring' || typeUser === 'main_bank_issuing' ? `${t(item.label_type)} ` : `${t(item.name)} `,
                  value: index,//item.id,
                })),
              ]}
              value={role}
              //isRequired={required}
              //isDisabled={hidden ? hidden : edit}
              isRequired={!edit}
            />
          </div>
          <div className="column" style={{ display: 'none' }}>
            <TextInput
              name="collaborator_number"
              label={t('collaboratorNumber')}
              value={collaborator_number}
              onChange={handleInputChange}
              placeholder={t('collaboratorNumber')}
              type="text"
              maxlength={8}
              controlClasses="is-expanded"
              disabled={disabled}
              //isRequired={required}
              isRequired={!disabled}
            />
          </div>
          <div className="column">
            <TextInput
              name="email"
              label={t('email')}
              value={email}
              onChange={handleInputChange}
              placeholder={t('emailExample')}
              type="email"
              //pattern=".+@baccredomatic\.com"
              //title="Formato solicitado correos que pertenezcan a @baccredomatic.com"
              controlClasses="is-expanded"
              //isRequired={required}
              isRequired={!edit}
            />
          </div>
        </div>
      </div>
      <div className="field is-horizontal" 
           style={{ display: !edit ? '' : 'none' }}>
        <label>{t('requiredFields')}</label>
      </div>
      <div className="field is-grouped is-grouped-centered"
           style={{ width: '50%', margin: '30px auto' }}>
        <button
          type="button"
          className="button custom-btn is-danger"
          style={{ color: '#C4C4C4', flex: '1', maxWidth: '200px', margin: '0 10px' }}
          onClick={cancelSave}
        >
          {t('cancel')}
        </button>
        <button
          type="button"
          className="button custom-btn is-primary"
          style={{ flex: '1', maxWidth: '200px', margin: '0 10px' }}
          onClick={onSubmit.bind(this)}
        >
          {t('save')}
        </button>
      </div>
    </Form>
  </Panel>
);

UserForm.propTypes = {
  /*t: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  handleInputChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  handleSelectChange: PropTypes.func.isRequired,
  documentTypesList: PropTypes.arrayOf(PropTypes.object).isRequired,
  documentNumber: PropTypes.string.isRequired,
  handleItemChange: PropTypes.func.isRequired,
  itemList: PropTypes.arrayOf(PropTypes.object).isRequired,
  email: PropTypes.string.isRequired,
  documentType: PropTypes.number.isRequired,
  roleType: PropTypes.number,
  roleList: PropTypes.arrayOf(PropTypes.object).isRequired,*/
};

UserForm.defaultProps = {
  discountRate: 0,
  client: false,
  adminName: '',
  adminLastname: '',
  adminPosition: '',
  required: false,
};

export default UserForm;
