/**
 * Created by Feedback Software on 25/10/18.
 * @param children contain the body of this table, it's important use th or td inside tr
 * @param totalPages if is pass by params it means that must be added the pagination nav
 * @param title it is used by pass by params to the Panel for the title of this
 * @param head contain the head of the table, it's important pass th inside tr
 * @param currentPage manage de actual page in pagination
 * @param onChangePage manage the change of the page in the pagination, must change currentPage
 * @returns {*}
 */

import React, { Component } from 'react';
import './styles.scss';
import { withNamespaces } from 'react-i18next';
import PropTypes from 'prop-types';
import { Panel } from '../index';
import { NavPagination } from './components';

class TableList extends Component {
  state = {
    totalPages: 1,
    currentPage: 0,
  };

  componentDidMount() {
    const { totalElements } = this.props;
    const { totalPages } = this.state;

    const ceil = Math.ceil(totalElements / 10);

    if (totalPages !== ceil) {
      this.setState(() => ({
        totalPages: ceil,
      }));
    }
  }

  onChangePage = (currentPage) => {
    const { onChangePage } = this.props;
    const { currentPage: previousPage } = this.state;
    if (currentPage !== previousPage) {
      this.setState(() => ({
        currentPage,
      }), () => {
        onChangePage(currentPage);
      });
    }
  };

  render() {
    const {
      children, title, head,
    } = this.props;

    const { totalPages, currentPage } = this.state;
    return (
      <div className="columns List">
        <div className="column is-full">
          <Panel headingText={title}>
            <table className="table">
              {!!head && (
                <thead>
                  {head}
                </thead>
              )}
              <tbody>
                {children}
              </tbody>
            </table>
            {totalPages > 0 && (
              <NavPagination
                currentPage={currentPage}
                totalPages={totalPages}
                onChangePage={this.onChangePage}
              />
            )}
          </Panel>
        </div>
      </div>
    );
  }
}

TableList.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.array,
  ]).isRequired,
  title: PropTypes.string,
  head: PropTypes.element,
  totalElements: PropTypes.number,
  onChangePage: PropTypes.func,
};

TableList.defaultProps = {
  totalElements: 0,
  title: '',
  head: null,
  onChangePage: () => { },
};

export default withNamespaces()(TableList);
