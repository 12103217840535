import React, { Component, Fragment } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import PropTypes from 'prop-types';
import { Loading, Panel, TableSimple, Toggle, ToggleBTN, } from '../../components';
import { roleActions } from '../../state/ducks/role';
import { authActions } from '../../state/ducks/auth';
import Modal from '../../components/Modal';
import { TOAST_CONFIG } from '../../config/constants';
import Form from '../../components/Form';
import TextArea from '../../components/TextArea';
import { paymentConditionsActions } from '../../state/ducks/paymentConditions';
import { statsActions } from '../../state/ducks/stats';
import './styles.scss';
import { providersActions } from '../../state/ducks/providers';
import PaymentConditionForm from '../../components/PaymentConditionForm';
import SelectSingle from '../../components/SelectSingle';

import { TextInput } from '../../components';
import { formatAmount } from '../../util';

class Request extends Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  getInitialState = () => ({
    id: '',
    name: '',
    roleType: '',
    conditionList: [],
    reject: false,
    is_edit: false,
    startCountingFrom: -1,
    baseDays: 0,
    discountRate: 0,
    receiverDiscountShare: 0,
    clientPayment: 0,
    commerceCode: -1,
    codeDescription: '',
    codes: [],
    idCondition: '',
    getInfo: false,
    request: -1,
    rejectReason: '',
    rejectStep: 0,
    rejectBTN: '',
  });

  componentDidMount() {
    const {
      router: { location: { pathname } },
      requestBank,
      providerCommerceCode,
      auth: {
        user: {
          b2b: { id, type_user, roles },
          profile: { role },
        },
      },
    } = this.props;

   /* if(pathname === '/payment-request-rejected'){
      requestBank({
        params: {
          idBank: id,
          rejected: true,
          receiver: false,
        },
        callback: (response) => {
          this.setState({
            conditionList: response,
          });
        }
      });
    } else {
      providerCommerceCode({
        params: {
          user: id,
          role: roles[0].id,
        },
        callback: (response) => {
          this.setState({
            codes: response.commerce_codes,
          });
          requestBank({
            params: {
              idBank: id,
              receiver: pathname === '/payment-request-sent' ? false : true,
            },
            callback: (response) => {
              this.setState({
                conditionList: response,
              });
            }
          });
        },
      });
    }*/
  }

  resetState = () => {
    this.setState(this.getInitialState());
  };

  getGridSettings = (onToggle) => {
    let columns;
    let data;
    let title;
    const {
      codes,
      conditionList,
      request,
    } = this.state;
    const {
      t,
      auth: {
        user: {
          b2b: { type_user, id },
          profile: { role },
        },
      },
      providerCommerceCode,
      deletePaymentCondition,
      fetchStats,
      paymentRequest,
      requestBank,
      showMessage,
      approveProductPayment,
      unsuscribeProductPayment,
      getInfoPaymentCondition,
      checkPaymentRequest,
      paymentConditions: { list, listReceiver },
      router: { location: { pathname } },
    } = this.props;

    /*let role_id = '';
    for (let i = 0; i < list.length; i++) {
      if (list[i].name === 'seller') {
        role_id = list[i].id;
      }
    }*/

    switch (request) {
      case 0://'/payment-request-sent':
        columns = [
          {
            expander: true,
            show: false,
          },
          {
            id: 'paymentType', // Required because our accessor is not a string
            Header: t('paymentType'),
            show: (role === 'buyer' || role === 'seller') ? false : true,
            headerClassName: 'wordwrap',
            accessor: d => (role === 'buyer' || role === 'seller') ? null : `${d.condition_type} `,
          },
          {
            id: 'commission', // Required because our accessor is not a string
            Header: t('commission'),
            show: (role === 'buyer' || role === 'seller') ? false : true,
            headerClassName: 'wordwrap',
            accessor: d => (role === 'buyer' || role === 'seller') ? null : d.condition_type === "TDC" || "null" ? "" : `${d.ncl_commission} `,
          },
          {
            id: 'applicantBank', // Required because our accessor is not a string
            Header: t('applicantBank'),
            show: (role === 'buyer' || role === 'seller') ? false : true,
            headerClassName: 'wordwrap',
            accessor: d => (role === 'buyer' || role === 'seller') ? null : `${d.bankReceiver.idPerson.name} `,
          },
          {
            id: 'person_commerce_code', // Required because our accessor is not a string
            Header: t('affiliationNumber'),
            show: (role === 'seller') ? true : false,
            headerClassName: 'wordwrap',
            accessor: d => (role === 'seller' && d.person_commerce_code !== null) ?
              codes[codes.findIndex(function (code) {
                return code.id === d.person_commerce_code;
              })].commerce_code : '',
          },
          {
            id: 'customer', // Required because our accessor is not a string
            Header: t('customer'),
            show: (role === 'buyer') ? false : true,
            headerClassName: 'wordwrap',
            accessor: d => (role === 'buyer') ? null : `${d.sender.idPerson.name} `,
          },
          {
            id: 'provider', // Required because our accessor is not a string
            Header: t('provider'),
            show: (role === 'seller') ? false : true,
            headerClassName: 'wordwrap',
            accessor: d => (role === 'seller') ? null : `${d.receiver.idPerson.name} `,
          },
          {
            id: 'commercialCreditDays', // Required because our accessor is not a string
            Header: t('commercialCreditDays'),
            headerClassName: 'wordwrap',
            accessor: d => `${d.days} `,
          },
          {
            id: 'startCountingFrom', // Required because our accessor is not a string
            Header: t('commercialCreditSince'),
            headerClassName: 'wordwrap',
            accessor: d => ((d.due_from_approval === '1') ? t('approvalDate') : t('receptionDate')),
          },
          /*{
            id: 'limit', // Required because our accessor is not a string
            Header: t('limit'),
            accessor: d => `${formatCurrencyCl(d.limit_amount)} `,
          },*/
          /*{
            id: 'discountRate', // Required because our accessor is not a string
            Header: ((type_user === 'acquiring_bank') || (type_user === 'issuing_bank'))  ? t('mdr') : type_user === 'seller' ? t('chargeForService') : t('chargeForService'),
            headerClassName: 'wordwrap',
            accessor: d => `${d.discount_rate} %`,
          },
          {
            id: 'percentage', // Required because our accessor is not a string
            Header: t('clientPercentageCharge'),
            headerClassName: 'wordwrap',
            accessor: d => `${d.percentage.slice(0, -4)} %`,
          },*/
          {
            id: 'status', // Required because our accessor is not a string
            Header: t('status'),
            accessor: d => `${d.status.description} `,
          },
          {
            id: 'action', // Required because our accessor is not a string
            Header: t('action'),
            filterable: false,
            headerClassName: 'wordwrap',
            className: 'buttons-col', /* TableSimple.css */
            width: 150,
            // show: (role === 'issuing_bank') ? false : true,
            accessor: d => `${d.id} `,
            Cell: cellInfo => (
              <table>
                <tbody>
                <tr>
                  <td>
                    {/* <button
                      className="button is-danger is-inverted"
                      onClick={() => {
                        deletePaymentCondition({
                          data: {
                            id: cellInfo.original.id,
                            is_request: true,
                          },
                          callback: (resp) => {
                            requestBank({
                              params: {
                                idBank: id,
                              },
                              callback: (response) => {
                                this.setState({
                                  conditionList: response,
                                });
                              }
                            });
                            if (resp.message) {
                              showMessage({
                                message: resp.message,
                                config: TOAST_CONFIG.SUCCESS,
                              });
                            } else {
                              showMessage({
                                message: t('actionNotAllowed'),
                                config: TOAST_CONFIG.ERROR,
                              });
                            }
                          },
                        });
                      }}
                    >
                      <i className="fas fa-times mr-8px" aria-hidden="true"/>
                      {t('delete')}
                    </button> */}
                    <button 
                      className="button is-primary is-inverted"
                      onClick={() => {
                        providerCommerceCode({
                          params: {
                            user: cellInfo.original.receiver_id,
                            role: 4,//role_id,
                          },
                          callback: (response) => {
                            const { 
                              discount_rate, 
                              days, 
                              due_from_approval, 
                              receiver_discount_share, 
                              percentage
                            } = cellInfo.original;
                            this.setState({
                              discountRate: discount_rate,
                              baseDays: days,
                              startCountingFrom: due_from_approval,
                              receiverDiscountShare: receiver_discount_share,
                              clientPayment: percentage,
                            })
                            onToggle({
                              checkRequest: true,
                              data: {
                                ... cellInfo.original,
                                codes: response.commerce_codes,
                                commerceCode: response.commerce_codes.length > 0 ? response.commerce_codes.findIndex(function (code) {
                                  return code.id === cellInfo.original.person_commerce_code;
                                }) : '',
                                codeDescription: response.commerce_codes.length > 0 ? response.commerce_codes[response.commerce_codes.findIndex(function (code) {
                                  return code.id === cellInfo.original.person_commerce_code;
                                })].description : '',
                              }
                            });
                          }
                        });
                      }}>
                        <i className="fas fa-eye"/>
                    </button>
                  </td>
                </tr>
                </tbody>
              </table>
            ),
          },
        ];
        data = request === -1 ? [] : conditionList;
        title = type_user === 'seller' ? t('requestsSent') : t('requestSent');
        break;
      case 1://'/payment-request-received':
        columns = [
          {
            expander: true,
            show: false,
          },
          {
            id: 'paymentType', // Required because our accessor is not a string
            Header: t('paymentType'),
            show: (role === 'buyer' || role === 'seller') ? false : true,
            headerClassName: 'wordwrap',
            accessor: d => (role === 'buyer' || role === 'seller') ? null : `${d.condition_type} `,
          },
          {
            id: 'commission', // Required because our accessor is not a string
            Header: t('commission'),
            show: (role === 'buyer' || role === 'seller') ? false : true,
            headerClassName: 'wordwrap',
            accessor: d => (role === 'buyer' || role === 'seller') ? null : d.condition_type === "TDC" || "null" ? "" : `${d.ncl_commission} `,
          },
          {
            id: 'applicantBank', // Required because our accessor is not a string
            Header: t('applicantBank'),
            show: (role === 'buyer' || role === 'seller' || role == 'seller_assist_card') ? false : true,
            headerClassName: 'wordwrap',
            accessor: d => (role === 'buyer' || role === 'seller' || role == 'seller_assist_card') ? null : `${d.bankSender.idPerson.name} `,
          },
          {
            id: 'person_commerce_code', // Required because our accessor is not a string
            Header: t('affiliationNumber'),
            show: (role === 'seller'),
            headerClassName: 'wordwrap',
            accessor: d => ((role === 'seller') && d.person_commerce_code !== null) ?
              codes[codes.findIndex(function (code) {
                return code.id === d.person_commerce_code;
              })].commerce_code : '',
          },
          {
            id: 'customer', // Required because our accessor is not a string
            Header: t('customer'),
            show: (role === 'buyer') ? false : true,
            headerClassName: 'wordwrap',
            accessor: d => (role === 'buyer') ? null : `${d.sender.idPerson.name} `,
          },
          {
            id: 'provider', // Required because our accessor is not a string
            Header: t('provider'),
            show: (role === 'seller' || role === 'seller_assist_card') ? false : true,
            headerClassName: 'wordwrap',
            accessor: d => (role === 'seller' || role === 'seller_assist_card') ? null : `${d.receiver.idPerson.name} `,
          },
          {
            id: 'rec_reason', // Required because our accessor is not a string
            Header: t('rejectReason'),
            show: (role !== 'seller_assist_card'),
            headerClassName: 'wordwrap',
            accessor: d => d.rec_reason !== null ? `${d.rec_reason}` : '',
          },
          {
            id: 'commercialCreditDays', // Required because our accessor is not a string
            Header: t('commercialCreditDays'),
            show: (role === 'seller_assist_card') ? false : true,
            headerClassName: 'wordwrap',
            accessor: d => `${d.days} `,
          },
          {
            id: 'startCountingFrom', // Required because our accessor is not a string
            Header: t('commercialCreditSince'),
            show: (role === 'seller_assist_card') ? false : true,
            headerClassName: 'wordwrap',
            accessor: d => ((d.due_from_approval === '1') ? t('approvalDate') : t('receptionDate')),
          },
          /*{
            id: 'limit', // Required because our accessor is not a string
            Header: t('limit'),
            show: (role == 'seller_assist_card') ? false : true,
            accessor: d => `${formatCurrencyCl(d.limit_amount)} `,
          },
          {
            id: 'discountRate', // Required because our accessor is not a string
            Header: ((type_user === 'acquiring_bank') || (type_user === 'issuing_bank')) ? t('mdr') : type_user === 'seller' ? t('chargeForService') : t('chargeForService'),
            show: (role === 'seller_assist_card') ? false : true,
            headerClassName: 'wordwrap',
            accessor: d => `${d.discount_rate} %`,
          },
          {
            id: 'customerPayment', // Required because our accessor is not a string
            Header: t('clientPercentageCharge'),
            show: (role === 'seller_assist_card') ? false : true,
            headerClassName: 'wordwrap',
            accessor: d => `${d.percentage.slice(0, -4)} %`,
          },*/
          {
            id: 'product', // Required because our accessor is not a string
            Header: t('product'),
            show: (role === 'seller_assist_card') ? true : false,
            headerClassName: 'wordwrap',
            accessor: d => (role === 'seller_assist_card') ? `${d.product.name}` : '',
          },
          {
            id: 'productType', // Required because our accessor is not a string
            Header: t('productType'),
            show: (role === 'seller_assist_card') ? true : false,
            headerClassName: 'wordwrap',
            accessor: d => (d.request_unsubscribe_product === 0) ? `${t('contractRequest')}` : `${t('unsuscribeRequest')}`,
          },
          {
            id: 'action', // Required because our accessor is not a string
            Header: t('action'),
            filterable: false,
            headerClassName: 'wordwrap',
            className: 'buttons-col', /* TableSimple.css */
            width: 150,
            accessor: d => `${d.id} `,
            Cell: cellInfo => (
              <table>
                <tbody>
                <tr>
                  {/* <td style={{ textAlign: 'center' }}>
                    <button
                      className="button is-danger is-inverted"
                      onClick={() => {
                        if (role !== 'seller_assist_card') {
                          providerCommerceCode({
                            params: {
                              user: cellInfo.original.receiver_id,
                              role: 4,//role_id,
                            },
                            callback: (response) => {
                              this.setState({
                                reject: false,
                                is_edit: false,
                                rejectReason: '',
                              });
                              onToggle({
                                codes: response.commerce_codes,
                                commerceCode: response.commerce_codes.length > 0 ? response.commerce_codes.findIndex(function (code) {
                                  return code.id === cellInfo.original.person_commerce_code;
                                }) : '',
                                codeDescription: response.commerce_codes.length > 0 ? response.commerce_codes[response.commerce_codes.findIndex(function (code) {
                                  return code.id === cellInfo.original.person_commerce_code;
                                })].description : '',
                                transaction: cellInfo.original.id,
                                infoPayment: cellInfo.original,
                                onSubmit: this.rejectInvoice
                              });
                            }
                          });
                        } else {
                          if (cellInfo.original.request_unsubscribe_product == 0) {
                            approveProductPayment({
                              data: {
                                id: cellInfo.original.id,
                                authorized_by: id,
                                approved: false,
                              },
                              callback: () => {
                                fetchStats({});
                                showMessage({
                                  message: t('applicationRejected'),
                                  config: TOAST_CONFIG.SUCCESS,
                                });
                                //this.resetState();
                                requestBank({
                                  params: {
                                    idBank: id,
                                    receiver: request === 1,
                                    is_bank: false,
                                  },
                                  callback: (response) => {
                                    this.setState({
                                      conditionList: response,
                                    });
                                  }
                                });
                                //window.location.reload(false);
                              }
                            });
                          }else{
                            onToggle({
                              product: cellInfo.original.id,
                              onSubmit: this.rejectInvoice,
                            });
                          }
                        }
                      }}
                    >
                      <span className="icon" title={t('reject')}>
                        <i className="fas fa-times"/>
                      </span>
                    </button>
                  </td>
                  <td style={{ textAlign: 'center' }}>
                    <button
                      className="button is-primary is-inverted"
                      onClick={() => {
                        if(role !== 'seller_assist_card'){
                          paymentRequest({
                            data: {
                              id: cellInfo.original.id,
                              approved: true,
                              authorized_by: id,
                              reason: '',
                            },
                            callback: (resp) => {
                              requestBank({
                                params: {
                                  idBank: id,
                                  receiver: request === 1,
                                },
                                callback: (response) => {
                                  this.setState({
                                    conditionList: response,
                                  });
                                }
                              });
                              if (resp) {
                                showMessage({
                                  message: t('applicationSuccess'),
                                  config: TOAST_CONFIG.SUCCESS,
                                });
                                fetchStats({});
                              } else {
                                showMessage({
                                  message: t('actionNotAllowed'),
                                  config: TOAST_CONFIG.ERROR,
                                });
                              }
                              //onToggle();
                            },
                          });
                          //this.rejectInvoice('approve', cellInfo.original.id, '', onToggle);
                        }else{
                          if(cellInfo.original.request_unsubscribe_product == 0){
                            approveProductPayment({
                              data: {
                                id: cellInfo.original.id,
                                authorized_by: id,
                                approved: true,
                              },
                              callback: () => {
                                fetchStats({});
                                showMessage({
                                  message: t('applicationSuccess'),
                                  config: TOAST_CONFIG.SUCCESS,
                                });
                                requestBank({
                                  params: {
                                    idBank: id,
                                    receiver: request === 1,
                                    is_bank: false,
                                  },
                                  callback: (response) => {
                                    this.setState({
                                      conditionList: response,
                                    });
                                  }
                                });
                                /*this.resetState();
                                window.location.reload(false);//cerrar comentario aquí
                              }
                            });
                          }else{
                            unsuscribeProductPayment({
                              data: {
                                id: cellInfo.original.id,
                                approved: true,
                                authorized_by: id,
                                rec_reason: '',
                              },
                              callback: (resp) => {
                                fetchStats({});
                                showMessage({
                                  message: t('productUnsubscribedSuccessfully'),
                                  config: TOAST_CONFIG.SUCCESS,
                                });
                                requestBank({
                                  params: {
                                    idBank: id,
                                    receiver: request === 1,
                                    is_bank: false,
                                  },
                                  callback: (response) => {
                                    this.setState({
                                      conditionList: response,
                                    });
                                  }
                                });
                                /*this.resetState();
                                window.location.reload(false); //cerrar comentario aquí
                              }
                            })
                          }
                        }

                      }}
                    >
                      <span className="icon" title={t('approve')}>
                        <i className="fas fa-check"/>
                      </span>
                    </button>
                  </td> */}
                  <td>
                    <button 
                      className="button is-primary is-inverted"
                      onClick={() => {
                        providerCommerceCode({
                          params: {
                            user: cellInfo.original.receiver_id,
                            role: 4,//role_id,
                          },
                          callback: (response) => {
                            const { 
                              discount_rate, 
                              days, 
                              due_from_approval, 
                              receiver_discount_share, 
                              percentage
                            } = cellInfo.original;
                            this.setState({
                              discountRate: discount_rate,
                              baseDays: days,
                              startCountingFrom: due_from_approval,
                              receiverDiscountShare: receiver_discount_share,
                              clientPayment: percentage,
                            })
                            onToggle({
                              checkRequest: true,
                              data: {
                                ... cellInfo.original,
                                codes: response.commerce_codes,
                                commerceCode: response.commerce_codes.length > 0 ? response.commerce_codes.findIndex(function (code) {
                                  return code.id === cellInfo.original.person_commerce_code;
                                }) : '',
                                codeDescription: response.commerce_codes.length > 0 ? response.commerce_codes[response.commerce_codes.findIndex(function (code) {
                                  return code.id === cellInfo.original.person_commerce_code;
                                })].description : '',
                              }
                            });
                          }
                        });
                      }}>
                        <i className="fas fa-eye"/>
                    </button>
                  </td>
                </tr>
                </tbody>
              </table>
            ),
          },
        ];
        data = request === -1 ? [] : conditionList;
        title = t('requestsReceived');//type_user === 'seller' ? t('requestsReceived') : t('requestReceived');
        break;
      case 2://'/payment-request-rejected':
        columns = [
          {
            expander: true,
            show: false,
          },
          {
            id: 'paymentType', // Required because our accessor is not a string
            Header: t('paymentType'),
            show: (role === 'buyer' || role === 'seller') ? false : true,
            headerClassName: 'wordwrap',
            accessor: d => (role === 'buyer' || role === 'seller') ? null : `${d.condition_type} `,
          },
          {
            id: 'commission', // Required because our accessor is not a string
            Header: t('commission'),
            show: (role === 'buyer' || role === 'seller') ? false : true,
            headerClassName: 'wordwrap',
            accessor: d => (role === 'buyer' || role === 'seller') ? null : d.condition_type === "TDC" || "null" ? "" : `${d.ncl_commission} `,
          },
          {
            id: 'provider', // Required because our accessor is not a string
            Header: t('provider'),
            show: (role === 'seller') ? false : true,
            headerClassName: 'wordwrap',
            accessor: d => (role === 'seller') ? null : `${d.receiver.idPerson.name} `,
          },
          {
            id: 'commercialCreditDays', // Required because our accessor is not a string
            Header: t('commercialCreditDays'),
            headerClassName: 'wordwrap',
            accessor: d => `${d.days} `,
          },
          {
            id: 'commercialCreditSince', // Required because our accessor is not a string
            Header: t('commercialCreditSince'),
            headerClassName: 'wordwrap',
            accessor: d => ((d.due_from_approval === '1') ? t('approvalDate') : t('receptionDate')),
          },
          {
            id: 'status', // Required because our accessor is not a string
            Header: t('status'),
            accessor: d => `${d.status.description} `,
          },
          {
            id: 'action', // Required because our accessor is not a string
            Header: t('action'),
            filterable: false,
            headerClassName: 'wordwrap',
            accessor: d => `${d.id} `,
            Cell: cellInfo => (
              <table>
                <tbody>
                <tr>
                  <td>
                    <button
                      className="button is-primary is-inverted"
                      onClick={() => {
                        getInfoPaymentCondition({
                          params: { condition: cellInfo.original.id },
                          callback: (resp) => this.setState({
                            getInfo: true,
                            is_edit: false,
                          }, () => {
                            checkPaymentRequest({
                              data:{ id: cellInfo.original.id },
                              callback: () =>{
                                requestBank({
                                  params: {
                                    idBank: id,
                                    rejected: true,
                                    receiver: false,
                                  },
                                  callback: (response) => {
                                    this.setState({
                                      conditionList: response,
                                    });
                                  }
                                });
                              }
                            })
                            onToggle({
                              paymentCondition: resp,
                              onSubmit: this.closeModal,
                            });
                          }),
                        });
                      }}
                    >
                      <span className="icon" title={t('detail')}>
                        <i className="far fa-eye" aria-hidden="true"/>
                      </span>
                    </button>
                  </td>
                </tr>
                </tbody>
              </table>
            ),
          },
        ];
        data = request === -1 ? [] : conditionList;
        title = t('requestRejected');
        break;
      case -1:
        columns = [];
        data = [];
        title = t('request');
        break;
      default:
        columns = [];
        data = [];
        title = '';
    }
    return {
      columns,
      data,
      title,
    };
  };

  approveRequest = (requestData) => {
    const {
      // codes,
      // conditionList,
      request,
      discountRate,
    } = this.state;
    const {
      t,
      auth: {
        user: {
          b2b: { /*type_user,*/ id },
          profile: { role },
        },
      },
      // providerCommerceCode,
      // deletePaymentCondition,
      fetchStats,
      paymentRequest,
      requestBank,
      showMessage,
      approveProductPayment,
      unsuscribeProductPayment,
      // getInfoPaymentCondition,
      // checkPaymentRequest,
      // paymentConditions: { list, listReceiver },
      // router: { location: { pathname } },
    } = this.props;

    paymentRequest({
      data: {
        id: requestData.id,
        approved: true,
        authorized_by: id,
        reason: '',
        discount_rate: discountRate,
      },
      callback: (resp) => {
        requestBank({
          params: {
            idBank: id,
            receiver: request === 1,
          },
          callback: (response) => {
            this.setState({
              conditionList: response,
            });
          }
        });
        if (resp) {
          showMessage({
            message: t('applicationSuccess'),
            config: TOAST_CONFIG.SUCCESS,
          });
          fetchStats({});
        } else {
          showMessage({
            message: t('actionNotAllowed'),
            config: TOAST_CONFIG.ERROR,
          });
        }
        //onToggle();
      },
    });
  }

  handleInputChange = (event) => {
    const {
      target: {
        checked, value: targetValue, type, name,
      },
    } = event;
    const value = type === 'checkbox' ? checked : targetValue;
    if (name === 'receiverDiscountShare') {
      let percentage = 100 - value;
      this.setState({
        [name]: (value),
        clientPayment: percentage,
      });
    } else {
      this.setState({
        [name]: value,
      });
    }
  };

  rejectInvoice = (type, /*transaction,*/ requestData, onToggle) => {
    const {
      t,
      fetchStats,
      showMessage,
      paymentRequest,
      requestBank,
      auth: {
        user: {
          b2b: { id },
          profile: { role },
        },
      },
      unsuscribeProductPayment,
      history,
    } = this.props;
    const {
      request,
      rejectReason,
      discountRate,
      clientPayment,
      startCountingFrom,
      baseDays,
    } = this.state;
    const {
      relation_id,
      sender: { idPerson: { document_number: sender } },
      receiver: { idPerson: { document_number: receiver } },
      codes,
      commerceCode,
      id: requestID,
    } = requestData;

    let data;

    if ( type === 'reject') {
      data = {
        id: requestID,
        approved: false,
        reason: rejectReason,
        authorized_by: id,
      }
    } else {
      data = {
        id: requestID,
        approved: false,
        reason: rejectReason,
        authorized_by: id,
        suggest_edit: {
          id: relation_id ? relation_id : "",//relation_id de la solicitud comercial a rechazar
          sender,//RFC del cliente,
          receiver,//RFC del proveedor,
          days: baseDays,//días sugeridos en el rechazo,
          dueFromApproval: startCountingFrom,//1/0,
          commerceCode: codes[commerceCode].commerce_code ? codes[commerceCode].commerce_code : "",//código de comercio del proveedor,
          discountRate: discountRate,//valor de la tasa de descuento,
          percentageReceiver: clientPayment,//porcentaje a pagar por el cliente
        },
      }
    }
    if (rejectReason !== '') {
      paymentRequest({
        data,
        callback: (resp) => {
          requestBank({
            params: {
              idBank: id,
              receiver: true
            },
            callback: (response) => {
              this.setState({
                conditionList: response,
              });
            }
          });
          if (resp) {
            fetchStats({});
            onToggle();
            showMessage({
              message: t('applicationRejected'),
              config: TOAST_CONFIG.SUCCESS,
            });
            history.push('/dashboard');
            // this.setState({
            //   rejectReason: '',
            //   reject: false,
            //   is_edit: false,
            // });
            //this.setState({ rejectReason: '' });
          } else {
            showMessage({
              message: t('actionNotAllowed'),
              config: TOAST_CONFIG.ERROR,
            });
          }
          onToggle();
        },
      });
    } else {
      showMessage({
        message: <strong style={{ color: '#ffffff' }}>{t('emptyFields')}</strong>,
        config: TOAST_CONFIG.ERROR,
      });
    };
  };

  saveRequest = (onToggle, transaction) => {
    const {
      auth: {
        user: {
          user_id, b2b: { type_user, id },
        },
      },
      t,
      showMessage,
      requestPaymentCondition,
      paymentRequest,
      fetchStats,
      requestBank,
    } = this.props;

    const {
      //id,
      baseDays,
      startCountingFrom,
      //limitAmount,
      discountRate,
      receiverDiscountShare,
      clientPayment,
      rejectReason,
      commerceCode,
      codes,
      request,
    } = this.state;

    const data = {
      id: transaction.id,
      approved: false,
      authorized_by: id,
      reason: rejectReason,
    };

    const conditionData = {
      bank_sender_id: (type_user === 'issuing_bank' || type_user === 'acquiring_bank') ? true : null,
      sender_request_id: null,
      sender_id: (type_user === 'issuing_bank' || type_user === 'acquiring_bank') ? transaction.sender_id :
        type_user === 'seller' ? transaction.sender_id : user_id,
      receiver_id: (type_user === 'issuing_bank' || type_user === 'acquiring_bank') ? transaction.receiver_id :
        type_user === 'seller' ? user_id : transaction.receiver_id,
      days: baseDays,
      due_from_approval: startCountingFrom,
      discount_rate: discountRate,
      percentage: clientPayment,//percentage,
      receiver_discount_share: receiverDiscountShare / 100,
      rec_reason: rejectReason,
      person_commerce_code: codes[commerceCode].id,
      type: 'base',
    };

    paymentRequest({
      data,
      callback: () => {
        onToggle();
        requestPaymentCondition({
          data: conditionData,
          callback: () => {
            fetchStats({});
            showMessage({
              message: t('editionRequestSent'),
              config: TOAST_CONFIG.SUCCESS,
            });
            requestBank({
              params: {
                idBank: id,
                is_bank: (type_user === 'issuing_bank' || type_user === 'acquiring_bank'),
                receiver: request === 1,
              },
              callback: (response) => {
                this.setState({
                  conditionList: response,
                });
              }
            });
          },
        });
      }
    });
  };

  handleSelectChange = (newVal, label) => {
    const { codes } = this.state;

    if (label.name === 'commerceCode') {
      this.setState({
        commerceCode: /*type_user === 'acquiring_bank' ? codesProvider.commerce_codes[newVal.value].id
          :*/ codes[newVal.value].id,
        codeDescription: /*type_user === 'acquiring_bank' ? codesProvider.commerce_codes[newVal.value].description
          :*/ codes[newVal.value].description,
      });
    }
    this.setState({
      [label.name]: newVal.value,
    });
  };

  handleSelectRequest = (newVal, label) => {
    const {
      auth: {
        user: {
          b2b: { id, roles },
        },
      },
      providerCommerceCode,
      requestBank,
    } = this.props;

    switch (newVal.value) {
      case -1:
        this.setState({
          conditionList: [],
        });
      break;
      case 0:
      case 1:
        providerCommerceCode({
          params: {
            user: id,
            role: roles[0].id,
          },
          callback: (response) => {
            this.setState({
              codes: response.commerce_codes,
            });
            requestBank({
              params: {
                idBank: id,
                receiver: newVal.value === 1,
              },
              callback: (response) => {
                this.setState({
                  conditionList: response,
                  [label.name]: newVal.value,
                });
              }
            });
          },
        });
      break;
      case 2:
        requestBank({
          params: {
            idBank: id,
            rejected: true,
            receiver: false,
          },
          callback: (response) => {
            this.setState({
              conditionList: response,
              [label.name]: newVal.value,
            });
          }
        });
        break;
    }
  };

  closeModal = (onToggle) => {
    this.getInitialState();
    onToggle();
  };

  render() {
    const {
      t,
      stats: { loading: statsLoading },
      paymentConditions: { loading: paymentLoading },
      providers: { receiver: providerList, loading: providerLoading },
      auth: { user: { b2b: { type_user }, } },
      showMessage,
    } = this.props;
    const {
      rejectReason,
      conditionList,
      reject,
      is_edit,
      startCountingFrom,
      baseDays,
      discountRate,
      receiverDiscountShare,
      clientPayment,
      codes,
      commerceCode,
      codeDescription,
      getInfo,
      request,
      rejectStep,
    } = this.state;
    const loading = paymentLoading || statsLoading || providerLoading;
    return (
      <Toggle>
        {({ toggled, onToggle, toggleProps }) => {
          const { title, columns, data } = this.getGridSettings(onToggle);
          return (
            <div className="dashboard">
              {loading && <Loading/>}
              <div className="columns is-multiline">
                <Fragment>
                  {(toggled && is_edit === true && getInfo === false)
                  && (
                    <Modal 
                      onToggle={onToggle}
                      className={(type_user === 'issuing_bank' || type_user === 'acquiring_bank') ? 'wider-modal' : ''}>
                        {loading && <Loading/>}
                        <div className='modal-scroll-container'>
                          <PaymentConditionForm
                            t={t}
                            onSubmit={() => this.saveRequest(onToggle, toggleProps.infoPayment)}
                            handleInputChange={this.handleInputChange}
                            isClient={type_user !== 'seller'}
                            isProvider={(type_user === 'buyer' || type_user === 'seller')}
                            isBank={(type_user === 'issuing_bank' || type_user === 'acquiring_bank')}
                            handleSelectChange={this.handleSelectChange}
                            persons={[]}
                            client={'client'}
                            provider={'provider'}
                            baseDays={baseDays}
                            discountRate={discountRate}
                            receiverDiscountShare={receiverDiscountShare}
                            clientPayment={clientPayment}
                            startCountingFrom={startCountingFrom}
                            clientEdit={(type_user === 'issuing_bank' || type_user === 'acquiring_bank')
                              ? toggleProps.infoPayment.sender.idPerson.name :
                              type_user === 'buyer' ? toggleProps.infoPayment.receiver.idPerson.name :
                                toggleProps.infoPayment.sender.idPerson.name}
                            providerEdit={(type_user === 'issuing_bank' || type_user === 'acquiring_bank') ?
                              toggleProps.infoPayment.receiver.idPerson.name : ''}
                            codes={codes}
                            commerceCode={commerceCode}
                            codeDescription={codeDescription}
                            editable={true}
                            hidden={false}
                            closeModal={() => this.closeModal(onToggle)}
                            rejectReason={rejectReason}
                            reject={false}
                            typeUser={type_user}
                            usedInModals
                          />
                        </div>
                    </Modal>
                  )}
                  {
                    ( toggled && is_edit === false && getInfo === true ) && (
                      <Modal 
                        onToggle={onToggle}                       
                        title={t('requestRejected')}>
                        {loading && <Loading/>}
                        <div className='modal-scroll-container'>
                          <PaymentConditionForm
                            t={t}
                            onSubmit={() => this.saveRequest(onToggle, toggleProps.infoPayment)}
                            handleInputChange={this.handleInputChange}
                            isClient={type_user !== 'seller'}
                            isProvider={(type_user === 'buyer' || type_user === 'seller')}
                            isBank={(type_user === 'issuing_bank' || type_user === 'acquiring_bank')}
                            handleSelectChange={this.handleSelectChange}
                            persons={[]}
                            client={'client'}
                            provider={'provider'}
                            baseDays={toggleProps.paymentCondition.days}
                            discountRate={toggleProps.paymentCondition.discount_rate}
                            receiverDiscountShare={toggleProps.paymentCondition.receiver_discount_share * 100}
                            clientPayment={toggleProps.paymentCondition.percentage}
                            startCountingFrom={toggleProps.paymentCondition.due_from_approval}
                            clientEdit={toggleProps.paymentCondition.sender.idPerson.name}
                            providerEdit={toggleProps.paymentCondition.receiver.idPerson.name}
                            codes={codes}
                            commerceCode={commerceCode}
                            codeDescription={codeDescription}
                            numberCommerceCode={toggleProps.paymentCondition.personCode ? toggleProps.paymentCondition.personCode.commerce_code : null}
                            editable={true}
                            hidden={true}
                            closeModal={() => this.closeModal(onToggle)}
                            rejectReason={toggleProps.paymentCondition.rec_reason}
                            reject={false}
                            pathname={'/payment-request-rejected'}
                            typeUser={type_user}
                            usedInModals
                            toggleBTNStatus={toggleProps.paymentCondition.bpp == 1 ? true : false}
                          />
                        </div>
                      </Modal>
                    )
                  }
                  {/* {
                    (toggled && is_edit === false && getInfo === false && !toggleProps.checkRequest)
                    && (
                      <Modal onToggle={onToggle}>{loading && <Loading/>}
                        <Panel
                          headingText={reject === true ? t('rejectReason') : t('rejectTradeRequest')}>
                          <div className="FormLogin">
                            <div style={{ display: reject === true ? '' : 'none' }}>
                              <Form
                                className="column is-full"
                                submitText={t('send')}
                                onSubmit={(event) => {
                                  event.preventDefault();
                                  this.rejectInvoice('reject', toggleProps.transaction, toggleProps.product, onToggle);
                                }}
                                buttonClasses="mr-8px"
                              >
                                <TextArea
                                  name="rejectReason"
                                  value={rejectReason}
                                  onChange={this.handleInputChange}
                                  placeholder=""
                                  controlClasses="is-expanded"
                                  maxLength="120"
                                />
                                <br/>
                              </Form>
                            </div>
                            <div className="field is-horizontal"
                                 style={{ display: reject === false ? '' : 'none' }}>
                              <div className="field-body columns" style={{ textAlign: 'center' }}>
                                <div className="column">
                                  <label style={{
                                    color: 'black',
                                    fontFamily: 'Roboto, Arial, sans-serif'
                                  }}>
                                    {t('permanentlyRejectCommercialStatus')}</label>
                                  <div>
                                    <a className="button is-primary"
                                       onClick={() => this.rejectInvoice('rejectData', toggleProps.infoPayment, toggleProps.product, onToggle)}
                                    >
                                      <i className="fas fa-times" aria-hidden="true"/>
                                      &nbsp;
                                      {t('reject')}
                                    </a>
                                  </div>
                                </div>
                                <div className="column">
                                  <label style={{
                                    color: 'black',
                                    fontFamily: 'Roboto, Arial, sans-serif'
                                  }}>
                                    {t('suggestTradeConditionCorrection')}</label>
                                  <div>
                                    <a className="button is-primary" onClick={() => this.setState({
                                      is_edit: true,
                                      reject: true,
                                      startCountingFrom: toggleProps.infoPayment.due_from_approval,
                                      baseDays: toggleProps.infoPayment.days,
                                      discountRate: Number(toggleProps.infoPayment.discount_rate)
                                        .toFixed(2),
                                      receiverDiscountShare: (Number(toggleProps.infoPayment.receiver_discount_share) * 100).toFixed(2), /*(type_user === 'issuing_bank' || type_user === 'acquiring_bank') && toggleProps.infoPayment.rec_reason === null ?
                                       (Number(toggleProps.infoPayment.receiver_discount_share) * 100).toFixed(2) :
                                       toggleProps.infoPayment.receiver_discount_share, // cerrar comentario aquí (Number(toggleProps.infoPayment.receiver_discount_share) * 100).toFixed(2),
                                      clientPayment: 100 - Number(toggleProps.infoPayment.receiver_discount_share * 100), /* (type_user === 'issuing_bank' || type_user === 'acquiring_bank') && toggleProps.infoPayment.rec_reason === null ?
                                       100 - Number(toggleProps.infoPayment.receiver_discount_share * 100) :
                                       100 - toggleProps.infoPayment.receiver_discount_share, // cerrar comentario aquí Number(toggleProps.infoPayment.receiver_discount_share * 100),
                                      codes: toggleProps.codes,
                                      commerceCode: toggleProps.commerceCode,
                                      codeDescription: toggleProps.codeDescription,
                                    })}
                                    >
                                      <i className="far fa-edit" aria-hidden="true"/>
                                      &nbsp;
                                      {t('edit')}
                                    </a>
                                  </div>
                                </div>
                                <br/>
                              </div>
                              <br/>
                            </div>
                          </div>
                        </Panel>
                      </Modal>
                  )} */}
                  { 
                    toggled && toggleProps.checkRequest && (
                      <Modal 
                        onToggle={() => {
                          onToggle()
                          this.setState({ rejectStep: 0, rejectReason: '' })
                        }} 
                        title={type_user === 'issuing_bank'? t('requestSentProduct') :t('receivedRequest')}>
                        <>
                        {loading && <Loading />}
                        <div className={`modal-scroll-container ${toggleProps.data.condition_type}`}>
                          <div style={{ marginTop: '25px' }}>
                            <div className="field is-horizontal">
                              <div className="field-body columns">
                                <div className="column">
                                  <TextInput
                                    name="provider"
                                    label={t('provider')}
                                    type="text"
                                    value={toggleProps.data.receiver.idPerson.name}
                                    disabled
                                    />
                                </div>
                                
                                {toggleProps.data.condition_type === 'TDC' ? (
                                <div className="column">
                                  <TextInput
                                    name="commerceCode"
                                    label={t('amexAffiliationNumber')}
                                    type="text"
                                    value={toggleProps.data.codes[toggleProps.data.commerceCode].commerce_code}
                                    disabled
                                    />
                                </div>
                                ) : (
                                  <div className="column">
                                    <TextInput
                                      name="commission"
                                      label={t('commission')}
                                      type="text"
                                      value={`${toggleProps.data.ncl_commission}%`}
                                      disabled
                                      />
                                  </div>
                                )}

                              </div>
                            </div>
                            <div className="field is-horizontal">
                              <div className="field-body columns">
                                <div className="column">
                                  <TextInput
                                    name="baseDays"
                                    label={ toggleProps.data.condition_type === 'TDC' ? t('commercialCreditDays') : t('arrangedDays') }
                                    type="number"
                                    step={1}
                                    value={baseDays}
                                    onChange={this.handleInputChange}
                                    disabled={!(this.state.requestBTN === "rejectWithEdition" && this.state.rejectStep === 1)}
                                    />
                                </div>
                                <div className="column">
                                  <SelectSingle
                                    name="startCountingFrom"
                                    label={ toggleProps.data.condition_type === 'TDC' ? t('commercialCreditSince') : t('startCountingFrom') }
                                    placeholder={t('SelectOption')}
                                    onChange={this.handleSelectChange}
                                    options={
                                      [{
                                        label: t('receptionDate'),
                                        value: 0,
                                      }, {
                                        label: t('approvalDate'),
                                        value: 1,
                                      }]}
                                    selectClasses="is-fullwidth"
                                    controlClasses="has-icons-left"
                                    icon="far fa-user"
                                    iconPosition="is-left"
                                    value={startCountingFrom}
                                    isDisabled={!(this.state.requestBTN === "rejectWithEdition" && this.state.rejectStep === 1)}
                                  />
                                </div>
                              </div>
                            </div>
                            {toggleProps.data.condition_type === 'TDC' ? (
                              <div className="field is-horizontal">
                                <div className="field-body columns">
                                  <div className="column">
                                    <TextInput
                                      name="discountRate"
                                      label={t('discountRate')}
                                      value={this.state.discountRate}
                                      onChange={this.handleInputChange}
                                      type="number"
                                      controlClasses="is-expanded has-icons-right"
                                      min="0"
                                      step="0.01"
                                      max="100"
                                      iconPosition="is-right"
                                      icon="fas fa-percent"
                                      disabled={(this.state.requestBTN === "rejectWithEdition" && this.state.rejectStep === 1) || type_user === "issuing_bank"}
                                      />
                                  </div>
                                </div>
                              </div>
                            ) : ( null )}
                            {toggleProps.data.condition_type === 'TDC' ? (
                            <div className="field is-horizontal">
                              <div className="field-body columns">
                                <div className="column">
                                  <TextInput
                                    name="receiverDiscountShare"
                                    label={t('providerPercentageCharge')}
                                    value={formatAmount(receiverDiscountShare)}
                                    onChange={this.handleInputChange}
                                    placeholder="100"
                                    type="number"
                                    controlClasses="is-expanded has-icons-right"
                                    min="0"
                                    step="0.01"
                                    max="100"
                                    iconPosition="is-right"
                                    icon="fas fa-percent"
                                    disabled={!(this.state.requestBTN === "rejectWithEdition" && this.state.rejectStep === 1)}
                                  />
                                </div>
                                <div className="column">
                                  <TextInput
                                    name="senderDiscountShare"
                                    label={t('clientPercentageCharge')}
                                    value={formatAmount(clientPayment)}
                                    onChange={this.handleInputChange}
                                    placeholder="100"
                                    type="number"
                                    controlClasses="is-expanded has-icons-right"
                                    min="0"
                                    step="0.01"
                                    max="100"
                                    iconPosition="is-right"
                                    icon="fas fa-percent"
                                    disabled
                                  />
                                </div>
                              </div>
                            </div>
                            ) : ( null )}
                            {rejectStep === 1 && 
                              <div className="field is-horizontal">
                                <div className="field-body columns">
                                  <div className="column">
                                    <TextArea
                                      name="rejectReason"
                                      label={t('rejectReason')}
                                      type="text"
                                      value={this.state.reason}
                                      onChange={this.handleInputChange}
                                      autofocus
                                      />
                                  </div>
                                </div>
                              </div>
                            }
                            <div className="field is-horizontal">
                              <div className="field-body columns">
                                <div className="column">
                                  <label className='label' style={{ marginLeft: "15px", fontSize: '.9rem' }}>BPP</label>
                                  <div 
                                    style={{display: 'flex', alignItems: 'center ', gap: '15px'}} 
                                  >
                                    <ToggleBTN 
                                      isOn={toggleProps.data.bpp == 1 ? true : false}
                                      isDisabled/>
                                    <p style={{fontSize: '.8em'}}>
                                      <i className="fas fa-info has-text-primary mr-8px"/>
                                      {t('BPPDesc')}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          {type_user !== 'issuing_bank' &&
                            <div className='modal-action-btns'>
                              {/* APPROVE */}
                              <button 
                                disabled= {this.state.rejectStep === 1}
                                className='button custom-btn is-link'
                                onClick={() => {
                                  this.approveRequest(toggleProps.data)
                                  onToggle()
                                }}>
                                {t('approve')}
                              </button>

                              {/* PARCIAL REJECT */}
                              {toggleProps.data.condition_type === 'TDC' ? (
                              <button 
                                className='button custom-btn is-info'
                                disabled={this.state.requestBTN !== "rejectWithEdition" && this.state.rejectStep === 1}
                                onClick={() => {
                                  if (this.state.rejectStep === 0) {
                                    this.setState({ rejectStep: 1, requestBTN: "rejectWithEdition" })
                                    showMessage({
                                      message: t('defineRejectReason'),
                                      config: TOAST_CONFIG.WARNING,
                                    });
                                  } else {
                                    this.rejectInvoice('rejectData', /*toggleProps.infoPayment,*/ toggleProps.data, onToggle)
                                  }
                                }}>
                                {t('rejectWithEdition')}
                              </button>
                              ) : ( null )}

                              {/* TOTAL REJECT */}
                              <button 
                                className='button custom-btn is-primary'
                                disabled={this.state.requestBTN !== "totalReject" && this.state.rejectStep === 1}
                                onClick={() => {
                                  if (this.state.rejectStep === 0) {
                                    this.setState({ rejectStep: 1, requestBTN: "totalReject" })
                                    showMessage({
                                      message: t('defineRejectReason'),
                                      config: TOAST_CONFIG.WARNING,
                                    });
                                  } else {
                                    this.rejectInvoice('reject', /*toggleProps.transaction,*/ toggleProps.data, onToggle);
                                  }
                                }}>
                                  {t('totalReject')}
                              </button>
                            </div>
                          }
                        </div>
                        </>
                      </Modal>
                    )
                  }
                  {data
                  && (
                    <div className="column is-full">
                      <Panel headingText={title}>
                        <Panel>
                          <div className="columns field-body">
                            <div className="column">
                              <SelectSingle
                                name="request"
                                label={t('request')}
                                placeholder={t('SelectOption')}
                                onChange={this.handleSelectRequest}
                                options={
                                  type_user === 'issuing_bank' ?
                                  [{
                                    label: t('requestsSent'),
                                    value: 0,
                                  }, {
                                    label: t('requestRejected'),
                                    value: 2,
                                  }] :
                                  [{
                                    label: t('requestsReceived'),
                                    value: 1,
                                  }]
                                }
                                selectClasses="is-fullwidth"
                                controlClasses="has-icons-left"
                                icon="far fa-user"
                                iconPosition="is-left"
                                value={request}
                                isClearable={false}
                              />
                            </div>
                          </div>
                        </Panel>
                        <TableSimple data={conditionList} columns={columns}/>
                      </Panel>
                    </div>
                  )}
                </Fragment>
              </div>
            </div>
          );
        }}
      </Toggle>
    );
  }
}

Request.propTypes = {
  t: PropTypes.func.isRequired,
  requestBank: PropTypes.func.isRequired,
  fetchRoles: PropTypes.func.isRequired,
  fetchTypesRole: PropTypes.func.isRequired,
  paymentRequest: PropTypes.func.isRequired,
  editRole: PropTypes.func.isRequired,
  role: PropTypes.shape(Object).isRequired,
  auth: PropTypes.shape(Object).isRequired,
};


const mapStateToProps = ({ stats, role, auth, router, paymentConditions, providers, }) => (
  {
    stats,
    role,
    auth,
    router,
    paymentConditions,
    providers,
  }
);
export default compose(
  connect(mapStateToProps, { ...roleActions, ...authActions, ...paymentConditionsActions, ...statsActions, ...providersActions, }),
  withNamespaces(),
)(Request);