import { createAction } from 'redux-actions';
import types from './types';

const startFetch = createAction(types.START_FETCH);
const endFetch = createAction(types.END_FETCH);
const endError = createAction(types.END_ERROR);
const fetchTransactions = createAction(types.FETCH_TRANSACTIONS);
const listTransactionsReceiver = createAction(types.LIST_TRANSACTIONS_RECEIVER);
const setTransactionsReceiver = createAction(types.SET_TRANSACTIONS_RECEIVER);
const listTransactionsSender = createAction(types.LIST_TRANSACTIONS_SENDER);
const setTransactionsSender = createAction(types.SET_TRANSACTIONS_SENDER);
const extractQr = createAction(types.EXTRACT);
const transfer = createAction(types.TRANSFER);
const updateTransactions = createAction(types.UPDATE_TRANSACTIONS);
const clear = createAction(types.CLEAR);
const authorizeTransaction = createAction(types.AUTHORIZE_TRANSACTION);
const checkBalance = createAction(types.CHECK_BALANCE);
const updateCharge = createAction(types.UPDATE_CHARGE);
const updateBalance = createAction(types.UPDATE_BALANCE);
const editTransaction = createAction(types.EDIT_TRANSACTION);
const getTransaction = createAction(types.GET_TRANSACTION);
const saveTransaction = createAction(types.SAVE_TRANSACTION);
const deleteTransaction = createAction(types.DELETE_TRANSACTION);
const fetchTransaction = createAction(types.FETCH_TRANSACTION);
const listTransactionAdmin = createAction(types.LIST_TRANSACTION_ADMIN);
const statTransaction = createAction(types.STAT_TRANSACTION);
const saveStatTransaction = createAction(types.SAVE_STAT_TRANSACTION);
const setTransaction = createAction(types.SET_TRANSACTION);
const rateTransaction = createAction(types.RATE_TRANSACTION);
const saveRate = createAction(types.SAVE_RATE);
const getRetryPayment = createAction(types.GET_RETRY_PAYMENT);
const minuteSettlePayment = createAction(types.MINUTE_SETTLE_PAYMENT);
const declinePaymentNclInvoice = createAction(types.DECLINE_PAYMENT_NCL_INVOICE);
const processPaymentNclInvoice = createAction(types.PROCESS_PAYMENT_NCL_INVOICE);
const getAdminReport = createAction(types.GET_ADMIN_REPORT);
const getBankReport = createAction(types.GET_BANK_REPORT);
const getReport = createAction(types.GET_REPORT);

const showMessage = createAction(types.MESSAGE,
  ({ message }) => ({
    message,
  }),
  ({ config }) => ({
    config,
  }));

export default {
  startFetch,
  endFetch,
  endError,
  fetchTransactions,
  listTransactionsReceiver,
  setTransactionsReceiver,
  listTransactionsSender,
  setTransactionsSender,
  extractQr,
  transfer,
  updateTransactions,
  clear,
  showMessage,
  authorizeTransaction,
  checkBalance,
  updateCharge,
  updateBalance,
  editTransaction,
  getTransaction,
  saveTransaction,
  deleteTransaction,
  fetchTransaction,
  listTransactionAdmin,
  statTransaction,
  saveStatTransaction,
  setTransaction,
  rateTransaction,
  saveRate,
  getRetryPayment,
  minuteSettlePayment,
  declinePaymentNclInvoice,
  processPaymentNclInvoice,
  getAdminReport,
  getBankReport,
  getReport,
};
