import React from "react";
import Panel from "../Panel";
import Form from "../Form";
import TextInput from "../TextInput";
import SelectSingle from "../SelectSingle";

const SearchPersonForm = ({
  t,
  searchPerson,
  handleInputChange,
  document_number,
  name,
  email,
  min_amount,
  max_amount,
  type_user,
  user,
  rol,
  rut,
  type_rol,
  handleOnInput,
  roleList,
  role_filter,
  handleSelectChange,
}) => {
  return (
    <Panel>
      <Form
        submitText={t("search")}
        onSubmit={(event) => {
          event.preventDefault();
          searchPerson();
        }}
        buttonClasses="mr-8px"
      >
        {type_rol === "operador" ? (
          <div className="columns field-body">
            <div className="column">
              <TextInput
                name="name"
                label={t("name")}
                value={name}
                onChange={handleInputChange}
                placeholder={type_rol === 'operador' ? t("anyName") : t("name")}
                type="text"
                controlClasses="is-expanded has-icons-left"
                iconPosition="is-left"
                icon="fas fa-search"
              />
            </div>
            <div className="column">
              <TextInput
                name="document_number"
                label={t("documentNumber")}
                value={document_number}
                onChange={handleInputChange}
                onInput={handleOnInput}
                placeholder="11111111-1"
                type="text"
                controlClasses="is-expanded has-icons-left"
                // inputClasses="has-text-right"
                iconPosition="is-left"
                icon="fas fa-search"
                dataRegex="alphaNumeric"
                maxlength="13"
              />
            </div>
          </div>
        ) : (
          <>
            <div
              className="columns field-body"
              style={{ display: type_user === "" ? "" : "none" }}
            >
              <div className="column">
                <TextInput
                  name="name"
                  label={t("businessName")}
                  value={name}
                  onChange={handleInputChange}
                  placeholder={t("businessName")}
                  type="text"
                  controlClasses="is-expanded has-icons-left"
                  iconPosition="is-left"
                  icon="fas fa-search"
                />
              </div>
              <div className="column">
                <TextInput
                  name="document_number"
                  label={t("documentNumber")}
                  value={document_number}
                  onChange={handleInputChange}
                  onInput={handleOnInput}
                  placeholder="11111111-1"
                  type="text"
                  controlClasses="is-expanded has-icons-left"
                  // inputClasses="has-text-right"
                  iconPosition="is-left"
                  icon="fas fa-search"
                  dataRegex="alphaNumeric"
                  maxlength="13"
                />
              </div>
              <div className="column">
                <TextInput
                  name="email"
                  label={t("email")}
                  value={email}
                  onChange={handleInputChange}
                  placeholder={t("email")}
                  type="text"
                  controlClasses="is-expanded"
                />
                <TextInput name="max_amount" value="9999999999" type="hidden" />
              </div>
            </div>
            <div
              className="columns field-body"
              style={{ display: type_user !== "" ? "" : "none" }}
            >
              <div className="column">
                <TextInput
                  name="user"
                  label={t("user")}
                  value={user}
                  onChange={handleInputChange}
                  placeholder={t("assignedName")}
                  type="text"
                  controlClasses="is-expanded"
                />
              </div>
              <div
                className="column"
                style={{ 
                display: (
                  type_rol === "general_admin" || 
                  type_rol === "general_admin_issuing" || 
                  type_rol === "general_admin_acquiring"
                ) ? "none" : "" }}
              >
                {/*<TextInput
                  name="rol"
                  label={t("rol")+type_rol}
                  value={rol}
                  onChange={handleInputChange}
                  placeholder={t("rol")}
                  type="text"
                  controlClasses="is-expanded"
                />*/}
                <SelectSingle
                  name="role_filter"
                  label={t('rol')}
                  selectClasses="is-fullwidth"
                  placeholder={t('Select')}
                  onChange={handleSelectChange}
                  options={[
                    {
                      label:`${t('Select')} `,
                      value: '',
                    },
                    ...roleList.map((item, index) => ({
                      label: `${t(item.name)} `,
                      value: index+1,
                    })),
                  ]}
                  value={role_filter}
                />
              </div>
              <div className="column">
                <TextInput
                  name="email"
                  label={t("email")}
                  value={email}
                  onChange={handleInputChange}
                  placeholder={t("emailExample")}
                  type="text"
                  controlClasses="is-expanded"
                />
              </div>
              {
                /*
                <div className="column is-hidden">
                  <TextInput
                    name="rut"
                    label={t("documentNumber")}
                    value={rut}
                    onChange={handleInputChange}
                    placeholder={t("documentNumber")}
                    type="text"
                    controlClasses="is-expanded"
                    inputClasses="has-text-right"
                  />
                </div>
                */
              }
            </div>
          </>
        )}
      </Form>
    </Panel>
  );
};

SearchPersonForm.defaultProps = {
  type_user: "",
  type_rol: "",
};
export default SearchPersonForm;
