import React from 'react';
import './styles.scss';
import { breadcrumbRoutes } from './breadcrumbRoutes';
import { compose } from 'redux';
import { withNamespaces } from 'react-i18next';
import { ro } from 'date-fns/locale';

// Agregar la ruta en './breadcrumRoutes'
// 1ro: nombre del botón en el menú
// 2do: nombre del botón en el submenú

const Breadcrumb = ({ route, typeUser, t }) => {

    return (
        breadcrumbRoutes(route) ? 
            <ul className='breadcrumb-main'>
                {breadcrumbRoutes(route, typeUser).map((item, index) => {
                    return (
                        <li key={index}>
                            { breadcrumbRoutes(route, typeUser).length === index + 1 
                                ? <strong>{t(item)}</strong>
                                : <span>{t(item)} {'>'}</span>
                            }
                        </li>
                    )
                })}
            </ul>
        : null
    );
};

export default compose(withNamespaces())(Breadcrumb);