import React, { PureComponent } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import './styles.scss';
import { insertAfter } from '../../util';

class Modal extends PureComponent {
  constructor(props) {
    super(props);
    // eslint-disable-next-line no-undef
    this.el = document.createElement('div');
  }

  componentDidMount() {
    window.addEventListener('keydown', e => this.closeWithEsc(e))

    // eslint-disable-next-line no-undef
    const root = document.getElementById('root');
    insertAfter(this.el, root);
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', e => this.closeWithEsc(e))

    // eslint-disable-next-line no-undef
    document.body.removeChild(this.el);
  }

  closeWithEsc = e => {
    if(e.key === 'Escape') {
      this.props.onToggle()}
    return
  }

  render() {
    const { children, onToggle, title, className } = this.props;
    return ReactDOM.createPortal(
      <div className="Modal">
        <div className="modal is-active">
          {/* eslint-disable-next-line max-len */}
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
          <div
            className="modal-background"
            onClick={onToggle}
          />
          <div
            className={"modal-content "+className}
          >
            {/* <div className="modal-alert">
              <i className="far fa-bell"></i>
            </div> */}
            <div className='modal-title'>
              <h2>{title}</h2>
              <i
                type="button"
                className="fas fa-times"
                aria-label="close"
                onClick={onToggle}
              /> 
                {/* x
              </button> */}
              {/* <i className="fas fa-times mr-8px" /> */}
            </div>
            {children}
          </div>
        </div>
      </div>,
      this.el,
    );
  }
}

Modal.defaultProps = {
  title: '',
}

Modal.propTypes = {
  children: PropTypes.element.isRequired,
  onToggle: PropTypes.func.isRequired,
};

export default Modal;
