import React, { Component, Fragment } from "react"
import { compose } from "redux"
import { connect } from "react-redux"
import { withNamespaces } from "react-i18next"
import { Loading, Modal, Panel, Toggle } from "../../components"
import { authActions } from "../../state/ducks/auth"
import { TOAST_CONFIG } from "../../config/constants"
import { clientsActions } from "../../state/ducks/clients"
import { providersActions } from "../../state/ducks/providers"
import PropTypes from "prop-types"
import PaymentForm from "./components/PaymentForm"
import { paymentConditionsActions } from "../../state/ducks/paymentConditions"

class CreatePayment extends Component {
  constructor(props) {
    super(props)
    this.state = {
      provider: -1,
      providers: [],
      providerName: "",
      currency: "",
      inputValue: "",
      paymentConditionId: "",
      commerceCode: -1,
      commerceCodes: [],
      codeDescription: "",
      amount: "",
      invoiceNumber: "",
      paymentReference: "",
      paymentDescription: "",
      mails: [
        {
          id: "",
          email: "",
        },
      ],
      countEmail: 0,
    }
  }

  getInitialState = () => {
    this.setState({
      provider: -1,
      providers: [],
      providerName: "",
      currency: "",
      inputValue: "",
      paymentConditionId: "",
      commerceCode: -1,
      commerceCodes: [],
      codeDescription: "",
      amount: "",
      invoiceNumber: "",
      paymentReference: "",
      paymentDescription: "",
      mails: [
        {
          id: Date.now(),
          email: "",
        },
      ],
    })
  }

  componentDidMount() {
    const {
      getProviders,
      auth: {
        user: {
          b2b: { user_type, id }
        },
      },
      location: { pathname },
      clients: {
        paymentRecords: {
          currentPayment
        },
      },
      getPaymentCommerceCode,
    } = this.props
    this.getInitialState()

    getProviders({
      params: {
        id,
        related: true,
        bpp: 1,
      },
      callback: (response) => {
        const sortedList = response.sort((a, b) => {
          if (a.idPerson.name.toLowerCase() > b.idPerson.name.toLowerCase()) {
            return 1;
          }
          if (b.idPerson.name.toLowerCase() > a.idPerson.name.toLowerCase()) {
            return -1;
          }
          return 0;
        })
        this.setState({ providers: sortedList })

        if (pathname === "/edit-payment") {
          if (user_type === "TDC") {
            getPaymentCommerceCode({
              params: {
                sender: id,
                receiver: typeof(currentPayment) !== "undefined" ? currentPayment.seller.id : '',
              },
              callback: (response) => {
                this.setState({
                  commerceCodes: response,
                })
                this.setFieldsToEdit()
              },
            })
          } else {
            this.setFieldsToEdit()
          }
        }
      },
    })
  }

  resetState = () => {
    this.getInitialState()
  }

  setFieldsToEdit = () => {
    const {
      clients: {
        paymentRecords: {
          currentPayment: {
            seller,
            adjusted_amount,
            invoice_number,
            payment_reference,
            description,
            emails,
            personCode,
          },
        },
      },
      auth: {
        user : {
          b2b: {
            user_type
          }
        }
      },
    } = this.props
    const { commerceCodes, providers } = this.state

    const provider = providers.findIndex((el) => el.id === seller.id)
    const commerceCode = user_type === "TDC" 
      ? commerceCodes.findIndex((el) => el.id === personCode.id)
      : -1
    const mails = emails.map((el, index) => (el = { id: Date.now() * index, email: el }))

    this.setState({
      provider,
      providerName: providers[provider].idPerson.name,
      commerceCode,
      codeDescription: user_type === "TDC" ? commerceCodes[commerceCode].description : '',
      amount: adjusted_amount,
      invoiceNumber: invoice_number,
      paymentReference: payment_reference,
      paymentDescription: description,
      paymentConditionId: personCode.condition_id,
      mails: emails.length > 0 ? mails : [{ id: Date.now(), email: "" }],
      // file: resource,
      // binaryFile: {name: 'Documento PDF'},
      // xmlFile: '',
      // xmlFileNameFile: 'Documento XML',
    })
  }

  handleOnInputChange = (newValue) => {
    this.setState({ inputValue: newValue })
    return newValue
  }

  handleProviderChange = (newVal, label) => {
    const {
      auth: {
        user: {
          b2b: { user_type, id },
        },
      },
      getPaymentCommerceCode,
      getPaymentConditionRange,
      // providers: { list },
    } = this.props;
    const { providers } = this.state

    if (user_type === 'TDC') {
      getPaymentCommerceCode({
        params: {
          sender: id,
          receiver: providers[newVal.value].id,
        },
        callback: (response) => {
          this.setState({
            commerceCodes: response,
          })
        },
      })
    } else {
      getPaymentConditionRange({
        params: {
          date_min: "1",
          date_max: Date.now(),
        },
        callback: res => {
          const conditionIndex = res.payment_conditions
            .findIndex(el => el.user.idPerson.id === providers[newVal.value].idPerson.id);
          const paymentConditionId = res.payment_conditions[conditionIndex].condition.id
          this.setState({paymentConditionId})
        }
      });
    }

    this.setState({
      provider: newVal.value,
      providerName: providers[newVal.value].idPerson.name,
    })
  }

  handleCommerceChange = (newVal) => {
    const { commerceCodes } = this.state

    this.setState({
      commerceCode: newVal.value,
      codeDescription: commerceCodes[newVal.value].description,
      currency: commerceCodes[newVal.value].currency_id,
      paymentConditionId: commerceCodes[newVal.value].payment_condition.id
    })
  }

  formatNumber = (n) => {
    return n
      .replace(/\D/g, "")
      .replace(/(\d)(\d{2})$/, "$1.$2")
      .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ",")
  }

  handleInputChange = (event) => {
    const {
      target: { checked, value: targetValue, type, name },
    } = event
    const value = type === "checkbox" ? checked : targetValue
    if (name === "amount") {
      let num = this.formatNumber(event.target.value)
      this.setState({
        [name]: num,
      })
    } else if (name !== "amount") {
      this.setState({
        [name]: value,
      })
    }
  }

  addEmail = () => {
    const { mails } = this.state

    if (mails.length < 10) {
      this.setState({ mails: [...mails, { mail: "", id: Date.now() } ] })
    }
  }

  deleteEmail = (id) => {
    const { mails } = this.state
    this.setState({mails: mails.filter(mail => mail.id !== id)})
  }

  handleInputChangeMails = (value, id) => {
    const { mails } = this.state;
    const mailIndex = mails.findIndex(mail => mail.id === id)
    const updatedMails = [...this.state.mails];
    updatedMails[mailIndex] = { ...updatedMails[mailIndex], email: value };
    
    this.setState({ mails: updatedMails });
  }

  handleFileChange = (event) => {
    const { t, showMessage } = this.props
    const files = Array.from(event.target.files)
    const arrays = files[0].name
    let ext = arrays.split(".")

    if (ext[1] === "pdf" || ext[1] === "PDF") {
      if (parseInt(files[0].size / 1024) > 3072) {
        showMessage({
          message: (
            <strong style={{ color: "#ffffff" }}>
              {t("fileSizeExceeded")}
            </strong>
          ),
          config: TOAST_CONFIG.ERROR,
        })
      } else {
        this.setState({
          binaryFile: files[0],
          file: event.target.value,
        })
      }
    } else {
      showMessage({
        message: t("errorLoad", { type: "PDF" }),
        config: TOAST_CONFIG.WARNING,
      })
    }
  }

  handleXMLChange = (event) => {
    const { t, showMessage } = this.props
    const files = Array.from(event.target.files)
    const arrays = files[0].name
    let ext = arrays.split(".")

    if (ext[1] === "xml" || ext[1] === "XML") {
      if (parseInt(files[0].size / 1024) > 3072) {
        showMessage({
          message: (
            <strong style={{ color: "#ffffff" }}>
              {t("fileSizeExceeded")}
            </strong>
          ),
          config: TOAST_CONFIG.ERROR,
        })
      } else {
        this.setState({
          binaryXMLFile: files[0],
          xmlFile: event.target.value,
        })
      }
    } else {
      showMessage({
        message: t("errorLoad", { type: "XML" }),
        config: TOAST_CONFIG.WARNING,
      })
    }
  }

  onSubmit = (onToggle, formData, pay) => {
    const {
      providers,
      provider,
      paymentConditionId,
      invoiceNumber,
      commerceCode,
      amount,
      paymentReference,
      paymentDescription,
      mails,
      binaryFile,
      binaryXMLFile,
    } = this.state
    const {
      t,
      createPayment,
      editPayment,
      history,
      showMessage,
      auth: {
        user: {
          b2b: {
            user_type
          }
        }
      },
      providers: { list },
      location: { pathname },
      clients: {
        paymentRecords: {
          currentPayment: { id: paymentID },
        },
      },
    } = this.props

    let mailList = []
    if (mails.length > 0) {
      mailList = mails
        .filter((mail) => mail.email !== "")
        .map((mail) => mail.email)
    }

    if (!this.validateFields()) {
      const data = {
        [pathname === "/create-payment" ? 'seller' : 'receiver_id']:
          providers[provider].id,
        [pathname === "/create-payment" ? 'commerce_code' : 'commerce_code_id']:
          user_type === "TDC" ? list[commerceCode].id : null,
        payment_condition_id: paymentConditionId,
        amount: amount.replaceAll(",", ""),
        invoice_number: invoiceNumber,
        payment_reference: paymentReference,
        description: paymentDescription,
        email: mailList,
        resource: binaryFile,
        resource_xml: binaryXMLFile,
        execute_payment: user_type === 'NCL' ? false : pay,
        ncl: user_type === 'NCL',
        approved: pay,
      }

      if (pay === true) {
        onToggle({
          data: pathname === "/create-payment" ? data : {...data, id: paymentID },
        })
      } else {
        if (pathname === "/create-payment") {
          createPayment({
            data,
            callback: (response) => {
              if (response.data.success) {
                history.push("/payment-record")
                showMessage({
                  message: t("savePaymentSuccess"),
                  config: TOAST_CONFIG.SUCCESS,
                })
                this.resetState()
              }
            },
          })
        } else {
          editPayment({
            data: {...data, id: paymentID},
            callback: (response) => {
              if (response.data.success) {
                history.push("/payment-record")
                showMessage({
                  message: t("editPaymentSuccess"),
                  config: TOAST_CONFIG.SUCCESS,
                })
                this.resetState()
              }
            },
          })
        }
      }
    } 
  }

  validateMails = (mails) => {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i
    return mails.every((email) => emailRegex.test(email))
  }

  validateFields = () => {
    const { 
      t, 
      showMessage, 
      auth: {
        user: {
          b2b: {
            user_type,
          }
        }
      }
    } = this.props;
    const { provider, commerceCode, amount, invoiceNumber, mails } = this.state;
    const amountIsZero = parseFloat(amount.replace(/,/g, "")) == 0;
    let mailList = []
    if (mails.length > 0) {
      mailList = mails
        .filter((mail) => mail.email !== "")
        .map((mail) => mail.email)
    };
    const areFieldsEmpty = (
      provider === -1 ||
      (user_type === 'TDC' && commerceCode === -1) ||
      amount === "" ||
      invoiceNumber === ""
    );
    
    if (areFieldsEmpty) {
      showMessage({
        message: t("emptyFields"),
        config: TOAST_CONFIG.ERROR,
      })
      return true
    }
    if (amountIsZero) {
      showMessage({
        message: t("notZeroAmount"),
        config: TOAST_CONFIG.ERROR,
      })
      return true
    }
    if (!this.validateMails(mailList)) {
      showMessage({
        message: t("invalidMails"),
        config: TOAST_CONFIG.ERROR,
      })
      return true
    }
  }

  closeModal = (onToggle) => {
    onToggle()
  }

  onCancel = () => {
    const { 
      history, 
      location: { pathname } } 
    = this.props
    history.push(pathname === "/create-payment" ? "/dashboard" : "/payment-record")
  }

  sendPaymentCallback = (response, onToggle) => {
    const {
      t,
      history,
      showMessage,
    } = this.props;

    // Repeated invoice
    if (response.data.data.message === "Ya existe el numero de factura") {
      onToggle();
      return
    }
    // Success or operational errors
    if (response.data.success) {
      showMessage({
        message: t("sendPaymentSuccess"),
        config: TOAST_CONFIG.SUCCESS,
      })
    } 
    history.push("/payment-record")
  } 

  render() {
    const {
      t,
      auth: {
        user: {
          b2b: {
            user_type
          }
        }
      },
      providers: { 
        loading: providerLoading, 
      },
      clients: {
        loading: clientLoading ,
      },
      createPayment,
      editPayment,
      location: { pathname },
    } = this.props

    const {
      provider,
      providerName,
      providers,
      inputValue,
      commerceCode,
      commerceCodes,
      codeDescription,
      amount,
      invoiceNumber,
      paymentReference,
      paymentDescription,
      mails,
      file,
      binaryFile,
      xmlFile,
      binaryXMLFile,
    } = this.state

    const loading = providerLoading || clientLoading

    return (
      <>
        {loading && <Loading />}
        <Toggle>
          {({ toggled, onToggle, toggleProps }) => (
            <Fragment>
              {toggled && (
                <Modal onToggle={onToggle} title={t("newPayment")}>
                  <Panel>
                    <>
                      <div style={{ padding: "1em", marginTop: "1em" }}>
                        <p style={{ textAlign: 'center'}}>
                          {t("confirmPayment", {
                            currency: user_type === "TDC" ? commerceCodes[commerceCode].currency_id : 'MXN',
                            amount: amount,
                            provider: providerName,
                          })}
                        </p>
                      </div>
                      <div className="modal-action-btns">
                        <button
                          className={`button custom-btn is-block is-danger`}
                          onClick={() => {
                            this.closeModal(onToggle)
                          }}
                        >
                          {t("cancel")}
                        </button>
                        <button
                          className={`button custom-btn is-block is-primary`}
                          onClick={() => {
                            if (pathname === "/create-payment") {
                              createPayment({
                                  data: toggleProps.data,
                                  callback: (response) => {
                                      this.sendPaymentCallback(response, onToggle)
                                },
                              }) 
                            } else {
                              editPayment({
                                data: toggleProps.data,
                                callback: (response) => {
                                  this.sendPaymentCallback(response, onToggle)
                                },
                              }) 
                            }
                          }}
                        >
                          {t("confirm")}
                        </button>
                      </div>
                    </>
                  </Panel>
                </Modal>
              )}
              <div className="columns">
                <div className="column is-full">
                  <Panel
                    headingText={
                      pathname === "/create-payment"
                        ? t("newPayment")
                        : t("editPayment")
                    }
                  >
                    <PaymentForm
                      t={t}
                      onToggle={onToggle}
                      providers={providers}
                      provider={provider}
                      inputValue={inputValue}
                      handleOnInputChange={this.handleOnInputChange}
                      handleProviderChange={this.handleProviderChange}
                      commerceCode={commerceCode}
                      commerceCodes={commerceCodes}
                      codeDescription={codeDescription}
                      handleCommerceChange={this.handleCommerceChange}
                      amount={amount}
                      invoiceNumber={invoiceNumber}
                      paymentReference={paymentReference}
                      paymentDescription={paymentDescription}
                      handleInputChange={this.handleInputChange}
                      mails={mails}
                      addEmail={this.addEmail}
                      deleteEmail={this.deleteEmail}
                      handleInputChangeMails={this.handleInputChangeMails}
                      file={file}
                      fileName={binaryFile && binaryFile.name}
                      xmlFile={xmlFile}
                      xmlFileName={binaryXMLFile && binaryXMLFile.name}
                      handleFileChange={this.handleFileChange}
                      handleXMLChange={this.handleXMLChange}
                      onSubmit={this.onSubmit}
                      onSave={this.onSubmit}
                      onCancel={this.onCancel}
                      userType={user_type}
                      pathname={pathname}
                    />
                  </Panel>
                </div>
              </div>
            </Fragment>
          )}
        </Toggle>
      </>
    )
  }
}

CreatePayment.propTypes = {
  auth: PropTypes.shape(Object).isRequired,
  providers: PropTypes.shape(Object).isRequired,
  transactions: PropTypes.shape(Object).isRequired,
  t: PropTypes.func.isRequired,
  clients: PropTypes.shape(Object).isRequired,
  history: PropTypes.shape(Object).isRequired,
  showMessage: PropTypes.func.isRequired,
}

const mapStateToProps = ({
  transactions,
  clients,
  auth,
  providers,
  gatewayActions,
}) => ({
  transactions,
  clients,
  auth,
  providers,
  gatewayActions,
})

export default compose(
  connect(mapStateToProps, {
    ...authActions,
    ...clientsActions,
    ...providersActions,
    ...paymentConditionsActions
  }),
  withNamespaces()
)(CreatePayment)
