import React, { Component } from 'react';
import { compose } from 'redux';
import './styles.scss';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import PropTypes from 'prop-types';
import { Loading, Panel, SelectSingle, TextInput, } from '../../components';
import Form from '../../components/Form';
import { documentTypeActions } from '../../state/ducks/documentType';
import { providersActions } from '../../state/ducks/providers';
import { bankActions } from '../../state/ducks/bank';
import { roleActions } from '../../state/ducks/role';
import { TOAST_CONFIG } from '../../config/constants';
import { leadActions } from '../../state/ducks/lead';


class CreateLead extends Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  getInitialState = () => {
    const {
      auth: {
        user: {
          b2b: {
            roles,
          },
        },
      },
    } = this.props;
    return ({
      name: '',
      documentType: null,
      documentNumber: '',
      address: '',
      upRequire: roles[0] === 'acquiring_bank' || roles[0] === 'seller' ? 'buyer' : 'seller',
    });
  };

  componentDidMount() {
    const {
      auth: {
        user: {
          b2b: {
            roles,
          },
        },
      },
      fetchDocumentTypes,
      fetchRoles,
      fetchBanks,
    } = this.props;
    fetchDocumentTypes({
      params: {
        status: 1,
      },
    });
    fetchRoles({
      params: {
        type: 'external',
      },
    });
    fetchBanks({
      params: {
        issuing_bank: roles[0] !== 'issuing_bank',
        acquiring_bank: roles[0] === 'issuing_bank',
      },
    });
  }

  resetState = () => {
    this.setState(this.getInitialState());
  };

  handleInputChange = (event) => {
    const {
      target: {
        checked, value: targetValue, type, name,
      },
    } = event;
    const value = type === 'checkbox' ? checked : targetValue;
    this.setState({
      [name]: value,
    });
  };

  handleSelectChange = (newVal, label) => {
    this.setState({
      [label.name]: newVal.value,
    });
  };

  handleDocumentTypeChange = (newVal) => {
    this.setState({
      documentType: newVal.value,
    });
  };

  validateEmptyFields = () => {
    const {
      documentType,
    } = this.state;
    const {
      t,
      showMessage,
    } = this.props;

    if (documentType === null) {
      showMessage({
        message: <strong style={{ color: '#ffffff' }}>{t('SelectDocumentType')}</strong>,
        config: TOAST_CONFIG.ERROR,
      });
      return false;
    }

    return true;
  };

  altaRequire = () => {
    const {
      fetchLeads,
      altaRequire,
      role: { list: roleList },
      showMessage,
      t,
      auth: {
        user: {
          user_id,
        },
      },
    } = this.props;
    const {
      name,
      documentType,
      documentNumber,
      address,
      upRequire,
    } = this.state;

    if (this.validateEmptyFields()) {
      const data = {
        name,
        document_type: documentType,
        document_number: documentNumber,
        address,
        referrer_id: user_id,
        role_id: roleList.filter(role => role.name === upRequire)[0].id,
      };
      altaRequire({
        data,
        callback: () => {
          showMessage({
            message: t('requireSuccess', { name }),
            config: TOAST_CONFIG.SUCCESS,
          });
          this.resetState();
          fetchLeads();
        },
      });
    }
  };

  render() {
    const {
      t,
      documentType: { list: documentTypesList, documentTypeLoading },
      role: { loading: roleLoading },
      providers: { loading: providerLoading },
    } = this.props;
    const loading = documentTypeLoading || roleLoading || providerLoading;
    const {
      name, documentNumber, address, upRequire,
    } = this.state;

    return (
      <div>
        {loading && <Loading/>}
        <div className="columns">
          <div className="column is-full">
            <Panel headingText={t(`${upRequire}CreateLead`)}>
              <Form
                submitText={t('save')}
                onSubmit={(event) => {
                  event.preventDefault();
                  this.altaRequire();
                }}
                buttonClasses="mr-8px"
              >
                <div className="field is-horizontal subtitle is-5 text-black">
                  {t('demographicInformation')}
                </div>
                <div className="field is-horizontal">
                  <div className="field-body columns">
                    <div className="column is-two-fifths">
                      <TextInput
                        name="name"
                        label={t(`${upRequire}Name`)}
                        value={name}
                        onChange={this.handleInputChange}
                        placeholder={t('name')}
                        type="text"
                        controlClasses="is-expanded"
                      />
                    </div>
                    <div className="column is-one-fifth">
                      <SelectSingle
                        name="documentType"
                        label={t('documentType')}
                        selectClasses="is-fullwidth"
                        placeholder={t('Select')}
                        onChange={this.handleDocumentTypeChange}
                        options={[
                          ...documentTypesList.map(item => ({
                            label: item.document_type,
                            value: item.id,
                          })),
                        ]}
                      />
                    </div>
                    <div className="column is-two-fifths">
                      <TextInput
                        name="documentNumber"
                        label={t('documentNumber')}
                        value={documentNumber}
                        onChange={this.handleInputChange}
                        placeholder={t('documentNumber')}
                        type="text"
                        controlClasses="is-expanded"
                      />
                    </div>
                  </div>
                </div>
                {upRequire === 'buyer' ?
                  <div className="field is-horizontal"> </div>
                  :
                <div className="field is-horizontal">
                  <div className="field-body columns">
                    <div className="column">
                      <TextInput
                        name="address"
                        label={t('address')}
                        value={address}
                        onChange={this.handleInputChange}
                        placeholder={t('address')}
                        type="text"
                        controlClasses="is-expanded"
                      />
                    </div>
                  </div>
                </div>
                }
              </Form>
            </Panel>
          </div>
        </div>
      </div>

    );
  }
}

CreateLead.propTypes = {
  role: PropTypes.shape(Object).isRequired,
  providers: PropTypes.shape(Object).isRequired,
  auth: PropTypes.shape(Object).isRequired,
  documentType: PropTypes.shape(Object).isRequired,
  fetchDocumentTypes: PropTypes.func.isRequired,
  fetchBanks: PropTypes.func.isRequired,
  altaRequire: PropTypes.func.isRequired,
  showMessage: PropTypes.func.isRequired,
  fetchRoles: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};


const mapStateToProps = (
  {
    providers, documentType, auth, role,
  },
) => ({
  providers,
  documentType,
  auth,
  role,
});
export default compose(
  connect(mapStateToProps, {
    ...documentTypeActions,
    ...providersActions,
    ...roleActions,
    ...bankActions,
    ...leadActions,
  }),
  withNamespaces(),
)(CreateLead);
