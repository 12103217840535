import { number } from "prop-types"
import Fingerprint2 from "fingerprintjs2"

// Helper function for insert a node after other
export const insertAfter = (el, referenceNode) => {
  referenceNode.parentNode.insertBefore(el, referenceNode.nextSibling)
}

export const getPath = (p, o) =>
  p.reduce((xs, x) => (xs && xs[x] ? xs[x] : null), o)

export const fileDateTime = () => {
  const today = new Date()
  return (
    today.getFullYear() +
    ("0" + (today.getMonth() + 1)).slice(-2) +
    ("0" + today.getDate()).slice(-2) +
    ("0" + today.getHours()).slice(-2) +
    ("0" + today.getMinutes()).slice(-2) +
    ("0" + today.getSeconds()).slice(-2)
  )
}

export const formatDate = (date) =>
  new Intl.DateTimeFormat("es", {
    timeZone: "America/Mexico_City",
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  }).format(date * 1000)

export const formatDateHour = (date) =>
  new Intl.DateTimeFormat("es", {
    timeZone: "America/Mexico_City",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    hour12: false,
  }).format(date * 1000)

export const formatFriendlyDate = (date) =>
  new Intl.DateTimeFormat("es", {
    month: "short",
    day: "2-digit",
    weekday: "short",
  }).format(date)

export const formatFriendlylongDate = (date, lng) =>
  new Intl.DateTimeFormat(lng, {
    month: "long",
    day: "2-digit",
    weekday: "long",
  }).format(date)

export const formatFriendlyDateLong = (date) =>
  new Intl.DateTimeFormat("es", {
    month: "long",
    day: "2-digit",
    weekday: "long",
  }).format(date * 1000)

// Este código es el mismo que usamos en components/currency
export const formatCurrencyKeo = (amount) =>
  new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(amount)

export const formatCurrency = (amount) =>
  Number(amount).toLocaleString("es-MX", {
    style: "currency",
    currency: "MXN",
  })

export const formatCurrencyCl = (amount) =>
  Number(amount).toLocaleString("es-MX", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })

export const formatIntCurrency = (amount) =>
  Number(amount).toLocaleString("es-MX", {
    style: "currency",
    currency: "MXN",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  })

const regex = {
  alphaNumeric: /[^a-z0-9]/gi,
}

export const validateAlphaNumeric = (event) => {
  const { target, nativeEvent } = event
  if (
    target.dataset.regex &&
    nativeEvent.data &&
    !nativeEvent.data.replace(regex[target.dataset.regex], "")
  ) {
    if (target.value) {
      target.value = target.value.replace(regex[target.dataset.regex], "")
    }
  }
}

export const formatAmount = (amount, currency = false) => {
  if (currency) {
    return amount > 0
      ? `${currency} ${Intl.NumberFormat("en-US", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }).format(amount)}`
      : "$0.00"
  } else {
    return amount > 0
      ? Intl.NumberFormat("en-US", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }).format(amount)
      : "0.00"
  }
}

export const replaceRegex = (value, regex, replaceBy = "") =>
  value ? value.replace(regex, replaceBy) : ""

export const numberFilter = (filter, row) => {
  const { id, value } = filter
  const cleanFilter = replaceRegex(`${value}`, /[ .,]/g)
  const cleanRow = replaceRegex(`${row[id]}`, /[ .,]/g)
  if (cleanRow.includes(cleanFilter)) {
    return row[id]
  }
}

export const onInputOnlyNumbers = (e) => {
  e.target.value = replaceRegex(e.target.value, /[^0-9]+/g, "")
}

export const defineWhereToGo = (user) => {
  const isFirstLogin = user.first_login === 1,
    isSeller = user.b2b.type_user === "seller",
    isBuyer = user.b2b.type_user === "buyer",
    passwordHasExpired = user.b2b.password_changed === 0,
    hasActive2FA = user.b2b.password_changed === -1 || user.b2b.fma_is_active,
    hasMultiProfiles = user.profiles.length > 0

  let route = ""

  switch (true) {
    case passwordHasExpired:
      route = "recover-password"
      break
    case hasActive2FA:
      route = "validate-code"
      break
    // case hasMultiProfiles:
    //   route = "select-profile"
    //   break
    case isBuyer && isFirstLogin:
      route = "confirm-customer"
      break
    case isSeller && isFirstLogin:
      route = "confirm-provider"
      break

    default:
      route = "dashboard"
      break
  }

  return route
}

export const getDeviceInfo = () => {
  return new Promise((resolve) => {
    const options = {
      excludes: {
        touchSupport: true,
        fontsFlash: true,
        screenResolution: true,
        pixelRatio: true,
        colorDepth: true,
        availableScreenResolution: true,
      },
    }

    Fingerprint2.get(options, (components) => {
      const values = components.map((component) => component.value)
      const murmur = Fingerprint2.x64hash128(values.join(""), 31)
      resolve(murmur)
    })
  })
}

export const getTableFilteredValues = (filtered) => {
  let objetForParams = {}
  filtered.forEach(el => objetForParams[el.id]= el.value);
  return encodeURIComponent(JSON.stringify(objetForParams))
}