const en = {
  translations: {
    accept: 'To accept',
    acceptTerms: 'You must agree to the terms terms and conditions', //
    acceptTermsGateway: 'We accept the charges or fees for service that are indicated in the Clients section and we accept that these are charged by GETNET by direct deduction from the monthly settlements made to the ESTABLISHMENT, as indicated in the Affiliation Contract and in its Annex that regulates access and use of the "B2B Enterprise" platform',
    account: 'Account',
    accountBalance: 'Account balance',
    accountPlural: 'Accounts',
    accumulatedAmount: 'Daily accumulated amount',
    acquiring_bank: 'Acquiring Bank',
    acquiringBank: 'Acquiring',
    acquiringQuery: 'Acquiring Query',
    action: 'Action',
    active: 'Active',
    activeCompanies: 'Active companies',
    activate: 'Activate',
    addComment: 'Add a comment ...',
    addCondition: ' Add condition',
    addFinanceProductSuccess: 'Financing product created successfully',
    addFinancingProducts: 'Agregar Productos de financiamiento',
    additionalInformation: 'Additional Information',
    addNew: 'Add new',
    address: 'Address',
    adjusted_amount: 'Adjusted amount',
    admin: 'Manage',
    administrators: 'Administrators',
    administratorsList: 'Administrators list',
    administratorName: 'Administrator name',
    administratorBank: 'Administrator Bank',
    adminName: 'Name',
    adminLastname: 'Last name',
    adminPosition: 'Position',
    adminPositionPlaceholder: 'Manager, executive, etc.',
    adminRoutes: 'Admin routes',
    assignPermissions: 'Assign permissions',
    adminUser: 'Administator',
    affiliateCode: 'Affiliate Code',
    affiliateN: 'Affiliate number',
    affiliateClients: 'Affiliate clients',
    authUser: 'Authorized user',
    advance: 'Anticipate',
    advanceCommercialConditions: 'Commercial advance conditions',
    advanceMount: 'Advance Amount',
    alias: 'Alias',
    alreadyRegisteredCard: 'The customer has already a registered card',
    allowedFormats: 'Allowed formats: JPG and PNG. Maximum size 3MB.',
    allowedFormatsPDF: 'Allowed formats: PDF and XML. Maximum size 3MB.',
    amexAffiliationNumber: 'AMEX affiliation number',
    amexCard: 'Amex Tesorero Card',
    amount: 'Amount',
    amountInt: 'The amount must be a whole number',
    amountInstallments: 'Amount of fees',
    amountMoney: 'Amount of money',
    amountOfTransactionsToProcess: 'Amount of transactions to process',
    amountAverageTransactionsMonthly: 'Average amount monthly transactions',
    amountToApprove: 'Amount to approve',
    amountToCollect: 'Daily total receivable', //
    amountToGet: 'Amount to Receive',
    amountToPay: 'Amount to Pay',
    anticipatedAmount: 'Advance Amount',
    annualQuota: 'Annual quota',
    anualRate: 'Anual rate',
    anyName: 'Any of the registered names',
    applicant: 'Applicant',
    applicationDate: 'Application date',
    applicantName: 'Applicant\'s name',
    applicantBank: 'Applicant bank',
    applicationDeleted: 'Request removed',
    applicationOneNotification: 'You have {{payment_request_pending}} request',
    applicationNotification: 'You have {{payment_request_pending}} requests',
    applicationSuccess: 'Application successfully approved',
    applicationRejected: 'Application successfully rejected',
    approvalConditionDate: 'Commercial condition approval date',
    approvalConditionDateUserType: 'Approval date for {{userType}}',
    approvalDate: 'Approval date',
    approvalStatus: 'Approval status',
    approve: 'Approve',
    approved: 'Approved',
    approveInvoice: 'Approve invoice',
    approvedStatus: 'Approved',
    approvedInvoices: 'Approved Inv.',
    approvedInvoicesTitle: 'Approved Invoices',
    approvedMount: 'Amount approved',
    arrangedDays: 'Days agreed',
    asterisk: '*** {{number_card}}',
    assistCard: 'Assist card',
    assign: 'Assign',
    assignRoute: 'Assign routes',
    assignedCardNumber: 'Assigned card number',
    assignedRoutes: 'Assigned routes',
    assignRoleRoute: 'The role and route were assigned successfully', // ...
    assignRouteSuccess: 'The route was assigned successfully',
    authorization: 'Authorization',
    available: 'Available',
    availableLineApprovedInvoices: 'Available line on approved invoices',
    avance: 'Advance',
    balance: 'Balance',
    bankList: 'Bank list',
    bankLogo: 'Bank logo',
    bankName: 'Bank name',
    banks: 'Banks',
    bankProvider: 'AMEX',
    bankType: 'Bank type',
    baseConditions: 'Base Conditions (Invoice Payment)',
    benefits: 'Benefits',
    bills: 'Invoices \nsent',
    billPayment: 'Bill payment',
    billingVolume: 'Billing volume',
    bin: 'Bin',
    buyerCreateLead: 'Require client registration',
    buyerName: 'Client name',
    business: 'Business',
    businessName: 'Business name',
    businessExecutive: 'Business executive',
    businessExecutives: 'Business executives',
    byAmount: 'por un monto de',
    by_amount: 'By amount',
    by_hierarchy: 'By position hierarchy',
    cardBind: 'Card bind',
    cantDeleteRol: 'The role cannot be deleted because it has one or more routes assigned',
    cantDeleteRoute: 'The route cannot be deleted because it is assigned to one or more roles',
    deleteExecutive: 'Executive removed',
    language: 'Language',
    cancel: 'Cancel',
    cardChargeDate: 'Charge Date on the Treasurer Amex Card',
    cardLoadedSuccessfully: 'Card loaded successfully',
    cardholderPaymentPercentage: 'Cardholder Payment Percentage',
    cardPayment: 'TTA selected for payment',
    cardPaymentDate: 'Car payment date',
    cedulaJuridicaDesc: '10 digits, no leading zero or hyphens',
    changeOfCommercialConditions: 'Record of change of commercial conditions',
    changeCode: 'You must modify the commercial code that corresponds to the new type of currency selected',
    changeLog: 'Change log',
    charge: 'Charge',
    chargeDate: 'Charge date',
    chargeForService: 'Service charge',
    cif: 'CIF',
    cifPattern: 'Must contain letters and numbers',
    client: 'Buyer',
    clientName: 'Customer name',
    clientPaymentCondition: 'Determine the payment terms for this',
    code: 'Code',
    codeArea: 'area code + phone number',
    collaboratorNumber: 'Collaborator number',
    collaboratorNumberCharacter: 'Collaborator number, must be 8 characters',
    company: 'Company',
    commerceCode: 'Affiliate number',
    commercialCreditDays: 'Commercial credit days',
    commercialCreditSince: 'Commercial credit since',
    updateInfo: 'Update information',
    commercialBillingUser: 'Commercial billing user',
    commerceCodeDesc: 'Enter a description  for commerce code',
    commercialConditions: 'Commercial terms',
    condition: 'Condition',
    concept: 'Concept',
    configuration: 'Settings',
    confirm: 'Confirm',
    confirmInvoiceApprovement: 'Are you sure you want to approve the invoice No. {{ invoiceNu }} for {{ currency}} {{ invoiceAmount }}?',
    confirmInvoiceDecline: 'Are you sure you want to decline the invoice No. {{ invoiceNu }} for {{ currency}} {{ invoiceAmount }}?',
    confirmPassword: 'Confirm password',
    confirmUnsubscribe: 'Are you sure you want to cancel the commercial condition with {{client}} and the {{product}} it involves?',
    confirmUnsubscribeFrom: 'Confirm unsubscribe from: {{product}}',
    confirmSupplierBeforeEdit: 'The supplier must confirm their data before they can be edited',
    confirmTaxCode: 'Confirm Tax Code',
    confirmTransfer: 'Confirm transaction',
    continue: 'Continue',
    consult: 'Consult',
    cort: 'Cort',
    cortDate: 'Cutoff date',
    createBank: 'Create new bank',
    createBankSuccess: 'Bank {{name}} created successfully ',
    createClientSuccess: 'Customer {{name}} created successfully',
    createCondition: 'Create trade condition for: {{name}}',
    createCustomer: 'Create \nCustomer',
    createExecutive: 'Create executive',
    createInvoiceSuccess: 'Invoice N° {{invoice_number}} has been successfully registered',
    createLead: 'Require Registration',
    createUser: 'Create user',
    createUserSuccess: 'User {{name}} has been successfully registered',
    changePassword: 'Change Password',
    changePasswordSuccess: 'Password change success',
    createNewItem: 'Create new industry "{{inputValue}}"',
    createNewExecutive: 'Create new executive "{{inputValueExecutive}}"',
    createNewManaged: 'Create new administrator',
    createPaymentCondition: 'Create payment \ncondition',
    createPaymentConditionSuccess: 'Payment condition created successfully',
    createProvider: 'Create \nProvider',
    createProviderSuccess: '{{name}} provider created successfully',
    createProfile: 'Create Profile',
    createProfileSuccess: 'Profile {{name}} created successfully',
    createRol: 'Create rol',
    createRolPlaceholder: 'Business, finance, etc.',
    createRoleSuccess: 'Role {{rolName}} created successfully ',
    createRolSuccess: '{{rolName}} role created successfully',
    createFlowApproval: 'Create approval flow',
    createFlowApprovalSuccess: 'Approval flow, created successfully',
    creditCardSubstitution: 'Credit card substitution',
    creditPeriod: 'Credit period',
    recentInvoices: 'Recent invoices',
    cuttingCycle: 'Cutting cycle',
    cuttingCycleAmex: 'AMEX treasurer card cutting cycle',
    cuttingCycleCard: 'Cut cycle of the card entered is: {{message}}',
    customer: 'Client',
    customerInformation: 'Selected Customer Information',
    customerName: 'Customer name',
    customerPayment: 'Customer payment',
    customerPaymentPercentage: '% Customer payment',
    customers: 'Customers',
    collectionDate: 'Collection date',
    city: 'City',
    cycleGroup: 'Cycle group',
    cyclesList: 'List of Billing Cycles',
    ccChargeDate: 'CC charge date',
    daily: 'Daily',
    dashboard: 'Resume',
    date: 'Date',
    dateOfAdmission: 'Date of entry in Sii',
    dateOfEntry: 'Date of entry to B2B',
    dateFf: 'Date FF',
    FF: 'FF',
    FV: 'FV',
    dateInvoice: 'Invoice date',
    dateV: 'Expiration date',
    dataConfirmedSuccessfully: 'Data confirmed successfully',
    day: 'Day',
    daysAdvance: 'Days to advance',
    daysDefer: 'Days to defer',
    daysNumber: 'Number of days',
    daysSince: ' days since ',
    defer: 'Diferido',
    defineRejectReason: "Define reject reason",
    decline: 'Decline',
    deferCommercialConditions: 'Commercial Deferral Conditions',
    deferMount: 'Deferred Amount',
    deferredAmount: 'Deferred Amount',
    deleteTta: 'TTA successfully eliminated',
    deleteInvoice: 'Invoice was remove',
    deletePaymentCondition: 'Payment condition was remove',
    delete: 'Remove',
    deleteRol: 'Can\'t delete rol',
    deleteRoute: 'Can\'t delete route',
    demographicInformation: 'Identification data',
    deposit: 'Deposit',
    description: 'Description',
    deactivate: 'Ddeactivate',
    destinationAccount: 'Destination account',
    detail: 'Detail',
    digitalCertificate: 'Digital certificate',
    differ: 'To differ',
    discountAmount: 'Discount amount',
    discountRate: 'Discount rate',
    discountRateMDR: 'Discount rate (MDR)',
    discountRateCharges: 'Discount rate charges',
    discountRateInvoiceSavedSuccessfully: 'Discount rate invoice saved successfully',
    discountRateOutOfLimits: 'Discount rate out of bounds',
    dispositionReport: 'Disposition report',
    document: 'Document',
    documentDate: 'Document date',
    documentNumber: 'RFC',
    documentType: 'Document type',
    doNotIncludeTheUsername: '* Do not include the username as part of the password.',
    dontPose: 'Does not have',
    download: 'Download',
    eCommerceTerminal: 'E-commerce terminal',
    edit: 'Edit',
    editBank: 'Edit manual: {{name}}',
    editCycle: 'Edit cycle',
    editRolSuccess: 'Role {{rolName}} edited successfully',
    enterDataInternalTaxService: 'Connect your company to the SII - Internal Revenue Service',
    email: 'Email',
    emptyAll: 'Be sure to fill in all the information',
    emptyAllRequest: 'Make sure to fill in all the information, of the required fields',
    editPaymentConditionSuccess: 'Payment condition success edit',
    editClientSuccess: 'Client {{name}} success edit',
    editProviderSuccess: 'Provider {{name}} success edit',
    editProfileSuccess: 'Profile {{name}} success edit',
    editUserSuccess: 'User {{name}} success edit',
    editionRequestSent: 'Edition request sent',
    emptyFields: 'Please complete all fields.',
    en: 'English',
    es: 'Español',
    pt: 'Português',
    enter: 'Enter',
    enterCuttingCycle: 'Enter cutting cycle',
    enterCodeSms: 'Check and type the code\n that we send you through SMS',
    enterTaxCode: 'Enter Tax Code',
    errorDate: 'The date \'since\' has to be less',
    errorData: 'Failed to load data.',
    errorDateCycles: 'The date FF has to be less',
    errorLoad: 'You must upload an extension file {{type}} o {{type1}}',
    executiveData: 'Business executive data',
    expirationDate: 'Expiration date',
    expiredInvoices: 'Approved / Paid',
    extract: 'Extract',
    extractAccount: 'Extract in B2B network',
    extractDetail1: 'Successfully generated approval code',
    extractDetail2: 'Amount to withdraw',
    extractDetail3: 'In',
    extractDetail4: 'Complete the extraction in a point of our Network',
    extractDetail5: 'With your phone number',
    extractDetail6: 'Provide your telephone number to the operator of the point to complete the extraction started',
    extractDetail7: 'QR Code',
    extractDetail8: 'Introduce yourself with the following code at the point of the network',
    financingProducts: 'Financing \nproducts',
    finish: 'Finish',
    firstAndSecondName: 'First and second name',
    fiscaldocument: 'Fiscal Document',
    flexibleConditions: 'Flexible Conditions (Financing)',
    flowApproval: 'Flow approval',
    flowsApproval: 'Flows approval',
    flowApprovalCheck: 'Default flow approval',
    forgotPassword: 'Forgot your password?',
    friday: 'Friday',
    fullName: 'Name and last name',
    goBack: 'Back',
    greaterThan: 'Greater Than',
    lessThan: 'Less Than',
    lessThanOrEqualTo: 'Less than or equal to',
    general_admin: 'General administrator user of the bank',
    general_admin_issuing: 'General administrator user of the Issuing bank',
    general_admin_acquiring: 'General administrator user of the Acquiring bank',
    general_admin__issuing_amex: 'General administrator user of the Issuing bank AMEX',
    greaterThanOrEqualTo: 'Greater than or equal to',
    group: 'Group ',
    fileSize: 'The maximum size allowed is 3MB',
    fileSizeExceeded: 'File size exceeded',
    handbook: 'Handbook',
    issuedInvoices: 'Issued invoices',
    import: 'Import',
    inactive: 'Inactive',
    inactiveBusinesses: 'Inactive businesses',
    inactiveClients: 'Inactive clients',
    informationOfTheSelectedBank: 'Information of the selected bank',
    inCharge: 'In Charge',
    installmentNumber: 'Number of fees',
    installments: 'No. de cuotas',
    intNumber: 'Must be a whole number',
    interest_rate: 'Mount rate',
    interestOrdinary: 'Ordinary interest',
    interestLatePayment: 'Interest on arrears',
    internalRevenueService: 'Internal Revenue Service',
    newProfile: 'New user',
    newProfileBTN: 'New profile',
    incorrectDate: 'You cannot enter a date before {{dateNow}}',
    incorrectEmail: '{{email}} it doesn\'t look like a valid email. Check that you have the correct format: ejemplo@mail.com',
    incorrectDays: 'You must enter a value greater than or equal to 0',
    interestRate: 'Interest rate',
    invalidAmount: 'Invalid amount',
    invalidRoleOrRoute: 'The role and route, can\'t be empty',
    invoiceCount: 'Number of Invoices',
    invoiceDate: 'Invoice date',
    invoice: 'Invoice',
    invoices: 'Invoices',
    invoicesApproved: 'Approved Invoices',
    invoicesToApproved: 'Invoices to approved',
    invoiceDetail: 'Invoice detail',
    invoiceIssueDate: 'Invoice issue date',
    invoiceN: 'Invoice N° {{invoiceNumber}}',
    invoicesNumber: 'Invoice number',
    invoiceNumber: 'Invoice No.',
    invoiceTotalAmount: 'Invoice Amount',
    invoiceUploaded: 'Invoice N° {{invoiceNumber}} uploaded to B2B Enterprise successfully',
    invoicesCollectiontDate: 'Invoices collection date',
    invoicesPaymentDate: 'Invoices payment date',
    invoicePending: 'To emit',
    invoiceReject: 'Invoice reject',
    invoiceProvidersReceiver: 'Receipt invoice',
    invoiceManualResolution: 'Invoices that require manual resolution',
    invoiceMarkPaid: 'Invoice # {{invoiceNumber}} was marked as paid',
    invoiceStatusInTheSii: 'Invoice status in the SII',
    issuing_bank: 'Issuing bank',
    items: 'Industries',
    keo: 'AMEX',
    kindOfProduct: 'Kind of product',
    legal: 'Legal',
    physical: 'Physical',
    quantity: 'Quantity',
    lastDays: 'Last days',
    last4Digits: 'Last 4 digits card number',
    lastMovements: 'Last Movements',
    lastName: "Last name",
    lead: 'Lead',
    lengthToCharacters: '* Length 8 to 12 characters containing all three of the following characteristics: uppercase letters (A-Z), lowercase letters (a-z), numbers (0-9), or special characters.',
    loadManuals: 'Load Manuals',
    leadList: 'Require list',
    listCuttingCycle: 'List of cutting cycles',
    listManuals: 'List manuals',
    listInvoices: 'In review',
    listPayments: 'Payment List',
    load: 'Load',
    loadBillingCycle: 'Upload Billing Cycles',
    loading: 'Loading...',
    loadingContent: 'Cargando contenido ...',
    loadInvoice: 'Load \ninvoice',
    loan: 'Disposition',
    loanDate: 'Disposición date',
    loanPeriod: 'Loan period',
    logs: 'Logs',
    logout: 'Logout',
    logTransaction: 'Log transaction',
    logView: 'Log View',
    operationLog: 'Event Log',
    mandatoryFieldsRequired: '(*) You have to fill up all the marked fields.',
    mainUser: 'Main User',
    mainInvoiceNumber: 'Main invoice number',
    manualsAndFaq: 'Manuals and FAQ\'s',
    manualLoaded: 'Manual loaded successfully',
    manualResolution: 'Manual Resolution',
    mdr: 'MDR',
    markAsPaid: 'Mark as paid',
    message: 'Message',
    messageUserProcess: 'You do not currently have a configured approval flow type. Please contact your representative to activate this functionality.',
    merchantAffiliateCode: 'Affiliate number',
    minimumCharacter: 'Business Affiliation Number, must have 10 characters',
    monthly: 'Monthly',
    monday: 'Monday',
    month: 'Month',
    name: 'Name',
    nameFantasy: 'Fantasy Name',
    newBank: 'New bank',
    newPassword: 'New password',
    newPaymentCondition: 'New Payment Conditions',
    newRecharge: 'New Recharge',
    newRole: 'New role',
    newTransfer: 'New Transfer',
    next: 'Next',
    nextCollection: 'Next collection',
    nextLoanExpiration: 'Next expirations of line',
    nextSellerPayments: 'Next supplier payments',
    nextDays: 'Next days',
    profileList: 'User List',
    nextPage: 'Next',
    nextPayment: 'Next payment',
    nInvoices: 'No. of Invoices',
    no: 'No',
    noCycles: 'There are no loaded cutting cycles for: {{month}}',
    notVerified: 'Not verified',
    noCard: 'You don\'t have a prepaid Card', //
    noData: 'No information',
    noInformation: 'No Information',
    noItemsAvailable: 'No industries available',
    noExecutivesAvailable: 'No business executive available',
    nonMatchingPasswords: 'The passwords don\'t match.',
    noRowsFound: 'No information',
    notifyCardPaymentAuthorization: 'Notify card payment authorization',
    noTransactions: 'This Card doesn\'t have movements',
    notYetFound: 'The invoice is not yet available in the SII, if you wish you can upload it and B2B Enterprise will take care of retrying the search for it and uploading it to our platform, otherwise cancel the operation and try to upload it again later',
    null: '',
    numberInstallments: 'Number of fees',
    numberTta: 'TTA number',
    of: 'of',
    operator: 'Condition',
    operationVolume: 'Vol. Operations',
    originAccount: 'Origin account',
    params: 'Params',
    page: 'pages',
    paid: 'Paid',
    paidAmount: 'Amount Paid',
    paidInvoices: 'Invoices paid',
    paidOut: 'Paid out',
    password: 'Password',
    passwordDigitalCertificate: 'Digital certificate password',
    passwordHasExpired: 'Your password has expired, please enter your new password',
    payedAmounts: 'Daily total billed', //
    payment: 'Payment',
    datePayment: 'Payment date',
    paymentDateInvoice: 'Bill collection date:',
    paymentConditionProvider: 'Commercial conditions for suppliers of',
    paymentConditionClients: 'Commercial conditions for customers of',
    permanentlyRejectCommercialStatus: 'Permanently reject commercial status',
    paymentClient: 'The client pyment is',
    paymentCondition: 'Condition',
    paymentCurrency: 'Payment currency',
    paymentDate: 'Payment date',
    paymentDeadline: 'Payment deadline',
    paymentCapital: 'Payment capital',
    paymentInterest: 'Payment interest',
    paymentReceived: 'Payment received',
    paymentOrders: 'Payment orders',
    paymentTax: 'Payment tax',
    paymentsTransfers: 'Payments and Transfers',
    platformAdministrator: 'Platform administrator',
    platformManuals: 'Platform manuals',
    person: 'Person',
    pending: 'Pending',
    pendingToApprove: 'Pending to approve',
    pendingInvoices: 'Pending Inv.',
    percentage: 'Anual interest percentage',
    period: 'Period',
    prepaidCard: 'Prepaid Card',
    present: 'Present',
    previous: 'Previous',
    previous_page: 'Previous',
    printQR: 'Print QR',
    product: 'Product',
    productUnsubscribedSuccessfully: 'Product unsubscribed successfully',
    productContracted: 'Product contract request: {{name}}, sent successfully',
    productsContracted: 'Contracted products',
    productName: 'Product name',
    profileName: 'Profile name',
    provider: 'Provider',
    providerPaymentCondition: 'Determine the payment terms for this provider',
    providerName: 'Provider\'s name',
    providers: 'Providers',
    profile: 'Profile',
    profiles: 'Profiles',
    profilesList: 'Profiles list',
    qualify: 'Enable',
    QuoteAndRequest: 'Quote And Request',
    rate: 'Porcentaje',
    request: 'Request',
    requestsSent: 'Requests sent',
    requestsReceived: 'Requests received',
    requestSentProduct: 'Request sent',
    requestUnsubscribe: 'Request unsubscribe',
    requestEdition: 'Request edition',
    requestSent: 'Request sent',
    requestReceived: 'Received request',
    requestsReceivedAmount: 'Request received ({{amount}})',
    requiredFields: '(*) Required fields',
    receivedInvoices: 'Invoices Received',
    receivedRequest: 'Received request',
    receiverDiscountShare: 'Provider payment percentage',
    receptionDate: 'Invoice issue date',
    recharge: 'Recharge',
    recipient: 'Recipient',
    recoverPassword: 'Recover Password',
    registerAdministrator: 'Register administrator',
    registerCard: 'Register Card',
    registeredCards: 'Registered cards from: {{name}}',
    registerCardAmex: 'Enter American Express card',
    listCards: 'Card list',
    cards: 'Cards',
    savedCards: 'Saved credit cards',
    secureCards: 'Your credit card information will be stored under strict security regulations',
    insertYourEmailAddress: 'Insert your email address.',
    successEmail: 'An email has been sent to your email address.',
    successfullyUpdatedManual: 'Successfully updated manual',
    enterYourNewPassword: 'Enter your new password',
    registrationStatus: 'Registration status',
    dateNotAvailable: 'Date not available',
    dateRange: 'Date range',
    registerBank: 'Register bank',
    registerRole: 'Register role',
    registerRoute: 'Register route',
    reject: 'To refuse',
    rejectedCycle: 'Billing cycle successfully rejected.',
    rejectedInvoices: 'Rejected Inv.',
    relatedUsers: 'Related Users',
    rememberToAcceptTheTerms: 'Remember to accept the terms and conditions to contract the product',
    removeRouteSuccess: 'The route was removed succeffully',
    reports: 'Reports',
    requiredInstallments: 'The number of dues is invalid', //
    requiredName: 'The name is invalid', //
    requiredPeriod: 'The period is invalid', //
    requiredRate: 'The rate is invalid', //
    requiredTypeFinance: 'The financing type is invalid', //
    requireSuccess: '{{name}} required succeffully',
    resource: 'Resource',
    rejectReason: 'Reject Reason',
    rejectWithEdition: 'Reject with edition request',
    replaceTheSelectedCard: 'You are sure to replace the selected card.',
    respJson: 'Response JSON',
    respXml: 'Response XML',
    response: 'Response',
    return: 'Return',
    roleCantBeEmpty: 'The role can\'t be empty',
    roleList: 'Role List',
    roles: 'Roles',
    rol: 'Rol',
    roleType: 'Rol type',
    rolName: 'Rol name',
    rolType: 'Rol type',
    routeList: 'Route list',
    routes: 'Routes',
    rows: 'Rows',
    save: 'Save',
    safetyRules: 'We need to connect your company to the SII to be able to obtain the invoices issued by your suppliers to your social reason, for this we thank you for entering the following data:',
    saturday: 'Saturday',
    sunday: 'Sunday',
    seeDetail: 'See detail',
    seeConditions: 'See conditions',
    seeCustomers: 'See customers',
    seeUsers: 'See users',
    Select: 'Select ...',
    selectANewCommercialExecutive: 'You must select a new commercial executive',
    SelectDocumentType: 'Select a document type',
    SelectBank: 'Select a bank',
    selectCard: 'TTA selected',
    selectMonth: 'You must select a month',
    selectCycle: 'You must select all cycles',
    SelectOption: 'Select an option',
    selectOrTypeItems: 'Select or type industries ...',
    selectOrTypeRoutes: 'Select or type the route',
    selectOrTypeRol: 'Select or type the roles',
    selectOrTypePermissions: 'Select or type the permissions',
    selectProfile: 'Select Profile',
    selectRecipient: 'SelectSingle recipient',
    sellerCreateLead: 'Requires provider registration',
    sellerName: 'Provider name',
    send: 'Submit',
    sendDate: 'Send date',
    senderDiscountShare: 'Customer payment percentage',
    sentInvoices: 'Invoices \nsent',
    sendInvoice: 'Invoice shipping date',
    shouldNotSelect: 'You should not select the same commercial executive, who wants to eliminate',
    settings: 'Settings',
    seeHow: 'See how',
    shippingDate: 'Shipping date',
    showQR: 'Show QR code',
    since: 'From',
    startDate: 'Start date',
    endingDate: 'Ending date',
    source_log: 'Request Origin',
    startCountingFrom: 'Days agreed from',
    successExecutive: 'Commercial executive created successfully',
    successCard: 'Your card has already been registered.',
    successCycle: 'Billing cycle confirmed successfully.',
    successCycles: 'Successfully confirmed billing cycles',
    summary: 'Summary',
    targetNumber: 'Target number',
    taxOrdinary: 'Ordinary tax',
    taxLatePayment: 'Tax on arrears',
    termsAndConditions: 'Terms and conditions',
    transactionsToProcess: 'Transactions to process',
    toDisable: 'To disable',
    total: 'Total',
    totalAmount: 'Total import',
    totalAmountToPayOnTheInvoice: 'Total amount to pay on the invoice',
    toBeApproved: 'To be approved',
    totalBuyers: 'Total Paying Companies',
    totalBuyersDeferred: 'Companies with Deferred Invoices',
    totalBuyersInvoiced: 'Companies with Approved Invoices',
    totalCommissionAmount: 'Total commission',
    commission: "Commission",
    totalCharged: 'Total charged {{month}}',
    totalDebtAmount: 'Total amount to pay',
    totalInvoice: 'Total invoice',
    totalInvoices: 'Total invoices',
    totalOwedAmount: 'Total amount receivable',
    totalPay: 'Total to pay',
    totalReject: 'Total reject',
    totalToPayCardholder: 'Total to Pay Cardholder Service Charge',
    totalToPayInvoice: 'Total to Pay Invoice',
    totalVolumePendingPayment: 'Total volume pending payment',
    totalSellers: 'Affiliated Providers',
    totalSellersCharged: 'Providers with Approved Invoices',
    totalSellersInvoiced: 'Suppliers with Pending Invoices',
    totalToApprove: 'Total to approve',
    totalToReceiver: 'Total to receiver',
    tradeInformation: 'Trade information',
    transactionType: 'Transaction type',
    transfer: 'Transfer',
    transferCharge: 'Operation charge',
    transferDetail2: 'Voucher',
    transferDetail3: 'Send to',
    transferDetail4: 'Amount send',
    transferDetail5: 'Discoundted total amount',
    transferDetail: 'Succesfull Transfer',
    transferState: 'State',
    transferType: 'Transaction type',
    TtaValidation: 'TTA validation',
    tuesday: 'Tuesday',
    theCodeExpiresIn: 'The code expires at: {{time}}',
    theInvoiceWillBeCharged: 'The invoice will be charged, {{status}}',
    thursday: 'Thursday',
    theyMustNotHave: '* They must not have identical consecutive characters, neither totally numeric, nor totally alphabetic.',
    type: 'Tipo',
    typeFinance: 'Tipo de financiamiento',
    typeOfApprovalFlow: 'Type of approval flow',
    typeOfEmitFlow: 'Emission flow type',
    typeAndDocumentNumber: 'Type and document number',
    underReview: 'In Review',
    underReviewInvoices: 'Invoices Under Review',
    until: 'To',
    updatedBillingCycles: 'Updated billing cycles',
    updateBank: 'Update bank',
    updateInformation: 'Update information',
    user: 'User',
    userManual: 'User manual',
    username: 'User name',
    usernamePlaceholder: 'Ex: user123',
    userQuery: 'User query',
    userBillingCycles: 'User billing cycles',
    Unsubscribe: 'Unsubscribe',
    verificationCode: 'Verification code',
    verificationCodeSent: 'Enter the verification code sent to your email',
    view: 'Watch',
    virtualAccount: 'Virtual account',
    waitingForApproval: 'Waiting for approval of request to cancel the service',
    yearly: 'Annual',
    transactions: 'Transactions',
    totalReceiver: 'Total to receiver',
    providersReceiver: 'Providers to receive',
    clientsPay: 'Customers payable',
    clients: 'Clients',
    selectedBank: 'Bank',
    uploadInvoice: 'Upload invoice',
    loadInvoiceData: 'Load invoice Data',
    naoReter: 'Not retain IR (advertising, publicity, commissions and correctje.)',
    invoiceApprovedSuccess: 'Invoice approved sucessfully',
    invoiceDeclinedSuccess: 'Invoice declined sucessfully',
    invoiceRejectedSuccess: 'Invoice rejected sucessfully',
    actionNotAllowed: 'Action not allowed',
    disable: 'To disable',
    status: 'Status',
    statusOfInvoice: 'The status of invoice N ° {{invoiceNumber}} in the SII is: {{status}}.',
    cannotBeUploaded: 'Therefore the invoice cannot be uploaded to B2B Enterprise',
    clientPay: 'Client pay',
    referrer: 'Referrer',
    buyer: 'Client',
    seller: 'Provider',
    limit: 'Limit',
    limitAmount: 'Limit amount',
    new: 'New',
    paymentMethod: 'Payment method',
    target: 'Target',
    line: 'Line',
    defaultFlow: 'Default flow',
    condition_amount: 'Amount',
    invoiceNumberValidate: 'The format must be xxx-xxx-xxxxxxxxx',
    amountValidate: 'The amount must be positive',
    cantCreateTransaction: 'The invoice number already exists',
    penddingInvoices: 'You have {{pending_invoices}} pendding invoice without approve',
    rejectInvoices: 'You have {{reject_invoices}} invoice(s) rejected',
    rejectInvoiceAll: 'Rejected invoices',
    rejectedRequests: 'Rejected requests ({{amount}})',
    emitInvoices: 'You have {{emit_invoices}} invoice(s) without emit',
    search: 'Search',
    operationRank: 'Range of operations (from)',
    maxRank: '(to)',
    support: 'Support',
    main_bank: 'Bank Main User',
    user_list: 'User list',
    edit_user: 'Edit User',
    acquiring_query: 'Acquiring Consultation Bank',
    issuing_query: 'Issuing Consultation Bank',
    enable: 'Enable',
    transactionReport: 'Transaction report',
    paymentDateRange: 'Bill collection date range',
    paymentDateRangeClient: 'Bill payment date range',
    identificationNumber: 'Identification number',
    time: 'Hour',
    event: 'Event',
    info: 'Info',
    infoLog: 'Event information',
    editFlowApprovalSuccess: 'Approval flow edited successfully',
    emissionFlow: 'Emission Flow',
    affiliationCode: 'Merchant Affiliate Code ',
    affiliateNumber: 'Affiliate number',
    validCard: 'Verified',
    invalidCard: 'To be checked',
    RUT: 'RUT',
    conditions: 'Conditions',
    phone: 'Phone number',
    contactPhone: 'Contact phone',
    yes: 'Yes',
    yourDataWillBeStoredEncrypted: '*Your data will be stored encrypted under strict security regulations.',
    validity: 'Validity',
    waitingBillingCycle: 'Waiting for billing cycle assignment',
    billingCycle: 'Billing cycle',
    billingCycles: 'Billing cycles',
    cardList: 'Card list',
    permissions: 'Permissions',
    region: 'Region',
    commune: 'Commune',
    grant: 'Grant',
    deny: 'Deny',
    viewTta: 'See TTA',
    webpay: 'Webpay',
    wednesday: 'Wednesday',
    getnet: 'Getnet',
    gateWay: 'Gateway',
    main_bank_issuing: 'Main User of the Issuing Bank',
    main_bank_acquiring: 'Main User of the Acquiring Bank',
    currency: 'Currency',
    currentCycle: 'Current Cycle',
    bankingExecutives: 'Business executive',
    disabled: 'Disabled',
    enabled: 'Enabled',
    assignedUsers: 'Assigned Users',
    newBankingExecutive: 'New Banking business executive',
    rate_operator: 'Financing Rate Operator',
    cycle_loader: 'Invoice Cycle Loader',
    requestProducts: 'Request your insurance or assistance',
    contractedProducts: 'Contracted insurance',
    products: 'Insurance / Assistance',
    cost: 'Cost',
    contract: 'Contract',
    registerProduct: 'Register Product',
    registerProfile: 'Register profile',
    insurances: 'Insurances',
    contractRequest: 'Contract Request',
    unsuscribeRequest: 'Unsuscribe Request',
    productType: 'Product Type',
    emailLabel: 'name@company.cl',
    fantasyName: 'Fantasy name',
    b2bMDR: 'B2B Enterprise Rate (MDR)',
    providerB2BRate: 'B2B rate paid by provider',
    clientB2BRate: 'B2B rate payable by the client is',
    verifyHuman: 'Please verify that you are human',
    selectInvoice: 'Select invoice',
    paymentAmount: 'Payment amount',
    valorNeto: 'Total value',
    vendorMerchantAffiliateCodes: 'Vendor Affiliate Number: {{provider}}',
    volumeProcessed: 'Volume processed in the last three months',
    invoiceTerms: 'By approving invoices within the B2B Enterprise platform, I accept the charge that will be made to my Treasurer American Express Card, for the amount that is individualized in each invoice, and the amount of the service charge, if applicable according to the commercial conditions agreed with your provider. Irrevocably instructs AMEX, so that such charges are made on the aforementioned Card.',
    main_bank_issuing_amex: 'Administrator User of the AMEX Issuing Bank',
    clientPercentageCharge: 'Buyer – Service charge percentage',
    providerPercentageCharge: 'Supplier – Service charge percentage',
    adminBank: 'Administrator Bank',
    bankInformation: 'Selected Bank Information',
    selectBank: 'You must select a Bank',
    noManuals: 'No hay manuales cargados',
    street: 'Calle',
    number: 'Número',
    otherSign: 'Reference',
    buyerCode: 'Client commerce code',
    sellerCode: 'Provider commerce code',
    january: 'January',
    february: 'February',
    march: 'March',
    april: 'April',
    may: 'May',
    june: 'June',
    july: 'July',
    august: 'August',
    september: 'September',
    october: 'October',
    november: 'November',
    december: 'December',
    updatedBillingCycle: 'Updated billing cycle',
    enterTheCardBillingCycle: 'You need to enter the card\'s billing cycle to continue operating with that card',
    successfullyAssignedCuttingCycle: 'Successfully assigned cutting cycle',
    validEmail: 'You must enter emails that belong to @ baccredomatic.com',
    providerGroup: 'Provider group',
    customerGroup: 'Customer group',
    suggestTradeConditionCorrection: 'Suggest trade condition correction',
    rejectTradeRequest: 'Reject trade request',
    esp: 'ESP',
    por: 'POR',
    eng: 'ENG',
    province: 'State',
    canton: 'Capital',
    myLoans: 'Loans',
    approveds: 'Approved',
    loanNumber: 'Loan number',
    totalBalance: 'Total balance',
    minimumPayment: 'Minimum payment',
    toPay: 'To pay',
    outdoorNumber: 'Outdoor number',
    interiorNumber: 'Interior number',
    suburb: 'Suburb',
    municipality: 'Delegation or municipality',
    postalCode: 'Postal code',
    authorizedBy: 'Approved by',
    RFC: 'RFC {{type}}',
    haveNotifications: 'Have notifications',
    receivingBank: 'Receiving bank',
    requestRejected: 'Sent with rejection',
    uniqueEmail: 'The email must not been registered before in the site as business executive',
    invoicesSent: 'Invoices sent',
    rejectInvoice: 'Rejected',
    affiliationNumber: 'Affiliation number',
    rejectedBy: 'Rejected by',
    failedPayment: 'Failed payment',
    incidence: 'Incidence',
    amountArranged: 'Amount arranged',
    pendingProvisions: 'Pending provisions',
    totalAmountDisposed: 'Total amount disposed',
    dispositionDate: 'Disposition date',
    balancePerDay: 'Balance per day',
    generateReferencePayment: 'Generate reference for payment',
    generatedReference: 'Number of reference, {{reference}}',
    accountClabe: 'Account clabe',
    accountCie: 'Account cie',
    assignedName: 'Assigned name',
    emailExample: 'ejemplo@correo.com',
    keoEdition: 'AMEX Status edition',
    declinedInvoices: 'Declined invoices',
    declined: 'Declined',
    apiRequests: 'API Requests',
    origin: 'Origin',
    responseStatus: 'Response status',
    replyMessage: 'Reply message',
    paymentInformation: 'Payment information',
    invoiceAmount: 'Invoice amount',
    unapprovedInvoices: 'Unapproved pending invoices ({{pending_invoices}})',
    processInvoicesCant: 'Invoices in payment process ({{in_process_invoices}})',
    chargeInvoices: 'You have {{in_process_invoices}} invoice(s) in the process of charge',
    gatewayInformation: 'Gateway information',
    chargeAttempt: 'Charge attempt',
    executionDate: 'Execution date',
    timeLimit: 'Time limit',
    retries: 'Retries',
    retry: 'Retry {{cant}}',
    tense: 'Time',
    errorHour: 'You cannot enter more than 24 hours',
    errorHour1: 'You cannot enter less than 1 hour',
    hour: 'Hours',
    hours: 'Hours',
    errorMinute: 'You cannot enter more than 60 minutes',
    errorMinute1: 'You can not enter less than 3 minutes',
    minute: 'Minute',
    minutes: 'Minutes',
    cannotAddMore: 'Cannot add more than 10 retries',
    errorInTheRow: 'Error en la fila {{row}}, {{error}}',
    retriesLoaded: 'Retries loaded successfully',
    information: 'Information',
    invoiceDateAutomatically: 'The Invoice Date field is now automatic, consider the day the request is made',
    dateTheInvoice: 'The date now corresponds to the day the invoice is uploaded',
    responsiveMsg: 'To get full control of the ABL web app we strongly recommend use the desktop version.',
    transactionDate: 'Transaction date',
    TDCUserConfirm: 'If you decide to continue, the supplier will be created with the TDC payment type.',
    NCLUserConfirm: 'If you decide to continue, the supplier will be created with the NCL payment type., it means that it will only could receive payments by SPEI transfers.',
    RFCValidation: 'RFC must match this format AAA0000000A0',
    wantToContinue: 'Do you want to continue?',
    createNCLCustomer: 'Create NCL customer',
    clientWithACG: 'The client will be created with the NCL payment type, that is, they will only be able to make payments through SPEI transfers',
    createdNCL: 'If you continue with the operation, the customer will be created with the NCL payment type, that is, you will only be able to make payments through SPEI transfers',
    wishToContinue: 'do you wish to continue?',
    countryCode: 'Country code',
    areaCode: 'Area code',
    paymentType: 'Payment type',
    newCondition: 'New condition',
    NCLCommission: 'NCL commission',
    sentCondition: 'Request sent between the {{client}} and the {{supplier}} with {{number}} agreed days from {{type}} and a commission of {{commission}}',
    NCLComission: 'NCL comission',
    nclInvoiceNotification2: 'The invoice has got NCL payment type, so, your payment will be executed by SPEI transfer outside the Business Link platform',
    nclInvoiceNotification: 'The invoice will be uploaded with the NCL payment type, so, your payment will be executed by SPEI transfer outside the Business Link platform',
    nclInvoiceApprovementNotification: 'The invoice payment will be executed by SPEI transfer outside the Business Link platform',
    invoicesNcl: 'NCL payments',
    proofPayment: 'Proof of payment',
    process: 'Process',
    downloadPayment: 'Download proof of payment',
    nclPaymentSuccessfullyRejected: 'Successfully declined NCL payment',
    nclPaymentProcessedSuccessfully: 'NCL payment processed successfully',
    AllowedFormats: 'Allowed formats: PDF and JPG.',
    errorLoadFile: 'You must upload a file with extension {{type}} or {{type1}}',
    paymentRegisteredSuccessfully: 'Payment registered successfully',
    downloadAttached: 'Download attached',
    NCLSupplierCommission: 'NCL supplier commission',
    scheduledCollectionDate: 'scheduled collection date',
    scheduledDateTheProvider: 'Scheduled payment date',
    effectiveDatePayment: 'Effective date of payment',
    effectiveDateCollection: 'Effective date of collection',
    switchProfile: 'Switch Profile',
    usd: 'USD',
    mxn: 'MXN',
    mexicanPesos: 'Mexican pesos',
    americanDollar: 'American dollar',
    relationshipInactive: 'Condition cannot be approved because the relationship is inactive',
    relationshipStatus: 'Relationship status',
    a: 'Asset',
    i: 'Inactive',
    c: 'Cancelled',
    p: 'Outstanding',
    r: 'Rejected',
    noLogRecord: 'We sorry, there is no payment log info',
    allInvoices: 'All invoices',
    processInvoices: 'Invoices in payment process',
    paymentProcess: 'Payment process',
    mode: 'Modality',
    withInvoice: 'With invoice',
    withoutInvoice: 'Without invoice',
    savePaymentSuccess: 'Payment saved successfully',
    sendPaymentSuccess: 'Payment sent successfully',
    editPaymentSuccess: 'Payment edited successfully',
    deletePaymentSuccess: 'Payment deleted successfully',
    paymentRecord: 'Payment Record',
    editPayment: 'Edit Payment',
    relatedEmails: 'Aditional emails',
    instructionDetail: 'Instruction detail',
    noInfoMsg: 'No available data',
    traditional: 'Traditional',
    confirmPayment: 'Are you shure you want to send a payment for {{currency}} ${{amount}} to provider {{provider}} now?',
    confirmDeletePayment: 'Are you sure you want to delete the payment for {{amount}} to provider {{provider}}?',
    notZeroAmount: 'The amount should be greater than zero',
    instructionNumber: 'Instruction number',
    bpp: 'BPP',
    selectAProvider: 'Select a provider',
    newPayment: 'New payment',
    BPPDesc: 'Buyer Pushed Payment allows the customer make payments to an invoice which has not yet been generated by the supplier.',
    commerceRelationshipID: 'Commerce relationship ID',
    validInvoiceNumber: 'The invoice number cannot be empty or a symbol',
    '/recent-invoices': 'Recent invoices',
    '/declined-invoices': 'Declined invoices',
    '/process-invoices': 'Invoices in payment process',
    '/create-payment': 'New payment',
    '/payment-record': 'Payment Record',
    '/payment-detail': 'Instruction detail',
    '/payment-log': 'Gateway information',
    '/edit-payment': 'Edit',
    '/update-retry-payment': 'Retries',
    '/approved-loan': 'My loans',
    '/create-provider': 'Create providers',
    '/edit-provider': 'Edit providers',
    '/create-customer': 'Create customers',
    '/edit-customer': 'Edit customers',
    '/create-profile': 'Crear profile',
    '/dashboard': 'Main screen',
    '/account-details': 'Account details',
    '/extract': 'Extract',
    '/prepaid-Card': 'Prepaid card',
    '/transfer': 'Transfer',
    '/deposit': 'Deposit',
    '/load-invoice': 'Load invoices',
    '/customers': 'List, edit or disable clients',
    // '/invoices': 'List, edit, approve or reject invoices without approving',
    '/invoices': 'Invoices',
    '/expired-invoices': 'List of expired invoices',
    '/related-users': 'Related users', //REVISAR
    '/clients': 'Clients',
    '/providers': 'Providers',
    '/register-bank': 'Create banks',
    '/bank-list': 'List, edit or disable banks',
    '/role-list': 'List, edit and delete roles',
    '/invoice-detail': 'Invoice Detail',
    '/register-financing-products': 'Create a financing product',
    '/financing-products': 'Finance products',
    '/register-role': 'Create roles',
    '/route-list': 'List, edit and delete routes',
    '/register-route': 'Create routes',
    '/admin-routes': 'Assign or remove routes to roles',
    '/review-invoices': 'List, edit, approve or reject invoices without approving',
    '/reject-invoices': 'Rejected Invoices',
    '/approved-invoices': 'List, advance or defer approved invoices',
    '/paid-invoices': 'List paid invoices',
    '/pending-invoices': 'Pending invoices',
    '/create-lead': 'Require customers or providers',
    '/lead-list': 'List, approve or reject required',
    '/reports': 'Reports',
    '/payment-conditions-log': 'Payments conditions log',
    '/transactions': 'Bank generated invoices',
    '/profile-list': 'Manage users',
    '/create-approval-flow': 'Create invoice approval flow',
    '/edit-approval-flow': 'Edit invoice approval flow',
    '/approval-flow-list': 'Approval flow list', 
    '/account-profile': 'Account profile',
    '/payment-conditions': 'Payment conditions',
    '/create-bank-user': 'Create user',
    '/edit-bank-user': 'Edit user',
    '/bank-user-list': 'User list',
    '/register-card': 'Register card',
    '/replace-card': 'Credit card replacement',
    '/list-cards': 'List of cards',
    '/payment-request-sent': 'Requests',
    '/payment-request-received': 'Requests',
    '/save-credentials': 'Save credentials',
    '/edit-credentials': 'Edit credentials',
    '/load-billing-cycle': 'Load billing cycle',
    '/edit-billing-cycle': 'Edit billing cycle',
    '/billing-cycles': 'Billing cycles',
    '/create-banking-executive': 'Create executive',
    '/load-executives': 'Load executives',
    '/products': 'Products',
    '/contracted-products': 'Contracted producsts',
    '/product-detail': 'Product details',
    '/accept-discount-rate': 'Accept discount rate',
    '/edit-bank-admin': 'Edit admin',
    '/save-manual': 'Save manual',
    '/list-manual': 'List manual',
    '/support': 'Support',
    '/monthly-report': 'Monthly report',
    '/commerce-code': 'Affiliate number',
    '/user-commerce-code': 'Affiliate number',
    '/confirm-provider': 'Confirm provider',
    '/confirm-customer': 'Confirm customer',
    '/create-payment-condition': 'Create payment condition',
    '/edit-payment-condition': 'Edit payment condition',
    '/provider-group-load': 'Load provider group',
    '/customer-group-load': 'Load customer group',
    '/list-provider': 'Providers list',
    '/list-customer': 'Customers list',
    '/create-admin-bank': 'Create admin bank',
    '/edit-admin-bank': 'Edit admin bank',
    '/admin-bank-list': 'Managing banks',
    '/resp': 'Card storage confirmation',
    '/log-view': 'Logs',
    '/operation-log': 'Logs',
    '/mixed-payments': 'Schedule mixed payment',
    '/credit-request': 'Credits and loans',
    '/credits-list': 'Credits List',
    '/check-deposit': 'Checks to deposit',
    '/approved-checks': 'Approved checks',
    '/not-approved-checks': 'Checks to be approved',
    '/rejected-checks': 'Rejected checks',
    '/send-information': 'Send ERP',
    '/banking-executives': 'Business executives',
    '/payment-request': 'Requests',
    '/disposition-report': 'Disposition report',
  },
};

export default en;
