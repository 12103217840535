import types from './types';

const flowApproval = (state = {}, action) => {
  switch (action.type) {
    case types.START_FETCH: {
      return {
        ...state,
        loading: true,
      };
    }
    case types.END_FETCH: {
      const { error, payload } = action;
      return {
        ...state,
        loading: false,
        error: error ? payload : null,
      };
    }
    case types.UPDATE_FLOW_APPROVAL: {
      const { list } = action.payload;

      return {
        ...state,
        list,
      };
    }
    case types.UPDATE_ACTIVE_FLOW: {
      const { payload } = action;

      return {
        ...state,
        activeFlow: payload,
      };
    }
    case types.UPDATE_PROCESS: {
      const { payload } = action;

      return {
        ...state,
        process: payload,
      };
    }
    case types.SELECTED_ID: {
      const { payload } = action;

      return {
        ...state,
        selectId: payload,
      };
    }
    default:
      return state;
  }
};

export default flowApproval;