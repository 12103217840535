import { credentialsActions, credentialsTypes } from '../ducks/credentials';

const savedCredentials = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== credentialsTypes.SAVED_CREDENTIALS) {
    return;
  }

  const {
    data,
    callback = () => {
    },
  } = action.payload;

  dispatch({
    type: credentialsTypes.API_CALL,
    payload: {
      config: {
        method: 'POST',
        url: 'b2b/save-credentials',
        data,
      },
      authorization: true,
      onStart: credentialsActions.startFetch,
      onComplete: ({ data }) => {
        callback(data);
      },
      onEnd: credentialsActions.endFetch,
    },
  });
};

const getCredentials = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== credentialsTypes.GET_CREDENTIALS) {
    return;
  }

  const {
    callback = () => {
    },
  } = action.payload;

  dispatch({
    type: credentialsTypes.API_CALL,
    payload: {
      config: {
        method: 'GET',
        url: `b2b/get-credentials`,
      },
      authorization: true,
      onStart: credentialsActions.startFetch,
      onComplete: ({ data: { success, data } }) => {
        if (success) {
          callback(data);
          dispatch(credentialsActions.savedData(data));
        }
      },
      onEnd: credentialsActions.endFetch,
    },
  });
};

const updateCredentials = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== credentialsTypes.UPDATE_CREDENTIALS) {
    return;
  }

  const {
    data,
    callback = () => {
    },
  } = action.payload;

  dispatch({
    type: credentialsTypes.API_CALL,
    payload: {
      config: {
        method: 'POST',
        url: `b2b/update-credentials`,
        data,
      },
      authorization: true,
      onStart: credentialsActions.startFetch,
      onComplete: ({ data: { success, data } }) => {
        if (success) {
          callback(data);
        }
      },
      onEnd: credentialsActions.endFetch,
    },
  });
};

export default [savedCredentials, getCredentials, updateCredentials];