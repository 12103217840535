/*
 Constante para definir el entorno en el que se encuentra la app
 Posibles valores:
 - production
 - stage
 - qa
 - dev
 - local
 */

export const ENV = 'qa';

// Constante para definir la version de la API
export const API_VERSION = 'v3';

// ENDPOINTS de la aplicacion en los diferentes entornos
// Nota: las claves de este objeto deben coincidir con el (o los) valores de la constante ENV
export const ENDPOINTS = {
  API: {
    production: '',
    stage: '',
    qa: 'https://api-demo-bac.medullaris.com',
    dev: 'https://api-dev-keo.medullaris.com',
    local: 'http://localhost/amex/business_amex/app/api/web',
  },
};
