import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import './styles.scss';
import { withNamespaces } from 'react-i18next';
import { Form, Panel, TextInput } from '../../../components';
import { transactionsActions } from '../../../state/ducks/transactions';
import { cardActions } from '../../../state/ducks/card';
import { TOAST_CONFIG } from '../../../config/constants';

class Recharge extends Component {
  constructor(props) {
    super(props);
    this.state = {
      amount: '',
    };
  }

  componentDidMount() {

  }

  handleInputChange = ({
    target: {
      type, value: v, checked, name,
    },
  }) => {
    const value = type === 'checkbox' ? checked : v;
    this.setState({
      [name]: value,
    });
  };

  handleSubmit = (event) => {
    event.preventDefault();

    const {
      transactions: { balance },
      rechargeCard,
      history,
      showMessage,
    } = this.props;
    const { amount: am } = this.state;
    const amount = parseFloat(am)
      .toFixed(2);

    const balance_number = parseFloat(balance.split(' ')[1])
      .toFixed(2);

    if (parseInt(amount, 0) === 0) {
      showMessage({
        message: 'Ingrese um monto valido',
        config: TOAST_CONFIG.INFO,
      });
    } else if (balance_number > amount) {
      const data = {
        amount,
        description: 'Recarga de Tarjeta',
        business: '',
        code: 123,
      };
      rechargeCard({
        data,
        callback: () => {
          history.replace('/prepaid-Card');
        },
      });
    } else {
      showMessage({
        message: 'Saldo insuficiente en la cuenta de B2B',
        config: TOAST_CONFIG.INFO,
      });
    }
  };

  render() {
    const { t, transactions: { balance } } = this.props;

    const today = new Date();
    const date = `${today.getFullYear()}-${
      today.getMonth() + 1}-${
      today.getDate()}`;

    return (
      <div className="columns">
        <div className="column">
          <Panel headingText={t('newRecharge')}>
            <div className="rechargeForm">
              <Form
                submitText={t('continue')}
                onSubmit={this.handleSubmit}
                buttonClasses="margin-right-5"
              >
                <div className="field is-horizontal">
                  <div className="field-body">
                    <TextInput
                      name="originAccount"
                      value={`${t('virtualAccount')} ${balance}`}
                      onChange={this.handleInputChange}
                      type="text"
                      label={t('originAccount')}
                      disabled
                      controlClasses="has-icons-left"
                      icon="far fa-envelope"
                      iconPosition="is-left"
                    />
                    <TextInput
                      name="destinationAccount"
                      value="Tarjeta Prepaga B2B"
                      onChange={this.handleInputChange}
                      type="text"
                      label={t('destinationAccount')}
                      disabled
                      controlClasses="has-icons-left"
                      icon="far fa-credit-card"
                      iconPosition="is-left"
                    />
                  </div>
                </div>
                <div className="field is-horizontal">
                  <div className="field-body">
                    <TextInput
                      name="amount"
                      label={t('amount')}
                      placeholder={t('amountMoney')}
                      onChange={this.handleInputChange}
                      type="number"
                      disabled={false}
                      fieldClasses="is-expanded"
                      controlClasses="is-expanded"
                      min="0.10"
                      step="0.01"
                    />
                  </div>
                </div>
                <div className="field is-horizontal">
                  <div className="field-body">
                    <TextInput
                      name="time"
                      label={t('date')}
                      value={date}
                      onChange={this.handleInputChange}
                      type="text"
                      disabled
                      fieldClasses="is-expanded"
                      controlClasses="is-expanded"
                      icon=""
                      iconPosition=""
                    />
                  </div>
                </div>
              </Form>
            </div>
          </Panel>
        </div>
      </div>
    );
  }
}

Recharge.propTypes = {
  transactions: PropTypes.shape(Object).isRequired,
  history: PropTypes.shape(Object).isRequired,
  rechargeCard: PropTypes.func.isRequired,
  showMessage: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

const mapStateToProps = ({ card, transactions }) => ({
  card,
  transactions,
});

export default compose(
  connect(mapStateToProps, { ...cardActions, ...transactionsActions }),
  withNamespaces(),
)(Recharge);
