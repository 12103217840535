import { gatewayActions, gatewayTypes } from '../ducks/gateway';

const fetchGateway = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== gatewayTypes.GET_GATEWAY) {
    return;
  }

  const {
    callback = () => {
      
    },
  } = action.payload;

  dispatch({
    type: gatewayTypes.API_CALL,
    payload: {
      config: {
        method: 'GET',
        url: `b2b/get-gateways`,
      },
      authorization: true,
      onStart: gatewayActions.startFetch,
      onComplete: ({ data: { success, data } }) => {
        if (success) {
          callback(data);
          //dispatch(gatewayActions.fetchGateway(data));
        }
      },
      onEnd: gatewayActions.endFetch,
    },
  });
};

const gatGatewayUser = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== gatewayTypes.GET_GATEWAY_USER) {
    return;
  }

  const {
    callback = () => {

    },
  } = action.payload;

  dispatch({
    type: gatewayTypes.API_CALL,
    payload: {
      config: {
        method: 'GET',
        url: `b2b/get-gateway-user`,
      },
      authorization: true,
      onStart: gatewayActions.startFetch,
      onComplete: ({ data: { success, data } }) => {
        if (success) {
          callback(data);
          //dispatch(gatewayActions.fetchGateway(data));
        }
      },
      onEnd: gatewayActions.endFetch,
    },
  });
};

export default [fetchGateway, gatGatewayUser];