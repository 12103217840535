const START_FETCH = 'gateway/START_FETCH';
const END_FETCH = 'gateway/END_FETCH';
const END_ERROR = 'gateway/END_ERROR';
const API_CALL = 'gateway/API_CALL';
const GET_GATEWAY = 'gateway/GET_GATEWAY';
const MESSAGE = 'gateway/MESSAGE';
const GET_GATEWAY_USER = 'gateway/GET_GATEWAY_USER';

export default {
    START_FETCH,
    END_FETCH,
    END_ERROR,
    API_CALL,
    MESSAGE,
    GET_GATEWAY,
    GET_GATEWAY_USER,
};