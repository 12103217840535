import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

const Form = ({
                className,
                onSubmit,
                children,
                submitText,
                buttonClasses,
                disabled,
                band,
                cancelButton,
                cancelText,
                onCancel,
                isBTNFull,
                savePayment,
                saveText,
                onSave,
                cancelPayment,
              }) => (
  <div className={ `Form ${className}` }>
    <form
      action=""
      onSubmit={ onSubmit }
    >
      { children }
      <div
        // className={`field is-grouped is-grouped${band === '#FFFFFF' ? '-right' : '-centered'} `}
        className='field is-grouped is-grouped-centered'
        style={{ width: !isBTNFull && '50%', margin: '30px auto' }}
      >
        {cancelButton &&
          <button
          style={{ color: '#C4C4C4', flex: '1', maxWidth: '200px' }}
          className={`button custom-btn is-block is-danger ${buttonClasses}`}
          onClick={onCancel}
          >
            { cancelText }
          </button>
        }
        {savePayment &&
          <button
            style={{ color: band, flex: '1', maxWidth: '200px' }}
            className={`button custom-btn is-block is-primary ${buttonClasses}`}
            onClick={onSave}
          >
            { saveText }
          </button>
        }
        <button
          type="submit"
          style={{ color: band, flex: '1', maxWidth: !isBTNFull && '200px' }}
          className={`button custom-btn is-block is-primary ${buttonClasses}`}
          disabled={ disabled }
        >
          { submitText }
        </button>
        {cancelPayment &&
          <button
          style={{ color: band, flex: '1', maxWidth: '200px' }}
          className={`button custom-btn is-block is-primary ${buttonClasses}`}
          onClick={onCancel}
          >
            { cancelText }
          </button>
        }
      </div>
    </form>
  </div>
);

Form.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.array,
  ]).isRequired,
  className: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  submitText: PropTypes.string.isRequired,
  buttonClasses: PropTypes.string,
  disabled: PropTypes.bool,
  cancelButton: PropTypes.bool,
  cancelText: PropTypes.string,
  onCancel: PropTypes.func,
  isBTNFull: PropTypes.bool,
  // loginForm: PropTypes.bool,
};

Form.defaultProps = {
  className: '',
  buttonClasses: '',
  disabled: false,
  band: '#FFFFFF',
  cancelButton: false,
  cancelText: '',
  isBTNFull: false,
  // loginForm: false,
};

export default Form;
