import types from './types';
import { authTypes } from '../auth';
import { initialState } from '../../../App';

const support = (state = {}, action) => {
    switch (action.type) {
        case authTypes.CLEAR: {
        return {
            ...state, ...initialState.card,
        };
        }
        case types.START_FETCH: {
        return {
            ...state,
            loading: true,
        };
        }
        case types.END_FETCH: {
        const { error, payload } = action;
        return {
            ...state,
            loading: false,
            error: error ? payload : null,
        };
        }
        /*case types.SAVE_EXECUTIVES_FILE: {
        const { payload } = action;
        return {
            ...state,
            fileExecutives: payload,
        };
        }*/
        default:
        return state;
    }
};

export default support;
