/**
 * Created by Feedback Software on 24/10/18.
 * @param children contain the body of this table, it's important use th or td inside tr
 * @param totalPages if is pass by params it means that must be added the pagination nav
 * @param title it is used by pass by params to the Panel for the title of this
 * @param head contain the head of the table, it's important pass th inside tr
 * @param currentPage manage de actual page in pagination
 * @param onChangePage manage the change of the page in the pagination, must change currentPage
 */

import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import { Loading, LoadInvoicesForm, Modal, Panel, Toggle, } from '../../components';
import { transactionsActions } from '../../state/ducks/transactions';
import { authActions } from '../../state/ducks/auth';
import { TransferDetail } from './components';
import { TOAST_CONFIG } from '../../config/constants';
import { clientsActions } from '../../state/ducks/clients';
import { providersActions } from '../../state/ducks/providers';
import './styles.scss';
import { gatewayActions } from '../../state/ducks/gateway';

class Providers extends Component {
  constructor(props) {
    super(props);
    const { t, transactions: { currency } } = props;
    this.state = {
      transaction_type: 'transfer',
      destination_phone_number: '',
      amount: '',
      invoice_date: new Date(),
      invoice_amount: null,
      invoice_number: null,
      invoice_description: '',
      client: null,
      product: currency,
      transfer_date: Date.now(),
      detail: '',
      options: [
        {
          text: t('extractAccount'),
          value: 1,
        },
      ],
      currency: '',
      description: '',
      commerceCodes: [],
      descriptionCommerceCode: '',
      commerceCode: -1,
      file: undefined,
      binaryFile: null,
      xmlFile: undefined,
      binaryXMLFile: null,
    };
  }

  getInitialState = () => {
    const { t, transactions: { currency } } = this.props;
    this.setState({
      transaction_type: 'transfer',
      destination_phone_number: '',
      amount: '',
      invoice_date: new Date(),
      invoice_amount: null,
      invoice_number: null,
      invoice_description: '',
      client: null,
      product: currency,
      transfer_date: Date.now(),
      detail: '',
      options: [
        {
          text: t('extractAccount'),
          value: 1,
        },
      ],
      showForm: true,
      uploadQrLoading: false,
      detail_amount: null,
      inputValue: '',
      type: -1,
      gateway: false,
      commerceCodes: [],
      descriptionCommerceCode: '',
      commerceCode: -1,
      currency: '',
      file: undefined,
      binaryFile: null,
      xmlFile: undefined,
      binaryXMLFile: null,
    });
  };

  componentDidMount() {
    const {
      getClients,
      //getTypeDte,
      //getGatewayUser,
      auth: { user: { b2b: { id } } },
    } = this.props;
    this.getInitialState();
    getClients({
      params: {
        id,
        related: true,
      },
    });
  }

  resetState = () => {
    this.getInitialState();
  };

  formatNumber = (n) => {
    return n.replace(/\D/g, '')
      .replace(/([0-9])([0-9]{2})$/, '$1.$2')
      .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ',');
  };

  validateInvoiceNumber = (n) => {
    if(n.match(/^(?!.*[,])(?![-/. ])(?!.*[-/. ]$)(?!.*[ -/. ]{2})[a-zA-Z0-9]+([ -/.][a-zA-Z0-9]+)*$/)){
      return true;
    }
    return false;
  }

  handleInputChange = (event) => {
    const {
      target: {
        checked, value: targetValue, type, name
      },
    } = event;
    const value = type === 'checkbox' ? checked : targetValue;
    
    if (name === 'invoice_amount') {
      let num = this.formatNumber(event.target.value);
      this.setState({
        [name]: num,
      });
    } else if (name !== 'invoice_amount') {
      this.setState({
        [name]: value,
      });
    }
  };

  handleFileChange = (event) => {
    const {
      t,
      showMessage,
    } = this.props;
    const { name, value } = event.target;
    const files = Array.from(event.target.files);
    const arrays = files.length > 0 ? files[0].name : "";
    let ext = arrays.split('.');

    if (files.length > 0) {
      if (ext[1].toLowerCase() !== 'pdf') {
        event.preventDefault()
        return showMessage({
            message: t('errorLoad', { type: 'PDF' }),
            config: TOAST_CONFIG.WARNING,
          });
      }
      if (parseInt(files[0].size / 1024) > 3072) {
        return showMessage({
            message: t('fileSizeExceeded'),
            config: TOAST_CONFIG.ERROR,
          });
      }
      this.setState({
        binaryFile: files[0],
        [name]: value,
      });
    } else {
      this.setState({
        binaryFile: null,
        [name]: undefined
      });
    }
  };

  handleXMLChange = (event) => {
    const {
      t,
      showMessage,
    } = this.props;
    const { name, value } = event.target;
    const files = Array.from(event.target.files);
    const arrays = files.length > 0 ? files[0].name : "";
    let ext = arrays.split('.');

    if (files.length > 0) {
      if (ext[1].toLowerCase() !== 'xml') {
        event.preventDefault()
        return showMessage({
            message: t('errorLoad', { type: 'XML' }),
            config: TOAST_CONFIG.WARNING,
          });
      }
      if (parseInt(files[0].size / 1024) > 3072) {
        return showMessage({
            message: t('fileSizeExceeded'),
            config: TOAST_CONFIG.ERROR,
          });
      }
      this.setState({
        binaryXMLFile: files[0],
        [name]: value,
      });
    } else {
      this.setState({
        binaryXMLFile: null,
        [name]: undefined
      });
    }
  };

  qrUploadInvoiceFile = (event) => {
    const {
      readInvoiceQr,
      clients: { list: clientList },
      auth: {
        user: {
          user_id,
        },
      },
    } = this.props;
    const files = Array.from(event.target.files);
    this.setState({
      uploadQrLoading: true,
      binaryFile: files[0],
      file: null,
    }, () => readInvoiceQr({
      params: {
        provider_id: user_id,
      },
      callback: ({
                  invoice_date,
                  invoice_number,
                  invoice_amount,
                  invoice_description,
                  client,
                  detail_amount,
                }) => setTimeout(
        () => this.setState({
          uploadQrLoading: false,
          showForm: true,
          invoice_date: new Date(invoice_date),
          invoice_number,
          invoice_amount: String(invoice_amount),
          invoice_description,
          client: client === '' ? null : clientList.map((mapClient, index) => ({ index, ...mapClient }))
            // eslint-disable-next-line eqeqeq
            .filter(rowClient => rowClient.id_person == client)[0].index,
          detail_amount,
        }),
        3000,
      ),
    }));
  };

  handleDateChange = (newDate) => {
    this.setState({
      invoice_date: newDate,
    });
  };

  handleClientChange = (newDate) => {
    const {
      auth: { user: { b2b: { id, user_type } } },
      getPaymentCommerceCode,
      clients: { list },
    } = this.props;

    if (user_type !== "NCL") {
      getPaymentCommerceCode({
        params: {
          sender: list[newDate.value].id,
          receiver: id,
        },
        callback: (response) => {
          this.setState({
            commerceCodes: response,
            commerceCode: -1,
            descriptionCommerceCode: '',
          });
        }
      });
    }

    this.setState({
      client: newDate.value,
    });
  };

  handleCommerceChange = (newVal, label) => {
    const {
      providers: { list },
    } = this.props;

    this.setState({
      [label.name]: newVal.value,
      descriptionCommerceCode: list[newVal.value].description,
      currency: list[newVal.value].currency_id !== null ? list[newVal.value].currency_id : '---',
    });
  };

  onSubmit = () => {
    const {
      client,
      invoice_amount,
      invoice_date,
      invoice_number,
      description,
      binaryFile,
      binaryXMLFile,
      detail_amount,
      commerceCode,
    } = this.state;
    const {
      t,
      auth: {
        user: {
          b2b: {
            id,
            user_type,
          },
        },
      },
      clients,
      loadInvoice,
      history,
      showMessage,
      providers: { list },
    } = this.props;

    const data = {
      invoice_number,
      buyer_id: clients.list[client].id,
      buyer: clients.list[client].id,
      seller: id,
      amount: invoice_amount.replaceAll(',', ''),
      detail_amount,
      date: invoice_date.toLocaleString("en-CA", {timeZone: "America/Mexico_City"}).split(',')[0],
      description: description,
      resource: binaryFile,
      resource_xml: binaryXMLFile,
      commerce_code: user_type === 'TDC' ? list[commerceCode].id : [],
    };

    if(this.validateInvoiceNumber(invoice_number)){
    loadInvoice({
      data,
      callback: () => {
        history.push('/invoices');
        showMessage({
          message: t('createInvoiceSuccess', { invoice_number }),
          config: TOAST_CONFIG.SUCCESS,
        });
        this.resetState();
      },
      t,
    });
    }else{
      showMessage({
        message: <strong style={{ color: '#ffffff' }}>{t('validInvoiceNumber')}</strong>,
        config: TOAST_CONFIG.ERROR,
      });
    }
  };

  handleOnInputChange = (newValue) => {
    this.setState({ inputValue: newValue });
    return newValue;
  };

  onFinish = () => {
    const { checkBalance } = this.props;
    this.setState(() => ({
      detailTransfer: null,
      amount: '',
      destination_phone_number: '',
    }));
    checkBalance();
  };

  validateEmptyFields = () => {
    const { client, invoice_amount, invoice_number, description, commerceCode, } = this.state;
    const {auth: { user: { b2b: { user_type } } } } = this.props;

    return (description === '' || invoice_number === '' || client === null || invoice_amount === '' || (user_type === 'TDC' && commerceCode === -1));
  };

  messageDate = (onToggle) => {
    onToggle({
      dateDisable: true,
    });
  };

  infoSii = (onToggle) => {
    const {
      invoice_number,
      invoice_date,
      client,
      type,
    } = this.state;
    const {
      infoSiiInvoice,
      clients,
    } = this.props;

    infoSiiInvoice({
      params: {
        invoice_number,
        date_sii: new Date(invoice_date).toISOString(),
        buyer_id: clients.list[client].id,
        type_dte: type,
      },
      callback: (response) => {
        if (response.hasOwnProperty('base_amount')) {
          onToggle({
            isEdit: false,
            isDetail: true,
            invoiceNumber: response.invoice_number,
            date_sii: response.date_sii,
            buyer: clients.list[client].idPerson.name,
            date_sii_doc: response.date_sii_doc,
            base_amount: response.base_amount,
            adjusted_amount: response.adjusted_amount,
            status_sii: response.status_sii,
            is_available: response.is_available,
          });
        } else {
          onToggle({
            isEdit: true,
            isDetail: false,
            invoiceNumber: response.invoice_number,
          });
        }
      }
    });
  };

  handleSelectChange = (newVal, label) => {
    this.setState({
      [label.name]: newVal.value,
    });
  };

  render() {
    const {
      t,
      transactions: { balance, charge, loading: transactionLoading },
      auth: { contacts,
        user: {
          b2b: {
            user_type,
          },
        }
      },
      clients,
      providers: { loading: providerLoading, type },
    } = this.props;
    const {
      amount,
      detailTransfer,
      client,
      invoice_amount,
      invoice_date,
      invoice_number,
      file,
      binaryFile,
      showForm,
      uploadQrLoading,
      detail_amount,
      inputValue,
      description,
      gateway,
      commerceCode,
      commerceCodes,
      descriptionCommerceCode,
      currency,
      binaryXMLFile,
      xmlFile,
    } = this.state;

    const loading = transactionLoading || providerLoading || uploadQrLoading;
    const charge_amount = parseFloat(amount) * (charge / 100);
    const disabledForm = this.validateEmptyFields();

    if (detailTransfer) {
      return (
        <TransferDetail
          detailTransfer={detailTransfer}
          onFinish={this.onFinish}
        />
      );
    }

    return (
      <Toggle>
        {({ toggled, onToggle, toggleProps }) => (
          <Fragment>
            {loading && <Loading/>}
            { toggled && toggleProps.dateDisable && (
              <Modal onToggle={onToggle} title={t('information')}> {loading && <Loading/>}
                <div className="has-text-centered">
                  <h1 className="message-date">
                    {t('invoiceDateAutomatically')}
                  </h1>
                  <button
                    type="button"
                    className="button custom-btn is-primary"
                    onClick={() => onToggle()}
                  >
                    {t('accept')}
                  </button>
                </div>
              </Modal>
              )
            }
            {showForm && (
              <LoadInvoicesForm
                formData={{ ...this.state }}
                onToggle={onToggle}
                toggle={toggled}
                toggleProps={toggleProps}
                disabled={disabledForm}
                balance={balance}
                handleInputChange={this.handleInputChange}
                handleFileChange={this.handleFileChange}
                handleDateChange={this.handleDateChange}
                handleClientChange={this.handleClientChange}
                contacts={contacts}
                chargeAmount={charge_amount}
                invoice_amount={invoice_amount}
                invoiceDate={invoice_date}
                invoiceNumber={invoice_number}
                description={description}
                clients={clients.list}
                selectedClient={client}
                onSubmit={this.onSubmit}
                file={file}
                fileName={binaryFile && binaryFile.name}
                xmlFile={xmlFile}
                xmlFileName={binaryXMLFile && binaryXMLFile.name} 
                detailAmount={detail_amount}
                handleOnInputChange={this.handleOnInputChange}
                inputValue={inputValue}
                currency={currency}
                infoSii={this.infoSii}
                typeList={type}
                handleSelectChange={this.handleSelectChange}
                gateway={gateway}
                handleSelectCommerce={this.handleCommerceChange}
                commerceCode={commerceCode}
                commerceCodes={commerceCodes}
                descriptionCommerceCode={descriptionCommerceCode}
                handleXMLChange={this.handleXMLChange}
                messageDate={this.messageDate}
                user_type={user_type}
              />
            )}
            {!showForm && (
              <Panel headingText={t('loadInvoice')}>
                <div className="columns">
                  <div className="column">
                    <div className="file has-name">
                      <label htmlFor="resume" className="file-label">
                        <input 
                          className="file-input"
                          type="file"
                          name="resume"
                          onChange={this.qrUploadInvoiceFile}
                        />
                        <a className="button is-outlined is-info is-large is-fullwidth">
                          <span className="">{t('uploadInvoice')}</span>
                          <span className="icon is-small">
                            <i className="fas fa-upload"/>
                          </span>
                        </a>
                      </label>
                    </div>
                  </div>
                  <div className="column">
                    <button 
                      className="button is-outlined is-success is-large is-fullwidth"
                      onClick={() => this.setState({ showForm: true })}
                    >
                      <span>{t('loadInvoiceData')}</span>
                      <span className="icon is-small">
                        <i className="fas fa-edit"/>
                      </span>
                    </button>
                  </div>
                </div>
              </Panel>
            )}
          </Fragment>
        )}
      </Toggle>
    );
  }
}

Providers.propTypes = {
  auth: PropTypes.shape(Object).isRequired,
  providers: PropTypes.shape(Object).isRequired,
  transactions: PropTypes.shape(Object).isRequired,
  transfer: PropTypes.func.isRequired,
  getClients: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  readInvoiceQr: PropTypes.func.isRequired,
  clients: PropTypes.shape(Object).isRequired,
  loadInvoice: PropTypes.func.isRequired,
  history: PropTypes.shape(Object).isRequired,
  showMessage: PropTypes.func.isRequired,
  checkBalance: PropTypes.func.isRequired,
};

const mapStateToProps = ({
                            transactions,
                            clients,
                            auth,
                            providers,
                            gatewayActions,
                          }) => (
  {
    transactions,
    clients,
    auth,
    providers,
    gatewayActions,
  }
);

export default compose(
  connect(mapStateToProps, {
    ...transactionsActions,
    ...authActions,
    ...clientsActions,
    ...providersActions,
    ...gatewayActions,
  }),
  withNamespaces(),
)(Providers);
