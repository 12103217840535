import React from 'react';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';
import { Form, SelectSingle, TextArea, TextInput } from '../../../../components';

const PaymentForm = (props) => {
  const {
    t,
    onToggle,
    formData,
    handleProviderChange,
    handleOnInputChange,
    providers,
    provider,
    inputValue,
    commerceCode,
    commerceCodes,
    codeDescription,
    handleCommerceChange,
    amount,
    invoiceNumber,
    paymentReference,
    paymentDescription,
    mails,
    handleInputChange,
    addEmail,
    deleteEmail,
    handleInputChangeMails,
    file,
    fileName,
    handleFileChange,
    xmlFile,
    xmlFileName,
    handleXMLChange,
    onSubmit,
    onCancel,
    userType,
    pathname,
  } = props;
  return (
    <div>
      <Form
        className="column is-full"
        submitText={t('send')}
        onSubmit={(event) => {
          event.preventDefault();
          onSubmit(onToggle, formData, true);
        }}
        onSave={(event) => {
          event.preventDefault();
          onSubmit(onToggle, formData, false);
        }} 
        onCancel={(event) => {
          event.preventDefault();
          onCancel();
        }}
        buttonClasses="mr-8px"
        cancelPayment={true}
        cancelText={t("cancel")}
        savePayment={true}
        saveText={t("save")}
      >
      <div className="field is-horizontal">
        <div className="field-body columns">
          <div className="column">
            <SelectSingle
              name="provider"
              label={t('provider')}
              placeholder={t('SelectOption')}
              onChange={handleProviderChange}
              options={[
                ...providers.map((provider, index) => ({
                  label: provider.idPerson.name,
                  value: index,
                })),
              ]}
              selectClasses="is-fullwidth"
              controlClasses="has-icons-left"
              icon="far fa-user"
              iconPosition="is-left"
              value={provider}
              isSearchable
              onInputChange={handleOnInputChange}
              inputValue={inputValue}
              isRequired={true}
              isDisabled={pathname === "/edit-payment"}
            />
          </div>
          { userType === 'TDC' &&
            <>
              <div className="column">
                <SelectSingle
                  name="commerceCode"
                  label={t('affiliateNumber')}
                  placeholder={t('SelectOption')}
                  onChange={handleCommerceChange}
                  options={[
                    ...commerceCodes.map((commerceCode, index) => ({
                      label: commerceCode.commerce_code,
                      value: index,
                    })),
                  ]}
                  noOptionsMessage={t('selectAProvider')}
                  selectClasses="is-fullwidth"
                  controlClasses="has-icons-left"
                  icon="far fa-user"
                  iconPosition="is-left"
                  value={commerceCode}
                  isSearchable
                  isRequired={true}
                  isDisabled={pathname === "/edit-payment"}
                  />
              </div>
              <div className="column">
                <TextInput
                  name="codeDescription"
                  label={ t('commerceCodeDescription') }
                  value={codeDescription}
                  placeholder={t('commerceCodeDescription')}
                  type="text"
                  controlClasses="is-expanded"
                  disabled={true}
                  />
              </div>
            </>
          }
        </div>
      </div>
      <div className="field is-horizontal">
        <div className="field-body columns">
          <div className="column">
            <TextInput
              name="invoiceNumber"
              label={`${t('invoicesNumber')} *`}
              value={invoiceNumber}
              onChange={handleInputChange}
              placeholder={t('invoicesNumber')}
              type="text"
              maxlength="60"
              controlClasses="is-expanded"
            />
          </div>
        </div>
      </div>
      <div className="field is-horizontal">
        <div className="field-body columns">
          <div className="column">
            <TextInput
              name="amount"
              label={ `${t('amount')} ${commerceCode !== -1 ? commerceCodes[commerceCode].currency_id : ''}*` }
              value={amount}
              onChange={handleInputChange}
              placeholder={t('amount')}
              type="text"
              controlClasses="is-expanded"
            />
          </div>
          <div className="column">
            <TextInput
              name="paymentReference"
              label={ t('paymentReference') }
              value={paymentReference}
              onChange={handleInputChange}
              placeholder={t('paymentReference')}
              type="text"
              controlClasses="is-expanded"
            />
          </div>
        </div>
      </div>
      <div className="field is-horizontal">
        <div className="field-body columns">
          <div className="column">
            <TextArea 
              name="paymentDescription"
              label={ t('description') }
              value={paymentDescription}
              onChange={handleInputChange}
              placeholder={t('description')}
            />
          </div>
        </div>
      </div>
      <ul>
        {mails.map((val, index) => (
          <li key={val.id}>
            <div className="field is-horizontal">
              <div className="field-body columns">
                <div className="column">
                  <TextInput
                    name={"mails-"+index}
                    label={ t('aditionalEmail') }
                    value={val.email}
                    onChange={e => handleInputChangeMails(e.target.value, val.id)}
                    placeholder={t('aditionalEmail')}
                    type="text"
                    controlClasses="is-expanded"
                    autoComplete="off"
                  />
                </div>
                <div style={{ marginTop: '35px', }}>
                  <button
                    className="button is-info is-outlined is-pulled-right"
                    type="button"
                    onClick={addEmail}
                    disabled={index < 9 ? false : true}
                  >
                    <i className="fas fa-plus-circle"/>
                  </button>
                </div>
                <div style={{
                  marginTop: '35px',
                  marginLeft: '.5em'
                }}>
                  <button
                    className="button is-info is-outlined"
                    type="button"
                    // onClick={deleteEmail.bind(this,index)}
                    onClick={() => deleteEmail(val.id)}
                    disabled={index === 0 ? true : false}
                  >
                    <i className="fas fa-minus-circle mr-10px"/>
                  </button>
                </div>
              </div>
            </div>
          </li>
        ))}
      </ul>
      <br/>
      <div className="field is-horizontal">
        <div className="field-body columns">
          <div className="column">
            <TextInput
              name="file"
              label={t('selectPDF')}
              value={file}
              fileName={fileName}
              onChange={handleFileChange}
              type="file"
            />
            <strong> 
              <span className="texto-file" style={{ color: '#A6192E' }}>{t('allowedFormatsPDF')}</span>
            </strong>
          </div>
          <div className="column">
            <TextInput
              name="file"
              label={t('selectXML')}
              value={xmlFile}
              fileName={xmlFileName}
              onChange={handleXMLChange}
              type="file"
            />
            <strong> 
              <span className="texto-file" style={{ color: '#A6192E' }}>{t('allowedFormatsXML')}</span>
            </strong>
          </div>
        </div>
      </div>
      </Form>
    </div>
  );
};

PaymentForm.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withNamespaces()(PaymentForm);
