import React, { useRef, useEffect, useState } from "react";
import PropTypes from 'prop-types';
import './styles.scss';
import { Link } from 'react-router-dom';
import { withNamespaces } from 'react-i18next';


const SubMenu = (props) => {
  const {
    t, items, open, pathname,
  } = props;
  const classOpen = open ? 'open' : '';
  const subMenuRef = useRef(null);
  const [classOverlap, setClassOverlap] = useState("");

  useEffect(() => {
    const offsetTop = subMenuRef.current.getBoundingClientRect().y;
    open && offsetTop < 90
      ? setClassOverlap("correct-overlap")
      : setClassOverlap("");
  }, [open]);

  return (
    <ul ref={subMenuRef} className={`subMenu animated ${classOpen} ${classOverlap}`} data-node="submenu">
      {items.map((value) => {
        const active = pathname === value.url ? 'active' : '';
        if (value.isHidden) {
          return null;
        }
        return (
          <li key={value.name} className={`${value.activeClass} ${active}`} data-node="submenu">
            <Link to={value.url} data-node="submenu">
              <span data-node="submenu"> {t(value.name)} </span>
            </Link>
          </li>
        );
      })}
    </ul>
  );
};

SubMenu.propTypes = {
  t: PropTypes.func.isRequired,
  items: PropTypes.arrayOf(PropTypes.object),
  open: PropTypes.bool.isRequired,
  pathname: PropTypes.string.isRequired,
};

SubMenu.defaultProps = {
  items: [],
};

export default withNamespaces()(SubMenu);
