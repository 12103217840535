import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { compose } from "redux"
import { withNamespaces } from "react-i18next"
import { ToastContainer } from "react-toastify"
import { authActions } from "../../state/ducks/auth"
import { Form, Loading, TextInput } from "../../components"
import "./styles.scss"
import Fingerprint2 from "fingerprintjs2"
import { formatDateHour } from "../../util"
// RESOURCES
import KeoLogo from "../../resources/components/KeoLogo"
import WorkeoLogo from "../../resources/components/WorkeoLogo"
import people from "../../resources/images/assets/people.png"
import comunidadB2B from "../../resources/images/assets/comunidad-b2b.png"
import logoAmex from "../../resources/images/assets/amex.png"

class ValidateCode extends Component {
  constructor(props) {
    super(props)
    this.state = {
      language: "",
      device_info: "",
      expired: "",
    }
  }

  componentDidMount = () => {
    const {
      auth: { language },
    } = this.props
    this.setState({ language })

    const promise = new Promise((resolve) => {
      let options = {
        excludes: {
          touchSupport: true,
          fontsFlash: true,
          screenResolution: true,
          pixelRatio: true,
          colorDepth: true,
          availableScreenResolution: true,
        },
      }

      Fingerprint2.get(options, function(components) {
        const values = components.map(function(component) {
          return component.value
        })
        const murmur = Fingerprint2.x64hash128(values.join(""), 31)
        resolve(murmur)
      })
    })

    promise.then((result) => {
      this.setState({ device_info: result })
    })
  }

  handleInputChange = (event) => {
    const {
      target: { type, checked, value: targetValue, name },
    } = event
    const value = type === "checkbox" ? checked : targetValue
    this.setState({
      [name]: value,
    })
  }

  handleSelectChange = (newVal) => {
    const { i18n } = this.props
    this.setState({
      language: newVal.target.value,
    })
    i18n.changeLanguage(newVal.target.value)
    // eslint-disable-next-line no-undef
    window.location.reload()
  }

  validateCode = () => {
    const {
      history,
      validateCode,
      auth: {
        user: { user_id },
      },
      logout,
    } = this.props
    const { code, device_info } = this.state

    const data = {
      code,
      user: user_id,
      device_info,
    }

    validateCode({
      data,
      callback: (response) => {
        if (!response.success) {
          logout()
          history.push("/login")
        } else {
          const hasMultiProfiles = response.data.profiles;
          const passwordHasExpired = response.data.b2b.password_changed === 0;
          if (hasMultiProfiles) {
            passwordHasExpired
              ? window.top.location.replace("/recover-password")
              : window.top.location.replace("/select-profile")
          } else {
            window.top.location.replace("/dashboard")
          }
        }
      },
    })
  }

  render() {
    const {
      auth: { loading, user },
      t,
    } = this.props
    const { language, code } = this.state
    const d = new Date()
    const n = d.getFullYear()

    return (
      <div className="SignIn">
        <ToastContainer />
        {loading && <Loading />}

        {/* HERO */}
        <section className="sign-in-hero"> 
            {/* <div className='keo'>
              <KeoLogo width='96' height='96' />
            </div>
            <div className='hero-title'>
              <h2>Compra ahora,</h2>
              <h2>paga después.</h2>
              <img src={comunidadB2B} className={comunidadB2B} alt='comunidad-b2b'/>
            </div>
            <div className="hero-img">
              <img src={people} className="people" alt="people" />
            </div> */}
        </section>

        {/* FORM MAIN CONTAINER */}
        <section className="sign-in-form">
          {/* LANGUAGES SELECT */}
          <div className="sign-in-language">
            <div className="field">
              <div className="control has-icons-left">
                <div className="select is-small">
                  <select
                    name="language"
                    value={language}
                    onChange={this.handleSelectChange}
                  >
                    <option value="es">{t("es")}</option>
                    <option value="pt">{t("pt")}</option>
                    <option value="en">{t("en")}</option>
                  </select>
                </div>
                <div className="icon is-small is-left">
                  <i className="fas fa-globe" />
                </div>
              </div>
            </div>
          </div>
          {/* WORKEO LOGO */}
          <div className="workeo">
            <img src={logoAmex} alt="logo" style={{
              height: '10em',
              width: '10em'
            }}/>
          </div>
          {/* FORM */}
          <div className="login-form">
            <span>{t("verificationCodeSent")}</span>
            <Form
              className="column is-full"
              submitText={t("send")}
              onSubmit={(event) => {
                event.preventDefault()
                this.validateCode()
              }}
              buttonClasses="custom-btn is-fullwidth"
              band={"#ffffff"}
              isBTNFull
            >
              <div style={{ textAlign: "center", marginTop: "10px" }}>
                <TextInput
                  name="code"
                  placeholder={t("verificationCode")}
                  label={
                    user.hasOwnProperty("expired_at")
                      ? t("theCodeExpiresIn", {
                          time: formatDateHour(user.expired_at),
                        })
                      : ""
                  }
                  type="text"
                  onChange={this.handleInputChange}
                  value={code}
                  login={true}
                />
                <br />
              </div>
            </Form>
          </div>
        </section>
        <div
          className="footerLogin"
          style={{ position: "absolute", right: "1vw", top: "95vh" }}
        >
          <img
            style={{ width: "2em", marginRight: ".5em" }}
            src={logoAmex}
            alt="logo"
          />
          <b>Copyright ©{` ${n} `}</b>
          B2B Enterprise. Powered by American Express. All rights reserved.
        </div>
      </div>
    )
  }
}

ValidateCode.propTypes = {
  passwordResetToken: PropTypes.func.isRequired,
  auth: PropTypes.shape(Object).isRequired,
  i18n: PropTypes.shape(Object).isRequired,
  t: PropTypes.func.isRequired,
  showMessage: PropTypes.func.isRequired,
}

const mapStateToProps = ({ auth }) => ({
  auth,
})

export default compose(
  connect(mapStateToProps, {
    ...authActions,
  }),
  withNamespaces()
)(ValidateCode)
