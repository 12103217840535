import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withNamespaces } from 'react-i18next';
import { ToastContainer } from 'react-toastify';
import { Redirect } from 'react-router-dom';
import { authActions } from '../../../state/ducks/auth';
import { providersActions } from '../../../state/ducks/providers';
import './styles.scss';
import { TOAST_CONFIG } from '../../../config/constants';
import Fingerprint2 from 'fingerprintjs2';
import HCaptcha from '@hcaptcha/react-hcaptcha';
// COMPONENTS
import { Loading, Toggle } from '../../../components';
import FormLogin from './components/FormLogin';
import FormRecover from './components/FormRecover';
import Modal from '../../../components/Modal';
import { Footer } from '../../layout/components';

// RESOURCES
import KeoLogo from '../../../resources/components/KeoLogo';
import WorkeoLogo from '../../../resources/components/WorkeoLogo';
import people from '../../../resources/images/assets/people.png';
import comunidadB2B from '../../../resources/images/assets/comunidad-b2b.png';
import logoAmex from '../../../resources/images/assets/amex.png';

class SignIn extends Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  componentDidMount = () => {
    const { auth: { language }, showMessage, t } = this.props;
    this.setState({ language });
  
    var promise = new Promise( (resolve) => {

      var options = {
        excludes: {
          touchSupport: true,
          fontsFlash: true,
          screenResolution: true,
          pixelRatio: true,
          colorDepth: true,
          availableScreenResolution: true,
        }
      };

      Fingerprint2.get(options, function (components) {
        var values = components.map(function (component) {
          return component.value;
        });
        var murmur = Fingerprint2.x64hash128(values.join(''), 31);
        resolve(murmur);
      });
    });
    
    promise.then( result => {
      this.setState({device_info: result});
    });

    if(window.location.hostname === 'localhost'){
      this.setState({robot: false});
    }

    if(window.innerWidth < 1024) {
      showMessage({
        message: t('responsiveMsg'),
        config: TOAST_CONFIG.INFO,
      });
    }
  };
  
  verifyHuman = () => {
    this.setState({robot: false});
  };

  handleInputChange = (event) => {
    const {
      target: {
        type, checked, value: targetValue, name,
      },
    } = event;
    const value = type === 'checkbox' ? checked : targetValue;
    this.setState({
      [name]: value,
    });
  };

  handleSelectChange = (newVal) => {
    const { i18n } = this.props;
    this.setState({
      language: newVal.target.value,
    });
    i18n.changeLanguage(newVal.target.value);
    // eslint-disable-next-line no-undef
    window.location.reload();
  };

  handleSubmit = (event) => {
    
    event.preventDefault();
    const { signIn, showMessage, t } = this.props;
    const { ...data } = this.state;
    const { robot } = this.state;

    if(!robot){
      signIn({ 
        data,
        callback: (response) => {}
      });
    }else{
      showMessage({
        message: t('verifyHuman'),
        config: TOAST_CONFIG.INFO,
      });
    }
  };

  handleReset = (onToggle) => {
    const { t, passwordReset, history, showMessage } = this.props;
    const { username } = this.state;
    const email = username;
    passwordReset({
      email,
      callback: (response) => {
        showMessage({
          message: response.data.message,//t('successEmail'),
          config: TOAST_CONFIG.SUCCESS,
        });
        onToggle();
        this.resetState();
      },
    });
  };

  getInitialState = () => ({
    language: '',
    username: '',
    password: '',
    device_info: '',
    robot: false,
    redirection: ''
  });

  resetState = () => {
    this.setState(this.getInitialState());
  };

  onChangeCaptcha = () => {
    /*if(this.captcha.current.getValue()){
      this.setState({
        robot: false,
      });
    }*/
    this.setState({
      robot: false,
    });
  }

  handleVerificationSuccess = (token, ekey) => {
    this.setState({
      robot: false,
    });
  }

  render() {
    const {
      auth,
      auth: { logged, loading, user },
      t,
    } = this.props;
    const { language, username, password, device_info } = this.state;
    const isFirstLogin = user.first_login === 1,
    isSeller = user.b2b.type_user === "seller",
    isBuyer = user.b2b.type_user === "buyer",
    passwordHasExpired = user.b2b.password_changed === 0,
    hasActive2FA = user.b2b.password_changed === -1 || user.b2b.fma_is_active,
    hasMultiProfiles = auth.profiles;

    if (logged) {
      if (passwordHasExpired) return <Redirect to="/recover-password"/>;
      if (hasActive2FA) return <Redirect to="/validate-code"/>;
      if (hasMultiProfiles) return <Redirect to="/select-profile"/>;
      if (isBuyer && isFirstLogin) return <Redirect to="/confirm-customer"/>;
      if (isSeller && isFirstLogin) return <Redirect to="/confirm-provider"/>;
      return <Redirect to="/dashboard"/>
    }

    const d = new Date();
    const n = d.getFullYear();
    return (
      <Toggle>
        {({ toggled, onToggle }) => (
          <Fragment>
            {
              toggled
              && (
                <Modal onToggle={onToggle} title={t('recoverPassword')}>
                  <FormRecover
                    handleReset={() => this.handleReset(onToggle)}
                    handleInputChange={this.handleInputChange}
                    data={{ username }}
                  />
                </Modal>
              )
            }
            <div className="SignIn">
              <ToastContainer/>
              {loading && <Loading/>}

              {/* HERO */}
              <section className="sign-in-hero"> 
                  {/* <div className='keo'>
                    <KeoLogo width='96' height='96' />
                  </div>
                  <div className='hero-title'>
                    <h2>Compra ahora,</h2>
                    <h2>paga después.</h2>
                    <img src={comunidadB2B} className={comunidadB2B} alt='comunidad-b2b'/>
                  </div>
                  <div className="hero-img">
                    <img src={people} className="people" alt="people" />
                  </div> */}
              </section>
              
              {/* FORM MAIN CONTAINER */}
              <section className="sign-in-form">
                {/* LANGUAGES SELECT */}
                <div className="sign-in-language">
                  <div className="field">
                      <div className="control has-icons-left">
                        <div className="select is-small">
                          <select 
                            name="language" 
                            value={language}
                            onChange={this.handleSelectChange}
                          >
                            <option value="es">{t('es')}</option>
                            <option value="pt">{t('pt')}</option>
                            <option value="en">{t('en')}</option>
                          </select>
                        </div>
                        <div className="icon is-small is-left">
                          <i className="fas fa-globe"/>
                        </div>
                      </div>
                  </div>
                </div>
                {/* WORKEO LOGO */}
                <div className='workeo'>
                  <img src={logoAmex} alt="logo" style={{
                    height: '10em',
                    width: '10em'
                  }}/>
                </div>
                {/* FORM */}
                <div className="login-form">
                  <FormLogin
                    handleSubmit={this.handleSubmit}
                    handleInputChange={this.handleInputChange}
                    data={{
                      username,
                      password,
                      device_info
                    }}
                  >
                    <div className="recaptcha-box">
                      <div className="captcha-container">
                        {/*<HCaptcha
                          sitekey="15d6a7e0-1ba4-4252-90be-f52d7260c679"
                          onVerify={(token,ekey) => this.handleVerificationSuccess(token, ekey)}
                        />*/}
                      </div>
                    </div>
                  </FormLogin>
                  <div className="center link-chile" style={{position: 'relative', zIndex: 999, marginTop: '1em'}}>
                    <a href={'#'}
                      onClick={() => {
                        onToggle({
                          isEdit: true,
                        });
                      }}
                    >
                      {t('forgotPassword')}
                    </a>
                  </div>
                </div>
              </section>
              <Footer usedInLogin/>
            </div>
          </Fragment>
        )}
      </Toggle>
    );
  }
}

SignIn.propTypes = {
  signIn: PropTypes.func.isRequired,
  history: PropTypes.shape(Object).isRequired,
  passwordReset: PropTypes.func.isRequired,
  auth: PropTypes.shape(Object).isRequired,
  i18n: PropTypes.shape(Object).isRequired,
  t: PropTypes.func.isRequired,
  showMessage: PropTypes.func.isRequired,
};

const mapStateToProps = ({ auth, providers }) => (
  {
    auth,
    providers,
  }
);

export default compose(
  connect(mapStateToProps, {
    ...authActions,
    ...providersActions,
  }),
  withNamespaces(),
)(SignIn);
