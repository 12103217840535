import React, { Component, Fragment } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import { CSVLink } from 'react-csv';
import ReactTooltip from 'react-tooltip';
import PropTypes from 'prop-types';
import Modal from '../../components/Modal';
import { Currency, Loading, Panel, TableSimple, Toggle, } from '../../components';
import { transactionsActions } from '../../state/ducks/transactions';
import {
  formatDate,
  getPath,
  numberFilter,
  onInputOnlyNumbers,
  fileDateTime,
  formatCurrencyKeo,
  getTableFilteredValues,
} from '../../util';
import './styles.scss';
import DateInput from '../../components/DateInput';
import { TOAST_CONFIG, API_URL } from '../../config/constants';
import XLSX from 'xlsx';
import deepClone from 'lodash.clonedeep';
import { clientsActions } from '../../state/ducks/clients';

class Reports extends Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
    this.selectTable = React.createRef();
    this.csvRef = React.createRef();
  }

  getInitialState = () => ({
    id: '',
    name: '',
    roleType: '',
    invoiceDate: "", //new Date().setDate(new Date().getDate() - 1),
    invoiceDateUntil: "", //new Date().setHours(23, 59, 59, 999),
    downloadFileData: [],
    adminReport: [],
    bankReport: [],
    report: [],
    totalRecords: 0,
    order: null
  });

  componentDidMount() {
    this.rangeDate(10, 0, [], [], true);
  }

  actionCol = (cellInfo) => {
    const {
      t,
      fetchPaymentDetail,
      fetchTransaction,
      history,
      auth: {
        user: {
          b2b: { user_type, type_user },
        },
      },
    } = this.props;
    const {
      resource,
      resource_xml,
      method
    } = cellInfo.original
    const hasPDF = resource,
      hasXML = resource_xml,
      isBPP = method.with_invoice !== 1;

    return (
      <table>
        <tbody>
          <tr className="TableSimple__action-btns">
            {/* DOWNLOAD PDF */}
            {hasPDF && (
              <td>
                <a
                  href={`${API_URL}b2b/resource-file?nameFile=${resource}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="button"
                  download
                >
                  <i
                    className="fas fa-file-pdf"
                    title={t("downloadPDF")}
                  />
                </a>
              </td>
            )}
            {/* DOWNLOAD XML */}
            {hasXML && (
              <td>
                <a
                  href={resource_xml}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="button"
                  download
                >
                  <i
                    className="fas fa-file-code"
                    title={t("downloadXML")}
                  />
                </a>
              </td>
            )}
            {/* PAYMENT DETAIL */}
              <td>
                <button
                  className="button"
                  onClick={() => {
                    isBPP ?
                      fetchPaymentDetail({
                        params: { payment: cellInfo.original.id },
                        callback: (res) => {
                          if (res.success) {
                            history.push({
                              pathname: "/payment-detail",
                            })
                          }
                        },
                      }) :
                      fetchTransaction({
                        params: {
                          id: cellInfo.original.id,
                          role: 
                            type_user === "seller" ? "receiver" : "sender"
                        },
                        callback: () => {
                          history.push({
                            pathname: '/invoice-detail',
                          });
                        }
                      });
                  }} 
                >
                  <i
                    className="fas fa-eye"
                    title={t("detail")}
                  />
                </button>
              </td>
          </tr>
        </tbody>
      </table>
    )
  }

  getGridSettings = () => {
    const {
      t,
      auth: {
        user: {
          b2b: {
            type_user, user_type
          },
        },
      },
      transactions: { sender, receiver, list },
      fetchPaymentDetail,
      fetchTransaction,
      history
    } = this.props;
    const {
      adminReport,
      bankReport,
      report,
    } = this.state;
    let columns = null;
    let data = null;
    let title = null;
    switch (type_user) {
      case 'admin_amex':
      case 'admin':
        columns = [
          {
            id: 'provider', // Required because our accessor is not a string
            Header: t('provider'),
            accessor: d => `${d.seller.name}`,
          },
          {
            id: 'document_type', // Required because our accessor is not a string
            Header: t('typeAndDocumentNumber'),
            width: 130,
            headerClassName: 'wordwrap',
            accessor: d => `${getPath(['seller', 'document_type'], d)} ${getPath(['seller', 'document_number'], d)}`,
          },
          {
            id: 'invoicesNumber', // Required because our accessor is not a string
            Header: t('invoicesNumber'),
            accessor: d => `${d.invoice_number}`,
          },
          {
            id: 'date', // Required because our accessor is not a string
            Header: t('sendInvoice'),
            //accessor: d => formatDate(d.date),
            accessor: 'date',
            width: 130,
            Cell: cellInfo => (
              <div>
                <span>{formatDate(cellInfo.original.date)}</span>
              </div>
            ),
            // filterMethod: (filter, row) => {
            //   if (!filter.value.match(/[\d/]+$/gm)) {
            //     return true;
            //   }
            //   if (formatDate(row.date)
            //     .includes(filter.value)) {
            //     return formatDate(row.date);
            //   }
            // },
            // Filter: ({ filter, onChange }) =>
            //   <input
            //     onChange={event => onChange(event.target.value)}
            //     style={{ width: '100%' }}
            //     value={filter && filter.value.match(/[\d/]+$/gm) ? filter.value : ''}
            //   />
          },
          {
            id: 'due_date', // Required because our accessor is not a string
            Header: t('chargeDate'),
            //accessor: d => d.receiver_status_id === 'PAP' ? '' : formatDate(d.due_date),
            accessor: 'due_date',
            Cell: cellInfo => (
              <div>
                <span> {
                  cellInfo.original.receiver_status_id === 'PAP' ? '' : formatDate(cellInfo.original.due_date)
                }</span>
              </div>
            ),
            // filterMethod: (filter, row) => {
            //   if (!filter.value.match(/[\d/]+$/gm)) {
            //     return true;
            //   }
            //   if (formatDate(row.due_date)
            //     .includes(filter.value)) {
            //     return formatDate(row.due_date);
            //   }
            // },
            // Filter: ({ filter, onChange }) =>
            //   <input
            //     onChange={event => onChange(event.target.value)}
            //     style={{ width: '100%' }}
            //     value={filter && filter.value.match(/[\d/]+$/gm) ? filter.value : ''}
            //   />
          },
          {
            id: 'approved_date', // Required because our accessor is not a string
            Header: t('approvalDate'),
            accessor: 'approved_date',
            Cell: cellInfo => (
              <div>
                <span> {
                  cellInfo.original.approved_date === null ? '' : formatDate(cellInfo.original.approved_date)
                }</span>
              </div>
            ),
            // filterMethod: (filter, row) => {
            //   if (!filter.value.match(/[\d/]+$/gm)) {
            //     return true;
            //   }
            //   if (formatDate(row.approved_date)
            //     .includes(filter.value)) {
            //     return formatDate(row.approved_date);
            //   }
            //   //return formatDate(row.date).indexOf(filter.value) >=0;
            // },
            // Filter: ({ filter, onChange }) =>
            //   <input
            //     onChange={event => onChange(event.target.value)}
            //     style={{ width: '100%' }}
            //     value={filter && filter.value.match(/[\d/]+$/gm) ? filter.value : ''}
            //   />
          },
          {
            id: 'arrangedDays', // Required because our accessor is not a string
            Header: t('arrangedDays'),
            className: 'text-center',
            accessor: d => `${d.payment_condition.days}`,
          },
          {
            id: 'startCountingFrom', // Required because our accessor is not a string
            Header: t('startCountingFrom'),
            className: 'text-center',
            accessor: d => ((d.payment_condition.due_from_approval === '1') ? t('approvalDate') : t('receptionDate')),
          },
          {
            id: 'paymentType',
            Header: t('paymentType'),
            className: 'text-center',
            accessor: d => d.type_transaction ? d.type_transaction : '',
          },
          {
            id: 'mode',
            Header: t('mode'),
            className: 'text-center',
            accessor: d => d.method.with_invoice === 1 ? t('traditional') : t('BPP'),
          },
          {
            id: 'currency',
            Header: t('currency'),
            className: 'text-center',
            accessor: d => `${d.pay_currency_id}`,
          },
          {
            id: 'adjusted_amount', // Required because our accessor is not a string
            Header: t('totalPay'),
            accessor: d => d.adjusted_amount,
            Cell: cellInfo => (
              <Currency value={cellInfo.original.adjusted_amount} />
            ),
            sortMethod: (a, b) => Number(a)-Number(b),
            // filterMethod: (filter, row) => numberFilter(filter, row),
            // Filter: ({ filter, onChange }) =>
            //   <input
            //     onInput={onInputOnlyNumbers}
            //     onChange={event => onChange(event.target.value)}
            //     style={{ width: '100%' }}
            //     value={filter ? filter.value : ''}
            //   />
          },
          {
            id: 'client', // Required because our accessor is not a string
            Header: t('client'),
            accessor: d => `${d.buyer.name}`,
          },
          {
            id: 'document_number', // Required because our accessor is not a string
            Header: t('typeAndDocumentNumber'),
            width: 130,
            headerClassName: 'wordwrap',
            accessor: d => `${getPath(['buyer', 'document_type'], d)} ${getPath(['buyer', 'document_number'], d)}`,
          },
          {
            id: 'transferState', // Required because our accessor is not a string
            Header: t('transferState'),
            accessor: d => d.receiver_status_id ?  d.receiver_status_id : '', //Cambio temporal palabra "Garantizado"
          },
        ];
        data = adminReport;
        title = t('admin');
        break;
      case 'buyer':
        columns = [
          {
            id: 'provider', // Required because our accessor is not a string
            Header: t('provider'),
            accessor: d => d.seller.name !== null ? `${d.seller.name}` : '',
          },
          {
            id: 'document_type', // Required because our accessor is not a string
            Header: 'RFC',
            headerClassName: 'wordwrap',
            accessor: d => d.seller.document_number !== null ? `${getPath(['seller', 'document_number'], d)}` : '',
          },
          {
            id: 'invoicesNumber', // Required because our accessor is not a string
            Header: t('invoiceNumber'),
            accessor: d => d.invoice_number !== null ? `${d.invoice_number}` : '',
          },
          {
            id: 'arrangedDays',
            Header: t('arrangedDays'),
            headerClassName: 'wordwrap',
            accessor: d => `${d.payment_condition.days}`,
          },
          {
            id: 'date', // Required because our accessor is not a string
            Header: t('shippingDate'),
            //accessor: d => formatDate(d.date),
            accessor: 'date',
            Cell: cellInfo => (
              <div>
                <span>{formatDate(cellInfo.original.date)}</span>
              </div>
            ),
            // filterMethod: (filter, row) => {
            //   if (!filter.value.match(/[\d/]+$/gm)) {
            //     return true;
            //   }
            //   if (formatDate(row.date)
            //     .includes(filter.value)) {
            //     return formatDate(row.date);
            //   }
            //   //return formatDate(row.date).indexOf(filter.value) >=0;
            // },
            // Filter: ({ filter, onChange }) =>
            //   <input
            //     onChange={event => onChange(event.target.value)}
            //     style={{ width: '100%' }}
            //     value={filter && filter.value.match(/[\d/]+$/gm) ? filter.value : ''}
            //   />
          },
          {
            id: 'approved_date', // Required because our accessor is not a string
            Header: t('approvalDate'),
            //accessor: d => formatDate(d.date),
            accessor: 'date',
            Cell: cellInfo => (
              <div>
                <span>{cellInfo.original.approved_date !== null ?
                  formatDate(cellInfo.original.approved_date)  : ''}</span>
              </div>
            ),
            // filterMethod: (filter, row) => {
            //   if (!filter.value.match(/[\d/]+$/gm)) {
            //     return true;
            //   }
            //   if (formatDate(row.approved_date)
            //     .includes(filter.value)) {
            //     return formatDate(row.approved_date);
            //   }
            //   //return formatDate(row.date).indexOf(filter.value) >=0;
            // },
            // Filter: ({ filter, onChange }) =>
            //   <input
            //     onChange={event => onChange(event.target.value)}
            //     style={{ width: '100%' }}
            //     value={filter && filter.value.match(/[\d/]+$/gm) ? filter.value : ''}
            //   />
          },
          {
            id: 'due_date', // Required because our accessor is not a string
            Header: t('scheduledDateTheProvider'),
            //accessor: d => formatDate(d.due_date),
            accessor: 'due_date',
            Cell: cellInfo => (
              <div>
                <div>
                <span>
                  {(cellInfo.original.payment_type === 'NCL') ? formatDate(cellInfo.original.due_date)
                  : (cellInfo.original.sender_status_id !== 'PAG')
                  ? formatDate(cellInfo.original.due_date) : formatDate(cellInfo.original.paid_date)}
                </span>
                </div>
              </div>
            ),
            // filterMethod: (filter, row) => {
            //   if (!filter.value.match(/[\d/]+$/gm)) {
            //     return true;
            //   }
            //   if (formatDate(row.due_date)
            //     .includes(filter.value)) {
            //     return formatDate(row.due_date);
            //   }
            //   //return formatDate(row.date).indexOf(filter.value) >=0;
            // },
            // Filter: ({ filter, onChange }) =>
            //   <input
            //     onChange={event => onChange(event.target.value)}
            //     style={{ width: '100%' }}
            //     value={filter && filter.value.match(/[\d/]+$/gm) ? filter.value : ''}
            //   />
          },
          {
            id: 'paymentType',
            Header: t('paymentType'),
            className: 'text-center',
            accessor: d => d.type_transaction ? d.type_transaction : '',
          },
          {
            id: 'mode',
            Header: t('mode'),
            className: 'text-center',
            accessor: d => d.method.with_invoice === 1 ? t('traditional') : t('BPP'),
          },
          {
            id: 'currency',
            Header: t('currency'),
            className: 'text-center',
            accessor: d => `${d.pay_currency_id}`,
          },
          {
            id: 'adjusted_amount', // Required because our accessor is not a string
            Header: t('totalInvoice'),
            accessor: d => d.adjusted_amount,
            Cell: cellInfo => (
              <Currency value={cellInfo.original.adjusted_amount} />
            ),
            sortMethod: (a, b) => Number(a)-Number(b),
            // filterMethod: (filter, row) => numberFilter(filter, row),
            // Filter: ({ filter, onChange }) =>
            //   <input
            //     onInput={onInputOnlyNumbers}
            //     onChange={event => onChange(event.target.value)}
            //     style={{ width: '100%' }}
            //     value={filter ? filter.value : ''}
            //   />
          },
          {
            id: 'description', // Required because our accessor is not a string
            Header: t('description'),
            Cell: cellInfo => (
              <div>
                <span data-type="info"
                      data-tip={cellInfo.original.description}>{cellInfo.original.description}</span>
                <ReactTooltip/>
              </div>
            ),
          },
          {
            id: 'relationshipStatus', // Required because our accessor is not a string != null
            Header: t('relationshipStatus'),
            accessor: d => d.payment_condition.status_keo ? t(d.payment_condition.status_keo) : '',
          },
          {
            id: 'transferState', // Required because our accessor is not a string
            Header: t('transferState'),
            accessor: d => (d.sender_status_id) ? `${d.sender_status_id}` : '', //Cambio temporal palabra "Garantizado"
          },
          {
            id: "action", // Required because our accessor is not a string
            Header: t("action"),
            accessor: (d) => "Acción",
            width: 220,
            filterable: false,
            Cell: cellInfo => this.actionCol(cellInfo)
          },
        ];
        // data = this.props.transactions.sender
        data = report;
        // data = receiver;
        title = t('providers');
        break;
      case 'issuing_query':
      case 'issuing_bank':
        columns = [
          {
            id: 'client', // Required because our accessor is not a string
            Header: t('client'),
            headerClassName: 'wordwrap',
            accessor: d => d.buyer.name !== null ? `${d.buyer.name}` : '',
          },
          /*{
            id: 'document_type', // Required because our accessor is not a string
            Header: t('typeAndDocumentNumber'),
            headerClassName: 'wordwrap',
            accessor: d => `${getPath(['sender', 'idPerson', 'idDocumentType', 'document_type'], d)} ${getPath(['sender', 'idPerson', 'document_number'], d)}`,
          },*/
          {
            id: 'buyerDocumentType', // Required because our accessor is not a string
            Header: t('RFC', {type : t('client')}),
            headerClassName: 'wordwrap',
            accessor: d => d.buyer.document_number !== null ? `${getPath(['buyer', 'document_number'], d)}` : '',
          },
          {
            id: 'invoicesNumber', // Required because our accessor is not a string
            Header: t('invoiceNumber'),
            accessor: d => `${d.invoice_number}`,
          },
          {
            id: 'arrangedDays',
            Header: t('arrangedDays'),
            headerClassName: 'wordwrap',
            accessor: d => `${d.payment_condition.days}`,
          },
          {
            id: 'date', // Required because our accessor is not a string
            Header: t('sendDate'),
            headerClassName: 'wordwrap',
            accessor: 'date',
            Cell: cellInfo => (
              <div>
                <span>{formatDate(cellInfo.original.date)}</span>
              </div>
            ),
            // filterMethod: (filter, row) => {
            //   if (!filter.value.match(/[\d/]+$/gm)) {
            //     return true;
            //   }
            //   if (formatDate(row.date)
            //     .includes(filter.value)) {
            //     return formatDate(row.date);
            //   }
            //   //return formatDate(row.date).indexOf(filter.value) >=0;
            // },
            // Filter: ({ filter, onChange }) =>
            //   <input
            //     onChange={event => onChange(event.target.value)}
            //     style={{ width: '100%' }}
            //     value={filter && filter.value.match(/[\d/]+$/gm) ? filter.value : ''}
            //   />
          },
          {
            id: 'approved_date', // Required because our accessor is not a string
            Header: t('approvalDate'),
            headerClassName: 'wordwrap',
            accessor: 'approved_date',
            Cell: cellInfo => (
              <div>
                <span> {
                  cellInfo.original.approved_date === null ? '' : formatDate(cellInfo.original.approved_date)
                }</span>
              </div>
            ),
            // filterMethod: (filter, row) => {
            //   if (!filter.value.match(/[\d/]+$/gm)) {
            //     return true;
            //   }
            //   if (formatDate(row.approved_date)
            //     .includes(filter.value)) {
            //     return formatDate(row.approved_date);
            //   }
            //   //return formatDate(row.date).indexOf(filter.value) >=0;
            // },
            // Filter: ({ filter, onChange }) =>
            //   <input
            //     onChange={event => onChange(event.target.value)}
            //     style={{ width: '100%' }}
            //     value={filter && filter.value.match(/[\d/]+$/gm) ? filter.value : ''}
            //   />
          },
          {
            id: 'due_date', // Required because our accessor is not a string
            Header: t('scheduledDateTheProvider'),
            headerClassName: 'wordwrap',
            accessor: 'due_date',
            Cell: cellInfo => (
              <div>
                <span>{cellInfo.original.sender_status_id !== 'PAG' ?
                  formatDate(cellInfo.original.due_date) : cellInfo.original.sender_status_id === 'PAG' ?
                    formatDate(cellInfo.original.paid_date) : ''}</span>
              </div>
            ),
            // filterMethod: (filter, row) => {
            //   if (!filter.value.match(/[\d/]+$/gm)) {
            //     return true;
            //   }
            //   if (row._original.sender_status_id !== 'PAG') {
            //     if (formatDate(row.due_date)
            //       .includes(filter.value)) {
            //       return formatDate(row.due_date);
            //     }
            //   } else {
            //     if (formatDate(row._original.paid_date)
            //       .includes(filter.value)) {
            //       return formatDate(row._original.paid_date);
            //     }
            //   }
            //   //return formatDate(row.date).indexOf(filter.value) >=0;
            // },
            // Filter: ({ filter, onChange }) =>
            //   <input
            //     onChange={event => onChange(event.target.value)}
            //     style={{ width: '100%' }}
            //     value={filter && filter.value.match(/[\d/]+$/gm) ? filter.value : ''}
            //   />
          },
          {
            id: 'mode',
            Header: t('mode'),
            className: 'text-center',
            accessor: d => d.method.with_invoice === 1 ? t('traditional') : t('BPP'),
          },
          /*{
            id: 'card_collection_date', // Required because our accessor is not a string
            Header: t('onlyCardPaymentDate'),
            headerClassName: 'wordwrap',
            accessor: 'card_collection_date',
            Cell: cellInfo => (
              <div>
                <span> {
                  cellInfo.original.card_collection_date === null ? '' : formatDate(cellInfo.original.card_collection_date)
                }</span>
              </div>
            ),
            filterMethod: (filter, row) => {
              if (!filter.value.match(/[\d/]+$/gm)) {
                return true;
              }
              if (formatDate(row.card_collection_date)
                .includes(filter.value)) {
                return formatDate(row.card_collection_date);
              }
              //return formatDate(row.date).indexOf(filter.value) >=0;
            },
            Filter: ({ filter, onChange }) =>
              <input
                onChange={event => onChange(event.target.value)}
                style={{ width: '100%' }}
                value={filter && filter.value.match(/[\d/]+$/gm) ? filter.value : ''}
              />
          },*/
          {
            id: 'currency',
            Header: t('currency'),
            accessor: d => `${d.pay_currency_id}`,
          },
          {
            id: 'adjusted_amount', // Required because our accessor is not a string
            Header: t('totalPay'),
            headerClassName: 'wordwrap',
            accessor: d => d.adjusted_amount,
            Cell: cellInfo => (
              <Currency value={cellInfo.original.adjusted_amount} />
            ),
            sortMethod: (a, b) => Number(a)-Number(b),
            // filterMethod: (filter, row) => numberFilter(filter, row),
            // Filter: ({ filter, onChange }) =>
            //   <input
            //     onInput={onInputOnlyNumbers}
            //     onChange={event => onChange(event.target.value)}
            //     style={{ width: '100%' }}
            //     value={filter ? filter.value : ''}
            //   />
          },
          {
            id: 'provider', // Required because our accessor is not a string
            Header: t('provider'),
            // accessor: d => d.parent_id === null ? t('bankProvider') : `${d.receiver.idPerson.name}`,
            accessor: d => d.seller.name !== null ? `${d.seller.name}` : '',
          },
          /*{
            id: 'cif', // Required because our accessor is not a string
            Header: t('cif'),
            accessor: d => `${d.receiver.cif}`,
          },*/
          /*{
            id: 'typeAndDocumentNumber', // Required because our accessor is not a string
            Header: t('typeAndDocumentNumber'),
            headerClassName: 'wordwrap',
            // accessor: d => d.parent_id === null ? '' : `${getPath(['receiver', 'idPerson', 'idDocumentType', 'document_type'], d)} ${getPath(['receiver', 'idPerson', 'document_number'], d)}`,
            accessor: d => d.receiver_id === null ? '' : `${getPath(['receiver', 'idPerson', 'idDocumentType', 'document_type'], d)} ${getPath(['receiver', 'idPerson', 'document_number'], d)}`,
          },*/
          {
            id: 'sellerDocumentType', // Required because our accessor is not a string
            Header: t('RFC', {type : t('provider')}),
            headerClassName: 'wordwrap',
            accessor: d => d.seller.document_number !== null ? `${getPath(['seller', 'document_number'], d)}` : '',
          },
          {
            id: 'description', // Required because our accessor is not a string
            Header: t('description'),
            Cell: cellInfo => (
              <div>
                <span data-type="info"
                      data-tip={cellInfo.original.description}>{cellInfo.original.description}</span>
                <ReactTooltip/>
              </div>
            ),
          },
          {
            id: 'transferState', // Required because our accessor is not a string
            Header: t('transferState'),
            accessor: d => d.sender_status_id, //((d.senderStatus.id === "APG") || (d.senderStatus.id === "ANG")) ?  t("approvedStatus") : `${d.senderStatus.description}`, //Cambio temporal palabra "Garantizado"
          },
          {
            id: "action", // Required because our accessor is not a string
            Header: t("action"),
            accessor: (d) => "Acción",
            width: 220,
            filterable: false,
            Cell: cellInfo => this.actionCol(cellInfo)
          },
        ];
        data = bankReport;
        title = t('customers');
        break;
      case 'seller':
      case 'seller_assist_card':
        columns = [
          {
            id: 'name', // Required because our accessor is not a string
            Header: t('client'),
            accessor: d => `${d.buyer.name}`,
          },
          {
            id: 'document_type', // Required because our accessor is not a string
            Header: 'RFC',
            headerClassName: 'wordwrap',
            accessor: d => `${getPath(['buyer', 'document_number'], d)}`,
          },
          {
            id: 'invoicesNumber', // Required because our accessor is not a string
            Header: type_user === 'seller' ? t('invoiceNumber') : t('invoicesNumber'),
            accessor: d => `${d.invoice_number}`,
          },
          {
            id: 'arrangedDays', // Required because our accessor is not a string arrangedDays
            Header: t('arrangedDays'),
            headerClassName: 'wordwrap',
            accessor: d => `${d.payment_condition.days}`,
          },
          {
            id: 'date', // Required because our accessor is not a string
            Header: type_user === 'seller' ? t('sendDate') : t('shippingDate'),
            //accessor: d => formatDate(d.date),
            accessor: 'date',
            Cell: cellInfo => (
              <div>
                <span>{formatDate(cellInfo.original.date)}</span>
              </div>
            ),
            // filterMethod: (filter, row) => {
            //   if (!filter.value.match(/[\d/]+$/gm)) {
            //     return true;
            //   }
            //   if (formatDate(row.date)
            //     .includes(filter.value)) {
            //     return formatDate(row.date)
            //       .indexOf(filter.value) >= 0;
            //   }
            //   //return formatDate(row.date).indexOf(filter.value) >=0;
            // },
            // Filter: ({ filter, onChange }) =>
            //   <input
            //     onChange={event => onChange(event.target.value)}
            //     style={{ width: '100%' }}
            //     value={filter && filter.value.match(/[\d/]+$/gm) ? filter.value : ''}
            //   />
          },
          {
            id: 'approved_date', // Required because our accessor is not a string
            Header: t('approvalDate'),
            //accessor: d => formatDate(d.date),
            accessor: 'date',
            Cell: cellInfo => (
              <div>
                <span>{cellInfo.original.approved_date !== null ?
                  formatDate(cellInfo.original.approved_date)  : ''}</span>
              </div>
            ),
            // filterMethod: (filter, row) => {
            //   if (!filter.value.match(/[\d/]+$/gm)) {
            //     return true;
            //   }
            //   if (formatDate(row.approved_date)
            //     .includes(filter.value)) {
            //     return formatDate(row.approved_date);
            //   }
            //   //return formatDate(row.date).indexOf(filter.value) >=0;
            // },
            // Filter: ({ filter, onChange }) =>
            //   <input
            //     onChange={event => onChange(event.target.value)}
            //     style={{ width: '100%' }}
            //     value={filter && filter.value.match(/[\d/]+$/gm) ? filter.value : ''}
            //   />
          },
          {
            id: 'pay_provider_date', // Required because our accessor is not a string
            Header: t('scheduledCollectionDate'),
            //accessor: d => d.pay_provider_date !== null ? formatDate(d.pay_provider_date) : '',
            accessor: 'pay_provider_date',
            Cell: cellInfo => (
              <div>
                <span>
                  {(cellInfo.original.payment_type === 'NCL') 
                  ? formatDate(cellInfo.original.pay_provider_date)
                  : (cellInfo.original.sender_status_id !== 'PAG')
                  ? formatDate(cellInfo.original.pay_provider_date) : formatDate(cellInfo.original.paid_date)}

                </span>
              </div>
            ),
            // filterMethod: (filter, row) => {
            //   if (!filter.value.match(/[\d/]+$/gm)) {
            //     return true;
            //   }
            //   if (formatDate(row.pay_provider_date)
            //     .includes(filter.value)) {
            //     return formatDate(row.pay_provider_date);
            //   }
            //   //return formatDate(row.date).indexOf(filter.value) >=0;
            // },
            // Filter: ({ filter, onChange }) =>
            //   <input
            //     onChange={event => onChange(event.target.value)}
            //     style={{ width: '100%' }}
            //     value={filter && filter.value.match(/[\d/]+$/gm) ? filter.value : ''}
            //   />
          },
          {
            id: 'paymentType',
            Header: t('paymentType'),
            className: 'text-center',
            accessor: d => d.type_transaction ? d.type_transaction : '',
          },
          {
            id: 'mode',
            Header: t('mode'),
            className: 'text-center',
            accessor: d => d.method.with_invoice === 1 ? t('traditional') : t('BPP'),
          },
          {
            id: 'currency',
            Header: t('currency'),
            className: 'text-center',
            accessor: d => `${d.pay_currency_id}`,
          },
          {
            id: 'adjusted_amount', // Required because our accessor is not a string
            Header: t('totalPay'),
            accessor: d => d.adjusted_amount,
            Cell: cellInfo => (
              <Currency value={cellInfo.original.adjusted_amount} />
            ),
            sortMethod: (a, b) => Number(a)-Number(b),
            // filterMethod: (filter, row) => numberFilter(filter, row),
            // Filter: ({ filter, onChange }) =>
            //   <input
            //     onInput={onInputOnlyNumbers}
            //     onChange={event => onChange(event.target.value)}
            //     style={{ width: '100%' }}
            //     value={filter ? filter.value : ''}
            //   />
          },
          {
            id: 'description', // Required because our accessor is not a string
            Header: t('description'),
            Cell: cellInfo => (
              <div>
                <span data-type="info"
                      data-tip={cellInfo.original.description}>{cellInfo.original.description}</span>
                <ReactTooltip/>
              </div>
            ),
          },
          {
            id: 'relationshipStatus', // Required because our accessor is not a string != null
            Header: t('relationshipStatus'),
            accessor: d => d.payment_condition.status_keo !== null ? d.payment_condition.status_keo : '',
          },
          {
            id: 'transferState', // Required because our accessor is not a string
            Header: t('transferState'),
            accessor: d => d.sender_status_id !== null ? `${d.sender_status_id}` : '', //Cambio temporal palabra "Garantizado"
          },
          {
            id: "action", // Required because our accessor is not a string
            Header: t("action"),
            accessor: (d) => "Acción",
            width: 220,
            filterable: false,
            Cell: cellInfo => this.actionCol(cellInfo)
          },
        ];
        data = report;
        title = t('customers');
        break;
      case 'acquiring_query':
      case 'acquiring_bank':
        columns = [
          {
            id: 'provider', // Required because our accessor is not a string
            Header: t('provider'),
            accessor: d => `${d.seller.name}`,
          },
          /*{
            id: 'document_type', // Required because our accessor is not a string
            Header: t('typeAndDocumentNumber'),
            headerClassName: 'wordwrap',
            accessor: d => `${getPath(['receiver', 'idPerson', 'idDocumentType', 'document_type'], d)} ${getPath(['receiver', 'idPerson', 'document_number'], d)}`,
          },*/
          {
            id: 'RFCProvider', // Required because our accessor is not a string
            Header: 'RFC',
            headerClassName: 'wordwrap',
            accessor: d => `${getPath(['seller', 'document_number'], d)}`,
          },
          {
            id: 'invoicesNumber', // Required because our accessor is not a string
            Header: t('invoiceNumber'),
            accessor: d => `${d.invoice_number}`,
          },
          {
            id: 'arrangedDays',
            Header: t('arrangedDays'),
            headerClassName: 'wordwrap',
            accessor: d => `${d.payment_condition.days}`,
          },
          {
            id: 'date', // Required because our accessor is not a string
            Header: t('dateInvoice'),
            //accessor: d => formatDate(d.date),
            accessor: 'date',
            Cell: cellInfo => (
              <div>
                <span>{formatDate(cellInfo.original.date)}</span>
              </div>
            ),
            // filterMethod: (filter, row) => {
            //   if (!filter.value.match(/[\d/]+$/gm)) {
            //     return true;
            //   }
            //   if (formatDate(row.date)
            //     .includes(filter.value)) {
            //     return formatDate(row.date);
            //   }
            //   //return formatDate(row.date).indexOf(filter.value) >=0;
            // },
            // Filter: ({ filter, onChange }) =>
            //   <input
            //     onChange={event => onChange(event.target.value)}
            //     style={{ width: '100%' }}
            //     value={filter && filter.value.match(/[\d/]+$/gm) ? filter.value : ''}
            //   />
          },
          {
            id: 'approved_date', // Required because our accessor is not a string
            Header: t('approvalDate'),
            headerClassName: 'wordwrap',
            accessor: 'approved_date',
            Cell: cellInfo => (
              <div>
                <span> {
                  cellInfo.original.approved_date === null ? '' : formatDate(cellInfo.original.approved_date)
                }</span>
              </div>
            ),
            // filterMethod: (filter, row) => {
            //   if (!filter.value.match(/[\d/]+$/gm)) {
            //     return true;
            //   }
            //   if (formatDate(row.approved_date)
            //     .includes(filter.value)) {
            //     return formatDate(row.approved_date);
            //   }
            //   //return formatDate(row.date).indexOf(filter.value) >=0;
            // },
            // Filter: ({ filter, onChange }) =>
            //   <input
            //     onChange={event => onChange(event.target.value)}
            //     style={{ width: '100%' }}
            //     value={filter && filter.value.match(/[\d/]+$/gm) ? filter.value : ''}
            //   />
          },
          {
            id: 'pay_provider_date', // Required because our accessor is not a string
            Header: t('scheduledCollectionDate'),
            accessor: 'pay_provider_date',
            Cell: cellInfo => (
              <div>
                <span>{(cellInfo.original.sender_status_id !== 'PAG')
                  ? formatDate(cellInfo.original.pay_provider_date) : formatDate(cellInfo.original.paid_date)}</span>
              </div>
            ),
            // filterMethod: (filter, row) => {
            //   if (!filter.value.match(/[\d/]+$/gm)) {
            //     return true;
            //   }
            //   if (formatDate(row.pay_provider_date)
            //     .includes(filter.value)) {
            //     return formatDate(row.pay_provider_date);
            //   }
            //   //return formatDate(row.date).indexOf(filter.value) >=0;
            // },
            // Filter: ({ filter, onChange }) =>
            //   <input
            //     onChange={event => onChange(event.target.value)}
            //     style={{ width: '100%' }}
            //     value={filter && filter.value.match(/[\d/]+$/gm) ? filter.value : ''}
            //   />
          },
          {
            id: 'paymentType',
            Header: t('paymentType'),
            className: 'text-center',
            accessor: d => d.type_transaction ? d.type_transaction : '',
          },
          {
            id: 'mode',
            Header: t('mode'),
            className: 'text-center',
            accessor: d => d.method.with_invoice === 1 ? t('traditional') : t('BPP'),
          },
          {
            id: 'currency',
            Header: t('currency'),
            className: 'text-center',
            accessor: d => `${d.pay_currency_id}`,
          },
          {
            id: 'adjusted_amount', // Required because our accessor is not a string
            Header: t('totalPay'),
            accessor: d => d.adjusted_amount,
            Cell: cellInfo => (
              <Currency value={cellInfo.original.adjusted_amount} />
            ),
            sortMethod: (a, b) => Number(a)-Number(b),
            // filterMethod: (filter, row) => numberFilter(filter, row),
            // Filter: ({ filter, onChange }) =>
            //   <input
            //     onInput={onInputOnlyNumbers}
            //     onChange={event => onChange(event.target.value)}
            //     style={{ width: '100%' }}
            //     value={filter ? filter.value : ''}
            //   />
          },
          {
            id: 'client', // Required because our accessor is not a string
            Header: t('client'),
            accessor: d => d.buyer.name !== null ? `${d.buyer.name}` : '',
          },
          /*{
            id: 'typeAndDocumentNumber', // Required because our accessor is not a string
            Header: t('typeAndDocumentNumber'),
            headerClassName: 'wordwrap',
            accessor: d => `${getPath(['sender', 'idPerson', 'idDocumentType', 'document_type'], d)} ${getPath(['sender', 'idPerson', 'document_number'], d)}`,
          },*/
          {
            id: 'RFCBuyer', // Required because our accessor is not a string
            Header: 'RFC',
            headerClassName: 'wordwrap',
            accessor: d => d.buyer.document_number !== null ? `${getPath(['buyer', 'document_number'], d)}` : '',
          },
          {
            id: 'description', // Required because our accessor is not a string
            Header: t('description'),
            Cell: cellInfo => (
              <div>
                <span data-type="info"
                      data-tip={cellInfo.original.description}>{cellInfo.original.description}</span>
                <ReactTooltip/>
              </div>
            ),
          },
          {
            id: 'transferState', // Required because our accessor is not a string
            Header: t('transferState'),
            accessor: d => d.sender_status_id,
            //((d.senderStatus.id === "APG") || (d.senderStatus.id === "ANG")) ?  t("approvedStatus") : `${d.senderStatus.description}`, //Cambio temporal palabra "Garantizado"
          },
          {
            id: "action", // Required because our accessor is not a string
            Header: t("action"),
            accessor: (d) => "Acción",
            width: 220,
            filterable: false,
            Cell: cellInfo => this.actionCol(cellInfo)
          },
        ];
        data = bankReport;
        title = t('providers');
        break;
      case 'rate_operator':
        columns = [
          {
            id: 'invoice_number', // Required because our accessor is not a string
            Header: t('mainInvoiceNumber'),
            accessor: d => d.invoice_number_main === null ? '' : `${d.invoice_number_main}`,
          },
          {
            id: 'invoicesNumber', // Required because our accessor is not a string
            Header: t('invoicesNumber'),
            accessor: d => `${d.invoice_number}`,
          },
          {
            id: 'due_date', // Required because our accessor is not a string
            Header: t('datePayment'),
            //accessor: d => formatDate(d.date),
            accessor: 'due_date',
            Cell: cellInfo => (
              <div>
                <span>{formatDate(cellInfo.original.due_date)}</span>
              </div>
            ),
            // filterMethod: (filter, row) => {
            //   if (!filter.value.match(/[\d/]+$/gm)) {
            //     return true;
            //   }
            //   if (formatDate(row.due_date)
            //     .includes(filter.value)) {
            //     return formatDate(row.due_date);
            //   }
            // },
            // Filter: ({ filter, onChange }) =>
            //   <input
            //     onChange={event => onChange(event.target.value)}
            //     style={{ width: '100%' }}
            //     value={filter && filter.value.match(/[\d/]+$/gm) ? filter.value : ''}
            //   />
          },
          {
            id: 'currency', // Required because our accessor is not a string
            Header: t('currency'),
            accessor: d => `${d.pay_currency_id}`,
          },
          {
            id: 'adjusted_amount', // Required because our accessor is not a string
            Header: t('totalPay'),
            accessor: d => d.adjusted_amount,
            Cell: cellInfo => (
              <Currency value={cellInfo.original.adjusted_amount} />
            ),
            sortMethod: (a, b) => Number(a)-Number(b),
            // filterMethod: (filter, row) => numberFilter(filter, row),
            // Filter: ({ filter, onChange }) =>
            //   <input
            //     onInput={onInputOnlyNumbers}
            //     onChange={event => onChange(event.target.value)}
            //     style={{ width: '100%' }}
            //     value={filter ? filter.value : ''}
            //   />
          },
          {
            id: 'name',
            Header: t('customer'),
            accessor: d => `${d.sender.idPerson.name}`,
          },
          {
            id: 'description', // Required because our accessor is not a string
            Header: t('description'),
            Cell: cellInfo => (
              <div>
                <span data-type="info"
                      data-tip={cellInfo.original.description}>{cellInfo.original.description}</span>
                <ReactTooltip/>
              </div>
            ),
          },
          {
            id: 'transferState', // Required because our accessor is not a string
            Header: t('transferState'),
            accessor: d => ((d.senderStatus.id === "APG") || (d.senderStatus.id === "ANG")) ?  t("approvedStatus") : `${d.senderStatus.description}`, //Cambio temporal palabra "Garantizado"
          },
        ];
        data = list;
        title = t('transactions');
        break;
      default:
        columns = [];
        data = [];
        title = '';
    }
    return {
      columns,
      data,
      title,
    };
  };

  handleInputChange = (event) => {
    const {
      target: {
        checked, value: targetValue, type, name,
      },
    } = event;
    const value = type === 'checkbox' ? checked : targetValue;
    this.setState({
      [name]: value,
    });
  };

  handleSelectChange = (newVal, label) => {
    this.setState({
      [label.name]: newVal.value,
    });
  };

  fetchResultsAndDownload = (e, fileFormat, fileName) => {
    const {
      getAdminReport,
      getBankReport,
      getReport,
      auth: { user: { profile, b2b: { id, type_user, user_type } } },
    } = this.props;
    const { invoiceDate, invoiceDateUntil } = this.state;
    const date_min = invoiceDate / 1000,
      date_max = invoiceDateUntil / 1000;

    if (type_user === 'admin' || type_user === 'admin_amex') {
      getAdminReport({
        params: {
          download: true,
          date_min,
          date_max,
        }, callback: (response) => {
          this.setState({
            adminReport: response.transactionsList,
            totalRecords: Number(response.countList)
          });
          this.download(e, fileFormat, fileName)
        }
      });
    }
    if (type_user.includes('issuing') || type_user.includes('acquiring')) {
      getBankReport({
        params: {
          bank_id: id,
          role: type_user.includes('issuing') ? 'sender' : 'receiver',
          date_min,
          date_max,
          download: true,
        }, callback: (response) => {
          this.setState({
            bankReport: response.transactionsList,
            totalRecords: Number(response.countList)
          });
          this.download(e, fileFormat, fileName)
        }
      });
    }
    if ( (type_user === 'seller' || type_user === 'buyer' || profile.type === 'client' || type_user === 'seller_assist_card') ) {
      getReport({
        params: {
          role: (type_user === 'seller' || type_user === 'seller_assist_card') ? 'receiver' : 'sender',
          ncl: user_type === 'NCL',
          date_min,
          date_max,
          download: true
        }, callback: (response) => {
          this.setState({
            report: response.transactionsList,
            totalRecords: Number(response.countList),
          });
          this.download(e, fileFormat, fileName)
        }
      });
    }
  }

  download = (e, fileFormat, fileName) => {

    e.preventDefault();

    const {
      auth: {
        user: {
          b2b: {
            type_user, id
          },
        },
      },
    } = this.props;

    // Quitamos la infomación que no queremos exportar
    function cleanData(data) {
      return data.filter(item => !item.hasOwnProperty("expander") && item.show !== false && item.id !== "action");
    }

    // Traemos el estado actual de la tabla
    const { sortedData, columns } = this.selectTable.current.getResolvedState();

    // Creamos una deep copy para evitra mutar el objeto original
    let cleanedSortedData = deepClone(sortedData);
    let cleanedColumns = deepClone(columns);

    // Limpiamos los datos
    cleanedSortedData = cleanData(cleanedSortedData);
    cleanedColumns = cleanData(cleanedColumns);

    // Cambiamos el formato de la fecha, la moneda y completamos los datos necesarios
    for (let i = 0; i < cleanedSortedData.length; i++) {
      cleanedSortedData[i].description = cleanedSortedData[i]._original.description;
      cleanedSortedData[i].totalPay = "$" + formatCurrencyKeo(cleanedSortedData[i]._original.adjusted_amount);
      cleanedSortedData[i].adjusted_amount = "$" + formatCurrencyKeo(cleanedSortedData[i]._original.adjusted_amount);
      if (type_user === 'rate_operator') {
        cleanedSortedData[i].due_date = formatDate(cleanedSortedData[i].due_date);
        cleanedSortedData[i].totalPay = "$" + formatCurrencyKeo(cleanedSortedData[i]._original.base_amount);
      }
      if (type_user === 'buyer' || type_user === 'issuing_query' || type_user === 'acquiring_query'
        || type_user === 'acquiring_bank') {
        cleanedSortedData[i].invoicesNumber = cleanedSortedData[i].invoicesNumber+"";
        cleanedSortedData[i].buyerDocumentType = cleanedSortedData[i].buyerDocumentType+"";
        cleanedSortedData[i].sellerDocumentType = cleanedSortedData[i].sellerDocumentType+"";
        cleanedSortedData[i].approved_date = cleanedSortedData[i]._original.approved_date !== null ? formatDate(cleanedSortedData[i]._original.approved_date) : '';
        cleanedSortedData[i].due_date = type_user === 'buyer' ? (cleanedSortedData[i]._original.sender_status_id !== 'PAG') ?
          formatDate(cleanedSortedData[i].due_date) : formatDate(cleanedSortedData[i]._original.paid_date) :
          (cleanedSortedData[i]._original.sender_status_id !== 'PAG') ?
            formatDate(cleanedSortedData[i]._original.pay_provider_date) : formatDate(cleanedSortedData[i]._original.paid_date);
        cleanedSortedData[i].date = formatDate(cleanedSortedData[i].date);
      }
      if (type_user === 'issuing_query' || type_user === 'issuing_bank') {
        cleanedSortedData[i].invoicesNumber = cleanedSortedData[i].invoicesNumber+"";
        cleanedSortedData[i].buyerDocumentType = cleanedSortedData[i].buyerDocumentType+"";
        cleanedSortedData[i].sellerDocumentType = cleanedSortedData[i].sellerDocumentType+"";
        cleanedSortedData[i].approved_date = cleanedSortedData[i].approved_date !== null ? formatDate(cleanedSortedData[i].approved_date) : '';
        cleanedSortedData[i].due_date = cleanedSortedData[i]._original.receiver_status_id !== 'PAG' ?
          formatDate(cleanedSortedData[i].due_date) : formatDate(cleanedSortedData[i]._original.paid_date);
        cleanedSortedData[i].date = formatDate(cleanedSortedData[i].date);
      }
      if (type_user === 'seller') {
        cleanedSortedData[i].approved_date = cleanedSortedData[i]._original.approved_date !== null ? formatDate(cleanedSortedData[i]._original.approved_date) : '';
        cleanedSortedData[i].invoicesNumber = cleanedSortedData[i].invoicesNumber+"";
        cleanedSortedData[i].date = formatDate(cleanedSortedData[i].date);
        cleanedSortedData[i].pay_provider_date = (cleanedSortedData[i]._original.pay_provider_date !== null && cleanedSortedData[i]._original.sender_status_id !== 'PAG') ?
          formatDate(cleanedSortedData[i].pay_provider_date) :
          (cleanedSortedData[i]._original.paid_date !== null && cleanedSortedData[i]._original.sender_status_id === 'PAG') ?
            formatDate(cleanedSortedData[i]._original.paid_date) : '';

      }
      if (type_user === 'admin' || type_user === 'admin_amex') {
        cleanedSortedData[i].date = formatDate(cleanedSortedData[i].date);
        cleanedSortedData[i].approved_date = cleanedSortedData[i].approved_date !== null && cleanedSortedData[i].approved_date !== '31/12/1969' ? formatDate(cleanedSortedData[i].approved_date) : '';
        cleanedSortedData[i].due_date = cleanedSortedData[i]._original.receiver_status_id === 'PAP' ? '' : formatDate(cleanedSortedData[i].due_date);
      }
    }

    // Este array almacena los datos que vamos a exportar    
    let downloadFileData = [];

    // Pusheamos solo los headers de las columnas
    downloadFileData.push(cleanedColumns.map(column => column.Header));

    downloadFileData.push(...cleanedSortedData.map((sorted) => {
      const individualItems = {};
      cleanedColumns.forEach((column) => {
        individualItems[column.id] = sorted[column.id];
      });
      return Object.values(individualItems);
    }));
    
    if ( fileFormat === "csv" ) {
      this.setState({ downloadFileData }, () => {
        this.csvRef.current.link.click();
      });
    }

    if ( fileFormat === "xlsx" ) {
      const ws = XLSX.utils.json_to_sheet(downloadFileData, {skipHeader : true});
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws,  fileDateTime());
      XLSX.writeFile(wb, `${fileName} - ${fileDateTime()}.xlsx`);
    }
  };

  handleDateChange = (dateName, newDate) => {
    if(newDate) {
      if (dateName === 'invoiceDate') {
        newDate.setHours(0, 0, 0, 0);
      }
      if (dateName === 'invoiceDateUntil') {
        newDate.setHours(23, 59, 59, 999);
      }
    } 
    this.setState({
      [dateName]: newDate ? newDate : '',
    });
  };

  rangeDate = (pageSize, page, filtered, order, last_invoices = false) => {
    const {
      getAdminReport,
      getBankReport,
      getReport,
      rateTransaction,
      auth: { user: { profile, b2b: { id, type_user, user_type } } },
    } = this.props;
    const { invoiceDate, invoiceDateUntil } = this.state;

    const min_date = last_invoices ? '' : invoiceDate / 1000,
      max_date =  last_invoices ? '' : invoiceDateUntil / 1000;

    const sharedParams = {
      date_min: min_date,
      date_max: max_date,
      last_invoice: last_invoices,
      page,
      pageSize,
      search: getTableFilteredValues(filtered),
      order: encodeURIComponent(JSON.stringify(order[0]))
    }

    if (type_user === 'admin' || type_user === 'admin_amex') {
      getAdminReport({
        params: {
          ...sharedParams
        }, callback: (response) => {
          this.setState({
            adminReport: response.transactionsList,
            totalRecords: Number(response.countList)
          });
        }
      });
    }
    if (type_user.includes('issuing') || type_user.includes('acquiring')) {
      getBankReport({
        params: {
          bank_id: id,
          role: type_user.includes('issuing') ? 'sender' : 'receiver',
          ...sharedParams
        }, callback: (response) => {
          this.setState({
            bankReport: response.transactionsList,
            totalRecords: Number(response.countList)
          });
        }
      });
    }
    if ( (type_user === 'seller' || type_user === 'buyer' || profile.type === 'client' || type_user === 'seller_assist_card') ) {
      getReport({
        params: {
          role: (type_user === 'seller' || type_user === 'seller_assist_card') ? 'receiver' : 'sender',
          ncl: user_type === 'NCL',
          ...sharedParams
        }, callback: (response) => {
          this.setState({
            report: response.transactionsList,
            totalRecords: Number(response.countList)
          });
        }
      });
    }
    if (type_user === 'rate_operator') {
      rateTransaction({
        params: {
          date_init: invoiceDate,
          date_end: invoiceDateUntil / 1000,
          is_report: true,
        }
      });
    }
  };

  render() {
    const {
      transactions: { loading },
      t,
      showMessage,
      auth: { user: { b2b: { buyer, seller, admin, admin_amex, type_user } } }
    } = this.props;
    const { downloadCsv, invoiceDate, invoiceDateUntil, downloadFileData } = this.state;
    return (
      <Toggle>
        {({ toggled, onToggle }) => {
          const { title, columns, data } = this.getGridSettings(onToggle);
          return (
            <div className="dashboard">
              {loading && <Loading />}
              <div className="columns is-multiline">
                <Fragment>
                  {
                    toggled
                    && (
                      <Modal onToggle={onToggle} />
                    )
                  }
                  {data
                  && (
                    <div className="column is-full">
                      <div className='download-buttons-rep'>
                        <button
                          className="button custom-btn is-link is-outlined"
                          onClick={(e) => {
                            this.fetchResultsAndDownload(e, "csv")
                          }}
                        >
                          <i className="fa fa-file-csv mr-8px" aria-hidden="true"/>
                          <span>{`${t('download')} CSV`}</span>
                        </button>
                        <button
                          className="button custom-btn is-link is-outlined"
                          onClick={(e) => {
                            this.fetchResultsAndDownload(e, "xlsx", t('transactionReport'))
                          }}
                        >
                          <i className="fa fa-file-excel mr-8px" aria-hidden="true"/>
                          <span>{`${t('download')} XLSX`}</span>
                        </button>
                        <CSVLink
                          data={downloadFileData}
                          filename={`${t('transactionReport')} - ${fileDateTime()}.csv`}
                          className="hidden"
                          ref={this.csvRef}
                          separator={";"}
                          charSet="UTF-8"
                        />
                      </div>
                      <Panel headingText={t('transactionReport')}>
                        <div className="field is-horizontal">
                          <div className="field-body columns">
                            <div className="columns is-centered"
                                style={{ width: '100%', alignItems: 'center', margin: '10px 0' }}>
                                  <div className="column">
                                    <p style={{ fontSize: '1rem' }}>
                                      {buyer === 1 && t('invoicesPaymentDate')}
                                      {(seller === 1 || type_user === "acquiring_bank") && t('invoicesCollectiontDate')}
                                      {admin === 1 && t('sendInvoice')}
                                      {admin_amex === 1 && t('sendInvoice')}
                                    </p>
                                  </div>
                                  <div className="column" style={{ zIndex: 99999 }}>
                                    <DateInput
                                      name="invoice_date"
                                      label={t('since')}
                                      value={invoiceDate}
                                      onChange={(value) => this.handleDateChange('invoiceDate', value)}
                                      type="text"
                                      fieldClasses="is-expanded"
                                      controlClasses="has-icons-left"
                                      icon="far fa-calendar-alt"
                                      iconPosition="is-left"
                                    />
                                  </div>
                                  <div className="column" style={{ zIndex: 99999 }}>
                                    <DateInput
                                      name="invoice_date"
                                      label={t('until')}
                                      value={invoiceDateUntil}
                                      onChange={(value) => this.handleDateChange('invoiceDateUntil', value)}
                                      type="text"
                                      fieldClasses="is-expanded"
                                      controlClasses="has-icons-left"
                                      icon="far fa-calendar-alt"
                                      iconPosition="is-left"
                                    />
                                  </div>
                                  <div className= "column" style={{ marginTop: '19px' }}>
                                    <button
                                      type="button"
                                      className="button custom-btn is-primary"
                                      style={{ height: '35px' }}
                                      onClick={() => {
                                        invoiceDate === '' && invoiceDateUntil === '' ?
                                          showMessage({
                                            message: <strong
                                              style={{ color: '#ffffff' }}>{t('emptyFields')}</strong>,
                                            config: TOAST_CONFIG.ERROR,
                                          })
                                          : invoiceDateUntil < invoiceDate ?
                                          showMessage({
                                            message: <strong
                                              style={{ color: '#ffffff' }}>{t('errorDate')}</strong>,
                                            config: TOAST_CONFIG.ERROR,
                                          })
                                          :
                                          this.rangeDate(10, 0, [], [], false);
                                      }}
                                    >
                                      {t('search')}
                                    </button>
                                  </div>
                            </div>
                          </div>
                          <br/>
                        </div>
                        {/* <div style={{marginBottom: '1em'}}>
                          <button
                            className="button is-block is-primary"
                            onClick={this.download}
                          >
                            {`${t('download')} CSV`}
                          </button>
                          <CSVLink
                            data={downloadFileData}
                            filename="summary_b2b.csv"
                            className="hidden"
                            ref={this.csvRef}
                            separator=';'
                            charSet="UTF-8"
                          />
                        </div> */}
                        <TableSimple
                          data={data}
                          columns={columns}
                          reff={this.selectTable}
                          onFetchData={this.rangeDate}
                          hasHeavyData
                          // filtered={this.state.filtered}
                          // onFilteredChange={filtered => this.setState({ filtered })}
                          totalRecords={this.state.totalRecords}
                        />
                      </Panel>
                    </div>
                  )}
                </Fragment>
              </div>
            </div>
          );
        }}
      </Toggle>
    );
  }
}

Reports.propTypes = {
  t: PropTypes.func.isRequired,
  listTransactionAdmin: PropTypes.func.isRequired,
  listTransactionsSender: PropTypes.func.isRequired,
  listTransactionsReceiver: PropTypes.func.isRequired,
  transactions: PropTypes.shape(Object).isRequired,
  auth: PropTypes.shape(Object).isRequired,
  showMessage: PropTypes.func.isRequired,
};

const mapStateToProps = ({ auth, transactions }) => (
  {
    auth,
    transactions,
  }
);
export default compose(
  connect(mapStateToProps, { ...transactionsActions, ...clientsActions }),
  withNamespaces(),
)(Reports);