import React from 'react';
import './styles.scss';
import PropTypes from 'prop-types';
import WorkeoLogo from '../../../../resources/components/WorkeoLogo';

const MultiprofileDropdown = ({language, setLanguage, i18n, t, logout}) => {
    return (
        <div className="MultiprofileDropdown">
          <div>
            <WorkeoLogo width={130}/>
          </div>
          <div className="MultiprofileDropdown__actions">
            <div className="control has-icons-left">
              <div className="select is-full-width">
                <div className="icon is-small is-left">
                  <i className="fas fa-globe"/>
                </div>
                <select
                  data-name="account-BTN"
                  value={language}
                  onChange={({ target: { value } }) => {
                    setLanguage(value);
                    i18n.changeLanguage(value);
                    // eslint-disable-next-line no-undef
                    window.location.reload();
                  }}
                  // style={{ border: 'none' }}
                >
                  <option value="es">{t('es')}</option>
                  <option value="pt">{t('pt')}</option>
                  <option value="en">{t('en')}</option>
                </select>
              </div>
              <div className="icon is-small is-left" style={{color: 'black'}}>
                <i className="fas fa-globe"/>
              </div>
            </div>
            <button className="button" onClick={logout}>
              <span className="icon">
                <i className="fas fa-power-off"></i>
              </span>
              <span>{t('logout')}</span>
            </button>
          </div>
        </div>
    );
};

MultiprofileDropdown.propTypes = {
    language: PropTypes.string.isRequired, 
    setLanguage: PropTypes.func.isRequired, 
    t: PropTypes.func.isRequired,
    logout: PropTypes.func.isRequired,
    i18n: PropTypes.shape(Object).isRequired,
};

export default MultiprofileDropdown;