import React, { useState } from "react";
import PropTypes from "prop-types";
import { Redirect, Switch } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";
import { withNamespaces } from "react-i18next";
import { ToastContainer } from "react-toastify";
import { Aside, Dropdown, Footer, MultiprofileDropdown } from "./components";
import { authActions } from "../../state/ducks/auth";
import { RouteWithSubRoutes } from "../../config/routes";
import "./styles.scss";

const leadNumber = (list) => {
  if (Object.is(list)) {
    if (Object.keys(list).length > 0) {
      return 1;
    }
    return 0;
  }
  if (Array.isArray(list)) {
    return list.length;
  }
  return 0;
};

const Layout = (props) => {
  const {
    auth,
    auth: {
      user: {
        b2b: {
          confirm_information,
          type_user,
          label_type_user,
          paths,
          admin_name,
          admin_lastname,
          roles,
          registered_card,
          saved_credentials,
          pending_payment_condition,
          user_type,
        },
        name,
        lastname,
        name_abbreviation,
        colorAvatar,
        profile,
        // profile: { role: currentRole },
      },
      language: defaultLanguage,
      profiles: hasMultiProfiles,
    },
    logout,
    location,
    routes,
    transactions: {
      pending_invoices,
      in_process_invoices,
    },
    lead: { list: leadList },
    t,
    i18n,
    stats: { payment_request_pending },
  } = props;

  // It filters the 'select-profile' route for no multiprofiles users (everyone has this route by default in backend)
  const customPaths = paths ? !hasMultiProfiles ? paths.filter(path => path !== "/select-profile") : paths : [];

  const [language, setLanguage] = useState(defaultLanguage);
  const [sideBarStatus, setSidebarStatus] = useState(
    localStorage.getItem("sideBarStatus") != null
      ? localStorage.getItem("sideBarStatus")
      : "expanded"
  );

  const updateUser = (index) => {
    const {
      auth: {
        user: {
          b2b: { roles },
          profile: { user_id },
        },
      },
      changeRole,
      history,
    } = props;

    const data = {
      user_id,
      new_role: roles[index].id,
    };
    changeRole({
      data,
      callback: () => {
        history.push("/login");
      },
    });
  };

  const editCredentials = () => {
    const {
      auth: {
        user: {
          b2b: { type_user, paths },
        },
      },
    } = props;

    let edit = 0;
    if (type_user === "buyer") {
      paths.forEach(function(path) {
        if (path === "/edit-credentials") {
          edit = 1;
        }
      });
    }
    return edit;
  };

  return (
    <div className="Layout">
      <ToastContainer />
        { location.pathname !== '/select-profile' ? ( // AVATAR & ALERTS
          <Dropdown
            logout={logout}
            name={name === null ? "" : name}
            lastName={lastname === null ? "" : lastname}
            adminName={admin_name}
            shortName={name_abbreviation}
            colorAvatar={colorAvatar}
            typeUser={type_user}
            labelTypeUser={
              profile
                ? profile.label_role === "acquiring_query" ||
                  profile.label_role === "issuing_query"
                  ? "Consultor"
                  : t(profile.label_role)
                : label_type_user
            }
            mailboxNumber={
              type_user === "issuing_bank" || type_user === "acquiring_bank"
                ? payment_request_pending
                : type_user == "seller"
                ? in_process_invoices
                : type_user === "buyer"
                ? parseInt(pending_invoices) + parseInt(in_process_invoices)
                : leadNumber(leadList)
            }
            mailboxRoute={
              type_user === "issuing_bank" || type_user === "acquiring_bank"
                ? "/" + "payment-request"
                : type_user === "seller"
                ? "/" + "invoices"
                : type_user === "buyer"
                ? "/invoices"
                : "/lead-list"
            }
            //mailboxNumber={type_user === 'buyer' ? pending_invoices : leadNumber(leadList)}
            //mailboxRoute={type_user === 'buyer' ? '/review-invoices' : '/lead-list'}
            t={t}
            i18n={i18n}
            language={language}
            setLanguage={setLanguage}
            roles={roles}
            currentRole={profile.role}
            updateUser={updateUser}
            registerCard={registered_card}
            savedCredentials={saved_credentials}
            editCredentials={editCredentials()}
            pendingPaymentCondition={pending_payment_condition}
            adminLastname={admin_lastname}
            confirm_information={confirm_information}
            pathName={location.pathname}
            userType={user_type} // NCL o TDC
            hasMultiProfiles={hasMultiProfiles}
          /> // LOGOUT & LANGUAGE
        ) : (
          <MultiprofileDropdown
            t={t}
            language={language}
            setLanguage={setLanguage}
            i18n={i18n}
            logout={logout}
          />
        )}
      <div className="be-wrapper">
        { location.pathname !== '/select-profile'&& (
          <Aside
            location={location}
            auth={auth}
            setSidebarStatus={setSidebarStatus}
          />
        )}
        <div
          className={location.pathname !== "/select-profile" ? `be-content ${sideBarStatus}` : 'select-profile'}
        >
          <div className="main-content">
            <Switch>
              {routes.map(
                route => customPaths.some(path => path.includes(route.path)) &&
                <RouteWithSubRoutes key={`${route.path}`} {...route} />
              )}
              {hasMultiProfiles ? (
                <Redirect to="/select-profile" />
              ) : (
                <Redirect to="/dashboard" />
              )}
            </Switch>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

Layout.propTypes = {
  auth: PropTypes.shape(Object).isRequired,
  location: PropTypes.shape({}).isRequired,
  routes: PropTypes.arrayOf(
    PropTypes.shape({
      path: PropTypes.string.isRequired,
      component: PropTypes.func.isRequired,
      exact: PropTypes.bool.isRequired,
    }).isRequired
  ).isRequired,
  transactions: PropTypes.shape(Object).isRequired,
  lead: PropTypes.shape(Object).isRequired,
  logout: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  i18n: PropTypes.shape({}).isRequired,
  changeRole: PropTypes.func.isRequired,
};

const mapStateToProps = ({ auth, transactions, lead, stats }) => ({
  auth,
  transactions,
  lead,
  stats,
});

export default compose(
  connect(mapStateToProps, {
    ...authActions,
  }),
  withNamespaces()
)(Layout);
