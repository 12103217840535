const START_FETCH = 'bankingExecutives/START_FETCH';
const END_FETCH = 'bankingExecutives/END_FETCH';
const END_ERROR = 'bankingExecutives/END_ERROR';
const API_CALL = 'bankingExecutives/API_CALL';
const GET_BANKING_EXECUTIVES = 'bankingExecutives/GET_BANKING_EXECUTIVES';
const GET_PERSON_EXECUTIVE = 'bankingExecutives/GET_PERSON_EXECUTIVE';
const MESSAGE = 'bankingExecutives/MESSAGE';
const GET_EXECUTIVES = 'bankingExecutives/GET_EXECUTIVES';
const GET_CLIENTS = 'bankingExecutives/GET_CLIENTS';
const MIGRATE_EXECUTIVE = 'bankingExecutives/MIGRATE_EXECUTIVE';
const DELETE_EXECUTIVE = 'bankingExecutives/DELETE_EXECUTIVE';
const EDIT_EXECUTIVE = 'bankingExecutives/EDIT_EXECUTIVE';

export default {
  START_FETCH,
  END_FETCH,
  END_ERROR,
  API_CALL,
  GET_BANKING_EXECUTIVES,
  GET_PERSON_EXECUTIVE,
  MESSAGE,
  GET_EXECUTIVES,
  GET_CLIENTS,
  MIGRATE_EXECUTIVE,
  DELETE_EXECUTIVE,
  EDIT_EXECUTIVE,
};