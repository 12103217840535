import React, { Component, Fragment } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import PropTypes from 'prop-types';
import { supportActions } from '../../state/ducks/support';
import { roleActions } from '../../state/ducks/role';
import { Link } from 'react-router-dom';
import { Form, Loading, Modal, Panel, SelectSingle, TableSimple, TextArea, TextInput, Toggle } from '../../components';
import { API_URL, TOAST_CONFIG } from '../../config/constants';

class Support extends Component {
    constructor(props) {
        super(props);
        this.state = this.getInitialState();
    }

    getInitialState = () => ({
        id: '',
        faqs: [],
        question: '',
        link: '',
        answer: '',
        roles: [],
        role: '',
    });

    componentDidMount(){
        const { 
            auth: {user: { b2b: { roles, bank_id } } },
            getManuals,
            getFaqs
        } = this.props;
        
        getManuals({
            params: {
                role: roles[0].id,
                bank: bank_id,
            }
        });

        getFaqs({
            params: {
                role: roles[0].id,
            },
            callback: (response) => {
                this.setState({faqs: response.data});
            }
        })

    }

    render() {
        const { 
            t,
            role: {type: { list } } 
        } = this.props;
        const {
            faqs
        } = this.state;

        return(
            <Panel headingText={t('b2bInformation')}>
                <p style={{paddingLeft: '1.3em', marginBottom: '.5em'}} className="is-size-4 has-text-weight-bold">
                        {t('userManual')}
                </p>
                {
                    list.length == 0 &&
                    <div style={{paddingLeft: '2em', marginBottom: '1em'}} className="is-one-third">
                        <p>{t('noManuals')}</p>
                    </div>
                }
                { list.length > 0 &&
                <div style={{paddingLeft: '2em', marginBottom: '1em'}}>
                    <div style={{display: 'flex'}}>
                        <p style={{marginRight: '.5em'}}>{list[0].name}</p>
                        <a 
                            href={`${API_URL}b2b/resource-file?nameFile=${list[0].resource}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="button"
                        >
                            <a className="ml-">
                                <i style={{marginRight: '.5em'}} className="fas fa-download"/>
                                {t('download')}
                            </a>
                        </a>
                    </div>
                </div>
                }
                <div style={{paddingLeft: '2em'}}>
                    <p style={{marginBottom: '.5em'}} className="is-size-4 has-text-weight-bold">
                        FAQ's
                    </p>
                    <ul style={{paddingLeft: '1.5em'}}>
                        {
                            faqs.map(item => (
                                <li>
                                    <p className="has-text-weight-bold">{item.question}</p>
                                    <div style={{display: "flex", marginBottom: '.5em'}}>
                                        <p style={{marginRight: ".5em"}}>{t('supportLink')+': '}</p>
                                        <a href={item.link_support} target="_blank">{item.link_support}</a>
                                    </div>
                                    <p style={{paddingLeft: '1em', marginBottom: '1em'}}>{item.answer}</p>
                                </li>
                            ))
                        }
                    </ul>
                </div>
            </Panel>
        );
    }

}

Support.propTypes = {
    t: PropTypes.func.isRequired,
    getFaq: PropTypes.func.isRequired,
};

const mapStateToProps = ({ support, auth, router, role }) => (
    {
        auth,
        support,
        router,
        role,
    }
);

export default compose(
    connect(mapStateToProps, {
        ...supportActions,
        ...roleActions,
    }),
    withNamespaces(),
)(Support);