import types from './types';
import { authTypes } from '../auth';
import { initialState } from '../../../App';

const bankingExecutives = (state = {}, action) => {
  switch (action.type) {
    case authTypes.CLEAR: {
      return {
        ...state, ...initialState.bankingExecutives,
      };
    }
    case types.START_FETCH: {
      return {
        ...state,
        loading: true,
      };
    }
    case types.END_FETCH: {
      const { error, payload } = action;
      return {
        ...state,
        loading: false,
        error: error ? payload : null,
      };
    }
    case types.GET_CLIENTS: {
      const { payload } = action;
      return {
        ...state,
        list: payload,
      };
    }
    case types.GET_EXECUTIVES: {
      const { payload } = action;
      return {
        ...state,
        executives: payload,
      };
    }
    default:
      return state;
  }
};

export default bankingExecutives;
