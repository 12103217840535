import React, { Component } from 'react';
import { compose } from 'redux';
import './styles.scss';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import PropTypes from 'prop-types';
import { Loading, Modal, Toggle } from '../../components';
import { documentTypeActions } from '../../state/ducks/documentType';
import { providersActions } from '../../state/ducks/providers';
import { TOAST_CONFIG } from '../../config/constants';
import { paymentConditionsActions } from '../../state/ducks/paymentConditions';
import { itemActions } from '../../state/ducks/item';
import { clientsActions } from '../../state/ducks/clients';
import { leadActions } from '../../state/ducks/lead';
import PersonForm from '../../components/PersonForm';
import { roleActions } from '../../state/ducks/role';
import { flowApprovalActions } from '../../state/ducks/flowApproval';
import { currencyActions } from '../../state/ducks/currency';
import { cardActions } from '../../state/ducks/card';
import { validateAlphaNumeric } from '../../util'

class CreateProvider extends Component {
  constructor(props) {
    super(props);
    const { location: { state } } = props;
    this.state = { ...this.getInitialState(), ...state };
  }

  getInitialState = () => ({
    name: '',
    documentType: -1,
    documentNumber: '',
    address: '',
    email: '',
    password: '',
    percentage: 0,
    period: -1,
    installments: 1,
    items: [],
    client: '',
    startCountingFrom: 1,
    baseProvider: 0,
    baseDays: 0,
    discountRate: 0,
    adminName: '',
    adminLastname: '',
    adminDocumentNumber: '',
    adminDocumentType: [],
    adminPosition: '',
    receiverDiscountShare: 100,
    clientPayment: 0,
    noRetain: false,
    edit: false,
    hidden: false,
    typeFlow: -1,
    code: '',
    regions: [],
    region: -1,
    communes: [],
    commune: -1,
    inputValue: '',
    city: '',
    paymentCurrency: -1,
    currency: -1,
    inputValueExecutive: '',
    executives: [],
    assistCard: '',
    business_name: '',
    unformattedRut: '',
    unformattedAdminRut: '',
    newBankingExec: [],
    countBankingExec: 0,
    typePerson: '',
    typeDocument: [],
    documentTypeUser: '',
    type_legal_person: '',
    provinces: [],
    province: -1,
    cantons: [],
    canton: -1,
    districts: [],
    district: -1,
    street: '',
    number: '',
    otherSign: '',
    countTradeInformation: 0,
    commerce_code: [{
      id: 0,
      commerce_code: '',
      terminal: '',
      currency: -1,
      currency_id: '',
      description: '',
    }],
    cif: '',
    categories: [],
    category: '',
    username_csp: '',
    password_csp: '',
    send_notification: false,
    industries: [],
    industriesList: [],
    industry: -1,
    inputValueIndustry: '',
    outdoor_number: '',
    interior_number: '',
    suburb: '',
    municipality: '',
    postal_code: '',
    tradeInformationOption: '',
    step: 1,
  });

  componentDidMount() {
    const {
      t,
      fetchDocumentTypes,
      fetchIndustries,
      fetchItems,
      fetchCities,
      fetchRoles,
      getWorkFlowTypes,
      getCurrency,
      getExecutive,
      assistCard,
      router: { location: { pathname } },
      auth: { user: { b2b: { roles } } },
      fetchCategories,
    } = this.props;
    assistCard({});
    fetchDocumentTypes({
      params: { status: 1 },
      callback: (data) => {
        this.setState({ 
          adminDocumentType: data,
          typeDocument: data 
        });
      }
    });
    fetchCategories({
      callback: (response) => {
        this.setState({ categories: response });
      }
    });
    fetchItems({
      category_id: "",
    });
    getExecutive({
      params: { owner: roles[0].owner_id },
      callback: (response) => {
        const exc = [];
        let j = 0;
        for (let i = 0; i < response.length + 1; i++) {
          if (i === 0) {
            exc.push({
              bank_id: response.length === 0 ? roles[0].owner_id : response[i].bank_id,
              lastname: '',
              id: -1,
              email: t('businessExecutive'),
              name: t('addNew'),
            });
          } else {
            exc.push({
              bank_id: response[j].bank_id,
              email: response[j].email,
              id: response[j].id,
              lastname: response[j].lastname,
              name: response[j].name,
            });
            j += 1;
          }
        }
        this.setState({ exc: exc });
      }
    });
    getCurrency({});
    fetchCategories({
      callback: (response) => {
        this.setState({ categories: response });
      }
    });
    fetchCities({
      callback: (response) => {
        this.setState({ districts: response });
      }
    });
    getWorkFlowTypes({});
    fetchRoles({
      params: {
        name: 'seller',
        type: 'external',
      },
    });

    if (pathname === '/create-provider') {
      fetchIndustries({
        callback: (response) => {
          this.setState({ industriesList: response});
        }
      });
    }
    if (pathname === '/edit-provider') this.setState(this.arrayProvider);
  }

  resetState = () => {
    this.setState(this.getInitialState());
  };

  getTitle = () => {
    const {
      t,
      router: { location: { pathname } },
    } = this.props;

    switch (pathname) {
      case '/create-provider':
        return t('createProvider');
      case '/edit-provider':
        return t('edit');
      default:
        return t('');
    }
  };

  arrayProvider = () => {
    const {
      providers: { list: providerList },
      documentType: { list: documentTypeList },
      fetchCities,
      currency: { list: listCurrency },
      fetchDocumentTypes,
      item: { list: listCategories },
      fetchIndustries,
    } = this.props;

    fetchIndustries({
      callback: (response) => {
        this.setState({
          industriesList: response,
          industry:  (providerList.seller[0].industries.length > 0 && providerList.seller[0].industries[0].id !== null) ? response.findIndex(x => x.id === providerList.seller[0].industries[0].id) : -1,
        });
      }
    });

    let documentType = -1;
    if (providerList.seller[0].idPerson.idDocumentType !== null) {
      fetchDocumentTypes({
        params: {
          status: 1
        },
        callback: (data) => {
          const typeDocument = providerList.seller[0].idPerson.idDocumentType.document_type !== 'RFC' ? 'RFC' : providerList.seller[0].idPerson.idDocumentType.document_type;
          documentType = (data.map(
            (documentTypeMap, index) => ({
              ...documentTypeMap,
              index
            })
          )
            .filter(documentTypeFil => documentTypeFil.document_type
              === typeDocument)[0].index);
          this.setState({
            documentTypeUser: documentType,
            typeDocument: data,
            typePerson: providerList.seller[0].idPerson.idDocumentType.document_type === 'Cédula jurídica' ? 'legal' : 'person',
            type_legal_person: providerList.seller[0].idPerson.idDocumentType.document_type === 'Cédula jurídica' ? 'JUR' : 'FIS',
          });
        }
      });
    }

    if (providerList.seller[0].admin_document_type !== '' && providerList.seller[0].admin_document_type !== null) {
      fetchDocumentTypes({
        params: { status: 1 },
        callback: (data) => {
          documentType = (data.map(
            (documentTypeMap, index) => ({
              ...documentTypeMap,
              index
            })
          )
            .filter(documentTypeFil => documentTypeFil.document_type
              === providerList.seller[0].admin_document_type)[0].index);
          this.setState({
            documentType: documentType,
          });
        }
      });
    }
    const item = [];
    for (let i = 0; i < providerList.items.length; i++) {
      item.push({
        id: providerList.items[i].id,
        classification: providerList.items[i].classification,
        code: providerList.items[i].code,
        description: providerList.items[i].description
      });
    }
    const executives = [];
    for (let i = 0; i < providerList.banking_executive.length; i++) {
      executives.push({
        id: providerList.banking_executive[i].executive.id,
        name: providerList.banking_executive[i].executive.name,
        lastname: providerList.banking_executive[i].executive.lastname,
        email: providerList.banking_executive[i].executive.email
      });
    }

    const industry = [];
    for (let i = 0; i < providerList.seller[0].industries.length; i++){
      industry.push({
        id: providerList.seller[0].industries[i].id,
        code: providerList.seller[0].industries[i].code,
        description: providerList.seller[0].industries[i].description,
      });
    }

    const commerce_code = [];
    if (providerList.commerce_code.length === 0) {
      commerce_code.push({
        id: 0,
        commerce_code: '',
        terminal: '',
        currency: -1,
        currency_id: '',
        description: '',
      });
    } else {
      for (let i = 0; i < providerList.commerce_code.length; i++) {
        commerce_code.push({
          id: providerList.commerce_code[i].id,
          commerce_code: providerList.commerce_code[i].commerce_code,
          terminal: providerList.commerce_code[i].terminal,
          currency: providerList.commerce_code[i].currency_id !== null ? (listCurrency.map(
            (listCurrencyTypeMap, index) => ({
              ...listCurrencyTypeMap,
              index
            })
          )
            .filter(listCurrencyTypeFil => listCurrencyTypeFil.id
              === providerList.commerce_code[i].currency_id)[0].index) : '',
          currency_id: providerList.commerce_code[i].currency_id,
          description: providerList.commerce_code[i].description
        });
      }
    }
    const period = providerList.seller[0].paymentConditionAsBuyer[0] !== null ?
      this.getDays(providerList.seller[0].paymentConditionAsBuyer[0].days) : -1;

    if (providerList.seller[0].district !== null) {
      fetchCities({
        callback: (response) => {
          this.setState({
            districts: response,
            district: response.findIndex(x => x.id == providerList.seller[0].district.id)
          });
        }
      });
    }

    return {
      category: providerList.items[0].category_id !== null ? listCategories.findIndex(x => x.id === parseInt(providerList.items[0].category_id)) : -1,
      code: providerList.seller[0].cod,
      name: providerList.seller[0].idPerson.name,
      documentTypeUser: documentType,
      documentNumber: providerList.seller[0].idPerson.document_number,
      cif: providerList.seller[0].cif !== null ? providerList.seller[0].cif : '',
      address: providerList.seller[0].address_full,
      street: providerList.seller[0].address !== null ? providerList.seller[0].address.street : '',
      outdoor_number: providerList.seller[0].address !== null ? providerList.seller[0].address.outdoor_number : '',
      interior_number: providerList.seller[0].address !== null ? providerList.seller[0].address.interior_number : '',
      suburb: providerList.seller[0].address !== null ? providerList.seller[0].address.suburb : '',
      municipality: providerList.seller[0].address !== null ? providerList.seller[0].address.municipality : '',
      postal_code: providerList.seller[0].address !== null ? providerList.seller[0].address.postal_code : '',
      adminName: providerList.seller[0].admin_name,
      adminLastname: providerList.seller[0].admin_lastname,
      adminDocumentNumber: providerList.seller[0].admin_document_number === null ? '' : providerList.seller[0].admin_document_number,
      adminPosition: providerList.seller[0].admin_position,
      email: providerList.seller[0].idPerson.idEmailActive.email,
      percentage: providerList.seller[0].paymentConditionAsBuyer[0] !== null ? providerList.seller[0].paymentConditionAsBuyer[0].percentage : 0,
      typeFlow: providerList.process_type === null ? 0 : providerList.process_type,
      period,
      edit: true,
      items: item,
      hidden: true,
      business_name: providerList.seller[0].business_name === null ? '' : providerList.seller[0].business_name,
      executives,
      commerce_code,
      username_csp: providerList.seller[0].username_csp === null ? '' : providerList.seller[0].username_csp,
      password_csp: providerList.seller[0].password_csp === null ? '' : providerList.seller[0].password_csp,
      send_notification: providerList.seller[0].send_notification === '1',
      authorized_by: providerList.seller[0].authorized_by,
      tradeInformationOption: providerList.seller[0].user_type ? providerList.seller[0].user_type : '',
    };
  };

  getDays = (period) => {
    switch (period) {
      case 365:
        return 0;
      case 30:
        return 1;
      case 1:
        return 2;
      default:
        return '';
    }
  };

  getDaysInv = (period) => {
    switch (period) {
      case 0:
        return 365;
      case 1:
        return 30;
      case 2:
        return 1;
      default:
        return '';
    }
  };

  formatPercentage = (n) => {
    return n.replace(/[^0-9]/, '');
  };

  formatRUT = (n) => {
    return n.replace(/[^0-9Kk]/, '');
    //return n.replace(/[^a-zA-Z0-9]/, '');
  };

  formatNumber = (n) => {
    if (n.length < 14) {
      return n.replace(/\D/g, '')
        .replace(/([0-9]{14})$/);
    } else {
      return;
    }
  };

  formatNumber2 = (value, length, type) => {
    if (value.length <= length) {
      if(type === "number") {return value.replace(/\D/g, '')}
      if(type === "text") {return value.replace(new RegExp("[0-9]"), '')}
      if(type === "textNum") {return value.replace(new RegExp("^[a-zA-Z][0-9]"), '')}
    } else {
      return value.slice(0, length)
    }
  }

  handleInputChangeNewExc = (event) => {
    const {
      target: {
        checked, value: targetValue, type, name,
      },
    } = event;
    const {
      auth: { user: { b2b: { id } } },
    } = this.props;
    const value = type === 'checkbox' ? checked : targetValue;

    const executive = name.split('-');

    let newBankingExec = [...this.state.newBankingExec];

    switch (executive[0]) {
      case 'name':
        newBankingExec[executive[1]] = {
          id: Number(executive[1]),
          name: value,
          lastname: newBankingExec[executive[1]].lastname,
          email: newBankingExec[executive[1]].email,
          bank_id: id,
        };
        break;
      case 'lastname':
        newBankingExec[executive[1]] = {
          id: Number(executive[1]),
          name: newBankingExec[executive[1]].name,
          lastname: value,
          email: newBankingExec[executive[1]].email,
          bank_id: id,
        };
        break;
      case 'email':
        newBankingExec[executive[1]] = {
          id: Number(executive[1]),
          name: newBankingExec[executive[1]].name,
          lastname: newBankingExec[executive[1]].lastname,
          email: value,
          bank_id: id,
        };
        break;
    }

    this.setState({ newBankingExec });
  };

  handleOnInputChange = (newValue) => {
    this.setState({ inputValue: newValue });
    return newValue;
  };

  handleOnInputChangeExecutive = (newValue) => {
    this.setState({ inputValueExecutive: newValue });
    return newValue;
  };

  handleOnInputChangeIndustry = (newValue) => {
    this.setState({ inputValueIndustry: newValue });
    return newValue;
  };

  handleInputChangeTypeDocument = (event) => {
    const {
      fetchDocumentTypes,
    } = this.props;

    const {
      target: {
        checked, value: targetValue, type, name,
      },
    } = event;
    const value = type === 'checkbox' ? checked : targetValue;

    fetchDocumentTypes({
      params: { legal_type : value === 'legal' ? 'J' : 'F' },
      callback: (data) => {
        this.setState({
          type_legal_person: value === 'legal' ? 'JUR' : 'FIS',
          typeDocument: data,
          [name]: value,
        });
      }
    });

  }

  handleInputChange = (event) => {
    const {
      target: {
        checked, value: targetValue, type, name,
      },
    } = event;
    const value = type === 'checkbox' ? checked : targetValue;
    
    if (name === 'postal_code') {
      let num = this.formatPercentage(value);
      this.setState({
          [name]: (num),
      });
    }else{
      this.setState({
        [name]: value,
      });
    }
  };

  handleInputChangeAdminDocument = (event) => {
    const {
      target: {
        checked, value: targetValue, type, name,
      },
    } = event;
    const {
      adminDocumentType,
    } = this.state;

    const value = type === 'checkbox' ? checked : targetValue;

    if ((name === 'adminDocumentNumber' && adminDocumentType === 0)) {
      let num = this.formatNumber(event.target.value);
      this.setState({
        [name]: (num),
      });
    } else {
      this.setState({
        [name]: value,
      });
    }
  };

  handleRegionChange = (newVal, label) => {
    const { fetchCommunes } = this.props;
    const { provinces } = this.state;

    this.setState({
      [label.name]: newVal.value,
    });

    fetchCommunes({
      province_id: provinces[newVal.value].id,
      callback: (response) => {
        this.setState({
          cantons: response,
        });
      }
    });
  }

  handleCantonChange = (newVal, label) => {

    const { fetchCities } = this.props;
    const { cantons } = this.state;

    this.setState({
      [label.name]: newVal.value,
    });

    fetchCities({
      canton_id: cantons[newVal.value].id,
      callback: (response) => {
        this.setState({
          districts: response,
        });
      }
    });
  }

  handlePercentageChange = (event) => {
    const {
      target: {
        checked, value: targetValue, type, name,
      },
    } = event;
    const value = type === 'checkbox' ? checked : targetValue;
    this.setState({
      [name]: value,
      clientPayment: 100 - Number(value),
    });
  };

  handleSelectChange = (newVal, label) => {
    const {
      t,
      showMessage,
      router: { location: { pathname } },
      fetchItems,
    } = this.props;
    const {
      categories,
    } = this.state;

    if (label.name === 'currency' && pathname === '/edit-provider') {
      showMessage({
        message: <strong style={{ color: '#ffffff' }}>{t('changeCode')}</strong>,
        config: TOAST_CONFIG.ERROR,
      });
    }

    if(label.name === 'category'){
      fetchItems({
        category_id: categories[newVal.value].id,
      });
    }

    this.setState({
      [label.name]: newVal.value,
    });
  };

  handleItemChange = (newValue) => {
    this.setState({ items: newValue });
  };

  handleIndustryChange = (newValue) => {
    this.setState({ industries: newValue });
  };

  handleExecutivesChange = (newValue, event) => {
    const {
      auth,
    } = this.props;
    const {
      newBankingExec,
    } = this.state;

    if (event.action === 'clear') {
      this.setState({
        executives: newValue,
        newBankingExec: [],
      });
    } else {
      if (event.action === 'remove-value' && event.removedValue.id === -1) {
        this.setState({
          executives: newValue,
          newBankingExec: [],
        });
      } else {
        if (newValue[newValue.length - 1].id === -1) {
          newBankingExec[0] = {
            id: 0,
            name: '',
            lastname: '',
            email: '',
            bank_id: auth.user.b2b.id,
          };
          this.setState({
            executives: newValue,
            newBankingExec: newBankingExec
          });
        } else {
          this.setState({
            executives: newValue
          });
        }
      }
    }
  };

  addBankingExec = () => {
    const {
      auth,
      showMessage,
      t,
    } = this.props;
    const {
      newBankingExec,
      countBankingExec,
    } = this.state;
    let count = 0,
      i = 1;
    count = countBankingExec + i;

    if (newBankingExec[newBankingExec.length - 1].email === '') {
      showMessage({
        message: <strong style={{ color: '#ffffff' }}>{t('mandatoryFieldsRequired')}</strong>,
        config: TOAST_CONFIG.ERROR,
      });
    } else {
      let variable = {
        id: count,
        name: '',
        lastname: '',
        email: '',
        bank_id: auth.user.b2b.id,
      };
      this.state.newBankingExec.push(variable);
    }
    this.setState({
      countBankingExec: count
    });
  };

  deleteBankingExec = (index) => {
    const {
      newBankingExec,
    } = this.state;
    this.setState(newBankingExec.splice(index, 1));
  };

  array_executives = (executives) => {
    let variable = [];

    for (let i = 0; i < executives.length; i++) {
      if (executives[i].id !== -1) {
        variable.push(Number(executives[i].id));
      }
    }
    return variable;
  };

  handleInputChangeTradeInformation = (event) => {
    const {
      currency: { list }
    } = this.props;
    const {
      target: {
        checked, value: targetValue, type, name,
      },
    } = event;

    const value = type === 'checkbox' ? checked : targetValue;

    if (name !== 'TDC' && name !== 'NCL') {
      const information = name.split('-');

      let commerce_code = [...this.state.commerce_code];

      switch (information[0]) {
        case 'commerce_code':
          commerce_code[information[1]] = {
            id: Number(information[1]),
            commerce_code: this.formatNumber2(value, 10, "number"),
            terminal: commerce_code[information[1]].terminal,
            currency: commerce_code[information[1]].currency,
            currency_id: commerce_code[information[1]].currency_id,
            description: commerce_code[information[1]].description,
          };
          break;
        case 'terminal':
          commerce_code[information[1]] = {
            id: Number(information[1]),
            commerce_code: commerce_code[information[1]].commerce_code,
            terminal: value,
            currency: commerce_code[information[1]].currency,
            currency_id: commerce_code[information[1]].currency_id,
            description: commerce_code[information[1]].description,
          };
          break;
        case 'currency':
          commerce_code[information[1]] = {
            id: Number(information[1]),
            commerce_code: commerce_code[information[1]].commerce_code,
            terminal: commerce_code[information[1]].terminal,
            currency: value,
            currency_id: list[value].id,
            description: commerce_code[information[1]].description,
          };
          break;
        case 'description':
          commerce_code[information[1]] = {
            id: Number(information[1]),
            commerce_code: commerce_code[information[1]].commerce_code,
            terminal: commerce_code[information[1]].terminal,
            currency: commerce_code[information[1]].currency,
            currency_id: commerce_code[information[1]].currency_id,
            description: value,
          };
          break;
      }

      this.setState({ commerce_code });
    } else {
      this.setState({ tradeInformationOption: name });
    }
  };

  handleSelectCurrency = (newVal, label) => {
    const {
      currency: { list },
    } = this.props;

    const cycles1 = label.name.split('-');
    const index = cycles1[1];

    let commerce_code = [...this.state.commerce_code];

    commerce_code[index] = {
      id: index,
      commerce_code: commerce_code[index].commerce_code,
      terminal: commerce_code[index].terminal,
      currency: newVal.value,
      currency_id: list[newVal.value].id,
      description: commerce_code[index].description,
    };

    this.setState({ commerce_code });
  };

  createProvider = (onToggle) => {
    const {
      createProvider,
      auth: {
        user: {
          b2b: {
            roles,
          },
        },
      },
      t,
      showMessage,
      documentType: { list: documentTypeList },
      deleteLead,
      role: { list: roleList },
      router: { location: { pathname } },
      providers: { list: providerList },
      editUser,
      history,
      flowApproval: { list: typeFlows },
      currency: { list: listCurrency },
    } = this.props;
    const {
      name,
      documentType,
      documentNumber,
      email,
      password,
      address,
      percentage,
      period,
      items,
      lead,
      lead_id,
      adminName,
      adminLastname,
      adminDocumentNumber,
      adminDocumentType,
      adminPosition,
      noRetain,
      districts,
      district,
      executives,
      assistCard,
      business_name,
      newBankingExec,
      documentTypeUser,
      typeDocument,
      type_legal_person,
      street,
      commerce_code,
      cif,
      username_csp,
      password_csp,
      send_notification,
      industry,
      industriesList,
      outdoor_number,
      interior_number,
      suburb,
      municipality,
      postal_code,
      authorized_by,
      tradeInformationOption,
    } = this.state;

    if (pathname === '/create-provider') {
      const banking_executive = this.array_executives(executives);
      const data = {
        name,
        document_type: typeDocument[documentTypeUser].document_type,
        document_number: documentNumber,
        email,
        password,
        user_type: 'flecha',
        agency_user_type: 'business_admin',
        role_name: roleList[0].id,
        items,
        process_type: null,
        commerce_code: tradeInformationOption.includes('TDC') ? commerce_code : [],
        banking_executive,
        new_banking_executive: newBankingExec,
        type_provider: assistCard === true ? 'assist_card' : 'seller',
        username_csp,
        password_csp,
        industries: [{
          id: industriesList[industry === -1 ? 0 : industry].id,
        }],
        b2b: {
          type_legal_person,
          bank_id: roles[0].owner_id,
          admin: 0,
          issuing_bank: 0,
          acquiring_bank: 0,
          seller: 1,
          buyer: 0,
          retain: noRetain ? 0 : 1,
          admin_name: adminName,
          admin_lastname: adminLastname,
          admin_document_number: adminDocumentNumber,
          admin_document_type: documentType !== -1 ? adminDocumentType[documentType].document_type : '',//'RUT',
          admin_position: adminPosition,
          business_name,
          cif,
          send_notification: send_notification === true ? 1 : 0,
          authorized_by,
          user_type: tradeInformationOption,
        },
        b2b_address: {
          street,
          outdoor_number,
          interior_number,
          suburb,
          municipality,
          postal_code,
          district_id: districts[district].id,
        },
      };

      createProvider({
        data,
        callback: (response) => {
          if (lead) {
            deleteLead({
              params: {
                id: lead_id,
              },
            });
          }
          showMessage({
            message: t('createProviderSuccess', { name }),
            config: TOAST_CONFIG.SUCCESS,
          });
          this.resetState();
          history.push('/dashboard');
        },
      });
      onToggle()
    }

    if (pathname === '/edit-provider') {
      if (this.validateEmptyFields()) {
        let band = false;
        commerce_code.forEach(element => {
          if (element.commerce_code.length < 10) {
            band = true;
          }
        });
        if (band && tradeInformationOption.includes('TDC')) {
          showMessage({
            message: <strong style={{ color: '#ffffff' }}>{t('minimumCharacter')}</strong>,
            config: TOAST_CONFIG.ERROR,
          });
        } else {
          const data = {
            id_user: providerList.seller[0].id,
            name,
            type_legal_person,
            documentType: typeDocument[documentTypeUser].id,//documentType: '',//documentTypeList[documentType].id,
            documentNumber: documentNumber,
            email,
            items,
            industries: [{
              id: industriesList[industry === -1 ? 0 : industry].id,
            }],
            address,
            admin_name: adminName,
            admin_lastname: adminLastname,
            admin_document_number: adminDocumentNumber,
            admin_document_type: documentType !== -1 ? adminDocumentType[documentType].document_type : '',
            admin_position: adminPosition,
            percentage,
            days: this.getDaysInv(period),
            type: 'B',
            process_type: null,
            banking_executive: this.array_executives(executives),
            new_banking_executive: newBankingExec,
            business_name,
            username_csp,
            password_csp,
            b2b_address: {
              street,
              outdoor_number,
              interior_number,
              suburb,
              municipality,
              postal_code,
              district_id: districts[district].id,
            },
            commerce_code: tradeInformationOption.includes('TDC') ? commerce_code : [],
            cif,
            send_notification: send_notification === true ? '1' : '0',
            authorized_by,
          };
          editUser({
            data,
            callback: () => {
              history.push('/dashboard');
              this.resetState();
              showMessage({
                message: t('editProviderSuccess', { name }),
                config: TOAST_CONFIG.SUCCESS,
              });
            },
          });
        }
      } else {
        showMessage({
          message: <strong style={{ color: '#ffffff' }}>{t('emptyAllRequest')}</strong>,
          config: TOAST_CONFIG.ERROR,
        });
      }
    }
  };

  isEmail = n => /[a-zA-Z0-9_]+([.][a-zA-Z0-9_]+)*@[a-zA-Z0-9_]+([.][a-zA-Z0-9_]+)*[.][a-zA-Z]{1,5}/.test(n);

  validateEmptyFields = () => {
    const {
      //code,
      name,
      //address,
      adminName,
      adminLastname,
      adminDocumentNumber,
      //adminDocumentType,
      documentNumber,
      //documentType,
      email,
      adminPosition,
      items,
      commerce_code,
      //region,
      //commune,
      //currency,
      //executives,
      street,
      outdoor_number,
      suburb,
      municipality,
      postal_code,
      district,
      tradeInformationOption,
    } = this.state;

    return !(
      name === '' ||
      adminName === '' ||
      adminLastname === '' ||
      documentNumber === '' ||
      email === '' ||
      adminPosition === '' ||
      commerce_code.currency_id === -1 || postal_code == '' ||
      street == '' || outdoor_number == '' || suburb == '' || 
      municipality == '' || district == -1 || items.length <= 0 || 
      (tradeInformationOption.includes('TDC') && this.validateCommercoCode()) || 
      tradeInformationOption === ''
    ) 
  };

  labelType = (typeList) => {
    const {
      t,
    } = this.props;
    const types = [];
    types.push({
      name: t('dontPose')
    });
    for (let i = 0; i < typeList.length; i++) {
      types.push({
        id: typeList[i].id,
        name: t(typeList[i].name)
      });
    }
    return types;
  };

  validateCommercoCode = () => {
    const {
      commerce_code,
    } = this.state;

    return ((commerce_code[commerce_code.length - 1].commerce_code === '' || commerce_code[commerce_code.length - 1].currency === -1)
    || commerce_code[commerce_code.length - 1].description === '');
  }

  addTradeInformation = () => {
    const {
      showMessage,
      t,
    } = this.props;
    const {
      countTradeInformation,
    } = this.state;
    let count = 0,
      i = 1;
    count = countTradeInformation + i;

    if (this.validateCommercoCode()) {
      showMessage({
        message: <strong style={{ color: '#ffffff' }}>{t('emptyAll')}</strong>,
        config: TOAST_CONFIG.ERROR,
      });
    } else {
      let variable = {
        id: count,
        commerce_code: '',
        terminal: '',
        currency_id: -1,
        description: '',
      };
      this.state.commerce_code.push(variable);
    }

    this.setState({
      countTradeInformation: count
    });
  };

  deleteTradeInformation = (index) => {
    const {
      commerce_code,
    } = this.state;
    this.setState(commerce_code.splice(index, 1));
  };

  cancelSave = () => {
    const { history } = this.props;
    history.push('/dashboard');
  }

  showConfirmationModal = (onToggle) => {
    const { 
      showMessage, 
      t 
    } = this.props;
    const {
      commerce_code,
      tradeInformationOption,
    } = this.state

    if (this.validateEmptyFields()) {
      let commerceCodeIsShort = false;
      commerce_code.forEach(element => {
        if (element.commerce_code.length < 10) {
          commerceCodeIsShort = true;
        }
      });

      switch (true) {
        case (commerceCodeIsShort && tradeInformationOption.includes('TDC')):
          showMessage({
            message: <strong style={{ color: '#ffffff' }}>{t('minimumCharacter')}</strong>,
            config: TOAST_CONFIG.ERROR,
          });
          break;
        default:
          onToggle()
          break;
      }
    } else {
      showMessage({
        message: <strong style={{ color: '#ffffff' }}>{t('mandatoryFieldsRequired')}</strong>,
        config: TOAST_CONFIG.ERROR,
      });
    }
  }

  render() {
    const {
      t,
      clients: { loading: clientLoading, /*list: clientList*/ },
      providers: { loading: providerLoading },
      documentType: { list: documentTypesList },
      item: { list: itemList },
      flowApproval: { list: typeFlows },
      auth: { user: { profile: { role } } },
      currency: { list },
      card: { assist, list: executivesList },
      path,
    } = this.props;
    const {
      name,
      documentNumber,
      documentType,
      email,
      password,
      address,
      percentage,
      baseDays,
      items,
      discountRate,
      adminName,
      adminLastname,
      adminDocumentNumber,
      adminDocumentType,
      adminPosition,
      receiverDiscountShare,
      clientPayment,
      noRetain,
      period,
      edit,
      hidden,
      typeFlow,
      code,
      regions,
      region,
      communes,
      commune,
      inputValue,
      inputValueExecutive,
      city,
      currency,
      executives,
      assistCard,
      business_name,
      exc,
      newBankingExec,
      typePerson,
      typeDocument,
      documentTypeUser,
      provinces,
      province,
      cantons,
      canton,
      districts,
      district,
      street,
      number,
      otherSign,
      commerce_code,
      cif,
      categories,
      category,
      username_csp,
      password_csp,
      send_notification,
      industries,
      industriesList,
      inputValueIndustry,
      outdoor_number,
      interior_number,
      suburb,
      municipality,
      postal_code,
      authorized_by,
      industry,
      tradeInformationOption,
    } = this.state;
    return (
      <Toggle>
        {({ toggled, onToggle, toggleProps }) => {

          return (
            <>
              {(clientLoading || providerLoading) && <Loading />}
              {toggled ? 
                <Modal 
                  onToggle={() => onToggle()}
                  title={t('wantToContinue')}>
                    <>
                      <div className='has-text-centered p-24px'>
                        {
                          tradeInformationOption === "TDC" ?
                          <p className='is-size-3'>{t('TDCUserConfirm')}</p> :
                          <p className='is-size-3'>{t('NCLUserConfirm')}</p>
                        }
                      </div>
                      <div className='modal-action-btns'>
                        <button className='button custom-btn is-danger' onClick={() => onToggle()}>
                          Cancelar
                        </button>
                        <button className='button custom-btn is-primary' onClick={() => this.createProvider(onToggle)}>
                          Guardar
                        </button>
                      </div>
                    </>
                </Modal> :
                <div className="columns">
                  <div className="column is-full">
                    <PersonForm
                      t={t}
                      title={this.getTitle()}
                      onSubmit={() => path === '/create-provider' ? this.showConfirmationModal(onToggle) : this.createProvider(onToggle)}
                      handleInputChange={this.handleInputChange}
                      handleInputChangeAdminDocument={this.handleInputChangeAdminDocument}
                      name={name}
                      handleSelectChange={this.handleSelectChange}
                      handleItemChange={this.handleItemChange}
                      handleExecutivesChange={this.handleExecutivesChange}
                      documentNumber={documentNumber}
                      address={address}
                      documentType={documentType}
                      items={items}
                      itemList={itemList}
                      email={email}
                      password={password}
                      baseDays={baseDays}
                      percentage={percentage}
                      discountRate={discountRate}
                      person="provider"
                      adminName={adminName}
                      adminLastname={adminLastname}
                      adminDocumentNumber={ adminDocumentNumber }
                      adminDocumentType={ adminDocumentType }
                      adminPosition={adminPosition}
                      handlePercentageChange={this.handlePercentageChange}
                      receiverDiscountShare={receiverDiscountShare}
                      clientPayment={clientPayment}
                      typeFlows={this.labelType(typeFlows)}
                      typeFlow={typeFlow}
                      period={period}
                      edit={edit}
                      hidden={hidden}
                      code={code}
                      role={role}
                      regions={regions}
                      region={region}
                      handleRegionChange={this.handleRegionChange}
                      handleCantonChange={this.handleCantonChange}
                      cantons={cantons}
                      canton={canton}
                      districts={districts}
                      district={district}
                      communes={communes}
                      commune={commune}
                      handleOnInputChange={this.handleOnInputChange}
                      handleOnInputChangeExecutive={this.handleOnInputChangeExecutive}
                      inputValue={inputValue}
                      inputValueExecutive={inputValueExecutive}
                      city={city}
                      paymentCurrency={list}
                      executivesList={exc}
                      executives={executives}
                      assistCard={assistCard}
                      exist={assist !== null ? assist.exist : ''}
                      business_name={business_name}
                      newBankingExec={newBankingExec}
                      addBankingExec={this.addBankingExec}
                      deleteBankingExec={this.deleteBankingExec}
                      handleInputChangeNewExc={this.handleInputChangeNewExc}
                      handleInputChangeTypeDocument={this.handleInputChangeTypeDocument}
                      documentTypeUser={documentTypeUser}
                      documentTypesList={typeDocument}
                      noRetain={typePerson}
                      provinces={provinces}
                      province={province}
                      street={street}
                      number={number}
                      otherSign={otherSign}
                      tradeInformation={commerce_code}
                      addTradeInformation={this.addTradeInformation}
                      deleteTradeInformation={this.deleteTradeInformation}
                      handleInputChangeTradeInformation={this.handleInputChangeTradeInformation}
                      handleSelectCurrency={this.handleSelectCurrency}
                      cif={cif}
                      cancelSave={this.cancelSave}
                      categories={categories}
                      category={category}
                      username_csp={username_csp}
                      password_csp={password_csp}
                      send_notification={send_notification}
                      handleOnInput={validateAlphaNumeric}
                      industries={industries}
                      industriesList={industriesList}
                      handleIndustryChange={this.handleIndustryChange}
                      inputValueIndustry={inputValueIndustry}
                      handleOnInputChangeIndustry={this.handleOnInputChangeIndustry}
                      outdoor_number={outdoor_number}
                      interior_number={interior_number}
                      suburb={suburb}
                      municipality={municipality}
                      postal_code={postal_code}
                      authorized_by={authorized_by}
                      industry={industry}
                      tradeInformationOption={tradeInformationOption}
                    />
                  </div>
                </div>
              }
            </>
          )
        }}
      </Toggle>
    );
  }
}

CreateProvider.propTypes = {
  clients: PropTypes.shape(Object).isRequired,
  auth: PropTypes.shape(Object).isRequired,
  documentType: PropTypes.shape(Object).isRequired,
  item: PropTypes.shape(Object).isRequired,
  location: PropTypes.shape(Object).isRequired,
  fetchDocumentTypes: PropTypes.func.isRequired,
  fetchItems: PropTypes.func.isRequired,
  createProvider: PropTypes.func.isRequired,
  deleteLead: PropTypes.func.isRequired,
  createPaymentCondition: PropTypes.func.isRequired,
  showMessage: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  router: PropTypes.shape(Object).isRequired,
  providers: PropTypes.shape(Object).isRequired,
  editUser: PropTypes.func.isRequired,
  history: PropTypes.shape(Object).isRequired,
};


const mapStateToProps = ({
                          clients, documentType, auth, item, role, router, providers, flowApproval, currency, card
                        }) => ({
  clients,
  documentType,
  auth,
  item,
  role,
  router,
  providers,
  flowApproval,
  currency,
  card,
});
export default compose(
  connect(mapStateToProps, {
    ...documentTypeActions,
    ...providersActions,
    ...paymentConditionsActions,
    ...itemActions,
    ...clientsActions,
    ...leadActions,
    ...roleActions,
    ...providersActions,
    ...flowApprovalActions,
    ...currencyActions,
    ...cardActions,
  }),
  withNamespaces(),
)(CreateProvider);