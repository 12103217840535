import React, { Component, Fragment } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import PropTypes from 'prop-types';
import Modal from '../../components/Modal';
import { Loading, Panel, TableSimple, Toggle, } from '../../components';
import { roleActions } from '../../state/ducks/role';
import { authActions } from '../../state/ducks/auth';
import { logActions } from '../../state/ducks/log';
import TextArea from '../../components/TextArea';
import TextInput from '../../components/TextInput';

class LogApiRequest extends Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  getInitialState = () => ({
    log: '',
  });

  componentDidMount() {
    const {
      logApiRequests,
    } = this.props;
    logApiRequests({
      callback: (response) => {
        this.setState({
          log: response,
        });
      }
    });
  }

  resetState = () => {
    this.setState(this.getInitialState());
  };

  getGridSettings = (onToggle) => {
    const {
      t,
    } = this.props;
    const {
      log,
    } = this.state;

    const columns = [
      {
        expander: true,
        show: false,
      },
      {
        id: 'created_at', // Required because our accessor is not a string
        Header: t('date'),
        accessor: d => `${d.created_at} `,
      },
      {
        id: 'url', // Required because our accessor is not a string
        Header: t('url'),
        accessor: d => `${d.url} `,
      },
      {
        id: 'source', // Required because our accessor is not a string
        Header: t('source'),
        accessor: d => (d.origin),
      },
      {
        id: 'status', // Required because our accessor is not a string
        Header: t('responseStatus'),
        accessor: d => d.response ? d.response.status : '',
      },
      {
        id: 'message', // Required because our accessor is not a string
        Header: t('replyMessage'),
        accessor: d => d.response ? d.response.message : '',
      },
      {
        id: 'action', // Required because our accessor is not a string
        Header: t('action'),
        filterable: false,
        //accessor: d => `${d.id} `,
        Cell: cellInfo => (
          <table>
            <tbody>
            <tr>
              <td>
                <button
                  className="button is-primary is-inverted"
                  onClick={() => {
                    this.setState({
                      request: JSON.stringify(cellInfo.original.request),
                      response: JSON.stringify(cellInfo.original.response),
                    }, () => {
                      onToggle({});
                    });
                  }}
                >
                  <i className="far fa-eye mr-8px" aria-hidden="true"/>
                  {t('view')}
                </button>
              </td>
            </tr>
            </tbody>
          </table>
        )
      },
    ];
    const data = log;
    const title = t('apiRequests');
    return {
      columns,
      data,
      title,
    };
  };

  closeModal = (onToggle) => {
    onToggle();
  };

  render() {
    const {
      t,
      log: { loading },
    } = this.props;
    const { request, response } = this.state;
    return (
      <Toggle>
        {({ toggled, onToggle }) => {
          const { title, columns, data } = this.getGridSettings(onToggle);
          return (
            <div className="dashboard">
              {loading && <Loading/>}
              <div className="columns is-multiline">
                <Fragment>
                  {
                    toggled
                    && (
                      <Modal onToggle={onToggle} className="wider-modal">
                        <div style={{width: "100%"}}>
                          <Panel headingText={t('logView')}>
                            <div className="field is-horizontal">
                              <div className="field-body columns">
                                <div className="column">
                                  <TextArea
                                    name="request"
                                    label={t('request')}
                                    value={request}
                                    onChange={this.closeModal}
                                    type="text"
                                    inputClasses="has-text-left"
                                    disabled={true}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="field is-horizontal">
                              <div className="field-body columns">
                                <div className="column">
                                  <TextArea
                                    name="response"
                                    label={t('response')}
                                    value={response}
                                    onChange={this.closeModal}
                                    type="text"
                                    inputClasses="has-text-right"
                                    disabled={true}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="is-fullwidth has-text-right">
                              <button
                                type="button"
                                className="button is-primary"
                                onClick={() => onToggle({})}
                              >
                                {t('accept')}
                              </button>
                            </div>
                          </Panel>
                        </div>
                      </Modal>
                    )
                  }
                  {data
                    && (
                      <div className="column is-full">
                        <Panel headingText={title}>
                          <TableSimple data={data} columns={columns}/>
                        </Panel>
                      </div>
                    )}
                </Fragment>
              </div>
            </div>
          );
        }}
      </Toggle>
    );
  }
}

LogApiRequest.propTypes = {
  t: PropTypes.func.isRequired,
};

const mapStateToProps = ({ role, auth, log, }) => (
  {
    role,
    auth,
    log,
  }
);
export default compose(
  connect(mapStateToProps, { ...roleActions, ...authActions, ...logActions }),
  withNamespaces(),
)(LogApiRequest);
