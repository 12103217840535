import { supportActions, supportTypes } from '../ducks/support';

const saveFaq = ({ dispatch }) => next => (action) => {
    next(action);

    if (action.type !== supportTypes.SAVE_FAQ) {
        return;
    }

    const {
        data,
        callback = () => {
        },
    } = action.payload;

    dispatch({
        type: supportTypes.API_CALL,
        payload: {
        config: {
            method: 'POST',
            url: 'b2b-support/save-faq',
            data,
        },
        authorization: true,
        onStart: supportActions.startFetch,
        onComplete: ({ data: responseData }) => {
            const { success, data } = responseData;
            if (success) {
            /*dispatch(roleActions.saveRole({
                rol,
            }));*/
            callback(responseData);
            }
        },
        onEnd: supportActions.endFetch,
        },
    });
};

const getFaqs = ({ dispatch }) => next => (action) => {
    next(action);

    if (action.type !== supportTypes.GET_FAQS) {
        return;
    }

    const {
        params: {
            role = '',
        },
        callback = () => {
        },
    } = action.payload;

    dispatch({
        type: supportTypes.API_CALL,
        payload: {
        config: {
            method: 'GET',
            url: `b2b-support/get-faqs?role=${role}`,
        },
        authorization: true,
        onStart: supportActions.startFetch,
        onComplete: ({ data: responseData }) => {
            const { success, data } = responseData;
            if (success) {
            /*dispatch(roleActions.saveRole({
                rol,
            }));*/
                callback(responseData);
            }
        },
        onEnd: supportActions.endFetch,
        },
    });
};

const updateFaq = ({ dispatch }) => next => (action) => {
    next(action);

    if (action.type !== supportTypes.UPDATE_FAQ) {
        return;
    }

    const {
        data,
        callback = () => {
        },
    } = action.payload;

    dispatch({
        type: supportTypes.API_CALL,
        payload: {
        config: {
            method: 'POST',
            url: 'b2b-support/update-faq',
            data,
        },
        authorization: true,
        onStart: supportActions.startFetch,
        onComplete: ({ data: responseData }) => {
            const { success, data } = responseData;
            if (success) {
            /*dispatch(roleActions.saveRole({
                rol,
            }));*/
                callback(responseData);
            }
        },
        onEnd: supportActions.endFetch,
        },
    });
};

const supportRoles = ({ dispatch }) => next => (action) => {
    next(action);

    if (action.type !== supportTypes.SUPPORT_ROLES) {
        return;
    }

    const {
        callback = () => {
        },
    } = action.payload;

    dispatch({
        type: supportTypes.API_CALL,
        payload: {
        config: {
            method: 'GET',
            url: '/b2b-roles/roles-support',
        },
        authorization: true,
        onStart: supportActions.startFetch,
        onComplete: ({ data: responseData }) => {
            const { success } = responseData;
            if (success) {
            callback(responseData);
            }
        },
        onEnd: supportActions.endFetch,
        },
    });
};

export default [saveFaq, supportRoles, getFaqs, updateFaq];
