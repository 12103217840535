import React, { Component } from 'react';
import { compose } from 'redux';
import './styles.scss';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import PropTypes from 'prop-types';
import { InvoiceDetailForm, LogDetailForm, Loading } from '../../components';
import { documentTypeActions } from '../../state/ducks/documentType';
import { providersActions } from '../../state/ducks/providers';
import { paymentConditionsActions } from '../../state/ducks/paymentConditions';
import { roleActions } from '../../state/ducks/role';
import { clientsActions } from '../../state/ducks/clients';
import { transactionsActions } from '../../state/ducks/transactions';
import { formatDate } from '../../util';
import { TOAST_CONFIG, API_URL } from '../../config/constants';

class InvoiceDetail extends Component {
  constructor(props) {
    super(props);
    this.state = { ...this.getInitialState() };
  }

  getInitialState = () => ({
    userType: false,
    client: '',
    invoice_amount: null,
    invoice_date: new Date(),
    invoice_description: '',
    invoice_number: null,
    due_date: new Date(),
    wf_status: [],
    sender: false,
    receiver: false,
    issuing_bank: false,
    cardPayment: [],
    message: '',
    isEdit: false,
    cardMask: '',
    auth: '',
    cardCollectionDate: '',
    payProviderDate: '',
    receiverStatusId: '',
    provider: '',
    status: '',
    wf_status_emit: [],
    discountRateCharges: null,
    discountRateChargesDate: '',
    discountRateChargesDueDate: '',
    discountRateChargesInvoiceNumber: '',
    discountRateChargesDiscountRate: '',
    discountRateChargesPercentage: '',
    discountRateChargesTotal: '',
    currency: '',
    approvalDate: '',
    commerceCode: '',
    descriptionCommerceCode: '',
    paymentLog: null,
    paymentType: '',
    NCLCommission: '',
    paidDate: '',
    senderStatus: '',
  });

  componentDidMount() {
    const {
      auth: {
        user: {
          b2b: { type_user },
        },
      },
      t,
      transactions: { list },
    } = this.props;

    let cardMask = null;
    if (list.card_payment.length > 0) cardMask = this.cardMask(list.card_payment[0].number);

    this.setState({
      invoice_date: formatDate(list.date),
      due_date: type_user === 'buyer' ? formatDate(list.due_date) : list.pay_provider_date !== null ? formatDate(list.pay_provider_date) : '',
      client: type_user === 'buyer' ? list.receiver.idPerson.name : list.sender.idPerson.name,
      provider: type_user === 'issuing_bank' ? list.receiver.idPerson.name : null,
      status: type_user === 'issuing_bank' ? list.senderStatus.description : null,
      invoice_number: list.invoice_number,
      invoice_description: list.description,
      invoice_amount: this.formatNumber(list.adjusted_amount),
      wf_status: list.wf_status,
      wf_status_emit: list.wf_status_emit,
      cardPayment: list.card_payment,
      sender: type_user === 'buyer',
      receiver: type_user === 'seller',
      issuing_bank: type_user === 'issuing_bank' ? true : false,
      acquiring_bank: type_user === 'acquiring_bank',
      cardMask: cardMask,
      message: list.log_message,
      auth: list.auth === null ? t('pending') : list.auth,
      days: parseInt(list.payment_condition.due_from_approval) === 1 ?
        list.payment_condition.days + t('daysSince') + t('approvalDate') :
        list.payment_condition.days + t('daysSince') + t('receptionDate'),
      isEdit: true,
      cardCollectionDate: list.card_collection_date === null ? null : formatDate(list.card_collection_date),
      payProviderDate: list.pay_provider_date === null ? null : formatDate(list.pay_provider_date),
      receiverStatusId: type_user === 'seller' ? list.receiver_status_id : list.sender_status_id,
      discountRateCharges: list.rate_transaction !== null ? list.rate_transaction : null,
      discountRateChargesDescription: list.rate_transaction !== null ? list.rate_transaction.description : null,
      discountRateChargesDate: list.rate_transaction !== null ? formatDate(list.rate_transaction.date) : null,
      discountRateChargesDueDate: list.rate_transaction !== null ? formatDate(list.rate_transaction.due_date) : null,
      discountRateChargesInvoiceNumber: list.rate_transaction !== null ? list.rate_transaction.invoice_number : null,
      discountRateChargesDiscountRate: list.rate_transaction !== null ? list.payment_condition.discount_rate.slice(0, -1) : null,
      discountRateChargesPercentage: list.rate_transaction !== null ? list.payment_condition.percentage.slice(0, -2) : null,
      discountRateChargesTotal: list.rate_transaction !== null ? list.rate_transaction.adjusted_amount : null,
      currency: list.pay_currency_id !== null ? list.pay_currency_id : '',
      approvalDate: list.approved_date !== null ? formatDate(list.approved_date) : t('pendingToApprove'),
      commerceCode: type_user === 'seller' ? list.payment_condition.personCode !== null ?
        list.payment_condition.personCode.commerce_code : t('dontPose') : '',
      descriptionCommerceCode: type_user === 'seller' ? list.payment_condition.personCode !== null ?
        list.payment_condition.personCode.description : t('dontPose') : '',
      paymentLog: list.payment_log,
      paidDate: list.paid_date !== null ? formatDate(list.paid_date) : '',
      senderStatus: list.sender_status_id,
      paymentType: list.payment_type,
      NCLCommission: list.payment_condition.ncl_commission,
    });
  }

  resetState = () => {
    this.setState(this.getInitialState());
  };

  cardMask = (number) => {
    let part1 = number.substring(0, 6);
    let part2 = number.substring(12, 16);

    return part1 + ' - XXXXXX - ' + part2;
  };

  formatNumber = (n) => {
    return n.replace(/\D/g, '')
      .replace(/([0-9])([0-9]{2})$/, '$1.$2')
      .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ',');
  };

  handleInputChange = (event) => {
    const {
      target: {
        checked, value: targetValue, type, name,
      },
    } = event;
    const value = type === 'checkbox' ? checked : targetValue;
    this.setState({
      [name]: value,
    });
  };

  handleSelectChange = (newVal, label) => {
    this.setState({
      [label.name]: newVal.value,
    });
  };

  updatePaid = () => {
    const {
      t,
      history,
      showMessage,
      setTransaction,
      transactions: { list },
    } = this.props;
    setTransaction({
      params: {
        id: list.id,
      },
      callback: () => {
        showMessage({
          message: t('invoiceMarkPaid', { invoiceNumber: list.invoice_number }),
          config: TOAST_CONFIG.SUCCESS,
        });
        history.push('/manual-resolution');
      }
    });
  };

  // goBack = () => {
  //   const userInfo = this.props.location.state !== undefined ? this.props.location.state.userInfo : -1;
  //   const { history } = this.props;

  //   history.push({
  //     pathname: '/invoices',
  //     state: {
  //       userInfo: userInfo
  //     },
  //   });
  // };

  render() {
    const {
      t,
      clients: { loading: clientLoading },
      documentType: { loading: documentTypesLoading },
      role: { loading: roleLoading },
      transactions: {
        loading: { transactionsLoading },
        list: { resource, payment_resource, resource_xml, sender_status_id },
      },
      history
    } = this.props;
    const {
      client,
      invoice_amount,
      invoice_date,
      invoice_description,
      invoice_number,
      due_date,
      wf_status,
      sender,
      receiver,
      cardPayment,
      isEdit,
      registerLoading,
      message,
      cardMask,
      auth,
      days,
      cardCollectionDate,
      payProviderDate,
      receiverStatusId,
      issuing_bank,
      provider,
      status,
      wf_status_emit,
      discountRateCharges,
      discountRateChargesDescription,
      discountRateChargesDate,
      discountRateChargesDueDate,
      discountRateChargesInvoiceNumber,
      discountRateChargesDiscountRate,
      discountRateChargesPercentage,
      discountRateChargesTotal,
      currency,
      approvalDate,
      commerceCode,
      descriptionCommerceCode,
      paymentLog,
      paidDate,
      senderStatus,
      paymentType,
      NCLCommission,
      acquiring_bank,
    } = this.state;
    const loading = clientLoading || documentTypesLoading || roleLoading || registerLoading || transactionsLoading;

    return (
      <div>
        {loading && <Loading/>}
        <div className="columns">
          <div className="column is-full">
            <div className='download-buttons-rep'>
              <a
                href={ resource ? `${API_URL}b2b/resource-file?nameFile=${resource}` : null}
                target="_blank"
                rel="noopener noreferrer"
                className="button custom-btn is-link is-outlined"
                style={{ padding: '0 20px' }}
                download
                disabled={!resource}
              >
                <i className="fa fa-file-pdf mr-8px"/>
                <span title={t('downloadPDF')}> PDF </span>
              </a>
              <a
                href={resource_xml ? resource_xml : null}
                target="_blank"
                rel="noopener noreferrer"
                className="button custom-btn is-link is-outlined"
                style={{ padding: '0 20px' }}
                download
                disabled={!resource_xml}
              >
                <i className="fa fa-file-code mr-8px"/>
                <span title={t('downloadXML')}> XML </span>
              </a>
              {sender_status_id === 'PAG' &&
                <a
                  href={ payment_resource ? `${API_URL}b2b/resource-file?nameFile=${payment_resource}` : null}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="button custom-btn is-link is-outlined"
                  style={{ padding: '0 20px' }}
                  download
                  disabled={!payment_resource}
                >
                  <i className="fa fa-file-invoice mr-8px"/>
                  <span title={`${t('download')} ${t('transferDetail2')}`}> {t('transferDetail2')} </span>
                </a>
              }
            </div>
            <InvoiceDetailForm
              t={t}
              handleInputChange={this.handleInputChange}
              client={client}
              invoice_date={invoice_date}
              due_date={due_date}
              invoiceNumber={invoice_number}
              description={invoice_description}
              invoiceAmount={invoice_amount}
              wf_status={wf_status}
              sender={sender}
              receiver={receiver}
              issuing_bank={issuing_bank}
              provider={provider}
              cardPayment={cardPayment}
              message={message}
              isEdit={isEdit}
              cardMask={cardMask}
              auth={auth}
              days={days}
              cardCollectionDate={cardCollectionDate}
              payProviderDate={payProviderDate}
              receiverStatusId={receiverStatusId}
              status={status}
              wf_status_emit={wf_status_emit}
              updatePaid={this.updatePaid}
              discountRateCharges={discountRateCharges}
              discountRateChargesDescription={discountRateChargesDescription}
              discountRateChargesDate={discountRateChargesDate}
              discountRateChargesDueDate={discountRateChargesDueDate}
              discountRateChargesInvoiceNumber={discountRateChargesInvoiceNumber}
              discountRateChargesDiscountRate={discountRateChargesDiscountRate}
              discountRateChargesPercentage={discountRateChargesPercentage}
              discountRateChargesTotal={discountRateChargesTotal}
              currency={currency}
              approvalDate={approvalDate}
              commerceCode={commerceCode}
              descriptionCommerceCode={descriptionCommerceCode}
              goBack={history.goBack}
              paymentType={paymentType}
              NCLCommission={NCLCommission}
              paidDate={paidDate}
              senderStatus={senderStatus}
              acquiring_bank={acquiring_bank}
            />
          </div>
        </div>
        {paymentLog !== null && (
          <LogDetailForm
            t={t}
            log={paymentLog}
          />
        )}
      </div>
    );
  }
}

InvoiceDetail.propTypes = {
  clients: PropTypes.shape({}).isRequired,
  auth: PropTypes.shape({}).isRequired,
  role: PropTypes.shape({}).isRequired,
  documentType: PropTypes.shape({}).isRequired,
  fetchTransaction: PropTypes.func.isRequired,
  showMessage: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

const mapStateToProps = ({
                          transactions,
                          clients,
                          documentType,
                          auth,
                          role,
                        }) => ({
  transactions,
  clients,
  documentType,
  auth,
  role,
});
export default compose(
  connect(mapStateToProps, {
    ...transactionsActions,
    ...documentTypeActions,
    ...providersActions,
    ...paymentConditionsActions,
    ...roleActions,
    ...clientsActions,
    ...roleActions,
  }),
  withNamespaces(),
)(InvoiceDetail);
