const START_FETCH = 'support/START_FETCH';
const END_FETCH = 'support/END_FETCH';
const API_CALL = 'support/API_CALL';
const MESSAGE = 'support/MESSAGE';
const SAVE_FAQ = 'support/SAVE_FAQ';
const SUPPORT_ROLES = 'support/SUPPORT_ROLES';
const GET_FAQS = 'support/GET_FAQS';
const UPDATE_FAQ = 'support/UPDATE_FAQ';

export default {
    START_FETCH,
    END_FETCH,
    API_CALL,
    MESSAGE,
    SAVE_FAQ,
    SUPPORT_ROLES,
    GET_FAQS,
    UPDATE_FAQ,
};