import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { es } from 'date-fns/esm/locale';

registerLocale('es', es);

/**
 * Created by Feedback Software on 25/10/18.
 * @param name
 * @param placeholder
 * @param value
 * @param onChange function to handle the state in the father components
 * @param type
 * @param helpText
 * @param label if empty doesn't show
 * @param disabled boolean to disable input
 * @param fieldClasses array of classes for the field
 * @param inputClasses array of classes for the input
 * @param controlClasses array of classes for the control
 * @param icon font awesome class of icon to display
 * @param iconPosition can receive 'is-left' or 'is-right' to position as class
 * @param min minimal amount used when the input is number
 * @param step incremental amount
 * @returns {*}
 * @constructor
 */
const DateInput = ({
  name,
  placeholder,
                     value,
                     onChange,
                     helpText,
                     label,
                     disabled = false,
                     fieldClasses,
                     inputClasses,
                     controlClasses,
                     icon = null,
                     iconPosition = null,
                     dateFormat,
                     maxDate,
                     minDate_init,
                     band = false,
                     showMonthYearPicker,
                     showFullMonthYearPicker,
                     locale,
                   }) => (
  <div className={`field ${fieldClasses}`}>
    <div className="label">{label}</div>
    <div className={`control ${controlClasses}`} /*style={{ width: band ? '60%' : '' }}*/>
      <DatePicker
        customInput={(
          <CustomInput
            inputClasses={inputClasses}
            name={name}
            placeholder={placeholder}
            disabled={disabled}
          />
        )}
        dateFormat={dateFormat}
        selected={value}
        onChange={onChange}
        minDate={minDate_init}
        maxDate={maxDate}
        disabled={disabled}
        locale={locale}
        showMonthYearPicker={showMonthYearPicker}
        showFullMonthYearPicker={showFullMonthYearPicker}
      />
      {
        !!icon && (
          <span className={`icon ${iconPosition}`}>
            <i className={`${icon}`} />
          </span>
        )
      }
    </div>
    <p className="help">{helpText}</p>
  </div>
);

DateInput.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.instanceOf(Date).isRequired,
  onChange: PropTypes.func,
  helpText: PropTypes.string,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  fieldClasses: PropTypes.string,
  inputClasses: PropTypes.string,
  controlClasses: PropTypes.string,
  icon: PropTypes.string,
  iconPosition: PropTypes.string,
  dateFormat: PropTypes.string,
};

DateInput.defaultProps = {
  placeholder: null,
  onChange: () => {
  },
  helpText: null,
  label: null,
  disabled: false,
  fieldClasses: '',
  inputClasses: '',
  controlClasses: '',
  icon: null,
  iconPosition: null,
  dateFormat: 'dd/MM/yyyy',
  showMonthYearPicker: false,
  showFullMonthYearPicker: false,
};


const CustomInput = forwardRef(({
  onClick,
  inputClasses,
  name,
  placeholder,
  value,
  type,
  disabled,
  onChange,
  min,
  step,
}, ref) => (
  <input
    ref={ref}
    id={`${name}-input`}
    onClick={onClick}
    className={`input ${inputClasses}`}
    name={name}
    placeholder={placeholder}
    value={value}
    onChange={onChange}
    type={type}
    disabled={disabled}
    min={min}
    step={step}
  />
));

CustomInput.propTypes = {
  onClick: PropTypes.func,
  value: PropTypes.string,
  onChange: PropTypes.func,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  min: PropTypes.number,
  step: PropTypes.number,
  inputClasses: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
};

CustomInput.defaultProps = {
  value: '',
  type: '',
  disabled: false,
  min: 0,
  step: 1,
  inputClasses: '',
  name: '',
  placeholder: '',
  onClick: () => {},
  onChange: () => {},
};

export default DateInput;
