import { createAction } from 'redux-actions';
import types from './types';

const startFetch = createAction(types.START_FETCH);
const endFetch = createAction(types.END_FETCH);
const endError = createAction(types.END_ERROR);
const getGateway = createAction(types.GET_GATEWAY);
const getGatewayUser = createAction(types.GET_GATEWAY_USER);

const showMessage = createAction(types.MESSAGE,
  ({ message }) => ({
    message,
  }),
  ({ config }) => ({
    config,
  })
);

export default {
  startFetch,
  endFetch,
  endError,
  getGateway,
  showMessage,
  getGatewayUser,
};