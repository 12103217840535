import { createAction } from 'redux-actions';
import types from './types';

const startFetch = createAction(types.START_FETCH);
const endFetch = createAction(types.END_FETCH);
const endError = createAction(types.END_ERROR);
const saveProduct = createAction(types.SAVE_PRODUCT);
const getProduct = createAction(types.GET_PRODUCT);
const updateProduct = createAction(types.UPDATE_PRODUCT);
const disableProduct = createAction(types.DISABLE_PRODUCT);
const getContractedProduct = createAction(types.GET_CONTRACTED_PRODUCT);
const getProducts = createAction(types.GET_PRODUCTS);
const getContracted = createAction(types.GET_CONTRACTED);

const showMessage = createAction(types.MESSAGE,
  ({ message }) => ({
    message,
  }),
  ({ config }) => ({
    config,
  }));

export default {
  startFetch,
  endFetch,
  endError,
  showMessage,
  saveProduct,
  getProduct,
  updateProduct,
  disableProduct,
  getContractedProduct,
  getProducts,
  getContracted,
};