import { createAction } from 'redux-actions';
import types from './types';

const startFetch = createAction(types.START_FETCH);
const endFetch = createAction(types.END_FETCH);
const fetchStats = createAction(types.FETCH_STATS);
const updateStats = createAction(types.UPDATE_STATS);
const fetchRelatedUsers = createAction(types.FETCH_RELATED_USERS);
const updateRelatedUsers = createAction(types.UPDATE_RELATED_USERS);
const getRelatedClientAssist = createAction(types.GET_RELATED_CLIENT_ASSIST);
const updateRelatedAssist = createAction(types.UPDATE_RELATED_ASSIST);

const showMessage = createAction(types.MESSAGE,
  ({ message }) => ({
    message,
  }),
  ({ config }) => ({
    config,
  }));

export default {
  startFetch,
  endFetch,
  showMessage,
  fetchStats,
  updateStats,
  fetchRelatedUsers,
  updateRelatedUsers,
  getRelatedClientAssist,
  updateRelatedAssist,
};
