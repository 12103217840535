import types from './types';

const route = (state = {}, action) => {
  switch (action.type) {
    case types.START_FETCH: {
      return {
        ...state,
        loading: true,
      };
    }
    case types.END_FETCH: {
      const { error, payload } = action;
      return {
        ...state,
        loading: false,
        error: error ? payload : null,
      };
    }
    case types.UPDATE_ROUTE: {
      const { list } = action.payload;

      return {
        ...state,
        list,
      };
    }
    case types.UPDATE_ROUTE_ROLE: {
      const { list } = action.payload;

      return {
        ...state,
        roleRoute: {
          list,
        },
      };
    }
    case types.SAVE_ROUTE: {
      const { rout } = action.payload;

      return {
        ...state,
        list: [...state.list, rout],
      };
    }
    case types.REMOVE_ROUTE: {
      const { route_id } = action.payload;

      return {
        ...state,
        list: [...state.list.filter(val => val.id !== Number(route_id))],
      };
    }
    default:
      return state;
  }
};

export default route;
