import React, { Component, Fragment } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import PropTypes from 'prop-types';
import {
  Currency,
  Loading,
  Panel,
  PaymentConditionForm,
  TableSimple,
  Toggle
} from '../../components';
import { getPath, formatAmount, numberFilter, onInputOnlyNumbers } from '../../util';
import { statsActions } from '../../state/ducks/stats';
import { authActions } from '../../state/ducks/auth';
import './styles.scss';
import { paymentConditionsActions } from '../../state/ducks/paymentConditions';
import Modal from '../../components/Modal';
import { clientsActions } from '../../state/ducks/clients';
import { providersActions } from '../../state/ducks/providers';
import { TOAST_CONFIG } from '../../config/constants';
import { Link } from 'react-router-dom';
import { roleActions } from '../../state/ducks/role';
import DateInput from '../../components/DateInput';
class RelatedUsers extends Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  getInitialState = () => ({
    persons: '',
    client: null,
    provider: null,
    baseDays: 0,
    editable: false,
    startCountingFrom: 0,
    //limitAmount: 0,
    band: false,
    clientEdit: '',
    discountRate: 0,
    receiverDiscountShare: 0,
    clientPayment: 0,
    editPercentage: 0,
    hidden: true,
    codes: [],
    codeDescription: '',
    commerceCode: '',
    minDate: '',
    maxDate: '',
    nclCommission: '',
    listPayment: [],
    numberCommerceCode: ''
  });

  componentDidMount() {
    const {
      auth: {
        user: {
          profile,
          user_id,
          b2b: { type_user, roles },
        },
      },
      //fetchRelatedUsers,
      getRelatedClientAssist,
      getClients,
      getProviders,
      providerCommerceCode,
    } = this.props;
    if (type_user === 'buyer' || type_user === 'seller') {
      //fetchRelatedUsers({});
      this.setState({
        minDate: new Date(),
        maxDate: new Date(),
      })
      providerCommerceCode({
        params: {
          user: user_id,
          role: profile.type === 'client' ? profile.owner_role_id : roles[0].id
        },
        callback: (response) => {
          this.setState({ codes: response.commerce_codes });
        },
      });
    }
    if (type_user === 'admin_amex') {
      getClients({});
      getProviders({});
    }
    if (type_user === 'seller_assist_card') {
      getRelatedClientAssist({});
    }
  }

  resetState = () => {
    this.setState(this.getInitialState());
  };

  getGridSettings = (onToggle) => {
    let columns = null;
    let data = null;
    let title = null;
    const {
      t,
      history,
      stats: { assist, relatedUsers },
      auth: {
        user: {
          user_id, b2b: { type_user, user_type },
        },
      },
      getPaymentCondition,
      clients: { list: listClient },
      providers: { list: listProvider },
      router: { location: { pathname } },
      role: { list },
      providerCommerceCode,
    } = this.props;
    const {
      codes,
      listPayment,
    } = this.state;

    let role_id = '';
    for (let i = 0; i < list.length; i++) {
      if (list[i].name === 'seller') {
        role_id = list[i].id;
      }
    }
    let codes1 = [];

    switch (type_user) {
      case 'buyer':
      case 'seller':
        columns = [
          {
            expander: true,
            show: false,
          },
          {
            id: 'fiscalDocument', // Required because our accessor is not a string
            Header: t('documentNumber'),
            accessor: d => `${getPath(['idPerson', 'document_number'], d.user)} `,
          },
          {
            id: 'name', // Required because our accessor is not a string
            Header: t('businessName'),
            accessor: d => `${getPath(['idPerson', 'name'], d.user)}`,
          },
          /*{
            id: 'uniqueCode',
            Header: t('uniqueCode'),
            accessor: d => d.user.cod !== null ? `${d.user.cod}` : '',
          },*/
          /*{
            id: 'buyerCode',
            Header: t('buyerCode'),
            headerClassName: 'wordwrap',
            accessor: d => d.commerce_codes.sender !== null ? `${d.commerce_codes.sender}` : '',
          },*/
          /*
          {
            id: 'sellerCode',
            Header: t('sellerCode'),
            headerClassName: 'wordwrap',
            accessor: d => d.commerce_codes.receiver !== null ? `${d.commerce_codes.receiver}` : '',
          },
          {
            id: 'address', // Required because our accessor is not a string
            Header: t('address'),
            headerClassName: 'wordwrap',
            accessor: d => d.user.address_full !== null ? `${d.user.address_full}` : '',
          },
          {
            id: 'email', // Required because our accessor is not a string
            Header: t('email'),
            headerClassName: 'wordwrap',
            accessor: d => `${d.user.username}`,
          },
          */
          {
            id: 'currency',
            Header: t('currency'),
            className: 'text-center',
            accessor: d => user_type === 'NCL' ? 'MXN' :  d.condition.personCode !== null ? d.condition.personCode.currency_id : '',
          },
          {
            id: 'paidAmount', // Required because our accessor is not a string
            Header: t('paidAmount'),
            headerClassName: 'wordwrap',
            accessor: d => d.invoices.pag_value,
            Cell: cellInfo => (
              <Currency value={cellInfo.original.invoices.pag_value} />
            ),
            sortMethod: (a, b) => Number(a)-Number(b),
            filterMethod: (filter, row) => numberFilter(filter, row),
            Filter: ({ filter, onChange }) =>
              <input
                onInput={onInputOnlyNumbers}
                onChange={event => onChange(event.target.value)}
                style={{ width: '100%' }}
                value={filter ? filter.value : ''}
              />
          },
          {
            id: 'approvedMount', // Required because our accessor is not a string
            Header: t('approvedMount'),
            headerClassName: 'wordwrap',
            accessor: d => d.invoices.pej_value,
            Cell: cellInfo => (
              <Currency value={cellInfo.original.invoices.pej_value} />
            ),
            sortMethod: (a, b) => Number(a)-Number(b),
            filterMethod: (filter, row) => numberFilter(filter, row),
            Filter: ({ filter, onChange }) =>
              <input
                onInput={onInputOnlyNumbers}
                onChange={event => onChange(event.target.value)}
                style={{ width: '100%' }}
                value={filter ? filter.value : ''}
              />
          },
          {
            id: 'amountToApprove', // Required because our accessor is not a string
            Header: t('amountToApprove'),
            headerClassName: 'wordwrap',
            accessor: d => d.invoices.pap_value,
            Cell: cellInfo => (
              <Currency value={cellInfo.original.invoices.pap_value} />
            ),
            sortMethod: (a, b) => Number(a)-Number(b),
            filterMethod: (filter, row) => numberFilter(filter, row),
            Filter: ({ filter, onChange }) =>
              <input
                onInput={onInputOnlyNumbers}
                onChange={event => onChange(event.target.value)}
                style={{ width: '100%' }}
                value={filter ? filter.value : ''}
              />
          },
          {
            id: 'NCLCommission',
            Header: t('NCLCommission'),
            className: 'text-center',
            show: user_type === 'NCL',
            accessor: d => d.condition.ncl_commission ? d.condition.ncl_commission : '',
          },
          {
            id: 'paymentType',
            Header: t('paymentType'),
            className: 'text-center',
            show: user_type === 'NCL',
            accessor: d => d.user.user_type ? d.user.user_type : '',
          },
          {
            id: 'action', // Required because our accessor is not a string
            Header: t('conditions'),
            headerClassName: 'wordwrap',
            filterable: false,
            accessor: d => `${d.user.id} `,
            Cell: cellInfo => (
              <table style={{ display: 'flex', justifyContent: 'center' }}>
                <tbody>
                <tr>
                  <td>
                    <button
                      className="button is-primary is-inverted ml-8px"
                      to="/payment-conditions"
                      onClick={() => {
                        type_user === 'seller' ?
                          getPaymentCondition({
                            params: {
                              idCondition: cellInfo.original.condition.id,
                              person: cellInfo.original.user.id,
                              isClient: false,
                            },
                            callback: (response) => {
                              this.openModal(response, cellInfo.original.user.id, true, onToggle);
                            },
                          })
                          :
                          providerCommerceCode({
                            params: {
                              user: cellInfo.original.user.id,
                              role: role_id,
                            },
                            callback: (response) => {
                              //this.openModal(response, cellInfo.original.id, true, onToggle);
                              this.setState({
                                codes: response.commerce_codes,
                              });
                              getPaymentCondition({
                                params: {
                                  person: cellInfo.original.user.id,
                                  isClient: true,
                                  idCondition: cellInfo.original.condition.id,
                                },
                                callback: (response) => {
                                  this.openModal(response, cellInfo.original.user.id, true, onToggle);
                                },
                              });
                            },
                          });
                      }}
                    >
                      <span className="icon" title={t('detail')}>
                        <i className="far fa-eye" aria-hidden="true"/>
                      </span>
                    </button>
                  </td>
                  <td>
                    {type_user !== 'seller' && type_user !== 'buyer' &&
                    <button
                      className="button is-primary is-inverted"
                      onClick={() => {
                        // type_user === 'seller' ?
                        //   getPaymentCondition({
                        //     params: {
                        //       person: cellInfo.original.user.id,//cellInfo.original.id,
                        //       isClient: false,
                        //       idCondition: cellInfo.original.condition.id,
                        //     },
                        //     callback: (response) => {
                        //       history.push({
                        //         pathname: '/edit-payment-condition',
                        //         state: {
                        //           response: response,
                        //           id: cellInfo.original.user.id,
                        //           band: false,
                        //           codes,
                        //         }
                        //       });
                        //     },
                        //   })
                        //   :
                          providerCommerceCode({
                            params: {
                              user: cellInfo.original.user.id,
                              role: role_id,
                            },
                            callback: (response) => {
                              codes1 = response.commerce_codes;
                              //this.openModal(response, cellInfo.original.id, true, onToggle);
                              this.setState({
                                codes: response.commerce_codes,
                              });
                              getPaymentCondition({
                                params: {
                                  person: cellInfo.original.user.id,
                                  isClient: true,
                                  idCondition: cellInfo.original.condition.id,
                                },
                                callback: (response) => {
                                  history.push({
                                    pathname: '/edit-payment-condition',
                                    state: {
                                      codes: codes1,
                                      response: response,
                                      id: cellInfo.original.user.id,
                                      band: false,
                                    }
                                  });
                                  //this.openModal(response, cellInfo.original.user.id, false, onToggle);
                                },
                              });
                            },
                          });
                      }}
                    >
                      <span className="icon" title={t('requestEdition')}>
                        <i className="far fa-edit" aria-hidden="true"/>
                      </span>
                    </button>
                    }
                  </td>
                </tr>
                </tbody>
              </table>
            ),
          },
        ];

        data = listPayment && listPayment.hasOwnProperty('payment_conditions') ? listPayment.payment_conditions : [];
        //data = relatedUsers && relatedUsers.hasOwnProperty('payment_conditions') ? relatedUsers.payment_conditions : [];
        title = type_user === 'seller' ? t('customers') : t('providers');
        break;
      case'admin_amex':
        columns = [
          {
            expander: true,
            show: false,
          },
          {
            id: 'fiscalDocument', // Required because our accessor is not a string
            Header: t('documentNumber'),
            accessor: d => `${getPath(['idPerson', 'document_number'], d)} `,
          },
          {
            id: 'name', // Required because our accessor is not a string
            Header: t('businessName'),
            accessor: d => `${getPath(['idPerson', 'name'], d)}`,
          },
          {
            id: 'address', // Required because our accessor is not a string
            Header: t('address'),
            accessor: d => `${d.address_full}`,
          },
          {
            id: 'email', // Required because our accessor is not a string
            Header: t('email'),
            accessor: d => `${d.username}`,
          },
        ];
        data = pathname === '/clients' ? listClient : listProvider;
        title = pathname === '/clients' ? t('customers') : t('providers');
        break;
      case 'seller_assist_card':
        columns = [
          {
            expander: true,
            show: false,
          },
          {
            id: 'client', // Required because our accessor is not a string
            Header: t('client'),
            accessor: d => `${d.client.name}`,
          },
          {
            id: 'kindOfProduct', // Required because our accessor is not a string
            Header: t('kindOfProduct'),
            accessor: d => d.product !== null ? `${d.product.name}` : '',
          },
          {
            id: 'effective_date', // Required because our accessor is not a string
            Header: t('validity'),
            accessor: d => d.effective_date === null ? '' : `${d.effective_date}`,
          },
          {
            id: 'action', // Required because our accessor is not a string
            Header: t('action'),
            filterable: false,
            accessor: d => `${d.id} `,
            Cell: cellInfo => (
              <table>
                <tbody>
                <tr>
                  <td>
                    <Link
                      className="button is-primary is-inverted"
                      onClick={() => {
                        onToggle({
                          isEdit: true,
                          id: cellInfo.original.paymentCondition.id,
                          name_product: cellInfo.original.product.name,
                          client: cellInfo.original.client.name,
                        });
                      }}
                    >
                      {t('Unsubscribe')}
                    </Link>
                  </td>
                </tr>
                </tbody>
              </table>
            )
          },
        ];
        data = assist;
        title = t('customers');
        break;
      default:
        columns = [];
        data = [];
        title = '';
    }
    return {
      columns,
      data,
      title,
    };
  };

  openModal = (response, id, edit, onToggle) => {
    const {
      stats: { relatedUsers },
      auth: {
        user: { b2b: { type_user, user_type } },
      },
    } = this.props;
    const { codes } = this.state;

    let client = this.getUser(type_user, id);

    if (type_user === 'seller_assist_card') {
      //onToggle();
    } else if (type_user !== 'seller_assist_card') {

      if (response.person_commerce_code != null  && user_type === 'TDC') {
        this.setState({
          commerceCode: codes.findIndex(function (code) {
            return code.id == response.person_commerce_code;
          }),
          codeDescription: codes[codes.findIndex(function (code) {
            return code.id == response.person_commerce_code;
          })].description,
        });
      } else {
        this.setState({
          commerceCode: '',
          codeDescription: '',
        });
      }

      this.setState({
        id: id,
        clientEdit: relatedUsers.related_users[client].idPerson.name,
        //clientEdit: relatedUsers[client].user.idPerson.name,
        baseDays: response.days,
        //limitAmount: response.limit_amount !== null ? this.formatNumber(response.limit_amount) : 0,
        persons: relatedUsers.related_users,
        //persons: relatedUsers,
        startCountingFrom: response.due_from_approval,
        editable: true,
        band: edit,
        // receiverDiscountShare: (Number(response.receiver_discount_share) * 100).toFixed(2),
        receiverDiscountShare: response.receiver_discount_share,
        discountRate: Number(response.discount_rate)
          .toFixed(2),
        editPercentage: parseInt(response.percentage),
        // clientPayment: 100 - Number(response.receiver_discount_share * 100),
        clientPayment: response.percentage,
        nclCommission: user_type ? response.ncl_commission : '',
        numberCommerceCode: user_type === 'TDC' ? response.personCode.commerce_code : ''
      });
      onToggle();
    }

  };

  getUser = (type_user, id) => {
    const {
      stats: { relatedUsers }
    } = this.props;

    let client;
    if (type_user === 'seller') {
      // this.setState({
      client = relatedUsers.related_users.map(
        (listClientMap, index) => ({
          ...listClientMap,
          index,
        }),
      )
        .filter(listClientFil => listClientFil.id
          === id)[0].index;
      //clientEdit: ,
      //});
    } else if (type_user === 'buyer') {
      //this.setState({
      client = relatedUsers.related_users.map(
        (listClientMap, index) => ({
          ...listClientMap,
          index,
        }),
      )
        .filter(listClientFil => listClientFil.id
          === id)[0].index;
      //});
    }

    return client;
  };

  formatNumber = (n) => {
    return n.replace(/\D/g, '')
      .replace(/([0-9])([0-9]{2})$/, '$1.$2')
      .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ',');
  };

  handleSelectChange = (newVal, label) => {
    this.setState({
      [label.name]: newVal.value,
    });
  };

  handleInputChange = (event) => {
    const {
      target: {
        checked, value: targetValue, type, name,
      },
    } = event;
    const value = type === 'checkbox' ? checked : targetValue;
    const { clientPayment } = this.state;

    /*if (name === 'limitAmount') {
      let num = this.formatNumber(event.target.value);
      this.setState({
        [name]: (num),
      });
    } else*/
    if (name === 'receiverDiscountShare') {
      let percentage = 100 - value;
      this.setState({
        [name]: (value),
        clientPayment: percentage,
      });
    } else {
      this.setState({
        [name]: (value),
      });
    }
  };

  closeModal = (onToggle) => {
    onToggle();
  };

  saveRequest = (onToggle) => {
    const {
      auth: {
        user: {
          user_id, b2b: { type_user },
        },
      },
      t,
      showMessage,
      requestPaymentCondition,
    } = this.props;

    const {
      id,
      baseDays,
      startCountingFrom,
      //limitAmount,
      discountRate,
      receiverDiscountShare,
      clientPayment,
      codes,
      code,
      commerceCode,
    } = this.state;

    let percentage = 0;

    const conditionData = {
      bank_sender_id: null,
      sender_request_id: null,
      sender_id: type_user === 'seller' ? id : user_id,
      receiver_id: type_user === 'seller' ? user_id : id,
      days: baseDays,
      due_from_approval: startCountingFrom,
      //limit_amount: limitAmount.toString().replaceAll(',', ''),
      discount_rate: discountRate,
      percentage: clientPayment, //percentage,
      receiver_discount_share: receiverDiscountShare / 100,
      person_commerce_code: codes[commerceCode].id,
      type: 'base',
    };

    if (baseDays < 0) {
      showMessage({
        message: <strong style={{ color: '#ffffff' }}>{t('incorrectDays')}</strong>,
        config: TOAST_CONFIG.ERROR,
      });
      return;
    }

    /*if (conditionData.limit_amount < 0) {
      showMessage({
        message: <strong style={{ color: '#ffffff' }}>{t('limitAmountValidate')}</strong>,
        config: TOAST_CONFIG.ERROR,
      });
    } else {*/
      requestPaymentCondition({
        data: conditionData,
        callback: () => {
          onToggle();
          showMessage({
            message: t('editionRequestSent'),
            config: TOAST_CONFIG.SUCCESS,
          });
        },
      });
    //}
  };

  acceptTerm = (onToggle, resp, id) => {
    const {
      t,
      showMessage,
      deletePaymentCondition,
      getRelatedClientAssist,
    } = this.props;

    if (resp === 'yes') {
      deletePaymentCondition({
        data: {
          id: id,
        },
        t,
        callback: (resp) => {
          onToggle();
          getRelatedClientAssist({});
          if (resp.message) {
            showMessage({
              message: resp.message,
              config: TOAST_CONFIG.SUCCESS,
            });
          } else {
            showMessage({
              message: t('actionNotAllowed'),
              config: TOAST_CONFIG.ERROR,
            });
          }
        },
      });
    }
    if (resp === 'no') {
      onToggle();
    }
  };

  closeModal = (onToggle) => {
    this.getInitialState();
    onToggle();
  };

  getRelatedUsers() {
    const {
      getPaymentConditionRange
    } = this.props;

    const {
      minDate,
      maxDate,
    } = this.state;

    getPaymentConditionRange({
      params: {
        date_min: minDate / 1000,
        date_max: maxDate / 1000,
      }, callback: (response) => {
        this.setState({ listPayment: response });
      }
    });
  }

  handleDateChange = (dateName, newDate) => {

    if(newDate){
      if (dateName === 'minDate' && newDate !== '' && newDate !== null) {
        newDate.setHours(0, 0, 0, 0);
      }
      if (dateName === 'maxDate' && newDate !== '' && newDate !== null) {
        newDate.setHours(23, 59, 59, 999);
      }
    }
    this.setState({
      [dateName]: newDate ? newDate : '',
    });
  };

  render() {
    const {
      t,
      stats: { loading: statsLoading },
      auth: { loading: authLoading, user: { b2b: { type_user, user_type } } },
      router: { location: { pathname } },
      paymentConditions: { 
        loading: paymentConditionLoading,
        list: paymentConditionsList 
      },
      showMessage,
    } = this.props;

    const {
      client,
      provider,
      baseDays,
      editable,
      startCountingFrom,
      //limitAmount,
      persons,
      band,
      clientEdit,
      discountRate,
      receiverDiscountShare,
      clientPayment,
      editPercentage,
      hidden,
      codes,
      commerceCode,
      codeDescription,
      minDate,
      maxDate,
      nclCommission,
      numberCommerceCode
    } = this.state;
    const loading = statsLoading || authLoading || paymentConditionLoading;
    return (
      <Toggle>
        {({ toggled, onToggle, toggleProps }) => {
          const { title, columns, data } = this.getGridSettings(onToggle);
          return (
            <div>
              {loading && <Loading/>}
              <div className="dashboard">
                <div className="columns is-multiline">
                  <Fragment>
                    {
                      toggled && type_user === 'seller_assist_card' &&
                      (
                        <Modal onToggle={onToggle}> {loading && <Loading/>}
                          <Panel
                            headingText={t('confirmUnsubscribeFrom', { product: toggleProps.name_product })}>
                            <div className="field is-horizontal">
                              <div className="field-body columns">
                                <div className="column is-full" style={{ textAlign: 'center' }}>

                                  <label>{t('confirmUnsubscribe', {
                                    client: toggleProps.client,
                                    product: toggleProps.name_product
                                  })}</label>
                                </div>
                              </div>
                            </div>
                            <div className="is-fullwidth has-text-centered">
                              <button
                                type="button"
                                className="button is-primary"
                                onClick={this.acceptTerm.bind(this, onToggle, 'yes', toggleProps.id)}
                              >
                                {t('yes')}
                              </button>
                              &nbsp;&nbsp;
                              <button
                                type="button"
                                className="button is-primary"
                                onClick={() => this.acceptTerm(onToggle, 'no')}
                              >
                                {t('no')}
                              </button>
                            </div>
                          </Panel>
                        </Modal>
                      )}
                    {
                      toggled && type_user !== 'seller_assist_card'
                      && (
                        <Modal onToggle={() => onToggle()} title={t('commercialConditions')}>
                          <>
                            {loading && <Loading/>}
                            <PaymentConditionForm
                              t={t}
                              onSubmit={band === true ? () => this.closeModal(onToggle) : () => this.saveRequest(onToggle)}
                              handleInputChange={this.handleInputChange}
                              handlePercentageChange={this.handlePercentageChange}
                              isClient={type_user !== 'seller'}
                              isProvider={(type_user === 'buyer' || type_user === 'seller')}
                              isConditionClient={type_user === 'buyer'}
                              isConditionProvider={type_user === 'seller'}
                              handleSelectChange={this.handleSelectChange}
                              persons={persons}
                              client={client}
                              provider={provider}
                              baseDays={baseDays}
                              discountRate={discountRate}
                              // receiverDiscountShare={receiverDiscountShare}
                              // clientPayment={clientPayment}
                              receiverDiscountShare={receiverDiscountShare}
                              clientPayment={clientPayment}
                              startCountingFrom={startCountingFrom}
                              //limitAmount={limitAmount}
                              clientEdit={clientEdit}
                              editable={editable}
                              //hidden={true}
                              hidden={false}
                              title={band ? false : t('requestEdition')}
                              band={band}
                              editPercentage={editPercentage}
                              // hidden={hidden}
                              related={true}
                              closeModal={() => this.closeModal(onToggle)}
                              codes={codes}
                              commerceCode={commerceCode}
                              codeDescription={codeDescription}
                              typeUser={type_user}
                              usedInModals
                              user_type={user_type}
                              nclCommission={nclCommission}
                              toggleBTNStatus={paymentConditionsList.bpp}
                              pathname={pathname}
                              numberCommerceCode={numberCommerceCode}
                            />
                          </>
                        </Modal>
                      )
                    }
                    {data
                    && (
                      <div className="column is-full">
                        <Panel headingText={title}>
                          {
                            ['seller', 'buyer'].includes(type_user) && (
                              <div className="field is-horizontal">
                                <div className="field-body columns">
                                  <div className="columns is-vcentered" style={{ width: '100%', marginTop: '15px' }}>
                                    <div className="column is-4">
                                      <p style={{ marginTop: '20px' }}>
                                        { t('approvalConditionDateUserType', {
                                          userType: (t(type_user === 'seller' ? 'customer': 'provider')).toLowerCase()
                                        })}
                                      </p>
                                    </div>
                                    <div className="column is-one-quarter" style={{ zIndex: 99999 }}>
                                      <DateInput
                                        name="condition_from"
                                        label={t('since')}
                                        value={minDate || new Date()}
                                        onChange={(value) => this.handleDateChange('minDate', value)}
                                        type="text"
                                        fieldClasses="is-expanded"
                                        controlClasses="has-icons-left"
                                        icon="far fa-calendar-alt"
                                        iconPosition="is-left"
                                      />
                                    </div>
                                    <div className="column is-one-quarter" style={{ zIndex: 99999 }}>
                                      <DateInput
                                        name="condition_to"
                                        label={t('until')}
                                        value={maxDate || new Date()}
                                        onChange={(value) => this.handleDateChange('maxDate', value)}
                                        type="text"
                                        fieldClasses="is-expanded"
                                        controlClasses="has-icons-left"
                                        icon="far fa-calendar-alt"
                                        iconPosition="is-left"
                                      />
                                    </div>
                                    <div className="column" style={{ marginTop: '20px' }}>
                                      <button
                                        type="button"
                                        className="button is-primary"
                                        onClick={() => {
                                          minDate === '' && maxDate === '' ?
                                            showMessage({
                                              message: <strong style={{ color: '#ffffff' }}>{t('emptyFields')}</strong>,
                                              config: TOAST_CONFIG.ERROR,
                                            })
                                            : minDate > maxDate ?
                                            showMessage({
                                              message: <strong style={{ color: '#ffffff' }}>{t('errorDate')}</strong>,
                                              config: TOAST_CONFIG.ERROR,
                                            })
                                            : this.getRelatedUsers();
                                        }}
                                      >
                                        {t('search')}
                                      </button>
                                    </div>
                                  </div>
                                </div>
                                <br/>
                              </div>
                            )
                          }
                          <TableSimple data={data} columns={columns}/>
                        </Panel>
                      </div>
                    )}
                  </Fragment>
                </div>
              </div>
            </div>
          );
        }}
      </Toggle>
    );
  }
}

RelatedUsers.propTypes = {
  t: PropTypes.func.isRequired,
  fetchRelatedUsers: PropTypes.func.isRequired,
  getPaymentCondition: PropTypes.func.isRequired,
  stats: PropTypes.shape(Object).isRequired,
  auth: PropTypes.shape(Object).isRequired,
  router: PropTypes.shape(Object).isRequired,
};


const mapStateToProps = ({ auth, clients, providers, stats, paymentConditions, router, role, }) => (
  {
    auth,
    clients,
    providers,
    stats,
    paymentConditions,
    router,
    role,
  }
);
export default compose(
  connect(mapStateToProps, {
    ...statsActions, ...authActions, ...paymentConditionsActions, ...clientsActions,
    ...providersActions, ...roleActions,
  }),
  withNamespaces(),
)(RelatedUsers);