const START_FETCH = 'credentials/START_FETCH';
const END_FETCH = 'credentials/END_FETCH';
const END_ERROR = 'credentials/END_ERROR';
const API_CALL = 'credentials/API_CALL';
const SAVED_CREDENTIALS = 'credentials/SAVED_CREDENTIALS';
const MESSAGE = 'credentials/MESSAGE';
const GET_CREDENTIALS = 'credentials/GET_CREDENTIALS';
const SAVED_DATA = 'credentials/SAVED_DATA';
const UPDATE_CREDENTIALS = 'credentials/UPDATE_CREDENTIALS';

export default {
  START_FETCH,
  END_FETCH,
  END_ERROR,
  API_CALL,
  SAVED_CREDENTIALS,
  MESSAGE,
  GET_CREDENTIALS,
  SAVED_DATA,
  UPDATE_CREDENTIALS,
};