import React, { Component, Fragment } from 'react';
import { compose } from 'redux';
import './styles.scss';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import { CSVLink } from 'react-csv';
import { Currency, Loading, LoadInvoicesForm, Panel, TableSimple, Toggle, } from '../../components';
import { statsActions } from '../../state/ducks/stats';
import { transactionsActions } from '../../state/ducks/transactions';
import { clientsActions } from '../../state/ducks/clients';
import Modal from '../../components/Modal';
import { providersActions } from '../../state/ducks/providers';
import {
  formatAmount,
  formatDate,
  fileDateTime,
  numberFilter,
  onInputOnlyNumbers,
  formatCurrencyKeo,
  getTableFilteredValues,
} from '../../util';
import { API_URL, TOAST_CONFIG } from '../../config/constants';
import FormReject from './components/FormReject';
import FormApprove from './components/FormApprove';
import SelectSingle from '../../components/SelectSingle';
import XLSX from "xlsx";
import deepClone from 'lodash.clonedeep';
import { logActions } from '../../state/ducks/log';

const ItemCard = ({ name, value }) => (
  <li>
    <b>
      {`${name}:`}
    </b>
    {value}
  </li>
);

ItemCard.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

class Invoices extends Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
    this.selectTable = React.createRef();
    this.csvRef = React.createRef();
    this.xmlxRef = React.createRef();
  }

  getInitialState = () => {
    const { t, transactions: { currency } } = this.props;
    return ({
      days: 1,
      interest_rate: 0,
      discount_rate: 0,
      adjusted_amount: 0,
      type: '',
      approveDisabled: false,
      transaction_type: 'transfer',
      destination_phone_number: '',
      amount: '',
      invoice_date: new Date(),
      invoice_amount: null,
      invoice_number: null,
      invoice_description: '',
      client: null,
      product: currency,
      transfer_date: Date.now(),
      discount_amount: 0,
      detail: '',
      options: [
        {
          text: t('extractAccount'),
          value: 1,
        },
      ],
      rejectReason: '',
      isEdit: '',
      binaryFile: '',
      password: '',
      downloadFileData: [],
      request: -1,
      invoices: '',
      // filtered: [],
      totalRecords: 0,
    });
  };

  componentDidMount() {
    const {
      // listTransactionsSender,
      // listTransactionsReceiver,
      auth: { user: { user_id, b2b: { id, type_user } } },
      getClients,
      router: { location: { pathname } },
    } = this.props;
    getClients({
      params: {
        id,
        related: true,
      },
    });
    const userInfo = this.props.location.state !== undefined ? this.props.location.state.userInfo : 0;

    if(type_user === 'issuing_bank' || type_user === 'acquiring_bank'){
      // listTransactionsSender({
      //   params: {
      //     bank_id: pathname === '/recent-invoices' ? id : '',
      //     pathname: pathname,
      //     is_report: true,
      //   },
      // });
      this.getTransactions()
      this.setState({
        request: userInfo !== -1 ? userInfo : -1,
      });
    }

    if ( userInfo !== -1 ) {
      // if (type_user === 'buyer') {
      //   listTransactionsSender({
      //     params: {
      //       user_id,
      //       pathname: pathname,
      //     },
      //   });
      //   this.setState({
      //     request: userInfo,
      //   });
      // }
      // if (type_user === 'seller' || type_user === 'seller_assist_card') {
      //   listTransactionsReceiver({
      //     params: {
      //       user_id,
      //       pathname: pathname,
      //     },
      //   });
      //   this.setState({
      //     request: userInfo,
      //   });
      // }
      this.getTransactions()
      this.setState({
        request: userInfo,
      });
    }
  }

  getTitle = () => {
    const {
      t,
      router: { location: { pathname } },
    } = this.props;

    switch (pathname) {
      case '/review-invoices':
        return t('underReviewInvoices');
      case '/approved-invoices':
        return t('approvedInvoicesTitle');
      case '/paid-invoices':
        return t('paidInvoices');
      case '/reject-invoices':
        return t('rejectInvoiceAll');
      case '/pending-invoices':
        return t('invoicePending');
      case '/declined-invoices':
        return t('declinedInvoices');
      case '/process-invoices':
        return t('processInvoices');
      default:
        return t('allInvoices');
    }
  };

  matchTarget = (targets) => {
    const { auth: { user: { user_id, profile } } } = this.props;

    if (targets) {
      let permisions = false;

      for (let i = 0; i < targets.roles.length; i++) {
        permisions = permisions || targets.roles[i] === profile.role_id;
      }

      for (let i = 0; i < targets.users.length; i++) {
        permisions = permisions || targets.users[i] === profile.user_id;
      }

      return permisions;
    }

    return (user_id === profile.user_id) || (profile.type === 'client');
  };

  validateTitle = (status) => {
    if ( status === 'PEJ' || status === 'MAN' || status === 'APG' || status === 'ANG') {
      return true;
    }
    return false;
  };

  getGridSettings = (userType, onToggle) => {
    let columns = null;
    let data = null;
    let title = null;
    const {
      t, transactions: { sender, receiver },
      auth: { user },
      router: { location: { pathname } },
      // clients,
      // deleteTransaction,
      // listTransactionsReceiver,
      fetchTransaction,
      history,
      getLogPayment,
      showMessage,
    } = this.props;
    const {
      // approveDisabled,
      request,
    } = this.state;
    
    switch (userType) {
      case 'buyer':
        columns = [
          {
            expander: true,
            show: false,
          },
          {
            id: 'name', // Required because our accessor is not a string
            Header: t('provider'),
            accessor: d => d.parent_id !== null ? t('bankProvider') : `${d.receiver.idPerson.name}`,
          },
          {
            id: 'invoicesNumber', // Required because our accessor is not a string
            Header: t('invoiceNumber'),
            accessor: d => `${d.invoice_number}`,
          },
          {
            id: 'date', // Required because our accessor is not a string
            Header: t('sendDate'),
            accessor: d => formatDate(d.date),
          },
          {
            id: 'due_date',
            Header: t('scheduledDateTheProvider'),
            width: 120,
            accessor: d => d.payment_type === 'NCL' ? formatDate(d.due_date) :
              d.sender_status_id !== 'PAG' ?  formatDate(d.due_date) : formatDate(d.paid_date),
          },
          {
            id: 'paymentType',
            Header: t('paymentType'),
            className: 'text-center',
            accessor: d => d.payment_type ? d.payment_type : '',
          },
          {
            id: 'currency', // Required because our accessor is not a string
            headerClassName: 'wordwrap',
            className: 'text-center',
            Header: t('currency'),
            accessor: d => `${d.pay_currency_id}`,
          },
          {
            id: 'adjusted_amount', // Required because our accessor is not a string
            Header: t('invoiceAmount'),
            headerClassName: 'wordwrap',
            accessor: d => d.adjusted_amount,
            Cell: cellInfo => (
              <Currency value={cellInfo.original.adjusted_amount} />
            ),
            sortMethod: (a, b) => Number(a)-Number(b),
            // filterMethod: (filter, row) => numberFilter(filter, row),
            // Filter: ({ filter, onChange }) =>
            //   <input
            //     onInput={onInputOnlyNumbers}
            //     onChange={event => onChange(event.target.value)}
            //     style={{ width: '100%' }}
            //     value={filter ? filter.value : ''}
            //   />
          },
          {
            id: 'description', // Required because our accessor is not a string
            Header: t('description'),
            accessor: d => `${d.description}`,
          },
          {
            id: 'relationshipStatus',
            Header: t('relationshipStatus'),
            accessor: d => d.paymentCondition.status_keo ? t(d.paymentCondition.status_keo.status_keo.toLowerCase()) : '',
          },
          {
            id: 'transferState', // Required because our accessor is not a string
            Header: t('transferState'),
            width: 120,
            accessor: d => ((d.senderStatus.id === "APG") || (d.senderStatus.id === "ANG")) ?  t("approvedStatus") : `${d.senderStatus.description}`, //Cambio temporal palabra "Garantizado"
          },
          {
            id: 'rejectReason', // Required because our accessor is not a string
            Header: t('rejectReason'),
            headerClassName: 'wordwrap',
            show: this.getPath(request) === '/reject-invoices',
            accessor: d => d.log_message === null || d.log_message === undefined ? '' : `${d.log_message}`,
          },
          {
            id: 'executionDate', // Required because our accessor is not a string
            Header: t('executionDate'),
            headerClassName: 'wordwrap',
            show: this.getPath(request) === '/process-invoices',
            accessor: d => d.payment_log === null || d.payment_log === undefined ? '' : `${d.payment_log[0].date}`,
          },
          {
            id: 'event', // Required because our accessor is not a string
            Header: t('event'),
            headerClassName: 'wordwrap',
            show: this.getPath(request) === '/process-invoices',
            accessor: d => d.payment_event === null ? '' : `${d.payment_event}`,
          },
          {
            id: 'action', // Required because our accessor is not a string
            Header: t('action'),
            headerClassName: 'wordwrap',
            className: 'buttons-col', /* TableSimple.css */
            filterable: false,
            width: 150,
            Cell: cellInfo => (
              <table>
                <tbody>
                <tr>
                  {cellInfo.original.resource
                  && (
                    <td>
                      <a
                        href={`${API_URL}b2b/resource-file?nameFile=${cellInfo.original.resource}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="button"
                        download
                      >
                          <span className="icon" title={t('downloadPDF')}>
                            <a className="ml-">
                              <i className="fas fa-file-pdf"/>
                            </a>
                          </span>
                      </a>
                    </td>
                  )}
                  {cellInfo.original.resource_xml
                  && (
                    <td>
                      <a
                        href={cellInfo.original.resource_xml}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="button"
                        download
                      >
                        <span className="icon" title={t('downloadXML')}>
                          <a className="ml-">
                            <i className="fas fa-file-code"/>
                          </a>
                        </span>
                      </a>
                    </td>
                  )}
                  {cellInfo.original.sender_status_id === 'PAP' && this.matchTarget(cellInfo.original.targets)
                  && (
                    <td>
                      <button
                        className="button is-info is-small is-inverted"
                        title={t(cellInfo.original.paymentCondition.status_keo.status_keo === 'I' ? 'relationshipInactive' : 'approve')}
                        onClick={() => onToggle({
                          clientApprovement: true,
                          id: cellInfo.original.id,
                          invoiceNu: cellInfo.original.invoice_number,
                          invoiceAmount: cellInfo.original.base_amount,
                          currency:  cellInfo.original.pay_currency_id,
                        })}
                        disabled={cellInfo.original.paymentCondition.status_keo.status_keo === 'I'}
                      >
                        <span className="icon" title={t(cellInfo.original.paymentCondition.status_keo.status_keo === 'I' ? 'relationshipInactive' : 'approve')}>
                          <i className="fas fa-check"/>
                        </span>
                      </button>
                    </td>
                  )}
                  {cellInfo.original.sender_status_id === 'PAP' && this.matchTarget(cellInfo.original.targets)
                  && (
                    <td>
                      <button
                        className="button is-danger is-inverted"
                        onClick={() => {
                          this.setState({
                            isEdit: true,
                            binaryFile: '',
                            password: '',
                          }, () => {
                            onToggle({
                              isBuyer: true,
                              type: 'delete',
                              transaction: cellInfo.original.id,
                              onSubmit: this.rejectInvoice,
                            });
                          });
                        }
                        }
                      >
                          <span className="icon" title={t('delete')}>
                            <i className="fas fa-times"/>
                          </span>
                      </button>
                    </td>
                  )}
                  {cellInfo.original.sender_status_id === 'EPR' && (
                    <td>
                      <button
                        className='button is-info is-small is-inverted'
                        onClick={() => onToggle({
                          inProcess: true,
                          log: cellInfo.original.payment_log,
                        })}
                      >
                        <span className="icon" title={t('delete')}>
                          <i className="far fa-eye"/>
                        </span>
                      </button>
                    </td>
                  )}
                  {(cellInfo.original.sender_status_id === 'PAG' && cellInfo.original.payment_resource) &&
                    <td>
                      <a
                        href={`${API_URL}b2b/resource-file?nameFile=${cellInfo.original.payment_resource}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="button is-info is-small is-inverted"
                        download
                      >
                        <span className="icon" title={t('transferDetail2')}>
                          <i className="fa fa-file-invoice-dollar"/>
                        </span>
                      </a>
                    </td>
                  }
                  <td>
                    <button
                      className="button is-info is-small is-inverted"
                      onClick={() => {
                        fetchTransaction({
                          params: {
                            id: cellInfo.original.id,
                            role: 'sender',
                          },
                          callback: () => {
                            history.push({
                              pathname: '/invoice-detail',
                              state: {
                                userInfo: request
                              },
                            });
                          }
                        });
                      }}
                    >
                        <span className="icon" title={t('detail')}>
                          <i className="far fa-file-alt"/>
                        </span>
                    </button>
                  </td>
                </tr>
                </tbody>
              </table>
            ),
          },
        ];
        data = request === -1 ? [] : sender;
        title = this.getTitle();
        break;
      case 'seller':
      case 'seller_assist_card':
        columns = [
          {
            expander: true,
            show: false,
          },
          {
            id: 'name', // Required because our accessor is not a string
            Header: t('client'),
            accessor: d => `${d.sender.idPerson.name}`,
          },
          {
            id: 'invoicesNumber', // Required because our accessor is not a string
            Header: t('invoiceNumber'),
            accessor: d => `${d.invoice_number}`,
          },
          {
            id: 'date', // Required because our accessor is not a string
            Header: t('sendDate'),
            accessor: d => formatDate(d.date),
          },
          {
            id: 'pay_provider_date', // Required because our accessor is not a string
            Header: t('scheduledCollectionDate'),
            width: 120,
            accessor: d => d.payment_type === 'NCL' ? formatDate(d.pay_provider_date) :
              d.sender_status_id !== 'PAG' ?  formatDate(d.pay_provider_date) : formatDate(d.paid_date),
          },
          {
            id: 'paymentType',
            Header: t('paymentType'),
            className: 'text-center',
            accessor: d => d.payment_type ? d.payment_type : '',
          },
          {
            id: 'currency',
            Header: t('currency'),
            className: 'text-center',
            accessor: d => `${d.pay_currency_id}`,
          },
          {
            id: 'adjusted_amount', // Required because our accessor is not a string
            Header: t('invoiceAmount'),
            accessor: d => d.adjusted_amount,
            Cell: cellInfo => (
              <Currency value={cellInfo.original.adjusted_amount} />
            ),
            sortMethod: (a, b) => Number(a)-Number(b),
            // filterMethod: (filter, row) => numberFilter(filter, row),
            // Filter: ({ filter, onChange }) =>
            //   <input
            //     onInput={onInputOnlyNumbers}
            //     onChange={event => onChange(event.target.value)}
            //     style={{ width: '100%' }}
            //     value={filter ? filter.value : ''}
            //   />
          },
          {
            id: 'description', // Required because our accessor is not a string
            Header: t('description'),
            Cell: cellInfo => (
              <div>
                <span data-type="info"
                      data-tip={cellInfo.original.description}>{cellInfo.original.description}
                </span>
                <ReactTooltip/>
              </div>
            ),
          },
          {
            id: 'rejectReason', // Required because our accessor is not a string
            Header: t('rejectReason'),
            headerClassName: 'wordwrap',
            show: this.getPath(request) === '/reject-invoices',
            accessor: d => d.log_message === null || d.log_message === undefined ? '' : `${d.log_message}`,
          },
          {
            id: 'relationshipStatus', // Required because our accessor is not a string != null
            Header: t('relationshipStatus'),
            accessor: d => d.paymentCondition.status_keo ? t(d.paymentCondition.status_keo.status_keo.toLowerCase()) : '',
          },
          {
            id: 'transferState', // Required because our accessor is not a string
            Header: t('transferState'),
            Cell: cellInfo => (
              <div>
                {cellInfo.original.log_message !== null && cellInfo.original.receiver_status_id === 'PEJ' ? (
                  <span data-type="info"
                    data-tip={cellInfo.original.log_message}>{cellInfo.original.log_message}
                  </span>
                  )
                  :
                  (
                  <span>
                    {((cellInfo.original.senderStatus.id === "APG") || (cellInfo.original.senderStatus.id === "ANG")) ?  t("approvedStatus") : `${cellInfo.original.senderStatus.description}` /*Cambio temporal palabra "Garantizado"*/}
                  </span>
                )}
              </div>
            ),
          },
          {
            id: 'executionDate', // Required because our accessor is not a string
            Header: t('executionDate'),
            headerClassName: 'wordwrap',
            show: this.getPath(request) === '/process-invoices',
            accessor: d => d.payment_log === null || d.payment_log === undefined ? '' : `${d.payment_log[0].date}`,
          },
          {
            id: 'event', // Required because our accessor is not a string
            Header: t('event'),
            headerClassName: 'wordwrap',
            show: this.getPath(request) === '/process-invoices',
            accessor: d => d.payment_event === null ? '' : `${d.payment_event}`,
          },
          {
            id: 'action', // Required because our accessor is not a string
            Header: t('action'),
            headerClassName: 'wordwrap',
            filterable: false,
            className: 'buttons-col', /* TableSimple.css */
            width: 150,
            Cell: cellInfo => (
              <table>
                <tbody>
                <tr>
                  {cellInfo.original.receiver_status_id === 'PAP' && this.matchTarget(cellInfo.original.targets) && (
                    <td>
                      <button
                        className="button is-primary is-inverted"
                        onClick={() => onToggle({
                          isDeclined: true,
                          id: cellInfo.original.id,
                          invoiceNu: cellInfo.original.invoice_number,
                          invoiceAmount: cellInfo.original.base_amount,
                          currency:  cellInfo.original.pay_currency_id,
                        })}
                      >
                        <span className="icon" title={t('decline')}>
                          <i className="fas fa-ban"></i>
                        </span>
                      </button>
                    </td>
                  )}
                  {cellInfo.original.resource
                  && (
                    <td>
                      <a
                        href={`${API_URL}b2b/resource-file?nameFile=${cellInfo.original.resource}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="button"
                        download
                      >
                        <span className="icon" title={t('downloadPDF')}>
                          <a className="ml-">
                            <i className="fas fa-file-pdf"/>
                          </a>
                        </span>
                      </a>
                    </td>
                  )}
                  {cellInfo.original.resource_xml
                  && (
                    <td>
                      <a
                        href={cellInfo.original.resource_xml}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="button"
                        download
                      >
                        <span className="icon" title={t('downloadXML')}>
                          <a className="ml-">
                            <i className="fas fa-file-code"/>
                          </a>
                        </span>
                      </a>
                    </td>
                  )}
                  {cellInfo.original.receiver_status_id === 'PEM' && cellInfo.original.adjusted_amount > 0 && this.matchTarget(cellInfo.original.targets)
                  && (
                    <td>
                      <button
                        className="button is-primary is-inverted"
                        onClick={() => this.approveInvoice(cellInfo.original.id)}
                      >
                        <span className="icon" title={t('approve')}>
                          <i className="fas fa-check"/>
                        </span>
                      </button>
                    </td>
                  )}
                  {cellInfo.original.receiver_status_id === 'PEM' && this.matchTarget(cellInfo.original.targets)
                  && (
                    <td>
                      <button
                        className="button is-danger is-inverted"
                        onClick={() => {
                          this.rejectInvoice(cellInfo.original.id);
                        }
                        }
                      >
                        <span className="icon" title={t('delete')}>
                          <i className="fas fa-times"/>
                        </span>
                      </button>
                    </td>
                  )}
                  {cellInfo.original.receiver_status_id === 'REC'
                  && (
                    <td>
                      <button
                        className="button is-primary is-inverted"
                        onClick={() => {
                          this.setState({
                            rejectReason: cellInfo.original.rec_reason,
                            isEdit: false,
                          }, () => {
                            onToggle({
                              isBuyer: true,
                              type: 'delete',
                              transaction: cellInfo.original,
                            });
                          });
                        }
                        }
                      >
                          <span className="icon" title={t('rejectReason')}>
                            <i className="far fa-eye" aria-hidden="true"/>
                          </span>
                      </button>
                    </td>
                  )}
                  {(cellInfo.original.sender_status_id === 'PAG' && cellInfo.original.payment_resource) &&
                    <td>
                      <a
                        href={`${API_URL}b2b/resource-file?nameFile=${cellInfo.original.payment_resource}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="button is-info is-small is-inverted"
                        download
                      >
                        <span className="icon" title={t('transferDetail2')}>
                          <i className="fa fa-file-invoice-dollar"/>
                        </span>
                      </a>
                    </td>
                  }
                  <td>
                    <button
                      className="button is-primary is-inverted"
                      onClick={() => {
                        fetchTransaction({
                          params: {
                            id: cellInfo.original.id,
                            role: 'receiver',
                          },
                          callback: () => {
                            history.push({
                              pathname: '/invoice-detail',
                              state: {
                                userInfo: request
                              },
                            });
                          }
                        });
                      }}
                    >
                      <span className="icon" title={t('detail')}>
                        <i className="far fa-file-alt"/>
                      </span>
                    </button>
                  </td>
                </tr>
                </tbody>
              </table>
            ),
          }
        ];
        data = request === -1 ? [] : receiver;
        title = this.getTitle();
        break;
      case 'issuing_bank':
        columns = [
          {
            expander: true,
            show: false,
          },
          {
            id: 'client',
            Header: t('client'),
            accessor: d => d.sender.idPerson.name,
          },
          {
            id: 'provider',
            Header: t('provider'),
            accessor: d => d.receiver.idPerson.name,
          },
          {
            id: 'invoicesNumber', // Required because our accessor is not a string
            Header: t('invoiceNumber'),
            accessor: d => d.invoice_number,
          },
          {
            id: 'date', // Required because our accessor is not a string
            Header: t('sendDate'),
            //show: sender[0],
            accessor: d => formatDate(d.date),
          },
          {
            id: 'pay_provider_date', // Required because our accessor is not a string
            Header: t('scheduledDateTheProvider'),
            accessor: d => d.payment_type === 'NCL' ? formatDate(d.pay_provider_date) :
              d.sender_status_id !== 'PAG' ?  formatDate(d.pay_provider_date) : formatDate(d.paid_date),
          },
          {
            id: 'paymentType',
            Header: t('paymentType'),
            className: 'text-center',
            accessor: d => d.payment_type ? d.payment_type : '',
          },
          {
            id: 'currency',
            Header: t('currency'),
            className: 'text-center',
            accessor: d => `${d.pay_currency_id}`,
          },
          {
            id: 'adjusted_amount', // Required because our accessor is not a string
            Header: t('invoiceAmount'),
            accessor: d => d.adjusted_amount,
            Cell: cellInfo => (
              <Currency value={cellInfo.original.adjusted_amount} />
            ),
            sortMethod: (a, b) => Number(a)-Number(b),
            // filterMethod: (filter, row) => numberFilter(filter, row),
            // Filter: ({ filter, onChange }) =>
            //   <input
            //     onInput={onInputOnlyNumbers}
            //     onChange={event => onChange(event.target.value)}
            //     style={{ width: '100%' }}
            //     value={filter ? filter.value : ''}
            //   />
          },
          {
            id: 'description', // Required because our accessor is not a string
            Header: t('description'),
            Cell: cellInfo => (
              <div>
                <span data-type="info"
                      data-tip={cellInfo.original.description}>{cellInfo.original.description}
                </span>
                <ReactTooltip/>
              </div>
            ),
          },
          {
            id: 'transferState',
            Header: t('status'),
            accessor: d => ((d.senderStatus.id === "APG") || (d.senderStatus.id === "ANG")) ?  t("approvedStatus") : `${d.senderStatus.description}`, //Cambio temporal palabra "Garantizado"
          },
          {
            id: 'rejectReason', // Required because our accessor is not a string
            Header: t('rejectReason'),
            headerClassName: 'wordwrap',
            show: this.getPath(request) === '/reject-invoices',
            accessor: d => d.log_message === null || d.log_message === undefined ? '' : `${d.log_message}`,
          },
          {
            id: 'executionDate', // Required because our accessor is not a string
            Header: t('executionDate'),
            headerClassName: 'wordwrap',
            show: this.getPath(request) === '/process-invoices',
            accessor: d => d.payment_log === null || d.payment_log === undefined ? '' : `${d.payment_log[0].date}`,
          },
          {
            id: 'event', // Required because our accessor is not a string
            Header: t('event'),
            headerClassName: 'wordwrap',
            show: this.getPath(request) === '/process-invoices',
            accessor: d => d.payment_event === null ? '' : `${d.payment_event}`,
          },
          {
            id: 'action', // Required because our accessor is not a string
            Header: t('action'),
            headerClassName: 'wordwrap',
            className: 'buttons-col', /* TableSimple.css */
            filterable: false,
            width: 150,
            Cell: cellInfo => (
              <table>
                <tbody>
                <tr>
                  {cellInfo.original.resource
                  && (
                    <td>
                      <a
                        href={`${API_URL}b2b/resource-file?nameFile=${cellInfo.original.resource}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="button"
                        download
                      >
                          <span className="icon" title={t('downloadPDF')}>
                            <a className="ml-">
                              <i className="fas fa-file-pdf"/>
                            </a>
                          </span>
                      </a>
                    </td>
                  )}
                  {cellInfo.original.resource_xml
                  && (
                    <td>
                      <a
                        //href={`${API_URL}b2b/resource-file?nameFile=${cellInfo.original.resource_xml}`}
                        href={cellInfo.original.resource_xml}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="button"
                        download
                      >
                        <span className="icon" title={t('downloadXML')}>
                          <a className="ml-">
                            <i className="fas fa-file-code"/>
                          </a>
                        </span>
                      </a>
                    </td>
                  )}
                  {cellInfo.original.sender_status_id === 'PAG' && cellInfo.original.payment_type !== 'NCL' && (
                    <td>
                      <button
                        className='button is-info is-small is-inverted'
                        onClick={() => {
                          getLogPayment({
                            params: { id: cellInfo.original.id },
                            callback: res => {
                              if(res.data.success && res.data.data.length > 0) {
                                onToggle({
                                  inProcess: true,
                                  log: res.data.data,
                                });
                              } else {
                                showMessage({
                                  message: t('noLogRecord'),
                                  config: TOAST_CONFIG.WARNING,
                                });
                              }
                            },
                          });
                        }}
                      >
                        <span className="icon" title={t('view')}>
                          <i className="far fa-eye"/>
                        </span>
                      </button>
                    </td>
                  )}
                  {(cellInfo.original.sender_status_id === 'PAG' && cellInfo.original.payment_resource) &&
                    <td>
                      <a
                        href={`${API_URL}b2b/resource-file?nameFile=${cellInfo.original.payment_resource}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="button is-info is-small is-inverted"
                        download
                      >
                        <span className="icon" title={t('transferDetail2')}>
                          <i className="fa fa-file-invoice-dollar"/>
                        </span>
                      </a>
                    </td>
                  }
                  <td>
                    <button
                      className="button is-info is-small is-inverted"
                      onClick={() => {
                        fetchTransaction({
                          params: {
                            id: cellInfo.original.id,
                            role: 'sender',
                          },
                          callback: () => {
                            history.push({
                              pathname: '/invoice-detail',
                              state: {
                                userInfo: request
                              },
                            });
                          }
                        });
                      }}
                    >
                        <span className="icon" title={t('detail')}>
                          <i className="far fa-file-alt"/>
                        </span>
                      {/*<i className="far fa-file-alt mr-8px" aria-hidden="true"/>*/}
                    </button>
                  </td>
                </tr>
                </tbody>
              </table>
            ),
          },
        ];
        data = request === -1 ? [] : sender;
        title = this.getTitle();
        break;
      case 'acquiring_bank':
        columns = [
          {
            expander: true,
            show: false,
          },
          {
            id: 'provider',
            Header: t('provider'),
            accessor: d => d.receiver.idPerson.name,
          },
          {
            id: 'client',
            Header: t('client'),
            accessor: d => d.sender.idPerson.name,
          },
          {
            id: 'invoicesNumber', // Required because our accessor is not a string
            Header: t('invoiceNumber'),
            accessor: d => d.invoice_number,
          },
          {
            id: 'date', // Required because our accessor is not a string
            Header: t('sendDate'),
            accessor: d => formatDate(d.date),
          },
          {
            id: 'pay_provider_date', // Required because our accessor is not a string
            Header: t('scheduledCollectionDate'),
            accessor: d => d.payment_type === 'NCL' ? formatDate(d.pay_provider_date) :
              d.sender_status_id !== 'PAG' ?  formatDate(d.pay_provider_date) : formatDate(d.paid_date),
          },
          {
            id: 'paymentType',
            Header: t('paymentType'),
            className: 'text-center',
            accessor: d => d.payment_type ? d.payment_type : '',
          },
          {
            id: 'currency',
            Header: t('currency'),
            className: 'text-center',
            accessor: d => `${d.pay_currency_id}`,
          },
          {
            id: 'adjusted_amount', // Required because our accessor is not a string
            Header: t('invoiceAmount'),
            accessor: d => d.adjusted_amount,
            Cell: cellInfo => (
              <Currency value={cellInfo.original.adjusted_amount} />
            ),
            sortMethod: (a, b) => Number(a)-Number(b),
            // filterMethod: (filter, row) => numberFilter(filter, row),
            // Filter: ({ filter, onChange }) =>
            //   <input
            //     onInput={onInputOnlyNumbers}
            //     onChange={event => onChange(event.target.value)}
            //     style={{ width: '100%' }}
            //     value={filter ? filter.value : ''}
            //   />
          },
          {
            id: 'description', // Required because our accessor is not a string
            Header: t('description'),
            Cell: cellInfo => (
              <div>
                <span data-type="info"
                      data-tip={cellInfo.original.description}>{cellInfo.original.description}
                </span>
                <ReactTooltip/>
              </div>
            ),
          },
          {
            id: 'transferState',
            Header: t('status'),
            accessor: d => ((d.senderStatus.id === "APG") || (d.senderStatus.id === "ANG")) ?  t("approvedStatus") : `${d.senderStatus.description}`, //Cambio temporal palabra "Garantizado"
          },
          {
            id: 'rejectReason', // Required because our accessor is not a string
            Header: t('rejectReason'),
            headerClassName: 'wordwrap',
            show: this.getPath(request) === '/reject-invoices',
            accessor: d => d.log_message === null || d.log_message === undefined ? '' : `${d.log_message}`,
          },
          {
            id: 'executionDate', // Required because our accessor is not a string
            Header: t('executionDate'),
            headerClassName: 'wordwrap',
            show: this.getPath(request) === '/process-invoices',
            accessor: d => d.payment_log === null || d.payment_log === undefined ? '' : `${d.payment_log[0].date}`,
          },
          {
            id: 'event', // Required because our accessor is not a string
            Header: t('event'),
            headerClassName: 'wordwrap',
            show: this.getPath(request) === '/process-invoices',
            accessor: d => d.payment_event === null ? '' : `${d.payment_event}`,
          },
          {
            id: 'action', // Required because our accessor is not a string
            Header: t('action'),
            headerClassName: 'wordwrap',
            className: 'buttons-col', /* TableSimple.css */
            filterable: false,
            width: 150,
            Cell: cellInfo => (
              <table>
                <tbody>
                <tr>
                  {cellInfo.original.resource
                  && (
                    <td>
                      <a
                        href={`${API_URL}b2b/resource-file?nameFile=${cellInfo.original.resource}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="button"
                        download
                      >
                          <span className="icon" title={t('downloadPDF')}>
                            <a className="ml-">
                              <i className="fas fa-file-pdf"/>
                            </a>
                          </span>
                      </a>
                    </td>
                  )}
                  {cellInfo.original.resource_xml
                  && (
                    <td>
                      <a
                        //href={`${API_URL}b2b/resource-file?nameFile=${cellInfo.original.resource_xml}`}
                        href={cellInfo.original.resource_xml}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="button"
                        download
                      >
                        <span className="icon" title={t('downloadXML')}>
                          <a className="ml-">
                            <i className="fas fa-file-code"/>
                          </a>
                        </span>
                      </a>
                    </td>
                  )}
                  {cellInfo.original.sender_status_id === 'PAG' && cellInfo.original.payment_type !== 'NCL' && (
                    <td>
                      <button
                        className='button is-info is-small is-inverted'
                        onClick={() => {
                          getLogPayment({
                            params: { id: cellInfo.original.id },
                            callback: res => {
                              if(res.data.success && res.data.data.length > 0) {
                                onToggle({
                                  inProcess: true,
                                  log: res.data.data,
                                });
                              } else {
                                showMessage({
                                  message: t('noLogRecord'),
                                  config: TOAST_CONFIG.WARNING,
                                });
                              }
                            },
                          });
                        }}
                      >
                        <span className="icon" title={t('view')}>
                          <i className="far fa-eye"/>
                        </span>
                      </button>
                    </td>
                  )}
                  {(cellInfo.original.sender_status_id === 'PAG' && cellInfo.original.payment_resource) &&
                    <td>
                      <a
                        href={`${API_URL}b2b/resource-file?nameFile=${cellInfo.original.payment_resource}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="button is-info is-small is-inverted"
                        download
                      >
                        <span className="icon" title={t('transferDetail2')}>
                          <i className="fa fa-file-invoice-dollar"/>
                        </span>
                      </a>
                    </td>
                  }
                  <td>
                    <button
                      className="button is-info is-small is-inverted"
                      onClick={() => {
                        fetchTransaction({
                          params: {
                            id: cellInfo.original.id,
                            role: 'sender',
                          },
                          callback: () => {
                            history.push({
                              pathname: '/invoice-detail',
                              state: {
                                userInfo: request
                              },
                            });
                          }
                        });
                      }}
                    >
                        <span className="icon" title={t('detail')}>
                          <i className="far fa-file-alt"/>
                        </span>
                    </button>
                  </td>
                </tr>
                </tbody>
              </table>
            ),
          },
        ];
        data = request === -1 ? [] : sender;
        title = this.getTitle();
        break;
        default:
        columns = [];
        data = [];
        title = '';
    }
    return {
      columns,
      data,
      title,
    };
  };

  approveInvoice = (transaction_id, onToggle) => {
    const {
      approveInvoice,
      // listTransactionsSender,
      // listTransactionsReceiver,
      auth: { user: { user_id, profile, b2b: { type_user } } },
      showMessage,
      t,
    } = this.props;
    const {
      binaryFile,
      password,
      request,
    } = this.state;
    const data = {
      transaction_id,
      approved: true,
      user_id: profile.user_id,
      role_id: profile.role_id,
      provider: type_user === 'seller' ? true : false,
      file_sii: binaryFile,
      file_pass: password,
    };

    approveInvoice({
      data,
      callback: (resp) => {
        // type_user === 'buyer' ? listTransactionsSender({ params: { user_id, pathname: this.getPath(request), } })
        //   : listTransactionsReceiver({ params: { user_id, pathname: this.getPath(request), } });
        this.getTransactions()
        if (resp.process) {
          showMessage({
            message: t('invoiceApprovedSuccess'),
            config: TOAST_CONFIG.SUCCESS,
          });
        } else {
          showMessage({
            message: t('actionNotAllowed'),
            config: TOAST_CONFIG.ERROR,
          });
        }
        onToggle && onToggle();
      },
    });
    //onToggle();
  };

  declineInvoice = (transaction_id, onToggle) => {
    const {
      declineInvoice,
      // listTransactionsReceiver,
      auth: { user: { user_id } },
      showMessage,
      t,
    } = this.props;

    const data = {
      transaction_id,
    };

    declineInvoice({
      data,
      callback: (resp) => {
        // listTransactionsReceiver({ params: { user_id, pathname: this.getPath(0) } });
        this.getTransactions()
        if (resp.message) {
          showMessage({
            message: t('invoiceDeclinedSuccess'),
            config: TOAST_CONFIG.SUCCESS,
          });
        } else {
          showMessage({
            message: t('actionNotAllowed'),
            config: TOAST_CONFIG.ERROR,
          });
        }
        onToggle && onToggle();
      },
    });
  };

  rejectInvoice = (transaction, onToggle) => {
    const {
      approveInvoice,
      // listTransactionsSender,
      // listTransactionsReceiver,
      auth: { user: { user_id, profile, b2b: { type_user } } },
      showMessage,
      t,
    } = this.props;
    const {
      binaryFile,
      rejectReason,
      password,
      request,
    } = this.state;

    const data = {
      transaction_id: transaction,
      approved: false,
      user_id: profile.user_id,
      role_id: profile.role_id,
      note: rejectReason,
      provider: type_user === 'seller' ? true : false,
      file_sii: binaryFile,
      file_pass: password,
    };

    if (type_user === 'seller') {
      approveInvoice({
        data,
        callback: (resp) => {
          // listTransactionsReceiver({ params: { user_id, pathname: this.getPath(request), } });
          this.getTransactions()
          if (resp.process) {
            showMessage({
              message: t('invoiceRejectedSuccess'),
              config: TOAST_CONFIG.SUCCESS,
            });
          }
        },
      });
    } else if (rejectReason !== '') {
      approveInvoice({
        data,
        callback: (resp) => {
          // listTransactionsSender({ params: { user_id, pathname: this.getPath(request), } });
          this.getTransactions()
          if (resp.process) {
            showMessage({
              message: t('invoiceRejectedSuccess'),
              config: TOAST_CONFIG.SUCCESS,
            });
            this.setState({ rejectReason: '' });
          } else {
            showMessage({
              message: t('actionNotAllowed'),
              config: TOAST_CONFIG.ERROR,
            });
          }
          // onToggle();
        },
      });
      onToggle();
    } else {
      showMessage({
        message: <strong style={{ color: '#ffffff' }}>{t('emptyFields')}</strong>,
        config: TOAST_CONFIG.ERROR,
      });
    }
  };

  editInvoice = (transaction, onToggle) => {
    const {
      editTransaction,
      auth: {
        user: {
          user_id,
        },
      },
      clients,
      // listTransactionsReceiver,
    } = this.props;
    const {
      invoice_date,
      invoice_amount,
      invoice_number,
      invoice_description,
      client,
      binaryFile,
      request,
    } = this.state;
    const data = {
      id: transaction.id,
      date: invoice_date,
      invoice_number,
      amount: invoice_amount,
      buyer_id: clients.list[client].id,
      seller_id: user_id,
      description: invoice_description,
      file: binaryFile,
    };
    editTransaction({
      data,
      callback: () => {
        this.getTransactions()
        // listTransactionsReceiver({ params: { user_id, pathname: this.getPath(request), } });
        onToggle();
      },
    });
  };

  deferPayment = (transaction_id, condition_id, onToggle) => {
    const {
      deferInvoice,
      // listTransactionsSender,
      fetchStats,
      auth: {
        user: { user_id },
      },
    } = this.props;
    const { days } = this.state;
    const data = {
      transaction_id,
      condition_id,
      days,
    };
    deferInvoice({
      data,
      callback: () => {
        this.getTransactions()
        // listTransactionsSender({ params: { user_id } });
        fetchStats();
        onToggle();
      },
    });
  };

  updateRates = (data) => {
    const { days, type } = this.state;
    const {
      auth: {
        user: {
          b2b: {
            advance_condition,
            defer_condition,
          },
        },
      },
    } = this.props;
    let interest_rate = 0;
    let discount_amount = 0;
    const payment_condition = data.paymentCondition;
    if (type === 'advance') {
      interest_rate = 1 - (((Number(advance_condition.percentage)
        / advance_condition.days) * days) / 100);
      discount_amount = Number(Number(data.adjusted_amount)
        * (Number(payment_condition.discount_rate
          * payment_condition.receiver_discount_share) / 100)) * -1;
    } else {
      interest_rate = 1 + (((Number(defer_condition.percentage)
        / defer_condition.days) * days) / 100);
      discount_amount = Number(Number(data.adjusted_amount)
        * (Number(payment_condition.discount_rate
          * (1 - payment_condition.receiver_discount_share)) / 100));
    }
    this.setState({
      interest_rate: Number(Math.abs(((Number(data.adjusted_amount) + discount_amount)
        * (interest_rate - 1)) * -1))
        .toFixed(2),
      adjusted_amount: Number((Number(data.adjusted_amount) + discount_amount)
        * interest_rate)
        .toFixed(2),
      discount_amount: Math.abs(discount_amount)
        .toFixed(2),
    });
  };

  handleDaysChange = (event, data) => {
    const {
      target: {
        value: targetValue, name,
      },
    } = event;
    this.setState({
      [name]: targetValue,
    }, () => this.updateRates(data));
  };

  handleInputChange = (event) => {
    const {
      target: {
        checked, value: targetValue, type, name,
      },
    } = event;
    const value = type === 'checkbox' ? checked : targetValue;
    if (name === 'invoice_amount' && this.isNumber(value)) {
      this.setState({
        [name]: value,
      });
    } else if (name !== 'invoice_amount') {
      this.setState({
        [name]: value,
      });
    }
  };

  handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    this.setState({
      binaryFile: files[0],
      file: event.target.value,
    });
  };

  handleDateChange = (newDate) => {
    this.setState({
      invoice_date: newDate,
    });
  };

  handleClientChange = (newDate) => {
    this.setState({
      client: newDate.value,
    });
  };

  advancePayment = (transaction_id, condition_id, onToggle) => {
    const {
      advanceInvoice,
      // listTransactionsReceiver,
      fetchStats,
      auth: {
        user: {
          b2b: { id },
        },
      },
    } = this.props;
    const { days, request } = this.state;
    const data = {
      transaction_id,
      condition_id,
      days,
    };
    advanceInvoice({
      data,
      callback: () => {
        this.getTransactions()
        // listTransactionsReceiver({ params: { id, pathname: this.getPath(request), } });
        fetchStats();
        onToggle();
      },
    });
  };

  handleFileReject = (event) => {
    const {
      target: {
        checked, value: targetValue, type, name,
      },
    } = event;
    const {
      t,
      showMessage,
    } = this.props;
    const files = Array.from(event.target.files);
    const arrays = files[0].name;
    let ext = arrays.split('.');

    if (name === 'file') {
      if (ext[ext.length - 1] === 'pfx') {
        this.setState({
          binaryFile: files[0],
          file: event.target.value,
        });
      } else {
        showMessage({
          message: <strong style={{ color: '#ffffff' }}>{t('errorLoad', { type: 'pfx' })}</strong>,
          config: TOAST_CONFIG.ERROR,
        });
      }
    }
  };

  // handleSelectRequest = (newVal, label) => {
  //   const {
  //     listTransactionsSender,
  //     listTransactionsReceiver,
  //     auth: { user: { user_id, b2b: { id, type_user } } },
  //     getClients,
  //   } = this.props;

  //   getClients({
  //     params: {
  //       id,
  //       related: true,
  //     },
  //   });

  //   if (type_user === 'issuing_bank' || type_user === 'acquiring_bank') {
  //     listTransactionsSender({
  //       params: {
  //         pathname: this.getPath(newVal.value),
  //         is_report: true,
  //       },
  //     });
  //   }

  //   if (type_user === 'buyer') {
  //     listTransactionsSender({
  //       params: {
  //         user_id,
  //         pathname: this.getPath(newVal.value),
  //       },
  //     });
  //   }
  //   if (type_user === 'seller' || type_user === 'seller_assist_card') {
  //     listTransactionsReceiver({
  //       params: {
  //         user_id,
  //         pathname: this.getPath(newVal.value),
  //       },
  //     });
  //   }
  //   this.setState({
  //     [label.name]: newVal.value,
  //   });
  // }

  getPath = (code) =>{
    switch (code) {
      case 0:
        return '/recent-invoices';
      case 1:
        return '/review-invoices';
      case 2:
        return '/approved-invoices';
      case 3:
        return '/process-invoices';
      case 4:
        return '/paid-invoices';
      case 5:
        return '/reject-invoices';
      case 6:
        return '/declined-invoices';
      default:
        return -1;
    }
  }

  download = (e, fileFormat, fileName) => {

    e.preventDefault();

    const {
      auth: {
        user: {
          b2b: {
            type_user
          },
        },
      },
    } = this.props;

    // Quitamos la infomación que no queremos exportar
    function cleanData(data) {
      return data.filter(item => !item.hasOwnProperty("expander") && item.show !== false && item.id !== "action");
    }

    // Traemos el estado actual de la tabla
    const { sortedData, columns } = this.selectTable.current.getResolvedState();

    // Creamos una deep copy para evitra mutar el objeto original
    let cleanedSortedData = deepClone(sortedData);
    let cleanedColumns = deepClone(columns);

    // Limpiamos los datos
    cleanedSortedData = cleanData(cleanedSortedData);
    cleanedColumns = cleanData(cleanedColumns);

    // Cambiamos el formato de la fecha, la moneda y completamos los datos necesarios
    for (let i = 0; i < sortedData.length; i++) {
      type_user === "buyer"
        ? cleanedSortedData[i].due_date = formatDate(cleanedSortedData[i]._original.due_date)
        : cleanedSortedData[i].pay_provider_date = formatDate(cleanedSortedData[i]._original.pay_provider_date);
      cleanedSortedData[i].description = cleanedSortedData[i]._original.description;
      cleanedSortedData[i].transferState = cleanedSortedData[i]._original.senderStatus.description;
      cleanedSortedData[i].totalPay = "$" + formatCurrencyKeo(cleanedSortedData[i]._original.adjusted_amount);

      if ( ( type_user === 'buyer' || type_user === 'issuing_bank') && cleanedSortedData[i]._original.senderStatus.description === 'PAP' )
        cleanedSortedData[i].date = (cleanedSortedData[i].date);
    }

    // Este array almacena los datos que vamos a exportar
    let downloadFileData = [];

    // Pusheamos solo los headers de las columnas
    downloadFileData.push(cleanedColumns.map(column => column.Header));

    downloadFileData.push(...cleanedSortedData.map((sorted) => {
      const individualItems = {};
      cleanedColumns.forEach((column) => {
        individualItems[column.id] = sorted[column.id];
      });
      return Object.values(individualItems);
    }));

    if ( fileFormat === "csv" ) {
      this.setState({ downloadFileData }, () => {
        this.csvRef.current.link.click();
      });
    }

    if ( fileFormat === "xlsx" ) {
      const ws = XLSX.utils.json_to_sheet(downloadFileData, {skipHeader : true});
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, fileDateTime());
      XLSX.writeFile(wb, `${fileName} - ${fileDateTime()}.xlsx`);
    }

  };

  getTransactions = (pageSize = 10, page = 0, filtered = [], order = [], download, downloadFunc) => {
    const {
      listTransactionsSender,
      listTransactionsReceiver,
      auth: { user: { user_id, b2b: { id, type_user } } },
      router: { location: { pathname } },
    } = this.props;
    const filterParams = {
      page,
      pageSize,
      search: getTableFilteredValues(filtered),
      download,
      order: encodeURIComponent(JSON.stringify(order[0]))
    }

    if (type_user.includes('seller')) {
      listTransactionsReceiver({
        params: {
          user_id,
          pathname,
          ...filterParams
        },
        callback: res => {
          this.setState({ totalRecords: Number(res.countList) })
          download && downloadFunc()
        }
      })
    } 
    if (type_user === 'buyer') {
      listTransactionsSender({
        params: {
          user_id,
          pathname,
          ...filterParams
        },
        callback: res => {
          this.setState({ totalRecords: Number(res.countList) })
          download && downloadFunc()
        }
      })
    } 
    if (type_user.includes('bank')) {
      listTransactionsSender({
        params: {
          bank_id: pathname === '/recent-invoices' ? id : '',
          pathname,
          is_report: true,
          ...filterParams
        },
        callback: res => {
          this.setState({ totalRecords: Number(res.countList) })
          download && downloadFunc()
        }
      })
    } 
  }

  render() {
    const {
      t,
      transactions: { balance, charge, loading: transactionLoading },
      auth: { user: { b2b: { amexOwner, type_user, advance_condition, defer_condition, user_type } }, contacts },
      clients,
      clients: { loading: clientLoading },
      router: { location: { pathname } },
      log: { loading: logLoading }
    } = this.props;
    const {
      days,
      interest_rate,
      adjusted_amount,
      type,
      file,
      client,
      invoice_amount,
      discount_amount,
      invoice_date,
      invoice_description,
      invoice_number,
      binaryFile,
      amount,
      rejectReason,
      isEdit,
      password,
      downloadFileData,
      request,
    } = this.state;
    const charge_amount = parseFloat(amount) * (charge / 100);
    const loading = transactionLoading || clientLoading || logLoading;
    return (
      <Toggle>
        {({ toggled, onToggle, toggleProps }) => {
          const { title, columns, data } = this.getGridSettings(type_user, onToggle);
          return (
            <div className="dashboard">
              {loading && <Loading/>}
              <div className="columns is-multiline">
                <Fragment>
                  {
                    // eslint-disable-next-line no-nested-ternary
                    toggled && toggleProps.clientApprovement ? (
                        <Modal
                          onToggle={onToggle}
                          title={t("confirmInvoiceApprovement", {invoiceNu: toggleProps.invoiceNu, currency: toggleProps.currency, invoiceAmount: formatAmount(toggleProps.invoiceAmount) })}>
                            <>
                              {user_type === "NCL" &&
                                <h2 className='has-text-info m-24px' style={{ fontSize: '1.25em' }}>
                                  {t('nclInvoiceApprovementNotification')}
                                </h2>
                              }
                              <div className="modal-action-btns">
                                <button
                                  className="button custom-btn is-danger"
                                  onClick={() => onToggle()}
                                  >
                                  {t("return")}
                                </button>
                                <button
                                  className="button custom-btn is-primary"
                                  onClick={() => this.approveInvoice(toggleProps.id, onToggle)}
                                  >
                                  {t("approve")}
                                </button>
                              </div>
                            </>
                        </Modal>
                    )
                    : toggled && toggleProps.isDeclined ? (
                      <Modal
                        onToggle={onToggle}
                        title={
                          t('confirmInvoiceDecline', {
                            invoiceNu: toggleProps.invoiceNu,
                            invoiceAmount: toggleProps.invoiceAmount,
                            currency: toggleProps.currency,
                          })
                        }
                      >
                        <div className="modal-action-btns">
                          <button
                            className="button custom-btn is-danger"
                            onClick={() => onToggle()}
                          >
                            {t('return')}
                          </button>
                          <button
                            className="button custom-btn is-primary"
                            onClick={() => this.declineInvoice(toggleProps.id, onToggle)}
                          >
                            {t('decline')}
                          </button>
                        </div>
                      </Modal>
                    )
                    : toggled && toggleProps.isApprove ? (
                        <Modal onToggle={onToggle}>
                          <FormApprove
                            t={t}
                            onToggle={onToggle}
                            toggle={toggled}
                            toggleProps={toggleProps}
                            handleSelectChange={this.handleSelectChange}
                          />
                        </Modal>
                      )
                      : toggled && toggleProps.isBuyer ? (
                        <Modal onToggle={onToggle} title={t('rejectReason')}> {loading && <Loading/>}
                          <FormReject
                            t={t}
                            onToggle={onToggle}
                            toggle={toggled}
                            toggleProps={toggleProps}
                            onSubmit={toggleProps.type === 'delete' ? () => this.rejectInvoice(toggleProps.transaction, onToggle)
                              : () => this.approveInvoice(toggleProps.transaction, onToggle)}
                            handleInputChange={this.handleInputChange}
                            data={{ rejectReason }}
                            isEdit={isEdit}
                            handleFileChange={this.handleFileReject}
                            binaryFile={binaryFile}
                            type={toggleProps.type}
                            type_user={type_user}
                            password={password}
                          />
                        </Modal>
                      )
                      : toggled && toggleProps.inProcess ? (
                        <Modal onToggle={onToggle} title={t('executionHistory')}> {loading && <Loading/>}
                          <div style={{minWidth: '30vw', margin: '1rem'}}>
                          {toggleProps.log.map((val, index) => (
                            <div>
                              <ul>
                                <li>{val.date}</li>
                                <li>{val.status}</li>
                                <li>{val.description}</li>
                              </ul>
                            </div>
                          ))}
                          </div>
                        </Modal>
                      )
                      : toggled && (
                        <Modal onToggle={onToggle}>
                          <LoadInvoicesForm
                            formData={{ ...this.state }}
                            onToggle={onToggle}
                            toggle={toggled}
                            toggleProps={toggleProps}
                            balance={balance}
                            handleInputChange={this.handleInputChange}
                            handleFileChange={this.handleFileChange}
                            handleDateChange={this.handleDateChange}
                            handleClientChange={this.handleClientChange}
                            contacts={contacts}
                            chargeAmount={charge_amount}
                            invoiceAmount={invoice_amount}
                            invoiceDate={invoice_date}
                            invoiceNumber={invoice_number}
                            description={invoice_description}
                            clients={clients.list}
                            selectedClient={client}
                            onSubmit={() => this.editInvoice(toggleProps.transaction, onToggle)}
                            file={file}
                            fileName={binaryFile && binaryFile.name}
                          />
                        </Modal>
                      )
                  }
                  {data
                  && (
                    <div className="column is-full" style={{ position: 'relative' }}>
                      <div className='download-buttons'>
                        <button
                          className="button custom-btn is-link is-outlined"
                          onClick={(e) => {
                            this.getTransactions(10, 0, [], [], true, () => this.download(e, "csv"))
                          }}
                        >
                          <i className="fa fa-file-csv mr-8px" aria-hidden="true"/>
                          <span>{`${t('download')} CSV`}</span>
                        </button>
                        <button
                          className="button custom-btn is-link is-outlined"
                          onClick={(e) => {
                            this.getTransactions(10, 0, [], [], true, () => this.download(e, "xlsx", title))
                          }}
                        >
                          <i className="fa fa-file-excel mr-8px" aria-hidden="true"/>
                          <span>{`${t('download')} XLSX`}</span>
                        </button>
                        <CSVLink
                          data={downloadFileData}
                          filename={`${title} - ${fileDateTime()}.csv`}
                          className="hidden"
                          ref={this.csvRef}
                          separator={";"}
                          charSet="UTF-8"
                        />
                      </div>
                      <Panel headingText={title}>
                        {/*<Panel>
                          <div className="columns field-body">
                            <div className="column">
                              <SelectSingle
                                name="request"
                                label={t('invoices')}
                                placeholder={t('SelectOption')}
                                onChange={this.handleSelectRequest}
                                options={
                                  [{
                                    label: t('recentInvoices'),
                                    value: 0,// '/recent-invoices',
                                  }, {
                                    label: t('underReviewInvoices'),
                                    value: 1,// '/review-invoices',
                                  }, {
                                    label: t('approvedInvoicesTitle'),
                                    value: 2,//'/approved-invoices',
                                  }, {
                                    label: t('processInvoices'),
                                    value: 3,//'/paid-invoices',
                                  }, {
                                    label: t('paidInvoices'),
                                    value: 4,//'/reject-invoices',
                                  }, {
                                    label: t('rejectInvoiceAll'),

                                    value: 5,//'/declined-invoices',
                                  }, {
                                    label: t('declinedInvoices'),
                                    value: 6,//'/process-invoices',
                                  }
                                  ]}
                                selectClasses="is-fullwidth"
                                controlClasses="has-icons-left"
                                icon="far fa-user"
                                iconPosition="is-left"
                                value={request}
                                isClearable={false}
                              />
                            </div>
                          </div>
                        </Panel>*/}
                        <TableSimple 
                          data={data} 
                          columns={columns} 
                          reff={this.selectTable}
                          onFetchData={this.getTransactions}
                          hasHeavyData
                          // filtered={this.state.filtered}
                          // onFilteredChange={filtered => this.setState({ filtered })}
                          totalRecords={this.state.totalRecords}
                        />
                      </Panel>
                    </div>
                  )}
                </Fragment>
              </div>
            </div>
          );
        }}
      </Toggle>

    );
  }
}

Invoices.propTypes = {
  auth: PropTypes.shape(Object).isRequired,
  transactions: PropTypes.shape(Object).isRequired,
  clients: PropTypes.shape(Object).isRequired,
  t: PropTypes.func.isRequired,
  fetchStats: PropTypes.func.isRequired,
  listTransactionsSender: PropTypes.func.isRequired,
  listTransactionsReceiver: PropTypes.func.isRequired,
  getClients: PropTypes.func.isRequired,
  approveInvoice: PropTypes.func.isRequired,
  declineInvoice: PropTypes.func.isRequired,
  editTransaction: PropTypes.func.isRequired,
  deferInvoice: PropTypes.func.isRequired,
  advanceInvoice: PropTypes.func.isRequired,
  router: PropTypes.shape(Object).isRequired,
  deleteTransaction: PropTypes.func.isRequired,
  history: PropTypes.shape(Object).isRequired,
};

const mapStateToProps = ({
                          transactions,
                          auth,
                          router,
                          clients,
                          log,
                        }) => ({
  transactions,
  auth,
  router,
  clients,
  log
});

export default compose(
  connect(mapStateToProps, {
    ...statsActions, ...transactionsActions, ...clientsActions, ...providersActions, ...logActions
  }),
  withNamespaces(),
)(Invoices);
