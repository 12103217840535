import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withNamespaces } from 'react-i18next';
import { ToastContainer } from 'react-toastify';
import { authActions } from '../../../state/ducks/auth';
import { TOAST_CONFIG } from '../../../config/constants';
import './styles.scss';
// COMPONENTS
import { Loading } from '../../../components';
import FormRecover from './components/FormRecover';
import { Footer } from '../../layout/components';
// RESOURCES
import KeoLogo from '../../../resources/components/KeoLogo';
import WorkeoLogo from '../../../resources/components/WorkeoLogo';
import people from '../../../resources/images/assets/people.png';
import comunidadB2B from '../../../resources/images/assets/comunidad-b2b.png';
import logoAmex from '../../../resources/images/assets/amex.png';

class RecoverPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      language: '',
      password: '',
      confirmPassword: '',
    };
  }

  componentDidMount = () => {
    const { auth: { language, } } = this.props;
    this.setState({ language });

    //window.location.hash="no-back-button";
  };

  handleInputChange = (event) => {
    const {
      target: {
        type, checked, value: targetValue, name,
      },
    } = event;
    const value = type === 'checkbox' ? checked : targetValue;
    this.setState({
      [name]: value,
    });

  };

  handleSelectChange = (newVal) => {
    const { i18n } = this.props;
    this.setState({
      language: newVal.target.value,
    });
    i18n.changeLanguage(newVal.target.value);
    // eslint-disable-next-line no-undef
    window.location.reload();
  };

  handleSubmit = () => {
    const {
      t,
      passwordResetToken,
      passwordResetLogin,
      showMessage,
      history,
      auth: { user },
      updateB2B,
      saveB2B,
      dispatch,
    } = this.props;
    const {
      password,
      confirmPassword
    } = this.state;
    const url = window.location.href;

    // eslint-disable-next-line prefer-const
    let regex = /[?&]([^=#]+)=([^&#]*)/g,
      params = {},
      match;
    // eslint-disable-next-line no-cond-assign
    while (match = regex.exec(url)) {
      params[match[1]] = match[2];
    }

    if(user.b2b.password_changed === 0){
      passwordResetLogin({
        email: user.email,
        password,
        callback: (response) => {
          updateB2B({
            params: {
              user_id: /*user.b2b.roles.owner_id === null ? user.user_id :*/ user.profile.user_id,
            },
          });
          history.push('/login');
          showMessage({
            message: response.data.data.message,
            config: TOAST_CONFIG.SUCCESS,
          });
        }
      });
    }else{
      if (password !== '' && confirmPassword !== '') {
        if (password === confirmPassword) {
          params.password = password;
          passwordResetToken({
            params,
            callback: (response) => {
              if(response.success == true){
                history.push('/dashboard');
                showMessage({
                  message: response.data.message,
                  config: TOAST_CONFIG.SUCCESS,
                });
              } else if (response.data.redirect_login && response.data.redirect_login === true) {
                history.push('/login');
              } else {
                this.setState({...this.state, password: '', confirmPassword: ''})
              }
            }
          });
        } else {
          showMessage({
            message: <strong style={{ color: '#ffffff' }}>{t('nonMatchingPasswords')}</strong>,
            config: TOAST_CONFIG.ERROR,
          });
        }
      } else if (password !== '' || confirmPassword !== '') {
        showMessage({
          message: <strong style={{ color: '#ffffff' }}>{t('emptyFields')}</strong>,
          config: TOAST_CONFIG.ERROR,
        });
      }
    }
  };

  render() {
    const {
      auth: { loading, user: { b2b: { is_password_expire } } },
      t,
    } = this.props;
    const { language, password, confirmPassword } = this.state;
    /*if (logged === true) {
      return <Redirect to="/dashboard" />;
    }*/
    return (
      <div className="SignIn">
        <ToastContainer />
        {loading && <Loading />}

        {/* HERO */}
        <section className="sign-in-hero"> 
            {/* <div className='keo'>
              <KeoLogo width='96' height='96' />
            </div>
            <div className='hero-title'>
              <h2>Compra ahora,</h2>
              <h2>paga después.</h2>
              <img src={comunidadB2B} className={comunidadB2B} alt='comunidad-b2b'/>
            </div>
            <div className="hero-img">
              <img src={people} className="people" alt="people" />
            </div> */}
        </section>

        {/* FORM MAIN CONTAINER */}
        <section className="sign-in-form">
          {/* LANGUAGES SELECT */}
          <div className="sign-in-language">
            <div className="field">
                <div className="control has-icons-left">
                  <div className="select is-small">
                    <select 
                      name="language" 
                      value={language}
                      onChange={this.handleSelectChange}
                    >
                      <option value="es">{t('es')}</option>
                      <option value="pt">{t('pt')}</option>
                      <option value="en">{t('en')}</option>
                    </select>
                  </div>
                  <div className="icon is-small is-left">
                    <i className="fas fa-globe"/>
                  </div>
                </div>
            </div>
          </div>
          {/* WORKEO LOGO */}
          <div className='workeo-recover'>
            <img src={logoAmex} alt="logo" style={{
              height: '10em',
              width: '10em'
            }}/>
          </div>
          {/* FORM */}
          <div className="login-form">
            <div className='recover-password-form'>
              <FormRecover
                handleSubmit={this.handleSubmit}
                handleInputChange={this.handleInputChange}
                data={{
                  password,
                  confirmPassword
                }}
                isPasswordExpire={is_password_expire}
              />
            </div>
          </div>
        </section>
        <Footer usedInLogin/>
      </div>
    );
  }
}

RecoverPassword.propTypes = {
  passwordResetToken: PropTypes.func.isRequired,
  auth: PropTypes.shape(Object).isRequired,
  i18n: PropTypes.shape(Object).isRequired,
  t: PropTypes.func.isRequired,
  showMessage: PropTypes.func.isRequired,
};

const mapStateToProps = ({ auth }) => (
  {
    auth,
  }
);

export default compose(
  connect(mapStateToProps, {
    ...authActions,
  }),
  withNamespaces(),
)(RecoverPassword);
