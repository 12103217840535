import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

const UserBadge = ({userName, role, id}) => {
    return (
        <div className="UserBadge__main">
          <i className="fas fa-user-circle" />
          <div className="UserBadge__name">
            <p>{userName}</p>
            <p>({role})</p>
          </div>
        </div>
    );
};

UserBadge.propTypes = {
    userName: PropTypes.string.isRequired,
    role: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
};

export default UserBadge;