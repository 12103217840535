import React, { memo } from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

const Currency = ({ value, symbol, location }) => {
  const format = (amount) => Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  }).format(amount)
  return (
    <div className="has-text-right">
      <span className={`mx-1 ${location === 'RIGHT' ? 'is-pulled-right' : ''}`}> { symbol }</span>
      { format(value) }
    </div>
  )
}

Currency.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  symbol: PropTypes.string,
  location: PropTypes.oneOf(['LEFT', 'RIGHT']),
};

Currency.defaultProps = {
  symbol: '$',
  location: 'LEFT'
}

export default memo(Currency)