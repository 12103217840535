import types from './types';
import { authTypes } from '../auth';
import { initialState } from '../../../App';

const transactions = (state = {}, action) => {
  switch (action.type) {
    case authTypes.CLEAR: {
      return {
        ...state, ...initialState.transactions,
      };
    }
    case types.START_FETCH: {
      return {
        ...state,
        loading: true,
      };
    }
    case types.END_FETCH: {
      const { error, payload } = action;
      return {
        ...state,
        loading: false,
        error: error ? payload : null,
      };
    }
    case types.UPDATE_TRANSACTIONS: {
      const { payload: { transactions, total } } = action;
      return {
        ...state,
        total: total,
        list: transactions,
      };
    }
    case types.UPDATE_CHARGE: {
      const { payload: { charge } } = action;
      return {
        ...state,
        charge,
      };
    }
    case types.UPDATE_BALANCE: {
      const { payload: { currency, balance } } = action;
      return {
        ...state,
        currency,
        balance,
      };
    }
    case types.SAVE_PROVIDERS: {
      const { payload } = action;
      return {
        ...state,
        list: payload.sort((a, b) => {
          if (a.idPerson.name.toLowerCase() > b.idPerson.name.toLowerCase()) {
            return 1;
          }
          if (b.idPerson.name.toLowerCase() > a.idPerson.name.toLowerCase()) {
            return -1;
          }
          return 0;
        }),
      };
    }
    case types.SET_PROVIDER: {
      const { payload } = action;
      return {
        ...state,
        list: payload,
      };
    }
    case types.GET_TYPE: {
      const { payload } = action;
      return {
        ...state,
        type: payload,
      };
    }
    case types.SET_PROVIDERS_CONDITIONS: {
      const { payload } = action;
      return {
        ...state,
        receiver: payload,
      };
    }
    case types.GET_COMMERCE_CODE: {
      const { payload } = action;
      return {
        ...state,
        receiverCommerce: payload,
      };
    }
    default:
      return state;
  }
};

export default transactions;
