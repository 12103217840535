import { userProfileActions, userProfileTypes } from '../ducks/userProfile';

const profileData = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== userProfileTypes.FETCH_USER_PROFILE) {
    return;
  }

  const {
    params: {
      owner_id = '', pageSize = 0,
    } = {},
    callback = () => {
    },
  } = action.payload;

  dispatch({
    type: userProfileTypes.API_CALL,
    payload: {
      config: {
        method: 'GET',
        url: `/b2b/user-profiles?id=${owner_id}&pageSize=${pageSize}`,
      },
      authorization: true,
      onStart: userProfileActions.startFetch,
      onComplete: ({ data: responseData }) => {
        const { success, data } = responseData;
        if (success) {
          dispatch(userProfileActions.updateUserProfile({
            list: data,
          }));
          callback(data);
        }
      },
      onEnd: userProfileActions.endFetch,
    },
  });
};

const editUserProfile = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== userProfileTypes.EDIT_USER_PROFILE) {
    return;
  }

  const {
    data,
    callback = () => {
    },
  } = action.payload;

  dispatch({
    type: userProfileTypes.API_CALL,
    payload: {
      config: {
        method: 'POST',
        url: `/api/update-core-person`,
        data,
      },
      authorization: true,
      onStart: userProfileActions.startFetch,
      onComplete: ({ data: { data, success } }) => {
        if (success) {
          callback(data);
          /* dispatch(roleActions.showMessage({
            message: t('deleteRol'),
            config: TOAST_CONFIG.SUCCESS,
          })); */
        }
      },
      onEnd: userProfileActions.endFetch,
    },
  });
};

export default [profileData, editUserProfile];
