import React, { Component, Fragment } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import PropTypes from 'prop-types';
import Modal from '../../components/Modal';
import { Loading, Panel, TableSimple, Toggle, } from '../../components';
import './styles.scss';
import SelectSingle from '../../components/SelectSingle';
import { clientsActions } from '../../state/ducks/clients';
import { providersActions } from '../../state/ducks/providers';
import { paymentConditionsActions } from '../../state/ducks/paymentConditions';
import { statsActions } from '../../state/ducks/stats';

class PaymentConditionLog extends Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
    this.selectTable = React.createRef();
    this.csvRef = React.createRef();
  }

  getInitialState = () => ({
    persons: [],
    person: -1,
  });

  componentDidMount() {
    const {
      auth: { user: { user_id, b2b: { type_user } } },
      fetchStats,
      getPaymentConditionLog,
    } = this.props;

    //if (type_user ) {
    //getClients({});
    fetchStats({});
    /*} else {
      getProviders({});
    }*/

    const onlyConditionsLogUsers = ["buyer", "seller"];
    if(onlyConditionsLogUsers.includes(type_user)) {
      this.setState({person: 1})
      getPaymentConditionLog({
        params: {
          idUser: user_id,
        }
      });
    }
  }

  resetState = () => {
    this.setState(this.getInitialState());
  };

  getGridSettings = () => {
    const {
      t,
      auth: {
        user: {
          b2b: {
            type_user,
          },
        },
      },
      paymentConditions: { log },
    } = this.props;
    const {
      person,
    } = this.state;
    let columns;
    let data;
    let title;
    switch (type_user) {
      case 'acquiring_bank':
      case 'issuing_bank':
      case 'seller':
      case 'buyer':
        columns = [
          {
            expander: true,
            show: false,
          },
          {
            id: 'payment_type', // Required because our accessor is not a string
            Header: t('paymentType'),
            headerClassName: 'wordwrap',
            accessor: d => d.condition_type !== undefined && d.condition_type !== null ? `${d.condition_type}` : '',
          },
          {
            id: 'ncl_commission', // Required because our accessor is not a string
            Header: t('commission'),
            headerClassName: 'wordwrap',
            accessor: d => d.ncl_commission !== undefined && d.ncl_commission !== null ? `${d.ncl_commission}%` : '',
          },
          {
            id: 'applicant', // Required because our accessor is not a string
            Header: t('applicant'),
            headerClassName: 'wordwrap',
            accessor: d => `${t(d.applicant)}`,
          },
          {
            id: 'applicants_name', // Required because our accessor is not a string
            Header: t('applicantName'),
            headerClassName: 'wordwrap',
            accessor: d => `${d.applicants_name}`,
          },
          {
            id: 'customer_or_supplier_name', // Required because our accessor is not a string
            Header: type_user === 'issuing_bank' || type_user === 'buyer' ? t('provider') : t('client'),
            accessor: d => `${d.customer_or_supplier_name}`,
          },
          {
            id: 'authorized_by', // Required because our accessor is not a string
            Header: t('authorizedBy'),
            headerClassName: 'wordwrap',
            show: type_user === 'issuing_bank' || type_user === 'acquiring_bank',
            accessor: d => d.authorized_by !== null ? `${d.authorized_by}` : '',
          },
          {
            id: 'commercialCreditSince', // Required because our accessor is not a string
            Header: t('commercialCreditSince'),
            headerClassName: 'wordwrap',
            accessor: d => `${d.days_agreed_from}`,
          },
          {
            id: 'date', // Required because our accessor is not a string
            Header: t('applicationDate'),
            headerClassName: 'wordwrap',
            accessor: 'application_date',
            Cell: cellInfo => (
              <div>
                <span>{(cellInfo.original.application_date)}</span>
              </div>
            ),
          },
          {
            id: 'approved_date', // Required because our accessor is not a string
            Header: t('approvalDate'),
            headerClassName: 'wordwrap',
            accessor: 'approval_rejection_date',
            Cell: cellInfo => (
              <div>
                <span> {
                  (cellInfo.original.approval_status === 'Pendiente' || cellInfo.original.approval_status === 'Rechazado')
                    ? '' : (cellInfo.original.approval_rejection_date)
                }</span>
              </div>
            ),
          },
          {
            id: 'approval_status', // Required because our accessor is not a string
            Header: t('approvalStatus'),
            headerClassName: 'wordwrap',
            accessor: d => `${d.approval_status}`,
          },
          {
            id: 'registrationStatus',
            Header: t('registrationStatus'),
            headerClassName: 'wordwrap',
            accessor: d => `${d.registration_status}`,
          },
        ];
        data = person === -1 ? [] : log;
        title = t('changeOfCommercialConditions');
        break;
      default:
        columns = [];
        data = [];
        title = '';
    }
    return {
      columns,
      data,
      title,
    };
  };

  handleInputChange = (event) => {
    const {
      target: {
        checked, value: targetValue, type, name,
      },
    } = event;
    const value = type === 'checkbox' ? checked : targetValue;
    this.setState({
      [name]: value,
    });
  };

  handleSelectChange = (newVal, label) => {
    const {
      stats: { sellers, buyers },
      auth: { user: { b2b: { type_user } } },
      getPaymentConditionLog,
    } = this.props;

    getPaymentConditionLog({
      params: {
        idUser: type_user === 'issuing_bank' ? buyers[newVal.value].id : sellers[newVal.value].id,
      }
    });

    this.setState({
      [label.name]: newVal.value,
    });
  };

  render() {
    const {
      t,
      auth: { user: { b2b: { type_user } } },
      paymentConditions: { loading: paymentConditionLoading },
      stats: { sellers, buyers, loading: statsLoading },
    } = this.props;
    const {
      person,
    } = this.state;
    const loading = statsLoading || paymentConditionLoading;
    return (
      <Toggle>
        {({ toggled, onToggle }) => {
          const { title, columns, data } = this.getGridSettings(onToggle);
          return (
            <div className="dashboard">
              {loading && <Loading/>}
              <div className="columns is-multiline">
                <Fragment>
                  {
                    toggled
                    && (
                      <Modal onToggle={onToggle}/>
                    )
                  }
                  {data
                  && (
                    <div className="column is-full">
                      <Panel headingText={t('changeOfCommercialConditions')}>
                        <div className="field">
                          <div className="field-body">
                            <div className="columns" style={{ width: '50%' }}>
                              <div className="column" style={{ zIndex: 99999, marginTop: '20px' }}>
                                {type_user !== "buyer" && type_user !== "seller" &&
                                  <SelectSingle
                                    name="person"
                                    label={type_user === 'issuing_bank' ? t('client') : t('provider')}
                                    placeholder={t('SelectOption')}
                                    onChange={this.handleSelectChange}
                                    options={type_user === 'issuing_bank' ? [
                                      ...buyers.map((code, index) => ({
                                        label: code.idPerson.name,
                                        value: index,
                                      })),
                                    ] : [...sellers.map((code, index) => ({
                                      label: code.idPerson.name,
                                      value: index,
                                    })),]}
                                    selectClasses="is-fullwidth"
                                    controlClasses="has-icons-left"
                                    icon="far fa-user"
                                    iconPosition="is-left"
                                    value={person}
                                  />
                                }
                              </div>
                            </div>
                          </div>
                          <br/>
                        </div>
                        <TableSimple
                          data={data}
                          columns={columns}
                          title={title}
                        />
                      </Panel>
                    </div>
                  )}
                </Fragment>
              </div>
            </div>
          );
        }}
      </Toggle>
    );
  }
}

PaymentConditionLog.propTypes = {
  t: PropTypes.func.isRequired,
  auth: PropTypes.shape(Object).isRequired,
  showMessage: PropTypes.func.isRequired,
};

const mapStateToProps = ({ auth, stats, clients, providers, paymentConditions, }) => (
  {
    auth,
    stats,
    clients,
    providers,
    paymentConditions,
  }
);
export default compose(
  connect(mapStateToProps, { ...statsActions, ...clientsActions, ...providersActions, ...paymentConditionsActions }),
  withNamespaces(),
)(PaymentConditionLog);