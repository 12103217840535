import React, { Component } from 'react';
import { compose } from 'redux';
import './styles.scss';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import PropTypes from 'prop-types';
import { Loading, } from '../../components';
import { TOAST_CONFIG } from '../../config/constants';
import { documentTypeActions } from '../../state/ducks/documentType';
import { roleActions } from '../../state/ducks/role';
import { clientsActions } from '../../state/ducks/clients';
import RoleForm from '../../components/RoleForm';
import { authActions } from '../../state/ducks/auth';


class CreateRole extends Component {
  constructor(props) {
    super(props);
    this.state = { ...this.getInitialState() };
  }

  getInitialState = () => ({
    name: '',
    roleType: 'client',
    registerLoading: false,
    is_approver: 1,
  });

  componentDidMount() {
    /*const { fetchTypesRole } = this.props;
    fetchTypesRole();*/
  }

  resetState = () => {
    this.setState(this.getInitialState());
  };

  handleInputChange = (event) => {
    const {
      target: {
        checked, name, value,
      },
    } = event;
    const is_approver = checked ? 0 : 1;

    this.setState({
      [name]: name === "is_approver" ? is_approver : value,
    });
  };

  createRol = () => {
    const {
      addRole,
      t,
      showMessage,
      history,
      auth: { user: { b2b: { id } } },
      role: { type: { list: typeList } },
    } = this.props;

    const { name, is_approver } = this.state;

    /*let indexRol = -1;
    for(let i = 0; i < typeList.length; i++){
      if(typeList[i] === roleType){
        indexRol = i;
        break;
      }
    }*/

    const data = {
      name,
      type: 'client',//typeList[indexRol],
      owner_id: id,
      is_approver
    };
    this.setState({ registerLoading: true });
    addRole({
      data,
      callback: (response) => {
        const { data: { name: rolName } } = response;
        history.push('/role-list');
        showMessage({
          message: t('createRoleSuccess', { rolName }),
          config: TOAST_CONFIG.SUCCESS,
        });
        this.resetState();
      },
    });
  };

  render() {
    const {
      t,
      role: { /*type: { list: typeList },*/ loading: roleLoading },
    } = this.props;
    const {
      name, registerLoading, roleType, is_approver,
    } = this.state;
    const loading = roleLoading || registerLoading;
    return (

      <div>
        {loading && <Loading />}
        <div className="columns">
          <div className="column is-full">
            <RoleForm
              t={t}
              onSubmit={this.createRol}
              handleInputChange={this.handleInputChange}
              name={name}
              //typeList={typeList}
              roleType={roleType}
              editable={false}
              is_approver={is_approver}
            />
          </div>
        </div>
      </div>

    );
  }
}

CreateRole.propTypes = {
  role: PropTypes.shape(Object).isRequired,
  history: PropTypes.shape(Object).isRequired,
  documentType: PropTypes.shape({}).isRequired,
  fetchTypesRole: PropTypes.func.isRequired,
  addRole: PropTypes.func.isRequired,
  showMessage: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};


const mapStateToProps = ({
  documentType,
  role,
}) => ({
  documentType,
  role,
});
export default compose(
  connect(mapStateToProps, {
    ...documentTypeActions,
    ...roleActions,
    ...clientsActions,
    ...authActions,
  }),
  withNamespaces(),
)(CreateRole);
