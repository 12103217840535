import * as React from "react"
import { useState } from "react"
import PropTypes from "prop-types"
import "./styles.scss"

/**
 *
 * @param {Object} props ToggleBTN props
 * @param {Number} [props.width] define the BTN size
 * @param {String} [props.color] color set: is-primary/link/info
 * @param {Function | Boolean} [props.handleClick] click handler
 * @param {Boolean | Number | String} [props.isOn] define toggle's status
 * @param {Boolean | undefined} [props.disabled]
 * @returns {*}
 */

const ToggleBTN = ({ width, color, handleClick, disabled, isOn }) => {
  const BTNStyle = {
    width,
    height: width / 2,
    minWidth: width,
    borderRadius: width,
  }

  return (
    <div
      name="toggle-btn"
      role="button"
      style={BTNStyle}
      className={`ToggleBTN__main ${isOn && "toggle-on"} ${color}`}
      onClick={() => {
        !disabled && handleClick()
      }}
    ></div>
  )
}

ToggleBTN.propTypes = {
  width: PropTypes.number,
  color: PropTypes.string,
  handleClick: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  isOn: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.number,
    PropTypes.string,
  ]),
  disabled: PropTypes.oneOfType([PropTypes.bool]),
}

ToggleBTN.defaultProps = {
  width: 50,
  handleClick: () => {},
}

export default ToggleBTN
