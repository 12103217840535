import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import { webpayActions } from '../../state/ducks/webpay';
import Modal from '../../components/Modal';
import { authActions } from '../../state/ducks/auth';
import { Loading, Panel, Toggle, } from '../../components';
import TextInput from '../../components/TextInput';
import SelectSingle from '../../components/SelectSingle';
import { cardActions } from '../../state/ducks/card';
import { TOAST_CONFIG } from '../../config/constants';
import { getPath } from '../../util';

class Success extends Component {
  constructor(props) {
    super(props);
    const {
      location: { state },
    } = props;
    this.state = { ...this.getInitialState(), ...state };
  }

  getInitialState = () => ({
    arrays: '',
    message: '',
    status: false,
    cycleResp: '',
    error: false,
    number_card: '',
    cuttingCycle: -1,
    cuttingCycles: [],
    startDate: '',
    endingDate: '',
    id_card: '',
  });

  componentDidMount() {
    const {
      getCycleCard,
      registerPayment,
      updateB2B,
      auth: {
        user: {
          user_id, b2b: { bank_id, confirm_information }, profile: { user_id: user_id_profile }
        },
      },
    } = this.props;

    let data = '';
    data = this.arrayData(window.location.href);

    /*getCycleCard({
      params: {
        bank: bank_id,
      }
    });*/

    registerPayment({
      data: data,
      callback: (response) => {
        if (response.success === true) {
          this.setState({
            message: response.data.message,
            status: true,
            number_card: data.number.substr(11),
            id_card: data.id,
          });
          updateB2B({
            params: {
              user_id: user_id_profile
            },
            // callback: () => {
              //   getCycleCard({
            //     params: {
              //       bank: bank_id,
            //     },
            //     callback: (response) => {
            //       this.setState({
            //         cuttingCycles: response,
            //       });
            //     }
            //   });
            //   // window.top.location.replace('/dashboard');
            // },
          });
          setTimeout(function () {
            window.top.location.replace(confirm_information ? '/list-cards' : '/confirm-customer');
          }, 2000);
        }
        if (response.success === false) {
          this.setState({
            message: response.data.message,
            status: false,
          });
          setTimeout(function () {
            window.top.location.replace('/register-card');
          }, 1000);
        }
      }
    });
  }

  arrayData = (url) => {
    const {
      auth: {
        user: {
          profile: { user_id }
        },
      },
    } = this.props;

    const arrays = url.split('=');
    const number = arrays[2].split('&');
    const cardToken = arrays[1].split('%2F');
    const cardToken1 = cardToken[cardToken.length - 1].split('&');
    const due_pay = arrays[3].split('&');
    const type = arrays[4].split('&');
    const idCard = arrays[5];

    return {
      user_id: user_id,
      due_pay: due_pay[0],
      number: number[0],
      token: cardToken1[0],
      replaceCard: type[0] === '1',
      idReplaceCard: idCard,
    };
  };

  /*resp = (onToggle, resp) => {
    const {
      confirmBillingCycle,
      webpay: { cycleCard },
    } = this.props;

    const data = {
      payment_person: cycleCard.data.payment_person,
      billing_cycle: cycleCard.data.billing_cycle,
      confirm: resp === 'yes' ? true : false,
    };

    confirmBillingCycle({
      data,
      callback: (response) => {
        if (response.success === true) {
          if (resp === 'yes') {
            this.setState({
              cycleResp: true,
              status: '',
            });
            window.top.location.replace('/list-cards');
          }
          if (resp === 'no') {
            this.setState({
              cycleResp: false,
              status: '',
            });
            window.top.location.replace('/dashboard');
          }
        }
        if (response.success === false) {
          this.setState({
            error: true,
          });
          window.top.location.replace('/dashboard');
        }
      }
    });
    this.getInitialState();
    onToggle();
  };

  closeModal1 = (onToggle) => {
    onToggle();
  };*/

  closeModal = (onToggle, type) => {
    const {
      t,
      showMessage,
      updateBillingPaymentPerson,
      auth: { user: { b2b: { confirm_information } } },
      webpay: { cycleCard: { data: { payment_person } } }
    } = this.props;
    const {
      cuttingCycle,
      cuttingCycles,
    } = this.state;

    if (type === 'cancel') {
      this.setState({
        error: true,
      });
      showMessage({
        message: t('enterTheCardBillingCycle'),
        config: TOAST_CONFIG.WARNING,
      });
    }

    if (type === 'save') {
      const data = {
        billing_cycle_id: cuttingCycles[cuttingCycle].id,
        payment_id: payment_person.id,
      };

      updateBillingPaymentPerson({
        data,
        callback: () => {
          onToggle();
          this.setState(
            { cycleResp: true }
          );
          showMessage({
            message: t('successfullyAssignedCuttingCycle'),
            config: TOAST_CONFIG.SUCCESS,
          });
          window.top.location.replace(confirm_information ? '/list-cards' : '/confirm-customer');
        }
      });

    }

    //window.top.location.replace('/list-cards');
  };

  handleSelectChange = (newVal, label) => {
    const {
      cuttingCycles,
    } = this.state;
    const {
      getPeriodBilling
    } = this.props;


    this.setState({
      [label.name]: newVal.value,
    });

    if (newVal.value !== '') {
      getPeriodBilling({
        params: {
          id: cuttingCycles[newVal.value].id,
        },
        callback: responseData => {
          this.setState({
            startDate: responseData.date_init,
            endingDate: responseData.date_end,
          });
        }
      });
    }
  };

  render() {
    const {
      status,
      cycleResp,
      error,
      number_card,
      cuttingCycle,
      cuttingCycles,
      //startDate,
      //endingDate,
      message,
    } = this.state;
    const {
      t,
      history,
      webpay: { cycleCard, loading: webpayLoading },
      card: { loading: cardLoading },
    } = this.props;
    const loading = webpayLoading || cardLoading;

    return (
      <Toggle>
        {({ /*toggled,*/ onToggle }) => {
          return (
            <div>
              {loading && <Loading/>}
              {status === true ? (
                <Modal 
                  title={t('cardLoadedSuccessfully')} 
                  onToggle={() => history.push('/list-cards')}>

                </Modal>
              ) : (
                <Modal 
                  title={t(message)} 
                  onToggle={() => history.push('/register-card')}>
                </Modal>
              )}
               {/* <Modal onToggle={onToggle}> {(loading) && <Loading/>}
                 {status === true && (
                  <div>
                    <Panel headingText={t('enterCuttingCycle')}>
                      <div className="field is-horizontal">
                        <div className="field-body columns">
                          <div className="column">
                            <TextInput
                              name="number_card"
                              label={t('targetNumber')}
                              value={'...' + number_card}
                              placeholder={t('targetNumber')}
                              type="text"
                              controlClasses="is-expanded"
                              inputClasses="has-text-right"
                              disabled={true}
                              //isRequired={true}
                            />
                          </div>
                          <div className="column">
                            <SelectSingle
                              name={'cuttingCycle'}
                              label={t('cuttingCycle')}
                              selectClasses="is-fullwidth"
                              placeholder={t('Select')}
                              onChange={this.handleSelectChange}
                              options={[
                                ...cuttingCycles.map((item, index) => ({
                                  label: `${getPath(['period', 'date_init'], item)} - ${getPath(['period', 'date_end'], item)}`,
                                  value: index,
                                })),
                              ]}
                              value={cuttingCycle}
                            />
                          </div> */}
                          {/*<div className="column">
                            <SelectSingle
                              name={'cuttingCycle'}
                              label={t('cuttingCycle')}
                              selectClasses="is-fullwidth"
                              placeholder={t('Select')}
                              onChange={this.handleSelectChange}
                              options={[
                                ...cuttingCycles.map((item, index) => ({
                                  label: `${getPath(['cycle', 'period', 'date_init'], item)} - ${getPath(['cycle', 'period', 'date_end'], item)}`,
                                  //label: item.cycle.period.date_init
                                  value: index,
                                })),
                              ]}
                              value={cuttingCycle}
                            />
                          </div>*/}
                        {/* </div>
                      </div> */}
                      {/* <div className="field is-horizontal">
                        <div className="field-body columns">
                          <div className="column">

                          </div>
                        </div>
                      </div> */}
                      {/* <div className="field is-horizontal">
                        <div className="field-body columns"> */}
                          {/*<div className="column">
                            <SelectSingle
                              name={'cuttingCycle'}
                              label={t('cuttingCycle')}
                              selectClasses="is-fullwidth"
                              placeholder={t('Select')}
                              onChange={this.handleSelectChange}
                              options={[
                                ...cuttingCycles.map((item, index) => ({
                                  label: `${getPath(['period', 'date_init'], item)} - ${getPath(['period', 'date_end'], item)}`,
                                  //label: item.cycle.period.date_init
                                  value: index,
                                })),
                              ]}
                              value={cuttingCycle}
                            />
                          </div>
                          <div className="column">
                            <TextInput
                              name="startDate"
                              label={t('startDate')}
                              value={startDate}
                              placeholder={t('startDate')}
                              type="text"
                              controlClasses="is-expanded"
                              disabled={true}
                              //isRequired={true}
                            />
                          </div>
                            <div className="column">
                            <TextInput
                            name="endingDate"
                            label={t('endingDate')}
                            value={endingDate}
                            placeholder={t('endingDate')}
                            type="text"
                            controlClasses="is-expanded"
                            disabled={true}
                            //isRequired={true}
                            />
                            </div>*/}
                        {/* </div>
                      </div>
                      <div className="field is-grouped is-grouped-right">
                        <button
                          type="button"
                          className="button is-primary"
                          onClick={this.closeModal.bind(this, onToggle, 'save')}
                          disabled={cuttingCycle === -1}
                        >
                          {t('save')}
                        </button>
                        &nbsp;&nbsp;
                        <button
                          type="button"
                          className="button is-primary is-danger"
                          onClick={this.closeModal.bind(this, onToggle, 'cancel')}
                        >
                          {t('cancel')}
                        </button>                        
                      </div> */}

                      {/*<div className="is-fullwidth has-text-right">
                        <button
                          type="button"
                          className="button is-primary"
                          onClick={this.closeModal.bind(this, onToggle)}
                        >
                          {t('accept')}
                        </button>
                        &nbsp;
                      </div>*/}
                    {/* </Panel>
                  </div>
                )} */}
                {/*status === true && (
                  <Panel headingText={t('cuttingCycleAmex')}>
                    <div className="field is-horizontal">
                      <div className="field-body columns">
                        <div className="column">
                          <h1 style={{ fontSize: '18px' }}>
                            <strong>{t('cuttingCycleCard', { message })}</strong>
                          </h1>
                          <br/>
                        </div>
                      </div>
                    </div>
                    <div className="is-fullwidth has-text-right">
                      <button
                        type="button"
                        className="button is-primary"
                        onClick={this.resp.bind(this, onToggle, 'yes')}
                      >
                        {t('yes')}
                      </button>
                      &nbsp;&nbsp;
                      <button
                        type="button"
                        className="button is-primary"
                        onClick={() => this.resp(onToggle, 'no')}
                      >
                        {t('no')}
                      </button>
                    </div>
                  </Panel>
                )*/}
                {/* {status === false && (
                  <div
                    className={`notification is-danger`} style={{ textAlign: 'center' }}>
                    <h1 style={{ fontSize: '18px' }}>
                      {cycleCard.length > 0 && (
                        <strong><label>{cycleCard.data.message}</label></strong>
                      )}
                      {cycleCard.length === 0 && (
                        <strong><label>{t('noInformation')}</label></strong>
                      )}
                      {cycleCard && (
                        <strong><label>{cycleCard.data.message}</label></strong>
                      )}
                    </h1>
                  </div>
                )}  */}
                {/* {cycleResp === true && (
                  <div>
                    <div className={`notification is-success`} style={{
                      fontFamily: 'Roboto, Arial, sans-serif',
                      marginTop: '-15px',
                      zIndex: 99999
                    }}>
                      <h1 style={{ fontSize: '18px' }}>
                        <strong>{t('successCycle')}</strong>
                      </h1>
                    </div>
                    <div className="column is-full">
                    </div>
                  </div>
                )
                }
                {cycleResp === false && (
                  <div>
                    <div className={`notification is-warning`} style={{
                      background: '#fcb92d',
                      fontFamily: 'Roboto, Arial, sans-serif'
                    }}>
                      <h1 style={{ fontSize: '18px' }}>
                        <strong>{t('rejectedCycle')}</strong>
                      </h1>
                    </div>
                    <div className="column is-full">
                    </div>
                  </div>
                )
                }
                {error === true && (
                  <div>
                    <div className={`notification is-info`} style={{
                      fontFamily: 'Roboto, Arial, sans-serif'
                    }}>
                      <h1 style={{ fontSize: '18px' }}>
                        <label><strong>{t('enterTheCardBillingCycle')}</strong></label>
                      </h1>
                    </div>
                    <div className="column is-full">
                    </div>
                  </div>
                )} 
              </Modal> */}
            </div>
          );
        }}
      </Toggle>
    );
  }
}

Success.propTypes = {
  t: PropTypes.func.isRequired,
  registerPayment: PropTypes.func.isRequired,
  updateB2B: PropTypes.func.isRequired,
};

const mapStateToProps = ({ webpay, auth, card }) => (
  {
    webpay,
    auth,
    card,
  }
);

export default compose(
  connect(mapStateToProps, {
    ...webpayActions,
    ...authActions,
    ...cardActions,
  }),
  withNamespaces(),
)(Success);