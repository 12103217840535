import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import { authActions } from '../../state/ducks/auth';
import { Panel, } from '../../components';
import { paymentConditionsActions } from '../../state/ducks/paymentConditions';

class AcceptDiscountRate extends Component {
    constructor(props) {
        super(props);
        const {
            location: { state },
        } = props;
        this.state = { ...this.getInitialState(), ...state };
    }

    getInitialState = () => ({
        conditions: [],
    });

    resetState = () => {
        this.setState(this.getInitialState());
    };

    componentDidMount() {
    }

    acceptCondition = (condition_id) => {
        const {
            acceptPaymentCondition,
            updateB2B,
            auth: { user: { user_id, b2b: { pending_payment_condition }, profile: { user_id: user_id_profile } } },
            history,
        } = this.props;

        let data;
        data = {
            id: [condition_id],
            approved: true,
        }

        acceptPaymentCondition({
            data,
            callback: () => {            
                updateB2B({
                    params: {
                        user_id: user_id_profile
                    },
                    callback: (data) => {
                        if(data.b2b.pending_payment_condition.length > 0){
                            history.push('/accept-discount-rate');
                        }else{
                            history.push('/dashboard');
                        }
                        this.resetState();
                    },   
                })
            },
        });
    }

    render() {
        const {
            t,
            auth: { user: { b2b: { pending_payment_condition } } },
        } = this.props;
        return (
        <div>
            <div className="columns">
                <div className="column is-full">
                    <Panel headingText={t('discountRateAccept')}>

                        {pending_payment_condition.map(val => (
                            <div>
                                <p style={{marginBottom: "1em"}}>
                                    <span style={{fontWeight: "bold"}}>{t('provider')+": "}</span>{val.provider_name}
                                </p>
                                <p>{t('discountRateTerms')}</p>
                                <div className="is-fullwidth has-text-right">
                                    <button
                                    type="button"
                                    className="button is-primary"
                                    onClick={() => this.acceptCondition(val.id)}
                                    >
                                    {t('accept')}
                                    </button>
                                </div>
                            </div>
                        ))}
                    </Panel>
                </div>
            </div>
        </div>
        );
    }
    }

    AcceptDiscountRate.propTypes = {
    t: PropTypes.func.isRequired,
    };

    const mapStateToProps = ({ auth, router, paymentConditions }) => (
    {
        auth,
        router,
        paymentConditions,
    }
    );

    export default compose(
    connect(mapStateToProps, {
        ...paymentConditionsActions,
        ...authActions,
    }),
    withNamespaces(),
    )(AcceptDiscountRate);