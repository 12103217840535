const START_FETCH = "auth/START_FETCH";
const END_FETCH = "auth/END_FETCH";
const END_ERROR = "auth/END_ERROR";
const UPDATE_PROFILE = "auth/UPDATE_PROFILE";
const UPDATE_USER = "auth/UPDATE_USER";
const UPDATE_ACCOUNT = "auth/UPDATE_ACCOUNT";
const UPDATE_CONTACTS = "auth/UPDATE_CONTACTS";
const GET_CONTACTS = "auth/GET_CONTACTS";
const SIGN_IN = "auth/SIGN_IN";
const LOGOUT = "auth/LOGOUT";
const LOGGED = "auth/LOGGED";
const API_CALL = "auth/API_CALL";
const CHANGE_LANGUAGE = "auth/CHANGE_LANGUAGE";
const MESSAGE = "auth/MESSAGE";
const CLEAR = "auth/CLEAR";
const UPDATE_STATUS = "auth/UPDATE_STATUS";
const PASSWORD_RESET = "auth/PASSWORD_RESET";
const PASSWORD_RESET_TOKEN = "auth/PASSWORD_RESET_TOKEN";
const PASSWORD_RESET_LOGIN = "auth/PASSWORD_RESET_LOGIN";
const CHANGE_ROLE = "auth/CHANGE_ROLE";
const SAVE_ROLE = "auth/SAVE_ROLE";
const UPDATE_B2B = "auth/UPDATE_B2B";
const SAVE_B2B = "auth/SAVE_B2B";
const REQUEST_2FA = "auth/REQUEST_2FA";
const CHANGE_2FA = "auth/CHANGE_2FA";
const VALIDATE_CODE = "auth/VALIDATE_CODE";
const GET_USER_INFO = "auth/GET_USER_INFO";
const SAVE_CONFIRMATION = "auth/SAVE_CONFIRMATION";
const LOGIN_PROFILES = "auth/LOGIN_PROFILES";
const SAVE_PROFILES = "auth/SAVE_PROFILES";

export default {
  START_FETCH,
  END_FETCH,
  END_ERROR,
  UPDATE_PROFILE,
  GET_CONTACTS,
  LOGGED,
  LOGOUT,
  SIGN_IN,
  UPDATE_USER,
  UPDATE_CONTACTS,
  API_CALL,
  CHANGE_LANGUAGE,
  MESSAGE,
  CLEAR,
  UPDATE_ACCOUNT,
  UPDATE_STATUS,
  PASSWORD_RESET,
  PASSWORD_RESET_TOKEN,
  PASSWORD_RESET_LOGIN,
  CHANGE_ROLE,
  SAVE_ROLE,
  UPDATE_B2B,
  SAVE_B2B,
  REQUEST_2FA,
  CHANGE_2FA,
  VALIDATE_CODE,
  GET_USER_INFO,
  SAVE_CONFIRMATION,
  LOGIN_PROFILES,
  SAVE_PROFILES,
};
