import types from './types';
import { authTypes } from '../auth';
import { initialState } from '../../../App';

const loan = (state = {}, action) => {
  switch (action.type) {
    case authTypes.CLEAR: {
      return {
        ...state, ...initialState.clients,
      };
    }
    case types.START_FETCH: {
      return {
        ...state,
        loading: true,
      };
    }
    case types.END_FETCH: {
      const { error, payload } = action;
      return {
        ...state,
        loading: false,
        error: error ? payload : null,
      };
    }
    case types.SAVE_LOAN: {
      const { payload } = action;
      return {
        ...state,
        list: payload,
      };
    }
    case types.SAVE_REFERENCE: {
      const { payload } = action;
      return {
        ...state,
        info: payload,
      };
    }
    default:
      return state;
  }
};

export default loan;