import types from './types';
import { authTypes } from '../auth';
import { initialState } from '../../../App';

const card = (state = {}, action) => {
  switch (action.type) {
    case authTypes.CLEAR: {
      return {
        ...state, ...initialState.card,
      };
    }
    case types.START_FETCH: {
      return {
        ...state,
        loading: true,
      };
    }
    case types.END_FETCH: {
      const { error, payload } = action;
      return {
        ...state,
        loading: false,
        error: error ? payload : null,
      };
    }
    case types.UPDATE_CARD_DATA: {
      const {
        card, person, address, transactions: transactionData,
      } = action.payload;

      return {
        ...state,
        card,
        person,
        address,
        transactions: transactionData,
      };
    }
    case types.SAVE_CYCLE_CAR: {
      const { payload } = action;
      return {
        ...state,
        cycle: payload,
      };
    }
    case types.GET_CYCLES: {
      const { payload } = action;
      return {
        ...state,
        list: payload,
      };
    }
    case types.GET_ASSIST_CARD: {
      const { payload } = action;
      return {
        ...state,
        assist: payload,
      };
    }
    case types.SAVE_EXECUTIVES_FILE: {
      const { payload } = action;
      return {
        ...state,
        fileExecutives: payload,
      };
    }
    default:
      return state;
  }
};

export default card;
