import React, { Component, Fragment } from 'react';
import { compose } from 'redux';
import './styles.scss';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import PropTypes from 'prop-types';
import { Loading, Modal, Toggle } from '../../components';
import { documentTypeActions } from '../../state/ducks/documentType';
import { providersActions } from '../../state/ducks/providers';
import { TOAST_CONFIG } from '../../config/constants';
import { paymentConditionsActions } from '../../state/ducks/paymentConditions';
import { itemActions } from '../../state/ducks/item';
import { leadActions } from '../../state/ducks/lead';
import PersonForm from '../../components/PersonForm';
import { roleActions } from '../../state/ducks/role';
import { clientsActions } from '../../state/ducks/clients';
import { flowApprovalActions } from '../../state/ducks/flowApproval';
import { cardActions } from '../../state/ducks/card';

import { validateAlphaNumeric } from '../../util'

class CreateCustomer extends Component {
  constructor(props) {
    super(props);
    const {
      location: { state },
    } = props;
    this.state = { ...this.getInitialState(), ...state };
  }

  getInitialState = () => ({
    name: '',
    documentType: '',
    documentNumber: '',
    email: '',
    password: '',
    address: '',
    percentage: 0,
    period: -1,
    installments: 1,
    items: [],
    provider: '',
    startCountingFrom: 1,
    baseProvider: null,
    baseDays: 0,
    discountRate: 0,
    adminName: '',
    adminLastname: '',
    adminPosition: '',
    adminDocumentType: [],
    adminDocumentNumber: '',
    receiverDiscountShare: 100,
    clientPayment: 0,
    typeFlow: -1,
    edit: false,
    hidden: false,
    last4Digits: '',
    cuttingCycleCard: -1,
    countCycleCard: 0,
    billing_cycles: [{
      id: 0,
      last_four: '',
      cycleCounting: -1,
      billing_cycle_id: '',
      billing_cycle_index: -1,
    }
    ],
    phoneNumber: '',
    provinces: [],
    province: -1,
    cantons: [],
    canton: -1,
    districts: [],
    district: -1,
    inputValue: '',
    inputValueExecutive: '',
    executives: [],
    business_name: '',
    unformattedRut: '',
    unformattedAdminRut: '',
    phoneNumberAdmin: '',
    newBankingExec: [],
    countBankingExec: 0,
    typePerson: '',
    typeDocument: [],
    documentTypeUser: '',
    type_legal_person: '',
    street: '',
    number: '',
    otherSign: '',
    cif: '',
    categories: [],
    category: '',
    industries: [],
    industriesList: [],
    industry: -1,
    inputValueIndustry: '',
    outdoor_number: '',
    interior_number: '',
    suburb: '',
    municipality: '',
    postal_code: '',
    authorized_by: '',
    user_type: 'NCL',
    status: false,
    countryCode: '',
    areaCode: '',
  });

  componentDidMount() {
    const {
      t,
      fetchDocumentTypes,
      fetchItems,
      fetchCategories,
      fetchRoles,
      getExecutive,
      auth: { user: { first_login, user_id, b2b: { type_user, roles } } },
      router: { location: { pathname } },
      getClient,
      fetchIndustries,
      fetchCities,
    } = this.props;
    
    if(type_user == 'buyer' && first_login == 0){
      getClient({
        params: {
          id: user_id,
        },
      });
    }
    fetchItems({
      category_id: "",
    });

    fetchDocumentTypes({
      params: { status: 1 },
      callback: (data) => {
        this.setState({ 
          adminDocumentType: data,
          typeDocument: data,
        });
      }
    });

    fetchCategories({
      callback: (response) => {
        this.setState({ categories: response });
      }
    });
    getExecutive({
      params: { owner: roles[0].owner_id },
      callback: (response) => {
        const exc = [];
        let j = 0;
        for (let i = 0; i < response.length + 1; i++) {
          if (i === 0) {
            exc.push({
              bank_id: response.length === 0 ? roles[0].owner_id : response[i].bank_id,
              lastname: '',
              id: -1,
              email: t('businessExecutive'),
              name: t('addNew'),
            });
          } else {
            exc.push({
              bank_id: response[j].bank_id,
              email: response[j].email,
              id: response[j].id,
              lastname: response[j].lastname,
              name: response[j].name,
            });
            j += 1;
          }
        }
        this.setState({ exc: exc });
      }
    });

    fetchCities({
      callback: (response) => {
        this.setState({ districts: response });
      }
    });

    if (pathname === '/create-customer') {
      fetchIndustries({
        callback: (response) => {
          this.setState({ industriesList: response});
        }
      });
    }
    fetchRoles({
      params: {
        type: 'external',
        name: 'buyer'
      },
    });

    if (pathname === '/edit-customer') this.setState(this.arrayCustomer());
  }

  arrayCustomer = () => {
    const {
      clients: { list: clientList },
      documentType: { list: documentTypeList },
      item: { list: listCategories },
      fetchCities,
      fetchDocumentTypes,
      fetchIndustries,
    } = this.props;
    let documentType = -1;

    if (clientList.buyer[0].idPerson.idDocumentType !== null) {
      fetchDocumentTypes({
        params: {
          status: 1
        },
        callback: (data) => {
          const typeDocument = clientList.buyer[0].idPerson.idDocumentType.document_type !== 'RFC' ? 'RFC' : clientList.buyer[0].idPerson.idDocumentType.document_type;
          documentType = (data.map(
            (documentTypeMap, index) => ({
              ...documentTypeMap,
              index
            })
          )
            .filter(documentTypeFil => documentTypeFil.document_type
              === typeDocument)[0].index);
          this.setState({
            documentTypeUser: documentType,
            typeDocument: data,
            type_legal_person: clientList.buyer[0].idPerson.idDocumentType.document_type === 'Cédula jurídica' ? 'JUR' : 'FIS',
            typePerson: clientList.buyer[0].idPerson.idDocumentType.document_type === 'Cédula jurídica' ? 'legal' : 'person',
          });
        }
      });
    }

    if (clientList.buyer[0].admin_document_type !== null) {
      fetchDocumentTypes({
        params: { status: 1 },
        callback: (data) => {
          documentType = (data.map(
            (documentTypeMap, index) => ({
              ...documentTypeMap,
              index
            })
          )
            .filter(documentTypeFil => documentTypeFil.document_type
              === clientList.buyer[0].admin_document_type)[0].index);
          this.setState({
            documentType: documentType,
          });
        }
      });
    }
    const item = [];
    for (let i = 0; i < clientList.items.length; i++) {
      item.push({
        id: clientList.items[i].id,
        classification: clientList.items[i].classification,
        code: clientList.items[i].code,
        description: clientList.items[i].description
      });
    }
    const executives = [];
    for (let i = 0; i < clientList.banking_executive.length; i++) {
      executives.push({
        id: clientList.banking_executive[i].executive.id,
        name: clientList.banking_executive[i].executive.name,
        lastname: clientList.banking_executive[i].executive.lastname,
        email: clientList.banking_executive[i].executive.email
      });
    }

    const period = clientList.buyer[0].paymentConditionAsBuyer[0] !== null ?
      (this.getDays(clientList.buyer[0].paymentConditionAsBuyer[0].days)) : -1;

    if(clientList.buyer[0].district !== null) {
      fetchCities({
        callback: (response) => {
          this.setState({
            districts: response,
            district: response.findIndex(x => x.id == clientList.buyer[0].district.id)
          });
        }
      });
    };
    fetchIndustries({
      callback: (response) => {
        this.setState({
          industriesList: response,
          industry:  (clientList.buyer[0].industries.length > 0 && clientList.buyer[0].industries[0].id !== null) ? response.findIndex(x => x.id === clientList.buyer[0].industries[0].id) : -1,
        });
      }
    });
    
    return {
      category: ((clientList.items.length) > 0 && (clientList.items[0].category_id !== null)) ? listCategories.findIndex(x => x.id === parseInt(clientList.items[0].category_id)) : -1,
      name: clientList.buyer[0].idPerson.name,
      documentTypeUser: documentType,
      documentType,
      documentNumber: clientList.buyer[0].idPerson.document_number,
      address: clientList.buyer[0].address_full,
      outdoor_number: clientList.buyer[0].address !== null ? clientList.buyer[0].address.outdoor_number : '',
      interior_number: clientList.buyer[0].address !== null ? clientList.buyer[0].address.interior_number : '',
      suburb: clientList.buyer[0].address !== null ? clientList.buyer[0].address.suburb : '',
      municipality: clientList.buyer[0].address !== null ? clientList.buyer[0].address.municipality : '',
      postal_code: clientList.buyer[0].address !== null ? clientList.buyer[0].address.postal_code : '',
      street: clientList.buyer[0].address !== null ? clientList.buyer[0].address.street : '',
      adminName: clientList.buyer[0].admin_name,
      adminLastname: clientList.buyer[0].admin_lastname,
      adminDocumentNumber: clientList.buyer[0].admin_document_number,
      adminPosition: clientList.buyer[0].admin_position,
      email: clientList.buyer[0].idPerson.idEmailActive.email,
      percentage: clientList.buyer[0].paymentConditionAsBuyer[0] !== null ? clientList.buyer[0].paymentConditionAsBuyer[0].percentage : 0,
      period,
      typeFlow: clientList.process_type === null ? 0 : clientList.process_type,
      edit: true,
      items: item,
      hidden: true,
      billing_cycles: [],
      executives,
      business_name: clientList.buyer[0].business_name === null ? '' : clientList.buyer[0].business_name,
      areaCode: clientList.buyer[0].phone !== null ? clientList.buyer[0].phone.area_code : '',
      countryCode: clientList.buyer[0].phone !== null ? clientList.buyer[0].phone.country_code : '',
      phoneNumber: clientList.buyer[0].phone !== null ? clientList.buyer[0].phone.phone_number : '',
      phoneNumberAdmin: clientList.buyer[0].admin_phone_number,
      authorized_by: clientList.buyer[0].authorized_by,
    };
  };

  getDays = (period) => {
    switch (period) {
      case 365:
        return 0;
      case 30:
        return 1;
      case 1:
        return 2;
      default:
        return '';
    }
  };

  getDaysInv = (period) => {
    switch (period) {
      case 0:
        return 365;
      case 1:
        return 30;
      case 2:
        return 1;
      default:
        return '';
    }
  };

  resetState = () => {
    this.setState(this.getInitialState());
  };

  getTitle = () => {
    const {
      t,
      router: { location: { pathname } },
    } = this.props;

    switch (pathname) {
      case '/create-customer':
        return t('createNCLCustomer');
      case '/edit-customer':
        return t('edit');
      default:
        return t('');
    }
  };

  handleInputChangeNewExc = (event) => {
    const {
      target: {
        checked, value: targetValue, type, name,
      },
    } = event;
    const {
      auth: { user: { b2b: { id } } },
    } = this.props;
    const value = type === 'checkbox' ? checked : targetValue;

    const executive = name.split('-');

    let newBankingExec = [...this.state.newBankingExec];

    switch (executive[0]) {
      case 'name':
        newBankingExec[executive[1]] = {
          id: Number(executive[1]),
          name: value,
          lastname: newBankingExec[executive[1]].lastname,
          email: newBankingExec[executive[1]].email,
          bank_id: id,
        };
        break;
      case 'lastname':
        newBankingExec[executive[1]] = {
          id: Number(executive[1]),
          name: newBankingExec[executive[1]].name,
          lastname: value,
          email: newBankingExec[executive[1]].email,
          bank_id: id,
        };
        break;
      case 'email':
        newBankingExec[executive[1]] = {
          id: Number(executive[1]),
          name: newBankingExec[executive[1]].name,
          lastname: newBankingExec[executive[1]].lastname,
          email: value,
          bank_id: id,
        };
        break;
    }

    this.setState({ newBankingExec });
  };

  handleInputChangeTypeDocument = (event) => {
    const {
      fetchDocumentTypes,
    } = this.props;
    const {
      target: {
        checked, value: targetValue, type, name,
      },
    } = event;
    const value = type === 'checkbox' ? checked : targetValue;

    fetchDocumentTypes({
      params: { legal_type : value === 'legal' ? 'J' : 'F' },
      callback: (data) => {
        this.setState({
          type_legal_person: value === 'legal' ? 'JUR' : 'FIS',
          typeDocument: data,
          [name]: value,
        });
      }
    });

  }

  handleInputChange = (event) => {
    const {
      target: {
        checked, value: targetValue, type, name,
      },
    } = event;
    const { documentType } = this.state;
    const value = type === 'checkbox' ? checked : targetValue;
    
    const cycles = name.split('-');
    const inputName = cycles[0];
    const index = cycles[1];

    if ('last4Digits' === inputName && this.formatNumber(value)) {
      let billing_cycles = [...this.state.billing_cycles];
      let min = value;

      billing_cycles[index] = {
        id: index,
        last_four: min,
        cycleCounting: billing_cycles[index].cycleCounting,
        billing_cycle_id: billing_cycles[index].billing_cycle_id,
      };

      this.setState({ billing_cycles });
    }

    if (name === 'phoneNumberAdmin') {
        let num = this.formatPercentage(value);
        this.setState({
            [name]: (num),
        });
    }

    if (name === 'postal_code') {
      let num = this.formatPercentage(value);
      this.setState({
          [name]: (num),
      });
    }

    if (name === 'adminDocumentNumber') {
      let num;

      switch (documentType) {
        case 0:
        case 2:
          num = this.formatNumber2(value, 9, "number").replace(new RegExp("^0+(?!$)"), "")
          break;
        case 1:
          num = this.formatNumber2(value, 8, "textNum")
          break;
        case 3:
          num = num = this.formatNumber2(value, 12, "number").replace(new RegExp("^0+(?!$)"), "")
          break;
        default:
          num = value
          break;
      }

      this.setState({
        [name]: (num),
      });
    } 
    if ((name !== 'adminDocumentNumber') && (name !== 'phoneNumberAdmin') && (name !== 'postal_code')) {
      this.setState({
        [name]: value,
      });
    }
  };

  handleInputChangeAdminDocument = (event) => {
    const {
      target: {
        checked, value: targetValue, type, name,
      },
    } = event;
    const {
      adminDocumentType,
    } = this.state;

    const value = type === 'checkbox' ? checked : targetValue;

    if ((name === 'adminDocumentNumber' && adminDocumentType === 0)) {
      let num = this.formatNumber(event.target.value);
      this.setState({
        [name]: (num),
      });
    } else {
      this.setState({
        [name]: value,
      });
    }
  };

  formatNumber = (n) => {
    if (n.length < 14) {
      return n.replace(/\D/g, '')
        .replace(/([0-9]{14})$/);
    } else {
      return;
    }
  };

  formatNumber2 = (value, length, type) => {
    if (value.length <= length) {
      if(type === "number") {return value.replace(/\D/g, '')}
      if(type === "text") {return value.replace(new RegExp("[0-9]"), '')}
      if(type === "textNum") {return value.replace(new RegExp("^[a-zA-Z][0-9]"), '')}
    } else {
      return value.slice(0, length)
    }
  }
  
  formatPercentage = (n) => {
    return n.replace(/[^\D]/, '');
  };

  handleItemChange = (newValue) => {
    this.setState({ items: newValue });
  };

  handleIndustryChange = (newValue) => {
    this.setState({ industries: newValue });
  };

  handleExecutivesChange = (newValue, event) => {
    const {
      auth,
    } = this.props;
    const {
      newBankingExec,
    } = this.state;

    if (event.action === 'clear') {
      this.setState({
        executives: newValue,
        newBankingExec: [],
      });
    } else {
      if (event.action === 'remove-value' && event.removedValue.id === -1) {
        this.setState({
          executives: newValue,
          newBankingExec: [],
        });
      } else {
        if (newValue[newValue.length - 1].id === -1) {
          newBankingExec[0] = {
            id: 0,
            name: '',
            lastname: '',
            email: '',
            bank_id: auth.user.b2b.id,
          };
          this.setState({
            executives: newValue,
            newBankingExec: newBankingExec
          });
        } else {
          this.setState({
            executives: newValue
          });
        }
      }
    }
  };

  addBankingExec = () => {
    const {
      auth,
      showMessage,
      t,
    } = this.props;
    const {
      newBankingExec,
      countBankingExec,
    } = this.state;
    let count = 0,
      i = 1;
    count = countBankingExec + i;

    if (newBankingExec[newBankingExec.length - 1].email === '') {
      showMessage({
        message: <strong style={{ color: '#ffffff' }}>{t('mandatoryFieldsRequired')}</strong>,
        config: TOAST_CONFIG.ERROR,
      });
    } else {
      let variable = {
        id: count,
        name: '',
        lastname: '',
        email: '',
        bank_id: auth.user.b2b.id,
      };
      this.state.newBankingExec.push(variable);
    }
    this.setState({
      countBankingExec: count
    });
  };

  deleteBankingExec = (index) => {
    const {
      newBankingExec,
    } = this.state;
    this.setState(newBankingExec.splice(index, 1));
  };

  handleSelectChange = (newVal, label) => {
    const {
      card: { cycle: listCycle },
      fetchItems,
    } = this.props;
    const {
      categories,
    } = this.state;

    const cycles1 = label.name.split('-');
    const inputName = cycles1[0];
    const index = cycles1[1];

    if(label.name === 'category'){
      fetchItems({
        category_id: categories[newVal.value].id,
      });
    }

    if (inputName === 'cuttingCycleCard' && newVal.value !== '') {
      let billing_cycles = [...this.state.billing_cycles];
      billing_cycles[index] = {
        id: index,
        last_four: billing_cycles[index].last_four,
        cycleCounting: listCycle[newVal.value].cycle,
        billing_cycle_id: listCycle[newVal.value].id,
        billing_cycle_index: newVal.value,
      };
      this.setState({ billing_cycles });
    } else {
      this.setState({
        [label.name]: newVal.value,
      });
    }
  };

  handleRegionChange = (newVal, label) => {

    const { fetchCommunes } = this.props;
    const { provinces } = this.state;

    this.setState({
      [label.name]: newVal.value,
    });

    fetchCommunes({
      province_id: provinces[newVal.value].id,
      callback: (response) => {
        this.setState({
          cantons: response,
        });
      }
    });
  }

  handleCantonChange = (newVal, label) => {
    this.setState({
      [label.name]: newVal.value,
    });
  }

  handlePercentageChange = (event) => {
    const {
      target: {
        checked, value: targetValue, type, name,
      },
    } = event;
    const value = type === 'checkbox' ? checked : targetValue;
    this.setState({
      [name]: value,
      clientPayment: 100 - Number(value),
    });
  };

  array_executives = (executives) => {
    let variable = [];

    for (let i = 0; i < executives.length; i++) {
      if (executives[i].id !== -1) {
        variable.push(Number(executives[i].id));
      }
    }

    return variable;
  };

  createProvider = () => {
    const {
      createProvider,
      editUser,
      auth: {
        user: {
          b2b: {
            type_user,
            roles,
          },
        },
      },
      t,
      showMessage,
      deleteLead,
      fetchLeads,
      role: { list: roleList },
      router: { location: { pathname } },
      clients: { list: clientList },
      flowApproval: { list: typeFlows },
      history,
    } = this.props;
    const {
      name,
      documentType,
      documentNumber,
      email,
      password,
      address,
      percentage,
      period,
      items,
      lead,
      lead_id,
      adminName,
      adminLastname,
      adminDocumentType,
      adminDocumentNumber,
      adminPosition,
      phoneNumber,
      districts,
      district,
      executives,
      business_name,
      phoneNumberAdmin,
      newBankingExec,
      documentTypeUser,
      typeDocument,
      type_legal_person,
      street,
      cif,
      outdoor_number,
      interior_number,
      suburb,
      municipality,
      postal_code,
      authorized_by,
      industry,
      industriesList,
      user_type,
      countryCode,
      areaCode,
    } = this.state;

    if (pathname === '/create-customer') {
      if (this.validateEmptyFields()) {
        const data = {
          name,
          document_type: typeDocument[documentTypeUser].document_type ,
          document_number: documentNumber,
          email,
          password,
          user_type: 'flecha',
          agency_user_type: 'business_admin',
          role_name: roleList[0].id,
          items,
          industries: [{
            id: industriesList[industry].id,
          }],
          process_type: null,
          b2b: {
            user_type,
            type_legal_person,
            bank_id: roles[0].owner_id,
            admin: 0,
            issuing_bank: 0,
            acquiring_bank: 0,
            seller: 0,
            buyer: 1,
            address,
            admin_name: adminName,
            admin_lastname: adminLastname,
            admin_document_number: adminDocumentNumber,
            admin_document_type: documentType === '' ? '' : adminDocumentType[documentType].document_type,
            admin_position: adminPosition,
            admin_phone_number: '',
            business_name,
            cif,
            authorized_by,
          },
          phone: {
            phone_number: phoneNumber,
            country_code: countryCode,
            area_code: areaCode,
            is_main: 1,
          },
          banking_executive: this.array_executives(executives),
          new_banking_executive: newBankingExec,
          b2b_address: {
            street,
            outdoor_number,
            interior_number,
            suburb,
            municipality,
            postal_code,
            district_id: districts[district].id,
          }
        };

        createProvider({
          data,
          callback: (response) => {
            const { b2b: { id } } = response;
            if (lead) {
              deleteLead({
                params: {
                  id: lead_id,
                },
                callback: fetchLeads({
                  params: {
                    role_id: type_user === 'issuing_bank' ? 'buyer' : 'seller',
                  },
                }),
              });
            }
            showMessage({
              message: t('createClientSuccess', { name }),
              config: TOAST_CONFIG.SUCCESS,
            });
            this.resetState();
            history.push('/dashboard');
          },
        });
      } else {
        showMessage({
          message: <strong style={{ color: '#ffffff' }}>{t('emptyAllRequest')}</strong>,
          config: TOAST_CONFIG.ERROR,
        });
      }
    }

    if(pathname === '/edit-customer') {
      if (this.validateEmptyFields()) {
        const data = {
          user_type,
          id_user: clientList.buyer[0].id,
          name,
          type_legal_person,
          documentType: typeDocument[documentTypeUser].id,
          documentNumber: documentNumber,
          email,
          items,
          industries: [{
            id: industriesList[industry].id,
          }],
          admin_name: adminName,
          admin_lastname: adminLastname,
          admin_position: adminPosition,
          admin_phone_number: phoneNumberAdmin,
          percentage,
          days: this.getDaysInv(period),
          process_type: null,
          type: 'B',
          admin_document_number: adminDocumentNumber,
          admin_document_type: documentType !== -1 ? adminDocumentType[documentType].document_type : '',
          business_name,
          phone: {
            phone_number: phoneNumber,
            country_code: countryCode,
            area_code: areaCode,
            is_main: 1,
          },
          banking_executive: this.array_executives(executives),
          new_banking_executive: newBankingExec,
          b2b_address: {
            street,
            outdoor_number,
            interior_number,
            suburb,
            municipality,
            postal_code,
            district_id: districts[district].id,
          },
          cif,
          authorized_by,
        };

        editUser({
          data,
          callback: () => {
            history.push('/dashboard');
            this.resetState();
            showMessage({
              message: t('editClientSuccess', { name }),
              config: TOAST_CONFIG.SUCCESS,
            });
          },
        });
      } else {
        showMessage({
          message: <strong style={{ color: '#ffffff' }}>{t('emptyAllRequest')}</strong>,
          config: TOAST_CONFIG.ERROR,
        });
      }
    }
  };

  isEmail = n => /[a-zA-Z0-9_]+([.][a-zA-Z0-9_]+)*@[a-zA-Z0-9_]+([.][a-zA-Z0-9_]+)*[.][a-zA-Z]{1,5}/.test(n);

  validateEmptyFields = () => {
    const {
      name,
      adminName,
      adminLastname,
      documentNumber,
      email,
      adminPosition,
      phoneNumber,
      industry,
      documentTypeUser,
      street,
      outdoor_number,
      suburb,
      municipality,
      postal_code,
      district,
      countryCode,
      areaCode,
    } = this.state;

    return !(
      name === '' || 
      adminName === '' ||
      adminLastname === '' ||
      documentNumber === '' ||
      documentTypeUser === '' ||
      email === '' ||
      adminPosition === '' || countryCode === '' || areaCode === '' || phoneNumber === '' ||
      industry == -1 || postal_code == '' ||
      industry == 0 || street == '' || outdoor_number == '' ||
      suburb == '' || municipality == '' || district == -1
      );
  };

  labelType = (typeList) => {
    const {
      t,
    } = this.props;
    const types = [];
    types.push({
      name: t('dontPose')
    });
    for (let i = 0; i < typeList.length; i++) {
      types.push({
        id: typeList[i].id,
        name: t(typeList[i].name)
      });
    }
    return types;
  };

  addCuttingCycle = () => {
    const {
      countCycleCard,
    } = this.state;
    let count = 0,
      i = 1;
    count = countCycleCard + i;

    let variable = {
      id: count,
      last_four: '',
      cycleCounting: -1,
      billing_cycle_id: '',
      billing_cycle_index: '-1',
    };
    this.state.billing_cycles.push(variable);

    this.setState({
      countCycleCard: count
    });
  };

  handleOnInputChange = (newValue) => {
    this.setState({ inputValue: newValue });
    return newValue;
  };

  handleOnInputChangeExecutive = (newValue) => {
    this.setState({ inputValueExecutive: newValue });
    return newValue;
  };

  handleOnInputChangeIndustry = (newValue) => {
    this.setState({ inputValueIndustry: newValue });
    return newValue;
  };

  deleteCuttingCycle = (index) => {
    const {
      billing_cycles,
    } = this.state;
    this.setState(billing_cycles.splice(index, 1));
  };

  cancelSave = () => {
    const { history } = this.props;
    history.push('/dashboard');
  }

  messageDate = () => {
    this.setState({
      status: true,
    })
  };

  closeModal = (onToggle) => {
    onToggle();
    this.setState({
      status: false,
    })
  };

  render() {
    const {
      t,
      providers: { loading: providerLoading },
      clients: { loading: clientLoading },
      item: { list: itemList },
      flowApproval: { list: typeFlows },
      card: { cycle, list },
      auth: {user: {profile: { role }}}
    } = this.props;
    const {
      name,
      documentNumber,
      email,
      password,
      address,
      percentage,
      baseDays,
      items,
      documentType,
      discountRate,
      adminName,
      adminLastname,
      adminDocumentType,
      adminDocumentNumber,
      adminPosition,
      receiverDiscountShare,
      clientPayment,
      typeFlow,
      period,
      edit,
      hidden,
      last4Digits,
      cuttingCycleCard,
      billing_cycles,
      phoneNumber,
      provinces,
      province,
      cantons,
      canton,
      districts,
      district,
      inputValue,
      inputValueExecutive,
      executives,
      business_name,
      phoneNumberAdmin,
      exc,
      newBankingExec,
      typePerson,
      typeDocument,
      documentTypeUser,
      street,
      number,
      otherSign,
      cif,
      categories,
      category,
      industries,
      industriesList,
      inputValueIndustry,
      outdoor_number,
      interior_number,
      suburb,
      municipality,
      postal_code,
      authorized_by,
      industry,
      status,
      countryCode,
      areaCode,
    } = this.state;

    return (
      <div>
        {(providerLoading || clientLoading) && <Loading />}
        <Toggle>
          {({ onToggle, }) => (
            <Fragment>
              {  status && (
                <Modal title={t('information')} onToggle={() => this.setState({ status: false })}> {/*loading && <Loading/>*/}
                  <div className="has-text-centered">
                    <h1 className="message-date">
                      {t('createdNCL')}
                      <br/><br/>
                      {t('wishToContinue')}
                    </h1>
                    <button
                      type="button"
                      className="button custom-btn is-danger"
                      onClick={this.closeModal.bind(this, onToggle)}
                    >
                      {t('cancel')}
                    </button>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <button
                      type="button"
                      className="button custom-btn is-primary"
                      onClick={this.createProvider.bind()}
                    >
                      {t('accept')}
                    </button>
                  </div>
                </Modal>
                )
              }
              <PersonForm
                t={t}
                title={this.getTitle()}
                messageDate={this.messageDate}
                onSubmit={this.createProvider}
                handleInputChange={this.handleInputChange}
                handleInputChangeAdminDocument={this.handleInputChangeAdminDocument}
                name={name}
                noRetain={typePerson}
                handleSelectChange={this.handleSelectChange}
                handleItemChange={this.handleItemChange}
                handleExecutivesChange={this.handleExecutivesChange}
                documentTypesList={typeDocument}
                documentsEcuador={this.state.documentsEcuador}
                documentNumber={documentNumber}
                address={address}
                documentType={documentType}
                items={items}
                itemList={itemList}
                email={email}
                password={password}
                baseDays={baseDays}
                percentage={percentage}
                discountRate={discountRate}
                client
                adminName={adminName}
                adminLastname={adminLastname}
                adminDocumentType={adminDocumentType}
                adminDocumentNumber={adminDocumentNumber}
                adminPosition={adminPosition}
                handlePercentageChange={this.handlePercentageChange}
                receiverDiscountShare={receiverDiscountShare}
                clientPayment={clientPayment}
                typeFlows={this.labelType(typeFlows)}
                typeFlow={typeFlow}
                period={period}
                edit={edit}
                hidden={hidden}
                last4Digits={last4Digits}
                cuttingCycle={cycle}
                cuttingCycleCard={cuttingCycleCard}
                addCuttingCycle={this.addCuttingCycle}
                deleteCuttingCycle={this.deleteCuttingCycle}
                cycles={billing_cycles}
                phoneNumber={phoneNumber}
                provinces={provinces}
                province={province}
                handleRegionChange={this.handleRegionChange}
                handleCantonChange={this.handleCantonChange}
                cantons={cantons}
                canton={canton}
                districts={districts}
                district={district}
                handleOnInputChange={this.handleOnInputChange}
                handleOnInputChangeExecutive={this.handleOnInputChangeExecutive}
                inputValue={inputValue}
                inputValueExecutive={inputValueExecutive}
                executivesList={exc}
                executives={executives}
                business_name={business_name}
                phoneNumberAdmin={phoneNumberAdmin}
                newBankingExec={newBankingExec}
                addBankingExec={this.addBankingExec}
                deleteBankingExec={this.deleteBankingExec}
                handleInputChangeNewExc={this.handleInputChangeNewExc}
                handleInputChangeTypeDocument={this.handleInputChangeTypeDocument}
                documentTypeUser={documentTypeUser}
                street={street}
                number={number}
                otherSign={otherSign}
                cif={cif}
                cancelSave={this.cancelSave}
                categories={categories}
                category={category}
                handleOnInput={validateAlphaNumeric}
                industries={industries}
                industriesList={industriesList}
                handleIndustryChange={this.handleIndustryChange}
                inputValueIndustry={inputValueIndustry}
                handleOnInputChangeIndustry={this.handleOnInputChangeIndustry}
                role={role}
                outdoor_number={outdoor_number}
                interior_number={interior_number}
                suburb={suburb}
                municipality={municipality}
                postal_code={postal_code}
                authorized_by={authorized_by}
                industry={industry}
                countryCode={countryCode}
                areaCode={areaCode}
              />
            </Fragment>
          )}
        </Toggle>
      </div>
    );
  }
}

CreateCustomer.propTypes = {
  providers: PropTypes.shape(Object).isRequired,
  auth: PropTypes.shape(Object).isRequired,
  documentType: PropTypes.shape(Object).isRequired,
  item: PropTypes.shape(Object).isRequired,
  location: PropTypes.shape(Object).isRequired,
  fetchDocumentTypes: PropTypes.func.isRequired,
  fetchItems: PropTypes.func.isRequired,
  deleteLead: PropTypes.func.isRequired,
  fetchLeads: PropTypes.func.isRequired,
  getExecutive: PropTypes.func.isRequired,
  createProvider: PropTypes.func.isRequired,
  createPaymentCondition: PropTypes.func.isRequired,
  getProviders: PropTypes.func.isRequired,
  editUser: PropTypes.func.isRequired,
  showMessage: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  router: PropTypes.shape(Object).isRequired,
  clients: PropTypes.shape(Object).isRequired,
  history: PropTypes.shape(Object).isRequired,
};


const mapStateToProps = ({
                          providers,
                          documentType,
                          auth,
                          item,
                          role,
                          router,
                          clients,
                          flowApproval,
                          card,
                        }) => ({
  providers,
  documentType,
  auth,
  item,
  role,
  router,
  clients,
  flowApproval,
  card,
});
export default compose(
  connect(mapStateToProps, {
    ...documentTypeActions,
    ...providersActions,
    ...paymentConditionsActions,
    ...itemActions,
    ...leadActions,
    ...roleActions,
    ...clientsActions,
    ...flowApprovalActions,
    ...cardActions,
  }),
  withNamespaces(),
)(CreateCustomer);