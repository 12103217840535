import types from './types';
import { authTypes } from '../auth';
import { initialState } from '../../../App';

const transactions = (state = {}, action) => {
  switch (action.type) {
    case authTypes.CLEAR: {
      return {
        ...state, ...initialState.transactions,
      };
    }
    case types.START_FETCH: {
      return {
        ...state,
        loading: true,
      };
    }
    case types.END_FETCH: {
      const { error, payload } = action;
      return {
        ...state,
        loading: false,
        error: error ? payload : null,
      };
    }
    case types.UPDATE_TRANSACTIONS: {
      const { payload: { transactions, total } } = action;
      return {
        ...state,
        total,
        list: transactions,
      };
    }
    case types.UPDATE_CHARGE: {
      const { payload: { charge } } = action;
      return {
        ...state,
        charge,
      };
    }
    case types.UPDATE_BALANCE: {
      const { payload: { currency, balance } } = action;
      return {
        ...state,
        currency,
        balance,
      };
    }
    case types.SET_TRANSACTIONS_RECEIVER: {
      const { list, pending_invoices, reject_invoices, emit_invoices, in_process_invoices } = action.payload;
      return {
        ...state,
        pending_invoices,
        reject_invoices,
        emit_invoices,
        in_process_invoices,
        receiver: list,
      };
    }
    case types.SET_TRANSACTIONS_SENDER: {
      const { list, pending_invoices, in_process_invoices } = action.payload;
      return {
        ...state,
        pending_invoices,
        in_process_invoices,
        sender: list,
      };
    }
    case types.SAVE_TRANSACTION: {
      const { list } = action.payload;
      return {
        ...state,
        list,
      };
    }
    case types.SAVE_STAT_TRANSACTION: {
      const { list } = action.payload;
      return {
        ...state,
        list,
      };
    }
    default:
      return state;
  }
};

export default transactions;
