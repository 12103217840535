import { createAction } from 'redux-actions';
import types from './types';

const startFetch = createAction(types.START_FETCH);
const endFetch = createAction(types.END_FETCH);
const endError = createAction(types.END_ERROR);
const savedCredentials = createAction(types.SAVED_CREDENTIALS);
const getCredentials = createAction(types.GET_CREDENTIALS);
const savedData = createAction(types.SAVED_DATA);
const updateCredentials = createAction(types.UPDATE_CREDENTIALS);

const showMessage = createAction(types.MESSAGE,
  ({ message }) => ({
    message,
  }),
  ({ config }) => ({
    config,
  })
);

export default {
  startFetch,
  endFetch,
  endError,
  showMessage,
  savedCredentials,
  getCredentials,
  savedData,
  updateCredentials,
};