const START_FETCH = 'currency/START_FETCH';
const END_FETCH = 'currency/END_FETCH';
const END_ERROR = 'currency/END_ERROR';
const API_CALL = 'currency/API_CALL';
const MESSAGE = 'currency/MESSAGE';
const GET_CURRENCY = 'currency/GET_CURRENCY';
const GET_CURRENCIES = 'currency/GET_CURRENCIES';

export default {
  START_FETCH,
  END_FETCH,
  END_ERROR,
  API_CALL,
  MESSAGE,
  GET_CURRENCY,
  GET_CURRENCIES,
};