import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';
import { withNamespaces } from 'react-i18next';

/**
 * Created by Feedback Software on 26/10/18.
 * @param t it's a function for call a translation of the label
 * @param totalPages if is pass by params it means that must be added the pagination nav
 * @param currentPage manage de actual page in pagination
 * @param onChangePage manage the change of the page in the pagination, must change currentPage
 * @returns {*}
 */

const NavPagination = ({
  t, totalPages, currentPage, onChangePage,
}) => (
  <div className="NavPagination">
    <nav
      className="pagination is-centered"
      role="navigation"
      aria-label="pagination"
    >
      {totalPages > 1 && currentPage > 0
        && (
          <button
            className="button pagination-previous"
            onClick={() => {
              onChangePage(currentPage - 1);
            }}
          >
            {t('previous_page')}
          </button>
        )}
      {totalPages > currentPage + 1
        && (
          <button
            className="button pagination-next"
            onClick={() => {
              onChangePage(currentPage + 1);
            }}
          >
            {t('nextPage')}
          </button>
        )}
      <ul className="pagination-list">
        {[...Array(totalPages)].map((e, i) => (
          <li
            key={e}
          >
            <button
              className={`button pagination-link link-${i} ${(currentPage === i) && 'is-current'}`}
              aria-label="Goto page 1"
              onClick={() => {
                onChangePage(i);
              }}
            >
              {i + 1}
            </button>
          </li>
        ))}
      </ul>
    </nav>
  </div>
);

NavPagination.propTypes = {
  t: PropTypes.func.isRequired,
  totalPages: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
};

export default withNamespaces()(NavPagination);
