import React from 'react';
import PropTypes from 'prop-types';

const Iframe = ({
                  src,
                  //height,
                  width,
                  title,
                }) => (
  <iframe
    src={src}
    /*height={height}*/
    title={title}
    width={width} style={{
    height: '100%',
    border: 'none'
  }}
  />
);

Iframe.propTypes = {
  src: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
};

export default Iframe;
