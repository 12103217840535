import { bankActions, bankTypes } from '../ducks/bank';

const bankData = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== bankTypes.FETCH_BANK) {
    return;
  }

  const {
    params: {
      issuing_bank = false,
      acquiring_bank = false,
      main_bank = false,
      admin = false,
      id = '',
      user = '',
      document_number = '',
      email = '',
      role = '',
      general_admin = '',
    } = {},
    callback = () => { },
  } = action.payload;

  dispatch({
    type: bankTypes.API_CALL,
    payload: {
      config: {
        method: 'GET',
        url: `b2b/get-bank-users?id=${id}&general_admin=${general_admin}&main_bank=${main_bank}&issuing_bank=${issuing_bank}&acquiring_bank=${acquiring_bank}&admin=${admin}&name=${user}&document_number=${document_number}&email=${email}&role=${role}`,
      },
      authorization: true,
      onStart: bankActions.startFetch,
      onComplete: ({ data: responseData }) => {
        const { success, data } = responseData;
        if (success) {
          dispatch(bankActions.updateBanks({
            list: data,
          }));
          callback();
        }
      },
      onEnd: bankActions.endFetch,
    },
  });
};

export default [bankData];
