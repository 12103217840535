import React, { Component, Fragment } from 'react';
import { compose } from 'redux';
import './styles.scss';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Loading,
  Panel,
  TableList,
  Toggle,
} from '../../components';
import { transactionsActions } from '../../state/ducks/transactions';
import AccountRow from './components/AccountRow';
import Modal from '../../components/Modal';
import { providersActions } from '../../state/ducks/providers';

const ItemCard = ({ name, value }) => (
  <li>
    <b>
      {`${name}:`}
    </b>
    {value}
  </li>
);

ItemCard.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

class Customers extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const { listTransactionsReceiver, auth: { user: { user_id } } } = this.props;
    listTransactionsReceiver({ params: { user_id } });
  }

  advancePayment = (transaction_id, condition_id, onToggle) => {
    const { advanceInvoice, listTransactionsReceiver, auth: { user: { user_id } } } = this.props;
    const data = {
      transaction_id,
      condition_id,
    };
    advanceInvoice({
      data,
      callback: () => {
        listTransactionsReceiver({ params: { user_id } });
        onToggle();
      },
    });
  };

  render() {
    const {
      t,
      transactions: { receiver, loading },
      auth: {
        user: {
          b2b: {
            advance_condition: {
              days,
              installments,
              percentage,
              id,
            },
          },
        },
      },
    } = this.props;
    return (
      <div>
        {loading && <Loading />}
        <Toggle>
          {({ toggled, onToggle, toggleProps }) => (
            <Fragment>
              {
                toggled
                && (
                  <Modal onToggle={onToggle}>
                    <TableList title={t('newPaymentCondition')}>
                      <div className="content">
                        <ul>
                          <ItemCard
                            name={t('creditPeriod')}
                            value={`${days} días`}
                          />
                          <ItemCard
                            name={t('numberInstallments')}
                            value={`${installments}`}
                          />
                          <ItemCard
                            name={t('interestRate')}
                            value={`${percentage} %`}
                          />
                        </ul>
                        <Link
                          className="button is-primary is-pulled-right"
                          to="/customers"
                          onClick={() => this.advancePayment(toggleProps.id,
                            id, onToggle)}
                        >
                          <i className="fas fa-check mr-8px " aria-hidden="true" />
                          {t('confirm')}
                        </Link>
                      </div>
                    </TableList>
                  </Modal>
                )
              }
              <div className="columns">
                <div className="column is-full">
                  <Panel headingText={t('listPayments')}>
                    <Link
                      className="button is-primary is-pulled-right"
                      to="/providers"
                    >
                      <i className="fas fa-plus mr-8px " aria-hidden="true" />
                      {t('loadInvoice')}
                    </Link>
                    <table className="table">
                      <thead>
                        <tr>
                          <th>{t('transactionDate')}</th>
                          <th>{t('totalPay')}</th>
                          <th>{t('name')}</th>
                          <th>{t('transferState')}</th>
                          <th>{t('action')}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {receiver.map(transaction => (
                          <AccountRow
                            key={transaction.id}
                            {...transaction}
                            link="/transaction-details"
                            openDetail={() => {
                              onToggle(transaction);
                            }}
                          />
                        ))}
                      </tbody>
                    </table>
                  </Panel>
                </div>
              </div>
            </Fragment>
          )}
        </Toggle>
      </div>

    );
  }
}

Customers.propTypes = {
  t: PropTypes.shape(Object).isRequired,
  transactions: PropTypes.shape(Object).isRequired,
  auth: PropTypes.shape(Object).isRequired,
  advanceInvoice: PropTypes.func.isRequired,
  listTransactionsReceiver: PropTypes.func.isRequired,
};

const mapStateToProps = ({ transactions, auth }) => (
  {
    transactions,
    auth,
  }
);
export default compose(
  connect(mapStateToProps, { ...transactionsActions, ...providersActions }),
  withNamespaces(),
)(Customers);
