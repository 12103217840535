import { createAction } from 'redux-actions';
import types from './types';

const startFetch = createAction(types.START_FETCH);
const endFetch = createAction(types.END_FETCH);
const endError = createAction(types.END_ERROR);
const getLoan = createAction(types.GET_LOAN);
const saveLoan = createAction(types.SAVE_LOAN);
const getReference = createAction(types.GET_REFERENCE);
const saveReference = createAction(types.SAVE_REFERENCE);

const showMessage = createAction(types.MESSAGE,
  ({ message }) => ({
    message,
  }),
  ({ config }) => ({
    config,
  }));

export default {
  startFetch,
  endFetch,
  endError,
  showMessage,
  getLoan,
  saveLoan,
  getReference,
  saveReference,
};