import { createAction } from 'redux-actions';
import types from './types';

const startFetch = createAction(types.START_FETCH);
const endFetch = createAction(types.END_FETCH);
const fetchRoles = createAction(types.FETCH_ROLE);
const fetchTypesRole = createAction(types.FETCH_TYPES_ROLE);
const updateRoles = createAction(types.UPDATE_ROLE);
const updateTypesRole = createAction(types.UPDATE_TYPES_ROLE);
const addRole = createAction(types.ADD_ROLE);
const saveRole = createAction(types.SAVE_ROLE);
const deleteRole = createAction(types.DELETE_ROLE);
const removeRole = createAction(types.REMOVE_ROLE);
const editRole = createAction(types.EDIT_ROLE);
const ownerId = createAction(types.OWNER_ID);
const rolesSupport = createAction(types.ROLES_SUPPORT);
const saveManual = createAction(types.SAVE_MANUAL);
const getManuals = createAction(types.GET_MANUALS);
const updateManual = createAction(types.UPDATE_MANUAL);

const showMessage = createAction(types.MESSAGE,
  ({ message }) => ({
    message,
  }),
  ({ config }) => ({
    config,
  }));

export default {
  startFetch,
  endFetch,
  showMessage,
  fetchRoles,
  fetchTypesRole,
  updateRoles,
  updateTypesRole,
  addRole,
  saveRole,
  deleteRole,
  removeRole,
  editRole,
  ownerId,
  rolesSupport,
  saveManual,
  getManuals,
  updateManual,
};
