import { financingProductsActions, financingProductsTypes } from '../ducks/financingProducts';

const url = '/b2b-payment-products';
const fetchFinancingProducts = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== financingProductsTypes.FETCH_FINANCING_PRODUCTS) {
    return;
  }
  const {
    pageSize,
  } = action.payload || 0;
  dispatch({
    type: financingProductsTypes.API_CALL,
    payload: {
      config: {
        method: 'GET',
        url: `${url}?pageSize=${pageSize}`,
      },
      authorization: false,
      onStart: financingProductsActions.startFetch,
      onComplete: ({ data: responseData }) => {
        const { success, data } = responseData;
        if (success) {
          dispatch(financingProductsActions.updateFinancingProducts({
            data,
          }));
        }
      },
      onEnd: financingProductsActions.endFetch,
    },
  });
};
const addFinancingProducts = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== financingProductsTypes.ADD_FINANCING_PRODUCTS) {
    return;
  }
  const {
    data,
    callback = () => {
    },
  } = action.payload;
  dispatch({
    type: financingProductsTypes.API_CALL,
    payload: {
      config: {
        method: 'POST',
        url,
        data,
      },
      authorization: false,
      onStart: financingProductsActions.startFetch,
      onComplete: ({ data: responseData }) => {
        const { rspData } = responseData;
        callback(rspData);
      },
      onEnd: financingProductsActions.endFetch,
    },
  });
};
const editFinancingProducts = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== financingProductsTypes.EDIT_FINANCING_PRODUCTS) {
    return;
  }
  const {
    id,
    data,
    callback = () => {
    },
  } = action.payload;
  dispatch({
    type: financingProductsTypes.API_CALL,
    payload: {
      config: {
        method: 'PUT',
        url: `${url}/${id}`,
        data,
      },
      authorization: false,
      onStart: financingProductsActions.startFetch,
      onComplete: ({ data: responseData }) => {
        const { data: rspData } = responseData;
        callback(rspData);
      },
      onEnd: financingProductsActions.endFetch,
    },
  });
};

const removeFinancingProducts = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== financingProductsTypes.DELETE_FINANCING_PRODUCT) {
    return;
  }
  const {
    data: { id },
    callback = () => {
    },
  } = action.payload;
  dispatch({
    type: financingProductsTypes.API_CALL,
    payload: {
      config: {
        method: 'DELETE',
        url: `${url}/${id}`,
      },
      authorization: false,
      onStart: financingProductsActions.startFetch,
      onComplete: () => {
        callback();
        dispatch(financingProductsActions.removeFinancingProduct({ id }));
      },
      onEnd: financingProductsActions.endFetch,
    },
  });
};

export default [
  fetchFinancingProducts,
  addFinancingProducts,
  editFinancingProducts,
  removeFinancingProducts,
];
