import React, { Component, Fragment } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import PropTypes from 'prop-types';
import { Currency, Loading, Panel, TableSimple, Toggle, } from '../../components';
import { roleActions } from '../../state/ducks/role';
import { authActions } from '../../state/ducks/auth';
import { productActions } from '../../state/ducks/product';
import TextInput from '../../components/TextInput';
import { TOAST_CONFIG } from '../../config/constants';
import { paymentConditionsActions } from '../../state/ducks/paymentConditions';
import { formatCurrencyCl, numberFilter, onInputOnlyNumbers } from '../../util';

class ProductList extends Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  getInitialState = () => ({
    id: '',
    name: '',
    name_product: '',
    description: '',
    currency: '',
    amount: '',
    benefits: '',
  });

  componentDidMount() {
    const {
      getContractedProduct,
      router: { location: { pathname } },
      auth: {
        user: {
          b2b: { id },
        },
      },
    } = this.props;
    if (pathname === '/contracted-products') {
      getContractedProduct({});
    }
  }

  resetState = () => {
    this.setState(this.getInitialState());
  };

  unsubscribe = (id) => {
    const {
      history,
      t,
      showMessage,
      product: { contracted },
      requestUnsubscribeProduct,
    } = this.props;
    requestUnsubscribeProduct({
      data: {
        id: id,
      },
      callback: (resp) => {
        showMessage({
          message: t('requestSentProduct'),
          config: TOAST_CONFIG.SUCCESS,
        });
        this.resetState();
        history.push('/dashboard');
      }
    });
  };

  getGridSettings = (onToggle) => {
    const {
      t,
      product: { list },
      getProduct,
      history,
    } = this.props;
    const columns = [
      {
        expander: true,
        show: false,
      },
      {
        id: 'product', // Required because our accessor is not a string
        Header: t('product'),
        accessor: d => `${d.product.name} `,
      },
      {
        id: 'company', // Required because our accessor is not a string
        Header: t('company'),
        accessor: d => t('assistCard'),
      },
      {
        id: 'amount', // Required because our accessor is not a string
        Header: t('annualQuota'),
        accessor: d => d.product.amount,
        Cell: cellInfo => (
          <Currency value={cellInfo.original.product.amount} />
        ),
        sortMethod: (a, b) => Number(a)-Number(b),
        filterMethod: (filter, row) => numberFilter(filter, row),
        Filter: ({ filter, onChange }) =>
          <input
            onInput={onInputOnlyNumbers}
            onChange={event => onChange(event.target.value)}
            style={{ width: '100%' }}
            value={filter ? filter.value : ''}
          />
      },
      {
        id: 'seeDetail', // Required because our accessor is not a string
        Header: t('seeDetail'),
        accessor: d => `${d.product_id} `,
        Cell: cellInfo => (
          <table>
            <tbody>
            <tr>
              <td>
                <button
                  className="button is-primary is-inverted"
                  onClick={() => {
                    getProduct({
                      params: {
                        id: cellInfo.original.product_id,
                      },
                      callback: () => {
                        history.push('/product-detail');
                      }
                    });
                  }}
                >
                  <span className="icon" title={t('detail')}>
                    <i className="far fa-file-alt"/>
                  </span>
                </button>
              </td>
            </tr>
            </tbody>
          </table>
        ),
      },
    ];
    const data = list;
    const title = t('contractedProducts');
    return {
      columns,
      data,
      title,
    };
  };

  handleInputChange = (event) => {
    const {
      target: {
        checked, value: targetValue, type, name,
      },
    } = event;
    const value = type === 'checkbox' ? checked : targetValue;
    this.setState({
      [name]: value,
    });
  };

  render() {
    const {
      t,
      product: { loading: productLoading, contracted },
      router: { location: { pathname } },
      role: { loading: roleLoading },
      paymentConditions: { loading: paymentConditionLoading },
    } = this.props;
    const loading = paymentConditionLoading || roleLoading || productLoading;
    return (
      <Toggle>
        {({ toggled, onToggle }) => {
          const { title, columns, data } = this.getGridSettings(onToggle);
          return (
            <div className="dashboard">
              {loading && <Loading/>}
                <Fragment>
                  {
                    pathname === '/product-detail'
                    && (
                      <Panel headingText={t('detail')}>
                        <div className="field is-horizontal">
                          <div className="field-body columns">
                            <div className="column">
                              <TextInput
                                name="name_product"
                                label={t('name')}
                                value={contracted.name}
                                onChange={this.handleInputChange}
                                placeholder="name"
                                type="text"
                                controlClasses="is-expanded"
                                //min="0"
                                disabled={true}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="field is-horizontal">
                          <div className="field-body columns">
                            <div className="column">
                              <TextInput
                                name="description"
                                label={t('description')}
                                value={contracted.description}
                                onChange={this.handleInputChange}
                                placeholder="description"
                                type="text"
                                controlClasses="is-expanded"
                                //min="0"
                                disabled={true}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="field is-horizontal">
                          <div className="field-body columns">
                            <div className="column">
                              <TextInput
                                name="currency"
                                label={t('currency')}
                                value={contracted.currency_id}
                                onChange={this.handleInputChange}
                                placeholder="currency"
                                type="text"
                                controlClasses="is-expanded"
                                //min="0"
                                disabled={true}
                              />
                            </div>
                            <div className="column">
                              <TextInput
                                name="amount"
                                label={t('amount')}
                                value={formatCurrencyCl(contracted.amount)}
                                onChange={this.handleInputChange}
                                placeholder="amount"
                                type="text"
                                controlClasses="is-expanded has-icons-left"
                                inputClasses="has-text-right"
                                iconPosition=""
                                icon=""
                                disabled={true}
                              />
                            </div>
                          </div>
                        </div>
                        {Array.isArray(contracted.details) && (
                          <div>
                            <div className="field is-horizontal title is-3 text-black">
                              {t('benefits')}
                            </div>
                            <div className="field is-horizontal">
                              <div className="field-body columns">
                                <div className="column">
                                  <ul>
                                    {contracted.details.map((value, index) => (
                                      <li key={index}>
                                        <p>
                                          <label>- {value.description} </label>
                                        </p>
                                      </li>
                                    ))}
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                        <div className="field is-horizontal"
                             style={{ display: (contracted.paymentCondition.request_unsubscribe_product === 1 ? '' : 'none') }}>
                          <div className="field-body columns">
                            <div className="column is-full" style={{ textAlign: 'right' }}>
                              <strong><label>{t('waitingForApproval')}</label></strong>
                            </div>
                          </div>
                        </div>
                        <div className="field is-horizontal">
                          <div className="field-body columns">
                            <div className="column" align="right">
                              <button
                                type="button"
                                className="button is-primary"
                                onClick={this.unsubscribe.bind(this, contracted.paymentCondition.id)}
                                disabled={contracted.paymentCondition.request_unsubscribe_product === 1}
                              >
                                {t('requestUnsubscribe')}
                              </button>
                            </div>
                          </div>
                        </div>
                      </Panel>
                    )
                  }
                  {data && pathname === '/contracted-products'
                  && (
                    <div className="column is-full">
                      <Panel headingText={title}>
                        <TableSimple data={data} columns={columns}/>
                      </Panel>
                    </div>
                  )}
                </Fragment>
            </div>
          );
        }}
      </Toggle>
    );
  }
}

ProductList.propTypes = {
  t: PropTypes.func.isRequired,
  auth: PropTypes.shape(Object).isRequired,
};


const mapStateToProps = ({ paymentConditions, product, router, role, auth }) => (
  {
    paymentConditions,
    role,
    auth,
    product,
    router,
  }
);
export default compose(
  connect(mapStateToProps, { ...productActions, ...roleActions, ...authActions, ...paymentConditionsActions }),
  withNamespaces(),
)(ProductList);