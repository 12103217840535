import React, { useState, useEffect, useRef, useMemo, useCallback } from "react"
import { withNamespaces } from "react-i18next"
import ReactTable from "react-table"
import PropTypes from "prop-types"
import "./styles.scss"

/**
 * Table component - Built based on react-table V6 - https://www.npmjs.com/package/react-table-v6
 * @param {Object} props TextInput props
 * @param {Function} props.t function for translating words
 * @param {Array<Object>} props.data array with the data to show (it comes from gridSettings())
 * @param {Array<Object>} props.columns array for defining table columns (it comes from gridSettings())
 * @param {Array<Object>} [props.subComponent] By adding a SubComponent props, you can easily add an expansion level to all root-level rows - https://www.npmjs.com/package/react-table-v6#sub-tables-and-sub-components
 * @param {Boolean} [props.hasHeavyData] define if the table will manage heavy data and set its properties
 * @param {Function} [props.onFetchData] function for handling requests on OnPageChange & OnPageSizeChange events
 * @param {Number} [props.totalRecords] set table total pages (If hasHeavyData === true, totalRecords is required)
 * @returns {*}
 * @constructor
 */
const TableSimple = ({
  t,
  data,
  columns,
  subComponent,
  reff,
  filterable,
  hasHeavyData, // boolean - validador en caso de tener que paginar
  onFetchData, // func. - función para manejar la petición
  totalRecords, // number - total de resultados p/simular la qty de páginas totales
}) => {
  const [tableState, setTableState] = useState({
    pageIndex: 0,
    pageSize: 10,
    order: [],
    filtered: [],
  })

  const {pageSize, order, filtered } = tableState
  // Use a ref to track whether the component is mounted
  const isMountedRef = useRef(true)

  useEffect(() => {
    // Skip the effect during the initial mount
    if (isMountedRef.current) {
      isMountedRef.current = false
      return
    }
    if (hasHeavyData) {
      // action to be executed when the user stopped typing
      const handleDebouncedInput = setTimeout(() => {
        onFetchData(pageSize, 0, filtered, order)
        setTableState({ ...tableState, pageIndex: 0 })
      }, 750)
      return () => {
        // Cleanup the timeout on component unmount or when filtered changes
        clearTimeout(handleDebouncedInput)
      }
    }
  }, [filtered])

  const onPageChange = (currentPageIndex) => {
    onFetchData(pageSize, currentPageIndex, filtered, order)
    setTableState({
      ...tableState,
      pageIndex: currentPageIndex,
    })
  }

  const onPageSizeChange = (currentPageSize) => {
    /**
     * Define how many results would be available based on pages qty and page size
     * @type {Number}
     */
    // const totalResults = pageSize * (pageIndex + 1)
    // const pageSizeDecrease = currentPageSize < pageSize
    /**
     * Define the page to fetch and set the current page to show in the table
     * @returns {Number}
     */
    // const page = () => {
    //   if (pageSizeDecrease) {
    //     return Math.floor(totalResults / currentPageSize) - 1
    //   } else {
    //     if (currentPageSize >= totalResults) {
    //       return 0
    //     } else {
    //       return Math.floor(totalResults / currentPageSize)
    //     }
    //   }
    // }

    onFetchData(currentPageSize, 0, filtered, order)

    setTableState({
      ...tableState,
      pageSize: currentPageSize,
      pageIndex: 0, //page(),
    })
  }

  const handleOrder = (sort) => {
    onFetchData(pageSize, 0, filtered, sort)
    setTableState({
      ...tableState,
      order: sort,
    })
  }

  return (
    <div className="TableSimple">
      <ReactTable
        data={data}
        columns={columns}
        filterable={filterable}
        filtered={filtered} // value para los inputs de filtros (autocontrolados)
        onFilteredChange={(filtered) =>
          setTableState({ ...tableState, filtered })
        } // handler para el "onChange" de los filtros
        defaultFilterMethod={(filter, row) =>
          String(row[filter.id])
            .toLowerCase()
            .includes(filter.value.toLowerCase())
        }
        defaultPageSize={10}
        className="-highlight"
        previousText={t("previous")}
        nextText={t("next")}
        loadingText={t("loading")}
        noDataText={t("noRowsFound")}
        pageText={t("page")}
        ofText={t("of")}
        rowsText={t("rows")}
        SubComponent={subComponent}
        ref={reff}
        //Props for heavy data tables
        onPageChange={hasHeavyData ? onPageChange : undefined} // handler para el "onChange" de las pag.
        onPageSizeChange={hasHeavyData ? onPageSizeChange : undefined} // handler para el "onChange" de las filas
        pages={
          hasHeavyData
            ? Math.ceil(totalRecords / tableState.pageSize) // Simulamos el total de pag. disponibles
            : undefined
        }
        page={hasHeavyData ? tableState.pageIndex : undefined} // Definimos el número de página
        manual={hasHeavyData ? true : false} // bool para poder definir el número de página
        onSortedChange={hasHeavyData ? handleOrder : undefined}
      />
    </div>
  )
}

TableSimple.propTypes = {
  t: PropTypes.func.isRequired,
  data: PropTypes.arrayOf(Object).isRequired,
  columns: PropTypes.arrayOf(Object).isRequired,
  subComponent: PropTypes.func,
  reff: PropTypes.shape(Object),
  hasHeavyData: PropTypes.bool,
}

TableSimple.defaultProps = {
  subComponent: () => {},
  reff: null,
  filterable: true,
  hasHeavyData: false,
}

export default withNamespaces()(TableSimple)
