import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';
import { Link } from 'react-router-dom';
import { withNamespaces } from 'react-i18next';
import { SubMenu } from '../index';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { statsActions } from '../../../../../../state/ducks/stats';

class Menu extends React.Component {
  constructor(props) {
    super(props);
    this.showDropdownMenu = this.showDropdownMenu.bind(this);
  }

  showDropdownMenu(e) {
    const { setSelected, url, selected } = this.props;
    const { node } = e.nativeEvent.target.dataset;
    node !== "submenu" ? setSelected(url === selected ? '' : url) : setSelected('');
  }

  componentDidMount() {
    const {
      pathname,
      setSelected,
    } = this.props

    pathname.includes('/dashboard') && setSelected('/dashboard')
  }

  render() {
    const {
      t,
      pathname,
      url,
      icon,
      name,
      submenu,
      isHidden,
      selected,
      auth: { user: { b2b: { confirm_information, type_user, registered_card, saved_credentials, roles, pending_payment_condition } } },
      stats: { payment_request_pending },
    } = this.props;
    let activeSubmenu = false;
    let iconMenu = '';
    if (submenu !== null) {
      iconMenu = <i className="icon icon-sub fa fa-angle-down" aria-hidden="true" />;
      const cantSubMenu = submenu.items.length;
      for (let i = 0; i < cantSubMenu; i += 1) {
        if (pathname === submenu.items[i].url) {
          activeSubmenu = true;
        }
      }
    }
    if (isHidden) {
      return null;
    }
    return (
      // eslint-disable-next-line max-len
      // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions, jsx-a11y/click-events-have-key-events
      <li
        className={
          `${pathname === url ? 'active' : ''} ${activeSubmenu ? 'active-sub' : ''} ${submenu !== null ? 'has-submenu' : ''} ${selected === url ? 'sub-open' : ''}`
        }
        onClick={this.showDropdownMenu}
        >
        <Link
          to={ (
            confirm_information === 0 && type_user === 'buyer')
            ? '/confirm-customer' 
            : (confirm_information === 0 && type_user === 'seller')
            ? '/confirm-provider'
            : url
          }
          onClick={() => {
            if (pathname === url) {
              // eslint-disable-next-line no-undef
              window.location.reload();
            }
          }}
        >
          <div className="menuContent">
            <i className={`section-icon ${icon}`} aria-hidden="true"/>
            <div className="notification-request">
              {payment_request_pending > 0 && icon === 'fas fa-clipboard-list' && (type_user === 'buyer' || type_user === 'seller' || type_user === 'seller_assist_card')
              && (
                <span className="number-request">{payment_request_pending}</span>
              )}
            </div>
            <span className="menu-text">{t(name)}</span>
            {iconMenu}
          </div>
        </Link>

        {type_user === 'seller' && (
          submenu && ((confirm_information !== 0 && Object.keys(pending_payment_condition).length === 0)
            || (type_user !== 'seller') || ((type_user === 'seller') && (roles[0].owner_id !== null))) && (
            <SubMenu
              items={submenu.items}
              open={selected === url}
              pathname={pathname}
            />)
        )}
        {type_user === 'buyer' && (
          submenu && ((confirm_information !== 0 && Object.keys(pending_payment_condition).length === 0) || (type_user !== 'buyer') || ((type_user === 'buyer') && (roles[0].owner_id !== null))) && (
            <SubMenu
              items={submenu.items}
              open={selected === url}
              pathname={pathname}
            />)
        )}
        {(type_user !== 'seller' && type_user !== 'buyer') && (
          submenu && (
            <SubMenu
              items={submenu.items}
              open={selected === url}
              pathname={pathname}
            />)
        )}
      </li>
    );
  }
}

Menu.propTypes = {
  t: PropTypes.func.isRequired,
  setSelected: PropTypes.func.isRequired,
  pathname: PropTypes.string.isRequired,
  selected: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  isHidden: PropTypes.bool.isRequired,
  submenu: PropTypes.shape(Object),
};

Menu.defaultProps = {
  submenu: null,
};
const mapStateToProps = ({ stats, auth }) => ({
  stats,
  auth
});

export default compose(
  connect(mapStateToProps, {
    ...statsActions,
  }),
  withNamespaces(),
)(Menu);
