/**
 * Created by Feedback Software on 29/10/18.
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import Panel from '../../components/Panel';
import { transactionsActions } from '../../state/ducks/transactions';
import './styles.scss';

class Deposit extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const { checkBalance } = this.props;
    checkBalance();
  }

  render() {
    const {
      auth: { account: { id, name, account_number } },
      transactions: { currency, balance },
    } = this.props;
    return (
      <div className="Deposit">
        <Panel>
          <table className="table">
            <tbody>
              <tr key={id}>
                <td>
                  {name}
                  <br />
                  <span className="text-verde">{account_number}</span>
                </td>
                <td>{`${currency} ${balance}`}</td>
              </tr>
            </tbody>
          </table>
        </Panel>
        <Panel headingText="deposit">
          <div className="instructions">
            <p>
              {'Para depositar dinero en tu cuenta, acércate a uno de nuestros Corresponsales e indica tu número telefónico para identificarte. Luego entrega el dinero en efectivo. El dinero estará inmediatamente disponible en tu cuenta'}
            </p>
          </div>
        </Panel>
      </div>
    );
  }
}

Deposit.propTypes = {
  auth: PropTypes.shape(Object).isRequired,
  transactions: PropTypes.shape(Object).isRequired,
  checkBalance: PropTypes.func.isRequired,
};

const mapStateToProps = ({ auth, transactions }) => ({
  auth,
  transactions,
});

export default compose(connect(mapStateToProps, { ...transactionsActions }), withNamespaces())(
  Deposit,
);
