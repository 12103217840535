import { currencyActions, currencyTypes } from '../ducks/currency';

const getCurrency = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== currencyTypes.GET_CURRENCY) {
    return;
  }

  const {
    callback = () => {
    },
  } = action.payload;
  dispatch({
    type: currencyTypes.API_CALL,
    payload: {
      config: {
        method: 'GET',
        url: `b2b-currency/get-currencies`,
      },
      authorization: true,
      onStart: currencyActions.startFetch,
      onComplete: ({ data: { success, data } }) => {
        if (success) {
          callback(data);
          dispatch(currencyActions.getCurrencies(data));
        }
      },
      onEnd: currencyActions.endFetch,
    },
  });
};

export default [getCurrency];