import types from './types';
import { authTypes } from '../auth';
import { initialState } from '../../../App';

const financingProducts = (state = {}, action) => {
  switch (action.type) {
    case authTypes.CLEAR: {
      return {
        ...state, ...initialState.financingProducts,
      };
    }
    case types.START_FETCH: {
      return {
        ...state,
        loading: true,
      };
    }
    case types.END_FETCH: {
      const { error, payload } = action;
      return {
        ...state,
        loading: false,
        error: error ? payload : null,
      };
    }
    case types.UPDATE_FINANCING_PRODUCTS: {
      const { data } = action.payload;
      return {
        ...state,
        list: data,
      };
    }
    case types.REMOVE_FINANCING_PRODUCT: {
      const { id } = action.payload;
      return {
        ...state,
        list: [...state.list.filter(val => val.id !== Number(id))],
      };
    }
    default:
      return state;
  }
};

export default financingProducts;
