import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import { authActions } from '../../state/ducks/auth';
import { Loading, Panel, } from '../../components';
import TextInput from '../../components/TextInput';
import { TOAST_CONFIG } from '../../config/constants';
import { credentialsActions } from '../../state/ducks/credentials';

class RegisterSii extends Component {
  constructor(props) {
    super(props);
    const {
      location: { state },
    } = props;
    this.state = { ...this.getInitialState(), ...state };
  }

  getInitialState = () => ({
    userSii: '',
    passwordSii: '',
    confirmPassword: '',
  });

  resetState = () => {
    this.setState(this.getInitialState());
  };

  componentDidMount() {
    const {
      auth: {
        user: { b2b: { registered_card, saved_credentials } },
      },
      history,
      router: { location: { pathname } },
      getCredentials,
    } = this.props;

    if (pathname === '/save-credentials') {
      if (saved_credentials === 1) {
        if (registered_card === 0) {
          history.push('/register-card');
        } else {
          history.push('/dashboard');
        }
      }
    }

    if (pathname === '/edit-credentials') {
      getCredentials({
        callback: (resp) => {
          this.setState(this.arrayCredentials(resp));
        }
      });
    }
  }

  arrayCredentials = (resp) => {
    const {
      credentials: { list: listCredentials },
    } = this.props;

    return {
      userSii: resp.user,
      passwordSii: resp.password,
      confirmPassword: resp.password,
    };
  };

  resp = () => {
    const {
      savedCredentials,
      updateCredentials,
      auth: {
        user: {
          user_id, b2b: { registered_card }, profile: { user_id: user_id_profile }
        },
      },
      router: { location: { pathname } },
      showMessage,
      t,
      history,
      updateB2B,
      credentials: { list: listCredentials },
    } = this.props;
    const {
      userSii,
      passwordSii,
      confirmPassword,
    } = this.state;

    if (pathname === '/save-credentials') {
      if (passwordSii === confirmPassword) {
        const data = {
          user_id: user_id,
          user: userSii,
          password: passwordSii,
          platform: 'SII'
        };

        savedCredentials({
          data,
          callback: () => {
            showMessage({
              message: t('dataSavedSuccessfully'),
              config: TOAST_CONFIG.SUCCESS,
            });
            updateB2B({
              params: {
                user_id: user_id_profile
              },
            });
            history.push('/register-card');
          },
        });

      } else {
        showMessage({
          message: <strong style={{ color: '#ffffff' }}>{t('nonMatchingPasswords')}</strong>,
          config: TOAST_CONFIG.ERROR,
        });
      }
    }

    if (pathname === '/edit-credentials') {
      if (passwordSii === confirmPassword) {
        const data = {
          user_id: user_id,
          user: userSii,
          password: passwordSii,
          platform: listCredentials.platform,
        };
        updateCredentials({
          data,
          callback: () => {
            showMessage({
              message: t('dataSavedSuccessfully'),
              config: TOAST_CONFIG.SUCCESS,
            });
            this.resetState();
            history.push('/dashboard');
          }
        });
      } else {
        showMessage({
          message: <strong style={{ color: '#ffffff' }}>{t('nonMatchingPasswords')}</strong>,
          config: TOAST_CONFIG.ERROR,
        });
      }
    }
  };

  handleInputChange = (event) => {
    const {
      target: {
        checked, value: targetValue, type, name,
      },
    } = event;
    const value = type === 'checkbox' ? checked : targetValue;

    if ((name === 'userSii')) {

      let num = this.formatRUT(event.target.value);

      if (name === 'documentNumber') {
        this.setState({ unformattedRut: num });
      }
      if (name === 'adminDocumentNumber') {
        this.setState({ unformattedAdminRut: num });
      }

      let prenum = '';
      let postnum = '';

      if (num.length >= 9) {
        prenum = num.slice(0, 8);
        postnum = num.slice(8, 9);
        num = prenum + '-' + postnum;
      }

      this.setState({
        [name]: (num),
      });
    } else {
      this.setState({
        [name]: value,
      });
    }
  };

  formatRUT = (n) => {
    return n.replace(/[^0-9Kk]/, '');
  };

  render() {
    const {
      userSii,
      passwordSii,
      confirmPassword,
    } = this.state;
    const {
      t,
      credentials: { loading: webpayLoading },
    } = this.props;

    return (
      <div>
        {webpayLoading && <Loading/>}
        <div className="columns">
          <div className="column is-full">
            <Panel headingText={t('internalRevenueService')}>
              <div className="column is-full">
                <div className="column">
                  <h1 style={{ fontSize: '20px' }}>
                    <strong>{t('enterDataInternalTaxService')}</strong> <br/>
                  </h1>
                  <div style={{ fontSize: '17px' }}>{t('safetyRules')}</div>
                  <div style={{ fontSize: '12px' }}>{t('yourDataWillBeStoredEncrypted')}</div>
                </div>
              </div>
              <div className="field is-horizontal">
                <div className="field-body columns">
                  <div className="column">
                    <TextInput
                      name="userSii"
                      label={t('RUT')}
                      value={userSii}
                      onChange={this.handleInputChange}
                      placeholder={t('documentNumber')}
                      type="text"
                      controlClasses="is-expanded"
                      inputClasses="has-text-left"
                      maxlength={13}
                    />
                  </div>
                  <div className="column">
                  </div>
                </div>
              </div>
              <div className="field is-horizontal">
                <div className="field-body columns">
                  <div className="column">
                    <TextInput
                      name="passwordSii"
                      label={t('enterTaxCode')}
                      value={passwordSii}
                      onChange={this.handleInputChange}
                      placeholder={t('enterTaxCode')}
                      type="password"
                      controlClasses="is-expanded"
                    />
                  </div>
                  <div className="column">
                  </div>
                </div>
              </div>
              <div className="field is-horizontal">
                <div className="field-body columns">
                  <div className="column">
                    <TextInput
                      name="confirmPassword"
                      label={t('confirmTaxCode')}
                      value={confirmPassword}
                      onChange={this.handleInputChange}
                      placeholder={t('confirmTaxCode')}
                      type="password"
                      controlClasses="is-expanded"
                    />
                  </div>
                  <div className="column">
                  </div>
                </div>
              </div>

              <div className="is-fullwidth has-text-right">
                <button
                  type="button"
                  className="button is-primary"
                  onClick={() => this.resp()}
                >
                  {t('save')}
                </button>
              </div>
            </Panel>
          </div>
        </div>
      </div>
    );
  }
}

RegisterSii.propTypes = {
  t: PropTypes.func.isRequired,
  registerPayment: PropTypes.func.isRequired,
  updateB2B: PropTypes.func.isRequired,
};

const mapStateToProps = ({ auth, router, credentials }) => (
  {
    auth,
    router,
    credentials,
  }
);

export default compose(
  connect(mapStateToProps, {
    ...credentialsActions,
    ...authActions,
  }),
  withNamespaces(),
)(RegisterSii);