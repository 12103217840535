/* eslint-disable jsx-a11y/label-has-for */
import React from 'react';
import PropTypes from 'prop-types';

/**
 * Created by Feedback Software on 25/10/18.
 * @param name
 * @param placeholder
 * @param value
 * @param onChange function to handle the state in the father components
 * @param type
 * @param helpText
 * @param label if empty doesn't show
 * @param disabled boolean to disable input
 * @param fieldClasses array of classes for the field
 * @param inputClasses array of classes for the input
 * @param controlClasses array of classes for the control
 * @param icon font awesome class of icon to display
 * @param iconPosition can receive 'is-left' or 'is-right' to position as class
 * @param step incremental amount
 * @returns {*}
 * @constructor
 */
const TextArea = ({
  name,
  placeholder,
  value,
  onChange,
  helpText,
  label,
  disabled = false,
  fieldClasses,
  textAreaClasses,
  controlClasses,
  icon = null,
  iconPosition = null,
  maxLength,
  autofocus,
  rows,
  cols,
}) => (
  <div className={ `field ${fieldClasses}` }>
    <label htmlFor={ name } className="label">{ label }</label>
    <div className={ `control ${controlClasses}` }>
      <textarea
        className={ `input ${textAreaClasses}` }
        name={ name }
        id={ name }
        placeholder={ placeholder }
        value={ value }
        onChange={ onChange }
        disabled={ disabled }
        maxLength={ maxLength }
        autoFocus={ autofocus }
        rows={ rows }
        cols={ cols }
      />
      {
        !!icon && (
          <span className={ `icon ${iconPosition}` }>
            <i className={ `${icon}` } />
          </span>
        )
      }
    </div>
    <p className="help">{ helpText }</p>
  </div>
);

TextArea.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  onChange: PropTypes.func,
  helpText: PropTypes.string,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  fieldClasses: PropTypes.string,
  textAreaClasses: PropTypes.string,
  controlClasses: PropTypes.string,
  icon: PropTypes.string,
  iconPosition: PropTypes.string,
  maxLength: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
};

TextArea.defaultProps = {
  placeholder: null,
  value: undefined,
  onChange: () => {},
  helpText: null,
  label: null,
  disabled: false,
  fieldClasses: '',
  textAreaClasses: '',
  controlClasses: '',
  icon: null,
  iconPosition: null,
  maxLength: '',
};

export default TextArea;
