const START_FETCH = 'route/START_FETCH';
const END_FETCH = 'route/END_FETCH';
const API_CALL = 'route/API_CALL';
const MESSAGE = 'route/MESSAGE';
const FETCH_ROUTE = 'route/FETCH_ROUTE';
const FETCH_ROUTE_BY_TYPE = 'route/FETCH_ROUTE_BY_TYPE';
const UPDATE_ROUTE = 'route/UPDATE_ROUTE';
const ADD_ROUTE = 'route/ADD_ROL';
const SAVE_ROUTE = 'route/SAVE_ROUTE';
const FETCH_ROUTE_ROLE = 'route/FETCH_ROUTE_ROLE';
const UPDATE_ROUTE_ROLE = 'route/UPDATE_ROUTE_ROLE';
const CREATE_ROUTE_ROLE = 'route/CREATE_ROUTE_ROLE';
const DELETE_ROUTE_ROLE = 'route/DELETE_ROUTE_ROLE';
const DELETE_ROUTE = 'route/DELETE_ROUTE';
const REMOVE_ROUTE = 'route/REMOVE_ROUTE';
const EDIT_ROUTE = 'route/EDIT_ROUTE';


export default {
  START_FETCH,
  END_FETCH,
  API_CALL,
  MESSAGE,
  FETCH_ROUTE,
  FETCH_ROUTE_BY_TYPE,
  UPDATE_ROUTE,
  ADD_ROUTE,
  SAVE_ROUTE,
  FETCH_ROUTE_ROLE,
  UPDATE_ROUTE_ROLE,
  CREATE_ROUTE_ROLE,
  DELETE_ROUTE_ROLE,
  DELETE_ROUTE,
  REMOVE_ROUTE,
  EDIT_ROUTE,
};
