import React, { Component, Fragment } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import PropTypes from 'prop-types';
import Modal from '../../components/Modal';
import { Loading, Panel, TableSimple, Toggle, } from '../../components';
import { roleActions } from '../../state/ducks/role';
import { authActions } from '../../state/ducks/auth';
import { logActions } from '../../state/ducks/log';
import TextArea from '../../components/TextArea';

class OperationLog extends Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  getInitialState = () => ({
    params: '',
    respJson: '',
    respXml: '',
    response: '',
  });

  componentDidMount() {
    const {
      eventLog,
    } = this.props;
    eventLog({});
  }

  resetState = () => {
    this.setState(this.getInitialState());
  };

  getGridSettings = (onToggle) => {
    const {
      t,
      log: { list },
    } = this.props;
    const columns = [
      {
        id: 'event', // Required because our accessor is not a string
        Header: t('event'),
        accessor: d => `${t(d.route)} `,
      },
      {
        id: 'date', // Required because our accessor is not a string
        Header: t('date'),
        accessor: d => `${d.date} `,
      },
      {
        id: 'time', // Required because our accessor is not a string
        Header: t('time'),
        accessor: d => `${d.time} `,
      },
      {
        id: 'info', // Required because our accessor is not a string
        Header: t('infoLog'),
        accessor: d => `${d.info_log} `,
      },
      {
        id: 'event_log', // Required because our accessor is not a string
        Header: t('detail'),
        accessor: d => (d.event_log) !== null ? `${d.event_log} ` : '',
      },
    ];
    const data = list;
    const title = t('logView');
    return {
      columns,
      data,
      title,
    };
  };

  closeModal = (onToggle) => {
    onToggle();
  };

  render() {
    const {
      t,
      log: { loading },
    } = this.props;
    const { params, respJson, respXml } = this.state;
    return (
      <Toggle>
        {({ toggled, onToggle }) => {
          const { title, columns, data } = this.getGridSettings(onToggle);
          return (
            <div className="dashboard">
              {loading && <Loading/>}
              <div className="columns is-multiline">
                <Fragment>
                  {
                    toggled
                    && (
                      <Modal onToggle={onToggle}>
                        <Panel headingText={t('logView')}>
                          <div className="field is-horizontal">
                            <div className="field-body columns">
                              <div className="column">
                                <TextArea
                                  name="params"
                                  label={t('params')}
                                  value={params}
                                  onChange={this.closeModal}
                                  type="text"
                                  inputClasses="has-text-right"
                                  disabled={true}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="field is-horizontal">
                            <div className="field-body columns">
                              <div className="column">
                                <TextArea
                                  name="respJson"
                                  label={t('respJson')}
                                  value={respJson}
                                  onChange={this.closeModal}
                                  type="text"
                                  inputClasses="has-text-right"
                                  disabled={true}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="field is-horizontal">
                            <div className="field-body columns">
                              <div className="column">
                                <TextArea
                                  name="respXml"
                                  label={t('respXml')}
                                  value={respXml}
                                  onChange={this.closeModal}
                                  type="text"
                                  inputClasses="has-text-right"
                                  disabled={true}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="is-fullwidth has-text-right">
                            <button
                              type="button"
                              className="button is-primary"
                              onClick={() => onToggle({})}
                            >
                              {t('accept')}
                            </button>
                          </div>
                        </Panel>
                      </Modal>
                    )
                  }
                  {data
                  && (
                    <div className="column is-full">
                      <Panel headingText={title}>

                        <TableSimple data={data} columns={columns}/>
                      </Panel>
                    </div>
                  )}
                </Fragment>
              </div>
            </div>
          );
        }}
      </Toggle>
    );
  }
}

OperationLog.propTypes = {
  t: PropTypes.func.isRequired,
  eventLog: PropTypes.func.isRequired,
};

const mapStateToProps = ({ role, auth, log }) => (
  {
    role,
    auth,
    log,
  }
);
export default compose(
  connect(mapStateToProps, { ...roleActions, ...authActions, ...logActions }),
  withNamespaces(),
)(OperationLog);
