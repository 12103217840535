import types from './types';

const credentials = (state = {}, action) => {
  switch (action.type) {
    case types.START_FETCH: {
      return {
        ...state,
        loading: true,
      };
    }
    case types.END_FETCH: {
      const { error, payload } = action;
      return {
        ...state,
        loading: false,
        error: error ? payload : null,
      };
    }
    case types.SAVED_DATA: {
      const { payload } = action;
      return {
        ...state,
        list: payload,
      };
    }
    default:
      return state;
  }
};

export default credentials;