import { flowApprovalActions, flowApprovalTypes } from '../ducks/flowApproval';

const createFlowApproval = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== flowApprovalTypes.CREATE_FLOW_APPROVAL) {
    return;
  }

  const {
    id,
    data = {},
    is_default,
    process_id,
    name,
    condition_amount,
    condition_operator,
    callback = () => {
    },
  } = action.payload;

  dispatch({
    type: flowApprovalTypes.API_CALL,
    payload: {
      config: {
        method: 'POST',
        url: `/b2b/create-workflow`,
        data: {
          id,
          data,
          is_default,
          process_id,
          name,
          condition_amount,
          condition_operator,
        }
      },
      authorization: true,
      onStart: flowApprovalActions.startFetch,
      onComplete: ({ data: { data, success } }) => {
        if (success) {
          callback(data);
          /* dispatch(roleActions.showMessage({
            message: t('deleteRol'),
            config: TOAST_CONFIG.SUCCESS,
          })); */
        }
      },
      onEnd: flowApprovalActions.endFetch,
    },
  });

};

const fetchActiveFlow = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== flowApprovalTypes.FETCH_ACTIVE_FLOW) {
    return;
  }

  const {
    params: {
      id
    }
  } = action.payload;

  dispatch({
    type: flowApprovalTypes.API_CALL,
    payload: {
      config: {
        method: 'GET',
        url: `/b2b/get-active-flow?id=${id}`,
      },
      authorization: true,
      onStart: flowApprovalActions.startFetch,
      onComplete: ({ data: { data, success } }) => {
        if (success) {
          dispatch(flowApprovalActions.updateActiveFlow(data));
        }
      },
      onEnd: flowApprovalActions.endFetch,
    },
  });

};

const getFlowApproval = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== flowApprovalTypes.GET_FLOW_APPROVAL) {
    return;
  }

  const {
    params: {
      id = '',
    } = {},
    callback = () => {
    },
  } = action.payload;

  dispatch({
    type: flowApprovalTypes.API_CALL,
    payload: {
      config: {
        method: 'GET',
        url: `/b2b/get-workflow?id=${id}`,
      },
      authorization: true,
      onStart: flowApprovalActions.startFetch,
      onComplete: ({ data: { data, success } }) => {
        if (success) {
          dispatch(flowApprovalActions.updateFlowApproval({
            list: data,
          }));
          callback(data);
        }
      },
      onEnd: flowApprovalActions.endFetch,
    },
  });

};

const getWorkFlows = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== flowApprovalTypes.GET_WORK_FLOWS) {
    return;
  }

  const {
    params: {
      id = '',
    } = {},
    callback = () => {
    },
  } = action.payload;

  dispatch({
    type: flowApprovalTypes.API_CALL,
    payload: {
      config: {
        method: 'GET',
        url: `/b2b/get-workflows?id=${id}`,
      },
      authorization: true,
      onStart: flowApprovalActions.startFetch,
      onComplete: ({ data: { data, success } }) => {
        if (success) {
          dispatch(flowApprovalActions.updateFlowApproval({
            list: data,
          }));
          callback(data);
        }
      },
      onEnd: flowApprovalActions.endFetch,
    },
  });

};

const selectId = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== flowApprovalTypes.SELECTED_ID) {
    return;
  }

  const {
    id = '',
  } = action.payload;

  //dispatch(flowApprovalActions.selectId(id));
  dispatch({
    authorization: true,
    onStart: flowApprovalActions.startFetch,
    // dispatch(flowApprovalActions.selectId(id));
    onComplete: () => {
      dispatch(flowApprovalActions.selectId(id));
    },
    onEnd: flowApprovalActions.endFetch,
  });

};

const getWorkFlowId = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== flowApprovalTypes.GET_WORK_FLOW_ID) {
    return;
  }

  const {
    params: {
      iduser = '',
      id = '',
    } = {},
    callback = () => {
    },
  } = action.payload;

  dispatch({
    type: flowApprovalTypes.API_CALL,
    payload: {
      config: {
        method: 'GET',
        url: `/b2b/get-workflowid?iduser=${iduser}&id=${id}`,
      },
      authorization: true,
      onStart: flowApprovalActions.startFetch,
      onComplete: ({ data: { success, data } }) => {
        if (success) {
          dispatch(flowApprovalActions.updateActiveFlow(data));
          callback(data);
        }
      },
      onEnd: flowApprovalActions.endFetch,
    },
  });

};

const desactivetWorkFlow = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== flowApprovalTypes.DESACTIVE_WORK_FLOW) {
    return;
  }

  const {
    params: {
      id = '',
    } = {},
    callback = () => {
    },
  } = action.payload;

  dispatch({
    type: flowApprovalTypes.API_CALL,
    payload: {
      config: {
        method: 'GET',
        url: `/b2b/desactive-workflow?id=${id}`,
      },
      authorization: true,
      onStart: flowApprovalActions.startFetch,
      onComplete: ({ data: { success, data } }) => {
        if (success) {
          callback(data);
          dispatch(flowApprovalActions.updateActiveFlow(data));
        }
      },
      onEnd: flowApprovalActions.endFetch,
    },
  });

};

const defaultWorkFlow = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== flowApprovalTypes.DEFAULT_WORK_FLOW) {
    return;
  }

  const {
    params: {
      id = '',
      userid = '',
    } = {},
    callback = () => {
    },
  } = action.payload;

  dispatch({
    type: flowApprovalTypes.API_CALL,
    payload: {
      config: {
        method: 'GET',
        url: `/b2b/default-workflow?id=${id}&userid=${userid}`,
      },
      authorization: true,
      onStart: flowApprovalActions.startFetch,
      onComplete: ({ data: { success, data } }) => {
        if (success) {
          callback(data);
          dispatch(flowApprovalActions.updateActiveFlow(data));
        }
      },
      onEnd: flowApprovalActions.endFetch,
    },
  });

};

const getWorkFlowTypes = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== flowApprovalTypes.GET_WORK_FLOW_TYPES) {
    return;
  }

  const {} = action.payload;

  dispatch({
    type: flowApprovalTypes.API_CALL,
    payload: {
      config: {
        method: 'GET',
        url: `workflow-type/process-types`,
      },
      authorization: true,
      onStart: flowApprovalActions.startFetch,
      onComplete: ({ data: { data, success } }) => {
        if (success) {
          dispatch(flowApprovalActions.updateFlowApproval({
            list: data,
          }));
        }
      },
      onEnd: flowApprovalActions.endFetch,
    },
  });

};

const getProcessWorkFlow = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== flowApprovalTypes.GET_PROCESS_WORK_FLOW) {
    return;
  }

  const {
    params: {
      user_id = '',
    } = {},
    callback = () => {
    },
  } = action.payload;

  dispatch({
    type: flowApprovalTypes.API_CALL,
    payload: {
      config: {
        method: 'GET',
        url: `workflow-type/user-process-type?user_id=${user_id}`,
      },
      authorization: true,
      onStart: flowApprovalActions.startFetch,
      onComplete: ({ data: { data, success } }) => {
        if (success) {
          callback(data);
          dispatch(flowApprovalActions.updateProcess(data));
        }
      },
      onEnd: flowApprovalActions.endFetch,
    },
  });

};

export default [createFlowApproval, getFlowApproval, fetchActiveFlow, getWorkFlows,
  getWorkFlowId, selectId, desactivetWorkFlow, defaultWorkFlow, getWorkFlowTypes, getProcessWorkFlow];
