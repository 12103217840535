import React, { Component, Fragment } from 'react';
import { compose } from 'redux';
import './styles.scss';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Loading, Panel, TableSimple, Toggle } from '../../components';
import { bankActions } from '../../state/ducks/bank';
import { userProfileActions } from '../../state/ducks/userProfile';
import { roleActions } from '../../state/ducks/role';
import { documentTypeActions } from '../../state/ducks/documentType';
import { authActions } from '../../state/ducks/auth';
import { getPath } from '../../util';
import { CSVLink } from 'react-csv';

class BankUserList extends Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
    this.selectTable = React.createRef();
    this.csvRef = React.createRef();
  }

  getInitialState = () => ({
    id: '',
    registerLoading: false,
    roleType: -1,
    name: '',
    documentType: -1,
    documentNumber: '',
    email: '',
    password: '',
    confirmPassword: '',
    editable: true,
    userType: false,
    downloadCsv: [],
  });

  componentDidMount() {
    const {
      fetchBanks,
      auth: { user: { b2b: { type_user, id } } },
    } = this.props;
    if (type_user === 'main_bank' || type_user === 'main_bank_issuing' || type_user === 'main_bank_acquiring') {
      fetchBanks({
        params: {
          id,
          main_bank: true,
        }
      })            
    } else if (type_user === 'admin' || type_user === 'admin_amex') {
      fetchBanks({
        params: {
          id,
          admin: true,
        }
      }) 
    }else{
      fetchBanks({ params: {id} });
    }    
  }

  resetState = () => {
    this.setState(this.getInitialState());
  };

  validateEmptyFields = () => {
    const {
      name,
      documentNumber,
    } = this.state;

    if ( name === '' || documentNumber === '' ){
      return false;
    } else {
      return  true;
    }
  };

  labelType = (role) => {
    const {
      t,
    } = this.props;

    return role === 'issuing_bank' ? t('issuing_bank') :
      role === 'issuing_query' ? t('issuing_query') : role === 'cycle_loader' ? t('cycle_loader') :
        role === 'rate_operator' ? t('rate_operator') : t('rate_operator');
  };

  getGridSettings = (onToggle) => {
    const {
      t,
      auth: { user: { b2b: { type_user, id }, profile: { type } } },
      //userProfile: { listb },
      //documentType: { list: documentTypesList },
      //role: { list: rolList },
      bank: { list },
      updateStatus,
      fetchBanks,
      history,
    } = this.props;
    const editurl = '/edit-bank-user';
    let list2 = [];
    const columns = [
      {
        expander: true,
        show: false,
      },
      /*
      {
        id: 'fiscalDocument', // Required because our accessor is not a string
        Header: t('documentNumber'),
        //style: type_user === 'main_bank_issuing' ? { textAlign: 'right' } : '',
        accessor: d => `${getPath(['idPerson', 'document_number'], d) === null ? '' : getPath(['idPerson', 'document_number'], d)}`,
      },
      */
      {
        id: 'name', // Required because our accessor is not a string
        Header: t('name'),
        //style: type_user === 'main_bank_issuing' ? { textAlign: 'left' } : '',
        accessor: d => d.idPerson.name
      },
      {
        id: 'lastName', // Required because our accessor is not a string
        Header: t('adminLastname'),
        //style: type_user === 'main_bank_issuing' ? { textAlign: 'left' } : '',
        accessor: d => (d.idPerson.lastname !== null) ? d.idPerson.lastname : '',
      },
      {
        id: 'adminPosition', // Required because our accessor is not a string
        Header: t('adminPosition'),
        width: 150,
        //style: type_user === 'main_bank_issuing' ? { textAlign: 'left' } : '',
        accessor: d => (d.admin_position === null) ? '' : d.admin_position,
      },
      {
        id: 'rol', // Required because our accessor is not a string
        Header: t('rol'),
        //style: type_user === 'main_bank_issuing' ? { textAlign: 'left' } : '',
        accessor: d => type_user === 'main_bank_issuing' ? this.labelType(d.roles[0].name) : `${t(d.roles[0].name)}`,//`${d.roles.map(e => t(e.name)).join(', ')} `,
      },
      {
        id: 'email', // Required because our accessor is not a string
        Header: t('email'),
        //style: type_user === 'main_bank_issuing' ? { textAlign: 'left' } : '',
        accessor: d => `${d.username}`,
      },
      {
        id: 'action', // Required because our accessor is not a string
        Header: t('action'),
        filterable: false,
        headerClassName: 'wordwrap',
        className: 'buttons-col', /* TableSimple.css */
        width: 200,
        accessor: d => `${d.id} `,
        Cell: cellInfo => (
          <table>
            <tbody>
            <tr>
              <td>
                <Link
                  className={`button is-inverted is-small is-${cellInfo.original.user_status === '1' ? 'danger' : 'success'}`}
                  to="/bank-user-list"
                  onClick={() =>
                      updateStatus({ data: { 
                        id_user: cellInfo.original.id, 
                        new_status: cellInfo.original.user_status === '1' ? '0' : '1' 
                      }, 
                    callback: () => fetchBanks({params: {id}}) })}
                  >
                    {cellInfo.original.user_status === '1'
                      ? t('disable')
                      : t('enable')
                    }
                  </Link>
                  <button
                    className="button is-info is-inverted is-small"
                    onClick={() => history.push({
                      pathname: editurl,
                      state: {
                      userInfo: cellInfo.original
                      },
                    })}
                  >
                    <i className="far fa-edit mr-8px" aria-hidden="true" />
                    {t('edit')}
                  </button>
              </td>
            </tr>
            </tbody>
          </table>
        ),
      },
    ];
    const data = list;
    const title = t('profileList');
    return {
      columns,
      data,
      title,
    };
  };

  handleInputChange = (event) => {
    const {
      target: {
        checked, value: targetValue, type, name,
      },
    } = event;
    const value = type === 'checkbox' ? checked : targetValue;
    this.setState({
      [name]: value,
    });
  };

  handleSelectChange = (newVal, label) => {
    this.setState({
      [label.name]: newVal.value,
    }, () => (this.state.documentType));
  };

  download = () => {
    const { sortedData, columns } = this.selectTable.current.getResolvedState();

    columns.splice(0, 1);
    columns.splice(5, 6);

    const downloadCsv = [['sep=,']];
    downloadCsv.push(columns.map(column => column.Header));
    downloadCsv.push(...sortedData.map((sorted) => {
      const downloadCsvObject = {};
      columns.forEach((column) => {
        downloadCsvObject[column.id] = sorted[column.id];
      });
      return Object.values(downloadCsvObject);
    }));
    this.setState({ downloadCsv }, () => {
      this.csvRef.current.link.click();
    });
  };

  render() {
    const {
      t,
      userProfile: { loading },
    } = this.props;
    const {
      downloadCsv,
    } = this.state;
    return (
      <Toggle>
        {({ toggled, onToggle, toggleProps }) => {
          const { title, columns, data } = this.getGridSettings(onToggle);
          return (
            <div className="dashboard">
              {loading && <Loading />}
              <div className="columns is-multiline">
                <Fragment>
                  {data
                  && (
                    <div className="column is-full">
                      <div className="panel-BTNS">
                        <Link
                          className="button custom-btn is-primary"
                          to="/create-bank-user"
                          onClick={() => onToggle({
                            // transaction: cellInfo.original,
                            // paymentCondition: advance_condition,
                            // onSubmit: this.advancePayment,
                          })}
                        >
                          {/* <i className="fas fa-plus mr-8px" aria-hidden="true" /> */}
                          {t('newProfile')}
                        </Link>
                        &nbsp;&nbsp;
                        <button
                          className="button custom-btn is-link is-outlined"
                          onClick={this.download}
                        >
                          {/* <i className="fas fa-file-csv mr-8px" aria-hidden="true"/> */}
                          {`${t('download')} CSV`}
                        </button>
                        <CSVLink
                          data={downloadCsv}
                          filename="summary_b2b.csv"
                          className="hidden"
                          ref={this.csvRef}
                        />
                      </div>
                      <Panel headingText={title}>
                        <TableSimple data={data} columns={columns} reff={this.selectTable}/>
                      </Panel>
                    </div>)}
                </Fragment>
              </div>
            </div>
          );
        }}
      </Toggle>
    );
  }
}

BankUserList.propTypes = {
  t: PropTypes.func.isRequired,
  editUserProfile: PropTypes.func.isRequired,
  fetchUserProfile: PropTypes.func.isRequired,
  userProfile: PropTypes.shape({}).isRequired,
  role: PropTypes.shape().isRequired,
  showMessage: PropTypes.func.isRequired,
  auth: PropTypes.shape({}).isRequired,
};

const mapStateToProps = ({ role, auth, userProfile, bank, documentType }) => (
  {
    role,
    auth,
    userProfile,
    bank,
    documentType,
  }
);
export default compose(
  connect(mapStateToProps, {
    ...roleActions,
    ...authActions,
    ...userProfileActions,
    ...bankActions,
    ...documentTypeActions,
  }),
  withNamespaces(),
)(BankUserList);