import React, { Component, Fragment } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import { Currency, Loading, Panel, TableSimple, Toggle, } from '../../components';
import { statsActions } from '../../state/ducks/stats';
import { transactionsActions } from '../../state/ducks/transactions';
import { clientsActions } from '../../state/ducks/clients';
import Modal from '../../components/Modal';
import { providersActions } from '../../state/ducks/providers';
import {
  formatDate,
  numberFilter,
  onInputOnlyNumbers,
} from '../../util';
import { API_URL, TOAST_CONFIG } from '../../config/constants';
import FormReject from '../invoices/components/FormReject';
import TextInput from '../../components/TextInput';
import DateInput from '../../components/DateInput';

const ItemCard = ({ name, value }) => (
  <li>
    <b>
      {`${name}:`}
    </b>
    {value}
  </li>
);

ItemCard.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

class InvoicesNCL extends Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
    this.selectTable = React.createRef();
    this.csvRef = React.createRef();
    this.xmlxRef = React.createRef();
  }

  getInitialState = () => {
    const { t, transactions: { currency } } = this.props;
    return ({
      days: 1,
      interest_rate: 0,
      discount_rate: 0,
      adjusted_amount: 0,
      type: '',
      approveDisabled: false,
      transaction_type: 'transfer',
      destination_phone_number: '',
      amount: '',
      invoice_date: new Date(),
      invoice_amount: null,
      invoice_number: null,
      invoice_description: '',
      client: null,
      product: currency,
      transfer_date: Date.now(),
      discount_amount: 0,
      detail: '',
      options: [
        {
          text: t('extractAccount'),
          value: 1,
        },
      ],
      isEdit: '',
      binaryFile: '',
      password: '',
      downloadFileData: [],
      request: -1,
      invoices: '',
      rejectReason: '',
      reject: false,
      pay: false,
      file: '',
      idTransaction: '',
      effective_date_payment: new Date(),
    });
  };

  componentDidMount() {
    const {
      listTransactionsSender,
      auth: { user: { b2b: { id, type_user } } },
      getClients,
    } = this.props;
    getClients({
      params: {
        id,
        related: true,
      },
    });

    if (type_user === 'issuing_bank') {
      listTransactionsSender({
        params: {
          ncl: true,
          is_report: true,
        },
      });
    }
  };

  getGridSettings = (userType, onToggle) => {
    let columns;
    let data;
    let title;
    const {
      t, transactions: { sender },
      fetchTransaction,
      history,
    } = this.props;
    const {
      request,
    } = this.state;

    switch (userType) {
      case 'issuing_bank':
        columns = [
          {
            expander: true,
            show: false,
          },
          {
            id: 'client',
            Header: t('client'),
            accessor: d => d.sender.idPerson.name,
          },
          {
            id: 'provider',
            Header: t('provider'),
            accessor: d => d.receiver.idPerson.name,
          },
          {
            id: 'invoicesNumber', // Required because our accessor is not a string
            Header: t('invoiceNumber'),
            accessor: d => d.invoice_number,
          },
          {
            id: 'currency',
            Header: t('currency'),
            className: 'text-center',
            accessor: d => `${d.pay_currency_id}`,
          },
          {
            id: 'days',
            Header: t('arrangedDays'),
            className: 'text-center',
            accessor: d => `${d.paymentCondition.days}`,
          },
          {
            id: 'pay_provider_date', // Required because our accessor is not a string
            Header: t('scheduledDateTheProvider'),
            headerClassName: 'wordwrap',
            accessor: 'pay_provider_date',
            Cell: cellInfo => (
              <div>
                <span>{cellInfo.original.pay_provider_date !== null ? formatDate(cellInfo.original.pay_provider_date) : ''}</span>
              </div>
            ),
            filterMethod: (filter, row) => {
              if (!filter.value.match(/[\d/]+$/gm)) {
                return true;
              }
              if (formatDate(row.pay_provider_date)
                .includes(filter.value)) {
                return formatDate(row.pay_provider_date);
              }
            },
            Filter: ({ filter, onChange }) =>
              <input
                onChange={event => onChange(event.target.value)}
                style={{ width: '100%' }}
                value={filter && filter.value.match(/[\d/]+$/gm) ? filter.value : ''}
              />
          },
          {
            id: 'mode',
            Header: t('mode'),
            className: 'text-center',
            accessor: d => (d.type_transaction === 'invoice_tdc' || d.type_transaction === 'invoice_ncl') ? `${t('traditional')}`
              :
              (d.type_transaction === 'payment') ? `${t('bpp')}` : '',
          },
          {
            id: 'adjusted_amount', // Required because our accessor is not a string
            Header: t('invoiceAmount'),
            accessor: d => d.adjusted_amount,
            Cell: cellInfo => (
              <Currency value={cellInfo.original.adjusted_amount} />
            ),
            sortMethod: (a, b) => Number(a)-Number(b),
            filterMethod: (filter, row) => numberFilter(filter, row),
            Filter: ({ filter, onChange }) =>
              <input
                onInput={onInputOnlyNumbers}
                onChange={event => onChange(event.target.value)}
                style={{ width: '100%' }}
                value={filter ? filter.value : ''}
              />
          },
          {
            id: 'description', // Required because our accessor is not a string
            Header: t('description'),
            Cell: cellInfo => (
              <div>
                <span data-type="info"
                      data-tip={cellInfo.original.description}>{cellInfo.original.description}
                </span>
                <ReactTooltip/>
              </div>
            ),
          },
          {
            id: 'status',
            Header: t('status'),
            accessor: d => ((d.senderStatus.id === "APG") || (d.senderStatus.id === "ANG")) ?  t("approvedStatus") : `${d.senderStatus.description}`, //Cambio temporal palabra "Garantizado"
          },
          {
            id: 'action', // Required because our accessor is not a string
            Header: t('action'),
            headerClassName: 'wordwrap',
            className: 'buttons-col', /* TableSimple.css */
            filterable: false,
            width: 150,
            Cell: cellInfo => (
              <table>
                <tbody>
                <tr>
                  <td>
                    <button
                      className="button is-info is-small is-inverted"
                      onClick={() => {
                        fetchTransaction({
                          params: {
                            id: cellInfo.original.id,
                            role: 'sender',
                          },
                          callback: () => {
                            history.push({
                              pathname: '/invoice-detail',
                              state: {
                                userInfo: request
                              },
                            });
                          }
                        });
                      }}
                    >
                        <span className="icon" title={t('detail')}>
                          <i className="far fa-file-alt"/>
                        </span>
                    </button>
                  </td>
                  {cellInfo.original.resource
                    && (
                      <td>
                        <a
                          href={`${API_URL}b2b/resource-file?nameFile=${cellInfo.original.resource}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="button is-info is-small is-inverted"
                          download
                        >
                          <span className="icon" title={t('downloadAttached')}>
                            <a className="ml-">
                              <i className="fas fa-file-alt"/>
                            </a>
                          </span>
                        </a>
                      </td>
                    )}
                  {cellInfo.original.payment_resource && cellInfo.original.senderStatus.id === 'PAG'
                    && (
                      <td>
                        <a
                          href={`${API_URL}b2b/resource-file?nameFile=${cellInfo.original.payment_resource}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="button is-info is-small is-inverted"
                          download
                        >
                          <span className="icon" title={t('proofPayment')}>
                            <a className="ml-">
                              <i className="fas fa fa-download"/>
                            </a>
                          </span>
                        </a>
                      </td>
                    )}
                  {cellInfo.original.senderStatus.id === 'APG'
                    && (
                      <td>
                          <button
                            className="button is-success is-inverted"
                            onClick={() => {onToggle({
                                process: true,
                                transaction: cellInfo.original,
                              });
                            }}
                          >
                            {t('process')}
                          </button>
                      </td>
                    )}
                </tr>
                </tbody>
              </table>
            ),
          },
        ];
        data = /*request === -1 ? [] :*/ sender;
        title = t('invoicesNcl');
        break;
      default:
        columns = [];
        data = [];
        title = '';
    }
    return {
      columns,
      data,
      title,
    };
  };

  closeModal = (onToggle, type, transaction) => {
    onToggle({
      process: false,
    });
    this.setState({
      reject: type === 'process',
      pay: type === 'pay',
      file: '',
      rejectReason: '',
      transaction: transaction,
      idTransaction: transaction.id,
    })
  };

  payInvoiceNcl = () => {
    const {
      t,
      showMessage,
      auth: { user: { b2b: { id } } },
      processPaymentNclInvoice,
      listTransactionsSender,
    } = this.props;
    const {
      transaction,
      idTransaction,
      binaryFile,
      effective_date_payment,
    } = this.state;

    const dataForm = new FormData();
    const data = {
      id: idTransaction,
      resource: binaryFile,
      user: id,
      payment: transaction.type_transaction === 'payment',
      effective_date_payment: Math.floor(effective_date_payment / 1000),
    };

    if (binaryFile) {
      for (let key in data) {
        dataForm.append(key, data[key]);
      }
    }

    const dataSend = binaryFile ? dataForm : data;

    processPaymentNclInvoice({
      data: dataSend,
      callback: () => {
        showMessage({
          message: t('paymentRegisteredSuccessfully'),
          config: TOAST_CONFIG.SUCCESS,
        });
        listTransactionsSender({
          params: {
            ncl: true,
            is_report: true,
          },
        });
        this.resetState();
      },
    });

    this.setState({
      pay: false,
    });

  };

  rejectInvoiceNcl = () => {
    const {
      transaction,
      idTransaction,
      rejectReason,
    } = this.state;
    const {
      t,
      showMessage,
      auth: { user: { b2b: { id } } },
      declinePaymentNclInvoice,
      listTransactionsSender,
    } = this.props;

    const rejectData = {
      id:idTransaction,
      rec_reason: rejectReason,
      user: id,
      payment: transaction.type_transaction === 'payment',
    };

    declinePaymentNclInvoice({
      data: rejectData,
      callback: () => {
        showMessage({
          message: t('nclPaymentSuccessfullyRejected'),
          config: TOAST_CONFIG.SUCCESS,
        });
        listTransactionsSender({
          params: {
            ncl: true,
            is_report: true,
          },
        });
        this.resetState();
      },
    });

    this.setState({
      reject: false,
    });

  };

  handleInputChange = (event) => {
    const {
      target: {
        checked, value: targetValue, type, name,
      },
    } = event;
    const value = type === 'checkbox' ? checked : targetValue;
    this.setState({
      [name]: value,
    });
  };

  handleFileChange = (event) => {
    const {
      t,
      showMessage,
    } = this.props;
    const files = Array.from(event.target.files);
    const arrays = files[0].name;
    let ext = arrays.split('.');

    if (ext[ext.length - 1].toUpperCase() === 'JPG' || ext[ext.length - 1].toUpperCase() === 'PDF') {
      if (parseInt(files[0].size / 1024) > 3072) {
        showMessage({
          message: <strong style={{ color: '#ffffff' }}>{t('fileSizeExceeded')}</strong>,
          config: TOAST_CONFIG.ERROR,
        });
      } else {
        this.setState({
          binaryFile: files[0],
          file: event.target.value,
        });
      }
    } else {
      showMessage({
        message: <strong style={{ color: '#ffffff' }}>{t('errorLoadFile', {
          type: 'PDF',
          type1: 'JPG'
        })}</strong>,
        config: TOAST_CONFIG.ERROR,
      });
    }
  };

  handleDateChange = (dateName, newDate) => {
    this.setState({
      [dateName]: newDate,
    });
  };

  render() {
    const {
      t,
      transactions: { loading: transactionLoading },
      auth: { user: { b2b: { type_user } } },
      clients: { loading: clientLoading },
    } = this.props;
    const {
      reject,
      rejectReason,
      pay,
      file,
      fileName,
      effective_date_payment,
    } = this.state;
    const loading = transactionLoading || clientLoading;
    return (
      <Toggle>
        {({ toggled, onToggle, toggleProps }) => {
          const { title, columns, data } = this.getGridSettings(type_user, onToggle);
          return (
            <div className="dashboard">
              {loading && <Loading/>}
              <div className="columns is-multiline">
                <Fragment>
                  {
                    toggled && toggleProps.process && (
                      <Modal onToggle={onToggle} title={t("process")}>
                        <div className="modal-action-btns">
                          <button
                            className="button custom-btn is-primary"
                            onClick={this.closeModal.bind(this, onToggle, 'process', toggleProps.transaction)}
                          >
                            {t("reject")}
                          </button>
                          <button
                            className="button custom-btn is-primary"
                            onClick={this.closeModal.bind(this, onToggle, 'pay', toggleProps.transaction)}
                          >
                            {t("toPay")}
                          </button>
                        </div>
                      </Modal>
                    )}
                  {
                    reject === true && (
                      <Modal title={t('rejectReason')} onToggle={() => this.setState({ reject: false })}> {loading && <Loading/>}
                        <FormReject
                          t={t}
                          onToggle={onToggle}
                          toggle={toggled}
                          toggleProps={toggleProps}
                          onSubmit={this.rejectInvoiceNcl}
                          handleInputChange={this.handleInputChange}
                          data={{ rejectReason }}
                          isEdit={true}
                          handleFileChange={this.handleFileReject}
                          type={'delete'}
                          type_user={type_user}
                          binaryFile={''}
                          invoiceNCL={true}
                        />
                      </Modal>
                    )
                  }
                  {
                    pay === true && (
                      <Modal title={t('toPay')} onToggle={() => this.setState({ pay: false })}> {loading && <Loading/>}
                        <div className="column is-full">
                        <div className="field is-horizontal">
                          <div className="field-body columns">
                            <div className="column is-full">
                              <div className="column is-full">
                                <TextInput
                                  name="file"
                                  label={t('proofPayment')}
                                  value={file}
                                  fileName={fileName}
                                  onChange={this.handleFileChange}
                                  type="file"
                                />
                                  <strong> <span className="texto-file">{t('AllowedFormats')}</span></strong>
                            </div>
                            </div>
                          </div>
                        </div>
                          <div className="field is-horizontal">
                            <div className="field-body columns">
                              <div className="column is-full" style={{ zIndex: 99999 }}>
                                <DateInput
                                  name="effective_date_payment"
                                  label={t('effectiveDatePayment')}
                                  value={effective_date_payment}
                                  onChange={(value) => this.handleDateChange('effective_date_payment', value)}
                                  type="text"
                                  fieldClasses="is-expanded"
                                  controlClasses="has-icons-left"
                                  icon="far fa-calendar-alt"
                                  iconPosition="is-left"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <button
                          className="button custom-btn is-primary"
                          disabled={file === ''}
                          onClick={this.payInvoiceNcl}
                        >
                          {t("toPay")}
                        </button>
                      </Modal>
                    )
                  }
                  { data
                    && (
                      <div className="column is-full">
                        <Panel headingText={title}>
                          <TableSimple data={data} columns={columns} reff={this.selectTable}/>
                        </Panel>
                      </div>
                    )}
                </Fragment>
              </div>
            </div>
          );
        }}
      </Toggle>

    );
  }
}

InvoicesNCL.propTypes = {
  auth: PropTypes.shape(Object).isRequired,
  transactions: PropTypes.shape(Object).isRequired,
  clients: PropTypes.shape(Object).isRequired,
  t: PropTypes.func.isRequired,
  fetchStats: PropTypes.func.isRequired,
  listTransactionsSender: PropTypes.func.isRequired,
  listTransactionsReceiver: PropTypes.func.isRequired,
  history: PropTypes.shape(Object).isRequired,
};

const mapStateToProps = ({
                          transactions,
                          auth,
                          router,
                          clients,
                        }) => ({
  transactions,
  auth,
  router,
  clients,
});

export default compose(
  connect(mapStateToProps, {
    ...statsActions, ...transactionsActions, ...clientsActions, ...providersActions,
  }),
  withNamespaces(),
)(InvoicesNCL);