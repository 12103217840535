import React, { Component, Fragment } from 'react';
import { compose } from 'redux';
import './styles.scss';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { BankForm, Loading, Modal, Panel, TableSimple, Toggle, } from '../../components';
import { statsActions } from '../../state/ducks/stats';
import { transactionsActions } from '../../state/ducks/transactions';
import { clientsActions } from '../../state/ducks/clients';
import { authActions } from '../../state/ducks/auth';
import { bankActions } from '../../state/ducks/bank';
import { providersActions } from '../../state/ducks/providers';
import { roleActions } from '../../state/ducks/role';
import { documentTypeActions } from '../../state/ducks/documentType';
import { TOAST_CONFIG } from '../../config/constants';
import { CSVLink } from 'react-csv';
import { getPath } from '../../util';
import { gatewayActions } from '../../state/ducks/gateway';
import Resizer from 'react-image-file-resizer';

const ItemCard = ({ name, value }) => (
  <li>
    <b>
      {`${name}:`}
    </b>
    {value}
  </li>
);

ItemCard.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

class Banks extends Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
    this.selectTable = React.createRef();
    this.csvRef = React.createRef();
  }

  getInitialState = () => ({
    bank_id: '',
    name: '',
    documentType: '',
    documentTypesList: [],
    roleList: [],
    documentNumber: 0,
    bankType: '',
    email: '',
    password: '',
    confirmPassword: '',
    downloadCsv: [],
    gateways: [],
    gateway: null,
    file: '',
    binaryFile: '',
  });

  componentDidMount() {
    const {
      fetchBanks,
      fetchDocumentTypes,
      fetchRoles,
      getGateway,
      auth: { user: { b2b: { type_user, id } } },
    } = this.props;
    if (type_user === 'main_bank' || type_user === 'main_bank_issuing' || type_user === 'main_bank_acquiring') {
      fetchBanks({
        params: {
          id,
          main_bank: true,
        }
      })            
    } else if (type_user === 'admin' || type_user === 'admin_amex') {
      fetchRoles({
        params: {
          type: 'bank',
          name: type_user === 'admin' ? 'general_admin' : 'main',
        },
      });
      fetchBanks({
        params: {
          admin: true,
        }
      });
      getGateway({
        callback: (response) => {
          this.setState({ gateways: response });
        }
      }); 
    }else{
      fetchBanks({});
    }
    //fetchBanks({});
    //fetchDocumentTypes({});
    /*fetchRoles({
      params: {
        type: 'bank',
      },
    });*/
  }

  getGridSettings = (onToggle) => {
    const {
      t,
      history,
      bank: { list },
      updateStatus,
      fetchBanks,
      documentType: { list: documentTypesList },
      role: { list: roleList },
    } = this.props;
    const columns = [
      {
        id: 'name', // Required because our accessor is not a string
        Header: t('name'),
        accessor: d => d.idPerson.name,
      },
      {
        id: 'bank_type', // Required because our accessor is not a string
        Header: t('type'),
        accessor: d => t(d.roles[0].name),
      },
      {
        id: 'email', // Required because our accessor is not a string
        Header: t('email'),
        accessor: d => d.idPerson.idEmailActive.email,
      },
      {
        id: 'document_number', // Required because our accessor is not a string
        Header: t('documentNumber'),
        accessor: d => getPath(['idPerson', 'document_number'], d) !== null ?
          `${(getPath(['idPerson', 'document_number'], d))}`
          : '',
      },
      {
        id: 'status', // Required because our accessor is not a string
        Header: t('status'),
        width: 100,
        Cell: cellInfo => (cellInfo.original.user_status === '1'
          ? <i className="far fa-check-circle mr-8px" aria-hidden="true" style={{ color: 'green' }} />
          : <i className="fas fa-ban mr-8px" aria-hidden="true" style={{ color: 'red' }} />),
      },
      {
        id: 'action', // Required because our accessor is not a string
        Header: t('action'),
        headerClassName: 'wordwrap',
        filterable: false,
        className: 'buttons-col', /* TableSimple.css */
        width: 200,
        Cell: cellInfo => (
          <table>
            <tbody>
            <tr>
              <td>
                <Link
                  className={`button is-small is-inverted is-${cellInfo.original.user_status === '1' ? 'danger' : 'success'}`}
                  to="/bank-list"
                  onClick={() => updateStatus({
                    data: {
                        id_user: cellInfo.original.id,
                        new_status: cellInfo.original.user_status === '1' ? '0' : '1'
                      },
                      callback: () => fetchBanks({})
                    })}
                  >
                    {cellInfo.original.user_status === '1'
                      ? t('disable')
                      : t('enable')
                    }
                  </Link>
                  <button
                    className="button is-info is-inverted is-small"
                    onClick={() => history.push({
                      pathname: '/edit-bank-admin',
                      state: {
                        userInfo: cellInfo.original
                      },
                    })}
                    /*onClick={() => {
                      this.setState({
                        bank_id: cellInfo.original.id,
                        name: cellInfo.original.idPerson.name,
                        documentType: 'RUT', /*documentTypesList.map(
                          (documentTypeMap, index) => ({ ...documentTypeMap, index }),
                        )
                          .filter(documentTypeFil => documentTypeFil.id
                            === cellInfo.original.idPerson.idDocumentType.id)[0].index,*/
                    /*documentNumber: cellInfo.original.idPerson.document_number,
                    bankType: roleList.map(
                      (roleMap, index) => ({
                        ...roleMap,
                        index
                      }),
                    )
                      .filter(roleFil => roleFil.id
                        === Number(cellInfo.original.roles[0].id))[0].index,
                    email: cellInfo.original.idPerson.idEmailActive.email,
                    originalEmail: cellInfo.original.idPerson.idEmailActive.email,
                    tdc_bin: cellInfo.original.tdc_bin,
                  }, () => {
                    onToggle({
                      isEdit: true,
                      bank: cellInfo.original,
                    });
                  });
                }}*/
                  >
                    <i className="far fa-edit mr-8px" aria-hidden="true"/>
                    {t('edit')}
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        ),
      }];
    const data = list;
    const title = t('administratorsList');
    return {
      columns,
      data,
      title,
    };
  };

  editProvider = (onToggle) => {
    const {
      t,
      history,
      role: { list: roleList },
      documentType: { list: documentTypeList },
      showMessage,
      editProvider,
      fetchBanks,
    } = this.props;
    const {
      bank_id,
      name,
      documentType,
      documentNumber,
      bankType,
      email,
      originalEmail,
      password,
      confirmPassword,
      binaryFile,
    } = this.state;

    const dataForm = new FormData();
    const data = {
      id_user: bank_id,
      country_code: '',
      phone_number: '',
      email: email === originalEmail ? '' : email,
      password: '',
      lastname: '',
      name,
      documentType: documentTypeList.map(
        (documentTypeMap, index) => ({
          ...documentTypeMap,
          index
        }),
      )
        .filter(documentTypeFil => documentTypeFil.document_type === 'RUT')[0].id,
      documentNumber,
      bankType: roleList[bankType].id,
      resource: binaryFile,
      b2b: {
        issuing_bank: 0,
        acquiring_bank: 0,
        [roleList[bankType].name]: 1,
      },
    };

    if (binaryFile) {
      for (let key in data) {
        if (typeof (data[key]) === 'object' && key !== 'resource') {
          for (let subKey in data[key]) {
            dataForm.append(`${key}.${subKey}`, data[key][subKey]);
          }
        } else {
          dataForm.append(key, data[key]);
        }
      }
    }

    const dataSend = binaryFile ? dataForm : data;

    editProvider({
      data: dataSend,
      callback: () => {
        showMessage({
          message: t('editUserSuccess', { name: name }),
          config: TOAST_CONFIG.SUCCESS,
        });
        history.push('/dashboard');
        /*fetchBanks({
          params: {
            id,
            main_bank: true,
          }
        })*/
        this.setState(this.getInitialState());
        onToggle();
      },
    });
  };

  resizeFile = (file, type) => new Promise(resolve => {
    Resizer.imageFileResizer(file, 300, 55, type, 100, 0,
      uri => {
        //resolve(uri);
        this.setState({
          binaryFile: uri,
        });
      },
      'file'
    );
  });

  handleFileChange = (event) => {
    const {
      t,
      showMessage,
    } = this.props;
    const files = Array.from(event.target.files);
    const arrays = files[0].name;
    let ext = arrays.split('.');

    if (ext[ext.length - 1].toUpperCase() === 'JPG' || ext[ext.length - 1].toUpperCase() === 'PNG') {
      if (parseInt(files[0].size / 1024) > 3072) {
        showMessage({
          message: <strong style={{ color: '#ffffff' }}>{t('fileSizeExceeded')}</strong>,
          config: TOAST_CONFIG.ERROR,
        });
      } else {
        this.resizeFile(files[0], ext[ext.length - 1]);
        this.setState({
          //binaryFile: files[0],
          file: event.target.value,
        });
      }
    } else {
      showMessage({
        message: <strong style={{ color: '#ffffff' }}>{t('errorLoad', {
          type: 'JPG',
          type1: 'PNG'
        })}</strong>,
        config: TOAST_CONFIG.ERROR,
      });
    }
  };

  handleInputChange = (event) => {
    const {
      target: {
        checked, value: targetValue, type, name,
      },
    } = event;
    const value = type === 'checkbox' ? checked : targetValue;
    if (name === 'documentNumber') {
      let num = this.formatRUT(event.target.value);
      let prenum = '';
      let postnum = '';

      if (num.length >= 9) {
        prenum = num.slice(0, 8);
        postnum = num.slice(8, 9);
        num = prenum + '-' + postnum;
      }

      this.setState({
        [name]: (num),
      });
    } else {
      this.setState({
        [name]: value,
      });
    }
  };

  handleSelectChange = (newVal, label) => {
    this.setState({
      [label.name]: newVal.value,
    });
  };

  formatRUT = (n) => {
    //return n.replace(/\D/g, '');
    return n.replace(/[^a-zA-Z0-9]/, '');
  };

  download = () => {
    const { sortedData, columns } = this.selectTable.current.getResolvedState();

    columns.splice(5, 6);

    for (let i = 0; i < sortedData.length; i++) {
      sortedData[i].status = sortedData[i]._original.user_status === '1' ? 'Habilitado' : 'Deshabilitado';
    }

    const downloadCsv = [['sep=,']];
    downloadCsv.push(columns.map(column => column.Header));
    downloadCsv.push(...sortedData.map((sorted) => {
      const downloadCsvObject = {};
      columns.forEach((column) => {
        downloadCsvObject[column.id] = sorted[column.id];
      });
      return Object.values(downloadCsvObject);
    }));
    this.setState({ downloadCsv }, () => {
      this.csvRef.current.link.click();
    });
  };

  render() {
    const {
      providers: { loading: providerLoading },
      auth: { loading: authLoading },
      bank: { loading: bankLoading, list: bankList }, t,
      documentType: { list: documentTypesList },
      role: { list: roleList },
    } = this.props;
    const {
      name,
      documentType,
      documentNumber,
      bankType,
      email,
      password,
      confirmPassword,
      downloadCsv,
      gateways,
      file,
      binaryFile,
    } = this.state;
    const loading = authLoading || bankLoading || providerLoading;
    return (
      <Toggle>
        {({ toggled, onToggle }) => {
          const { title, columns, data } = this.getGridSettings(onToggle);
          return (
            <div className="dashboard">
              {loading && <Loading />}
              <div className="columns is-multiline">
                <Fragment>
                  {
                    toggled
                    && (
                      <Modal onToggle={onToggle}>{loading && <Loading/>}
                        <BankForm
                          documentTypesList={documentTypesList}
                          roleList={roleList}
                          name={name}
                          documentNumber={documentNumber}
                          email={email}
                          password={password}
                          confirmPassword={confirmPassword}
                          handleFileChange={this.handleFileChange}
                          handleInputChange={this.handleInputChange}
                          handleSelectChange={this.handleSelectChange}
                          t={t}
                          onSubmit={() => this.editProvider(onToggle)}
                          documentType={documentType}
                          bankType={bankType}
                          title={t('updateBank')}
                          gatewayList={gateways}
                          file={file}
                          fileName={binaryFile && binaryFile.name}
                          //src={`${API_URL}b2b/resource-file?nameFile=${bankList[87].resource}`}
                        />
                      </Modal>
                    )
                  }
                  {data
                    && (
                      <div className="column is-full">
                        <div className="panel-BTNS">
                          <Link
                            className="button custom-btn is-primary"
                            to="/register-bank"
                            onClick={() => onToggle({
                              // transaction: cellInfo.original,
                              // paymentCondition: advance_condition,
                              // onSubmit: this.advancePayment,
                            })}
                          >
                            {/* <i className="fas fa-plus mr-8px" aria-hidden="true" /> */}
                            {t('registerAdministrator')}
                          </Link>
                          &nbsp;&nbsp;
                          <button
                            className="button custom-btn is-outlined is-link"
                            onClick={this.download}
                          >
                            <i className="fas fa-file-csv mr-8px" aria-hidden="true"/>
                            {`${t('download')} CSV`}
                          </button>
                          <CSVLink
                            data={downloadCsv}
                            filename="summary_b2b.csv"
                            className="hidden"
                            ref={this.csvRef}
                          />
                        </div>
                        <Panel headingText={title}>
                          <TableSimple data={data} columns={columns} reff={this.selectTable}/>
                        </Panel>
                      </div>
                    )}
                </Fragment>
              </div>
            </div>
          );
        }}
      </Toggle>
    );
  }
}

Banks.propTypes = {
  auth: PropTypes.shape(Object).isRequired,
  bank: PropTypes.shape(Object).isRequired,
  documentType: PropTypes.shape(Object).isRequired,
  role: PropTypes.shape(Object).isRequired,
  // stats: PropTypes.arrayOf(Object).isRequired,
  // transactions: PropTypes.shape(Object).isRequired,
  t: PropTypes.func.isRequired,
  editProvider: PropTypes.func.isRequired,
  showMessage: PropTypes.func.isRequired,
  fetchDocumentTypes: PropTypes.func.isRequired,
  fetchRoles: PropTypes.func.isRequired,
  fetchBanks: PropTypes.func.isRequired,
  updateStatus: PropTypes.func.isRequired,
};

const mapStateToProps = ({
                           transactions,
                           stats,
                           auth,
                           bank,
                           role,
                           documentType,
                           gatewayActions,
                           providers,
                         }) => ({
  transactions,
  stats,
  auth,
  bank,
  role,
  documentType,
  gatewayActions,
  providers,
});
export default compose(
  connect(mapStateToProps, {
    ...statsActions,
    ...transactionsActions,
    ...clientsActions,
    ...providersActions,
    ...bankActions,
    ...authActions,
    ...roleActions,
    ...documentTypeActions,
    ...gatewayActions,
  }),
  withNamespaces(),
)(Banks);
