/**
 * Created by Jonas on 29/08/19.
*/

import React from 'react';
import './styles.scss';
import PropTypes from 'prop-types';
import Panel from '../Panel';
import Form from '../Form';
import TextInput from '../TextInput';

const RoleForm = ({
  t,
  onSubmit,
  handleInputChange,
  name,
  editable,
  handleSelectChange,
  typeList,
  roleType,
  is_approver,
}) => (
  <Panel headingText={editable ? '' : t('registerProfile') } usedInModals>
    <Form
      submitText={t('save')}
      onSubmit={(event) => {
        event.preventDefault();
        onSubmit();
      }}
      buttonClasses="mr-8px"
      className={editable && 'no-background'}
    >
      {/* <div className="field is-horizontal subtitle is-5 text-black">
                {t('demographicInformation')}
              </div> */}
      <div className="field is-horizontal">
        <div className="field-body columns">
          <div className="column is-tree-fifths">
            <TextInput
              name="name"
              label={t('profileName')}
              value={name}
              onChange={handleInputChange}
              // placeholder={t('name')}
              placeholder={t('createRolPlaceholder')}
              maxlength={40}
              type="text"
              controlClasses="is-expanded"
              title="Three letter country code"
              isRequired
            />
          </div>
      </div>
      </div>
      <div className="field is-horizontal">
        <div className="field-body columns">
          <div className='RoleForm__checkbox column is-tree-fifths'>
            <input
              type="checkbox"
              name="is_approver"
              onChange={handleInputChange}
              checked={!is_approver}
              isRequired
            />
            <span>No ejecutar acciones en las facturas</span>
          </div>
        </div>
      </div>
    </Form>
  </Panel>
);

RoleForm.propTypes = {
  t: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  handleInputChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  roleType: PropTypes.string.isRequired,
  handleSelectChange: PropTypes.func.isRequired,
  typeList: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default RoleForm;
