import React, { useEffect, useState } from "react"
import "./styles.scss"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { compose } from "redux"
import { clientsActions } from "../../state/ducks/clients"
import { withNamespaces } from "react-i18next"
import { API_URL, TOAST_CONFIG } from "../../config/constants"
import {
  Loading,
  Panel,
  PaymentDetailForm,
  PaymentLogForm,
} from "../../components"

const PaymentDetail = ({
  t,
  showMessage,
  auth: {
    user: {
      b2b: { user_type, type_user },
    },
  },
  clients: {
    paymentRecords: { currentPayment },
    loading,
  },
  history,
}) => {
  useEffect(() => {}, [])

  return (
    <>
      {loading && <Loading />}
      {/* INSTRUCTION DETAIL */}
      <Panel headingText={t("instructionDetail")}>
        <PaymentDetailForm currentPayment={currentPayment} t={t} history={history}/>
      </Panel>

      {/* PAYMENT LOG */}
      {user_type === "TDC" && (
        <Panel headingText={t("gatewayInformation")}>
          <PaymentLogForm log={currentPayment.log} t={t} />
          <div className="accept-return-btns">
            <button
              className="button custom-btn is-primary"
              onClick={() => history.push({ pathname: "/payment-record" })}
            >
              {t("return")}
            </button>
          </div>
        </Panel>
      )}
    </>
  )
}

PaymentDetail.propTypes = {
  auth: PropTypes.shape(Object).isRequired,
  i18n: PropTypes.shape(Object).isRequired,
  t: PropTypes.func.isRequired,
  // showMessage: PropTypes.func.isRequired,
}

const mapStateToProps = ({ auth, clients }) => ({
  auth,
  clients,
})

export default compose(
  connect(mapStateToProps, {
    // ...clientsActions,
  }),
  withNamespaces()
)(PaymentDetail)
