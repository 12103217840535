import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import PropTypes from 'prop-types';
import { Loading, Panel, TableSimple } from '../../components';
import { authActions } from '../../state/ducks/auth';
import { transactionsActions } from '../../state/ducks/transactions';
import { formatCurrency, formatDate } from '../../util';
import { TOAST_CONFIG } from '../../config/constants';

class ManualResolution extends Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  resetState = () => {
    this.setState(this.getInitialState());
  };

  getInitialState = () => ({
    id: '',
    name: '',
  });

  componentDidMount() {
    const {
      listTransactionsSender,
      auth: { user: { b2b: { id } } },
    } = this.props;
    listTransactionsSender({
      params: {
        id,
      },
    });
  }

  getGridSettings = () => {
    const {
      t,
      transactions: { sender },
      listTransactionsSender,
      fetchTransaction,
      setTransaction,
      auth: { user: { b2b: { id } } },
      history,
      showMessage,
    } = this.props;
    const columns = [
      {
        expander: true,
        show: false,
      },
      {
        id: 'client', // Required because our accessor is not a string
        Header: t('client'),
        accessor: d => `${d.sender.idPerson.name}`,
      },
      {
        id: 'invoicesNumber', // Required because our accessor is not a string
        Header: t('invoicesNumber'),
        accessor: d => `${d.invoice_number}`,
      },
      {
        id: 'paymentDate', // Required because our accessor is not a string
        Header: t('paymentDate'),
        accessor: d => formatDate(d.due_date),
      },
      {
        id: 'totalPay', // Required because our accessor is not a string
        Header: t('totalPay'),
        accessor: d => formatCurrency(d.adjusted_amount),
      },
      {
        id: 'provider', // Required because our accessor is not a string
        Header: t('provider'),
        accessor: d => `${d.receiver.idPerson.name}`,
      },
      {
        id: 'status', // Required because our accessor is not a string
        Header: t('status'),
        accessor: d => `${d.senderStatus.description}`,
      },
      {
        id: 'action', // Required because our accessor is not a string
        Header: t('action'),
        filterable: false,
        accessor: d => `${d.id} `,
        Cell: cellInfo => (
          <table>
            <tbody>
            <tr>
              <td>
                <button
                  className="button is-primary is-inverted"
                  onClick={() => {
                    fetchTransaction({
                      params: {
                        id: cellInfo.original.id,
                        role: 'sender',
                      },
                      callback: () => {
                        history.push('/invoice-detail');
                      }
                    });
                  }}
                >
                  <span className="icon" title={t('detail')}>
                    <i className="far fa-file-alt"/>
                  </span>
                </button>
              </td>
              <td>
                <button
                  className="button is-success"
                  onClick={() => {
                    setTransaction({
                      params: {
                        id: cellInfo.original.id,
                      },
                      callback: () => {
                        showMessage({
                          message: t('invoiceMarkPaid', { invoiceNumber: cellInfo.original.invoice_number }),
                          config: TOAST_CONFIG.SUCCESS,
                        });
                        listTransactionsSender({
                          params: {
                            id,
                          },
                        });
                      }
                    });
                  }}
                >
                  {t('paidOut')}
                </button>
              </td>
            </tr>
            </tbody>
          </table>
        ),
      },
    ];
    const data = sender;
    const title = t('invoiceManualResolution');
    return {
      columns,
      data,
      title,
    };
  };

  handleInputChange = (event) => {
    const {
      target: {
        checked, value: targetValue, type, name,
      },
    } = event;
    const value = type === 'checkbox' ? checked : targetValue;
    this.setState({
      [name]: value,
    });
  };

  render() {
    const {
      transactions: { loading: transactionsLoading },
    } = this.props;
    const { title, columns, data } = this.getGridSettings();
    return (
      <div className="dashboard">
        {(transactionsLoading) && <Loading/>}
        <div className="columns is-multiline">
          <div className="column is-full">
            <Panel headingText={title}>
              <div>
                <TableSimple data={data} columns={columns}/>
              </div>
            </Panel>
          </div>
        </div>
      </div>
    );
  }
}

ManualResolution.propTypes = {
  showMessage: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  listTransactionsSender: PropTypes.func.isRequired,
};

const mapStateToProps = ({ transactions, auth, }) => (
  {
    transactions,
    auth,
  }
);

export default compose(
  connect(mapStateToProps, {
    ...transactionsActions,
    ...authActions,
  }),
  withNamespaces(),
)(ManualResolution);
