import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';
import { withNamespaces } from 'react-i18next';
import { Form, TextInput } from '../../../../../components';

const FormLogin = ({
                      t,
                      handleSubmit,
                      options,
                      handleInputChange,
                      data,
                      children,
                    }) => {
  const { username, password } = data;
  return (
    <div className="FormLogin">
      <Form
        className="column is-full no-background"
        submitText={t('enter')}
        onSubmit={handleSubmit}
        buttonClasses="custom-btn is-fullwidth"
        band={'white'}
        isBTNFull
      >
        <TextInput
          name="username"
          placeholder={t('username')}
          type="text"
          onChange={handleInputChange}
          value={username}
          login={true}
          // inputClasses='custom-input'
          />
        <TextInput
          placeholder={t('password')}
          type="password"
          name="password"
          onChange={handleInputChange}
          value={password}
          login={true}
          // inputClasses='custom-input'
        />
        {children}
      </Form>
    </div>
  );
};

FormLogin.propTypes = {
  t: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  options: PropTypes.shape(),
  handleInputChange: PropTypes.func.isRequired,
  data: PropTypes.shape({
    username: PropTypes.string.isRequired,
    password: PropTypes.string.isRequired,
    device_info: PropTypes.string,
  }).isRequired,
};

FormLogin.defaultProps = {
  options: {},
};

export default withNamespaces()(FormLogin);
