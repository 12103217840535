import { createAction } from "redux-actions";
import types from "./types";

const startFetch = createAction(types.START_FETCH);
const endFetch = createAction(types.END_FETCH);
const endError = createAction(types.END_ERROR);
const updateProfile = createAction(types.UPDATE_PROFILE);
const getContacts = createAction(types.GET_CONTACTS);
const updateUser = createAction(types.UPDATE_USER);
const updateAccount = createAction(types.UPDATE_ACCOUNT);
const updateContacts = createAction(types.UPDATE_CONTACTS);
const signIn = createAction(types.SIGN_IN);
const logout = createAction(types.LOGOUT);
const logged = createAction(types.LOGGED);
const changeLanguage = createAction(types.CHANGE_LANGUAGE);
const clear = createAction(types.CLEAR);
const updateStatus = createAction(types.UPDATE_STATUS);
const passwordReset = createAction(types.PASSWORD_RESET);
const passwordResetToken = createAction(types.PASSWORD_RESET_TOKEN);
const passwordResetLogin = createAction(types.PASSWORD_RESET_LOGIN);
const changeRole = createAction(types.CHANGE_ROLE);
const saveRole = createAction(types.SAVE_ROLE);
const updateB2B = createAction(types.UPDATE_B2B);
const saveB2B = createAction(types.SAVE_B2B);
const request2FA = createAction(types.REQUEST_2FA);
const change2FA = createAction(types.CHANGE_2FA);
const validateCode = createAction(types.VALIDATE_CODE);
const getUserInfo = createAction(types.GET_USER_INFO);
const saveConfirmation = createAction(types.SAVE_CONFIRMATION);
const loginProfiles = createAction(types.LOGIN_PROFILES);
const saveProfiles = createAction(types.SAVE_PROFILES);

const showMessage = createAction(
  types.MESSAGE,
  ({ message }) => ({
    message,
  }),
  ({ config }) => ({
    config,
  })
);

export default {
  startFetch,
  endFetch,
  endError,
  updateProfile,
  getContacts,
  updateUser,
  updateContacts,
  signIn,
  logout,
  logged,
  showMessage,
  changeLanguage,
  clear,
  updateAccount,
  updateStatus,
  passwordReset,
  passwordResetToken,
  passwordResetLogin,
  changeRole,
  saveRole,
  updateB2B,
  saveB2B,
  request2FA,
  change2FA,
  validateCode,
  getUserInfo,
  saveConfirmation,
  loginProfiles,
  saveProfiles,
};
