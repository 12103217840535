import { createAction } from 'redux-actions';
import types from './types';

const startFetch = createAction(types.START_FETCH);
const endFetch = createAction(types.END_FETCH);
const createPaymentCondition = createAction(types.CREATE_PAYMENT_CONDITION);
const editPaymentCondition = createAction(types.EDIT_PAYMENT_CONDITION);
const deletePaymentCondition = createAction(types.DELETE_PAYMENT_CONDITION);
const getPaymentCondition = createAction(types.GET_PAYMENT_CONDITION);
const getPaymentConditionRange = createAction(types.GET_PAYMENT_CONDITION_RANGE);
const savePaymentCondition = createAction(types.SAVE_PAYMENT_CONDITION);
const paymentRequest = createAction(types.PAYMENT_REQUEST);
const requestBank = createAction(types.REQUEST_BANK);
const saveListReceiver = createAction(types.SAVE_LIST_RECEIVER);
const requestPaymentCondition = createAction(types.REQUEST_PAYMENT_CONDITION);
const approveProductPayment = createAction(types.APPROVE_PRODUCT_PAYMENT);
const unsuscribeProductPayment = createAction(types.UNSUSCRIBE_PRODUCT_PAYMENT);
const requestUnsubscribeProduct = createAction(types.REQUEST_UNSUBSCRIBE_PRODUCT);
const acceptPaymentCondition = createAction(types.ACCEPT_PAYMENT_CONDITION);
const getPaymentConditionLog = createAction(types.GET_PAYMENT_CONDITION_LOG);
const paymentConditionLog = createAction(types.PAYMENT_CONDITION_LOG);
const checkPaymentRequest = createAction(types.CHECK_PAYMENT_REQUEST);
const getInfoPaymentCondition = createAction(types.GET_INFO_PAYMENT_CONDITION);
const getAdminPaymentConditions = createAction(types.GET_ADMIN_PAYMENT_CONDITIONS);
const activateBpp = createAction(types.ACTIVATE_BPP);

const showMessage = createAction(types.MESSAGE,
  ({ message }) => ({
    message,
  }),
  ({ config }) => ({
    config,
  }));

export default {
  startFetch,
  endFetch,
  showMessage,
  createPaymentCondition,
  editPaymentCondition,
  deletePaymentCondition,
  getPaymentCondition,
  getPaymentConditionRange,
  savePaymentCondition,
  paymentRequest,
  requestBank,
  saveListReceiver,
  requestPaymentCondition,
  approveProductPayment,
  unsuscribeProductPayment,
  requestUnsubscribeProduct,
  acceptPaymentCondition,
  getPaymentConditionLog,
  paymentConditionLog,
  checkPaymentRequest,
  getInfoPaymentCondition,
  getAdminPaymentConditions,
  activateBpp
};
