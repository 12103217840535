const START_FETCH = 'paymentConditions/START_FETCH';
const END_FETCH = 'paymentConditions/END_FETCH';
const API_CALL = 'paymentConditions/API_CALL';
const MESSAGE = 'paymentConditions/MESSAGE';
const CREATE_PAYMENT_CONDITION = 'paymentConditions/CREATE_PAYMENT_CONDITION';
const EDIT_PAYMENT_CONDITION = 'paymentConditions/EDIT_PAYMENT_CONDITION';
const DELETE_PAYMENT_CONDITION = 'paymentConditions/DELETE_PAYMENT_CONDITION';
const GET_PAYMENT_CONDITION = 'paymentConditions/GET_PAYMENT_CONDITION';
const GET_PAYMENT_CONDITION_RANGE = 'paymentConditions/GET_PAYMENT_CONDITION_RANGE';
const SAVE_PAYMENT_CONDITION = 'paymentConditions/SAVE_PAYMENT_CONDITION';
const PAYMENT_REQUEST = 'paymentConditions/PAYMENT_REQUEST';
const REQUEST_BANK = 'paymentConditions/REQUEST_BANK';
const SAVE_LIST_RECEIVER = 'paymentConditions/SAVE_LIST_RECEIVER';
const REQUEST_PAYMENT_CONDITION = 'paymentConditions/REQUEST_PAYMENT_CONDITION';
const APPROVE_PRODUCT_PAYMENT = 'paymentConditions/APPROVE_PRODUCT_PAYMENT';
const UNSUSCRIBE_PRODUCT_PAYMENT = 'paymentConditions/UNSUSCRIBE_PRODUCT_PAYMENT';
const REQUEST_UNSUBSCRIBE_PRODUCT = 'paymentConditions/REQUEST_UNSUBSCRIBE_PRODUCT';
const ACCEPT_PAYMENT_CONDITION = 'paymentConditions/ACCEPT_PAYMENT_CONDITION';
const GET_PAYMENT_CONDITION_LOG = 'paymentConditions/GET_PAYMENT_CONDITION_LOG';
const PAYMENT_CONDITION_LOG = 'paymentConditions/PAYMENT_CONDITION_LOG';
const CHECK_PAYMENT_REQUEST = 'paymentConditions/CHECK_PAYMENT_REQUEST';
const GET_INFO_PAYMENT_CONDITION = 'paymentConditions/GET_INFO_PAYMENT_CONDITION';
const GET_ADMIN_PAYMENT_CONDITIONS = 'paymentConditions/GET_ADMIN_PAYMENT_CONDITIONS';
const ACTIVATE_BPP = 'paymentConditions/ACTIVATE_BPP';

export default {
  START_FETCH,
  END_FETCH,
  API_CALL,
  MESSAGE,
  CREATE_PAYMENT_CONDITION,
  EDIT_PAYMENT_CONDITION,
  DELETE_PAYMENT_CONDITION,
  GET_PAYMENT_CONDITION,
  GET_PAYMENT_CONDITION_RANGE,
  SAVE_PAYMENT_CONDITION,
  PAYMENT_REQUEST,
  REQUEST_BANK,
  SAVE_LIST_RECEIVER,
  REQUEST_PAYMENT_CONDITION,
  APPROVE_PRODUCT_PAYMENT,
  UNSUSCRIBE_PRODUCT_PAYMENT,
  REQUEST_UNSUBSCRIBE_PRODUCT,
  ACCEPT_PAYMENT_CONDITION,
  GET_PAYMENT_CONDITION_LOG,
  PAYMENT_CONDITION_LOG,
  CHECK_PAYMENT_REQUEST,
  GET_INFO_PAYMENT_CONDITION,
  GET_ADMIN_PAYMENT_CONDITIONS,
  ACTIVATE_BPP
};
