import { itemActions, itemTypes } from '../ducks/item';

const itemData = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== itemTypes.FETCH_ITEMS) {
    return;
  }

  const {
    category_id,
  } = action.payload;

  dispatch({
    type: itemTypes.API_CALL,
    payload: {
      config: {
        method: 'GET',
        url:  `b2b/items?category_id=${category_id}`,
      },
      authorization: true,
      onStart: itemActions.startFetch,
      onComplete: ({ data: responseData }) => {
        const { success, data } = responseData;
        if (success) {
          dispatch(itemActions.updateItems({
            list: data,
          }));
        }
      },
      onEnd: itemActions.endFetch,
    },
  });
};

const regionsData = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== itemTypes.FETCH_REGIONS) {
    return;
  }

  const {
    callback = () => {
    }
  } = action.payload;

  dispatch({
    type: itemTypes.API_CALL,
    payload: {
      config: {
        method: 'GET',
        url: '/address/get-province',
      },
      authorization: true,
      onStart: itemActions.startFetch,
      onComplete: ({ data: responseData }) => {
        const { success, data } = responseData;
        if (success) {
          callback(data);
        }
      },
      onEnd: itemActions.endFetch,
    },
  });
};

const communesData = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== itemTypes.FETCH_COMMUNES) {
    return;
  }

  const {
    province_id,
    callback = () => {
    }
  } = action.payload;

  dispatch({
    type: itemTypes.API_CALL,
    payload: {
      config: {
        method: 'GET',
        url: `/address/get-canton?province_id=${province_id}`,
      },
      authorization: true,
      onStart: itemActions.startFetch,
      onComplete: ({ data: responseData }) => {
        const { success, data } = responseData;
        if (success) {
          callback(data);
        }
      },
      onEnd: itemActions.endFetch,
    },
  });
};

const citiesData = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== itemTypes.FETCH_CITIES) {
    return;
  }

  const {
    canton_id,
    callback = () => {
    }
  } = action.payload;

  dispatch({
    type: itemTypes.API_CALL,
    payload: {
      config: {
        method: 'GET',
        url: `/address/get-district?canton_id=${canton_id}`,
      },
      authorization: true,
      onStart: itemActions.startFetch,
      onComplete: ({ data: responseData }) => {
        const { success, data } = responseData;
        if (success) {
          callback(data);
        }
      },
      onEnd: itemActions.endFetch,
    },
  });
};

const categoriesData = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== itemTypes.FETCH_CATEGORIES) {
    return;
  }

  const {
    callback = () => {
    }
  } = action.payload;

  dispatch({
    type: itemTypes.API_CALL,
    payload: {
      config: {
        method: 'GET',
        url: 'b2b/item-categories',
      },
      authorization: true,
      onStart: itemActions.startFetch,
      onComplete: ({ data: responseData }) => {
        const { success, data } = responseData;
        if (success) {
          callback(data);
          dispatch(itemActions.updateItems({
            list: data,
          }));
        }
      },
      onEnd: itemActions.endFetch,
    },
  });
};

const fetchIndustries = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== itemTypes.FETCH_INDUSTRIES) {
    return;
  }

  const {
    callback = () => {
    }
  } = action.payload;

  dispatch({
    type: itemTypes.API_CALL,
    payload: {
      config: {
        method: 'GET',
        url: 'b2b/industries',
      },
      authorization: true,
      onStart: itemActions.startFetch,
      onComplete: ({ data: responseData }) => {
        const { success, data } = responseData;
        if (success) {
          callback(data);
          dispatch(itemActions.updateIndustries({
            industries: data,
          }));
        }
      },
      onEnd: itemActions.endFetch,
    },
  });
};

export default [itemData, regionsData, communesData, citiesData, categoriesData, fetchIndustries];
