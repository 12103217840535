import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import { webpayActions } from '../../state/ducks/webpay';
import './styles.scss';
import Panel from '../../components/Panel';
import SantanderCard from '../../resources/images/assets/amex-card.png';
import { Loading, TableSimple, Toggle } from '../../components';
import Modal from '../../components/Modal';
import TextInput from '../../components/TextInput';
import SelectSingle from '../../components/SelectSingle';
import { cardActions } from '../../state/ducks/card';
import { TOAST_CONFIG } from '../../config/constants';
import { getPath } from '../../util';

class ListCards extends Component {
    constructor(props) {
        super(props);
        this.state = this.getInitialState();
    }

    getInitialState = () => ({
        cards: [],
        cuttingCycle: -1,
        cuttingCycles: [],
        number_card: '',
        startDate: '',
        endingDate: '',
        cardSubstitution: false,
    });

    componentDidMount(){
        const { auth: { user: { user_id }}, listCards } = this.props;
        listCards({
            params: {
                user_id,
            },
            callback: (response) => {
                this.setState({ cards: response });
            }
        });
    }

    getGridSettings = (onToggle) => {
        const {
            t,
            getCycleCard,
            auth: {
                user: { b2b: { bank_id } },
            },
        } = this.props;
        const { cards } = this.state;
        let columns;
        /*let data = null;
        let title = null;*/

        columns = [
            {
                expander: true,
                show: false,
            },
            {
                id: 'card', // Required because our accessor is not a string
                Header: t('target'),
                headerClassName: 'wordwrap',
                //width: 160,
                Cell: cellInfo => (<img src={SantanderCard} alt="card" style={{
                      marginRight: '1.5em',
                      width: '3em'
                  }}/>
                )
                //accessor: d => `${getPath(['idPerson', 'idDocumentType', 'document_type'], d)} ${getPath(['idPerson', 'document_number'], d)} `,
            },
            {
                id: 'number', // Required because our accessor is not a string
                Header: t('targetNumber'),
                headerClassName: 'wordwrap',
                //width: 210,
                accessor: d => `... ${d.number.substr(11)}`,
            },
            {
                id: 'due_pay', // Required because our accessor is not a string
                Header: t('expirationDate'),
                headerClassName: 'wordwrap',
                //width: 160,
                accessor: d => `${d.due_pay}`,
            },
            /*{
                id: 'cycle', // Required because our accessor is not a string
                Header: t('cuttingCycle'),
                headerClassName: 'wordwrap',
                width: 160,
                accessor: d => d.cycle !== null ? `${d.cycle.cycle}` : '',
            },*/
            /*
            {
                id: 'date_init', // Required because our accessor is not a string
                Header: t('cortDate'),
                headerClassName: 'wordwrap',
                width: 160,
                accessor: d => d.cycle !== null ? `${d.cycle.date_init}` : '',
            },
            {
                id: 'date_end', // Required because our accessor is not a string
                Header: t('datePayment'),
                headerClassName: 'wordwrap',
                width: 160,
                accessor: d => d.cycle !== null ? `${d.cycle.date_end}` : '',
            },
            */
            // {
                // id: 'action', // Required because our accessor is not a string
                // Header: t('action'),
                // headerClassName: 'wordwrap',
                // style: { whiteSpace: 'nowrap' },
                // accessor: d => `${d.id} `,
                // Cell: cellInfo => (
                //   <table>
                //       <tbody>
                //       <tr>
                //           <td>
                //               <button
                //                 className="button is-primary is-inverted"
                //                 title={t('edit')}
                //                 onClick={() => {
                //                     getCycleCard({
                //                         params: {
                //                             bank: bank_id,
                //                         },
                //                         callback: (response) => this.setState({
                //                             cuttingCycles: response,
                //                             number_card: cellInfo.original.number.substr(11),
                //                             startDate: cellInfo.original.cycle !== null ? cellInfo.original.cycle.date_init : '',
                //                             endingDate: cellInfo.original.cycle !== null ? cellInfo.original.cycle.date_end : '',
                //                             cuttingCycle: cellInfo.original.cycle !== null ?
                //                               response.map(
                //                                 (listClientMap, index) => ({
                //                                     ...listClientMap,
                //                                     index,
                //                                 }),)
                //                                 .filter(listClientFil => listClientFil.id === cellInfo.original.cycle.billing_cycle_id)[0].index
                //                               : -1,
                //                         }, () => {
                //                             onToggle({
                //                                 isEdit: true,
                //                                 paymentCondition: cellInfo.original,
                //                                 id_card: cellInfo.original.id,
                //                             });
                //                         }),
                //                     });
                                    /*getClients({
                                        params: {
                                            //id: cellInfo.original.id,
                                        },
                                        callback: () => this.setState({
                                            id: cellInfo.original.id,
                                            baseDays: 0,
                                            percentage: 0,
                                            discountRate: 0,
                                            receiverDiscountShare: 0,
                                            // editable: true,
                                            hidden: true,
                                        }, () => {
                                            onToggle({
                                                isEdit: true,
                                                paymentCondition: cellInfo.original,
                                            });
                                        }),
                                    });
                                    providerCommerceCode({
                                        params: {
                                            user: cellInfo.original.id,
                                            role: role_id,
                                        },
                                        callback: (response) => {
                                            this.setState({ codes: response.commerce_codes });
                                        },
                                    });*/
                            //     }}
                            //   >
                            //       <i className="far fa-edit mr-8px" aria-hidden="true"/>
                            //       {`${t('edit')}`}
                            //   </button>
                              /*<Link
                                    className="button is-primary is-inverted ml-8px"
                                    to="#"
                                    onClick={() => {
                                    }}
                                  >
                                      <i className="fas fa-trash-alt mr-8px" aria-hidden="true"/>
                                      {`${t('delete')}`}
                                  </Link>*/

                            //   <button
                            //     className="button is-primary is-inverted"
                            //     onClick={() => {
                            //         onToggle({
                            //             cardSubstitution: true,
                            //             idCard: cellInfo.original.id,
                            //         });
                                    /*this.setState({
                                        cardSubstitution: true,
                                    })*/
                //                 }}
                //                 title={t('creditCardSubstitution')}
                //               >
                //                   <i className="fas fa-sync-alt mr-8px" aria-hidden="true"/>
                //               </button>
                //           </td>
                //       </tr>
                //       </tbody>
                //   </table>
                // )
            // },
        ];

        return {
            columns,
            data: cards,
            title: t('cardList'),
        };
    };

    handleSelectChange = (newVal, label) => {
        const {
            //cuttingCycle,
            cuttingCycles,
        } = this.state;
        const {
            //card: { cycle },
            getPeriodBilling
        } = this.props;


        this.setState({
            [label.name]: newVal.value,
        });

        if (newVal.value !== '') {
            getPeriodBilling({
                params: {
                    id: cuttingCycles[newVal.value].id,
                },
                callback: responseData => {
                    this.setState({
                        startDate: responseData.date_init,
                        endingDate: responseData.date_end,
                    });
                }
            });
        }
    };

    closeModal = (onToggle, type, payment_id) => {
        const {
            t,
            auth: { user: { user_id } },
            //history,
            showMessage,
            listCards,
            updateBillingPaymentPerson,
        } = this.props;
        const {
            cuttingCycle,
            cuttingCycles,
        } = this.state;

        if (type === 'save') {
            updateBillingPaymentPerson({
                data: {
                    billing_cycle_id: cuttingCycles[cuttingCycle].id,
                    payment_id
                },
                callback: () => {
                    showMessage({
                        message: t('updatedBillingCycle'),
                        config: TOAST_CONFIG.SUCCESS,
                    });
                    listCards({
                        params: {
                            user_id,
                        },
                        callback: (response) => {
                            this.setState({ cards: response });
                        }
                    });
                    //history.push('/dashboard');
                }
            });
        }
        /*this.setState({
              cycleResp: true,
            status: '',
        });*/
        onToggle();
        //window.top.location.replace('/list-cards');
    };

    render() {
        const {
            t,
            history,
            //card: { loading: cardLoading },
            webpay: { loading: webpayLoading },
        } = this.props;

        const {
            //cards,
            number_card,
            cuttingCycle,
            cuttingCycles,
            /*startDate,
            endingDate,*/
        } = this.state;
        const loading = webpayLoading;

        return (
        <Toggle>
            {({ toggled, onToggle, toggleProps }) => {
                const { title, columns, data } = this.getGridSettings(onToggle);
                return (
                    <div className="dashboard">
                        <div className="columns is-multiline">
                            {loading && <Loading/>}
                            <Fragment>
                                {toggled && toggleProps.cardSubstitution && (
                                <Modal onToggle={onToggle}> {(loading) && <Loading/>}
                                    <Panel headingText={t('creditCardSubstitution')}>
                                        <div className="field is-horizontal">
                                            <div className="field-body columns">
                                                <div className="column">
                                                    <p>{t('replaceTheSelectedCard')}</p>
                                                    <br/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="field is-horizontal">
                                            <div className="field-body columns">
                                                <div className="column"
                                                    style={{ textAlign: 'center' }}>
                                                    <button
                                                        className="button is-primary"
                                                        onClick={() => history.push({
                                                            pathname: '/replace-card',
                                                            state: {
                                                                userInfo: toggleProps.idCard
                                                            },
                                                        })}
                                                    >
                                                        {t('accept')}
                                                    </button>
                                                </div>
                                                <div className="column"
                                                    style={{ textAlign: 'center' }}>
                                                    <button
                                                        className="button is-primary"
                                                        onClick={() => onToggle()}
                                                    >
                                                        {t('cancel')}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </Panel>
                                </Modal>
                                )}
                                {
                                    toggled && toggleProps.isEdit
                                    && (
                                    <Modal onToggle={onToggle}> {loading && <Loading/>}
                                        <div>
                                            <Panel headingText={t('enterCuttingCycle')}>
                                                <div className="field is-horizontal">
                                                    <div className="field-body columns">
                                                        <div className="column">
                                                            <TextInput
                                                                name="number_card"
                                                                label={t('targetNumber')}
                                                                value={'...' + number_card}
                                                                placeholder={t('targetNumber')}
                                                                type="text"
                                                                controlClasses="is-expanded"
                                                                inputClasses="has-text-right"
                                                                disabled={true}
                                                                //isRequired={true}
                                                            />
                                                        </div>
                                                        <div className="column">
                                                            <SelectSingle
                                                                name={'cuttingCycle'}
                                                                label={t('cuttingCycle')}
                                                                selectClasses="is-fullwidth"
                                                                placeholder={t('Select')}
                                                                onChange={this.handleSelectChange}
                                                                options={[
                                                                    ...cuttingCycles.map((item, index) => ({
                                                                        label: `${getPath(['period', 'date_init'], item)} - ${getPath(['period', 'date_end'], item)}`,
                                                                        //label: item.cycle,
                                                                        value: index,
                                                                    })),
                                                                ]}
                                                                value={cuttingCycle}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="field is-horizontal">
                                                    <div className="field-body columns">
                                                        {/**/}
                                                    </div>
                                                </div>
                                                <div className="field is-horizontal">
                                                    <div className="field-body columns">
                                                        {/*<div className="column">
                                                            <TextInput
                                                                name="startDate"
                                                                label={t('cortDate')}
                                                                value={startDate}
                                                                placeholder={t('startDate')}
                                                                type="text"
                                                                controlClasses="is-expanded"
                                                                disabled={true}
                                                                //isRequired={true}
                                                            />
                                                        </div>
                                                            <div className="column">
                                                            <TextInput
                                                            name="endingDate"
                                                            label={t('onlyCardPaymentDate')}
                                                            value={endingDate}
                                                            placeholder={t('endingDate')}
                                                            type="text"
                                                            controlClasses="is-expanded"
                                                            disabled={true}
                                                            //isRequired={true}
                                                            />
                                                            </div>*/}
                                                    </div>
                                                </div>
                                                <div className="field is-grouped is-grouped-right">
                                                    <button
                                                        type="button"
                                                        className="button is-primary"
                                                        onClick={this.closeModal.bind(this, onToggle, 'save', toggleProps.id_card)}
                                                        disabled={cuttingCycle === -1}
                                                    >
                                                        {t('save')}
                                                    </button>
                                                    &nbsp;&nbsp;
                                                    <button
                                                        type="button"
                                                        className="button is-primary is-danger"
                                                        onClick={this.closeModal.bind(this, onToggle, 'cancel', '')}
                                                    >
                                                        {t('cancel')}
                                                    </button>
                                                </div>
                                            </Panel>
                                        </div>
                                    </Modal>
                                    )
                                }
                            </Fragment>
                            {data
                            && (
                            <div className="column is-full">
                                <Panel headingText={title}>
                                    <div className="terms-box">
                                        <p style={{ marginBottom: '.5em' }}></p>
                                        <p>{t('secureCards')}</p>
                                    </div>
                                    <TableSimple data={data} columns={columns} />
                                </Panel>
                            </div>
                            )}
                        </div>
                    </div>
                  );
              }}
          </Toggle>
        );

    }

}

ListCards.propTypes = {
    t: PropTypes.func.isRequired,
    listCards: PropTypes.func.isRequired,
}

const mapStateToProps = ({ webpay, card, }) => (
  {
      card,
      webpay,
  }
);

export default compose(
  connect(mapStateToProps, {
      ...cardActions,
      ...webpayActions,
  }),
  withNamespaces(),
)(ListCards);