import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { withNamespaces } from 'react-i18next';
import {
  Form,
  Panel,
  SelectSingle,
  TextInput,
} from '../../../../components';

const ExtractForm = ({
  t,
  onChange,
  onSubmit,
  balance,
}) => (
  <Panel headingText={t('extract')}>
    <Form
      submitText={t('continue')}
      onSubmit={onSubmit}
      buttonClasses="is-right margin-right-5"
    >
      <div className="field is-horizontal">
        <div className="field-body">
          <TextInput
            name="originAccount"
            value={`${t('virtualAccount')} ${balance}`}
            onChange={onChange}
            type="text"
            label={t('originAccount')}
            disabled
            controlClasses="has-icons-left"
            icon="far fa-envelope"
            iconPosition="is-left"
          />
          <SelectSingle
            name="destinationAccount"
            label={t('destinationAccount')}
            disabled
            placeholder={t('destinationAccount')}
            onChange={onChange}
            options={[
              {
                text: t('extractAccount'),
                value: 1,
              },
            ]}
            value={1}
            selectClasses="is-fullwidth"
            controlClasses="has-icons-left"
            icon="far fa-globe"
            iconPosition="is-left"
          />
        </div>
      </div>
      <div className="field is-horizontal">
        <div className="field-body">
          <TextInput
            name="transaction_amount"
            label={t('amount')}
            placeholder={t('amountMoney')}
            onChange={onChange}
            type="number"
            disabled={false}
            fieldClasses="is-expanded"
            controlClasses="is-expanded"
            min={0.10}
            step={0.01}
          />
        </div>
      </div>
      <div className="field is-horizontal">
        <div className="field-body">
          <TextInput
            name="time"
            label={t('date')}
            value={moment()
              .format('YYYY-MM-DD')}
            onChange={onChange}
            type="text"
            disabled
            fieldClasses="is-expanded"
            controlClasses="is-expanded"
          />
        </div>
      </div>
    </Form>
  </Panel>
);

ExtractForm.propTypes = {
  t: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  balance: PropTypes.string.isRequired,
};

export default withNamespaces()(ExtractForm);
