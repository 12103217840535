import { Component } from 'react';
import PropTypes from 'prop-types';

class Toggle extends Component {
  constructor(props) {
    super(props);
    this.state = {
      toggled: false,
      toggleProps: null,
    };
    // eslint-disable-next-line no-underscore-dangle
    this._isMounted = false;
  }

  componentDidMount() {
    // eslint-disable-next-line no-underscore-dangle
    this._isMounted = true;
  }

  componentWillUnmount() {
    // eslint-disable-next-line no-underscore-dangle
    this._isMounted = false;
  }

  onToggle = (toggleProps = {}) => {
    // eslint-disable-next-line no-underscore-dangle
    if (this._isMounted) {
      this.setState(({ toggled }) => ({
        toggled: !toggled,
        toggleProps,
      }));
    }
  };

  openToggle = (toggleProps = {}) => {
    // eslint-disable-next-line no-underscore-dangle
    if (this._isMounted) {
      this.setState(() => ({
        toggled: true,
        toggleProps,
      }));
    }
  };

  closeToggle = (toggleProps = {}) => {
    // eslint-disable-next-line no-underscore-dangle
    if (this._isMounted) {
      this.setState(() => ({
        toggled: false,
        toggleProps,
      }));
    }
  };

  render() {
    const { children } = this.props;
    const { toggled, toggleProps } = this.state;

    return children({
      toggled,
      onToggle: this.onToggle,
      openToggle: this.openToggle,
      closeToggle: this.closeToggle,
      toggleProps,
    });
  }
}

Toggle.propTypes = {
  children: PropTypes.func.isRequired,
};

export default Toggle;
