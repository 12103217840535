import React, { Component, Fragment } from 'react';
import { compose } from 'redux';
import './styles.scss';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { documentTypeActions } from '../../state/ducks/documentType';
import { leadActions } from '../../state/ducks/lead';
import { authActions } from '../../state/ducks/auth';
import { Loading, TableSimple, Toggle } from '../../components';
import TextInput from '../../components/TextInput';
import Panel from '../../components/Panel';
import Modal from '../../components/Modal';
import FormRecover from './components/FormRecover';
import { TOAST_CONFIG } from '../../config/constants';

class AccountProfile extends Component {
  constructor(props) {
    super(props);

    this.state = { ...this.getInitialState() };
  }

  getInitialState = () => ({
    name: '',
    documentType: '',
    documentNumber: '',
    email: '',
    password: '',
    address: '',
    edit: '',
    confirmPassword: '',
    phone: '',
    code: '',
  });

  componentDidMount() {
    const {
      //fetchDocumentTypes,
      auth: { user },
    } = this.props;
    this.setState({
      edit: true,
      name: user.name,
      documentType: user.document_type,
      documentNumber: user.document_number,
      address: user.b2b.address_full,
      email: user.email,
    });
   // fetchDocumentTypes({});
  }

  resetState = () => {
    this.setState(this.getInitialState());
  };

  handleInputChange = (event) => {
    const {
      target: {
        checked, value: targetValue, type, name,
      },
    } = event;
    const value = type === 'checkbox' ? checked : targetValue;
    this.setState({
      [name]: value,
    });
  };

  handleSelectChange = (newVal, label) => {
    this.setState({
      [label.name]: newVal.value,
    });
  };

  handleReset = (onToggle) => {
    const { t, showMessage, passwordResetLogin, history } = this.props;
    const { email, password, confirmPassword } = this.state;

    if (password !== '' && confirmPassword !== '') {
      if (password === confirmPassword) {
        passwordResetLogin({
          email,
          password,
          callback: () => {
            onToggle();
            showMessage({
              message: t('changePasswordSuccess'),
              config: TOAST_CONFIG.SUCCESS,
            });
          }
        });
      } else {
        showMessage({
          message: <strong style={{ color: '#ffffff' }}>{t('nonMatchingPasswords')}</strong>,
          config: TOAST_CONFIG.ERROR,
        });
      }
    } else if (password !== '' || confirmPassword !== '') {
      showMessage({
        message: <strong style={{ color: '#ffffff' }}>{t('emptyFields')}</strong>,
        config: TOAST_CONFIG.ERROR,
      });
    }
  };

  getGridSettings = () => {
    const {
      t,
      auth: { user: { banking_executive } },
    } = this.props;

    const columns = [
      {
        expander: true,
        show: false,
      },
      {
        id: 'name', // Required because our accessor is not a string
        Header: t('name'),
        accessor: d => d.name,
      },
      { 
        id: 'lastname',
        Header: t('adminLastname'),
        accessor: d => d.lastname,
      },
      {
        id: 'email', // Required because our accessor is not a string
        Header: t('email'),
        accessor: d => `${d.email}`,
      },
    ];

    const data = banking_executive;
    const title = t('bankingExecutives');
    return {
      columns,
      data,
      title,
    };

  };

  request2FA = (onToggle) => {
    const { 
      auth: { user: { username } },
      request2FA,
      showMessage,
    } = this.props;

    const data = {
      email: username,
    };

    request2FA({
      data,
      callback: (response) => {
        onToggle({
          is2FA: true,
        });
        if (response.data.success) {
          showMessage({
            message: response.data.data.message,
            config: TOAST_CONFIG.SUCCESS,
          });
        }
      }
    });
  };

  send2FA = (onToggle) => {

    const {
      auth: { user: { email, user_id: userId, profile: { user_id: user_id_profile } }, },
      change2FA,
      //history,
      showMessage,
      updateB2B,
    } = this.props;
    const { code } = this.state;
    
    const data = {
      code,
      email,
    }

    change2FA({
      data,
      callback: (response) => {
        updateB2B({
          params: {
            user_id: user_id_profile
          },
        });
        this.setState({ code: '' });
        if(response.data.success) {
          showMessage({
            message: response.data.data.message,
            config: TOAST_CONFIG.SUCCESS,
          });
        }
        this.setState({code: ''})
        onToggle();
      }
    });
  };

  render() {
    const {
      t, /*documentType: { list: documentTypesList }*/
      auth: { loading, user, user: { process_type, profile, currency, owner_name, b2b: { type_user, fma_is_active } } },
    } = this.props;
    const {
      name,
      documentNumber,
      email,
      address,
      documentType,
      edit,
      password,
      confirmPassword,
      code,
    } = this.state;

    const { title, columns, data } = this.getGridSettings();

    return (  
      <Toggle>
        {({ toggled, onToggle, toggleProps }) => (
          <Fragment>
            {
              toggled && toggleProps.isEdit
              && (
                <Modal 
                  onToggle={onToggle}
                  title={t('changePassword')}
                  > 
                  {loading && <Loading/>}
                  <FormRecover
                    handleInputChange={this.handleInputChange}
                    onSubmit={() => this.handleReset(onToggle)}
                    data={{
                      password,
                      confirmPassword
                    }}
                    usedInModals
                  />
                </Modal>
              )
            }
            {
              toggled && toggleProps.is2FA
              && (
                <Modal onToggle={onToggle} title={t('FMA')}> 
                  {loading && <Loading/>}
                  {/* <Panel headingText={t('FMA')}> */}
                    {/* <div className="field is-horizontal">
                      <div className="field-body columns"> */}
                        <div className="activate-2fa-form">
                          <p>{t('email2FA')}</p>
                          <TextInput
                            name="code"
                            value={code}
                            onChange={this.handleInputChange}
                            placeholder={t('verificationCode')}
                            type="text"
                            controlClasses="is-expanded"
                          />
                          <button
                            className="button custom-btn is-primary"
                            onClick={() => {this.send2FA(onToggle)}}
                          >
                            {t("send")}
                          </button>
                        </div>
                      {/* </div>
                    </div> */}
                  {/* </Panel> */}
                </Modal>
              )
            }
            <Panel headingText={t('profile')}>
              {loading && <Loading/>}
              <div className="account-profile-form">              
                <div className="field is-horizontal">
                  <div className="field-body columns">
                    <div className="column">
                      <TextInput
                        name="name"
                        label={t('name')}
                        value={name}
                        onChange={this.handleInputChange}
                        placeholder={t('name')}
                        type="text"
                        controlClasses="is-expanded"
                        disabled={edit}
                      />
                    </div>
                    {((profile.role == "buyer") || (profile.role == "seller")) &&
                    <div className="column is-one-fifth">
                      <TextInput
                        name="documentType"
                        label={t('documentType')}
                        selectClasses="is-fullwidth"
                        placeholder={t('documentType')}
                        value={documentType}
                        disabled={edit}
                      />
                    </div>}
                    <div className="column">
                      <TextInput
                        name="documentNumber"
                        label={t('documentNumber')}
                        value={documentNumber}
                        onChange={this.handleInputChange}
                        placeholder={t('documentNumber')}
                        type="text"
                        controlClasses="is-expanded"
                        disabled={edit}
                      />
                    </div>
                  </div>
                </div>
                <div className="field is-horizontal">
                  <div className="field-body columns">
                    <div className="column" style={{
                      display:
                        type_user === 'issuing_bank' || type_user === 'acquiring_bank' ||
                        type_user === 'cycle_loader' || type_user === 'rate_operator' || type_user === 'seller' || type_user === 'buyer' ? '' : 'none'
                    }}>
                      <TextInput
                        name="address"
                        label={type_user === 'issuing_bank' || type_user === 'acquiring_bank' || type_user === 'cycle_loader' || type_user === 'rate_operator'
                          ? t('selectedBank') : t('address')}
                        value={type_user === 'issuing_bank' || type_user === 'acquiring_bank' || type_user === 'cycle_loader' || type_user === 'rate_operator'
                          ? owner_name : address}
                        onChange={this.handleInputChange}
                        placeholder={type_user === 'issuing_bank' || type_user === 'acquiring_bank' || type_user === 'cycle_loader' || type_user === 'rate_operator'
                          ? t('selectedBank') : t('address')}
                        type="text"
                        controlClasses="is-expanded"
                        disabled={edit}
                      />
                    </div>
                    {(profile.role === 'buyer') &&
                    <div className="column">
                      <TextInput
                        name="phone"
                        label={t('contactPhone')}
                        value={user.phone !== null ? user.phone.phone_number : ''}
                        onChange={this.handleInputChange}
                        placeholder={t('phone')}
                        type="text"
                        controlClasses="is-expanded"
                        disabled={edit}
                      />
                    </div>
                    }
                    {(profile.role === "buyer" || profile.role === "seller") &&
                    <div className="column"
                        style={{ display: (process_type === null ? 'none' : '') }}>
                      <TextInput
                        name="address"
                        label={profile.role === 'buyer' ? t('typeOfApprovalFlow') : t('typeOfEmitFlow')}
                        value={process_type === null ? t('') : t(process_type.name)}
                        onChange={this.handleInputChange}
                        placeholder={t('address')}
                        type="text"
                        controlClasses="is-expanded"
                        disabled={edit}
                      />
                    </div>
                    }
                  </div>
                </div>
                <div className="field is-horizontal">
                  <div className="field-body columns">
                    <div className="column">
                      <TextInput
                        name="email"
                        label={t('email')}
                        value={email}
                        onChange={this.handleInputChange}
                        placeholder={t('email')}
                        type="email"
                        controlClasses="is-expanded"
                        disabled={edit}
                      />
                    </div>
                    {(profile.role === 'seller' && (currency.length > 0)) &&
                    <div className="column">
                      <TextInput
                        name="currency"
                        label={t('currency')}
                        value={currency[0].description + ' (' + currency[0].id + ')'}
                        placeholder={t('currency')}
                        disabled={edit}
                      />
                    </div>
                    }
                  </div>
                </div>
                <div className="field is-horizontal">
                  <div className="field-body columns">
                    <div className="column">
                      <Link
                        to="/account-profile"
                        onClick={() => {
                          onToggle({
                            isEdit: true,
                          });
                        }}
                      >
                        {t('changePassword')}
                      </Link>
                      <button
                        className={`button ${fma_is_active === 0 ? "is-success" : "is-danger"} is-outlined`}
                        style={{display: 'block', marginTop: '.5em'}}
                        type="button"
                        onClick={() => { this.request2FA(onToggle)}}
                      >
                        {fma_is_active === 0 ? t('enable2FA') : t('disable2FA')}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <br />
            </Panel>
            {(profile.role === "buyer") &&
            <Panel headingText={t('bankingExecutives')}>
              {loading && <Loading/>}
              <TableSimple title={title} data={data} columns={columns}/>
            </Panel>}
          </Fragment>
        )}
      </Toggle>
    );
  }
}

AccountProfile.propTypes = {
  auth: PropTypes.shape(Object).isRequired,
  documentType: PropTypes.shape(Object).isRequired,
  fetchDocumentTypes: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  passwordResetLogin: PropTypes.func.isRequired,
  history: PropTypes.shape(Object).isRequired,
  showMessage: PropTypes.func.isRequired,
};

const mapStateToProps = ({
  documentType,
  auth,
}) => ({
  documentType,
  auth,
});
export default compose(
  connect(mapStateToProps, {
    ...documentTypeActions,
    ...leadActions,
    ...authActions,
  }),
  withNamespaces(),
)(AccountProfile);
