const START_FETCH = 'loan/START_FETCH';
const END_FETCH = 'loan/END_FETCH';
const END_ERROR = 'loan/END_ERROR';
const API_CALL = 'loan/API_CALL';
const MESSAGE = 'loan/MESSAGE';
const GET_LOAN = 'loan/GET_LOAN';
const SAVE_LOAN = 'loan/SAVE_LOAN';
const GET_REFERENCE = 'loan/GET_REFERENCE';
const SAVE_REFERENCE = 'loan/SAVE_REFERENCE';

export default {
  START_FETCH,
  END_FETCH,
  END_ERROR,
  API_CALL,
  MESSAGE,
  GET_LOAN,
  SAVE_LOAN,
  GET_REFERENCE,
  SAVE_REFERENCE,
};