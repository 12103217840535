import React, { Component, Fragment } from 'react';
import { compose } from 'redux';
import './styles.scss';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {
  Area,
  AreaChart,
  Brush,
  CartesianGrid, Cell, Legend, Pie, PieChart,
  ResponsiveContainer, Sector,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import {
  Currency,
  InformativeBoxes,
  InvoiceDetailForm,
  Loading,
  Panel,
  PaymentConditionForm,
  TableSimple,
  Toggle,
} from '../../components';
import { statsActions } from '../../state/ducks/stats';
import { transactionsActions } from '../../state/ducks/transactions';
import { clientsActions } from '../../state/ducks/clients';
import { leadActions } from '../../state/ducks/lead';
import { paymentConditionsActions } from '../../state/ducks/paymentConditions';
import Modal from '../../components/Modal';
import { providersActions } from '../../state/ducks/providers';
import {
  formatCurrency,
  formatCurrencyCl,
  formatDate,
  formatFriendlyDate,
  formatFriendlyDateLong,
  formatIntCurrency,
  getPath,
  validateAlphaNumeric,
  formatAmount, numberFilter, onInputOnlyNumbers,
} from '../../util';
import { TOAST_CONFIG } from '../../config/constants';
import { documentTypeActions } from '../../state/ducks/documentType';
import SearchPersonForm from '../../components/SearchPersonForm';
import DateInput from '../../components/DateInput';
import { cardActions } from '../../state/ducks/card';
import { currencyActions } from '../../state/ducks/currency';
import Form from '../../components/Form';
import TextInput from '../../components/TextInput';
import { bankActions } from '../../state/ducks/bank';
import { authActions } from '../../state/ducks/auth';
import { Redirect } from 'react-router-dom';
import { roleActions } from '../../state/ducks/role';
import { itemActions } from '../../state/ducks/item';
import SelectSingle from '../../components/SelectSingle';

const ItemCard = ({ name, value }) => (
  <li>
    <b>
      {`${name}:`}
    </b>
    {value}
  </li>
);

ItemCard.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  getInitialState = () => ({
    id: '',
    client: null,
    provider: null,
    baseDays: 0,
    discountRate: 0,
    receiverDiscountShare: 0,
    clientPayment: 0,
    percentage: 0,
    days: 365,
    editable: false,
    startCountingFrom: 0,
    //limitAmount: '',
    inputValue: '',
    alert: false,
    document_number: '',
    name: '',
    email: '',
    min_amount: '',
    max_amount: '',
    search: true,
    stat: '',
    invoiceDate: new Date().setDate(new Date().getDate() - 1),
    invoiceDateUntil: new Date().setHours(23, 59, 59, 999),
    is_rate: false,
    user_type: '',
    user: '',
    rol: '',
    rut: '',
    arrayOrder: [],
    notification: '',
    codes: [],
    codeDescription: '',
    commerceCode: '',
    idCondition: '',
    role_filter: '',
    user_ncl: 'NCL',
    request: 0,
  });

  componentDidMount() {
    const {
      fetchBanks,
      fetchStats,
      listTransactionsSender,
      listTransactionsReceiver,
      auth: {
        user: {
          token,
          user_id,
          b2b: {
            type_user,
          },
        },
      },
      fetchDocumentTypes,
      getClients,
      getProviders,
      statTransaction,
      getBillingCycles,
      t,
      fetchRoles,
      showMessage,
    } = this.props;

    if ((type_user !== 'general_admin') && (type_user !== 'main_bank_issuing') && (type_user !== 'main_bank_acquiring')) {
      fetchRoles({
        params: {
          name: 'seller',
        },
      });
    }

    if(type_user === 'main_bank_issuing' || type_user === 'main_bank_acquiring'){
      fetchRoles({
        params: {
          owner_id: user_id
        },
      });
    }

    if (type_user === 'general_admin' || type_user === 'general_admin_issuing' ||
      type_user === 'general_admin_acquiring' || type_user === 'general_admin_issuing_amex') {
      fetchBanks({
        params: {
          //id: user_id,
          general_admin: true,
        }
      });
    }

    window.sessionStorage.setItem('token', token);

    var currentYear = new Date().getFullYear();
    var currentMonth = new Date().getMonth();

    if (type_user === 'acquiring_bank' || type_user === 'issuing_bank') {
      fetchStats({
          currency: 'mxn',
        });
      this.setState({ search: true });
    }

    if (type_user === 'main_bank') {
      this.searchPerson();
    }

    if (type_user === 'main_bank_issuing' || type_user === 'main_bank_issuing_amex' ||
      type_user === 'main_bank_acquiring') {
      //this.searchPerson();
      fetchDocumentTypes({
        params: { legal_type: 'J' },
      });
    } else {
      fetchDocumentTypes({});
    }
    if (type_user === 'issuing_bank' || type_user === 'acquiring_bank') {
      getClients({});
      getProviders({});
      fetchStats({
        callback: (resp) => {
          (parseInt(resp[1]) > 0 || parseInt(resp[0]) > 0) ? this.setState({
            alert: 'bank',
            notification: Number(resp) === 1 ? 'haveNotifications' : 'haveNotifications'
          }) : this.setState({ alert: '' });
        },
      });
    } else {
      fetchStats({});
    }
    switch (type_user) {
      case 'admin_amex':
      case 'admin':
        getClients({});
        getProviders({});
        statTransaction({
          params: {
            emmit: true,
            approved: true,
            //min_date: 1589154906,
            //max_date: 1589654906,
          },
          callback: (response) => {
            this.setState({ stat: response });
          }
        });
        break;
      case 'buyer':
        listTransactionsSender({
          params: {
            user_id,
          },
          t,
          callback: ({pending_invoices, in_process_invoices}) => {
            if (Number(pending_invoices) > 0 || Number(in_process_invoices) > 0) {
              this.setState({ alert: 'b' })
            }
          },
        });
        break;
      case 'seller_assist_card':
      case 'seller':
        listTransactionsReceiver({
          params: {
            user_id,
          },
          t,
          callback: ({emit_invoices, in_process_invoices}) => {
            if(Number(emit_invoices) > 0, Number(in_process_invoices) > 0) {
              this.setState({ alert: 's' })
            }
          },
        });
        break;
      case 'cycle_loader':
          //getCyclePeriods({});
          getBillingCycles({
            params: {
              month: currentMonth + 1,
              year: currentYear,
            },
            callback: (response) => {
              this.orderArray(response);
            }
          });
          break;
      /*case 'issuing_bank':
        fetchLeads({
          params: {
            role_id: 'buyer',
          },
        });
        break;
      case 'acquiring_bank':
        fetchLeads({
          params: {
            role_id: 'seller',
          },
        });
        break;*/
      default:
    }

    if(window.innerWidth < 1024) {
      showMessage({
        message: t('responsiveMsg'),
        config: TOAST_CONFIG.INFO,
      });
    }
  }

  sort = (list) => {
    return list.sort(((a, b) => b.created_at - a.created_at));
  };

  orderArray = (list) => {
    let arrayOrder = [],
      i = 0,
      order = [5, 3, 8, 1, 2];

    for (let j = 0; j < 5; j++) {
      list.forEach(function (path) {
        if (order[i] === Number(path.billingCycle.cycle)) {
          arrayOrder.push({
            id: i,
            group: path.billingCycle.cycle,
            billing_cycle_id: path.billing_cycle_id,
            dayI: '',
            date_init: path.date_init,
            dayF: '',
            date_end: path.date_end,
          });
          i = i + 1;
        }
      });
    }

    this.setState({
      arrayOrder,
    });
  };

  resetState = () => {
    this.setState(this.getInitialState());
  };

  renderRowSubComponent = ({ row }) => {
    const { _original: data } = row;
    const {
      t,
      auth: { user: { b2b: { type_user } } },
    } = this.props;
    return (
      <div className="columns row-sub-component" style={{ padding: '1rem', fontSize: '.85em' }}>
        <div className="column has-text-centered">
          <h4>
            {`${t('invoicesApproved')}:`}
          </h4>
          <span>{`${data.approved_invoice_count}`}</span>
        </div>
        <div className="column has-text-right">
          <h4>
            {`${t('approvedMount')}:`}
          </h4>
          <Currency value={data.approved_invoice_total_amount} />
        </div>
        <div className="column has-text-centered">
          <h4>
            {`${t('nInvoices')}:`}
          </h4>
          <span>{`${data.invoice_count}`}</span>
        </div>
        <div className="column has-text-right">
          <h4>
            {`${t('totalAmount')}:`}
          </h4>
          <Currency value={data.invoice_total_amount} />
        </div>
        <div className="column has-text-right">
          <h4>
            {`${t(`${type_user === 'issuing_bank' ? 'defer' : 'advance'}Mount`)}:`}
          </h4>
          <Currency value={data.advance_invoice_total_amount} />
        </div>
        <div className="column has-text-right">
          <h4>
            {`${t('totalCommissionAmount')}:`}
          </h4>
          <Currency value={data.commission_advance_invoice_total_amount} />
        </div>
      </div>
    );
  };

  sumTotalReceivable = (array, type) => {
    let sum = 0, sum1 = 0;
    array.forEach(function (pos) {
      if ( pos.status === 'ANG' || pos.status === 'APG' || pos.status === 'EPR' ) {
        sum = sum + parseFloat(pos.total_amount);
      }
      if ( pos.status === 'PAP' ) {
        sum1 = sum1 + parseFloat(pos.total_amount);
      }
    });
    return type === 0 ? sum : sum1;
  };

  getStatsData = (userType) => {
    let statsData;
    const {
      t,
      auth: {
        user: {
          b2b: { user_type },
        },
      },
      transactions: { list: graph },
      clients: { list: listClient },
      providers: { list: listProvider },
      stats: {
        total_paid,
        next_payment,
        total_debt_amount,
        total_owed_amount,
        account_balance,
        paid_total,
        pending_amount,
        invoice_total,
        total_operators,
        total_users,
        total_active_users,
        total_amount_approved_crc,
        total_amount_approved_usd,
        total_pending_invoices,
        total_amount_paid_crc,
        total_amount_paid_usd,
        total_debt_amount_crc,
        total_debt_amount_usd,
        inactive_providers,
        total_paid_clients_crc,
        total_paid_clients_usd,
        total_paid_clients_month_usd,
        total_paid_clients_month_crc,
        total_pending_invoice_usd,
        total_pending_invoice_crc,
        next_payment_crc,
        next_payment_usd,
        paid_total_crc,
        paid_total_usd,
        total_owed_amount_crc,
        total_owed_amount_usd,
        total_paid_crc,
        total_paid_usd,
        next_loan_expirations,
        next_suppliers_payment,
        line_credit_available,
        average_monthly_amount_transactions,
        next_loan_expirations_crc,
        next_loan_expirations_usd,
        next_suppliers_payment_crc,
        next_suppliers_payment_usd,
        line_credit_available_usd,
        average_monthly_amount_transactions_usd,
        total_disposal_year,
        pending_disposal,
        total_disposal_month,
      },
    } = this.props;
    const {
      stat,
      request,
    } = this.state;
    const date = new Date();
    const months = [t('january'), t('february'), t('march'), t('april'), t('may'), t('june'), t('july'), t('august'), t('september'), t('october'), t('november'), t('december')];
    const month = months[date.getMonth()];
    const mat = stat.invoices_number;

    switch (userType) {
      case 'admin_amex':
      case 'admin':
        statsData = [
          {
            icon: 'far fa-id-badge',
            title: 'clients',
            value: listClient.length,
          }, {
            icon: 'far fa-id-badge',
            title: 'providers',
            value: listProvider.length,
          }, {
            icon: 'fas fa-hand-holding-usd',
            title: 'totalToReceiver', // formatAmount(mat[4].total_amount, "$")
            value: mat !== undefined ? formatAmount(this.sumTotalReceivable(mat, 0), "$") : formatAmount(0, "$"),
          },
          {
            icon: 'fas fa-hand-holding-usd',
            title: 'totalToApprove', // formatAmount(mat[3].total_amount, "$")
            value: mat !== undefined ? formatAmount(this.sumTotalReceivable(mat, 1), "$") : formatAmount(0, "$"),
          },
        ];
        break;
      case 'acquiring_bank':
      case 'acquiring_query':
        statsData = [
          {
            icon: 'fas fa-file-invoice-dollar',
            // title: 'amountOfTransactionsToProcess',
            title: 'Disposiciones realizadas',
            value: total_disposal_month ? <Currency symbol={''} value={total_disposal_month} /> : ('0.00'),
            typeCurrency: this.getCurrency().toUpperCase(),
          }, {
            icon: 'fas fa-file-invoice',
            // title: 'transactionsToProcess',
            title: 'Disposiciones pendientes',
            value: pending_disposal ? <Currency symbol={''} value={pending_disposal} /> : ('0.00'),
            typeCurrency: this.getCurrency().toUpperCase(),
          }, {
            icon: 'fas fa-hand-holding-usd',
            // title: 'volumeProcessed',
            title: 'Total disposiciones',
            value: total_disposal_year ? <Currency symbol={''} value={total_disposal_year} /> : ('0.00'),
            typeCurrency: this.getCurrency().toUpperCase(),
          },
        ];
        break;
      case 'issuing_bank':
      case 'issuing_query':
        statsData = [
          {
            icon: 'fas fa-file-invoice',
            // title: t('billingPerMonth', { month }),
            title: 'Disposiciones realizadas',
            value: total_disposal_month ? <Currency symbol={''} value={total_disposal_month} /> : ('0.00'),
            typeCurrency: request === 0 ? 'MXN' : 'USD',
          }, {
            icon: 'fas fa-file-invoice-dollar',
            // title: 'totalVolumePendingPayment',
            title: 'Disposiciones pendientes',
            value: pending_disposal ? <Currency symbol={''} value={pending_disposal} /> : ('0.00'),
            typeCurrency: request === 0 ? 'MXN' : 'USD',
          }, {
            icon: 'fas fa-hand-holding-usd',
            // title: 'billingVolume',
            title: 'Total disposiciones',
            value: total_disposal_year ? <Currency symbol={''} value={total_disposal_year} /> : ('0.00'),
            typeCurrency: request === 0 ? 'MXN' : 'USD',
          },
        ];
        break;
      case 'buyer':
        statsData = user_type === 'NCL' ? [
          {
            icon: 'fas fa-file-invoice-dollar',
            title: 'nextSellerPayments',
            value: next_suppliers_payment ? (next_suppliers_payment) : ('0.00'),
            typeCurrency: ' ',
            bigValue1: <></>
          },
          {
            icon: 'fas fa-file-invoice-dollar',
            title: 'amountAverageTransactionsMonthly',
            value: average_monthly_amount_transactions ? (average_monthly_amount_transactions) : ('0.00'),
            typeCurrency: ' ',
            bigValue1: <></>
          },
        ] : [
          {
            icon: 'fas fa-file-invoice-dollar',
            title: 'nextLoanExpiration',
            value: next_loan_expirations_crc ? <Currency symbol={''} value={next_loan_expirations_crc} /> : ('0.00'),
            bigValue1: next_loan_expirations_usd ? <Currency symbol={''} value={next_loan_expirations_usd} /> : ('0.00'),
            typeCurrency: 'MXN',
            typeCurrency1: 'USD',
          },
          {
            icon: 'fas fa-file-invoice-dollar',
            title: 'nextSellerPayments',
            value: next_suppliers_payment_crc ? <Currency symbol={''} value={next_suppliers_payment_crc} /> : ('0.00'),
            bigValue1: next_suppliers_payment_usd ? <Currency symbol={''} value={next_suppliers_payment_usd} /> : ('0.00'),
            typeCurrency: 'MXN',
            typeCurrency1: 'USD',
          },
          // {
          //   icon: 'fas fa-file-invoice-dollar',
          //   title: 'availableLineApprovedInvoices',
          //   value: line_credit_available ? <Currency symbol={''} value={line_credit_available} /> : ('0.00'),
          //   bigValue1: line_credit_available_usd ? <Currency symbol={''} value={line_credit_available_usd} /> : ('0.00'),
          //   typeCurrency: 'MXN',
          //   typeCurrency1: 'USD',
          // },
          {
            icon: 'fas fa-file-invoice-dollar',
            title: 'amountAverageTransactionsMonthly',
            value: average_monthly_amount_transactions ? <Currency symbol={''} value={average_monthly_amount_transactions} /> : ('0.00'),
            bigValue1: average_monthly_amount_transactions_usd ? <Currency symbol={''} value={average_monthly_amount_transactions_usd} /> : ('0.00'),
            typeCurrency: 'MXN',
            typeCurrency1: 'USD',
          },
        ];
        break;
      case 'seller_assist_card':
      case 'seller':
        statsData = [
          {
            icon: 'far fa-id-badge',
            title: 'nextPayment',
            value:  next_payment_crc !== null ? <Currency symbol={''} value={next_payment_crc.grouped_amount} /> : ('0.00'),
            bigValue1: next_payment_usd !== null ? <Currency symbol={''} value={next_payment_usd.grouped_amount} /> : ('0.00'),
            typeCurrency: 'MXN',
            typeCurrency1: 'USD',
          }, {
            icon: 'fas fa-file-invoice-dollar',
            title: 'totalOwedAmount',
            value: total_owed_amount_crc !== null ? <Currency symbol={''} value={total_owed_amount_crc} /> : ('0.00'),
            bigValue1: total_owed_amount_usd !== null ? <Currency symbol={''} value={total_owed_amount_usd} /> : ('0.00'),
            typeCurrency: 'MXN',
            typeCurrency1: 'USD',
          }, /*{
            icon: 'fas fa-hand-holding-usd',
            title: 'accountBalance',
            value: account_balance ? formatCurrency(account_balance) : formatCurrency(0),
          },*/
          {
            icon: 'fas fa-file-invoice-dollar',
            title: 'amountToGet',
            value: invoice_total ? invoice_total : 0,
          },
          {
            icon: 'fas fa-file-invoice-dollar',
            title: t('totalCharged', { month }),
            value: total_paid_crc ? <Currency symbol={''} value={total_paid_crc} /> : ('0.00'),
            bigValue1: total_paid_usd ? <Currency symbol={''} value={total_paid_usd} /> : ('0.00'),
           // bigValue1: total_paid_usd ? (total_paid_usd) : ('0.00'),
            typeCurrency: 'MXN',
            typeCurrency1: 'USD',
          },
        ];
        break;
      case 'main_bank':
      case 'main_bank_acquiring':
      case 'main_bank_issuing':
        statsData = [
          {
            icon: 'fas fa-users',
            title: 'users',
            value: total_operators === undefined ? 0 : total_operators,
          }, {
            icon: 'fas fa-building',
            title: 'business',
            value: total_users === undefined ? 0 : total_users,
          }, {
            icon: 'fas fa-chart-line',
            title: 'activeCompanies',
            value: total_active_users === undefined ? 0 : total_active_users,
          },
        ];
        break;
      default:
        statsData = [];
    }
    return { statsData };
  };

  getStatsData1 = (userType) => {
    const {
      stats: {
        buyer_total,
        inactive_customers,
        seller_total,
        inactive_providers,
      }
    } = this.props;
    let statsData1;

    switch (userType) {
      case 'issuing_bank':
        statsData1 = [
          {
            icon: 'fas fa-users',
            title: 'affiliateClients',
            value: buyer_total,
          }, {
            icon: 'fas fa-users-slash',
            title: 'inactiveClients',
            value: inactive_customers,
          },
        ];
        break;
      case 'acquiring_bank':
        statsData1 = [
          {
            icon: 'fas fa-users',
            title: 'totalSellers',
            value: seller_total,
          },
          {
            icon: 'fas fa-store-alt-slash',
            title: 'inactiveBusinesses',
            value: inactive_providers
          },
        ];
        break;
    }
    return { statsData1 };
  };

  getLateralStatsData = (userType) => {
    let statsData;
    const {
      t,
      stats: {
        paid_amount,
        pending_amount,
        financed_amount,
        total_paid,
      },
    } = this.props;
    const date = new Date();
    const months = [t('january'), t('february'), t('march'), t('april'), t('may'), t('june'), t('july'), t('august'), t('september'), t('october'), t('november'), t('december')];
    const month = months[date.getMonth()];
    switch (userType) {
      /* case 'buyer':
        statsData = [
          {
            icon: 'fas fa-file-invoice-dollar',
            title: 'amountToPay',
            value: pending_amount ? formatCurrency(pending_amount) : formatCurrency(0),
          }, {
            icon: 'fas fa-file-invoice-dollar',
            title: 'paidAmount',
            value: paid_amount ? formatCurrency(paid_amount) : formatCurrency(0),
          }, /* {
            icon: 'fas fa-hand-holding-usd',
            title: 'deferredAmount',
            value: financed_amount ? formatCurrency(financed_amount) : formatCurrency(0),
          }, */
      /*  ];
        break;*/
      case 'seller':
        statsData = [
          /*{
            icon: 'fas fa-file-invoice-dollar',
            title: 'amountToGet',
            value: pending_amount ? formatCurrency(pending_amount) : formatCurrency(0),
          }, {
            icon: 'fas fa-file-invoice-dollar',
            title: 'paidAmount',
            value: paid_amount ? formatCurrency(paid_amount) : formatCurrency(0),
          },  {
            icon: 'fas fa-hand-holding-usd',
            title: 'anticipatedAmount',
            value: financed_amount ? formatCurrency(financed_amount) : formatCurrency(0),
          }, */
          {
            icon: 'fas fa-file-invoice-dollar',
            title: t('totalCharged', { month }),
            value: total_paid ? formatIntCurrency(total_paid) : formatCurrency(0),
          },
        ];
        break;
      default:
        statsData = [];
    }
    return { statsData };
  };

  labelType = (role) => {
    const {
      t,
    } = this.props;

    return role === 'issuing_bank' ? t('issuing_bank') :
      role === 'issuing_query' ? t('issuing_query') : role === 'cycle_loader' ? t('cycle_loader') :
        role === 'rate_operator' ? t('rate_operator') : t('rate_operator');
  };

  getGridSettings = (userType, onToggle) => {
    let columns = null;
    let data = null;
    let title = null;
    const { search, user_ncl, request, } = this.state;
    const {
      t,
      history,
      stats: { sellers, buyers },
      auth: { user: { b2b: { defer_condition, advance_condition }, profile: { type } } },
      getClients,
      getClient,
      getClientsConditions,
      getProviders,
      getProvidersConditions,
      getProvider,
      getCycleCard,
      getCurrency,
      getCard,
      fetchBanks,
      updateStatus,
      getProviderCommerceCode,
      transactions: { list },
      bank: { list: bankList },
      providerCommerceCode,
      role: { list: roleList },
      fetchCategories,
      showMessage,
    } = this.props;

    let role_id = '';
    for (let i = 0; i < roleList.length; i++) {
      if (roleList[i].name === 'seller') {
        role_id = roleList[i].id;
      }
    }

    switch (userType) {
      case 'acquiring_query':
      case 'acquiring_bank':
        columns = [
          {
            id: 'fiscalDocument', // Required because our accessor is not a string
            Header: 'RFC',
            headerClassName: 'wordwrap',
            accessor: d => `${getPath(['idPerson', 'document_number'], d)} `,
            //accessor: d => `${getPath(['idPerson', 'idDocumentType', 'document_type'], d)} ${getPath(['idPerson', 'document_number'], d)} `,
          },
          {
            id: 'name', // Required because our accessor is not a string
            Header: t('name'),
            headerClassName: 'wordwrap',
            accessor: d => `${getPath(['idPerson', 'name'], d)}`,
          },
          {
            id: 'user_type', // Required because our accessor is not a string
            Header: t('paymentType'),
            headerClassName: 'wordwrap',
            accessor: d => d.user_type,
          },
          // {
          //   id: 'address', // Required because our accessor is not a string
          //   Header: t('address'),
          //   headerClassName: 'wordwrap',
          //   accessor: d => d.address_full !== null ? `${d.address_full}` : '',
          // },
          // {
          //   id: 'email', // Required because our accessor is not a string
          //   Header: t('email'),
          //   headerClassName: 'wordwrap',
          //   accessor: d => `${d.username}`,
          // },
          // {
          //   id: 'fantasyName',
          //   Header: t('fantasyName'),
          //   show: false,
          //   headerClassName: 'wordwrap',
          //   accessor: d => d.business_name === null ? '' : `${d.business_name}`,
          // },
          // {
          //   id: 'operationVolume', // Required because our accessor is not a string
          //   Header: t('operationVolume'),
          //   headerClassName: 'wordwrap',
          //   accessor: d => formatCurrencyCl(d.invoice_total_amount),
          // },
          {
            id: 'totalDisposiciones', // Required because our accessor is not a string
            // Header: t('operationVolume'),
            Header: 'Total de disposiciones',
            width: 110,
            headerClassName: 'wordwrap',
            accessor: d => d.invoice_count,
          },
          {
            id: 'currency',
            Header: t('currency'),
            className: 'text-center',
            accessor: d => this.getCurrency().toUpperCase(),
          },
          {
            id: 'invoice_total_amount', // Required because our accessor is not a string
            Header: t('amountArranged'),//'Monto dispuesto',
            headerClassName: 'wordwrap',
            accessor: d => d.invoice_total_amount,
            Cell: cellInfo => (
              <Currency value={cellInfo.original.invoice_total_amount} />
            ),
            sortMethod: (a, b) => Number(a)-Number(b),
            filterMethod: (filter, row) => numberFilter(filter, row),
            Filter: ({ filter, onChange }) =>
              <input
                onInput={onInputOnlyNumbers}
                onChange={event => onChange(event.target.value)}
                style={{ width: '100%' }}
                value={filter ? filter.value : ''}
              />
          },
          {
            id: 'disposicionesPendientes', // Required because our accessor is not a string
            // Header: t('operationVolume'),
            Header: 'Disposiciones pendientes',
            width: 110,
            headerClassName: 'wordwrap',
            accessor: d => d.approved_invoice_count,
          },
          {
            id: 'user_is_active', // Required because our accessor is not a string
            Header: t('transferState'),
            headerClassName: 'wordwrap',
            accessor: d => d.user_is_active === 1 ? t('active') : t('inactive'),
            filterMethod: (filter, row) => {
              if (filter.value === 'all') {
                return true;
              }
              if (filter.value === t('active')) {
                return row.user_is_active === t('active');
              }
              if (filter.value === t('inactive')) {
                return row.user_is_active === t('inactive');
              }
            },
            Filter: ({ filter, onChange }) =>
              <select
                onChange={event => onChange(event.target.value)}
                style={{ width: '100%' }}
                value={filter ? filter.value : 'all'}
              >
                <option value="all">All</option>
                <option value={t('active')}>{t('active')}</option>
                <option value={t('inactive')}>{t('inactive')}</option>
              </select>,
          },
          {
            id: 'action', // Required because our accessor is not a string
            Header: t('conditions'),
            headerClassName: 'wordwrap',
            className: 'buttons-col', /* TableSimple.css */
            filterable: false,
            width: 120,
            accessor: d => `${d.id} `,
            Cell: cellInfo => (
              <table>
                <tbody>
                <tr>
                  <td>
                    {/*<button
                      style={{ display: (type === 'query' ? 'none' : '') }}
                      className="button is-primary is-inverted"
                      onClick={() => {
                        getClients({
                          params: {
                            //id: cellInfo.original.id,
                          },
                          callback: () => {
                            providerCommerceCode({
                              params: {
                                user: cellInfo.original.id,
                                role: role_id,
                              },
                              callback: (response) => {
                                history.push({
                                  pathname: '/create-payment-condition',
                                  state: {
                                    userInfo: cellInfo.original,
                                    codesProvider: response,
                                  },
                                });
                                //this.setState({ codes: response.commerce_codes });
                              },
                            });
                          },
                          /*callback: () => this.setState({
                            id: cellInfo.original.id,
                            baseDays: 0,
                            percentage: 0,
                            discountRate: 0,
                            receiverDiscountShare: 0,
                            // editable: true,
                            hidden: true,
                          }, () => {
                            onToggle({
                              isEdit: true,
                              paymentCondition: cellInfo.original,
                            });
                          }),// Cerrar comentario aquí
                        });
                      }}
                    >
                      <i className="fas fa-plus mr-8px" aria-hidden="true"/>
                      {`${t('new')}`}
                    </button>*/}
                    <Link
                      className="button is-info is-inverted is-small ml-8px"
                      to="/payment-conditions"
                      onClick={() => getClientsConditions({ params: { id: cellInfo.original.id } })}
                    >
                      <i className="far fa-eye" aria-hidden="true"/>
                    </Link>
                  </td>
                </tr>
                </tbody>
              </table>
            ),
          },
          {
            id: 'action', // Required because our accessor is not a string
            Header: t('action'),
            filterable: false,
            headerClassName: 'wordwrap',
            className: 'buttons-col', // TableSimple.css
            width: 150,
            show: type === 'query' ? false : true,
            accessor: d => `${d.id} `,
            Cell: cellInfo => (
              <table>
                <tbody>
                <tr style={{ display: 'flex' }}>
                  <td>
                    <button
                      className="button is-info is-inverted is-small"
                      onClick={() => {
                        if(cellInfo.original.confirm_information == 0) {
                          showMessage({
                              message: <strong style={{ color: '#ffffff' }}>{t('confirmSupplierBeforeEdit')}</strong>,
                              config: TOAST_CONFIG.ERROR,
                            });
                        } else {
                          fetchCategories({});
                          getCurrency({});
                          getProvider({
                            params: {
                              id: cellInfo.original.id,
                            },
                            callback: () => {
                              history.push('/edit-provider');
                            }
                          });
                        }
                      }}
                    >
                      <i className="far fa-edit mr-8px" aria-hidden="true"/>
                      {`${t('edit')}`}
                    </button>
                  </td>
                  <td>
                    { cellInfo.original.user_type !== user_ncl && (
                      <button
                        className="button is-info is-inverted is-small"
                        title={t('affiliationNumber')}
                        onClick={() => {
                          getProviderCommerceCode({
                            params: {
                              id: cellInfo.original.id,
                            },
                            callback: () => {
                              history.push({
                                pathname: '/commerce-code',
                              });
                            }
                          });
                        }}
                      >
                        <i className="fas fa-store" aria-hidden="true"/>
                      </button>
                    )}
                  </td>
                </tr>
                </tbody>
              </table>
            ),
          },
        ];
        search ? data = sellers : data = [];
        title = t('providers');
        break;
      case 'issuing_query':
      case 'issuing_bank':
        columns = [
          {
            id: 'name', // Required because our accessor is not a string
            Header: t('name'),
            headerClassName: 'wordwrap',
            accessor: d => `${getPath(['idPerson', 'name'], d)}`,
          },
          {
            id: 'fiscalDocument', // Required because our accessor is not a string
            Header: 'RFC',
            headerClassName: 'wordwrap',
            accessor: d => `${getPath(['idPerson', 'document_number'], d)} `,
            //accessor: d => `${getPath(['idPerson', 'idDocumentType', 'document_type'], d)} ${getPath(['idPerson', 'document_number'], d)} `,
          },
          {
            id: 'user_type', // Required because our accessor is not a string
            Header: t('paymentType'),
            headerClassName: 'wordwrap',
            accessor: d => d.user_type,
          },
          {
            id: 'totalDisposiciones', // Required because our accessor is not a string
            // Header: t('operationVolume'),
            Header: 'Total de disposiciones',
            width: 110,
            headerClassName: 'wordwrap',
            accessor: d => d.invoice_count,
          },
          {
            id: 'currency',
            Header: t('currency'),
            className: 'text-center',
            accessor: d => this.getCurrency().toUpperCase(),
          },
          {
            id: 'invoice_total_amount', // Required because our accessor is not a string
            // Header: t('operationVolume'),
            Header: t('amountArranged'),
            headerClassName: 'wordwrap',
            accessor: d => d.invoice_total_amount,
            Cell: cellInfo => (
              <Currency value={cellInfo.original.invoice_total_amount} />
            ),
            sortMethod: (a, b) => Number(a)-Number(b),
            filterMethod: (filter, row) => numberFilter(filter, row),
            Filter: ({ filter, onChange }) =>
              <input
                onInput={onInputOnlyNumbers}
                onChange={event => onChange(event.target.value)}
                style={{ width: '100%' }}
                value={filter ? filter.value : ''}
              />
          },
          {
            id: 'disposicionesPendientes', // Required because our accessor is not a string
            // Header: t('operationVolume'),
            Header: 'Disposiciones pendientes',
            width: 110,
            headerClassName: 'wordwrap',
            accessor: d => d.approved_invoice_count,
          },
          {
            id: 'user_is_active', // Required because our accessor is not a string
            Header: t('transferState'),
            headerClassName: 'wordwrap',
            accessor: d => d.user_is_active === 1 ? t('active') : t('inactive'),
            filterMethod: (filter, row) => {
              if (filter.value === 'all') {
                return true;
              }
              if (filter.value === t('active')) {
                return row.user_is_active === t('active');
              }
              if (filter.value === t('inactive')) {
                return row.user_is_active === t('inactive');
              }
            },
            Filter: ({ filter, onChange }) =>
              <select
                onChange={event => onChange(event.target.value)}
                style={{ width: '100%' }}
                value={filter ? filter.value : 'all'}
              >
                <option value="all">All</option>
                <option value={t('active')}>{t('active')}</option>
                <option value={t('inactive')}>{t('inactive')}</option>
              </select>,
          },
          {
            id: 'action', // Required because our accessor is not a string
            Header: t('conditions'),
            headerClassName: 'wordwrap',
            className: 'buttons-col', /* TableSimple.css */
            filterable: false,
            width: 150,
            accessor: d => `${d.id} `,
            Cell: cellInfo => (
              <table>
                <tbody>
                <tr>
                  <td>
                    { cellInfo.original.user_type === user_ncl && (
                      <button
                        title={t('newCondition')}
                        style={{ display: (type === 'query' ? 'none' : '') }}
                        className="button is-info is-small is-inverted"
                        onClick={() => {
                          getProviders({
                            params: {
                              //id: cellInfo.original.id,
                              user_type: user_ncl,
                            },
                            callback: () =>
                              history.push({
                                pathname: '/create-payment-condition',
                                state: {
                                  userInfo: cellInfo.original,
                                  //codesProvider: response,
                                },
                              })

                          });
                        }}
                      >
                        <i className="fas fa-plus" aria-hidden="true" title={t('newCondition')}/>
                      </button>
                    )}
                    <Link
                      className="button is-info is-inverted is-small"
                      to="/payment-conditions"
                      onClick={() =>
                        getProvidersConditions({ params: { id: cellInfo.original.id } })}
                    >
                      {/* <i className="far fa-eye" aria-hidden="true"/> */}
                      {t('seeConditions')}
                    </Link>
                  </td>
                </tr>
                </tbody>
              </table>
            ),
          },
          {
            id: 'action', // Required because our accessor is not a string
            Header: t('action'),
            headerClassName: 'wordwrap',
            filterable: false,
            //show: false,
            className: 'buttons-col', /* TableSimple.css */
            width: 150,
            accessor: d => `${d.id} `,
            Cell: cellInfo => (
              <table>
                { cellInfo.original.user_type !== 'TDC' ? (
                <tbody>
                <tr>
                  <td>
                    <button
                      className="button is-primary is-inverted"
                      onClick={() => {
                        fetchCategories({});
                        getClient({
                          params: {
                            id: cellInfo.original.id,
                          },
                          callback: () => {
                            history.push('/edit-customer');
                          }
                        });
                      }}
                    >
                      <i className="far fa-edit mr-8px" aria-hidden="true"/>
                      {`${t('edit')}`}
                    </button>
                  </td>
                  {/*<td>
                    <button
                      className="button is-primary is-inverted"
                      onClick={() => {
                        getCard({
                          params: {
                            person: cellInfo.original.id,
                          },
                          callback: () => {
                            history.push({
                              pathname: '/payment-person',
                              state: {
                                clientName: cellInfo.original.idPerson.name,
                              }
                            });
                          }
                        });
                      }}
                    >
                      <span className="icon" title={t('viewTta')}>
                        <i className="far fa-credit-card" style={{ fontSize: '16px' }}/>
                      </span>
                    </button>
                  </td>*/}
                </tr>
                </tbody>
                ) : ( null ) }
              </table>
            ),
          },
        ];
        search ? data = this.sort(buyers) : data = [];
        title = t('customers');
        break;
      case 'rate_operator':
        columns = [
          {
            id: 'mainInvoiceNumber', // Required because our accessor is not a string
            Header: t('mainInvoiceNumber'),
            headerClassName: 'wordwrap',
            accessor: d => d.invoice_number === null ? '' : `${d.invoice_number}`,
          },
          {
            id: 'datePayment', // Required because our accessor is not a string
            Header: t('datePayment'),
            headerClassName: 'wordwrap',
            accessor: d => formatDate(d.due_date),
          },
          {
            id: 'adjusted_amount', // Required because our accessor is not a string
            Header: t('totalPay'),
            headerClassName: 'wordwrap',
            accessor: d => d.adjusted_amount,
            Cell: cellInfo => (
              <Currency value={cellInfo.original.adjusted_amount} />
            ),
            sortMethod: (a, b) => Number(a)-Number(b),
            filterMethod: (filter, row) => numberFilter(filter, row),
            Filter: ({ filter, onChange }) =>
              <input
                onInput={onInputOnlyNumbers}
                onChange={event => onChange(event.target.value)}
                style={{ width: '100%' }}
                value={filter ? filter.value : ''}
              />
          },
          {
            id: 'currency', // Required because our accessor is not a string
            Header: t('currency'),
            headerClassName: 'wordwrap',
            accessor: d => `${d.pay_currency_id}`,
          },
          {
            id: 'customer', // Required because our accessor is not a string
            Header: t('customer'),
            headerClassName: 'wordwrap',
            accessor: d => `${d.sender.idPerson.name}`,
          },
          {
            id: 'description', // Required because our accessor is not a string
            Header: t('description'),
            headerClassName: 'wordwrap',
            accessor: d => `${d.description}`,
          },
          {
            id: 'transferState', // Required because our accessor is not a string
            Header: t('transferState'),
            headerClassName: 'wordwrap',
            accessor: d => `${d.receiverStatus.description}`,
          },
          {
            id: 'action', // Required because our accessor is not a string
            Header: t('action'),
            filterable: false,
            headerClassName: 'wordwrap',
            className: 'buttons-col', /* TableSimple.css */
            width: 150,
            Cell: cellInfo => (
              <td>
                <Link
                  className="button is-primary is-inverted"
                  to="/dashboard"
                  onClick={() => {
                    this.setState({
                      id: cellInfo.original.id,
                      discountRateChargesDate: formatDate(cellInfo.original.date),
                      discountRateChargesDueDate: formatDate(cellInfo.original.pay_provider_date),
                      discountRateChargesTotal: cellInfo.original.adjusted_amount,
                      discountRateChargesDescription: cellInfo.original.description,
                      discountRateChargesPercentage: cellInfo.original.paymentCondition.percentage,
                      discountRateChargesDiscountRate: cellInfo.original.paymentCondition.discount_rate,
                      discountRateChargesInvoiceNumber: cellInfo.original.invoice_number === null ? '' : cellInfo.original.invoice_number,
                      currency: cellInfo.original.pay_currency_id,
                      is_rate: true
                      //editable: true,
                    }, () => {
                      onToggle({
                        is_rate: true,
                      });
                    });
                  }}
                >
                  <i className="fas fa-plus mr-8px" aria-hidden="true"/>
                  {t('info')}
                </Link>
              </td>
            ),
          }];
        title = t('transactions');
        data = list;
        break;
      case 'buyer':
        columns = [
          {
            id: 'invoicesNumber', // Required because our accessor is not a string
            Header: t('invoicesNumber'),
            headerClassName: 'wordwrap',
            accessor: d => `${d.invoice_number}`,
          },
          {
            id: 'paymentDate', // Required because our accessor is not a string
            Header: t('paymentDate'),
            headerClassName: 'wordwrap',
            accessor: d => formatDate(d.due_date),
          },
          {
            id: 'adjusted_amount', // Required because our accessor is not a string
            Header: t('totalPay'),
            headerClassName: 'wordwrap',
            accessor: d => d.adjusted_amount,
            Cell: cellInfo => (
              <Currency value={cellInfo.original.adjusted_amount} />
            ),
            sortMethod: (a, b) => Number(a)-Number(b),
            filterMethod: (filter, row) => numberFilter(filter, row),
            Filter: ({ filter, onChange }) =>
              <input
                onInput={onInputOnlyNumbers}
                onChange={event => onChange(event.target.value)}
                style={{ width: '100%' }}
                value={filter ? filter.value : ''}
              />
          },
          {
            id: 'name', // Required because our accessor is not a string
            Header: t('name'),
            headerClassName: 'wordwrap',
            accessor: d => `${d.receiver.idPerson.name}`,
          },
          {
            id: 'description', // Required because our accessor is not a string
            Header: t('description'),
            headerClassName: 'wordwrap',
            accessor: d => `${d.description}`,
          },
          {
            id: 'transferState', // Required because our accessor is not a string
            Header: t('transferState'),
            headerClassName: 'wordwrap',
            accessor: d => `${d.senderStatus.description}`,
          },
          {
            id: 'action', // Required because our accessor is not a string
            Header: t('action'),
            filterable: false,
            headerClassName: 'wordwrap',
            className: 'buttons-col', /* TableSimple.css */
            width: 150,
            Cell: cellInfo => (
              <td>
                {cellInfo.original.sender_status_id === 'PAP'
                && (
                  <Link
                    className="button is-primary is-inverted"
                    to="/dashboard"
                    onClick={() => this.approveInvoice(cellInfo.original.id)}
                  >
                    <i className="fas fa-check mr-8px" aria-hidden="true"/>
                    {t('accept')}
                  </Link>
                )}
                {cellInfo.original.sender_status_id === 'PAP'
                && (
                  <Link
                    className="button is-danger is-inverted"
                    to="/dashboard"
                    onClick={() => this.rejectInvoice(cellInfo.original.id)}
                  >
                    <i className="fas fa-times mr-8px" aria-hidden="true"/>
                    {t('reject')}
                  </Link>
                )}
                {cellInfo.original.sender_status_id === 'PEJ'
                && (
                  <Link
                    className="button is-success is-inverted"
                    to="/dashboard"
                    onClick={() => onToggle({
                      transaction: cellInfo.original,
                      paymentCondition: defer_condition,
                      onSubmit: this.deferPayment,
                    })}
                  >
                    <i className="far fa-clock mr-8px" aria-hidden="true"/>
                    {t('differ')}
                  </Link>
                )}
              </td>
            ),
          }];
        title = t('payedAmounts');
        break;
      case 'seller_assist_card':
      case 'seller':
        columns = [
          {
            id: 'invoicesNumber', // Required because our accessor is not a string
            Header: t('invoicesNumber'),
            headerClassName: 'wordwrap',
            accessor: d => `${d.invoice_number}`,
          },
          {
            id: 'paymentDate', // Required because our accessor is not a string
            Header: t('paymentDate'),
            headerClassName: 'wordwrap',
            accessor: d => formatDate(d.due_date),
          },
          {
            id: 'adjusted_amount', // Required because our accessor is not a string
            Header: t('totalPay'),
            headerClassName: 'wordwrap',
            accessor: d => d.adjusted_amount,
            Cell: cellInfo => (
              <Currency value={cellInfo.original.adjusted_amount} />
            ),
            sortMethod: (a, b) => Number(a)-Number(b),
            filterMethod: (filter, row) => numberFilter(filter, row),
            Filter: ({ filter, onChange }) =>
              <input
                onInput={onInputOnlyNumbers}
                onChange={event => onChange(event.target.value)}
                style={{ width: '100%' }}
                value={filter ? filter.value : ''}
              />
          },
          {
            id: 'name', // Required because our accessor is not a string
            Header: t('name'),
            headerClassName: 'wordwrap',
            accessor: d => `${d.sender.idPerson.name}`,
          },
          {
            id: 'description', // Required because our accessor is not a string
            Header: t('description'),
            headerClassName: 'wordwrap',
            accessor: d => `${d.description}`,
          },
          {
            id: 'transferState', // Required because our accessor is not a string
            Header: t('transferState'),
            headerClassName: 'wordwrap',
            accessor: d => `${d.receiverStatus.description}`,
          },
          {
            id: 'action', // Required because our accessor is not a string
            Header: t('action'),
            filterable: false,
            headerClassName: 'wordwrap',
            className: 'buttons-col', /* TableSimple.css */
            width: 150,
            Cell: cellInfo => (
              <td>
                {cellInfo.original.receiver_status_id === 'PEJ'
                && (
                  <Link
                    className="button is-success is-inverted"
                    to="/dashboard"
                    onClick={() => onToggle({
                      transaction: cellInfo.original,
                      paymentCondition: advance_condition,
                      onSubmit: this.advancePayment,
                    })}
                  >
                    <i className="far fa-clock mr-8px" aria-hidden="true"/>
                    {t('advance')}
                  </Link>
                )}
              </td>
            ),
          }];
        title = t('amountToCollect');
        break;
      case 'general_admin':
      case 'general_admin_issuing':
      case 'general_admin_issuing_amex':
      case 'general_admin_acquiring':
        columns = [
          {
            expander: true,
            show: false,
          },
          {
            id: 'document_number', // Required because our accessor is not a string
            Header: t('documentNumber'),
            accessor: d => getPath(['idPerson', 'document_number'], d) !== null ?
              `${(getPath(['idPerson', 'document_number'], d))}` : '',
          },
          {
            id: 'name', // Required because our accessor is not a string
            Header: t('name'),
            accessor: d => d.idPerson.name,
          },
          {
            id: 'bank_type', // Required because our accessor is not a string
            Header: t('type'),
            accessor: d => t(d.roles[0].name),
          },
          {
            id: 'email', // Required because our accessor is not a string
            Header: t('email'),
            accessor: d => d.idPerson.idEmailActive.email,
          },
          {
            id: 'status', // Required because our accessor is not a string
            Header: t('status'),
            width: 100,
            Cell: cellInfo => (cellInfo.original.user_status === '1'
              ? <i className="far fa-check-circle mr-8px" aria-hidden="true"
                   style={{ color: 'green' }}/>
              : <i className="fas fa-ban mr-8px" aria-hidden="true" style={{ color: 'red' }}/>),
          },
          {
            id: 'action', // Required because our accessor is not a string
            Header: t('action'),
            filterable: false,
            headerClassName: 'wordwrap',
            className: 'buttons-col', /* TableSimple.css */
            width: 200,
            Cell: cellInfo => (
              <table>
                <tbody>
                <tr>
                  <td>
                    <Link
                      className={`button is-inverted is-small is-${cellInfo.original.user_status === '1' ? 'danger' : 'success'}`}
                      to="/bank-list"
                      onClick={() => updateStatus({
                        data: {
                          id_user: cellInfo.original.id,
                          new_status: cellInfo.original.user_status === '1' ? '0' : '1'
                        },
                        callback: () => fetchBanks({})
                      })}
                    >
                      {cellInfo.original.user_status === '1'
                        ? t('disable')
                        : t('enable')
                      }
                    </Link>
                    <button
                      className="button is-info is-inverted is-small"
                      onClick={() => history.push({
                        pathname: '/edit-admin-bank',
                        state: {
                          userInfo: cellInfo.original
                        },
                      })}
                    >
                      <i className="far fa-edit mr-8px" aria-hidden="true"/>
                      {t('edit')}
                    </button>
                  </td>
                </tr>
                </tbody>
              </table>
            ),
          }];
        title = t('amountToCollect');
        data = bankList;
        break;
      case 'main_bank':
      case 'main_bank_acquiring':
      case 'main_bank_issuing':
      case 'main_bank_issuing_amex':
        columns = [
          {
            expander: true,
            show: false,
          },
          /*
          {
            id: 'fiscalDocument', // Required because our accessor is not a string
            Header: t('documentNumber'),
            headerClassName: 'wordwrap',
            accessor: d => `${getPath(['idPerson', 'document_number'], d) === null ? '' : getPath(['idPerson', 'document_number'], d)} `,
            //accessor: d => `${getPath(['idPerson', 'idDocumentType', 'document_type'], d)} ${getPath(['idPerson', 'document_number'], d)} `,
          },
          */
          {
            id: 'name', // Required because our accessor is not a string
            Header: t('name'),
            headerClassName: 'wordwrap',
            accessor: d => `${d.idPerson.name}`,
          },
          {
            id: 'lastName', // Required because our accessor is not a string
            Header: t('adminLastname'),
            headerClassName: 'wordwrap',
            accessor: d => `${d.idPerson.lastname}`,
          },
          {
            id: 'charge', // Required because our accessor is not a string
            Header: t('charge'),
            headerClassName: 'wordwrap',
            accessor: d => `${d.admin_position}`,
          },
          {
            id: 'rol', // Required because our accessor is not a string
            Header: t('rol'),
            headerClassName: 'wordwrap',
            accessor: d => userType === 'main_bank_issuing' ? this.labelType(d.roles[0].name) : `${t(d.roles[0].name)}`,
          },
          {
            id: 'email', // Required because our accessor is not a string
            Header: t('email'),
            headerClassName: 'wordwrap',
            accessor: d => `${d.username}`,
          },
          /*{
            id: 'fantasyName',
            Header: t('fantasyName'),
            accessor: d => d.business_name === null ? '' : `${d.business_name}`,
          },*/
          {
            id: 'action', // Required because our accessor is not a string
            Header: t('action'),
            filterable: false,
            headerClassName: 'wordwrap',
            className: 'buttons-col', /* TableSimple.css */
            width: 200,
            Cell: cellInfo => (
              <table>
                <tbody>
                <tr>
                <div>
                    <Link
                      className={`button is-inverted is-small is-${cellInfo.original.user_status === '1' ? 'danger' : 'success'}`}
                      to="/dashboard"
                      onClick={() =>
                        updateStatus({
                          data: {
                            id_user: cellInfo.original.id,
                            new_status: cellInfo.original.user_status === '1' ? '0' : '1'
                          },
                          callback: () => fetchBanks({})
                        })}
                    >
                      {cellInfo.original.user_status === '1'
                        ? t('disable')
                        : t('enable')
                      }
                    </Link>
                    <button
                      className="button is-info is-inverted is-small"
                      style={{marginLeft: '.5em'}}
                      onClick={() => history.push({
                        pathname: '/edit-bank-user',
                        state: {
                          userInfo: cellInfo.original
                        },
                      })}
                    >
                      <i className="far fa-edit" aria-hidden="true"/>
                      {/*t('edit')*/}
                    </button>
                  </div>
                </tr>
                </tbody>
              </table>
            ),
          }
        ];
        title = t('users');
        search ? data = bankList : data = [];
        break;
      default:
        columns = [];
        data = [];
        title = '';
    }
    return {
      columns,
      data,
      title,
    };
  };

  getCurrency = () => {
    const {
      request,
    } = this.state;

    return request === 0 ? 'mxn' : 'usd';
  };

  newPaymentCondition = (onToggle) => {
    const {
      auth: { user: { user_id, b2b: { type_user } } },
      clients: { list: clientList },
      providers: { list: providerList },
      createPaymentCondition,
      requestPaymentCondition,
      t,
      showMessage,
    } = this.props;
    const {
      id,
      client,
      provider,
      discountRate,
      receiverDiscountShare,
      baseDays,
      percentage,
      startCountingFrom,
      //limitAmount,
      clientPayment,
      commerceCode,
    } = this.state;
    const days = baseDays;
    const persons = type_user === 'acquiring_bank' ? clientList : providerList;
    const conditionData = {
      id: '',
      bank_sender_id: true,
      sender_request_id: null,
      sender_id: type_user === 'acquiring_bank' ? persons[client].id : id,
      receiver_id: type_user === 'acquiring_bank' ? id : persons[provider].id,
      days,
      due_from_approval: startCountingFrom,
      //limit_amount: limitAmount.replaceAll(',', ''),
      discount_rate: discountRate,
      percentage: clientPayment, //percentage,
      receiver_discount_share: receiverDiscountShare / 100,
      type: 'base',
      person_commerce_code: commerceCode,
    };
    if (days < 0) {
      showMessage({
        message: <strong style={{ color: '#ffffff' }}>{t('incorrectDays')}</strong>,
        config: TOAST_CONFIG.ERROR,
      });
      return;
    }

    /*if (conditionData.limit_amount < 0) {
      showMessage({
        message: <strong style={{ color: '#ffffff' }}>{t('limitAmountValidate')}</strong>,
        config: TOAST_CONFIG.ERROR,
      });
    } else {*/
      requestPaymentCondition({
        data: conditionData,
        callback: () => {
          onToggle();
          showMessage({
            message: t('createPaymentConditionSuccess'),
            config: TOAST_CONFIG.SUCCESS,
          });
          this.resetState();
        },
      });
    //}
  };

  approveInvoice = (transaction_id) => {
    const {
      approveInvoice,
      listTransactionsSender,
      auth: {
        user: { user_id },
      },
    } = this.props;
    const data = {
      transaction_id,
      approved: true,
    };
    approveInvoice({
      data,
      callback: () => {
        listTransactionsSender({ params: { user_id } });
      },
    });
  };

  rejectInvoice = (transaction_id) => {
    const {
      approveInvoice,
      listTransactionsSender,
      auth: {
        user: { user_id },
      },
    } = this.props;
    const data = {
      transaction_id,
      approved: false,
    };
    approveInvoice({
      data,
      callback: () => {
        listTransactionsSender({ params: { user_id } });
      },
    });
  };

  deferPayment = (transaction_id, condition_id, onToggle) => {
    const {
      deferInvoice,
      listTransactionsSender,
      fetchStats,
      auth: {
        user: { user_id },
      },
    } = this.props;
    const data = {
      transaction_id,
      condition_id,
    };
    deferInvoice({
      data,
      callback: () => {
        listTransactionsSender({ params: { user_id } });
        fetchStats();
        onToggle();
      },
    });
  };

  advancePayment = (transaction_id, condition_id, onToggle) => {
    const {
      advanceInvoice,
      listTransactionsReceiver,
      fetchStats,
      auth: {
        user: { user_id },
      },
      card: { list },
    } = this.props;
    const data = {
      transaction_id,
      condition_id,
    };
    advanceInvoice({
      data,
      callback: () => {
        listTransactionsReceiver({ params: { user_id } });
        fetchStats();
        onToggle();
      },
    });
  };

  handleInputChange = (event) => {
    const {
      target: {
        checked, value: targetValue, type, name,
      },
    } = event;
    const value = type === 'checkbox' ? checked : targetValue;

    /* if (name === 'limitAmount') {
      let num = this.formatNumber(event.target.value);
      this.setState({
        [name]: (num),
      });
    } else 
    if(name === "document_number"){
      let num = this.formatRUT(event.target.value);
      let prenum = '';
      let postnum = '';
      if(num.length >= 9){
          prenum = num.slice(0,8);
          postnum = num.slice(8,9);
          num = prenum+"-"+postnum;
        }  
        
      this.setState({
        [name]: (num),
      })
    } else */

    if (name === 'receiverDiscountShare') {
      let percentage = 100 - value;
      this.setState({
        [name]: (value),
        clientPayment: percentage,
      });
    }else{
      /*
      if(name === "document_number"){
        let num = this.formatNumber2(event.target.value, 10, "number");

        this.setState({
          [name]: (num),
        })
      } else {
        this.setState({
          [name]: (value),
        });
      }
      */
      this.setState({
        [name]: (value),
      });
    }
  };

  handleOnInputChange = (newValue, actionMeta) => {
    // const inputValue = newValue.replace(/\W/g, '');
    this.setState({ inputValue: newValue });
    return newValue;
  };

  formatRUT = (n) => {
    return n.replace(/[^0-9Kk]/, '');
    //return n.replace(/[^a-zA-Z0-9]/, '');
  };

  formatNumber = (n) => {
    return n.replace(/\D/g, '')
      .replace(/([0-9])([0-9]{2})$/, '$1.$2')
      .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ',');
  };

  formatNumber2 = (value, length, type) => {
      if(type === "number") {return value.replace(/\D/g, '').slice(0, length)}
      if(type === "text") {return value.replace(new RegExp("[0-9]"), '').slice(0, length)}
      if(type === "textNum") {return value.replace(new RegExp("^[a-zA-Z][0-9]"), '').slice(0, length)}
}

  handlePercentageChange = (event) => {
    const {
      target: {
        checked, value: targetValue, type, name,
      },
    } = event;
    const value = type === 'checkbox' ? checked : targetValue;
    this.setState({
      [name]: value,
      clientPayment: 100 - Number(value),
    });
  };

  handleSelectChange = (newVal, label) => {
    const { clients, providers, providerCommerceCode, role: { list } } = this.props;
    const { editable, codes } = this.state;
    this.setState({
      [label.name]: newVal.value,
    });

    let role_id = '';
    for (let i = 0; i < list.length; i++) {
      if (list[i].name === 'seller') {
        role_id = list[i].id;
      }
    }
    if (label.name === 'provider') {
      providerCommerceCode({
        params: {
          user: providers.list[newVal.value].id,
          role: role_id,
        },
        callback: (response) => {
          this.setState({ codes: response.commerce_codes });
        },
      });
    }

    if (label.name === 'commerceCode') {
      this.setState({
        commerceCode: codes[newVal.value].id,
        codeDescription: codes[newVal.value].description,
      });
    }

    if (editable) {
      const index = newVal.value;
      const conditions = label.name === 'client' ? clients.list[index].conditions : providers.list[index].conditions;

      const percentageValue = conditions.receiver_discount_share * 100;

      this.setState({
        baseDays: conditions.days,
        percentage: conditions.percentage,
        discountRate: conditions.discount_rate,
        receiverDiscountShare: percentageValue,
        clientPayment: 100 - percentageValue,
        //limitAmount: conditions.limit_amount,
      });
    }
  };

  searchPerson = () => {
    const {
      document_number,
      name,
      email,
      min_amount,
      max_amount,
      user,
      rol,
      rut,
      role_filter,
    } = this.state;
    const { fetchStats, fetchBanks, auth: { user: { b2b: { type_user } } }, role } = this.props;
    this.setState({ search: true });

    if (type_user === 'main_bank' || type_user === 'main_bank_acquiring'
      || type_user === 'main_bank_issuing' || type_user === 'main_bank_issuing_amex') {
      fetchBanks({
        params: {
          email,
          user,
          role: ((role.list.length > 0) && (typeof(role.list[role_filter-1]) !== "undefined")) ? role.list[role_filter-1].id : '',
          document_number: rut,
          main_bank: true,
        }
      });
    } else if (type_user === 'general_admin' || type_user === 'general_admin_issuing' ||
      type_user === 'general_admin_acquiring' || type_user === 'general_admin_issuing_amex') {
      fetchBanks({
        params: {
          email,
          user,
          //role: rol,
          document_number: rut,
          general_admin: true,
        }
      });
    } else {
      fetchStats({
        document_number,
        name,
        email,
        min_amount,
        max_amount,
        currency: this.getCurrency(),
      });
    }
  };

  getTitle = () => {
    const {
      t,
    } = this.props;
    return {
      title1: t('issuedInvoices'),
      title2: t('invoicesApproved'),
      title3: t('approvedMount'),
      title4: t('invoicesToApproved'),
      title5: t('dateRange'),
    };
  };

  handleDateChange = (dateName, newDate) => {
    
    if(newDate){
      if (dateName === 'invoiceDate' && newDate !== '') {
        newDate.setHours(0, 0, 0, 0);
      }
      if (dateName === 'invoiceDateUntil' && newDate !== '') {
        newDate.setHours(23, 59, 59, 999);
      }
    }

    this.setState({
      [dateName]: newDate ? newDate : '',
    });
  };

  rangeDate = () => {
    const {
      statTransaction,
      auth: { user: { b2b: { type_user } } },
    } = this.props;
    const { invoiceDate, invoiceDateUntil } = this.state;

    if (type_user === 'admin' || type_user === 'admin_amex') {
      statTransaction({
        params: {
          emmit: true,
          approved: true,
          min_date: invoiceDate / 1000,
          max_date: invoiceDateUntil / 1000,
        },
        callback: (response) => {
          this.setState({ stat: response });
        }
      });
    }
  };

  searchInvoice = () => {
    const {
      t, showMessage,
    } = this.props;
    const {
      mainInvoiceNumber,
      invoiceDate,
      invoiceDateUntil,
    } = this.state;
    const { rateTransaction } = this.props;

    if (invoiceDateUntil < invoiceDate) {
      showMessage({
        message: <strong
          style={{ color: '#ffffff' }}>{t('errorDate')}</strong>,
        config: TOAST_CONFIG.ERROR,
      });
    } else {
      rateTransaction({
        params: {
          invoice_number: mainInvoiceNumber,
          date_init: invoiceDate,
          date_end: invoiceDateUntil / 1000,
          is_report: false,
        }
      });
    }
  };

  saveInvoice = (onToggle) => {
    const {
      showMessage,
      t,
      saveRate,
      rateTransaction,
    } = this.props;
    const {
      id,
      discountRateChargesInvoiceNumber,
      discountRateChargesTotal,
      mainInvoiceNumber,
      invoiceDate,
      invoiceDateUntil,
    } = this.state;

    if (discountRateChargesInvoiceNumber !== '' && discountRateChargesTotal !== '') {
      const data = {
        id,
        invoice_number: discountRateChargesInvoiceNumber,
        adjusted_amount: discountRateChargesTotal,
      };

      saveRate({
        data,
        callback: () => {
          rateTransaction({
            params: {
              invoice_number: mainInvoiceNumber,
              date_init: invoiceDate,
              date_end: invoiceDateUntil,
            }
          });
          onToggle();
          showMessage({
            message: t('discountRateInvoiceSavedSuccessfully'),
            config: TOAST_CONFIG.SUCCESS,
          });
        }
      });
    } else {
      showMessage({
        message: <strong style={{ color: '#ffffff' }}>{t('emptyFields')}</strong>,
        config: TOAST_CONFIG.ERROR,
      });
    }

  };

  closeModal = (onToggle) => {
    this.getInitialState();
    onToggle();
  };

  handleSelectRequest = (newVal, label) => {
    const {
      fetchStats,
    } = this.props;

    fetchStats({
      currency: newVal.value === 0 ? 'mxn' : 'usd',
    });
    this.setState({
      search: true,
      [label.name]: newVal.value,
      name: '',
      document_number: '',
    });
  }

  render() {
    const {
      t, auth: { loading: authLoading, user: { b2b: { type_user, is_password_expire, user_type } } },
      card: { list, loading: cardLoading },
      stats: { graph_data, graph_data_crc, graph_data_usd, graph_multi, loan_graph_data_crc, loan_graph_data_usd, payment_request_rejected, payment_request_pending, loading: statsLoading },
      clients: { list: clientList, loading: clientLoading },
      providers: { list: providerList, loading: providerLoading },
      transactions: {
        list: graph,
        pending_invoices,
        reject_invoices,
        emit_invoices,
        in_process_invoices,
        loading: transactionLoading,
      },
      bank: { loading: bankLoading },
      transactions,
      paymentConditions: { loading: paymentConditionLoading },
      showMessage,
      role,
      history,
    } = this.props;
    const {
      document_number,
      name,
      email,
      min_amount,
      max_amount,
    } = this.state;
    const persons = type_user === 'acquiring_bank' ? clientList : providerList;
    const { statsData } = this.getStatsData(type_user);
    const { statsData1 } = this.getStatsData1(type_user);
    const { statsData: lateralStatsData } = this.getLateralStatsData(type_user);
    const {
      client,
      provider,
      baseDays,
      discountRate,
      receiverDiscountShare,
      clientPayment,
      editable,
      //limitAmount,
      inputValue,
      alert,
      stat,
      invoiceDate,
      invoiceDateUntil,
      mainInvoiceNumber,
      handleDateChange,
      is_rate,
      discountRateChargesPercentage,
      discountRateChargesDescription,
      discountRateChargesDate,
      discountRateChargesDueDate,
      discountRateChargesTotal,
      discountRateChargesDiscountRate,
      discountRateChargesInvoiceNumber,
      currency,
      user,
      rol,
      rut,
      hidden,
      arrayOrder,
      notification,
      codes,
      commerceCode,
      codeDescription,
      role_filter,
      request,
    } = this.state;

    let dias = [t('monday'), t('tuesday'), t('wednesday'), t('thursday'), t('friday'), t('saturday'), t('sunday')];
    const loading = authLoading || bankLoading || clientLoading || providerLoading || transactionLoading || paymentConditionLoading || statsLoading;
    if (is_password_expire === 1) {
      return <Redirect to="/recover-password"/>;
    }
    /*if (type_user === 'main_bank' || type_user === 'main_bank_acquiring' || type_user === 'main_bank_issuing') {
      return <Redirect to="/bank-user-list"/>;
    }*/
    const { title1, title2, title3, title4, title5 } = this.getTitle();

    const COLORS = ['#05B8BC', '#B60026', '#FFB100']
    return (
      <Toggle>
        {({ toggled, onToggle }) => {
          const { title, columns, data } = this.getGridSettings(type_user, onToggle);
          return (
            <div className="dashboard">
              {loading && <Loading/>}
              {(alert && type_user === 'issuing_bank' && payment_request_rejected > 0) && (
                <Modal
                  title={alert === 'bank' ? t(notification, { payment_request_pending }) /*: alert === 's' ? t('haveNotifications', { in_process_invoices })*/ : t('haveNotifications'/*, { pending_invoices }*/)}
                  onToggle={() => this.setState({ alert: false })}>
                    <Link
                      className="button custom-btn is-primary"
                      style={{ width: '200px' }}
                      to="/payment-request"
                    >
                      {t('rejectedRequests', { amount: payment_request_rejected })}
                    </Link>
                </Modal>
              )}
              {(alert && type_user === 'acquiring_bank' && payment_request_pending > 0) && (
                <Modal
                  title={alert === 'bank' ? t(notification, { payment_request_pending }) /*: alert === 's' ? t('haveNotifications', { in_process_invoices })*/ : t('haveNotifications'/*, { pending_invoices }*/)}
                  onToggle={() => this.setState({ alert: false })}>
                    <div className="field is-grouped is-grouped-right">
                      <Link
                        className="button custom-btn is-primary"
                        style={{ width: '200px' }}
                        to="/payment-request"
                      >
                        { t('requestsReceivedAmount', { amount: payment_request_pending }) }
                      </Link>
                    </div>
                </Modal>
              )}
              {alert && !type_user.includes('bank') && (
                <Modal
                  title={alert === 'bank' ? t(notification, { payment_request_pending }) /*: alert === 's' ? t('haveNotifications', { in_process_invoices })*/ : t('haveNotifications'/*, { pending_invoices }*/)}
                  onToggle={() => this.setState({ alert: false })}>
                  {/* <div
                    className={`notification ${alert === 's' ? 'is-info' : 'is-info'} is-light`}> */}
                    <h1 style={{ fontSize: '18px', marginTop: '1em', fontWeight: 700, color: "#00575B" }}>
                      {alert === 's' ? t('chargeInvoices', { in_process_invoices }) : ''}
                    </h1>
                    {alert === 's' &&
                    <div style={{textAlign: 'center', marginTop: '1.5em'}}>
                      <Link
                        className="button custom-btn is-primary"
                        style={{ width: 'fit-content', margin: '1em' }}
                        to="/pending-invoices"
                      >
                        { t('view')}
                      </Link>
                    </div>}
                    {alert === 'b' &&
                    <div style={{textAlign: 'center', marginTop: '1.5em'}}>
                      {pending_invoices > 0 && (
                      <Link
                        className="button custom-btn is-primary"
                        style={{ width: 'fit-content', margin: '1em' }}
                        to="/recent-invoices"
                      >
                        {t('unapprovedInvoices', {pending_invoices})}
                      </Link>)}
                      {in_process_invoices > 0 && (
                      <Link
                        className="button custom-btn is-primary"
                        style={{ width: 'fit-content', margin: '1em' }}
                        onClick={() => history.push({
                          pathname: 'invoices',
                          state: {
                            userInfo: 0
                          },
                        })}
                        //to="/review-invoices"
                      >
                        { t('processInvoicesCant', { in_process_invoices })}
                      </Link>)}
                    </div>}
                  {/* </div> */}
                </Modal>
              )}
              { (type_user === 'acquiring_bank' || type_user === 'issuing_bank') &&(
              <div className="columns field-body">
                <div className="column">
                  <SelectSingle
                    name="request"
                    label={t('currency')}
                    placeholder={t('SelectOption')}
                    onChange={this.handleSelectRequest}
                    options={
                      [{
                        label: t('mexicanPesos'),
                        value: 0,
                      }, {
                        label: t('americanDollar'),
                        value: 1,
                      },
                      ]}
                    selectClasses="is-fullwidth"
                    controlClasses="has-icons-left"
                    icon="far fa-user"
                    iconPosition="is-left"
                    value={request}
                    isClearable={false}
                  />
                </div>
              </div>
              )}
              <div className="columns is-multiline">
                <div className="column is-full"
                    style={{
                      display: type_user !== 'general_admin' && type_user !== 'general_admin_acquiring' &&
                      type_user !== 'general_admin_issuing' && type_user !== 'general_admin_issuing_amex'
                      && type_user !== 'main_bank_issuing_amex' ? '' : 'none'
                    }}>
                  <InformativeBoxes items={statsData}/>
                </div>
                <div className="column is-full"
                    style={{
                      display: type_user !== 'general_admin' && type_user !== 'general_admin_acquiring' &&
                      type_user !== 'general_admin_issuing' && type_user !== 'general_admin_issuing_amex'
                      && type_user !== 'main_bank_issuing_amex' ? '' : 'none'
                    }}>
                  <InformativeBoxes items={statsData1}/>
                </div>
                <Fragment>
                  {
                    toggled && is_rate === false
                    && (
                      <Modal onToggle={onToggle}> {loading && <Loading/>}
                        <PaymentConditionForm
                          t={t}
                          onSubmit={() => this.newPaymentCondition(onToggle)}
                          handleInputChange={this.handleInputChange}
                          handlePercentageChange={this.handlePercentageChange}
                          isClient={type_user !== 'acquiring_bank'}
                          isConditionClient={type_user === 'buyer'}
                          isConditionProvider={type_user === 'seller'}
                          handleSelectChange={this.handleSelectChange}
                          persons={persons}
                          client={client}
                          provider={provider}
                          baseDays={baseDays}
                          discountRate={discountRate}
                          receiverDiscountShare={receiverDiscountShare}
                          clientPayment={clientPayment}
                          editable={editable}
                          //limitAmount={limitAmount}
                          //hidden={hidden}
                          hidden={false}
                          handleOnInputChange={this.handleOnInputChange}
                          inputValue={inputValue}
                          closeModal={() => this.closeModal(onToggle)}
                          codes={codes}
                          commerceCode={commerceCode}
                          codeDescription={codeDescription}
                          typeUser={type_user}
                          usedInModals
                        />
                      </Modal>
                    )
                  }
                  {((type_user !== 'admin' && type_user !== 'admin_amex' && type_user !== 'cycle_loader'
                    && type_user !== 'rate_operator' && type_user !== 'general_admin' &&
                    type_user !== 'general_admin_issuing' && type_user !== 'general_admin_issuing_amex'
                    && type_user !== 'general_admin_acquiring' && type_user !== 'main_bank_issuing_amex') && graph_data_crc)
                  && (
                      <div className="column is-full">
                        <Panel headingText={title}>
                          <ResponsiveContainer width="100%" height={500}>
                            <AreaChart
                              data={user_type === 'NCL' ? graph_data_crc : graph_multi}
                              margin={{
                                top: 0,
                                right: 100,
                                bottom: 10,
                                left: 50,
                              }}
                            >
                              <defs>
                                <linearGradient id="colorUsd" x1="0" y1="0" x2="0" y2="1">
                                  <stop offset="5%" stopColor="#8884d8" stopOpacity={0.95}/>
                                  <stop offset="95%" stopColor="#8884d8" stopOpacity={0.1}/>
                                </linearGradient>
                                { user_type === 'TDC' && (
                                  <linearGradient id="colorMxn" x1="0" y1="0" x2="0" y2="1">
                                    <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8}/>
                                    <stop offset="95%" stopColor="#82ca9d" stopOpacity={0}/>
                                  </linearGradient>
                                )}
                              </defs>
                              { user_type === 'NCL' && (
                                <Area
                                  type="monotone"
                                  legendType="line"
                                  dataKey="y"
                                  stroke="#FF004C"
                                  strokeWidth={3}
                                  fillOpacity={1}
                                  fill="url(#greenGradient)"
                                />
                              )}

                                <Area type="monotone" legendType="line" dataKey="USD" stroke="#8884d8" strokeWidth={3} fillOpacity={1} fill="url(#colorUsd)" />
                                <Area type="monotone" legendType="line" dataKey="MXN" stroke="#82ca9d" strokeWidth={3} fillOpacity={1} fill="url(#colorMxn)" />

                              <XAxis
                                dataKey="label"
                                interval="preserveStartEnd"
                                name={t('date')}
                                tick={{
                                  fontWeight: 'bold',
                                  fontSize: 12,
                                }}
                                tickSize={10}
                                minTickGap={50}
                                tickFormatter={tick => formatFriendlyDate(new Date(tick.replace(/-/g, '\/')))}
                              />
                              <YAxis
                                interval="preserveStartEnd"
                                name={t('amount')}
                                tick={{
                                  fontWeight: 'bold',
                                  fontSize: 12,
                                }}
                                tickFormatter={tick => formatCurrency(tick)}
                              />
                              <CartesianGrid strokeDasharray="5 5" vertical={false}/>
                              {user_type === 'TDC' && (
                                <Tooltip content={CustomTooltip}/>
                              )}
                              {user_type === 'NCL' && ( <Tooltip
                                formatter={value => [formatCurrency(value), t('accumulatedAmount')]}
                                labelFormatter={label => new Intl.DateTimeFormat('es-AR', {
                                  month: 'long',
                                  day: '2-digit',
                                  weekday: 'long',
                                  year: 'numeric',
                                }).format(new Date(label.replace(/-/g, '\/')))}
                              /> )}
                              {(user_type === 'NCL' ? graph_data_crc.length : graph_multi.length > 0) && (
                                <Brush
                                  dataKey="label"
                                  height={50}
                                  stroke="#000000"
                                  travellerWidth={10}
                                  y={450}
                                  startIndex={0}
                                  endIndex={60}
                                >
                                </Brush>
                              )}
                            </AreaChart>
                          </ResponsiveContainer>
                        </Panel>
                      </div>
                  )}
                  {/*((type_user === 'buyer') && loan_graph_data_crc) => Gráfica de Disposiciones
                    && (
                      <div className="column is-12">
                        <Panel headingText={t('myLoans')}>
                          <div className="columns">
                            <div className="column center-pie">
                              <PieChart width={550} height={510}>
                                <Pie
                                  dataKey="value"
                                  isAnimationActive={true}
                                  data={loan_graph_data_crc}
                                  cx="50%"
                                  cy="50%"
                                  innerRadius={100}
                                  outerRadius={140}
                                  paddingAngle={3}
                                  fill="#8884d8"
                                  label={CurrencyLabel}
                                >
                                  {loan_graph_data_crc && loan_graph_data_crc.map((entry, index) => (
                                      <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                    ))}
                                </Pie>
                                <Legend />
                                <Tooltip />
                              </PieChart>
                            </div>
                            {user_type !== 'NCL' && (
                              <div className="column center-pie">
                                <PieChart width={550} height={510}>
                                  <Pie
                                    dataKey="value"
                                    isAnimationActive={true}
                                    data={loan_graph_data_usd}
                                    cx="50%"
                                    cy="50%"
                                    innerRadius={100}
                                    outerRadius={140}
                                    paddingAngle={3}
                                    fill="#8884d8"
                                    label={CurrencyLabel}
                                  >
                                    {loan_graph_data_usd && loan_graph_data_usd.map((entry, index) => (
                                      <Cell key={`cell-${index}`}
                                            fill={COLORS[index % COLORS.length]}/>
                                    ))}
                                  </Pie>
                                  <Legend/>
                                  <Tooltip/>
                                </PieChart>
                              </div>)}
                          </div>
                        </Panel>
                      </div>
                    )*/}
                  {((type_user === 'admin' || type_user === 'admin_amex') && graph_data)
                  && (
                    <div className="column is-full">
                      <div style={{ zIndex: 99999 }}>
                        {
                          (type_user === 'admin' || type_user === 'admin_amex')
                          && (
                            <div className="block">
                              <div className="column is-full">
                                <div className="columns field-body is-vcentered">
                                  <div className="column">
                                    <p style={{
                                      textAlign: 'center',
                                      fontSize: '1.25rem'
                                    }}>
                                      {t('dateRange')}
                                    </p>
                                  </div>
                                  <div className="column" style={{ zIndex: 99999 }}>
                                    <DateInput
                                      name="invoice_date"
                                      label={t('since')}
                                      value={invoiceDate}
                                      onChange={(value) => this.handleDateChange('invoiceDate', value)}
                                      type="text"
                                      fieldClasses="is-expanded"
                                      controlClasses="has-icons-left"
                                      icon="far fa-calendar-alt"
                                      iconPosition="is-left"
                                      position="absolute"
                                    />
                                  </div>
                                  <div className="column" style={{ zIndex: 99999 }}>
                                    <DateInput
                                      name="invoice_date"
                                      label={t('until')}
                                      value={invoiceDateUntil}
                                      onChange={(value) => this.handleDateChange('invoiceDateUntil', value)}
                                      type="text"
                                      fieldClasses="is-expanded"
                                      controlClasses="has-icons-left"
                                      icon="far fa-calendar-alt"
                                      iconPosition="is-left"
                                    />
                                  </div>
                                  <div clasName="column" style={{ marginTop: '16px' }}>
                                    <button
                                      type="button"
                                      className="button is-primary"
                                      onClick={() => {
                                        invoiceDate === '' && invoiceDateUntil === '' ?
                                          showMessage({
                                            message: <strong
                                              style={{ color: '#ffffff' }}>{t('emptyFields')}</strong>,
                                            config: TOAST_CONFIG.ERROR,
                                          })
                                          : invoiceDateUntil < invoiceDate ?
                                          showMessage({
                                            message: <strong
                                              style={{ color: '#ffffff' }}>{t('errorDate')}</strong>,
                                            config: TOAST_CONFIG.ERROR,
                                          })
                                          :
                                          this.rangeDate();
                                      }}
                                    >
                                      {t('send')}
                                    </button>
                                  </div>
                                </div>
                                {/* <div className="columns field-body">
                                  <div className="column"></div>
                                </div> */}
                              </div>
                            </div>
                          )}
                      </div>
                      <br/>
                      <div className="columns field-body" style={{ zIndex: 1 }}>
                        <div className="column" style={{ zIndex: 1, }}>
                          <Panel headingText={title1} style={{ zIndex: 1 }}>
                            <ResponsiveContainer width="100%" height={400}>
                              <AreaChart
                                data={stat !== '' ? stat.graph_data[0].graph_emmit : 0}
                                margin={{
                                  top: 0,
                                  right: 50,
                                  bottom: 10,
                                  left: 10,
                                }}
                              >
                                <defs>
                                  <linearGradient id="greenGradient" x1="0" y1="0" x2="0" y2="1">
                                    <stop offset="5%" stopColor="#FF004C" stopOpacity={0.95}/>
                                    <stop offset="95%" stopColor="#FFFFFF" stopOpacity={0.1}/>
                                  </linearGradient>
                                </defs>
                                <Area
                                  type="monotone"
                                  legendType="line"
                                  dataKey="y"
                                  stroke="#FF004C"
                                  strokeWidth={3}
                                  fillOpacity={1}
                                  fill="url(#greenGradient)"
                                />
                                <XAxis
                                  dataKey="label"
                                  interval="preserveStartEnd"
                                  name={t('date')}
                                  tick={{
                                    fontWeight: 'bold',
                                    fontSize: 12,
                                  }}
                                  tickSize={5}
                                  minTickGap={25}
                                  tickFormatter={tick => formatFriendlyDate(new Date(tick.replace(/-/g, '\/')))}
                                />
                                <YAxis
                                  interval="preserveStartEnd"
                                  name={t('quantity')}
                                  tick={{
                                    fontWeight: 'bold',
                                    fontSize: 12,
                                  }}
                                  tickFormatter={tick => (tick)}
                                />
                                <CartesianGrid strokeDasharray="5 5" vertical={false}/>
                                <Tooltip
                                  formatter={value => [(value), t('totalInvoices')]}
                                  labelFormatter={label => new Intl.DateTimeFormat('es-AR', {
                                    month: 'long',
                                    day: '2-digit',
                                    weekday: 'long',
                                    year: 'numeric',
                                  }).format(new Date(label.replace(/-/g, '\/')))}
                                />
                                {stat !== '' ? stat.graph_data[0].graph_emmit.length > 0 : 0 && (
                                  <Brush
                                    dataKey="label"
                                    height={50}
                                    stroke="#000000"
                                    travellerWidth={10}
                                    y={450}
                                    startIndex={0}
                                    endIndex={60}
                                  >
                                    <AreaChart>
                                      <Area
                                        type="monotone"
                                        dataKey="y"
                                        stroke="#FF004C"
                                        strokeWidth={3}
                                        fillOpacity={1}
                                        fill="url(#greenGradient)"
                                      />
                                    </AreaChart>
                                  </Brush>
                                )
                                }
                              </AreaChart>
                            </ResponsiveContainer>
                          </Panel>
                        </div>
                        <div className="column">
                          <Panel headingText={title2}>
                            <ResponsiveContainer width="100%" height={400}>
                              <AreaChart
                                data={stat !== '' ? stat.graph_data[1].graph_approved : 0}
                                margin={{
                                  top: 0,
                                  right: 50,
                                  bottom: 10,
                                  left: 10,
                                }}
                              >
                                <defs>
                                  <linearGradient id="greenGradient" x1="0" y1="0" x2="0" y2="1">
                                    <stop offset="5%" stopColor="#FF004C" stopOpacity={0.95}/>
                                    <stop offset="95%" stopColor="#FFFFFF" stopOpacity={0.1}/>
                                  </linearGradient>
                                </defs>
                                <Area
                                  type="monotone"
                                  legendType="line"
                                  dataKey="y"
                                  stroke="#FF004C"
                                  strokeWidth={3}
                                  fillOpacity={1}
                                  fill="url(#greenGradient)"
                                />
                                <XAxis
                                  dataKey="label"
                                  interval="preserveStartEnd"
                                  name={t('date')}
                                  tick={{
                                    fontWeight: 'bold',
                                    fontSize: 12,
                                  }}
                                  tickSize={5}
                                  minTickGap={25}
                                  tickFormatter={tick => formatFriendlyDate(new Date(tick.replace(/-/g, '\/')))}
                                />
                                <YAxis
                                  interval="preserveStartEnd"
                                  name={t('quantity')}
                                  tick={{
                                    fontWeight: 'bold',
                                    fontSize: 12,
                                  }}
                                  tickFormatter={tick => (tick)}
                                />
                                <CartesianGrid strokeDasharray="5 5" vertical={false}/>
                                <Tooltip
                                  formatter={value => [(value), t('totalInvoices')]}
                                  labelFormatter={label => new Intl.DateTimeFormat('es-AR', {
                                    month: 'long',
                                    day: '2-digit',
                                    weekday: 'long',
                                    year: 'numeric',
                                  }).format(new Date(label.replace(/-/g, '\/')))}
                                />
                                {stat !== '' ? stat.graph_data[1].graph_approved.length > 0 : 0 && (
                                  <Brush
                                    dataKey="label"
                                    height={50}
                                    stroke="#000000"
                                    travellerWidth={10}
                                    y={450}
                                    startIndex={0}
                                    endIndex={60}
                                  >
                                    <AreaChart>
                                      <Area
                                        type="monotone"
                                        dataKey="y"
                                        stroke="#FF004C"
                                        strokeWidth={3}
                                        fillOpacity={1}
                                        fill="url(#greenGradient)"
                                      />
                                    </AreaChart>
                                  </Brush>
                                )
                                }
                              </AreaChart>
                            </ResponsiveContainer>
                          </Panel>
                        </div>
                      </div>
                      <div className="columns field-body">
                        <div className="column">
                          <Panel headingText={title3}>
                            <ResponsiveContainer width="100%" height={400}>
                              <AreaChart
                                data={stat !== '' ? stat.graph_data[2].graph_amount_approved : 0}
                                margin={{
                                  top: 0,
                                  right: 50,
                                  bottom: 10,
                                  left: 10,
                                }}
                              >
                                <defs>
                                  <linearGradient id="greenGradient" x1="0" y1="0" x2="0" y2="1">
                                    <stop offset="5%" stopColor="#FF004C" stopOpacity={0.95}/>
                                    <stop offset="95%" stopColor="#FFFFFF" stopOpacity={0.1}/>
                                  </linearGradient>
                                </defs>
                                <Area
                                  type="monotone"
                                  legendType="line"
                                  dataKey="y"
                                  stroke="#FF004C"
                                  strokeWidth={3}
                                  fillOpacity={1}
                                  fill="url(#greenGradient)"
                                />
                                <XAxis
                                  dataKey="label"
                                  interval="preserveStartEnd"
                                  name={t('date')}
                                  tick={{
                                    fontWeight: 'bold',
                                    fontSize: 12,
                                  }}
                                  tickSize={5}
                                  minTickGap={25}
                                  tickFormatter={tick => formatFriendlyDate(new Date(tick.replace(/-/g, '\/')))}
                                />
                                <YAxis
                                  interval="preserveStartEnd"
                                  name={t('amount')}
                                  tick={{
                                    fontWeight: 'bold',
                                    fontSize: 12,
                                  }}
                                  tickFormatter={tick => formatCurrency(tick)}
                                />
                                <CartesianGrid strokeDasharray="5 5" vertical={false}/>
                                <Tooltip
                                  formatter={value => [formatCurrency(value), t('accumulatedAmount')]}
                                  labelFormatter={label => new Intl.DateTimeFormat('es-AR', {
                                    month: 'long',
                                    day: '2-digit',
                                    weekday: 'long',
                                    year: 'numeric',
                                  }).format(new Date(label.replace(/-/g, '\/')))}
                                />
                                {stat !== '' ? stat.graph_data[2].graph_amount_approved.length > 0 : 0 && (
                                  <Brush
                                    dataKey="label"
                                    height={50}
                                    stroke="#000000"
                                    travellerWidth={10}
                                    y={450}
                                    startIndex={0}
                                    endIndex={60}
                                  >
                                    <AreaChart>
                                      <Area
                                        type="monotone"
                                        dataKey="y"
                                        stroke="#FF004C"
                                        strokeWidth={3}
                                        fillOpacity={1}
                                        fill="url(#greenGradient)"
                                      />
                                    </AreaChart>
                                  </Brush>
                                )
                                }
                              </AreaChart>
                            </ResponsiveContainer>
                          </Panel>
                        </div>
                        <div className="column">
                          <Panel headingText={title4}>
                            <ResponsiveContainer width="100%" height={400}>
                              <AreaChart
                                data={stat !== '' ? stat.graph_data[3].graph_pending_approve : 0}
                                margin={{
                                  top: 0,
                                  right: 50,
                                  bottom: 10,
                                  left: 10,
                                }}
                              >
                                <defs>
                                  <linearGradient id="greenGradient" x1="0" y1="0" x2="0" y2="1">
                                    <stop offset="5%" stopColor="#FF004C" stopOpacity={0.95}/>
                                    <stop offset="95%" stopColor="#FFFFFF" stopOpacity={0.1}/>
                                  </linearGradient>
                                </defs>
                                <Area
                                  type="monotone"
                                  legendType="line"
                                  dataKey="y"
                                  stroke="#FF004C"
                                  strokeWidth={3}
                                  fillOpacity={1}
                                  fill="url(#greenGradient)"
                                />
                                <XAxis
                                  dataKey="label"
                                  interval="preserveStartEnd"
                                  name={t('date')}
                                  tick={{
                                    fontWeight: 'bold',
                                    fontSize: 12,
                                  }}
                                  tickSize={5}
                                  minTickGap={25}
                                  tickFormatter={tick => formatFriendlyDate(new Date(tick.replace(/-/g, '\/')))}
                                />
                                <YAxis
                                  interval="preserveStartEnd"
                                  name={t('quantity')}
                                  tick={{
                                    fontWeight: 'bold',
                                    fontSize: 12,
                                  }}
                                  tickFormatter={tick => (tick)}
                                />
                                <CartesianGrid strokeDasharray="5 5" vertical={false}/>
                                <Tooltip
                                  formatter={value => [(value), t('totalInvoices')]}
                                  labelFormatter={label => new Intl.DateTimeFormat('es-AR', {
                                    month: 'long',
                                    day: '2-digit',
                                    weekday: 'long',
                                    year: 'numeric',
                                  }).format(new Date(label.replace(/-/g, '\/')))}
                                />
                                {stat !== '' ? stat.graph_data[3].graph_pending_approve.length > 0 : 0 && (
                                  <Brush
                                    dataKey="label"
                                    height={50}
                                    stroke="#000000"
                                    travellerWidth={10}
                                    y={450}
                                    startIndex={0}
                                    endIndex={60}
                                  >
                                    <AreaChart>
                                      <Area
                                        type="monotone"
                                        dataKey="y"
                                        stroke="#FF004C"
                                        strokeWidth={3}
                                        fillOpacity={1}
                                        fill="url(#greenGradient)"
                                      />
                                    </AreaChart>
                                  </Brush>
                                )
                                }
                              </AreaChart>
                            </ResponsiveContainer>
                          </Panel>
                        </div>
                      </div>
                    </div>
                  )}
                  {/*((type_user === 'seller' || type_user === 'seller_assist_card') && lateralStatsData) && (
                    <div className="column is-one-third">
                      <InformativeBoxes
                        items={lateralStatsData}
                        size="is-full"
                        className="inverted"
                      />
                    </div>
                  )*/}
                  {data
                  && (
                    <div className="column is-full"
                        style={{ display: type_user === 'admin' ? 'none' : ''}}>
                      <Panel
                        headingText={
                          (type_user === 'issuing_bank' || type_user === 'issuing_query') ? t('search') + ' ' + t('clients') :
                            (type_user === 'acquiring_bank' || type_user === 'acquiring_query') ? t('search') + ' ' + t('providers') :
                              (type_user === 'rate_operator' || type_user === 'cycle_loader') ? t('search') + ' ' + t('invoices') : t('search') + ' ' + t('users')}>
                        {
                          (type_user === 'acquiring_bank' || type_user === 'acquiring_query')
                          && (
                            <SearchPersonForm
                              t={t}
                              searchPerson={this.searchPerson}
                              handleInputChange={this.handleInputChange}
                              handleOnInput={validateAlphaNumeric}
                              document_number={document_number}
                              name={name}
                              email={email}
                              min_amount={min_amount}
                              max_amount={max_amount}
                              type_rol={"operador"}
                            />
                          )}
                        {
                          (type_user === 'issuing_bank' || type_user === 'issuing_query')
                          && (
                            <SearchPersonForm
                              t={t}
                              searchPerson={this.searchPerson}
                              handleInputChange={this.handleInputChange}
                              handleOnInput={validateAlphaNumeric}
                              document_number={document_number}
                              name={name}
                              email={email}
                              min_amount={min_amount}
                              max_amount={max_amount}
                              type_rol={"operador"}
                            />
                          )}
                        {
                          (type_user === 'general_admin' || type_user === 'main_bank' || type_user === 'general_admin_acquiring'
                            || type_user === 'main_bank_acquiring' || type_user === 'general_admin_issuing'
                            || type_user === 'general_admin_issuing_amex' || type_user === 'main_bank_issuing'
                            || type_user === 'main_bank_issuing_amex')
                          && (
                            <SearchPersonForm
                              t={t}
                              searchPerson={this.searchPerson}
                              handleInputChange={this.handleInputChange}
                              handleOnInput={validateAlphaNumeric}
                              user={user}
                              email={email}
                              rol={rol}
                              rut={rut}
                              type_user={'banks'}
                              type_rol={type_user}
                              roleList={role.list}
                              role_filter={role_filter}
                              handleSelectChange={this.handleSelectChange}
                            />
                          )}
                        {(type_user === 'rate_operator')
                        && (
                          <div style={{ zIndex: 99999 }}>
                            <Form
                              submitText={t('search')}
                              onSubmit={(event) => {
                                event.preventDefault();
                                this.searchInvoice();
                              }}
                              buttonClasses="mr-8px"
                            >
                              <div className="field is-horizontal">
                                <div className="columns field-body">
                                  <div className="column">
                                    <TextInput
                                      name="mainInvoiceNumber"
                                      label={t('mainInvoiceNumber')}
                                      value={mainInvoiceNumber}
                                      onChange={this.handleInputChange}
                                      placeholder={t('mainInvoiceNumber')}
                                      type="text"
                                      inputClasses="has-text-right"
                                      controlClasses="is-expanded has-icons-left"
                                      iconPosition="is-left"
                                      icon="fas fa-search"
                                    />
                                  </div>
                                  <div className="column" style={{ zIndex: 99999 }}>
                                    <DateInput
                                      name="invoice_date"
                                      label={t('since')}
                                      value={invoiceDate}
                                      onChange={(value) => this.handleDateChange('invoiceDate', value)}
                                      type="text"
                                      fieldClasses="is-expanded"
                                      controlClasses="has-icons-left"
                                      icon="far fa-calendar-alt"
                                      iconPosition="is-left"
                                    />
                                  </div>
                                  <div className="column" style={{ zIndex: 99999 }}>
                                    <DateInput
                                      name="invoice_date"
                                      label={t('until')}
                                      value={invoiceDateUntil}
                                      onChange={(value) => this.handleDateChange('invoiceDateUntil', value)}
                                      type="text"
                                      fieldClasses="is-expanded"
                                      controlClasses="has-icons-left"
                                      icon="far fa-calendar-alt"
                                      iconPosition="is-left"
                                    />
                                  </div>
                                </div>
                              </div>
                            </Form>
                            {/*</Panel>*/}
                            <Fragment>
                              {
                                toggled && is_rate
                                && (
                                  <Modal onToggle={onToggle}> {loading && <Loading/>}
                                    <InvoiceDetailForm
                                      t={t}
                                      onSubmit={() => this.saveInvoice(onToggle)}
                                      handleInputChange={this.handleInputChange}
                                      sender={true}
                                      discountRateChargesDescription={discountRateChargesDescription}
                                      discountRateChargesDate={discountRateChargesDate}
                                      discountRateChargesDueDate={discountRateChargesDueDate}
                                      discountRateChargesInvoiceNumber={discountRateChargesInvoiceNumber}
                                      discountRateChargesDiscountRate={discountRateChargesDiscountRate}
                                      discountRateChargesPercentage={discountRateChargesPercentage}
                                      discountRateChargesTotal={discountRateChargesTotal}
                                      currency={currency}
                                      rate={true}
                                      isEdit={true}
                                      isRate={is_rate}
                                    />
                                  </Modal>
                                )
                              }
                            </Fragment>
                          </div>
                        )}
                        {(type_user !== 'admin' && type_user !== 'admin_amex' && type_user !== 'cycle_loader') && (
                          <div style={{ zIndex: 1, }}>
                            <TableSimple
                              data={data}
                              columns={columns}
                              subComponent={this.renderRowSubComponent}
                            />
                          </div>
                        )}
                        {(type_user === 'cycle_loader') && (
                          <Panel headingText={t('currentCycle')}>
                            <div className="field is-horizontal">
                            <div className="field-body columns">
                              <div className="column is-one-third">
                              </div>
                              <div className="column is-one-third">
                                <strong>
                                  {t('dateFf')}
                                </strong>
                              </div>
                              <div className="column is-one-third">
                                <strong>
                                  {t('dateV')}
                                </strong>
                              </div>
                            </div>
                          </div>
                          <div>
                          <ul>
                            {arrayOrder.map((val, index) => (
                              <li>
                                <div className="field is-horizontal">
                                  <div className="field-body columns">
                                    <div className="column is-one-fifth">
                                      <label style={{
                                        marginTop: '30px',
                                        display: 'block',
                                      }}>
                                        {t('group') + val.group}
                                      </label>
                                    </div>
                                    <div style={{
                                      width: '70px',
                                      marginTop: '40px'
                                    }}>
                                      <label>
                                        {dias[(new Date(val.date_init*1000).getDay())-1]}
                                      </label>
                                    </div>
                                    <div className="column is-one-third">
                                      <DateInput
                                        name={'cycle_date'}
                                        label={t('month')}
                                        value={new Date(val.date_init*1000)}
                                        fieldClasses="is-expanded"
                                        controlClasses="has-icons-left"
                                        icon="far fa-calendar-alt"
                                        iconPosition="is-left"
                                        dateFormat="dd/MM/yyyy"
                                        showMonthYearPicker={true}
                                        showFullMonthYearPicker={true}
                                        locale={'es'}
                                        disabled
                                        />
                                      <br/>
                                    </div>
                                    <div style={{
                                      width: '70px',
                                      marginTop: '40px'
                                    }}>
                                      <label>
                                        {dias[(new Date(val.date_end*1000).getDay())-1]}
                                      </label>
                                    </div>
                                    <div className="column is-one-third">
                                      <DateInput
                                        name={'cycle_date'}
                                        label={t('month')}
                                        value={new Date(val.date_end*1000)}
                                        fieldClasses="is-expanded"
                                        controlClasses="has-icons-left"
                                        icon="far fa-calendar-alt"
                                        iconPosition="is-left"
                                        dateFormat="dd/MM/yyyy"
                                        showMonthYearPicker={true}
                                        showFullMonthYearPicker={true}
                                        locale={'es'}
                                        disabled
                                        />
                                      <br/>
                                    </div>
                                  </div>
                                </div>
                            </li>
                            ))}
                          </ul>
                          </div>
                          </Panel>
                        )}
                      </Panel>
                    </div>
                  )}
                </Fragment>
              </div>
            </div>
          );
        }}
      </Toggle>
    );
  }
}

const CurrencyLabel = ({ x, y, textAnchor, fill, value }) => {
  return (
    <text x={x} y={y} textAnchor={textAnchor} fill={fill}>
      {formatCurrency(value)}
    </text>
  );
}

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    const date = new Intl.DateTimeFormat('es-AR', {
      month: 'long',
      day: '2-digit',
      weekday: 'long',
      year: 'numeric',
    }).format(new Date(label.replace(/-/g, '\/')));
    return (
      <div className="custom-tooltip">
        <p className="label-graph">{date}</p>
        <div style={{display: 'inline-block', padding: 10}}>
          {
            payload.map((entry, index) => (
            <li key={`item-${index}`} >
              {`${('Monto acumulado')} ${entry.dataKey}: ${entry.value}`}
            </li>
          ))}
        </div>
      </div>
    );
  }
  return null;
}

Dashboard.propTypes = {
  auth: PropTypes.shape(Object).isRequired,
  clients: PropTypes.shape(Object).isRequired,
  providers: PropTypes.shape(Object).isRequired,
  stats: PropTypes.shape(Object).isRequired,
  t: PropTypes.func.isRequired,
  getClients: PropTypes.func.isRequired,
  getClientsConditions: PropTypes.func.isRequired,
  getProviders: PropTypes.func.isRequired,
  getProvidersConditions: PropTypes.func.isRequired,
  fetchStats: PropTypes.func.isRequired,
  fetchLeads: PropTypes.func.isRequired,
  listTransactionsSender: PropTypes.func.isRequired,
  listTransactionsReceiver: PropTypes.func.isRequired,
  approveInvoice: PropTypes.func.isRequired,
  deferInvoice: PropTypes.func.isRequired,
  advanceInvoice: PropTypes.func.isRequired,
  createPaymentCondition: PropTypes.func.isRequired,
  showMessage: PropTypes.func.isRequired,
  getProvider: PropTypes.func.isRequired,
  getClient: PropTypes.func.isRequired,
  getCyclePeriods: PropTypes.func.isRequired,
  fetchDocumentTypes: PropTypes.func.isRequired,
  statTransaction: PropTypes.func.isRequired,
  requestPaymentCondition: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
};

const mapStateToProps = ({
                           stats,
                           auth,
                           clients,
                           providers,
                           documentType,
                           transactions,
                           card,
                           currency,
                           bank,
                           paymentConditions,
                           role, item,
                         }) => ({
  stats,
  auth,
  clients,
  providers,
  documentType,
  transactions,
  card,
  currency,
  bank,
  paymentConditions,
  role,
  item,
});
export default compose(
  connect(mapStateToProps, {
    ...authActions,
    ...bankActions,
    ...documentTypeActions,
    ...statsActions,
    ...transactionsActions,
    ...clientsActions,
    ...providersActions,
    ...paymentConditionsActions,
    ...leadActions,
    ...cardActions,
    ...currencyActions,
    ...roleActions,
    ...itemActions,
  }),
  withNamespaces(),
)(Dashboard);
