const START_FETCH = 'documentType/START_FETCH';
const END_FETCH = 'documentType/END_FETCH';
const API_CALL = 'documentType/API_CALL';
const MESSAGE = 'documentType/MESSAGE';
const FETCH_DOCUMENT_TYPES = 'documentType/FETCH_DOCUMENT_TYPES';
const UPDATE_DOCUMENT_TYPES = 'documentType/UPDATE_DOCUMENT_TYPES';


export default {
  START_FETCH,
  END_FETCH,
  API_CALL,
  MESSAGE,
  FETCH_DOCUMENT_TYPES,
  UPDATE_DOCUMENT_TYPES,
};
