import { clientsActions, clientsTypes } from '../ducks/clients';

const fetchClients = ({ dispatch }) => next => (action) => {
    next(action);

    if (action.type !== clientsTypes.GET_CLIENTS) {
        return;
    }

    const {
        params: {
            id = '',
            related = false,
        } = {},
        callback = () => {},
    } = action.payload;
    dispatch({
        type: clientsTypes.API_CALL,
        payload: {
            config: {
                method: 'GET',
                url: `/b2b/clients?provider=${id}&related=${related}`,
            },
            authorization: true,
            onStart: clientsActions.startFetch,
            onComplete: ({ data: { success, data } }) => {
                if (success) {
                    callback(data);
                    dispatch(clientsActions.saveClients(data));
                }
            },
            onEnd: clientsActions.endFetch,
        },
    });
};

const fetchClient = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== clientsTypes.GET_CLIENT) {
    return;
  }

  const {
    params: {
      id = '',
    } = {},
    callback = () => {
    },
  } = action.payload;
  dispatch({
    type: clientsTypes.API_CALL,
    payload: {
      config: {
        method: 'GET',
        url: `/b2b/client?client=${id}`,
      },
      authorization: true,
      onStart: clientsActions.startFetch,
      onComplete: ({ data: { success, data } }) => {
        if (success) {
          dispatch(clientsActions.setClient(data));
          callback(data);
        }
      },
      onEnd: clientsActions.endFetch,
    },
  });
};

const fetchClientsConditions = ({ dispatch }) => next => (action) => {
    next(action);

    if (action.type !== clientsTypes.GET_CLIENTS_CONDITIONS) {
        return;
    }

    const {
        params: {
            id = '',
        } = {},
        callback = () => {},
    } = action.payload;
    dispatch({
        type: clientsTypes.API_CALL,
        payload: {
            config: {
                method: 'GET',
                url: `/b2b/clients-with-conditions?provider=${id}`,
            },
            authorization: true,
            onStart: clientsActions.startFetch,
            onComplete: ({ data: { success, data } }) => {
                if (success) {
                    callback(data);
                    dispatch(clientsActions.setClientsConditions(data));
                }
            },
            onEnd: clientsActions.endFetch,
        },
    });
};

const sendResponse = ({ dispatch }) => next => (action) => {
    next(action);

    if (action.type !== clientsTypes.APPROVE_INVOICE) {
        return;
    }
    const {
        data: {
            transaction_id,
            approved,
            user_id,
            role_id,
            note,
            provider,
            file_sii,
            file_pass,
        },
        data,
        callback = () => {
        },
    } = action.payload;

    const dataForm = new FormData();
    if (file_sii) {
        dataForm.append('transaction_id', transaction_id);
        dataForm.append('approved', approved);
        dataForm.append('user_id', user_id);
        dataForm.append('role_id', role_id);
        dataForm.append('note', approved === false ? note : '');
        dataForm.append('provider', provider);
        dataForm.append('file_pass', file_pass);
        dataForm.append('file_sii', file_sii);
    }

    const dataSend = file_sii ? dataForm : data;

    dispatch({
        type: clientsTypes.API_CALL,
        payload: {
            config: {
                method: 'POST',
                url: '/b2b/approve',
                data: dataSend,
            },
            authorization: true,
            onStart: clientsActions.startFetch,
            onComplete: (response) => {
                if (response.data.success === true) {
                    callback(response.data.data);
                }
            },
            onEnd: clientsActions.endFetch,
        },
    });
};

const defer = ({ dispatch }) => next => (action) => {
    next(action);

    if (action.type !== clientsTypes.DEFER_INVOICE) {
        return;
    }

    const {
        data,
        callback = () => {},
    } = action.payload;
    dispatch({
        type: clientsTypes.API_CALL,
        payload: {
            config: {
                method: 'POST',
                url: '/b2b/defer',
                data,
            },
            authorization: false,
            onStart: clientsActions.startFetch,
            onComplete: (response) => {
                if (response.data.success) {
                    callback(response.data.data);
                }
            },
            onEnd: clientsActions.endFetch,
        },
    });
};

const editUser = ({ dispatch }) => next => (action) => {
    next(action);

    if (action.type !== clientsTypes.EDIT_USER) {
        return;
    }

    const {
        data,
        callback = () => {},
    } = action.payload;

    dispatch({
        type: clientsTypes.API_CALL,
        payload: {
            config: {
                method: 'POST',
                url: '/user/update-buyer-seller-information',
                data,
            },
            authorization: true,
            onStart: clientsActions.startFetch,
            onComplete: ({ data: { data, success } }) => {
                if (success) {
                    callback(data);
                }
            },
            onEnd: clientsActions.endFetch,
        },
    });
};

const clientsFileLoad = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== clientsTypes.CLIENTS_FILE_LOAD) {
    return;
  }
  const {
    data,
    data: {
      bank_id,
      role,
      users_file,
    },
    callback = () => {},
    t = () => {},
  } = action.payload;

  const dataForm = new FormData();
  if(users_file){
    dataForm.append('bank_id', bank_id);
    dataForm.append('role', role);
    dataForm.append('users_file', users_file);
  }
  const dataSend = users_file ? dataForm : data;

  dispatch({
    type: clientsTypes.API_CALL,
    payload: {
      config: {
        method: 'POST',
        url: '/api/users-file-load',
      data: dataSend,
      },
      authorization: true,
      onStart: clientsActions.startFetch,
      onComplete: (response) => {
        dispatch(clientsActions.setClientsConditions(response.data.data));
        callback(response.data.data);
      },
      onEnd: clientsActions.endFetch,
    },
  });
};

const fetchDispositionReport = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== clientsTypes.GET_DISPOSITION_REPORT) {
    return;
  }

  const {
    params: {
        from = '',
        to = '',
        last_dispositions,
    } = {},
    callback = () => {},
  } = action.payload;
  dispatch({
    type: clientsTypes.API_CALL,
    payload: {
      config: {
        method: 'GET',
        url: `/b2b/disposition-report?from=${from}&to=${to}&last_dispositions=${last_dispositions}`,
      },
      authorization: true,
      onStart: clientsActions.startFetch,
      onComplete: ({ data: { success, data } }) => {
        if (success) {
          if(Array.isArray(data)) {
              dispatch(clientsActions.setDispositionReport(data));
          }
          callback(data);
        }
      },
      onEnd: clientsActions.endFetch,
    },
  });
};

const createPayment = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== clientsTypes.CREATE_PAYMENT) {
    return;
  }

  const {
    data,
    data: {
      seller,
      payment_condition_id,
      commerce_code,
      amount,
      invoice_number,
      payment_reference,
      description,
      email,
      resource,
      resource_xml,
      execute_payment,
      ncl,
      approved
    },
    callback = () => {},
    t = () => {},
  } = action.payload;

  const dataForm = new FormData();
  if(resource || resource_xml){
    dataForm.append('seller', seller);
    dataForm.append('payment_condition_id', payment_condition_id);
    dataForm.append('commerce_code', commerce_code);
    dataForm.append('amount', amount);
    dataForm.append('invoice_number', invoice_number);
    dataForm.append('payment_reference', payment_reference);
    dataForm.append('description', description);
    dataForm.append('email', JSON.stringify(email));
    dataForm.append('resource', resource);
    dataForm.append('resource_xml', resource_xml);
    dataForm.append('execute_payment', execute_payment);        
    dataForm.append('ncl', ncl);        
    dataForm.append('approved', approved);        
  }
  const dataSend = (resource || resource_xml) ? dataForm : data;

  dispatch({
      type: clientsTypes.API_CALL,
      payload: {
          config: {
          method: 'POST',
          url: '/b2b-payment/save-payment',
          data: dataSend,
          },
          authorization: true,
          onStart: clientsActions.startFetch,
          onComplete: (response) => {
            callback(response);
          },
          onError: (response) => {
            callback(response);
          },
          onEnd: clientsActions.endFetch,
      },
  });
};

const fetchClientsPaymentRecords = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== clientsTypes.FETCH_CLIENTS_PAYMENT_RECORDS) {
    return;
  }

  const {
    callback = () => {
    },
  } = action.payload;
  dispatch({
    type: clientsTypes.API_CALL,
    payload: {
      config: {
        method: 'GET',
        url: `/b2b-payment/payments-business`,
      },
      authorization: true,
      onStart: clientsActions.startFetch,
      onComplete: ({ data }) => {
        if (data.success) {
          dispatch(clientsActions.savePaymentRecords(data.data));
        }
        callback(data);
      },
      onEnd: clientsActions.endFetch,
    },
  });
};

const fetchBanksPaymentRecords = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== clientsTypes.FETCH_BANKS_PAYMENT_RECORDS) {
    return;
  }

  const {
    callback = () => {
    },
  } = action.payload;
  dispatch({
    type: clientsTypes.API_CALL,
    payload: {
      config: {
        method: 'GET',
        url: `/b2b-payment/payments-banks`,
      },
      authorization: true,
      onStart: clientsActions.startFetch,
      onComplete: ({ data }) => {
        if (data.success) {
          dispatch(clientsActions.savePaymentRecords(data.data));
        }
        callback(data);
      },
      onEnd: clientsActions.endFetch,
    },
  });
};

const fetchPaymentDetail = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== clientsTypes.FETCH_PAYMENT_DETAIL) {
    return;
  }

  const {
    params: {
      payment = '',
  } = {},
    callback = () => {
    },
  } = action.payload;
  dispatch({
    type: clientsTypes.API_CALL,
    payload: {
      config: {
        method: 'GET',
        url: `/b2b-payment/payment-detail?payment=${payment}`,
      },
      authorization: true,
      onStart: clientsActions.startFetch,
      onComplete: ({ data }) => {
        if (data.success) {
          dispatch(clientsActions.saveCurrentPayment(data.data));
        }
        callback(data);
      },
      onEnd: clientsActions.endFetch,
    },
  });
};

const editPayment = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== clientsTypes.EDIT_PAYMENT) {
      return;
  }

  const {
      data: {
        receiver_id,
        payment_condition_id,
        commerce_code_id,
        amount,
        invoice_number,
        payment_reference,
        description,
        email,
        resource,
        resource_xml,
        execute_payment,
        ncl,
        approved,
        id
      },
      callback = () => {},
  } = action.payload;

  const dataForm = new FormData();
    dataForm.append('receiver_id', receiver_id);
    dataForm.append('payment_condition_id', payment_condition_id);
    dataForm.append('commerce_code_id', commerce_code_id);
    dataForm.append('amount', amount);
    dataForm.append('invoice_number', invoice_number);
    dataForm.append('payment_reference', payment_reference);
    dataForm.append('description', description);
    dataForm.append('email', JSON.stringify(email));
    dataForm.append('resource', resource);
    dataForm.append('resource_xml', resource_xml);
    dataForm.append('execute_payment', execute_payment);
    dataForm.append('ncl', ncl);        
    dataForm.append('approved', approved);          

  dispatch({
    type: clientsTypes.API_CALL,
    payload: {
      config: {
          method: 'POST',
          url: `/b2b-payment/update-payment?id=${id}`,
          data: dataForm,
      },
      authorization: true,
      onStart: clientsActions.startFetch,
      onComplete: (response) => {
        callback(response);
      },
      onError: (response) => {
        callback(response);
      },
      onEnd: clientsActions.endFetch,
    },
  });
};

const deletePayment = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== clientsTypes.DELETE_PAYMENT) {
      return;
  }

  const {
      id,
      callback = () => {},
  } = action.payload;

  dispatch({
    type: clientsTypes.API_CALL,
    payload: {
      config: {
          method: 'DELETE',
          url: `/b2b-payment/${id}`,
      },
      authorization: true,
      onStart: clientsActions.startFetch,
      onComplete: ({ data }) => {
          callback(data);
      },
      onEnd: clientsActions.endFetch,
    },
  });
};

const fetchPaymentLog = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== clientsTypes.FETCH_PAYMENT_LOG) {
    return;
  }

  const {
    params: {
      payment = '',
  } = {},
    callback = () => {
    },
  } = action.payload;
  dispatch({
    type: clientsTypes.API_CALL,
    payload: {
      config: {
        method: 'GET',
        url: `/b2b-payment/payment-log?payment=${payment}`,
      },
      authorization: true,
      onStart: clientsActions.startFetch,
      onComplete: ({ data }) => {
        if (data.success) {
          dispatch(clientsActions.savePaymentLog(data.data));
        }
        callback(data);
      },
      onEnd: clientsActions.endFetch,
    },
  });
};

export default [ 
  fetchClients, 
  fetchClientsConditions, 
  sendResponse, 
  defer, 
  fetchClient, 
  editUser, 
  clientsFileLoad, 
  fetchDispositionReport, 
  createPayment, 
  fetchClientsPaymentRecords,
  fetchBanksPaymentRecords, 
  fetchPaymentDetail,
  editPayment,
  deletePayment,
  fetchPaymentLog
];