import { logActions, logTypes } from '../ducks/log';

const getLog = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== logTypes.FETCH_LOG) {
    return;
  }

  const {
    callback = () => {
    }
  } = action.payload;

  dispatch({
    type: logTypes.API_CALL,
    payload: {
      config: {
        method: 'GET',
        url: 'b2b-log/get-log',
      },
      authorization: true,
      onStart: logActions.startFetch,
      onComplete: ({ data: { success, data } }) => {
        if (success) {
          callback(data);
          dispatch(logActions.getLog(data));
        }
      },
      onEnd: logActions.endFetch,
    },
  });
};

const eventLog = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== logTypes.EVENT_LOG) {
    return;
  }

  const {
    callback = () => {
    }
  } = action.payload;

  dispatch({
    type: logTypes.API_CALL,
    payload: {
      config: {
        method: 'GET',
        url: 'b2b-log/get-log-operations',
      },
      authorization: true,
      onStart: logActions.startFetch,
      onComplete: ({ data: { success, data } }) => {
        if (success) {
          callback(data);
          dispatch(logActions.getLog(data));
        }
      },
      onEnd: logActions.endFetch,
    },
  });
};

const getLogPayment = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== logTypes.GET_LOG_PAYMENT) {
    return;
  }

  const {
    params: { 
      id
    },
    callback = () => {
    }
  } = action.payload;

  dispatch({
    type: logTypes.API_CALL,
    payload: {
      config: {
        method: 'GET',
        url: `b2b-log/get-log-payment?id=${id}`,
      },
      authorization: true,
      onStart: logActions.startFetch,
      onComplete: (res) => {
        callback(res);
      },
      onEnd: logActions.endFetch,
    },
  });
};

const transactionLog = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== logTypes.TRANSACTION_LOG) {
    return;
  }

  const {
    callback = () => {
    }
  } = action.payload;

  dispatch({
    type: logTypes.API_CALL,
    payload: {
      config: {
        method: 'GET',
        url: 'trans-bank/log-webpay-transaction',
      },
      authorization: true,
      onStart: logActions.startFetch,
      onComplete: ({ data: { success, data } }) => {
        if (success) {
          callback(data);
          dispatch(logActions.getLog(data));
        }
      },
      onEnd: logActions.endFetch,
    },
  });
};

const logApiRequests = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== logTypes.LOG_API_REQUESTS) {
    return;
  }

  const {
    callback = () => {
    }
  } = action.payload;

  dispatch({
    type: logTypes.API_CALL,
    payload: {
      config: {
        method: 'GET',
        url: 'b2b-log/log-request',
      },
      authorization: true,
      onStart: logActions.startFetch,
      onComplete: ({ data: { success, data } }) => {
        if (success) {
          callback(data);
          dispatch(logActions.getLog(data));
        }
      },
      onEnd: logActions.endFetch,
    },
  });
};

export default [getLog, eventLog, transactionLog, logApiRequests, getLogPayment];