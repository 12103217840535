import { bankingExecutivesActions, bankingExecutivesTypes } from '../ducks/bankingExecutives';

const getBankingExecutives = ({ dispatch }) => next => (action) => {
    next(action);
  
    if (action.type !== bankingExecutivesTypes.GET_BANKING_EXECUTIVES) {
      return;
    }
  
    const {
      params: {
        deleted = 0,
        owner = '',
      } = {},
      callback = () => { },
    } = action.payload;
  
    dispatch({
      type: bankingExecutivesTypes.API_CALL,
      payload: {
        config: {
          method: 'GET',
          url: `b2b-banking-executive/get-executives?deleted=${deleted}&owner=${owner}`,
        },
        authorization: true,
        onStart: bankingExecutivesActions.startFetch,
        onComplete: ({ data: responseData }) => {
          const { success, data } = responseData;
          if (success) {
            callback(data);
            dispatch(bankingExecutivesActions.getExecutives(data));
          }
        },
        onEnd: bankingExecutivesActions.endFetch,
      },
    });
};

const getPersonExecutive = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== bankingExecutivesTypes.GET_PERSON_EXECUTIVE) {
    return;
  }

  const {
    params: {
      id,
    } = {},
    callback = () => { },
  } = action.payload;

  dispatch({
    type: bankingExecutivesTypes.API_CALL,
    payload: {
      config: {
        method: 'GET',
        url: `b2b-banking-executive/get-person-executive?user=&executive=${id}`,
      },
      authorization: true,
      onStart: bankingExecutivesActions.startFetch,
      onComplete: ({ data: responseData }) => {
        const { success, data } = responseData;
        //if (success) {
        callback(data, success);
        dispatch(bankingExecutivesActions.getClients(data));
        //}
      },
      onEnd: bankingExecutivesActions.endFetch,
    },
  });
};

const deleteExecutive = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== bankingExecutivesTypes.DELETE_EXECUTIVE) {
    return;
  }

  const {
    data,
    callback = () => {
    },
  } = action.payload;

  dispatch({
    type: bankingExecutivesTypes.API_CALL,
    payload: {
      config: {
        method: 'POST',
        url: `b2b-banking-executive/delete-executive`,
        data,
      },
      authorization: true,
      onStart: bankingExecutivesActions.startFetch,
      onComplete: ({ data: { success, data } }) => {
        if (success) {
          callback(data);
        }
      },
      onEnd: bankingExecutivesActions.endFetch,
    },
  });
};

const migrateExecutive = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== bankingExecutivesTypes.MIGRATE_EXECUTIVE) {
    return;
  }

  const {
    data,
    callback = () => {
    },
  } = action.payload;

  dispatch({
    type: bankingExecutivesTypes.API_CALL,
    payload: {
      config: {
        method: 'POST',
        url: `b2b-banking-executive/migrate-executive`,
        data,
      },
      authorization: true,
      onStart: bankingExecutivesActions.startFetch,
      onComplete: ({ data: { success, data } }) => {
        if (success) {
          callback(data);
        }
      },
      onEnd: bankingExecutivesActions.endFetch,
    },
  });
};

const editExecutive = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== bankingExecutivesTypes.EDIT_EXECUTIVE) {
    return;
  }

  const {
    data,
    callback = () => {
    },
  } = action.payload;

  dispatch({
    type: bankingExecutivesTypes.API_CALL,
    payload: {
      config: {
        method: 'POST',
        url: `b2b-banking-executive/update-banking-executive`,
        data,
      },
      authorization: true,
      onStart: bankingExecutivesActions.startFetch,
      onComplete: ({ data: { success, data } }) => {
        if (success) {
          callback(data);
        }
      },
      onEnd: bankingExecutivesActions.endFetch,
    },
  });
};

export default [getBankingExecutives, getPersonExecutive, deleteExecutive, migrateExecutive, editExecutive];