import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import "./styles.scss"
import { connect } from "react-redux"
import { compose } from "redux"
import { withNamespaces } from "react-i18next"
import { authActions } from "../../state/ducks/auth"
import { Panel, UserBadge } from "../../components"
import { redirectUser } from "../../config/constants"
import { defineWhereToGo, getDeviceInfo } from "../../util"

const SelectProfile = ({ auth: { profiles, user }, loginProfiles, t }) => {
  const [redirection, setRedirection] = useState("")
  const [deviceInfo, setDeviceInfo] = useState("")

  useEffect(() => {
    getDeviceInfo().then((res) => setDeviceInfo(res))
  }, [])

  const handleClick = (event, profile) => {
    event.persist()
    const { id, token, refresh_token } = profile

    const data = {
      token,
      refresh_token,
      id,
      device_info: deviceInfo,
    }
    loginProfiles({
      data,
      callback: (response) => {
        if (response.data.success) {
          setRedirection(defineWhereToGo(response.data.data))
        }
      },
    })
  }

  return (
    <>
      {redirectUser[redirection]}
      <Panel headingText={t("selectProfile")}>
        <div className="SelectProfile__main">
          <ul className="SelectProfile__profiles-list">
            {profiles.map((profile) => {
              const { name: userName, role, id } = profile
              return (
                <li key={id}>
                  <button onClick={(event) => handleClick(event, profile)}>
                    <UserBadge userName={userName} role={t(role)} id={id} />
                  </button>
                </li>
              )
            })}
          </ul>
        </div>
      </Panel>
    </>
  )
}

SelectProfile.propTypes = {
  auth: PropTypes.shape(Object).isRequired,
  i18n: PropTypes.shape(Object).isRequired,
  t: PropTypes.func.isRequired,
  showMessage: PropTypes.func.isRequired,
}

const mapStateToProps = ({ auth }) => ({
  auth,
})

export default compose(
  connect(mapStateToProps, {
    ...authActions,
  }),
  withNamespaces()
)(SelectProfile)
