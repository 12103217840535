import React from "react"
import "./styles.scss"
import PropTypes from "prop-types"
import { TextInput } from "./.."
import { formatAmount } from "../../util"

const PaymentDetailForm = ({
  currentPayment: {
    seller,
    personCode,
    adjusted_amount,
    invoice_number,
    payment_reference,
    description,
    emails,
    user_type,
  },
  t,
  history,
}) => {
  return (
    <div className="PaymentDetailForm__main Form">
      <div className="field is-horizontal">
        <div className="field-body columns">
          <div className="column">
            <TextInput
              name="provider"
              label={t("provider")}
              value={seller.name}
              disabled={true}
            />
          </div>
          {user_type === 'TDC' && (
            <div>
              <div className="column">
                <TextInput
                  name="commerceCode"
                  label={t("affiliateNumber")}
                  value={personCode.commerce_code}
                  disabled={true}
                />
              </div>
              <div className="column">
                <TextInput
                name="codeDescription"
                label={t('commerceCodeDescription')}
                value={personCode.description}
                disabled={true}
                />
                </div>
            </div>)}
        </div>
      </div>
      <div className="field is-horizontal">
        <div className="field-body columns">
          <div className="column">
            <TextInput
              name="invoiceNumber"
              label={user_type === 'TDC' ? t("invoicesNumber") : t('instructionNumber')}
              value={invoice_number}
              disabled={true}
            />
          </div>
        </div>
      </div>
      <div className="field is-horizontal">
        <div className="field-body columns">
          <div className="column">
            <TextInput
              name="amount"
              label={`${t("amount")} ${user_type === 'TDC' ? personCode.currency_id : ''}`}
              value={formatAmount(adjusted_amount)}
              disabled={true}
            />
          </div>
          <div className="column">
            <TextInput
              name="paymentReference"
              label={t("paymentReference")}
              value={payment_reference}
              disabled={true}
            />
          </div>
        </div>
      </div>
      <div className="field is-horizontal">
        <div className="field-body columns">
          <div className="column">
            <TextInput
              name="paymentDescription"
              label={t("description")}
              value={description}
              disabled={true}
            />
          </div>
          <div className="column">
            <TextInput
              name="relatedEmails"
              label={t("relatedEmails")}
              value={emails.join("  |  ")}
              disabled={true}
            />
          </div>
        </div>
      </div>
      <div className="field is-grouped is-grouped-centered">
        <button
          type="button"
          className='button custom-btn is-block is-danger'
          style={{ flex: '1', maxWidth: '200px' }}
          onClick={history.goBack}
        >
          {t('goBack')}
        </button>
      </div>
    </div>
  )
}

PaymentDetailForm.propTypes = {
  currentPayment: PropTypes.shape(Object).isRequired,
  t: PropTypes.func.isRequired,
  history: PropTypes.shape(Object).isRequired,
}

export default PaymentDetailForm
