import React, { Component } from 'react';
import { compose } from 'redux';
import './styles.scss';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import PropTypes from 'prop-types';
import { Loading } from '../../components';
import { TOAST_CONFIG } from '../../config/constants';
import { documentTypeActions } from '../../state/ducks/documentType';
import { routeActions } from '../../state/ducks/route';
import { clientsActions } from '../../state/ducks/clients';
import RouteForm from '../../components/RouteForm';


class CreateRoute extends Component {
  constructor(props) {
    super(props);
    this.state = { ...this.getInitialState() };
  }

  getInitialState = () => ({
    name: '',
    registerLoading: false,
  });

  resetState = () => {
    this.setState(this.getInitialState());
  };

  handleInputChange = (event) => {
    const {
      target: {
        checked, value: targetValue, type, name,
      },
    } = event;
    const value = type === 'checkbox' ? checked : targetValue;
    this.setState({
      [name]: value,
    });
  };

  handleSelectChange = (newVal, label) => {
    this.setState({
      [label.name]: newVal.value,
    });
  };

  createRoute = () => {
    const {
      addRoute,
      t,
      showMessage,
      history,
    } = this.props;
    const { name } = this.state;
    const data = {
      path: name,
    };
    this.setState({ registerLoading: true });
    addRoute({
      data,
      callback: (response) => {
        const { data: { path: pathName } } = response;
        history.push('/route-list');
        showMessage({
          message: t('createRouteSuccess', { pathName }),
          config: TOAST_CONFIG.SUCCESS,
        });
        this.resetState();
      },
    });
  };

  render() {
    const {
      t,
      route: { loading: routeLoading },
    } = this.props;
    const {
      name, registerLoading,
    } = this.state;
    const loading = routeLoading || registerLoading;
    return (

      <div>
        {loading && <Loading />}
        <div className="columns">
          <div className="column is-full">
            <RouteForm
              t={t}
              onSubmit={this.createRoute}
              handleInputChange={this.handleInputChange}
              name={name}
            />
          </div>
        </div>
      </div>

    );
  }
}

CreateRoute.propTypes = {
  route: PropTypes.shape(Object).isRequired,
  history: PropTypes.shape(Object).isRequired,
  addRoute: PropTypes.func.isRequired,
  showMessage: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};


const mapStateToProps = ({
  route,
}) => ({ route });
export default compose(
  connect(mapStateToProps, {
    ...documentTypeActions,
    ...routeActions,
    ...clientsActions,
  }),
  withNamespaces(),
)(CreateRoute);
