import React from "react";
import { Switch } from "react-router-dom";
import { ConnectedRouter } from "connected-react-router";
import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";
import routes, { RouteWithSubRoutes } from "./config/routes";
// eslint-disable-next-line import/no-cycle
import configureStore from "./state/store";
import "./styles/index.scss";

export const initialState = {
  auth: {
    logged: false,
    language: "",
    user: {
      b2b: {
        password_changed: -1,
      },
      token: "",
    },
    profile: {},
    profiles: null,
    contacts: [],
    account: {
      id: 1,
      name: "Cuenta B2B",
      account_number: "XXXXXXXXXXX",
      balance: "$ 0,00",
      product: "AR$",
    },
    b2b: {
      roles: [],
      paths: [],
    },
    loading: false,
    error: null,
  },
  clients: {
    list: [],
    sender: [],
    dispositionReport: [],
    paymentRecords: {
      list: [],
      currentPayment: {},
      paymentLog: []
    }
  },
  providers: {
    loading: false,
    list: [],
    receiver: [],
    receiverCommerce: null,
    type: [],
  },
  transactions: {
    balance: 0,
    list: [],
    loading: false,
    error: null,
    receiver: [],
    sender: [],
  },
  documentType: {
    loading: false,
    list: [],
  },
  role: {
    loading: false,
    list: [],
    type: {
      list: [],
    },
  },
  route: {
    loading: false,
    list: [],
    roleRoute: {
      list: [],
    },
  },
  bank: {
    loading: false,
    list: [],
  },
  financingProducts: {
    loading: false,
    list: [],
  },
  item: {
    loading: false,
    list: [],
    industries: [],
  },
  stats: {
    loading: false,
    assist: [],
    buyer_total: 0,
    active_buyer_total: 0,
    deferred_buyer_total: 0,
    buyers: [],
    seller_total: 0,
    active_seller_total: 0,
    paid_seller_total: 0,
    payment_request_pending: 0,
    relatedUsers: [],
    sellers: [],
    next_payment: null,
    next_payment_crc: false,
    next_payment_usd: false,
    paid_total_crc: 0,
    paid_total_usd: 0,
    total_debt_amount: 0,
    total_debt_amount_crc: 0,
    total_debt_amount_usd: 0,
    total_owed_amount: 0,
    graph_data: [],
    total_amount_approved_crc: 0,
    total_amount_approved_usd: 0,
    total_pending_invoices: 0,
    total_amount_paid_crc: 0,
    total_amount_paid_usd: 0,
    inactive_providers: 0,
    inactive_customers: 0,
    total_paid_clients_crc: 0,
    total_paid_clients_usd: 0,
    total_paid_clients_month_usd: 0,
    total_paid_clients_month_crc: 0,
    total_pending_invoice_usd: 0,
    total_pending_invoice_crc: 0,
    total_owed_amount_crc: 0,
    total_owed_amount_usd: 0,
    total_paid_crc: 0,
    total_paid_usd: 0,
    next_loan_expirations: 0,
    next_suppliers_payment: 0,
    line_credit_available: 0,
    average_monthly_amount_transactions: 0,
  },
  paymentConditions: {
    loading: false,
    list: [],
    listReceiver: [],
    log: [],
  },
  card: {
    assist: null,
    person: null,
    address: null,
    card: null,
    transactions: {
      transactions: [],
    },
    cycle: [],
    list: [],
    executives: [],
    loading: false,
    error: null,
  },
  lead: {
    list: [],
    loading: false,
  },
  userProfile: {
    list: [],
    loading: false,
  },
  flowApproval: {
    loading: false,
    selectId: "",
    list: [],
    activeFlow: [],
    process: [],
  },
  log: {
    loading: false,
    list: [],
  },
  webpay: {
    loading: false,
    accessToken: "",
    list: [],
    cycleCard: [],
  },
  gateway: {
    loading: false,
    list: [],
  },
  credentials: {
    loading: false,
    list: [],
  },
  currency: {
    loading: false,
    list: [],
  },
  cyclePeriods: {
    loading: false,
    list: [],
  },
  bankingExecutives: {
    loading: false,
    list: [],
    executives: [],
  },
  product: {
    loading: false,
    list: [],
    contracted: [],
  },
  support: {
    loading: false,
    list: [],
  },
  loan: {
    loading: false,
    list: [],
  },
};

function ScrollTop() {
  window.scrollTo(0, 0);
  return "";
}

const App = () => {
  const { store, persistor, history } = configureStore(initialState);

  // This is useful when state gets corrupted and persisted
  // persistor.purge();
  
  caches.keys().then((names) => {
    names.forEach((name) => {
      caches.delete(name);
    });
  });

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ConnectedRouter history={history}>
          <div>
            <ScrollTop />
            <Switch>
              {routes.map((route) => (
                <RouteWithSubRoutes key={`${route.path}`} {...route} />
              ))}
            </Switch>
          </div>
        </ConnectedRouter>
      </PersistGate>
    </Provider>
  );
};

export default App;
