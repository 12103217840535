import { createAction } from 'redux-actions';
import types from './types';

const startFetch = createAction(types.START_FETCH);
const endFetch = createAction(types.END_FETCH);
const endError = createAction(types.END_ERROR);
const getBankingExecutives = createAction(types.GET_BANKING_EXECUTIVES);
const getPersonExecutive = createAction(types.GET_PERSON_EXECUTIVE);
const getClients = createAction(types.GET_CLIENTS);
const getExecutives = createAction(types.GET_EXECUTIVES);
const deleteExecutive = createAction(types.DELETE_EXECUTIVE);
const migrateExecutive = createAction(types.MIGRATE_EXECUTIVE);
const editExecutive = createAction(types.EDIT_EXECUTIVE);

const showMessage = createAction(types.MESSAGE,
  ({ message }) => ({
    message,
  }),
  ({ config }) => ({
    config,
  })
);

export default {
  startFetch,
  endFetch,
  endError,
  showMessage,
  getBankingExecutives,
  getPersonExecutive,
  getExecutives,
  getClients,
  deleteExecutive,
  migrateExecutive,
  editExecutive,
};