import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  AcceptDiscountRate,
  AccountDetails,
  AccountProfile,
  AdminBank,
  AssignRoute,
  BankingExecutives,
  Banks,
  BankUserList,
  BillingCycle,
  BillingCycleList,
  BusinessExecutives,
  Card,
  CommerceCode,
  ConfirmCustomer,
  ConfirmProvider,
  CreateBank,
  CreateCustomer,
  CreateFaq,
  CreateFinancingProducts,
  CreateFlowApproval,
  CreateLead,
  CreatePaymentCondition,
  CreateProfile,
  CreateProvider,
  CreateRole,
  CreateRoute,
  CreateUser,
  CustomerCode,
  Customers,
  Dashboard,
  Deposit,
  EditBankUser,
  EditPaymentCondition,
  Extract,
  Faq,
  FinancingProducts,
  FlowApproval,
  GroupCustomer,
  GroupProvider,
  InvoiceDetail,
  Invoices,
  Layout,
  Leads,
  ListAdminBank,
  ListCards,
  LogView,
  ManualResolution,
  Manuals,
  MonthlyReport,
  OperationLog,
  PaymentConditionLog,
  PaymentConditions,
  PrepaidCard,
  ProductList,
  Products,
  Providers,
  Recharge,
  RecoverPassword,
  RegisterCard,
  RegisterSii,
  RelatedUsers,
  Reports,
  Request,
  Roles,
  Routes,
  SignIn,
  Success,
  Support,
  Transactions,
  Transfer,
  UserProfile,
  ValidateCode,
  Loans,
  DispositionReport,
  LogApiRequest,
  RetryPayment, 
  InvoicesNcl, 
  SelectProfile,
  CreatePayment,
  PaymentRecord,
  PaymentDetail,
  PaymentLog,
} from '../scenes';

const mapStateToProps = ({ auth }) => ({
  auth,
});

export const privateRoute = WrappedComponent => connect(mapStateToProps)(
  ({ auth, ...rest }) => (auth.logged === true
    ? <WrappedComponent auth={auth} {...rest} />
    : <Redirect to="/login" />
  ),
);

export const RouteWithSubRoutes = route => (
  <Route
    path={route.path}
    exact={route.exact}
    render={props => (
      <route.component {...props} {...route} />
    )}
  />
);

export const NotFound = () => (
  <Redirect to="/dashboard" />
);

const routes = [
  {
    path: '/login',
    component: SignIn,
    exact: true,
  },
  {
    path: '/recover-password',
    component: RecoverPassword,
    exact: true,
  },
  {
    path: '/validate-code',
    component: ValidateCode,
    exact: true,
  },
  {
    path: '/resp',
    component: privateRoute(Success),
    exact: true,
  },
  // {
  //   path: '/select-profile',
  //   component: privateRoute(SelectProfile),
  //   exact: true,
  // },
  {
    path: '/',
    component: privateRoute(Layout),
    routes: [
      {
        path: '/create-provider',
        component: privateRoute(CreateProvider),
        exact: true,
      },
      {
        path: '/edit-provider',
        component: privateRoute(CreateProvider),
        exact: true,
      },
      {
        path: '/create-customer',
        component: privateRoute(CreateCustomer),
        exact: true,
      },
      {
        path: '/edit-customer',
        component: privateRoute(CreateCustomer),
        exact: true,
      },
      {
        path: '/create-profile',
        component: privateRoute(CreateProfile),
        exact: true,
      },
      {
        path: '/dashboard',
        component: privateRoute(Dashboard),
        exact: true,
      },
      {
        path: '/account-details',
        component: privateRoute(AccountDetails),
        exact: true,
      },
      {
        path: '/extract',
        component: privateRoute(Extract),
        exact: true,
      },
      {
        path: '/prepaid-Card',
        component: privateRoute(PrepaidCard),
        exact: true,
      },
      {
        path: '/prepaid-Card/recharge',
        component: privateRoute(Recharge),
        exact: true,
      },
      {
        path: '/transfer',
        component: privateRoute(Transfer),
        exact: true,
      },
      {
        path: '/deposit',
        component: privateRoute(Deposit),
        exact: true,
      }, {
        path: '/load-invoice',
        component: privateRoute(Providers),
        exact: true,
      }, {
        path: '/customers',
        component: privateRoute(Customers),
        exact: true,
      }, {
        path: '/invoices',
        component: privateRoute(Invoices),
        exact: true,
      }, {
        path: '/expired-invoices',
        component: privateRoute(Invoices),
        exact: true,
      },
      {
        path: '/related-users',
        component: privateRoute(RelatedUsers),
        exact: true,
      },
      {
        path: '/clients',
        component: privateRoute(RelatedUsers),
        exact: true,
      },
      {
        path: '/providers',
        component: privateRoute(RelatedUsers),
        exact: true,
      },
      {
        path: '/register-bank',
        component: privateRoute(CreateBank),
        exact: true,
      },
      {
        path: '/bank-list',
        component: privateRoute(Banks),
        exact: true,
      },
      {
        path: '/role-list',
        component: privateRoute(Roles),
        exact: true,
      },
      {
        path: '/log-view',
        component: privateRoute(LogView),
        exact: true,
      },
      {
        path: '/invoice-detail',
        component: privateRoute(InvoiceDetail),
        exact: true,
      },
      {
        path: '/register-financing-products',
        component: privateRoute(CreateFinancingProducts),
        exact: true,
      },
      {
        path: '/financing-products',
        component: privateRoute(FinancingProducts),
        exact: true,
      },
      {
        path: '/register-role',
        component: privateRoute(CreateRole),
        exact: true,
      },
      {
        path: '/route-list',
        component: privateRoute(Routes),
        exact: true,
      },
      {
        path: '/register-route',
        component: privateRoute(CreateRoute),
        exact: true,
      },
      {
        path: '/admin-routes',
        component: privateRoute(AssignRoute),
        exact: true,
      },
      {
        path: '/recent-invoices',
        component: privateRoute(Invoices),
        exact: true,
      },
      {
        path: '/process-invoices',
        component: privateRoute(Invoices),
        exact: true,
      },
      {
        path: '/declined-invoices',
        component: privateRoute(Invoices),
        exact: true,
      },
      {
        path: '/review-invoices',
        component: privateRoute(Invoices),
        exact: true,
      },
      {
        path: '/reject-invoices',
        component: privateRoute(Invoices),
        exact: true,
      },
      {
        path: '/approved-invoices',
        component: privateRoute(Invoices),
        exact: true,
      },
      {
        path: '/paid-invoices',
        component: privateRoute(Invoices),
        exact: true,
      },
      {
        path: '/pending-invoices',
        component: privateRoute(Invoices),
        exact: true,
      },
      {
        path: '/create-lead',
        component: privateRoute(CreateLead),
        exact: true,
      },
      {
        path: '/lead-list',
        component: privateRoute(Leads),
        exact: true,
      },
      {
        path: '/reports',
        component: privateRoute(Reports),
        exact: true,
      },
      {
        path: '/disposition-report',
        component: privateRoute(DispositionReport),
        exact: true,
      },
      {
        path: '/payment-conditions-log',
        component: privateRoute(PaymentConditionLog),
        exact: true,
      },
      {
        path: '/transactions',
        component: privateRoute(Transactions),
        exact: true,
      },
      {
        path: '/profile-list',
        component: privateRoute(UserProfile),
        exact: true,
      },
      {
        path: '/create-approval-flow',
        component: privateRoute(CreateFlowApproval),
        exact: true,
      },
      {
        path: '/edit-approval-flow',
        component: privateRoute(CreateFlowApproval),
        exact: true,
      },
      {
        path: '/approval-flow-list',
        component: privateRoute(FlowApproval),
        exact: true,
      },
      {
        path: '/account-profile',
        component: privateRoute(AccountProfile),
        exact: true,
      },
      {
        path: '/payment-conditions',
        component: privateRoute(PaymentConditions),
        exact: true,
      },
      {
        path: '/payment-request',
        component: privateRoute(Request),
        exact: true,
      },
      {
        path: '/create-bank-user',
        component: privateRoute(CreateUser),
        exact: true,
      },
      {
        path: '/edit-bank-user',
        component: privateRoute(EditBankUser),
        exact: true,
      },
      {
        path: '/bank-user-list',
        component: privateRoute(BankUserList),
        exact: true,
      },
      {
        path: '/operation-log',
        component: privateRoute(OperationLog),
        exact: true,
      },
      {
        path: '/manual-resolution',
        component: privateRoute(ManualResolution),
        exact: true,
      },
      {
        path: '/register-card',
        component: privateRoute(RegisterCard),
        exact: true,
      },
      {
        path: '/replace-card',
        component: privateRoute(RegisterCard),
        exact: true,
      },
      {
        path: '/list-cards',
        component: privateRoute(ListCards),
        exact: true,
      },
      /*{
        path: '/payment-request-sent',
        component: privateRoute(Request),
        exact: true,
      },*/
      {
        path: '/payment-request-received',
        component: privateRoute(Request),
        exact: true,
      },
      {
        path: '/payment-request-rejected',
        component: privateRoute(Request),
        exact: true,
      },
      {
        path: '/log-transaction-webpay',
        component: privateRoute(LogView),
        exact: true,
      },
      {
        path: '/save-credentials',
        component: privateRoute(RegisterSii),
        exact: true,
      },
      {
        path: '/edit-credentials',
        component: privateRoute(RegisterSii),
        exact: true,
      },
      {
        path: '/load-billing-cycle',
        component: privateRoute(BillingCycle),
        exact: true,
      },
      {
        path: '/billing-cycles',
        component: privateRoute(BillingCycleList),
        exact: true,
      },
      {
        path: '/create-banking-executive',
        component: privateRoute(BusinessExecutives),
        exact: true,
      },
      {
        path: '/load-executives',
        component: privateRoute(BusinessExecutives),
        exact: true,
      },
      {
        path: '/payment-person',
        component: privateRoute(Card),
        exact: true,
      },
      {
        path: '/banking-executives',
        component: privateRoute(BankingExecutives),
        exact: true,
      },
      {
        path: '/products',
        component: privateRoute(Products),
        exact: true,
      },
      {
        path: '/contracted-products',
        component: privateRoute(ProductList),
        exact: true,
      },
      {
        path: '/product-detail',
        component: privateRoute(ProductList),
        exact: true,
      },
      {
        path: '/edit-billing-cycle',
        component: privateRoute(BillingCycle),
        exact: true,
      },
      {
        path: '/accept-discount-rate',
        component: privateRoute(AcceptDiscountRate),
        exact: true,
      },
      {
        path: '/edit-bank-admin',
        component: privateRoute(CreateBank),
        exact: true,
      },
      {
        path: '/save-faq',
        component: privateRoute(CreateFaq),
        exact: true,
      },
      {
        path: '/list-faqs',
        component: privateRoute(Faq),
        exact: true,
      },
      {
        path: '/save-manual',
        component: privateRoute(Manuals),
        exact: true,
      },
      {
        path: '/list-manual',
        component: privateRoute(Manuals),
        exact: true,
      },
      {
        path: '/support',
        component: privateRoute(Support),
        exact: true,
      },
      {
        path: '/monthly-report',
        component: privateRoute(MonthlyReport),
        exact: true,
      },
      {
        path: '/commerce-code',
        component: privateRoute(CommerceCode),
        exact: true,
      },
      {
        path: '/user-commerce-code',
        component: privateRoute(CustomerCode),
        exact: true,
      },
      {
        path: '/confirm-provider',
        component: privateRoute(ConfirmProvider),
        exact: true,
      },
      {
        path: '/confirm-customer',
        component: privateRoute(ConfirmCustomer),
        exact: true,
      },
      {
        path: '/create-payment-condition',
        component: privateRoute(CreatePaymentCondition),
        exact: true,
      },
      {
        path: '/edit-payment-condition',
        component: privateRoute(EditPaymentCondition),
        exact: true,
      },
      {  
        path: '/provider-group-load',
        component: privateRoute(GroupProvider),
        exact: true,
      },
      {
        path: '/list-provider',
        component: privateRoute(GroupProvider),
        exact: true,
      },
      /*{
        path: '/customer-group-load',
        component: privateRoute(GroupCustomer),
        exact: true,
      },*/
      {
        path: '/list-customer',
        component: privateRoute(GroupCustomer),
        exact: true,
      },
      {
        path: '/create-admin-bank',
        component: privateRoute(AdminBank),
        exact: true,
      },
      {
        path: '/edit-admin-bank',
        component: privateRoute(AdminBank),
        exact: true,
      },
      {
        path: '/admin-bank-list',
        component: privateRoute(ListAdminBank),
        exact: true,
      },
      {
        path: '/approved-loan',
        component: privateRoute(Loans),
        exact: true,
      },
      {
        path: '/log-api-request',
        component: privateRoute(LogApiRequest),
        exact: true,
      },
      {
        path: '/update-retry-payment',
        component: privateRoute(RetryPayment),
        exact: true,
      },
      {
        path: '/invoices-ncl',
        component: privateRoute(InvoicesNcl),
        exact: true,
      },
      {
        path: '/select-profile',
        component: privateRoute(SelectProfile),
        exact: true,
      },
      {
        path: '/create-payment',
        component: privateRoute(CreatePayment),
        exact: true,
      },
      {
        path: '/edit-payment',
        component: privateRoute(CreatePayment),
        exact: true,
      },
      {
        path: '/payment-record',
        component: privateRoute(PaymentRecord),
        exact: true,
      },
      {
        path: '/payment-detail',
        component: privateRoute(PaymentDetail),
        exact: true,
      },
      {
        path: '/payment-log',
        component: privateRoute(PaymentLog),
        exact: true,
      },
    ],
  },
  {
    component: NotFound,
  },
];

export default routes;
