import { createAction } from 'redux-actions';
import types from './types';

const startFetch = createAction(types.START_FETCH);
const endFetch = createAction(types.END_FETCH);
const saveFaq = createAction(types.SAVE_FAQ);
const supportRoles = createAction(types.SUPPORT_ROLES);
const getFaqs = createAction(types.GET_FAQS);
const updateFaq = createAction(types.UPDATE_FAQ);

const showMessage = createAction(types.MESSAGE,
    ({ message }) => ({
        message,
    }),
    ({ config }) => ({
        config,
    })
);

export default {
    startFetch,
    endFetch,
    showMessage,
    saveFaq,
    supportRoles,
    getFaqs,
    updateFaq,
};
